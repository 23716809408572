'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('tiaTimer', ['$timeout', 'elementStorage',
   function($timeout, elementStorage) {

    function link(scope, element, attrs) {
      var _timerRunning = false;
      var _timerPaused = false;
      var totalElapsedSeconds = 0;

      scope.minutes = "00";
      scope.seconds = "00";

      var cachedDate = elementStorage.getState(scope.userId, scope.experienceId, scope.quizId);
      elementStorage.clearState(scope.userId, scope.experienceId, scope.quizId);
      scope.$on('$destroy', function() {
        if (timerIsRunning()) {
          elementStorage.saveState(scope.userId, scope.experienceId, scope.quizId, new Date());
          setTimerRunning(false);
          scope.onLeaveTest()({elapsedSeconds: totalElapsedSeconds});
        }
      });

      scope.isEnabled = function () {
        return scope.enableStartButton && !scope.isPreview;
      };

      scope.onTimerButtonClick = function () {
        if (!scope.isEnabled())
          return;

        if (timerIsRunning()) {
          setTimerPaused(true);
          setTimerRunning(false);
          scope.onPause()({elapsedSeconds: totalElapsedSeconds});
        }
        else {
          startTimer();
        }
      };

      scope.onEndTIATest = function () {
        scope.onEndTest()({elapsedSeconds: totalElapsedSeconds});
      };

      function setTimerRunning(value) {
        _timerRunning = value;
      }
      function timerIsRunning() {
        return _timerRunning;
      }
      scope.timerIsRunning = timerIsRunning;

      function setTimerPaused(isPaused) {
        _timerPaused = isPaused;
      }
      function timerIsPaused() {
        return _timerPaused;
      }
      scope.timerIsPaused = timerIsPaused;

      scope.$watch('quizPaused', function (quizPaused) {
        setTimerPaused(quizPaused);
        if (quizPaused) {
          setTimerRunning(false);
        }
      });

      scope.$watch('proctorElapsedSeconds', function (elapsedSeconds) {
        if (elapsedSeconds) {
          totalElapsedSeconds = elapsedSeconds;
          setTimerDisplay(elapsedSeconds);
          if (!timerIsPaused() && !scope.quizEnded) {
            startTimer(elapsedSeconds);
          }
        }
      });

      scope.$watch('quizEnded', function (quizEnded) {
        if (quizEnded) {
          setTimerRunning(false);
          setTimerDisplay(scope.quizTime);
        }
      });

      function setTimerDisplay(elapsedSeconds) {
        let minutes = Math.floor(elapsedSeconds / 60);
        scope.minutes = ("0" + minutes).slice(-2);
        scope.seconds = ("0" + (elapsedSeconds % 60)).slice(-2);
      }

      function startTimer(elapsedSeconds) {
        if (timerIsRunning()) {
          return;
        }

        if (!elapsedSeconds && !(scope.onBegin()())) {
          return;
        }

        setTimerPaused(false);
        setTimerRunning(true);

        if (elapsedSeconds) {
          totalElapsedSeconds = elapsedSeconds;
        }

        if (cachedDate) {
          let awayTime = Math.round((Date.now() - cachedDate.getTime()) / 1000);
          totalElapsedSeconds = totalElapsedSeconds + awayTime;
          cachedDate = null;
        }

        var onTimeout = function () {
          if (timerIsRunning()) {
            totalElapsedSeconds = totalElapsedSeconds + 1;
            setTimerDisplay(totalElapsedSeconds);
            $timeout(onTimeout, 1000);
          }
          else {
            $timeout.cancel(timer);
          }
        };

        var timer = $timeout(onTimeout, 1000);
      }
    }

    return {
      restrict: 'E',
      scope: {
        userId: '=',
        experienceId: '=',
        quizId: '=',
        enableStartButton: '=',
        showStartButton: '=',
        onBegin: '&',
        onPause: '&',
        onEndTest: '&',
        onLeaveTest: '&',
        proctorElapsedSeconds: '=',
        quizPaused: '=',
        quizEnded: '=',
        quizTime: '=',
        quizPreview: '='
      },
      templateUrl: 'tiaTimerDirective.tpl',
      link: link
    };
  }]);

})();