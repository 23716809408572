'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('SchoolAdminMode', ['$rootScope', '$q', '$location', '$log', '$routeParams', 'User', 'userSchoolAdminPermissions',
                                       'userSubscriptionPermissions', 'subscriptionReports', 'ReportNavigation', 'ModalNavigation',
    function ($rootScope, $q, $location, $log, $routeParams, User, userSchoolAdminPermissions,
        userSubscriptionPermissions, subscriptionReports, ReportNavigation, ModalNavigation) {

    // Track the current admin
    var inschoolAdminMode = false;

    // Get the current school id from the URL
    function currentSchoolId() {
      return $routeParams.schoolId ? $routeParams.schoolId : null;
    }

    // Get the current subscription id from the URL
    function currentSubscriptionId() {
      return $routeParams.subscriptionId ? $routeParams.subscriptionId : null;
    }

    function defaultSchoolId() {
      return userSchoolAdminPermissions.getSchools(User.getId())
        .then(function(schools){
          if (schools && schools.length) {
            return schools[0].school_id;
          } else {
            return null;
          }
        });
    }

    function defaultSubscriptionId() {
      return userSchoolAdminPermissions.getSchools(User.getId())
        .then(function(schools){
          if (schools && schools.length) {
            return schools[0].subscription_id;
          } else {
            return null;
          }
        });
    }

    // test to see if we are in school admin mode
    function isActiveMode() {
      return inschoolAdminMode;
    }

    var remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
      // If the current route is a school route then set correctly
      if (current && current.$$route && current.pathParams && current.pathParams.schoolId) {
        inschoolAdminMode = true;
      }
      else {
        inschoolAdminMode = false;
      }
    });

    // Get the current report from the URL
    // named for brevity, in fact back-end returns report_key as report_id
    function currentReportKey() {
      return $routeParams.reportId;
    }

    // return the title when we are in school admin mode
    function getTitle() {
      return userSchoolAdminPermissions.getSchool(currentSchoolId()).then(function(school){
        var title = "School Admin Console";
        if (school) {
          title = school.name + " Admin Console";
        }
        return title;
      });
    }

    function getSchools() {
      return userSchoolAdminPermissions.getSchools(User.getId());
    }

    function navigateToUsersAndLicenses() {
      return defaultSchoolId().then(function(schoolId){
        return defaultSubscriptionId().then(function(subscriptionId) {
          $location.path('/school/' + schoolId + '/subscription/' + subscriptionId + '/seats').search({});
        });
      });
    }

    function navigateToUsers() {
      if (!currentSchoolId()) {
        return defaultSchoolId().then(function(schoolId){
          return defaultSubscriptionId().then(function(subscriptionId) {
            $location.path('/school/' + schoolId + '/subscription/' + subscriptionId + '/users').search({});
          });
        });
      } else {
        $location.path('/school/' + currentSchoolId() + '/subscription/' + currentSubscriptionId() + '/users').search({});
      }
    }

    function navigateToUsersAndRosters() {
      if (!currentSchoolId()) {
        return defaultSchoolId().then(function(schoolId){
          return defaultSubscriptionId().then(function(subscriptionId) {
            ModalNavigation.navigateToModal(
                '/school/' + schoolId + '/subscription/' + subscriptionId + '/manage',
                $location.path()
              );
          });
        });
      } else {
        ModalNavigation.navigateToModal(
            '/school/' + currentSchoolId() + '/subscription/' + currentSubscriptionId() + '/manage',
            $location.path()
          );
      }
    }

    function navigateToExperiences() {
      $location.path('/school/' + currentSchoolId() + '/subscription/' + currentSubscriptionId() + '/experiences').search({});
    }

    function navigateToReports() {
      $location.path('/school/' + currentSchoolId() + '/subscription/' + currentSubscriptionId() + '/reports').search({});
    }

    function getReports() {
      return subscriptionReports.getReports(currentSubscriptionId());
    }

    // Get the current report from the URL
    function currentReportId() {
      return $routeParams.reportId ? $routeParams.reportId : undefined;
    }

    function getReportData(filter) {
      return subscriptionReports.getReportData(currentSubscriptionId(), currentReportId(), filter);
    }

    function showReport(reportId) {
      ReportNavigation.navigateToReport(reportId, '/school/' + currentSchoolId() + '/subscription/' + currentSubscriptionId() + '/report/' + reportId,
          {}, $location.path());
    }

    function resetPermissions() {
    }

    return {
      isActiveMode: isActiveMode,
      getTitle: getTitle,
      getSchools: getSchools,
      getSchoolId: currentSchoolId,
      getSubscriptionId: currentSubscriptionId,
      navigateToUsersAndLicenses: navigateToUsersAndLicenses,
      navigateToUsers: navigateToUsers,
      navigateToUsersAndRosters: navigateToUsersAndRosters,
      navigateToExperiences: navigateToExperiences,
      navigateToReports: navigateToReports,
      getReports: getReports,
      getReportData: getReportData,
      showReport: showReport,
      resetPermissions: resetPermissions
    };

  }]);

})();