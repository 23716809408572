'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['ActiveMode', 'DistrictAdminMode', 'AdminConsoleService', 'userDistrictAdminPermissions',
                      'userSchoolAdminPermissions', 'reportToCSV', 'xpAlert', 'adminConsoleUsersService'];

function controller(ActiveMode, DistrictAdminMode, AdminConsoleService, userDistrictAdminPermissions,
                    userSchoolAdminPermissions, reportToCSV, xpAlert, adminConsoleUsersService) {
  var ctrl = this;

  ctrl.hasErrors = false;
  ctrl.isDistrictAdmin = false;
  ctrl.downloadFile = "";
  ctrl.isImporting = false;

  if (DistrictAdminMode.isActiveMode()) {
    ctrl.isDistrictAdmin = true;
    ctrl.downloadFile = "/downloads/District_Teacher_Import_Template.csv";
    userDistrictAdminPermissions.getDistrict(ActiveMode.currentDistrictId()).then(function(district){
      ctrl.Name = district.name;
    });
    AdminConsoleService.schools(ActiveMode.currentSubscriptionId()).then(function(schools){
      ctrl.schools = schools;
    });
  } else {
    ctrl.downloadFile = "/downloads/Teacher_Import_Template.csv";
    userSchoolAdminPermissions.getSchool(ActiveMode.currentSchoolId()).then(function(school){
      ctrl.Name = school.name;
    });
  }

  ctrl.downloadCampuses = reportToCSV(
      function() {
        return adminConsoleUsersService.campusHeaders();
      }, function () {
        return ctrl.schools;
      }, function () {
        return ctrl.Name + ' Schools.csv';
      }
  );

  ctrl.downloadErrors = reportToCSV(
    function () {
      return adminConsoleUsersService.teacherErrorHeaders();
    }, function () {
      return ctrl.teacherErrors;
    }, function () {
      return ctrl.Name + ' Teachers.csv';
    }
  );

  this.onFileSelect = function(files) {
    if (files.length > 0) {
      var file = files[0];
      if (adminConsoleUsersService.isCSVFile(file)) {
        var reader = new FileReader();
        reader.onload = onLoadTeacherFile;
        reader.readAsText(file);
      }
      else {
        xpAlert.error("Invalid import file. Only csv files can be imported", true);
      }
    }
  };

  function onLoadTeacherFile(event) {
    var fileContents = event.target.result;
    xpAlert.clearAlerts();
    ctrl.isImporting = true;
    adminConsoleUsersService.importTeacherFile(fileContents, ActiveMode.currentSubscriptionId(), ctrl.schools, ActiveMode.currentSchoolId(), true)
    .then(function(results){
      if (results.hasErrors) {
        ctrl.hasErrors = true;
        ctrl.teacherErrors = results.teachers;
      }
      ctrl.isImporting = false;
    });
  }

}

module.component('xpAdminInviteTeachers', {
  template: require('./adminConsoleInviteTeachers.jade'),
  controller: controller,
});

})();
