'use strict';

angular.module('client.services').factory('ModalNavigation', ['$sessionStorage', '$location',
  function ($sessionStorage, $location) {

    var sessionStateVariable = 'ModalNavigation';

    function setBackDestination(destination) {

      // See if any stack has been created.  If not then create a new one
      var stack = $sessionStorage[sessionStateVariable];
      if (!stack) {
        stack = [];
      }

      // if destination push it onto the stack
      if (destination) {
        stack.push(destination);
      }

      // Save the stack
      $sessionStorage[sessionStateVariable] = stack;
    }

    function navigateToModal(path, backPath) {
      setBackDestination(backPath);
      $location.path(path);
    }

    function navigateToModalWithParam(path, params, backPath) {
      setBackDestination(backPath);
      $location.path(path).search(params);
    }

    function getBackDestination() {
      var backDestination = $location.path();  // Default to stay here
      var stack = $sessionStorage[sessionStateVariable];
      if (stack) {
        // pull the last destination off the top of the stack
        backDestination = stack.pop();

        // Save the stack
        $sessionStorage[sessionStateVariable] = stack;
      }
      return backDestination;
    }

    return {
      getBackDestination: getBackDestination,
      setBackDestination: setBackDestination,
      navigateToModal: navigateToModal,
      navigateToModalWithParam: navigateToModalWithParam
    };

  }]);
