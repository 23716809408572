'use strict';

angular.module('client.services').factory('ReportNavigation', ['$sessionStorage', '$location',
  function ($sessionStorage, $location) {

    var navigationStateVariable = 'ReportNavigation';

    function setBackDestination(report, destination, params) {
      // See if any stack has been created.  If not then create a new one
      var stack = $sessionStorage[navigationStateVariable];
      if (!stack) {
        stack = [];
      }

      // if no destination then pop off any destination
      if (!destination) {
        stack.pop();
      }
      else {
        stack.push({
                    destination: destination,
                    params: params ? params : ''
                  });
      }

      // Save the stack
      $sessionStorage[navigationStateVariable] = stack;
    }

    function navigateToReport(report, path, params, backPath, backParams) {
      setBackDestination(report, backPath, backParams);
      $location.path(path).search(params);
    }

    function getBackDestination(report) {
      var backDestination = {
                              destination: $location.path(),  // Default to stay here
                              params: ''
                            };
      var stack = $sessionStorage[navigationStateVariable];
      if (stack) {
        // pull the last destination off the top of the stack
        backDestination = stack.pop();

        // Save the stack
        $sessionStorage[navigationStateVariable] = stack;
      }
      return backDestination;
    }

    return {
      getBackDestination: getBackDestination,
      navigateToReport: navigateToReport
    };

  }]);
