'use strict';

(function () {

  /**
   * @return {string}
   */
  function toUSDateFormat(d) {
    return window.moment(d).format('M/DD/YY');
  }

  function abbreviateNumber(num) {
    if (!num) {
      return num;
    }
    num = Number(num) || 0;
    if (num < 1000) {
      return '' + num;
    }
    var power = ((num).toPrecision(2).split("e")[1] || [0, 0]).slice(1);
    var triplets = Math.floor(power / 3);
    var numericResult = (num / Math.pow(10, triplets * 3)).toFixed(1);
    if (numericResult.length > 4) {
      numericResult = (num / Math.pow(10, triplets * 3)).toFixed();
    }
    return numericResult + ['', 'K', 'M', 'B', 'T'][triplets];
  }

  function formatExperienceSummaryDataRow(row) {
    var date = new Date(row.ends_at);
    var quiz = row.quiz_score;
    if (quiz !== null) {
      quiz = Math.round(quiz) || 0;
    }
    var resp = row.sum_response_count || 0;
    if (resp % 1 !== 0) {
      // we need to keep type Number for sorting later, hence the hack instead of toFixed
      resp = Math.round(resp * 1e1) / 1e1;
    }
    var percCompleted = Math.round((row.progress || 0) * 100);
    var timeOnTask = Math.round((row.period || 0) / 60);
    if (row.period && (row.period > 0) && (row.period < 60)) {
      timeOnTask = '< 1';
    } else {
      timeOnTask = abbreviateNumber(timeOnTask);
    }

    var timeOnTaskAccurate = row.period;
    if (timeOnTaskAccurate) {
      timeOnTaskAccurate = (timeOnTaskAccurate / 60).toFixed(2);
    }
    var quizSort = row.quiz_score;
    if (quizSort === null) {
      quizSort = -1;
    }
    var activitySort = row.avg_elements_score;
    if (activitySort === null) {
      activitySort = -1;
    }
    return {
      archivedInfo: row.archived_info || false,
      prettyDate: toUSDateFormat(date),
      prettyName: row.student_first_name + ' ' + row.student_last_name,
      filterDate: date,
      experienceName: row.experience_name,
      experienceId: row.experience_id,
      percentComplete: percCompleted,
      activity: row.avg_elements_score,
      activitySort: activitySort,
      responses: resp,
      timeOnTask: timeOnTask,
      timeOnTaskSort: row.period || 0,
      timeOnTaskAccurate: timeOnTaskAccurate,
      quizSort: quizSort,
      quizScore: quiz
    };
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function mapData(data, column) {
    return (data || []).map(function (r) {
      return r[column];
    });
  }

  function countUniqueColumnFn(column) {
    return function (data) {
      return mapData(data, column).filter(onlyUnique).length;
    };
  }

  var summaryTilesGetters = {
    'Experiences': countUniqueColumnFn('experience_id'),
    'Responses': function (data) {
      return mapData(data, 'sum_response_count').reduce(function sumAllResponses(sum, value) {
        return sum + value;
      }, 0);
    },
    'Quiz Score': function (data) {
      var length = data.filter(function (rec) {
        return rec.quiz_score !== null;
      }).length || 1;
      var allScores = mapData(data, 'quiz_score')
        .filter(function (rec) {
          return rec !== null;
        })
        .reduce(function sumAllQuizScores(sum, value) {
          return sum + value;
        }, 0);

      return Math.round(allScores / length);
    }
  };

  function generateSummaryTiles(requestedTiles, data) {
    return requestedTiles.map(function (tile) {
      var value = abbreviateNumber(summaryTilesGetters[tile.name](data));
      if (tile.showPercentageSign) {
        value = value + '%';
      }
      return {
        name: tile.name,
        value: value,
        guide: tile.guide
      };
    });
  }

  function sortAndFilterByStudentAndXPName(data, studentId, xpFilter) {
    return data
      .filter(function (rec) {
        return rec.student_id === studentId && xpFilter.check(rec.experience_name);
      })
      .map(formatExperienceSummaryDataRow);
  }

  function calculateAverage(data, field, notToRound) {
    var divider = data.filter(function (rec) {
      return rec[field] !== null;
    }).length || 1;
    var sum = mapData(data, field)
      .filter(function (rec) {
        return rec !== null;
      })
      .reduce(function sumAllValues(sum, value) {
        return sum + (value || 0);
      }, 0);
    if (notToRound) {
      return sum / divider;
    }
    return Math.round(sum / divider);
  }

  function getAverages(data) {
    var averages = {
      complete: '',
      responses: '',
      activity: '',
      quiz: '',
      time: ''
    };
    averages.complete = Math.round(calculateAverage(data, 'progress', true) * 100) + '%';
    averages.responses = calculateAverage(data, 'sum_response_count');
    averages.activity = calculateAverage(data, 'avg_elements_score');
    averages.quiz = calculateAverage(data, 'quiz_score') + '%';
    var averageTime = calculateAverage(data, 'period', true) / 60;
    if (averageTime && (averageTime > 0) && (averageTime < 1)) {
      averageTime = '< 1';
    } else {
      averageTime = abbreviateNumber(Math.round(averageTime));
    }
    averages.time = averageTime;

    return averages;
  }

  function getFilter($location) {
    var filter = {};
    var query = $location.search();
    filter.class_id = parseInt(query.class_id, 10);
    filter.drilldown = query.drilldown || false;
    filter.student_id = parseInt(query.student_id, 10);
    filter.filter_option = parseInt(query.filter_option, 10);
    filter.teacher_id = parseInt(query.teacher_id, 10);
    return filter;
  }

  function extractStudentsFromData(data) {
    var byId = {};
    data.forEach(function (rec) {
      byId[rec.student_id] = rec;
    });
    return Object.keys(byId)
      .map(function (id) {
        var rec = byId[id];
        return {
          student_id: rec.student_id,
          first_name: rec.student_first_name,
          last_name: rec.student_last_name,
          class_name: rec.class_name
        };
      });
  }

  function getExperienceNameFilters(reportKey) {
    if (reportKey === 'curriculum-student-progress-wordly-wise') {
      return [
        {
          name: 'All Experiences',
          check: function (xpName) {
            return true;
          }
        },
        {
          name: 'Only Tests',
          check: function (xpName) {
            return (xpName || '').toLowerCase().indexOf('test') > -1;
          }
        },
        {
          name: 'Exclude Tests',
          check: function (xpName) {
            return (xpName || '').toLowerCase().indexOf('test') === -1;
          }
        }
      ];
    }
    return [
      {
        name: 'All Experiences',
        check: function (xpName) {
          return true;
        }
      },
      {
        name: 'Only Unit Reviews',
        check: function (xpName) {
          return (xpName || '').toLowerCase().indexOf('review') > -1;
        }
      },
      {
        name: 'Exclude Unit Reviews',
        check: function (xpName) {
          return (xpName || '').toLowerCase().indexOf('review') === -1;
        }
      }
    ];
  }

  function paintAveragesCells() {
    // hack to apply background to filter cells quickly
    var averageCellElements = Array.prototype.slice.call(document.querySelectorAll('[data-averages-cell="true"]'));
    averageCellElements.forEach(function (el) {
      el.parentElement.parentElement.style.backgroundColor = '#FEFCD0';
    });
  }

  var module = angular.module('client.components');

  controller.$inject = ['$location', '$scope', '$log', 'reportToPDF', 'reportToCSV',
    'ActiveMode', 'ExperienceNavigator', 'NgTableParams', 'SavedFilterState', 'ngTableEventsChannel'];

  function controller($location, $scope, $log, reportToPDF, reportToCSV,
                      ActiveMode, ExperienceNavigator, NgTableParams, SavedFilterState, ngTableEventsChannel) {

    var ctrl = this;
    ctrl.lastUpdated = window.moment().subtract(1, 'day').format('MM/DD/YYYY');
    ctrl.xpFilters = getExperienceNameFilters();
    ctrl.xpFilter = ctrl.xpFilters[0];
    ctrl.students = [];
    ctrl.summaryTiles = [];
    ctrl.showSchools = false;
    ctrl.showTeacher = true;
    ctrl.inited = false;
    ctrl.tableParams = new NgTableParams(
      {
        // items per page
        count: 12,
        sorting: {filterDate: "desc"}
      },
      {
        dataset: [],
        // options for page size
        counts: []

      }
    );
    ctrl.reportKind = false;
    ctrl.includeArchived = false;
    ctrl.averages = {
      complete: '',
      responses: '',
      activity: '',
      quiz: '',
      time: ''
    };
    ctrl.showDownloadPDF = true;
    ctrl.downloadPDF = function () {
      reportToPDF.ngReport(ctrl, 'showDownloadPDF')('printable', ctrl.className + ' - ' + ctrl.parent.title + '.pdf');
    };
    ctrl.downloadCSV = reportToCSV(
      function () {
        return [
          {
            name: 'Student',
            field: 'prettyName',
            wrap: true
          },
          {
            name: 'Date',
            field: 'prettyDate',
            wrap: true
          },
          {
            name: 'Experience Name',
            field: 'experienceName',
            wrap: true
          },
          {
            name: '% complete',
            field: 'percentComplete',
            wrap: false
          },
          {
            name: 'Responses',
            field: 'responses',
            wrap: false
          },
          {
            name: 'Average activity score based on first response',
            field: 'activity',
            wrap: false
          },
          {
            name: 'Quiz Score',
            field: 'quizScore',
            wrap: false
          },
          {
            name: 'Time on task (min.)',
            field: 'timeOnTaskAccurate',
            wrap: false
          }
        ];
      },
      function () {
        return ctrl.tableParams.settings().dataset;
      }, function () {
        return ctrl.className + ' - ' + ctrl.parent.title + '.csv';
      },
      ',',
      function titleAndAverages() {
        var titleRow = [
          '',
          '',
          '',
          JSON.stringify(ctrl.parent.title),
          '',
          '',
          '',
          ''
        ];
        return [titleRow];
      }
    );

    ctrl.toggleCourse = function (course) {
      if (course.id !== "All") {
        ctrl.course = course.id;
      } else {
        ctrl.course = undefined;
      }
      ctrl.filterCourse = course;
      updateReport();
    };

    ctrl.onRefresh = function (downloadPDF, downloadCSV, course) {
      if (downloadPDF) {
        ctrl.downloadPDF();
      } else if (downloadCSV) {
        ctrl.downloadCSV();
      } else if (course) {
        ctrl.toggleCourse(course);
      }
    };

    // ng-table event with all data on all pages filtered
    // to get only displayed data user ngTableParams.data or onAfterReloadData event
    ngTableEventsChannel.onAfterDataSorted(function (ngTableDefaults, ngTableParams, updatedData) {
      if (!ctrl.reportData) {
        return;
      }
      var experienceIds = updatedData.map(function (row) {
        return row.experienceId;
      });
      var filteredData = ctrl.reportData.filter(function (rec) {
        return (experienceIds.indexOf(rec.experience_id) > -1) &&
          (rec.student_id === ctrl.selectedStudent.student_id) &&
          ctrl.xpFilter.check(rec.experience_name);
      });
      ctrl.summaryTiles = getSummaryTiles(ctrl.reportKind, filteredData);
      ctrl.averages = getAverages(filteredData);
      paintAveragesCells();
    }, $scope);


    function getSummaryTiles(reportKind, data) {
      var tiles = [
        {
          name: 'Experiences',
          showPercentageSign: false,
          guide: 'generating'
        },
        {
          name: 'Responses',
          showPercentageSign: false,
          guide: 'averaging'
        },
        {
          name: 'Quiz Score',
          showPercentageSign: true,
          guide: false
        }
      ];
      return generateSummaryTiles(
        tiles,
        data
      );
    }

    // Reload any filtering that was previously set for this report
    var savedFilterState = SavedFilterState.getFilter();
    if (savedFilterState) {
      var newFilter = {};
      if (savedFilterState.experienceName) {
        newFilter.experienceName = savedFilterState.experienceName;
      }
      if (savedFilterState.prettyDate) {
        newFilter.prettyDate = savedFilterState.prettyDate;
      }
      if (savedFilterState.prettyName) {
        newFilter.prettyName = savedFilterState.prettyName;
      }
      if (savedFilterState.includeArchived) {
        ctrl.includeArchived = savedFilterState.includeArchived;
      }
      if (savedFilterState.classId) {
        ctrl.classId = savedFilterState.classId;
      }
      if (savedFilterState.drilldown) {
        ctrl.drilldown = savedFilterState.drilldown;
      }
      angular.extend(ctrl.tableParams.filter(), newFilter);
    }

    // Save the filter state when leaving this view.
    // Not ideal since it introduces scope but this will just save the filters once when the user leaves this view
    $scope.$on("$destroy", function () {
      var filters = ctrl.tableParams.filter() || {};
      filters.includeArchived = ctrl.includeArchived;
      filters.filterYear = ctrl.filterYear;
      filters.year = ctrl.year;
      filters.classId = ctrl.classId;
      filters.drilldown = ctrl.drilldown;
      SavedFilterState.setFilter(filters);
    });

    function getUniqueCourseList(data) {
      var idToName = {};
      data.forEach(function (rec) {
        if (rec.collection_uuid) {
          idToName[rec.collection_uuid] = rec.collection;
        }
      });
      var uniqueCourses = Object.keys(idToName).map(function (id) {
        var courseRec = data.find(function (rec) {
          return rec.collection_uuid === id;
        });
        return {
          id: courseRec.collection_uuid,
          name: courseRec.collection
        };
      })
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      return uniqueCourses;
    }

    function updateReport(preselectedStudentId, preselectedXpFilter) {
      return ActiveMode.getReportData({
        include_archived: ctrl.includeArchived,
        year: ctrl.year,
        class_id: ctrl.classId,
        drilldown: ctrl.drilldown,
        course: ctrl.course,
        teacher_id: ctrl.teacher_id
      }).then(function (report) {
        if (report.name && (report.name !== ctrl.parent.title)) {
          ctrl.parent.title = ctrl.parent.title.replace('Student', '').replace(' Progress', '').replace(' Report', '') + report.name;
        }
        var xpFilters = getExperienceNameFilters(report.report_key);
        ctrl.xpFilters = xpFilters;
        ctrl.xpFilter = xpFilters[0];
        ctrl.students = (report.data.students || []);
        if (report.data.data.length && !ctrl.students.length) {
          ctrl.students = extractStudentsFromData(report.data.data);
        }
        ctrl.students = ctrl.students.sort(function (a, b) {
          return a.last_name.localeCompare(b.last_name);
        });
        ctrl.students = ctrl.students.map(function (rec) {
          rec.name = rec.first_name + ' ' + rec.last_name;
          return rec;
        });
        if (preselectedStudentId) {
          ctrl.selectedStudent = ctrl.students.filter(function (rec) {
            return rec.student_id === preselectedStudentId;
          })[0];
        }
        if (preselectedXpFilter) {
          ctrl.xpFilter = ctrl.xpFilters[preselectedXpFilter];
        }
        ctrl.selectedStudent = ctrl.selectedStudent || ctrl.students[0] || {};
        ctrl.className = ctrl.selectedStudent.class_name;
        ctrl.reportKind = report.report_kind;

        ctrl.reportData = report.data.data;

        ctrl.tableParams.settings({
          dataset: sortAndFilterByStudentAndXPName(ctrl.reportData, ctrl.selectedStudent.student_id, ctrl.xpFilter)
        });

        if (!ctrl.courses || ctrl.courses.length === 0) {
          ctrl.courses = getUniqueCourseList(report.data.data);
        }

        ctrl.reportData = report.data.data;

        ctrl.inited = true;

        return report.data.data;
      })
        .catch(function (error) {
          $log.error("error in getting report data:", error);
          ctrl.inited = true;
        });
    }

    var filter = getFilter($location);
    ctrl.classId = filter.class_id;
    ctrl.drilldown = filter.drilldown;
    ctrl.teacher_id = filter.teacher_id;
    updateReport(filter.student_id, filter.filter_option);

    ctrl.toggleStudent = function toggleStudent(selection) {
      ctrl.selectedStudent = selection;
      ctrl.tableParams.settings({
        dataset: sortAndFilterByStudentAndXPName(ctrl.reportData, ctrl.selectedStudent.student_id, ctrl.xpFilter)
      });
    };

    ctrl.toggleXPFilter = function toggleXPFilter(selection) {
      ctrl.xpFilter = selection;
      ctrl.tableParams.settings({
        dataset: sortAndFilterByStudentAndXPName(ctrl.reportData, ctrl.selectedStudent.student_id, ctrl.xpFilter)
      });
    };

    ctrl.goToPastExperience = function (expId) {
      var expUrl = '/experience/' + expId + '/dashboard/responses';
      ExperienceNavigator.navigateToExperience(expUrl, "", $location.path(), {
        class_id: ctrl.classId,
        student_id: ctrl.selectedStudent.student_id,
        drilldown: 'student-summary'
      });
    };
  }

  module.component('studentSummary', {
    require: {parent: '^^xpReport'},
    template: require('./studentSummaryReport.jade'),
    controller: controller
  });

})();
