'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('studentList', ['ChangeStudentPassword', 'CourseFactory', 'ModalService', 'rosterService', 'PermissionConsts', 'userClassPermissions',
   function(ChangeStudentPassword, CourseFactory, ModalService, rosterService, PermissionConsts, userClassPermissions) {
    function link(scope, element, attrs)
    {
      scope.sortIsReversed = false;
      scope.predicate = 'last_name';

      scope.$watch('students', function (value) {
        updateStudentLearningStatus();
      });

      scope.sort = function(sortBy) {
        // if this is the existing sort column then reverse the sort
        if (scope.predicate === sortBy) {
          scope.sortIsReversed = !scope.sortIsReversed;
        }
        else
          scope.sortIsReversed = false;

        // Set the new sorting criteria
        scope.predicate = sortBy;

        // Now perform the actual sort on the list
        scope.students.sort(sortStudents(sortBy, scope.sortIsReversed));
      };

      function updateStudentLearningStatus() {
        if (scope.students && scope.statuses) {
          scope.students.forEach(function(student) {
            if (student.learning_status_id) {
              var status = scope.statuses.find(function(st) { return st.id === student.learning_status_id; });
              if (status) {
                student.learning_status_name = status.learning_status_name;
              }
            }
          });
        }
      }

      rosterService.getLearningStatus(-6).then(function(statuses) {
        statuses.unshift({id: 0, learning_status_name: 'None'});
        scope.statuses = statuses;
      });

      function sortStudents(sortBy, reverse) {
        return function(a, b) {
          if (a && b) {
            return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase()) * (reverse ? -1 : 1);
          }
          else {
            return 0;   // if either is invalid then just return they are equal.  Should never really happen
          }
        }
      }

      scope.selectStatus = function(student, status) {
        rosterService.setLearningStatus(-6, student.id, status.id).then(function(res) {
          student.learning_status_name = status.learning_status_name;
        }).catch(function(error) {
          student.learning_status_name = null;
        });
      };

      // Change the password for the current student
      scope.selectStudent = function(student) {
        ChangeStudentPassword.changePassword(student);
      };

      scope.deleteStudent = function(student) {
        if (scope.remove) {
          scope.remove({user: student});
        }
      };

      scope.addStudent = function(student) {
        if (scope.add) {
          scope.add({user: student});
        }
      };

      scope.hasAssignDistrictIdPermission = function() {
        return userClassPermissions.hasPermission(PermissionConsts.ui_assign_district_id);
      };

      scope.hasAssignStatusPermission = function() {
        return userClassPermissions.hasPermission(PermissionConsts.ui_assign_student_status);
      };

      scope.canRoster = function() {
        return scope.rostering;
      };

      scope.isIntegration = function() {
        return scope.integration;
      }

      scope.addDistrict = function(student) {
        student.checked = false;
        var config = {
            title: 'Add District ID',
            backdrop: 'static',
            template: require('./districtID.jade'),
            student: student,
            check: function(scope, student) {
              student.invalidId = false;
              student.assignedId = false;
              rosterService.checkDistrictId(-6, student.districtId).then(function (result) {
                if (result) {
                  if (result.user_id && result.user_id != student.id) {
                    student.assignedId = true;
                  } else if (result.meta) {
                    student.districtName = result.meta.first_name + " " + result.meta.last_name;
                    student.checked = true;
                  } else {
                    student.invalidId = true;
                  }
                } else {
                  student.invalidId = true;
                }
              }).catch(function(error) {
                student.invalidId = true;
                student.checked = true;
              });
            },
            cancel: function (scope, student) {
              scope.modal.hide();
            },
            save: function (scope, student) {
              if (student.districtId) {
                rosterService.saveDistrictId(-6, student.id, student.districtId).then(function (result) {
                  student.lms_source = student.districtId;
                  scope.modal.hide();
                });
              }
            }
          };

          // Show the current student dialog
          ModalService.show(config);
      };

      scope.deleteDistrict = function(student) {
        ModalService.show(
            {
              title: 'Delete District ID',
              message: 'Are you sure you want to delete district id ' + student.lms_source + ' from student ' + student.first_name + ' ' + student.last_name + '?',
              buttons: [
                {
                  title: 'Cancel',
                  class: 'btn-default',
                  click: '$hide()'
                },
                {
                  title: 'Delete',
                  click: 'deleteDistrictId(); $hide()'
                }
              ],
              deleteDistrictId: function deleteDistrictId() {
                rosterService.deleteDistrictId(-6, student.id).then(function (result) {
                  if (result) {
                    student.lms_source = null;
                    student.districtId = null;
                  }
                });
              }
            }
          );
      };
    }

    return {
      replace: true,
      restrict: 'E',
      template: require("./studentList.jade"),
      link: link,
      scope: {
        view: '@',
        students: '=',
        courseName: '=',
        remove: '&',
        add: '&',
        predicate: '@',
        rostering: '=',
        integration: '='
      }
    };

  }]);
})();