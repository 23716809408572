'use strict';

angular.module('client.services').factory('LicenseValidation', ['api_server', '$resource', function (api_server, $resource) {
  return $resource(api_server + '/subscriptions/validate/:subid', {subid: '@subid'}, {
    post: {
      method: 'POST',
      isArray: true,
      cache: true,
      params: {subid: '@subid'}
    }
  });
}]);
