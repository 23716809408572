'use strict';
angular.module('xp-element-poll', ['angularWidget', 'client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'client.directives'])
  .controller('clientPollElementCtrl', ['$scope', '$log','widgetConfig', 'ElementsRestService', 'ElementsErrorService', 'JSONStringUtility', 'ElementUtilities',
                                        'SHARE_MODE', 'GATE_MODE', '$sce', '$q', 'ActiveExperience',
  function ($scope, $log, widgetConfig, ElementsRestService, ElementsErrorService, JSONStringUtility, ElementUtilities, SHARE_MODE, GATE_MODE, $sce, $q, ActiveExperience) {
  $scope.options = widgetConfig.getOptions($scope);
  $scope.DISPLAY_MODE = Object.freeze({kTeacherMode : 0, kStudentModeRead : 1, kStudentModeWrite : 2});
  $scope.elementInitialized = false;

    $scope.tabularData = [];

  function resetElement()
  {
    $scope.instructions = {};
    $scope.choicesData = {};
    $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeRead;
    $scope.form = {selection: []};
    $scope.gateMode = GATE_MODE.XPGateModeGated;
    $scope.myAnswer = null;
    $scope.totalNumberOfStudents = -1;
    $scope.multiple_allowed = false;
    $scope.maxSelect = 0;
    $scope.share = SHARE_MODE.GROUP;
    $scope.selectedRespondent = 0;
    $scope.isTeacher = false;
    $scope.portionResponded = 0;
    $scope.enumerator = 'letter';
    $scope.hasCorrectAnswers = false;
    $scope.alignment = "text-left";
    $scope.correctChoices = 0;
  }

  resetElement();

  function isPoll() {
    return $scope.options.element.type == 'poll';
  }

  function isChoice() {
    return $scope.options.element.type == 'choice' || $scope.options.element.type == 'quiz_choice';
  }

  function isQuizChoice() {
    return $scope.options.element.type == 'quiz_choice';
  }

  $scope.invertInstructions = function() {
    return isChoice();
  };

  $scope.hasResponses = function() {
    return $scope.myAnswer !== null;
  };

  $scope.isTeacherViewingInactiveExperience = function() {
    if ($scope.options.context !== undefined)
      return $scope.options.context.getViewingInactiveExperience() &&
          $scope.options.context.userIsTeacher() &&
          $scope.tabularData.length > 0 &&
          !$scope.filteredStudent &&
          $scope.options.studentId === undefined;
    else
      return false;
  };

  $scope.canEditForRespondent = function(respondentId) {
    if (!$scope.options.context)
      return false;

    if ($scope.options.context.isPreview) {
      return false;
    }

    return ($scope.isTeacher ||
            (!$scope.isTeacher && $scope.myAnswer != null)) &&
            !$scope.options.context.getViewingInactiveExperience() &&
            !$scope.options.quiz;
  };

  $scope.showAnswers = function() {
    return ActiveExperience.currentExperience() &&
            (!$scope.isTeacher ||
            ($scope.isTeacher && !ActiveExperience.currentExperience().hideTeacherNotes && $scope.displayMode != $scope.DISPLAY_MODE.kStudentModeWrite));
  };

  function parseElement() {
    var element = $scope.options.element;
    var options = [];

    element.config.attributes.forEach(function(attribute)
      {
        var name = attribute.name;
        var value = attribute.value;
        switch (name)
        {
          case "question" :
            $scope.instructions.question = $sce.trustAsHtml(value);
            break;
          case "question_image_url" :
            $scope.instructions.imageUrl = ElementUtilities.getElementURL(element, $scope.options.context.experienceId, value);
            break;
          case "multiple_allowed" :
            $scope.multiple_allowed = value === "true" || value === true;
            break;
          case "max_selections" :
            $scope.maxSelect = parseInt(value, 10);
            break;
          case "share" :
            $scope.share = value;
            break;
          case "enumerator" :
            $scope.enumerator = value;
            break;
          case "image_alignment" :
            if (value == "center")
              $scope.alignment = "text-center";
            else if (value == "right")
              $scope.alignment = "text-right";
          break;
          case "options" :
            if (!(value instanceof Array))
            {
              value =
                [
                  {
                    name: "option",
                    value: value.option
                  }
                ];
            }
            options = value;
            break;
        }
      });

    var optionIndex = 0;
    options.forEach(function(option) {
      if (option.name == "option") {
        if (!$scope.choicesData.options) {
          $scope.choicesData.options = [];
        }

        $scope.choicesData.options.push(
          {
            text : $sce.trustAsHtml(option.value.text),
            id : optionIndex,
            imageUrl: option.value.option_image_url,
            isCorrect : option.value.correct && (option.value.correct === "true" || option.value.correct === true),
            code : getEnumerator(optionIndex),
            numberOfAnswersForOption : 0,
            respondentIds : {},
            respondents : [],
            displayRespondents : false
          });
        optionIndex = optionIndex + 1;

        // Keep indicator to determine if any options are marked as correct
        $scope.hasCorrectAnswers = $scope.hasCorrectAnswers ||
          (option.value.correct && (option.value.correct === "true" || option.value.correct === true));

        // Keep track of the number of correct answers for multiple select type choice
        if (option.value.correct && (option.value.correct === "true" || option.value.correct === true)) {
          $scope.correctChoices = $scope.correctChoices + 1;
        }
      }
    });
  }

  function getEnumerator(index) {
    switch ($scope.enumerator)
    {
      case 'number':
        return (index + 1) + ")";
      case 'bullet':
        return String.fromCharCode(8226);
      case 'none':
        return "";
    }
    // implies letter or unset
    return String.fromCharCode(65 + index) + ")";
  }

  function getStudentName(user_id) {
    var name = user_id;

    // Search for the matching student name in the clazz
    $scope.options.context.clazz.students.forEach(function(student){
      if (student.uid === user_id)
        name = student.displayName;
    });

    return name;
  }

  function processSharedState(result, firstTime) {
    var context = $scope.options.context;
    $scope.tabularData = [];

    // Filter out any records that exist but don't contain actual data
    $scope.choicesData.allAnswers = result.filter(function(answer) {
      return answer.user_data;
    });

    var options = $scope.choicesData.options;
    options.forEach(function(option){
      option.numberOfAnswersForOption = 0;
      option.respondentIds = {};
      option.respondents = [];
      option.displayRespondents = false;
      option.percentage = 0;
    });

    $scope.myAnswer = null;
    if (result instanceof Array && result.length) {
      var totalResponses = 0;
      $scope.filterAnswers(result).forEach(function(answer){
        answer.user_data = JSONStringUtility.parse(answer.user_data);

        if ($scope.options.studentId === undefined || $scope.options.studentId === answer.user_id) {
          if (answer.user_data && answer.user_data.selection && answer.user_data.selection instanceof Array) {
            answer.user_data.selection.forEach(function(optionId, answerIndex){
              if ($scope.isUsingSmallGroups()) {
                if (!options[optionId].respondentIds[answer.small_gid]) {
                  options[optionId].numberOfAnswersForOption = options[optionId].numberOfAnswersForOption + 1;
                  totalResponses = totalResponses + 1;
                }
              } else {
                options[optionId].numberOfAnswersForOption = options[optionId].numberOfAnswersForOption + 1;
                if (answerIndex === 0) {
                  totalResponses = totalResponses + 1;
                }
              }
              options[optionId].respondentIds[$scope.isUsingSmallGroups() ? answer.small_gid : answer.user_id] = true;

              // See if this student already has a row
              var found = false;
              $scope.tabularData.forEach(function(tabrow){
                if (tabrow.uid == answer.user_id) {
                  tabrow.Response += ", " + (options[optionId].code ? options[optionId].code : "") + " " + (options[optionId].text ? options[optionId].text : "");
                  if (options[optionId].isCorrect && $scope.correctChoices) {
                    tabrow.score = tabrow.score + (100 / answer.user_data.selection.length);
                  }
                  found = true;
                }
                else if (answer.small_gid && tabrow.gid == answer.small_gid) {
                  found = true;
                }
              });

              // Store values for tabular display
              if (!found) {
                var tabularRow = {};
                tabularRow.Student = getStudentName(answer.user_id);
                tabularRow.gid = answer.small_gid ? answer.small_gid : null;
                tabularRow.uid = answer.user_id;
                tabularRow.score = options[optionId].isCorrect && $scope.correctChoices ? (100 / $scope.correctChoices) : 0;
                tabularRow.isScored = $scope.hasCorrectAnswers;
                tabularRow.Response = (options[optionId].code ? options[optionId].code : "") + " " + (options[optionId].text ? options[optionId].text : "");
                $scope.tabularData.push(tabularRow);
              }
            });
          }
        }

        if (($scope.isUsingSmallGroups() && answer.small_gid == context.groupId) || (!$scope.isUsingSmallGroups() && answer.user_id == context.userId)) {
          $scope.myAnswer=answer;
        }
      });

      if (context.userIsTeacher() && totalResponses > 0) {
        options.forEach(function(option){
          option.percentage = Math.round((option.numberOfAnswersForOption * 100) / totalResponses);
        });
      }

      if ($scope.isUsingSmallGroups()) {
        $scope.portionResponded = totalResponses / context.clazz.smallGroups;
      }
      else if (context.clazz.students.length) {
        $scope.portionResponded = totalResponses / context.clazz.students.length;
      }
      else {
        $scope.portionResponded = 0;
      }
    }

    var cachedResponse = false;
    if ($scope.cached) {
      var cachedValue = $scope.cached({elementId: $scope.options.element.id});
      if (cachedValue) {
        $scope.form.selection = cachedValue;
        $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeWrite;
        cachedResponse = true;
      }
    }

    if ($scope.myAnswer !== null && $scope.myAnswer.user_data && $scope.myAnswer.user_data.selection && !cachedResponse) {
      var selection = $scope.form.selection;
      selection.length = 0;
      selection.push.apply(selection, $scope.myAnswer.user_data.selection);
    }

    if (context.userIsTeacher()) {
      options.forEach(function(option){
        if ($scope.isUsingSmallGroups()) {
          for (var i = 1; i <= context.clazz.smallGroups + 1; ++i) {
            if (option.respondentIds.hasOwnProperty(i)) {
              if (i === context.clazz.smallGroups + 1) {
                option.respondents.push({id: 'T', displayName: "Teacher"});
              } else {
                option.respondents.push({id: i, displayName: "Small Group " + i});
              }
            }
          }
          option.respondents.sort(function(a, b) {return a.displayName.localeCompare(b);});
        }
        else {
          context.clazz.teachers.forEach(function(teacher){
            if (option.respondentIds.hasOwnProperty(teacher.uid)) {
              option.respondents.push(teacher);
            }
          });
          context.clazz.students.forEach(function(student){
            if (option.respondentIds.hasOwnProperty(student.uid)) {
              option.respondents.push(student);
            }
          });
          option.respondents.sort(function(a, b) {return a.displayName.localeCompare(b);});
        }
        option.displayRespondents = option.respondents.length > 0;
      });
    }

    // If the student has already answered (and this is not a quiz) then they must pick the edit menu to change their response
    $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeRead;
    if (context.userIsStudent() && ($scope.myAnswer === null || $scope.options.quiz || cachedResponse) && !$scope.options.context.getViewingInactiveExperience()) {
      $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeWrite;
    }

    if (firstTime) {
      // Notify the widget that were are done loading the data
      widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
    }
  }

  function loadAnswers(firstTime) {
    if (!$scope.options.element.id)
      return;

    var isInactive = $scope.options.context.getViewingInactiveExperience();

    if (isInactive && isQuizChoice()) {
      ElementsRestService.getLastValidSharedQuizState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.groupName, isInactive,
          function(result) {
            processSharedState(result, firstTime);
          },
          function(error) {
            ElementsErrorService.error(error);
        });
    } else {
      ElementsRestService.getSharedState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.groupName, isInactive,
        function(result) {
          processSharedState(result, firstTime);
        },
        function(error) {
          ElementsErrorService.error(error);
      });
    }
  }

  function stateChangedNotificationHandler(e) {
    var state = e.detail;
    if (state.record.element_id != $scope.options.element.id) return;
    if (state.record.user_id == $scope.options.context.userId) return;

    $log.debug ("Received poll state update: " + JSON.stringify(state));

    loadAnswers(false);
  }

  function handleExperienceUpdatedNotification(e) {
    var experience = e.detail;
    $log.debug ("Received (poll) experience update: " + JSON.stringify(experience));

    ElementsRestService.getExperienceStudentsNumber($scope.options.context.experienceId,
        function(result) {
          $scope.totalNumberOfStudents = result;
        },
        function(error){
          ElementsErrorService.error(error);
        });
  }

  $scope.$watch('options', function(newValue, oldValue, scope) {
    var options = $scope.options;
    if (!options.element)
      return;

    // if already initialized then return
    if ($scope.elementInitialized) {
      return;
    }

    // Reset element to initial state
    resetElement();

    var service = options.elementRealtimeService;
    var EVENTS = service.EVENTS;
    var context = options.context;
    parseElement();

    $scope.isTeacher = context.userIsTeacher();

    if (isPoll() || (isChoice() && (context.userIsTeacher() || $scope.isUsingSmallGroups())))
    {
      service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
      $scope.$on('$destroy', function(){
        service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
      });
    }

    if (isPoll())
    {
      service.on(EVENTS.XPExperienceUpdatedNotification, handleExperienceUpdatedNotification);
      $scope.$on('$destroy', function(){
        service.removeListener(EVENTS.XPExperienceUpdatedNotification, handleExperienceUpdatedNotification);
      });
    }

    $scope.displayMode = context.userIsTeacher() ? $scope.DISPLAY_MODE.kTeacherMode : $scope.DISPLAY_MODE.kStudentModeRead;

      if ($scope.totalNumberOfStudents == -1) // only for the first time (redundant but just in case)
          $scope.totalNumberOfStudents = context.clazz.students.length; // here the class must be updated already

    $scope.selectedRespondent = $scope.isUsingSmallGroups() ? context.groupId : context.userId;

    loadAnswers(true);

    // mark this element as initialized
    $scope.elementInitialized = true;
  }, true);

  $scope.$watch('savingElementId', function(newValue, oldValue) {
    // Submit the response selected in this element
    if (newValue && newValue.elementId === $scope.options.element.id && $scope.form.selection){
      $scope.didSubmit(newValue);
    }
  }, true);

  function checkResponses(selections) {
    var allCorrect = true;
    var someCorrect = false;

    // Iterate over the responses and see if each correctly matches the correct response
    $scope.choicesData.options.forEach(function(option, index) {
      // See if this matches the nth value in the source
      if ((option.isCorrect && selections.indexOf(option.id) === -1) ||
          (!option.isCorrect && selections.indexOf(option.id) !== -1)) {
        allCorrect = false;
      }
      else if (option.isCorrect && selections.indexOf(option.id) !== -1) {
        someCorrect = true;
      }
    });

    return allCorrect ? 1 : (someCorrect ? -1 : 0);
  }

  $scope.didSubmit = function(savingElement) {
    var groupId = $scope.options.context.getPostingGroupId($scope.isUsingSmallGroups());

    // show the correct alert if student facing feedback is enabled
    if ($scope.options.context.getStudentFacingFeedback() && $scope.hasCorrectAnswers && !$scope.options.quiz) {
      $scope.assessmentStatus = checkResponses($scope.form.selection);
    }

    // Save the new userstate information to the server
    ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.userId, groupId,
                                      JSON.stringify({"selection" : $scope.form.selection}), function() {
      // Calls back to quiz letting it know the score has been saved
      if ($scope.scoreSaved) {
        $scope.scoreSaved({ finished: (savingElement && savingElement.finished)});
      }
      if ($scope.changed) {
        $scope.changed({elementId: $scope.options.element.id, selection: null});
      }
      loadAnswers(false);
    },
    function(error) {
      ElementsErrorService.error(error);
    });
  };

  $scope.getEditMenuItemsForUser = function(respondentId)
  {
    var menuOptions =
    [
      {
        text: '<div class="xp-element-menu-edit">Edit</div>',
        click: 'requestEdit("' + respondentId +'")'
      }
    ];

    if ($scope.displayMode == $scope.DISPLAY_MODE.kStudentModeWrite)
    {
      menuOptions =
      [
        {
          text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
          click: 'cancelEdit("' + respondentId +'")'
        }
      ];
    }

    return menuOptions;
  };

  $scope.oldResponse = null;

  $scope.requestEdit = function(respondentId) {
    // reset the assessment status flag to hide the alert if it is showing
    $scope.assessmentStatus = false;

    // Save the current responses in case the user cancels
    $scope.oldResponse = $scope.form.selection.concat();

    // Set mode so input is enabled
    $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeWrite;
  };

  $scope.cancelEdit = function(respondentId) {
    $scope.form.selection = $scope.oldResponse.concat();

    $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeRead;
  };

  $scope.shouldShowResults = function () {
    if (!$scope.options || !$scope.options.context) {
      return false;
    }

    if (isChoice()) {
      return $scope.options.context.userIsTeacher();
    }

    if (isPoll()) {
      if ($scope.options.context.userIsTeacher() || $scope.gateMode == GATE_MODE.XPGateModeUngated) {
            return $scope.choicesData.allAnswers && $scope.choicesData.allAnswers.length > 0;
        }
        else {
            return $scope.myAnswer !== null && $scope.myAnswer !== undefined;
        }
    }

    return false;
  };

  $scope.numberOfRespondentsWhoSubmitted = function() {
    if (!$scope.choicesData || !$scope.choicesData.allAnswers) {
      return 0;
    }

    if ($scope.isUsingSmallGroups()) {
      var respondedGroupIds = [];
      $scope.choicesData.allAnswers.forEach(function(answer) {
        if (!respondedGroupIds.includes(answer.small_gid)) {
          respondedGroupIds.push(answer.small_gid);
        }
      });
      return respondedGroupIds.length;
    } else {
      return $scope.choicesData.allAnswers.length;
    }
  };

  $scope.progressDisplayTextForChoiceView = function()
  {
      if (!$scope.options || !$scope.shouldShowResults())
          return null;

      var totalCount = $scope.isUsingSmallGroups() ? $scope.options.context.clazz.smallGroups : $scope.totalNumberOfStudents;
      var submittedCount = $scope.numberOfRespondentsWhoSubmitted();
      if (submittedCount === 0)
          return '' + totalCount + ' yet to respond';
      if (totalCount > submittedCount)
      {
          var unsubmittedCount = totalCount - submittedCount;
          var percent = 100 * (unsubmittedCount/totalCount) ;

          return unsubmittedCount + ' yet to respond';
      }
      else
      {
          return 'All ' + submittedCount + ' ' + ($scope.isUsingSmallGroups() ? 'groups' : 'students') + ' have responded';
      }
  };

  $scope.selectedOption = function() {
    if ($scope.selectionMade) {
      $scope.selectionMade({selected: true});
    }
    if ($scope.changed) {
      $scope.changed({elementId: $scope.options.element.id, selection: $scope.form.selection});
    }
  };

  $scope.toggleCheck = function(id, event) {
    if (!$scope.multiple_allowed) {
      $scope.form.selection = [id];
    } else {
      if ($scope.form.selection.includes(id)) {
        $scope.form.selection = $scope.form.selection.filter(function(response) { return response !== id; });
      } else {
        if (!$scope.maxSelect || $scope.form.selection.length < $scope.maxSelect) {
          $scope.form.selection.push(id);
        }
        else {
          event.preventDefault();
        }
      }
    }

    if ($scope.changed) {
      $scope.changed({elementId: $scope.options.element.id, selection: $scope.form.selection});
    }

    // Let the quiz know that at least one response is input
    if ($scope.selectionMade) {
      $scope.selectionMade({selected: $scope.form.selection.length > 0 ? true : false});
    }
  };

  $scope.numberOfAnswersForOption = function(id)
  {
    var answers = $scope.choicesData.allAnswers;
    var count = 0;
    answers.forEach(function(value){
      if (value.user_data && value.user_data.indexOf(id) !== -1) {
        count = count + 1;
      }
    });
    return count;
  };

  function displayStudentFacingFeedback() {
    // if this is a student they are not editing and they are viewing their own data
    return (($scope.options.context.getStudentFacingFeedback() || $scope.options.context.getViewingInactiveExperience()) &&
            !$scope.isTeacher &&
            isChoice() && $scope.hasCorrectAnswers &&
            $scope.displayMode == $scope.DISPLAY_MODE.kStudentModeRead &&
            $scope.myAnswer);
  }

  function studentSelectedOption(option) {
    return $scope.myAnswer &&
            $scope.myAnswer.user_data &&
            $scope.myAnswer.user_data.selection &&
            $scope.myAnswer.user_data.selection.indexOf(option.id) != -1;
  }

  $scope.showCorrectIndicator = function(option) {
    if (!option.isCorrect) {
      return false;
    }
    else if ($scope.isTeacher || (displayStudentFacingFeedback() && studentSelectedOption(option))) {
     return true;
    }
  };

  $scope.showCorrectAnswer = function(option) {
    if (!option.isCorrect) {
      return false;
    }
    else if ($scope.options.context.getViewingInactiveExperience() && ($scope.isTeacher ||
        (displayStudentFacingFeedback() && !studentSelectedOption(option)))) {
     return true;
    }
  };

  $scope.showInCorrectIndicator = function(option) {
    if (!$scope.isTeacher && displayStudentFacingFeedback() && $scope.displayMode == $scope.DISPLAY_MODE.kStudentModeRead &&
        !option.isCorrect && studentSelectedOption(option)) {
      return true;
    }
    else {
      return false;
    }
  };

  $scope.onRetry = function() {
    $scope.requestEdit();
  };

  $scope.isUsingSmallGroups = function()
  {
    return SHARE_MODE.isUsingSmallGroups($scope.share);
  };

  $scope.respondentIsUser = function(respondentId)
  {
    return !$scope.isUsingSmallGroups() || respondentId === 0;
  };

  $scope.respondentIsGroup = function(respondentId)
  {
    return !$scope.respondentIsUser(respondentId);
  };
}]);
