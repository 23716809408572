'use strict';

angular.module('client.services').factory('adminConsoleUsersService', ['$log', '$location', '$timeout', 'ImportValidation', 'importTeachersClassesStudents',
                                                                   'ActiveMode', 'xpAlert', 'ModalNavigation', 'LicenseValidation', 'CourseFactory',
  function ($log, $location, $timeout, ImportValidation, importTeachersClassesStudents,
              ActiveMode, xpAlert, ModalNavigation, LicenseValidation, CourseFactory) {

  function campusHeaders() {
    return [
      {
        name: 'ID',
        field: 'id',
        wrap: true
      },
      {
        name: 'Name',
        field: 'name',
        wrap: true
      },
      {
        name: 'Address',
        field: 'address',
        wrap: true
      },
      {
        name: 'city',
        field: 'city',
        wrap: false
      },
      {
        name: 'subsriptions',
        field: 'subscriptions',
        wrap: false
      }
    ]
  }

  function teacherErrorHeaders() {
    return [
      {
        name: 'schoolId',
        field: 'schoolId',
        wrap: true
      },
      {
        name: 'givenName',
        field: 'givenName',
        wrap: true
      },
      {
        name: 'familyName',
        field: 'familyName',
        wrap: true
      },
      {
        name: 'email',
        field: 'email',
        wrap: false
      },
      {
        name: 'password',
        field: 'password',
        wrap: false
      },
      {
        name: 'errors',
        field: 'errors',
        wrap: false
      }
    ];
  }

  function classErrorHeaders() {
    return [
      {
        name: 'teacher e-mail',
        field: 'teacher',
        wrap: true
      },
      {
        name: 'class name',
        field: 'classname',
        wrap: true
      },
      {
        name: 'first name',
        field: 'firstname',
        wrap: true
      },
      {
        name: 'last name',
        field: 'lastname',
        wrap: false
      },
      {
        name: 'username',
        field: 'username',
        wrap: false
      },
      {
        name: 'password',
        field: 'password',
        wrap: false
      },
      {
        name: 'email',
        field: 'email',
        wrap: false
      },
      {
        name: 'errors',
        field: 'errors',
        wrap: false
      }
    ];
  }

  function isCSVFile(file) {
    if (!file.name.endsWith(".csv")) {
      return false;
    }
    else {
     return true;
    }
  }

  function fileContentsNoHeader(fileContents) {
    // Look for the first Line Feed.  If this file was created on the Mac, it will only have a carriage return so look for that if no line feed
    var endOfFirstLine = fileContents.indexOf("\n");
    if (endOfFirstLine == -1)
      endOfFirstLine = fileContents.indexOf("\r");

    // The data to import is everything after the header line
    return fileContents.substring(endOfFirstLine + 1);
  }

  function importTeacherFile(fileContents, subscriptionId, schools, currentSchool, returnToSrcURL) {
    // The data to import is everything after the header line
    var teachercsv = fileContentsNoHeader(fileContents);

    // Validate the contents from the file
    return parseAndValidateTeachers(teachercsv, subscriptionId, schools, currentSchool, returnToSrcURL);
  }

  function parseAndValidateTeachers(teachercsv, subscriptionId, schools, currentSchool, returnToSrcURL) {
    var isDistrictFile = schools && schools.length;

    // Get the import and convert it to JSON to work with
    var content = {csv: teachercsv ? teachercsv : "", separator: ",", header: true };
    if (isDistrictFile) {
      content.districtTeacherImport = true;
    } else {
      content.teacherImport = true;
    }
    var teachers = ImportValidation.convertToJSON(content);

    // Check the individual teachers for validity
    if (isDistrictFile) {
      return validateAllDistrictTeachers(teachers, subscriptionId, schools, returnToSrcURL);
    } else {
      return validateAllSchoolTeachers(teachers, subscriptionId, currentSchool, returnToSrcURL);
    }
  }

  function validateAllDistrictTeachers(teachers, subscriptionId, schools, returnToSrcURL) {
    return ImportValidation.validateDistrictImportTeachers(teachers, schools).then(function(teachers){
      // Check for any errors in the teachers
      var teacherErrors = getTeacherErrors(teachers);
      if (ImportValidation.checkForErrors(teacherErrors)) {
        return {hasErrors: true, teachers: teacherErrors};
      }

      return importTeachersClassesStudents.importDistrictTeachers({}, { subscriptionId: subscriptionId, 'teachers': angular.toJson(teachers)})
      .$promise.then(function(result) {
        if (result && (result.error || result.isError)) {
          xpAlert.error("Something went wrong, please try again later.", true);
          return {};
        } else if (!returnToSrcURL) {
          xpAlert.success("You have successfully created the teacher accounts. Please continue to phase 2 to import classes and students.", true);
        } else if (returnToSrcURL) {
          var backDestination = ModalNavigation.getBackDestination();
          if (backDestination) {
            $location.path(backDestination);
          }
        }
      })
      .catch(function(error){
        $log.error("An error occurred importing these teachers. " + error);
        xpAlert.error("Something went wrong, please try again later.", true);
      });
    });
  }

  function validateAllSchoolTeachers(teachers, subscriptionId, currentSchool, returnToSrcURL) {
    var schoolTeachers = teachers.map(function(teacher) { teacher.schoolId = currentSchool; return teacher; });
    return ImportValidation.validateImportTeachers(schoolTeachers, subscriptionId).then(function(teachers){
      // Check for any errors in the teachers
      var teacherErrors = getTeacherErrors(teachers);
      if (ImportValidation.checkForErrors(teacherErrors)) {
        return {hasErrors: true, teachers: teacherErrors};
      }

      return importTeachersClassesStudents.importTeachers({}, { subid: subscriptionId, 'teachers': angular.toJson(teachers)})
      .$promise.then(function(result) {
        if (result && (result.error || result.isError)) {
          xpAlert.error("Something went wrong, please try again later.", true);
          return {};
        } else if (!returnToSrcURL) {
          xpAlert.success("You have successfully created the teacher accounts. Please continue to phase 2 to import classes and students.", true);
        } else if (returnToSrcURL) {
          var backDestination = ModalNavigation.getBackDestination();
          if (backDestination) {
            $location.path(backDestination);
          }
        }
      })
      .catch(function(error){
        $log.error("An error occurred importing these teachers. " + error);
        xpAlert.error("Something went wrong, please try again later.", true);
      });
    });
  }

  function getTeacherErrors(teachers) {
    return teachers.map(function(teacher){
      teacher.errors = "";
      if (teacher.school_error) {
        teacher.errors = teacher.school_error;
      }
      if (teacher.firstname_error) {
        if (teacher.errors && teacher.errors.length) {
          teacher.errors = teacher.errors + ", ";
        }
        teacher.errors = teacher.errors + teacher.firstname_error;
      }
      if (teacher.lastname_error) {
        if (teacher.errors && teacher.errors.length) {
          teacher.errors = teacher.errors + ", ";
        }
        teacher.errors = teacher.errors + teacher.lastname_error;
      }
      if (teacher.email_error) {
        if (teacher.errors && teacher.errors.length) {
          teacher.errors = teacher.errors + ", ";
        }
        teacher.errors = teacher.errors + teacher.email_error;
      }
      if (teacher.password_error) {
        if (teacher.errors && teacher.errors.length) {
          teacher.errors = teacher.errors + ", ";
        }
        teacher.errors = teacher.errors + teacher.password_error;
      }
      return teacher;
    });
  }

  function importClassFile(fileContents, subscriptionId) {
    // The data to import is everything after the header line
    var studentcsv = fileContentsNoHeader(fileContents);

    // Validate the contents from the file
    return parseAndValidateStudents(studentcsv, subscriptionId);
  }

  function parseAndValidateStudents(studentcsv, subscriptionId) {
    // Get the import and convert it to JSON to work with
    var content = {csv: studentcsv ? studentcsv : "", separator: ",", header: true, classImport: true };
    var students = ImportValidation.convertToJSON(content);

    // if the teachers and classes are valid then move to the next screen
    return validateTeachersAndClasses(students).then(function(validClasses) {
      if (validClasses) {
        // Check to make sure all the teachers have access to the current license subscription
        return validateAvailableLicenses(students, subscriptionId).then(function(validLicenses) {
          // if the teachers all had access to this license then move to the next screen
          if (validLicenses) {
            // Check the individual students for validity
            return validateAllStudents(students, subscriptionId);
          }
        });
      }
    });
  }

  function validateTeachersAndClasses(students) {
    return ImportValidation.validateTeachersClasses(students).then(function(errorMsg){
      if (errorMsg.length > 0) {
        xpAlert.error(errorMsg, true);
        return false;
      }
      return true;
    });
  }

  function validateAvailableLicenses(students, subscriptionId) {
    // Get a unique list of teacher names contained in the import file
    var teacherNames =  students.map(function(student) {
      return student.teacher;
    });
    var uniqueTeachers = teacherNames.filter(function(name, index, self) {
      return self.indexOf(name) === index;
    });

    // Call the service to see if the user has the licenses
    return LicenseValidation.post({'subid': subscriptionId, 'teachers': uniqueTeachers}).$promise.then(function(teachers) {
      // Iterate the results to see if each teacher has access
      var errors = "";
      teachers.forEach(function(teacher) {
        // if any error then add it to display string
        if (teacher.status === 'error') {
          errors += errors.length > 0 ? " and " : "";
          errors += "Teacher " + teacher.teacher + " does not have access to the current subscription.";
        }
      });

      // if there are any teachers that don't support this license plan then inform the user
      if (errors.length > 0) {
        xpAlert.error(errors, true);
        return false;
      }
      else {
        return true;
      }
    });
  }

  function validateAllStudents(students, subscriptionId) {
    return ImportValidation.validateImportStudents(students, true).then(function(students){

      // Check for any errors in the students
      var studentErrors = getStudentErrors(students);
      if (ImportValidation.checkForErrors(studentErrors)) {
        return {hasErrors: true, students: studentErrors};
      }

      return importTeachersClassesStudents.import({}, { 'subid': subscriptionId, 'students': angular.toJson(students)})
      .$promise.then(function(result) {
        if (result && result.name && result.name === 'license_error' && result.information) {
          xpAlert.success("Success! Classes and students added. But you have exceeded your " + result.information.subscriptionName + " seat licenses by " + result.information.exceeded +
                          " seats. <a href=\"http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers\" target=blank> Learn how</a> to correct this or " +
                          "<a href=\"" + result.information.admin_bs_url + "\" target=blank> " + result.information.admin_bs_msg + "</a>", true);
          return {};
        } else if (result && result.name && result.name === "class_size") {
            return CourseFactory.maxStudentsMsg().then(function(msg) {
              xpAlert.error(msg, true);
              return {};
            });
        } else if (result && result.name && result.message) {
            xpAlert.error(result.message, true);
            return {};
        } else if (result && result.error) {
            xpAlert.error("Something went wrong, please try again later.", true);
            return {};
        }
        else {
          var backDestination = ModalNavigation.getBackDestination();
          if (backDestination) {
            $location.path(backDestination);
            $timeout(function() {
              xpAlert.success("Success! Classes and students were added.", true);
            });
          }
        }
      })
      .catch(function(error){
        $log.error("An error occurred importing these students. " + error);
        xpAlert.error("Something went wrong, please try again later.", true);
        return {};
      });
    });
  }

  function getStudentErrors(students) {
    return students.map(function(student){
      student.errors = "";
      if (student.firstname_error) {
        student.errors = student.errors + student.firstname_error;
      }
      if (student.lastname_error) {
        if (student.errors && student.errors.length) {
          student.errors = student.errors + ", ";
        }
        student.errors = student.errors + student.lastname_error;
      }
      if (student.username_error) {
        if (student.errors && student.errors.length) {
          student.errors = student.errors + ", ";
        }
        student.errors = student.errors + student.username_error;
      }
      if (student.password_error) {
        if (student.errors && student.errors.length) {
          student.errors = student.errors + ", ";
        }
        student.errors = student.errors + student.password_error;
      }
      if (student.email_error) {
        if (student.errors && student.errors.length) {
          student.errors = student.errors + ", ";
        }
        student.errors = student.errors + student.email_error;
      }
      return student;
    });
  }


  return {
    campusHeaders: campusHeaders,
    teacherErrorHeaders: teacherErrorHeaders,
    classErrorHeaders: classErrorHeaders,
    importTeacherFile: importTeacherFile,
    isCSVFile: isCSVFile,
    importClassFile: importClassFile,
  };
}]);
