'use strict';

//----------------------------------------------------------
//
// Used to retrieve classes for the specified teacher id or student username
//
// ----------------------------------------------------------
angular.module('client.services').factory('CourseAPI', ['$resource', 'api_server',
  function ($resource, api_server) {
    var url = api_server + '/classes';
    return $resource(url, {
      cid: '@cid',
      teacher_user_id: '@teacher_user_id',
      student_username: '@student_username',
      remove_students: '@remove_students'
    }, {
      list: { // Internal user only - use CourseFactory.list instead so we benefit from the broadcast;
        method: 'GET',
        isArray: true,
        params: {
          teacher_user_id: '@teacher_user_id',
          student_username: '@student_username'
        }
      },
      get: {
        method: 'GET',
        url: url + '/:cid',
        isArray: false,
        params: {
          cid: '@cid'
        }
      },
      add: {
        method: 'POST',
        isArray: false,
        params: {
          teacher_user_id: '@teacher_user_id'
        }
      },
      update: {
        method: 'PUT',
        url: url + '/:cid',
        isArray: false,
        params: {
          cid: '@cid'
        }
      },
      remove: {
        method: 'DELETE',
        url: url + '/:cid',
        isArray: false,
        params: {
          cid: '@cid'
        }
      },
      archive: {
        method: 'PUT',
        url: url + '/:cid/archive',
        isArray: false,
        params: {
          cid: '@cid',
          remove_students: '@remove_students'
        }
      },
      maxStudents: {
        method: 'GET',
        url: url + '/maxstudents'
      },
      getStudents: {
        method: 'GET',
        url: url + '/:cid/students',
        isArray: true,
        params: {
          cid: '@cid'
        }
      },
      getStudentsAndTeachers: {
        method: 'GET',
        url: url + '/:cid/students_teachers',
        isArray: true,
        params: {
          cid: '@cid'
        }
      },
      getCampusStudents: {
        method: 'GET',
        url: url + '/:cid/campus',
        isArray: true,
        params: {
          cid: '@cid'
        }
      },
      addStudents: {
        method: 'PUT',
        url: url + '/:cid/students',
        isArray: false,
        params: {
          cid: '@cid'
        }
      },
      addTeachers: {
        method: 'PUT',
        url: url + '/:cid/teachers',
        isArray: false,
        params: {
          cid: '@cid'
        }
      },
      createAndAddToClass: {
        method: 'POST',
        url: url + '/:cid/auth/:auth',
        isArray: false,
        params: {
          cid: '@cid',
          auth: '@auth'
        }
      },
      removeStudentsFromTeacher: {
        method: 'PUT',
        url: url + '/students/removeall',
        isArray: false
      },
      removeStudents: {
        method: 'PUT',
        url: url + '/:cid/removestudents',
        isArray: false,
        params: {
          cid: '@cid'
        }
      },
      removeTeachers: {
        method: 'PUT',
        url: url + '/:cid/removeteachers',
        isArray: false,
        params: {
          cid: '@cid'
        }
      },
      getStates: {
        method: 'GET',
        url: url + '/states',
        isArray: true
      }
    });
  }]);

angular.module('client.services').factory('ClassesService', ['$q', 'CourseAPI', 'UserInfoDefaultFactory',
  function ($q, CourseAPI, UserInfoDefaultFactory) {

    var classNames = [];
    var classWithTeacherNames = [];

    function getClassName(classId) {
      var className = classNames[classId];
      if (!className) {
        return CourseAPI.get({cid: classId}).$promise.then(function (course) {
          classNames[classId] = course.class_name;
          return course.class_name;
        });
      } else {
        return $q.when(className);
      }
    }

    function getClassWithTeacherName(classId, teacherId) {
      var classWithTeacherName = classWithTeacherNames[classId];
      if (!classWithTeacherName) {
        return CourseAPI.get({cid: classId}).$promise.then(function (course) {
          return UserInfoDefaultFactory.get({id: teacherId}).$promise.then(function (teacher) {
            var classWithTeacher = teacher.last_name + " " + teacher.first_name + ", " + course.class_name;
            classWithTeacherNames[classId] = classWithTeacher;
            return classWithTeacher;
          });
        });
      } else {
        return $q.when(classWithTeacherName);
      }
    }

    return {
      getClassName: getClassName,
      getClassWithTeacherName: getClassWithTeacherName
    };

  }]);
