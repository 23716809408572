'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = [];

  function controller () {
    var ctrl = this;
    ctrl.chartType = 'pie';
    ctrl.width = 600;
    ctrl.height = 400;

    ctrl.chartOptions = {
      title: {
        display: true,
        text: "Pie Chart"
      },
      legend: {
        display: true,
        position: "right"
      }
    };
    
    ctrl.$onChanges = function(changes) {
      if (changes.chartTitle && changes.chartTitle.currentValue) {
        ctrl.chartOptions.title.text = changes.chartTitle.currentValue;
      }
      if (changes.legendPos && changes.legendPos.currentValue) {
        ctrl.chartOptions.legend.position = changes.legendPos.currentValue;
      }
    };
  }

  module.component('xpPieChart', {
    template: require('./pieChart.jade'),
    controller: controller,
    bindings: {
      chartData: '=',
      chartTitle: '<',
      legendPos: '<'
    }
  });

})();
