'use strict';

angular.module('client.services').factory('CourseModalFactory', ['$rootScope', '$q', '$timeout', 'ClassesGrades', 'ClassesSubjects', 'LicensePlans', 'CourseFactory',
  'User', 'ExperienceGroupListFactory', 'UserCodeFactory', 'ModalService', '$log', 'userPermissions', 'PermissionConsts', 'UserDefaultInfo',
  'canvasService', 'schoologyService', 'tourService',
  function ($rootScope, $q, $timeout, ClassesGrades, ClassesSubjects, LicensePlans, CourseFactory,
            User, ExperienceGroupListFactory, UserCodeFactory, ModalService, $log, userPermissions, PermissionConsts, UserDefaultInfo,
            canvasService, schoologyService, tourService) {

    // This function Hides the current dialog then waits for it to become hidden before it resolves the promise.
    // This was necessary because the $modal hide code for the previous dialog was being called after the $modal show
    // was called for the new dialog.  This was causing the "next" dialog in the chain to disappear.  This code now waits
    // for the dialog to finish hiding before it lets the new one get created.  This is a problem because the dialogs here
    // don't specify a prefixClass in the options of the dialog.  If this was done, the $modal class would differentiate
    // between the different dialogs and this would not happen.  Unfortunately, if we added a different prefix for each
    // dialog, we would also need to add a new style for each that would show/hide correctly at the body tag level.
    function hideAndResolve(scope, deferred, params) {
      var onHide = $rootScope.$on('modal.hide', function (e, modal) {
        $timeout(function () {
          deferred.resolve(params);
        });
        onHide();
      });

      scope.modal.hide();
    }

    function hideAndReject(scope, deferred, params) {
      var onHide = $rootScope.$on('modal.hide', function (e, modal) {
        $timeout(function () {
          deferred.reject(params);
        });
        onHide();
      });

      scope.modal.hide();
    }

    function courseExists(name, currentCourse) {
      var deferred = $q.defer();

      var params = {
        teacher_user_id: User.getId(),
        cache: true
      };

      CourseFactory.list(params)
        .then(function (courses) {
          courses.forEach(function (course) {
            if (name.toUpperCase() == course.class_name.toUpperCase() &&
              (!currentCourse ||
                name.toUpperCase() != currentCourse.class_name.toUpperCase())) {
              deferred.resolve(name);
              return deferred.promise;
            }
          });
          deferred.resolve(null);
        });

      return deferred.promise;
    }

    function addCourseModal(config) {
      ModalService.show(config);
    }

    function addClass(user, googleClasses, canvasClasses, schoologyClasses) {
      var deferred = $q.defer();

      var config = {
        showTextInput: true,
        backdrop: 'static',
        textFieldValue: {value: ''},
        textFieldPlaceholder: 'Class Name',
        template: require('./createClass.jade'),
        isFirstTimeUser: user.first_time,
        isGoogleUser: user.google_user && googleClasses.has_courses,
        googleClasses: (googleClasses && googleClasses.courses) || [],
        isCanvasUser: user.canvas_user,
        canvasClasses: canvasClasses.courses || [],
        isSchoologyUser: user.schoology_user,
        schoologyClasses: schoologyClasses.courses || [],
        lms_id: canvasClasses.lms_id,
        invalidCourseName: null,
        activeTab: 0,
        closeDialog: function () {
          tourService.setTour(0);
        },
        addLmsClass: function (scope, type) {
          var params = {
            classType: type
          };
          hideAndResolve(scope, deferred, params);
        },
        addExplorosClass: function (scope, form) {
          if (!form.$valid) {
            return;
          }
          courseExists(scope.textFieldValue.value)
            .then(function (name) {
              scope.invalidCourseName = name;
              if (name) {
                return;
              }
              var params = {
                classType: 'exploros',
                name: scope.textFieldValue.value
              };
              tourService.gotoNextTourStep();
              hideAndResolve(scope, deferred, params);
            })
            .catch(function (error) {
              $log.error("error in submit course", error);
              hideAndResolve(scope, deferred, error);
            });
        },
        change: function (scope) {
          scope.invalidCourseName = (scope.invalidCourseName == scope.textFieldValue.value) ? scope.invalidCourseName : null;
        }
      };

      addCourseModal(config);
      return deferred.promise;
    }

    function addClassFromGoogle(googleClasses) {
      var deferred = $q.defer();

      var config = {
        title: 'Import From Google Classroom',
        srcImage: 'google-classroom',
        backdrop: 'static',
        template: require('./addFromLmsClassroom.jade'),
        selectMsg: 'Please select a Google Classroom class to import.',
        courseList: {
          selected: null,
          data: googleClasses.courses || []
        },
        closeDialog: function () {
          tourService.setTour(0);
        },
        submit: function (scope, form, action) {
          scope.form = form;
          if (!scope.courseList.selected) {
            return;
          }

          tourService.gotoNextTourStep();
          hideAndResolve(scope, deferred, scope.courseList.selected);
        }
      };

      addCourseModal(config);
      return deferred.promise;
    }

    function addClassFromCanvas(canvasClasses) {
      var deferred = $q.defer();

      var config = {
        title: 'Import From Canvas',
        srcImage: 'instructure_lg',
        backdrop: 'static',
        template: require('./addFromLmsClassroom.jade'),
        selectMsg: 'Please select a canvas class to import.',
        courseList: {
          selected: undefined,
          data: canvasClasses.courses || []
        },
        closeDialog: function () {
          tourService.setTour(0);
        },
        submit: function (scope, form, action) {
          scope.form = form;
          if (!scope.courseList.selected) {
            return;
          }

          scope.courseList.selected.lms_id = canvasClasses.lms_id;
          tourService.gotoNextTourStep();
          hideAndResolve(scope, deferred, scope.courseList.selected);
        }
      };

      addCourseModal(config);
      return deferred.promise;
    }

    function addClassFromSchoology(schoologyClasses) {
      var deferred = $q.defer();

      var config = {
        title: 'Import From Schoology',
        srcImage: 'schoology',
        backdrop: 'static',
        template: require('./addFromLmsClassroom.jade'),
        selectMsg: 'Please select a schoology class to import.',
        courseList: {
          selected: undefined,
          data: schoologyClasses.courses || []
        },
        closeDialog: function () {
          tourService.setTour(0);
        },
        submit: function (scope, form, action) {
          scope.form = form;
          if (!scope.courseList.selected) {
            return;
          }

          tourService.gotoNextTourStep();
          hideAndResolve(scope, deferred, scope.courseList.selected);
        }
      };

      addCourseModal(config);
      return deferred.promise;
    }

    function filterStudentList(selected, list) {
      return list.filter(function (student) {
        for (var i = 0; i < selected.length; i++) {
          if (student.id === selected[i].id) {
            return false;
          }
        }
        return true;
      });
    }

    function modifyStudents(teacher_code, class_name, group, title, students) {
      var deferred = $q.defer();

      var config = {
        title: title || 'Add/Remove Students',
        backdrop: 'static',
        showTextInput: false,
        teacherCode: teacher_code,
        className: class_name,
        students: {
          selected: students ? (Array.isArray(students) ? students : [students]) : [],
          filtered: [],
          list: []
        },
        template: require('./addCourseModifyStudentsModal.jade'),
        buttons: [
          {
            title: "Cancel",
            click: '$hide()',
            class: 'btn-default'
          },
          {
            title: 'Save',
            click: 'submit(scope, true)',
            class: 'btn-primary',
            type: 'submit'
          }
        ],
        onSelectStudent: function (item) {
          config.students.list.forEach(function (student) {
            if (student.id === item.id) {
              student.selected = true;
            }
          });
          config.students.filtered = filterStudentList(config.students.selected, config.students.list);
        },
        onRemoveStudent: function (item) {
          config.students.list.forEach(function (student) {
            if (student.id === item.id) {
              student.selected = false;
            }
          });
          config.students.filtered = filterStudentList(config.students.selected, config.students.list);
        },
        filterStudentList: filterStudentList,
        submit: function (scope, add) {
          var params = {
            'teacher_user_id': User.getId(),
            'class_name': class_name,
            'students': []
          };

          if (scope.students.selected.length > 0 && add) {
            scope.students.selected.forEach(function (student) {
              params.students.push(student.username);
            });
            hideAndResolve(scope, deferred, params);
          } else {
            hideAndResolve(scope, deferred, params);
            // hideAndReject(scope, deferred, params);
          }
        }
      };

      ExperienceGroupListFactory.get({
        'id': User.getId(),
        'gid': group ? group.gid : null
      }).$promise
        .then(function (data) { //Success callback
          // Handle and error object
          if (typeof data.error == 'object') {
            deferred.reject(data.error.description);
          }

          data.students.forEach(function (student) {
            student.name = student.first_name + ' ' + student.last_name;
            student.selected = false;
            students.forEach(function (selected) {
              if (student.id == selected.id) {
                student.selected = true;
              }
            });
          });

          config.students.list = data.students;
          config.students.filtered = filterStudentList(config.students.selected, config.students.list);

          addCourseModal(config);
        });

      return deferred.promise;
    }

    function courseDetails() {
      var deferred = $q.defer();

      var courseDetailsData = {licenses: []};
      var hasEPSLicenses = true;

      var config = {
        backdrop: 'static',
        template: require('./classDetails.jade'),
        grades: ClassesGrades.get(),
        subjects: ClassesSubjects.get(),
        translation: {
          nothingSelected: 'No License'
        },
        licenses: LicensePlans.getAssignable({'userId': User.getId()}, function (result) {
          if (result && result.length) {
            courseDetailsData.licenses = result.map(function (license) {
              license.ticked = true;
              if (license.plan_token.toLowerCase() != 'spire' &&
                license.plan_token.toLowerCase() != 'ispire' &&
                license.plan_token.toLowerCase() != 'wordlywise') {
                hasEPSLicenses = false;
              }
              return license;
            });
          }
        }),
        closeDialog: function () {
          tourService.setTour(0);
        },
        selectedGrade: function () {
          return courseDetailsData.grade;
        },
        selectedSubject: function () {
          return courseDetailsData.subject;
        },
        selectedLicense: function () {
          if (config.licenses.length === 0 || (courseDetailsData.licenses && courseDetailsData.licenses.length)) {
            return true;
          } else {
            return false;
          }
        },
        clearLicense: function () {
          courseDetailsData.licenses = [];
        },
        selectedLicenses: function () {
          return courseDetailsData.licenses.map(function (lic) {
            lic.ticked = true;
            return lic;
          });
        },
        hasSelectedLicenses: function () {
          return courseDetailsData.licenses.length;
        },
        hasEPSLicense: function () {
          return hasEPSLicenses;
        },
        setGrade: function (grade) {
          courseDetailsData.grade = grade;
        },
        setSubject: function (subject) {
          courseDetailsData.subject = subject;
        },
        setLicense: function (license) {
          if (license.ticked) {
            courseDetailsData.licenses.push(license);
          } else {
            courseDetailsData.licenses = courseDetailsData.licenses.filter(function (lic) {
              return lic.id != license.id;
            });
          }
        },
        submit: function (scope, form) {
          scope.form = form;
          if (!form.$valid) {
            return;
          }

          tourService.gotoNextTourStep();
          hideAndResolve(scope, deferred, courseDetailsData);
        }
      };

      addCourseModal(config);
      return deferred.promise;
    }

    function settings(courseIn) {
      var deferred = $q.defer();

      var course = angular.copy(courseIn);
      course.licenses = [];
      var canvasCourses = {selected: undefined, data: []};
      var schoologyCourses = {selected: undefined, data: []};
      var remove_students_on_archive = false;
      var isPrimaryTeacher = true;
      var hasEPSLicenses = true;

      UserDefaultInfo.getUserInfo().then(function (userInfo) {
        course.googleUser = userInfo.google_user;
        course.canvasUser = userInfo.canvas_user;
        if (course.canvasUser) {
          canvasService.getAvailableCourses(userInfo).then(function (results) {
            canvasCourses = {
              selected: undefined,
              data: results.courses || []
            };
          });
        }
        course.schoologyUser = userInfo.schoology_user;
        if (course.schoologyUser) {
          schoologyService.getAvailableCourses(userInfo).then(function (results) {
            schoologyCourses = {
              selected: undefined,
              data: results.courses || []
            };
          });
        }
        var primaryTeacher = course.teachers.find(function (teacher) {
          return teacher.primary;
        });
        isPrimaryTeacher = primaryTeacher && primaryTeacher.user_id === userInfo.id;
      });

      var config = {
        title: 'Class Settings',
        backdrop: 'static',
        showTextInput: true,
        textFieldValue: {
          value: course.class_name
        },
        translation: {
          nothingSelected: 'No License'
        },
        grades: ClassesGrades.get(),
        subjects: ClassesSubjects.get(),
        licenses: LicensePlans.getAssignable({'userId': User.getId()}, function (results) {
          // The license that is returned with the class is a class subscription.  When saving the class, the server is expecting a license plan.
          // this code simply finds the corresponding license plan (for display and submission) that matches the class subscription already attached
          // to this class.
          if (courseIn.licenses) {
            if (results && results instanceof Array) {
              courseIn.licenses.forEach(function (subLicense) {
                results.forEach(function (license) {
                  if (subLicense.subscription_id === license.subscription_id) {
                    license.ticked = true;
                    course.licenses.push(license);
                  }
                  if (license.plan_token.toLowerCase() != 'spire' &&
                    license.plan_token.toLowerCase() != 'ispire' &&
                    license.plan_token.toLowerCase() != 'wordlywise') {
                    hasEPSLicenses = false;
                  }
                });
              });
            }
          }
        }),
        showCanvasClasses: function () {
          return !course.ims_id && course.canvasUser && !course.google_id;
        },
        isCanvasClass: function () {
          return course.ims_id > 0 && course.canvasUser;
        },
        showSchoologyClasses: function () {
          return !course.ims_id && course.schoologyUser && !course.google_id;
        },
        isSchoologyClass: function () {
          return course.ims_id === -4 && course.schoologyUser;
        },
        isGoogleClass: function () {
          return course.ims_id === -1 && course.googleUser;
        },
        canvasCourse: function () {
          return canvasCourses;
        },
        schoologyCourse: function () {
          return schoologyCourses;
        },
        selectedGrade: function () {
          return course.grade;
        },
        selectedSubject: function () {
          return course.subject;
        },
        clearLicense: function () {
          course.licenses = [];
        },
        selectedLicenses: function () {
          return course.licenses.map(function (lic) {
            lic.ticked = true;
            return lic;
          });
        },
        hasSelectedLicenses: function () {
          return course.licenses.length;
        },
        hasEPSLicense: function () {
          return hasEPSLicenses;
        },
        textFieldPlaceholder: 'Class Name',
        template: require('./classSettings.jade'),
        activeTab: 0,
        setGrade: function (grade) {
          course.grade = grade;
        },
        setSubject: function (subject) {
          course.subject = subject;
        },
        setLicense: function (license) {
          if (license.ticked) {
            course.licenses.push(license);
          } else {
            course.licenses = course.licenses.filter(function (lic) {
              return lic.id != license.id;
            });
          }
        },
        isPrimaryTeacher: function () {
          return isPrimaryTeacher;
        },
        setRemoveStudents: function (remove_students) {
          remove_students_on_archive = remove_students;
        },
        canvasPostAssignments: course.canvasPostAssignments,
        setCanvasPostAssignments: function (value) {
          course.canvasPostAssignments = value;
        },
        canvasPostGrades: course.canvasPostGrades,
        setCanvasPostGrades: function (value) {
          course.canvasPostGrades = value;
        },
        schoologyPostAssignments: course.schoologyPostAssignments,
        setSchoologyPostAssignments: function (value) {
          course.schoologyPostAssignments = value;
        },
        schoologyPostGrades: course.schoologyPostGrades,
        setSchoologyPostGrades: function (value) {
          course.schoologyPostGrades = value;
        },
        googlePostAssignments: course.googlePostAssignments,
        setGooglePostAssignments: function (value) {
          course.googlePostAssignments = value;
        },
        googlePostGrades: course.googlePostGrades,
        setGooglePostGrades: function (value) {
          course.googlePostGrades = value;
        },
        submit: function (scope, form, action) {
          scope.form = form;
          if (!form.$valid) {
            return;
          }

          var response = {
            course: course,
            action: action,
            remove_students: false
          };

          if (form && (form.$name == 'form1')) {
            courseExists(scope.textFieldValue.value, course)
              .then(function (name) {
                scope.invalidCourseName = name;
                if (name) {
                  return;
                }

                course.class_name = scope.textFieldValue.value;

                hideAndResolve(scope, deferred, response);
              })
              .catch(function (error) {
                $log.error("error in course factory submit:", error);
                hideAndResolve(scope, deferred, error);
              });

            return;
          } else if (form && (form.$name == 'form2')) {
            // Need to pass option for removing students on archive
            response.remove_students = remove_students_on_archive;
          } else if (form && (form.$name == 'form3')) {
            response.canvasCourse = canvasCourses.selected;
            response.schoologyCourse = schoologyCourses.selected;
          }

          hideAndResolve(scope, deferred, response);
        },
        change: function (scope) {
          scope.invalidCourseName = (scope.invalidCourseName == scope.textFieldValue.value) ?
            scope.invalidCourseName : null;
        }
      };

      addCourseModal(config);
      return deferred.promise;
    }

    function deleteClass(id) {
      var deferred = $q.defer();

      var config = {
        title: 'Delete Class',
        backdrop: 'static',
        message: 'Are you sure you want to delete this class?',
        showTextInput: false,
        template: require('../../views/partials/modals/alertModal.jade'),
        buttons: [
          {
            title: 'Cancel',
            click: '$hide()',
            class: 'btn-default'
          },
          {
            title: 'Delete Class',
            click: 'submit(scope, form)',
            class: 'btn-primary',
            type: 'submit'
          }
        ],
        submit: function (scope, form) {
          scope.form = form;
          if (!form.$valid) {
            return;
          }

          CourseFactory.remove(id)
            .then(function (message) {
              hideAndResolve(scope, deferred, message);
            })
            .catch(function (error) {
              $log.error("error in delete class:", error);
              hideAndReject(scope, deferred, new Error(error));
            });
        }
      };

      addCourseModal(config);
      return deferred.promise;
    }

    return {
      add: addClass,
      addFromGoogleClassroom: addClassFromGoogle,
      addFromCanvas: addClassFromCanvas,
      addFromSchoology: addClassFromSchoology,
      modifyStudents: modifyStudents,
      courseDetails: courseDetails,
      settings: settings,
      remove: deleteClass
    };
  }]);
