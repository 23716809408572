'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('spirePlacementDataTable', ['ExperienceNavigator', '$location',
   function(ExperienceNavigator, $location) {
     function link(scope, element, attrs)
     {
       scope.getStudents = function() {
         return scope.model.getStudents(scope.classId);
       };

       scope.getLevels = function() {
         return scope.model.getLevels();
       };

       scope.hasMultipleScores = function(studentId, levelId) {
         return scope.model.hasMultipleScores(studentId, levelId);
       };

       scope.getExperienceDate = function(studentId, levelId, index) {
         return scope.model.getExperienceDate(studentId, levelId, index);
       };

       scope.getLastExperienceDate = function(studentId, levelId) {
         return scope.model.getLastExperienceDate(studentId, levelId);
       };

       scope.getErrorCount = function(studentId, levelId, index) {
         var errors = "";
         var errorCount = scope.model.getErrorCount(studentId, levelId, index);
         if (errorCount >= 0) {
           errors =  "(" + errorCount;
           if (errorCount === 0 || errorCount > 1) {
             errors = errors + " errors)";
           } else {
             errors = errors + " error)";
           }
         }
         return errors;
       };

       scope.getDropdown = function(studentId, levelId) {
         var dropdown = scope.model.getCellDropdown(studentId, levelId);
         dropdown.navigateToExperience = navigateToExperience;
         return dropdown;
       };

       scope.onShowExperience = function(studentId, levelId, index) {
         var path = 'experience/' + scope.model.getExperienceId(studentId, levelId, index) + '/activity/scene/0';
         ExperienceNavigator.navigateToExperience(path, "", $location.path());
       };
     }

     return {
       replace: true,
       restrict: 'E',
       templateUrl: "spirePlacementDataTable.tpl",
       link: link,
       scope: {
         model: '=',
         classId: '=',
         student: '='
       }
     };

     function navigateToExperience(experienceId)
     {
       var path = 'experience/' + experienceId + '/activity/scene/0';
       ExperienceNavigator.navigateToExperience(path, "", $location.path());
     }

   }]);

  module.directive('spireStudentPlacementScore', ['ExperienceNavigator', '$location',
   function(ExperienceNavigator, $location) {
     function link(scope, element, attrs)
     {
       scope.hasScore = function() {
         return scope.model.hasScore(scope.student.id);
       };

       scope.getStudentName = function() {
         return scope.student.name + "'s";
       };

       scope.getStudentPlacement = function() {
         var studentPlacement = scope.model.getStudentPlacement(scope.student.id);
         return " from " + window.moment(studentPlacement.updated_at).format('M/DD/YY') +
                 " is: " + studentPlacement.placement + " with " + studentPlacement.incorrect_word_count + " errors";
       };

       scope.onShowExperience = function() {
         var studentPlacement = scope.model.getStudentPlacement(scope.student.id);
         var path = 'experience/' + studentPlacement.experience_id + '/activity/scene/0';
         ExperienceNavigator.navigateToExperience(path, "", $location.path(), {student: scope.student.id});
       };
     }

     return {
       replace: true,
       restrict: 'E',
       templateUrl: "spireStudentPlacementScore.tpl",
       link: link,
       scope: {
         model: '=',
         student: '='
       }
     };
   }]);

  module.directive('spireStudentDataTable', ['ExperienceNavigator', '$location',
   function(ExperienceNavigator, $location) {
     var emptyColumn = {};
     function link(scope, element, attrs)
     {
       var maxDataColumns = scope.maxColumns || 8;
       var startDataColumn = 0;

       scope.getStudents = function() {
         return scope.model.getStudents(scope.classId);
       };

       scope.showScrollRight = function () {
         return scope.model.getColumns(scope.level).length > startDataColumn + maxDataColumns;
       };

       scope.scrollRight = function () {
         startDataColumn += maxDataColumns;
       };

       scope.showScrollLeft = function () {
         return startDataColumn > 0;
       };

       scope.scrollLeft = function () {
         startDataColumn -= maxDataColumns;
       };

       scope.hasValue = function(student, column) {
         if (column === emptyColumn)
           return false;

         return !angular.isUndefined(scope.model.getCellValue(scope.level, student, column, scope.classId));
       };

       function getModelColumns()
       {
         return scope.model.getColumns(scope.level).slice(startDataColumn, startDataColumn + maxDataColumns);
       }

       scope.getColumns = function() {
         var columns = getModelColumns();
         while (columns.length < maxDataColumns) {columns.push(emptyColumn);}
         return columns;
       };

       scope.getSetColumns = function(set) {
         var columns = scope.model.getColumns(scope.level).slice((set-1) * maxDataColumns, ((set-1) * maxDataColumns) + maxDataColumns);
         while (columns.length < maxDataColumns) {columns.push(emptyColumn);}
         return columns;
       };

       scope.getColumnSets = function() {
         var maxSets = Math.ceil(scope.model.getColumns(scope.level).length / maxDataColumns);
         var sets = [];
         for (var index = 1; index <= maxSets; ++index) {
           sets.push(index);
         }
         return sets;
       };

       scope.getOverallCellValue = function(column) {
         if (column === emptyColumn)
           return undefined;

         return scope.model.getOverallCellValue(scope.level, column, scope.classId);
       };

       scope.getCellValue = function(student, column) {
         if (column === emptyColumn)
           return undefined;

         return scope.model.getCellValue(scope.level, student, column, scope.classId);
       };

       scope.formatScore = function (score) {
         return scope.model.formatScore(score);
       };

       scope.getScoreClass = function(score, column) {
         if (column === emptyColumn)
           return 'blank-cell';

         var classes = ['score-cell'];
         var columns = getModelColumns();

         if (columns.length > 0 && column === columns[columns.length - 1])
         {
           classes.push('last');
         }

         classes.push(scope.model.getScoreClass(score));
         return classes;
       };

       scope.getDropdown = function(student, column) {
         if (column === emptyColumn)
           return undefined;
         var dropdown = scope.model.getCellDropdown(scope.level, student, column, scope.classId);
         dropdown.navigateToExperience = navigateToExperience;
         return dropdown;
       };

       scope.getLegend = function()
       {
         return scope.model.getLegend();
       };
     }

     return {
       replace: true,
       restrict: 'E',
       templateUrl: "spireStudentDataTable.tpl",
       link: link,
       scope: {
         level: '=',
         model: '=',
         classId: '=',
         maxColumns: '=',
         student: '='
       }
     };

     function navigateToExperience(path, studentId)
     {
       if (studentId) {
         ExperienceNavigator.navigateToExperience(path, "", $location.path(), {student: studentId});
       }
       else {
         ExperienceNavigator.navigateToExperience(path, $location.path());
       }
     }
   }]);

  module.directive('spireClassPlacementDataTable', ['ExperienceNavigator', '$location',
                                           function(ExperienceNavigator, $location) {
   var emptyColumn = {};
   function link(scope, element, attrs)
   {
     scope.getStudents = function() {
       return scope.model.getStudents();
     };

     scope.getLevels = function() {
       return scope.model.getLevels();
     };

     scope.hasMultipleScores = function(classId, studentId, levelId) {
       return scope.model.hasMultipleScores(classId, studentId, levelId);
     };

     scope.getExperienceDate = function(classId, studentId, levelId, index) {
       return scope.model.getExperienceDate(classId, studentId, levelId, index);
     };

     scope.getLastExperienceDate = function(classId, studentId, levelId) {
       return scope.model.getLastExperienceDate(classId, studentId, levelId);
     };

     scope.getErrorCount = function(classId, studentId, levelId, index) {
       return scope.model.getErrorCount(classId, studentId, levelId, index);
     };

     scope.getDropdown = function(classId, studentId, levelId) {
       var dropdown = scope.model.getCellDropdown(classId, studentId, levelId);
       dropdown.navigateToExperience = navigateToExperience;
       return dropdown;
     };

     scope.onShowExperience = function(classId, studentId, levelId, index) {
       var path = 'experience/' + scope.model.getExperienceId(classId, studentId, levelId, index) + '/activity/scene/0';
       ExperienceNavigator.navigateToExperience(path, "", $location.path());
     };
   }

   return {
     replace: true,
     restrict: 'E',
     templateUrl: "spireClassPlacementDataTable.tpl",
     link: link,
     scope: {
       model: '='
     }
   };

   function navigateToExperience(experienceId)
   {
     var path = 'experience/' + experienceId + '/activity/scene/0';
     ExperienceNavigator.navigateToExperience(path, "", $location.path());
   }

 }]);

  module.directive('spireClassDataTable', ['ReportNavigation', '$location', 'SavedFilterState', 'ActiveMode', 'CourseFactory',
   function(ReportNavigation, $location, SavedFilterState, ActiveMode, CourseFactory) {
     var emptyColumn = {};
     function link(scope, element, attrs)
     {
       var maxDataColumns = 8;
       var startDataColumns = [];

       scope.isAdmin = function() {
         return scope.model.isAdmin();
       };

       scope.getClasses = function() {
         return scope.model.getClasses();
       };

       scope.getLevels = function() {
         return scope.model.getLevels();
       };

       function getStartDataColumn(level) {
         if (!startDataColumns[level])
           startDataColumns[level] = 0;
         return startDataColumns[level];
       }

       function updateStartDataColumn(level, newVal) {
         startDataColumns[level] = newVal;
       }

       scope.showScrollRight = function (level) {
         return scope.model.getColumns(level).length > getStartDataColumn(level) + maxDataColumns;
       };

       scope.scrollRight = function (level) {
         updateStartDataColumn(level, getStartDataColumn(level) + maxDataColumns);
       };

       scope.showScrollLeft = function (level) {
         return getStartDataColumn(level) > 0;
       };

       scope.scrollLeft = function (level) {
         updateStartDataColumn(level, getStartDataColumn(level) - maxDataColumns);
       };

       scope.hasValue = function(level, clss, column) {
         if (column === emptyColumn)
           return false;

         return !angular.isUndefined(scope.model.getCellValue(level, clss, column));
       };

       function getModelColumns(level) {
         return scope.model.getColumns(level).slice(getStartDataColumn(level), getStartDataColumn(level) + maxDataColumns);
       }

       scope.getColumns = function(level) {
         var columns = getModelColumns(level);
         while (columns.length < maxDataColumns) {columns.push(emptyColumn);}
         return columns;
       };

       scope.getOverallCellValue = function(level, column) {
         if (column === emptyColumn)
           return undefined;

         return scope.model.getOverallCellValue(level, column);
       };

       scope.getCellValue = function(level, clss, column) {
         if (column === emptyColumn)
           return undefined;

         return scope.model.getCellValue(level, clss, column);
       };

       scope.formatScore = function (score) {
         return scope.model.formatScore(score);
       };

       scope.getScoreClass = function(level, score, column) {
         if (column === emptyColumn)
           return 'blank-cell';

         var classes = ['score-cell'];
         var columns = getModelColumns(level);

         if (columns.length > 0 && column === columns[columns.length - 1])
         {
           classes.push('last');
         }

         classes.push(scope.model.getScoreClass(score));
         return classes;
       };

       scope.getDropdown = function(level, clss, column) {
         if (column === emptyColumn)
           return undefined;
         var dropdown = scope.model.getCellDropdown(level, clss, column);
         // dropdown.navigateToClass = navigateToClass;
         return dropdown;
       };

       scope.getLegend = function() {
         return scope.model.getLegend();
       };

       scope.navigateToClassReport = function(classId, level) {
         // Need to set the correct level so the sub report will pick it up
         var state = SavedFilterState.getState();
         if (!state) {
           state = { level: {}, class: {} };
         }
         state.level.id = level;
         state.level.name = 'Level ' + level;
         SavedFilterState.setState(state);

         // Set a filter on this report so it will display the correct class
         var filter = {drilldown: true, "class": classId};

         // Now navigate to the report
         ReportNavigation.navigateToReport(ActiveMode.currentReportId(), $location.path(), filter, $location.path());
       };
     }

     return {
       replace: true,
       restrict: 'E',
       templateUrl: "spireClassDataTable.tpl",
       link: link,
       scope: {
         level: '=',
         model: '='
       }
     };
   }]);

  module.directive('spireReportPrePostTable', ['ExperienceNavigator', '$location',
   function(ExperienceNavigator, $location) {
     var emptyColumn = {};
     function link(scope, element, attrs)
     {
       scope.getStudents = function() {
         return scope.model.getStudents(scope.classId);
       };

       scope.formatPercent = function(value) {
         if (value !== null && !angular.isUndefined(value))
         {
           return '' + value + '%';
         }

         return value;
       };

       scope.getValue = function(student, prepost, column) {
         return scope.model.getValue(scope.level, student, prepost, column, scope.classId);
       };

       scope.getOverallValue = function(prepost, column) {
         return scope.model.getOverallValue(scope.level, prepost, column, scope.classId);
       };

       scope.navigateToExperience = function(student, prepost)
       {
         var path = 'experience/' + scope.model.getValue(scope.level, student, prepost, 'experience_id', scope.classId) + '/activity/scene/0';
         ExperienceNavigator.navigateToExperience(path, "", $location.path(), {student: student.id});
       };
     }

     return {
       replace: true,
       restrict: 'E',
       templateUrl: "spireReportPrePostTable.tpl",
       link: link,
       scope: {
         model: '=',
         level: '=',
         classId: '=',
         student: '='
       }
     };
   }]);

  module.directive('spireClassPrePostTable', ['ReportNavigation', '$location', 'SavedFilterState', 'ActiveMode', 'CourseFactory',
   function(ReportNavigation, $location, SavedFilterState, ActiveMode, CourseFactory) {
     var emptyColumn = {};
     function link(scope, element, attrs)
     {
       scope.isAdmin = function() {
         return scope.model.isAdmin();
       };

       scope.getClasses = function() {
         return scope.model.getClasses();
       };

       scope.getLevels = function() {
         return scope.model.getLevels();
       };

       scope.formatPercent = function(value) {
         if (value !== null && !angular.isUndefined(value))
         {
           return '' + value + '%';
         }

         return value;
       };

       scope.getValue = function(level, clss, prepost, column) {
         return scope.model.getValue(level, clss, prepost, column);
       };

       scope.getOverallValue = function(level, prepost, column) {
         return scope.model.getOverallValue(level, prepost, column);
       };

       scope.navigateToClassReport = function(classId, level) {
         // Need to set the correct level so the sub report will pick it up
         var state = SavedFilterState.getState();
         if (!state) {
           state = { level: {}, class: {} };
         }
         state.level.id = level;
         state.level.name = 'Level ' + level;
         SavedFilterState.setState(state);

         // Set a filter on this report so it will display the correct class
         var filter = {drilldown: true, "class": classId};

         // Now navigate to the report
         ReportNavigation.navigateToReport(ActiveMode.currentReportId(), $location.path(), filter, $location.path());
       };
     }

     return {
       replace: true,
       restrict: 'E',
       templateUrl: "spireClassPrePostTable.tpl",
       link: link,
       scope: {
         model: '=',
         level: '='
       }
     };
   }]);

  module.directive('spireReportMidTable', ['ExperienceNavigator', '$location',
   function(ExperienceNavigator, $location) {
     var emptyColumn = {};
     function link(scope, element, attrs)
     {
       scope.getStudents = function() {
         return scope.model.getStudents(scope.classId);
       };

       scope.getColumns = function() {
         return scope.model.getColumns(scope.level);
       };

       scope.getOverallCellValue = function(column) {
         return scope.model.getOverallCellValue(scope.level, column, scope.classId);
       };

       scope.getCellValue = function(student, column) {
         return scope.model.getCellValue(scope.level, student, column, scope.classId);
       };

       scope.formatScore = function (score) {
         return scope.model.formatScore(score);
       };

       scope.getScoreClass = function(score, column) {
         if (column === emptyColumn)
           return 'blank-cell';

         var classes = ['score-cell'];
         var columns = scope.getColumns();

         if (columns.length > 0 && column === columns[columns.length - 1])
         {
           classes.push('last');
         }

         classes.push(scope.model.getScoreClass(score));
         return classes;
       };

       scope.getLegend = function()
       {
         return scope.model.getLegend();
       };

       scope.getDate = function(student)
       {
         return scope.model.getStudentValue(scope.level, scope.classId, student, 'date');
       };

       scope.navigateToExperience = function(student)
       {
         var path = 'experience/' + scope.model.getStudentValue(scope.level, scope.classId, student, 'experienceId') + '/activity/scene/0';
         ExperienceNavigator.navigateToExperience(path, "", $location.path(), {student: student.id});
       };
     }

     return {
       replace: true,
       restrict: 'E',
       templateUrl: "spireReportMidTable.tpl",
       link: link,
       scope: {
         level: '=',
         model: '=',
         classId: '=',
         student: '='
       }
     };
   }]);

  module.directive('spireClassMidTable', ['ReportNavigation', '$location', 'SavedFilterState', 'ActiveMode', 'CourseFactory',
   function(ReportNavigation, $location, SavedFilterState, ActiveMode, CourseFactory) {
     var emptyColumn = {};
     function link(scope, element, attrs)
     {
       scope.isAdmin = function() {
         return scope.model.isAdmin();
       };

       scope.getClasses = function() {
         return scope.model.getClasses();
       };

       scope.getLevels = function() {
         return scope.model.getLevels();
       };

       scope.getColumns = function(level) {
         return scope.model.getColumns(level);
       };

       scope.getOverallCellValue = function(level, column) {
         return scope.model.getOverallCellValue(level, column);
       };

       scope.getCellValue = function(level, clss, column) {
         return scope.model.getCellValue(level, clss, column);
       };

       scope.formatScore = function (score) {
         return scope.model.formatScore(score);
       };

       scope.getScoreClass = function(level, score, column) {
         if (column === emptyColumn)
           return 'blank-cell';

         var classes = ['score-cell'];
         var columns = scope.getColumns(level);

         if (columns.length > 0 && column === columns[columns.length - 1])
         {
           classes.push('last');
         }

         classes.push(scope.model.getScoreClass(score));
         return classes;
       };

       scope.getLegend = function()
       {
         return scope.model.getLegend();
       };

       scope.getDate = function(student)
       {
         return scope.model.getStudentValue(scope.level, student, 'date');
       };

       scope.navigateToClassReport = function(classId, level) {
         // Need to set the correct level so the sub report will pick it up
         var state = SavedFilterState.getState();
         if (!state) {
           state = { level: {}, class: {} };
         }
         state.level.id = level;
         state.level.name = 'Level ' + level;
         SavedFilterState.setState(state);

         // Set a filter on this report so it will display the correct class
         var filter = {drilldown: true, "class": classId};

         // Now navigate to the report
         ReportNavigation.navigateToReport(ActiveMode.currentReportId(), $location.path(), filter, $location.path());
       };
     }

     return {
       replace: true,
       restrict: 'E',
       templateUrl: "spireClassMidTable.tpl",
       link: link,
       scope: {
         level: '=',
         model: '=',
       }
     };
   }]);

})();
