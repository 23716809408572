'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = ['studentLearningService', 'ActiveMode', 'reportToPDF', 'ReportingSchoolYearsService', '$log'];

function controller (studentLearningService, ActiveMode, reportToPDF, ReportingSchoolYearsService, $log) {
  var ctrl = this;

  ctrl.inited = false;
  ctrl.unitStandards = [];
  ctrl.clusters = {};
  ctrl.selectedSchools = [];
  ctrl.selectedTeachers = [];
  ctrl.selectedClasses = [];
  ctrl.defaultYear = ReportingSchoolYearsService.get()[0];
  ctrl.showExperiences = true;
  ctrl.preview = false;
  ctrl.units = [];
  ctrl.allStudents = [];

  // Experiences need to be sorted by the unit they belong to first then there own set order second
  function sortExperiences(a, b) {
    // Find the unit for each experience
    const aUnit = ctrl.units.find(function(unit) { return unit.uuid === a.collection; });
    const bUnit = ctrl.units.find(function(unit) { return unit.uuid === b.collection; });

    if (!aUnit || !bUnit) {
      return 0;
    }

    if (aUnit.set_order === bUnit.set_order) {
      return a.set_order - b.set_order;
    }

    return aUnit.set_order - bUnit.set_order;
  }

  function InitExperiences(reportData) {
    if (reportData.collection_uuids && reportData.collection_uuids.length > 0) {
      if (ctrl.currentReport === 'curriculum-student-learning' || ctrl.currentReport === 'curriculum-student-learning-multi-part') {
        studentLearningService.getExperiencesByPublishable(reportData.collection_uuids)
        .then(function(experiences){
          ctrl.experiences = experiences.sort(sortExperiences);
          ctrl.preview = true;
        });
      }
      else {
        studentLearningService.getExperiencesByStandards(reportData.collection_uuids)
        .then(function(experiences){
          ctrl.experiences = experiences.sort(sortExperiences);
        });
      }
    }
  }

  function InitReportData(filter) {
    ctrl.inited = false;
    if (filter.year && filter.year !== ctrl.defaultYear.value){
      ctrl.showExperiences = false;
    } else {
      ctrl.showExperiences = true;
    }
    ActiveMode.getReportData(filter).then(function (report) {
      InitExperiences(report);
      if ((ctrl.currentReport === 'curriculum-student-learning' || ctrl.currentReport === 'curriculum-student-learning-multi-top') && !ctrl.classes) {
        ctrl.classes = report.data.classes.map(function(cl){
          return {
            id: cl.cid,
            name: cl.class_name,
            ticked: false
          };
        }).sort(function(a, b) { return a.name.localeCompare(b.name); });
        ctrl.allStudents = report.data.students.map(function(student) {
          return {
            id: student.student_user_id,
            name: student.student_first_name + " " + student.student_last_name,
            class_id: student.class_id
          };
        }).sort(function(a, b) { return a.name.localeCompare(b.name); });
      }
      ctrl.units = report.data.units;
      if (!ctrl.unit && ctrl.units && ctrl.units.length > 0) {
        ctrl.unit = ctrl.units[0];
      }
      ctrl.standards = report.data.standards;
      ctrl.expectations = report.data.expectations;
      updateReportView();
      ctrl.inited = true;
    })
    .catch(function(error){
      $log.error("error in getting report data: ", error);
    });
  }

  function InitTeachers() {
    InitTeachersAndClasses();
    ctrl.inited = true;
  }

  function InitSchools() {
    studentLearningService.getSchools(ActiveMode.currentSubscriptionId(), ActiveMode.currentReportId())
    .then(function(schools) {
      ctrl.schools = schools;
      var schoolIds = schools.map(function(school) { return school.id; });
      InitTeachersAndClasses(schoolIds);
      ctrl.inited = true;
    });
  }

  function InitTeachersAndClasses(schools) {
    studentLearningService.getTeachersAndClasses(ActiveMode.currentSubscriptionId(), ActiveMode.currentReportId(), schools)
    .then(function(teachersAndClasses) {
      var convertedTeachers = teachersAndClasses.map(function(teacher){
        return {
          teacher_user_id: teacher.teacher_user_id,
          first_name: teacher.first_name,
          last_name: teacher.last_name
          };
      });
      ctrl.teachers = convertedTeachers.filter(function(teacher, index, self){
        return index === convertedTeachers.findIndex(function(inner) { return inner.teacher_user_id === teacher.teacher_user_id; });
      }).sort(function(a, b) { return a.last_name.localeCompare(b.last_name); });
      ctrl.allClasses = teachersAndClasses.map(function(clazz){
        return {
          id: clazz.cid,
          name: clazz.class_name,
          teacher_user_id: clazz.teacher_user_id
        };
      }).sort(function(a, b) { return a.name.localeCompare(b.name); });
      InitReportData({year: ctrl.defaultYear.value});
    });
  }

  ctrl.$onInit = function () {
    ActiveMode.getReport(ActiveMode.currentReportId()).then(function(currentReport) {
      ctrl.currentReport = currentReport.report_kind;
      if (ctrl.currentReport === 'curriculum-student-learning' || ctrl.currentReport === 'curriculum-student-learning-multi-top') {
        InitReportData({year: ctrl.defaultYear.value});
      }
      else if (ctrl.currentReport === 'school-student-learning' || ctrl.currentReport === 'school-student-learning-multi-top') {
        InitTeachers();
      }
      else if (ctrl.currentReport === 'district-student-learning' || ctrl.currentReport === 'district-student-learning-multi-top') {
        InitSchools();
      }
    })
    .catch(function(error){
      $log.error("error in getting report: ", error);
    });
  };

  ctrl.onRefresh = function(schools, teachers, classes, students, year) {

    // The first part of this method handles when a school changes.  The API needs to limit the teachers and classes by school
    var schoolIds = schools.map(function(school) { return school.id; });
    if (schoolIds && schoolIds.length && (schoolIds.length != ctrl.selectedSchools.length ||
                                      !schoolIds.every(function(value, index) { return value === ctrl.selectedSchools[index]; }))) {
      ctrl.selectedSchools = schoolIds;
      if (schoolIds[0] === "All") {
        schoolIds = [];
      }
      InitTeachersAndClasses(schoolIds);
      return;
    }

    // This checks a change of the teacher.  This requires updating classes
    var teacherIds = teachers.map(function(teacher) { return teacher.teacher_user_id; });
    if (teacherIds && teacherIds.length && (teacherIds.length != ctrl.selectedTeachers.length ||
        !teacherIds.every(function(value, index) { return value === ctrl.selectedTeachers[index]; }))) {
      ctrl.selectedTeachers = teacherIds;
      if (teacherIds.length === 1 && teacherIds[0] !== "All") {
        ctrl.classes = ctrl.allClasses.filter(function(cl) { return teacherIds.indexOf(cl.teacher_user_id) !== -1; });
      } else {
        ctrl.classes = null;
      }
    }

    // Update the list of students if the teacher selected a single class
    if (classes && ctrl.allStudents) {
      var classIds = classes.map(function(clazz) { return clazz.id; });
      if (classIds && classIds.length && (classIds.length != ctrl.selectedClasses.length ||
          !classIds.every(function(value, index) { return value === ctrl.selectedClasses[index]; }))) {
        ctrl.selectedClasses = classIds;
        if (classIds.length === 1 && classIds[0] != "All") {
          ctrl.students = ctrl.allStudents.filter(function(student) { return student.class_id === classes[0].id; });
        } else {
          ctrl.students = null;
        }
        students = [];
      }
    }

    var filter = {};
    if (schools && schools.length > 0 && schools[0].id !== "All") {
      filter.schools = schools.map(function(sch) { return sch.id; });
    }
    if (teachers && teachers.length > 0 && teachers[0].teacher_user_id !== "All") {
      filter.teachers = teachers.map(function(teacher) { return teacher.teacher_user_id; });
    }
    if (classes && classes.length > 0 && classes[0].id !== "All") {
      filter.classes = classes.map(function(cl) { return cl.id; });
      if (students && students.length > 0 && students[0].id !== "All") {
        filter.students = students.map(function(student) { return student.id; });
      }
    }

    if (year) {
      filter.year = year.value;
    }
    InitReportData(filter);
  };

  ctrl.onChangeUnit = function(unit) {
    if (unit) {
      ctrl.unit = unit;
      updateReportView();
    }
  };

  function updateReportView() {
    ctrl.unitStandards = ctrl.standards.filter(function(std) {
      return std.unit_name === ctrl.unit.name;
    });
    ctrl.clusters = [];
    if (!ctrl.unitStandards || ctrl.unitStandards.length === 0) {
      return;
    }
    var cluster = null;
    ctrl.expectations.forEach(function(exp) {
      if (exp.unit_name === ctrl.unit.name) {
        if (!cluster || (cluster && (exp.cluster_type !== cluster.cluster_type || exp.cluster_name !== cluster.cluster_name))) {
          if (cluster) {
            ctrl.clusters.push(cluster);
          }
          cluster = { cluster_type: exp.cluster_type, cluster_name: exp.cluster_name, expectations: [] };
        }
        var expClass = "";
        if (exp.type_id === 1 || exp.type_id === 4) {
          expClass = "student-learning-legend-process";
        }
        else if (exp.type_id === 5) {
          expClass = "student-learning-legend-supporting";
        }
        else if (exp.type_id === 6) {
          expClass = "student-learning-legend-readiness";
        }

        cluster.expectations.push(
            {
              code: exp.expectation_code,
              label: exp.expectation_label,
              students: exp.students_count,
              responses: exp.response_count_sum,
              rps: exp.responses_per_student,
              mps: exp.minutes_per_student,
              quiz: exp.avg_quiz_item_score,
              expectation_id: exp.expectation_id,
              style: expClass
            });
      }
    });
    if (cluster) {
      ctrl.clusters.push(cluster);
    }
  }

  ctrl.downloadPDF = function() {
    reportToPDF.createReportToPDF(ctrl)('printable', 'Student Summary Report', 'scrollingdiv', [1250, 1000], 'portrait');
  };
}

module.component('standardStudentLearning', {
  template: require('./studentLearningReport.jade'),
  controller: controller,
});

})();
