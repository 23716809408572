'use strict';

angular.module('client.services').factory('GoogleDrive', ['$resource', '$q', '$window', 'User', 'ModalService', 'api_server',
  function ($resource, $q, $window, User, ModalService, api_server) {

    var ouid = '';

    // One time initialization to assign the authorization token from back end authentication to front-end google api
    function init() {
      if (User.isGoogleUser()) {
        gapi.load('client', function () {
          return $resource(api_server + '/auth/google/credentials').get().$promise
            .then(function (credentials) {
              gapi.client.init({
                client_id: credentials.clientId,
                'discoveryDocs': ['https://www.googleapis.com/discovery/v1/apis/drive/v2/rest'],
                scope: 'https://www.googleapis.com/auth/drive.file'
              })
                .then(function (argument) {
                  gapi.auth.setToken({access_token: credentials.google_oauth});
                })
                .then(function (res) {
                  ouid = credentials.ouid || '';
                  return res;
                })
                .catch(function (error) {
                  console.error("Google Init Failure :", error);
                });
            });
        });
      } else {
        return $q.when();
      }
    }

    // Takes an array of google file ID's and an array of google email addresses.  Adds a permission to each file for each user
    function share(fileIds, users) {
      var permissionRequest = function (fileId, user) {
        return gapi.client.drive.permissions.insert({
          'fileId': fileId,
          'resource': {
            "role": user.role,
            "type": "user",
            "value": user.email
          }
        });
      };

      var batch = gapi.client.newBatch();
      fileIds.forEach(function (fileId) {
        users.forEach(function (user) {
          batch.add(permissionRequest(fileId, user));
        });
      });

      return batch;
    }

    function open(docUrl) {
      var separator = '?';
      var docUserUrl = docUrl;
      if (ouid && ouid.length > 0) {
        if (docUrl.indexOf(separator) !== -1) {
          separator = '&';
        }
        docUserUrl = docUserUrl + separator + "ouid=" + ouid;
      }
      $window.open(docUserUrl, '_blank');
    }

    function newGoogleFile(docURL) {
      if (ouid && ouid.length > 0) {
        docURL = docURL + '?' + 'ouid=' + ouid;
      }
      $window.open(docURL, '_blank');
    }

    function newGoogleDoc() {
      newGoogleFile('https://docs.google.com/document/create');
    }

    function newGoogleSheet() {
      newGoogleFile('https://docs.google.com/spreadsheets/create');
    }

    function newGooglePresentation() {
      newGoogleFile('https://docs.google.com/presentation/create');
    }

    return {
      share: share,
      init: init,
      open: open,
      newGoogleDoc: newGoogleDoc,
      newGoogleSheet: newGoogleSheet,
      newGooglePresentation: newGooglePresentation
    };
  }]);
