'use strict';

(function() {
var module = angular.module('client.components');

fullPageAlertController.$inject = ['xpAlert'];

function fullPageAlertController(xpAlert) {
  var ctrl = this;
   ctrl.xpAlert = xpAlert;
}

module.component('xpFullPageAlert', {
  template: require('./fullPageAlert.jade'),
  controller: fullPageAlertController,
});

})();

