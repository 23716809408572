'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['User', 'ModalService', 'userPermissions', 'PermissionConsts'];

  function controller(User, ModalService, userPermissions, PermissionConsts) {

    var ctrl = this;

    function userIsTeacher() {
      return User.getSystemRole() == User.ROLE.TEACHER;
    }

    function addSchool() {
      var config = {
        backdrop: 'static',
        template: require('./assignSchool.jade'),
      }

      ModalService.show(config);
    }

    function isDistrictAdmin() {
      return userPermissions.hasPermission(PermissionConsts.ui_show_admin_interface);
    }

    ctrl.checkAddSchool = function() {
      var user = User.getCurrentUser();
      if (user && userIsTeacher()&& !user.school_id && !isDistrictAdmin()) {
        addSchool();
      }
    };
  }

  module.component('noSchool', {
    template: require('./noSchool.jade'),
    controller: controller
  });

})();