'use strict';
(function () {
var module = angular.module('xp-element-spire-mid-level', ['client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'client.directives', 'xp-element-spire-common']);
module.controller('clientSpireMidLevelsElementCtrl', [
  '$scope', 'ElementsRestService', 'ElementsErrorService', 'spire', 'xpSpireConcepts',
  function ($scope, ElementsRestService, ElementsErrorService, spire, xpSpireConcepts) {
  spire.configureScope($scope, parseElement, displayUserResponses, isResponseModified, "mid level test");
  var level = null;
  $scope.lessons = [];

  function parseElement() {
    var element = $scope.options.element;
    var options = [];

    element.config.attributes.forEach(function (attribute) {
      var name = attribute.name;
      var value = attribute.value;
      switch (name) {
        case "level" :
          level = value;
          break;
        case "concepts" :
          if (!(value instanceof Array))
          {
            value =
              [
               {
                 name: "concepts",
                 value: value.concept
               }
               ];
          }
          $scope.lessons = parseLessons(value);
          break;
      }
    });
  }

  function parseLessons(lessons) {
    var values = [];

    // Parse the array of lessons
    lessons.forEach(function (lesson) {
      var cpt = {};
      cpt.lesson = lesson.value.lesson;
      cpt.description = lesson.value.description;
      cpt.isOpen = false;

      // Parse the decodable and spelling words
      cpt.decodable_words = spire.parseWords(lesson.value.decodable_words);
      cpt.spelling_words = spire.parseWords(lesson.value.spelling_words);

      // Create two reading models for the words
      cpt.dwModel = new spire.ReadingModel();
      cpt.dwModel.setPhrases(cpt.decodable_words);
      cpt.swModel = new spire.ReadingModel();
      cpt.swModel.setPhrases(cpt.spelling_words);

      // add to the array
      values.push(cpt);
    });

    return values;
  }

  function clearUserResponses() {
    $scope.lessons.forEach(function (lesson) {
      lesson.dwModel.setResponse(null);
      lesson.swModel.setResponse(null);
      lesson.isOpen = false;
    });
    $scope.comment.text = '';
  }

  function displayUserResponses(user) {
    clearUserResponses();
    var response = null;

    if (!angular.isUndefined(user) && user !== null) {
      response = $scope.studentResponses[user];
      if (angular.isUndefined(response) || response === null) {
        setEditing(!$scope.options.context.getViewingInactiveExperience());
      }
      else {
        setEditing(false);
        for (var index = 0; index < $scope.lessons.length; ++index) {
          $scope.lessons[index].dwModel.setResponse(response.responses[index].decodable_words);
          $scope.lessons[index].swModel.setResponse(response.responses[index].spelling_words);
        }
        $scope.comment.text = response.comment;
      }
    }
    else {
      setEditing(false);
    }
  }

  function setEditing(value) {
    $scope.lessons.forEach(function (lesson) {
      lesson.dwModel.setEditing(value);
      lesson.swModel.setEditing(value);
    });
  }

  function isEditing() {
    return $scope.lessons[0].dwModel.isEditing();
  }

  $scope.isEditing = isEditing;

  $scope.canSubmit = function () {
    return isEditing();
  };

  $scope.getEditMenuItems = function () {
    var menuOptions =
      [
       {
         text: '<div class="xp-element-menu-edit">Edit</div>',
         click: 'requestEdit()'
       },
       {
         divider: true
       },
       {
         text: '<div class="xp-element-menu-delete">Delete</div>',
         click: 'requestDelete()'
       }
       ];

    if (isEditing()) {
      menuOptions =
        [
         {
           text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
           click: 'cancelEdit()'
         }
         ];
    }

    return menuOptions;
  };

  $scope.requestEdit = function () {
    setEditing(true);
  };

  $scope.cancelEdit = function () {
    if (isResponseModified()) {
      $scope.displayChangeWarning($scope.selectedStudent);
    }
    else {
      setEditing(false);
    }
  };

  $scope.didSubmit = function () {
    if ($scope.selectedStudent === null || !$scope.isTeacher)
      return;

    var response = {};
    response.responses = [];
    $scope.lessons.forEach(function (lesson) {
      var resp = {
          decodable_words: lesson.dwModel.getResponse(),
          spelling_words: lesson.swModel.getResponse()
      };
      response.responses.push(resp);
    });

    response.comment = $scope.comment.text;

    ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.selectedStudent, -1, JSON.stringify(response),
        function () {
      for (var index = 0; index < $scope.lessons.length; ++index) {
        $scope.lessons[index].dwModel.setResponse(response.responses[index].decodable_words);
        $scope.lessons[index].swModel.setResponse(response.responses[index].spelling_words);
      }
      setEditing(false);
    },
    function (error) {
      ElementsErrorService.error(error);
    });
  };

  function isResponseModified() {
    var isModified = false;
    $scope.lessons.forEach(function (lesson) {
      isModified = isModified || lesson.dwModel.isModified() || lesson.swModel.isModified();
    });

    return isModified || isCommentModified();
  }

  function isCommentModified() {
    var selectedStudent = $scope.selectedStudent;
    if (selectedStudent !== null) {
      var response = $scope.studentResponses[selectedStudent];
      if (response) {
        return $scope.comment.text != response.comment;
      }
    }

    return $scope.comment.text.length > 0;
  }

  $scope.correct = function (lesson) {
    return lesson.dwModel.getScore() + lesson.swModel.getScore();
  };

  $scope.possible = function (lesson) {
    return lesson.dwModel.getPossible() + lesson.swModel.getPossible();
  };

  $scope.score = function (lesson) {
    return Math.round(((lesson.dwModel.getScore() + lesson.swModel.getScore()) /
        (lesson.dwModel.getPossible() + lesson.swModel.getPossible())) * 100);
  };

  $scope.isPassingScore = function (lesson) {
    return $scope.score(lesson) >= 80;
  };

  $scope.getLessonLabel = function(lesson) {
    var concepts = xpSpireConcepts(level, lesson.lesson);

    if (!concepts || concepts.length === 0) return '';

    return 'Concept' + (concepts.length === 1 ? ' ' + concepts[0] : 's ' + concepts[0] + '&ndash;' + concepts[concepts.length - 1]);
  };

}]);
})();