'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = ['NgTableParams'];

  function controller (NgTableParams) {
    var ctrl = this;
    
    ctrl.tableParams = new NgTableParams(
      {
        count: 5,
        sorting: {id: 'desc'}
      },
      {
        counts: [],
        dataset: ctrl.tableData
      }
    );
    
    ctrl.$onChanges = function(changes) {
      if (changes.tableData && changes.tableData.currentValue) {
        ctrl.tableParams.settings({
          dataset: changes.tableData.currentValue
        });
      }
      if (changes.defaultSort && changes.defaultSort.currentValue) {
        ctrl.tableParams.sorting(changes.defaultSort.currentValue, "desc");
      }
    };
    
    ctrl.getStyleClass = function (columnIndex) {
      return ctrl.columns[columnIndex].style||"";
    };
    
  }

  module.component('xpDataTable', {
    template: require('./dataTable.jade'),
    controller: controller,
    bindings: {
      title: '=',
      columns: '=',
      tableData: '<',
      defaultSort: '<'
    }
  });

})();