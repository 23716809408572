'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$element', '$attrs', '$log', 'VideoStreamingService', 'OT'];

  function controller ($scope, $element, $attrs, $log, VideoStreamingService, OT) {
    var ctrl = this;

    ctrl.publisherProps = {
        style: {nameDisplayMode: 'off', buttonDisplayMode: 'off'},
        resolution: '1280x720',
        frameRate: 30,
        mirror: false,
        publishVideo: false,
        publishAudio: true
    };

    ctrl.publisher = null;

    function initPublisher() {
      // Initialize all the callback handlers from opentok
      initializeSessionHandlers();

      ctrl.publisherProps.publishVideo = ctrl.videoEnabled;
      ctrl.publisherProps.publishAudio = ctrl.audioEnabled;

      // The teacher needs to initialize as a publisher
      ctrl.publisher = OT.initPublisher(ctrl.apiKey, $element[0], ctrl.publisherProps, function(err){
        if (err) {
          $log.error("Failed to initialize publisher: " + err);
          return false;
        }

        // Initialize all the callbacks needed from the publisher
        initializePublisherHandlers();

        ctrl.session.publish(ctrl.publisher, function(err) {
          if (err) {
            $log.error("Failed to publish publisher: " + err);
            return false;
          }

          $log.info("initPublisher just published " + ctrl.publisher.streamId);

          if (ctrl.teacher) {
            return VideoStreamingService.setTeacherStreamingStatus(ctrl.experienceId, "on", 0);
          }
        });
      });
    }

    ctrl.$onChanges = function(changes) {
      if (changes.session && (!changes.session.previousValue || changes.session.isFirstChange()) && changes.session.currentValue) {
        initPublisher(changes.session.currentValue);
      }
      if (ctrl.publisher) {
        if (changes.videoEnabled) {
          ctrl.publisher.publishVideo(changes.videoEnabled.currentValue);
        }
        if (changes.audioEnabled) {
          ctrl.publisher.publishAudio(changes.audioEnabled.currentValue);
        }
      }
    };

    ctrl.$onDestroy = function () {
      if (ctrl.session && ctrl.publisher) {
        $log.info("About to unpublish " + ctrl.publisher.streamId);
        ctrl.session.unpublish(ctrl.publisher, function(err) {
          if (err) {
            $log.error("failed to unpublish publisher: " + err);
            return false;
          }
          ctrl.publisher = null;
        });
      }
    };

    function initializeSessionHandlers() {
      // Dispatched locally by the Session object when the connection is established
      ctrl.session.on("sessionConnected", function (event) {
        $log.info("The session connected. " + JSON.stringify(event));
      });

      // The Session object dispatches SessionDisconnectEvent object when a session has disconnected
      ctrl.session.on("sessionDisconnected", function (event) {
        $log.info("The session disconnected. " + event.reason);
      });

      // A new stream, published by another client, has been created on this session
      ctrl.session.on("streamCreated", function (event) {
        $log.info("A new stream was created. " + event.stream.id);
      });

      // A stream from another client has stopped publishing to the session.
      ctrl.session.on("streamDestroyed", function (event) {
        $log.info("A stream was destroyed. " + event.stream.id);
      });

      // The Session object dispatches a connectionCreated event when a client (including your own) connects to a Session
      ctrl.session.on("connectionCreated", function (event) {
        $log.info("A connection was created. " + event.connection.id);
      });

      // The Session object dispatches a connectionCreated event when a client (including your own) connects to a Session
      ctrl.session.on("connectionDestroyed", function (event) {
        $log.info("A connection was destroyed. " + event.connection.id);
      });
    }

    function initializePublisherHandlers() {
      ctrl.publisher.on("accessDenied", function(event) {
        $log.info("Publisher access denied");
      });

      ctrl.publisher.on("accessAllowed", function(event) {
        $log.info("Publisher access allowed");
      });

      ctrl.publisher.on("loaded", function(event) {
        $log.info("Publisher loaded");
      });

      ctrl.publisher.on("streamCreated", function(event) {
        $log.info("Publisher stream created");
      });

      ctrl.publisher.on("streamDestroyed", function(event) {
        $log.info("Publisher stream destroyed");
      });

      ctrl.publisher.on("videoElementCreated", function(event) {
        $log.info("Publisher video element created");
      });
    }
  }

  module.component('publisher', {
    template: require('./publisher.jade'),
    controller: controller,
    bindings: {
      teacher: '<',
      session: '<',
      apiKey: '<',
      experienceId: '<',
      videoEnabled: '<',
      audioEnabled: '<'
    }
  });
})();
