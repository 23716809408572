'use strict';

(function () {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$location', '$log', 'ClassCodes', 'ClassCodeValidator', 'ActiveMode', 'ClassMode',
    'CourseFactory', 'User', 'GoogleOauthUrlFactory', 'UserSignupFactory', '$timeout', 'loginService',
    '$window', 'UserCourses', 'CleverOauthUrlFactory', 'api_path'];

  function controller($scope, $location, $log, ClassCodes, ClassCodeValidator, ActiveMode, ClassMode,
                      CourseFactory, User, GoogleOauthUrlFactory, UserSignupFactory, $timeout, loginService,
                      $window, UserCourses, CleverOauthUrlFactory, api_path) {
    var ctrl = this;
    ctrl.activePage = 1;
    ctrl.xpError = {};
    ctrl.classCode = "";
    ctrl.showEmailForm = false;
    ctrl.student = {};

    // See if this is a logged in student or a new student
    ctrl.isStudentConnected = $location.path().includes("student");
    ctrl.isTeacherConnected = $location.path().includes("teacher");

    function validateCode() {
      if (ctrl.isTeacherConnected) {
        return ClassCodeValidator.validateCoteacherCode({}, {'code': ctrl.classCode}).$promise;
      } else {
        return ClassCodeValidator.validateCode({}, {'code': ctrl.classCode}).$promise;
      }
    }

    ctrl.connectToClass = function (isValid) {
      ctrl.xpError.details = '';
      if (!ctrl.classCode) {
        ctrl.xpError.details = 'Please enter a valid class code.';
        return;
      }

      // Make a call to the server to validate the class code
      validateCode().then(function (existingClass) {
        // if this contains an error then the code was invalid
        if (!existingClass.error) {
          ctrl.teacher = existingClass.teacher_name;
          ctrl.className = existingClass.class_name;
          ctrl.classId = existingClass.cid;
          ctrl.activePage = 2;
        } else {
          ctrl.xpError.details = existingClass.error.description;
        }
      }).catch(function (error) {
        ctrl.xpError.details = error.message;
      });
    };

    function joinClass() {
      if (ctrl.isStudentConnected) {
        return CourseFactory.addStudents({'cid': ctrl.classId, 'students': angular.toJson([User.getCurrentUser()])});
      } else {
        return CourseFactory.addTeachers({'cid': ctrl.classId, 'teachers': angular.toJson([User.getCurrentUser()])});
      }
    }

    function createStudentAddToClass(auth) {
      return CourseFactory.createAndAddToClass({'cid': ctrl.classId, 'auth': auth});
    }

    ctrl.connectToClassVerified = function () {
      // If this student was logged in then connect this student to the specified class
      if (ctrl.isStudentConnected || ctrl.isTeacherConnected) {
        joinClass().then(function (result) {
          if (result.error) {
            $log.error("error in connectToClassVerified on CourseFactory.addStudents:", result.error);
            ctrl.xpError.details = result.error.description;
          } else {
            // need to reload class list to include this new class
            UserCourses.updateCachedCourses().then(function (courses) {
              // Added to class so navigate to this class
              ClassMode.goToClass(ctrl.classId);
            });
          }
        })
        .catch(function (error) {
          $log.error("error in connectToClassVerified: ", error);
          ActiveMode.navigateToDefaultView();
        });
      } else {
        var auth = $location.search().auth;
        if (auth) {
          createStudentAddToClass(auth).then(function (result) {
            if (result && result.redirect) {
              $window.location.href = result.redirect;
            } else if (result && result.error) {
              $log.error("error in connectToClassVerified creating student and adding to class:", result.error);
              ctrl.xpError.details = result.error.description;
            }
          })
          .catch(function (error) {
            $log.error("error in connectToClassVerified: ", error);
            ActiveMode.navigateToDefaultView();
          });
        } else {
          // If this is a new student then they need to provide there info and be added
          ctrl.activePage = 3;
        }
      }
    };

    ctrl.signupWithGoogle = function () {
      var system_role = 'STUDENT';
      GoogleOauthUrlFactory.signup({
        'system_role': system_role,
        'classCode': ctrl.classCode
      }, function (data) { //Success callback

        // Handle error
        if (typeof data.error == 'object') {
          ctrl.xpError.details = data.error.description;
        } else {
          $timeout(function () {
            $scope.$apply(function () {
              $window.location.href = data.url;
            });
          });
        }

      }, function (error) { //Failure callback
        $log.error("user signupWithGoogle error:", error);
        $location.path('/login');
      });
    };

    ctrl.signupWithClever = function () {
      var system_role = 'STUDENT';
      // $location.path('/auth/clever/signup?system_role=STUDENT&classCode='+ctrl.classCode);
      $log.info("redirecting to clever");
      $window.location.href = api_path + '/auth/clever/signup?system_role=STUDENT&classCode=' + ctrl.classCode;

      // CleverOauthUrlFactory.signup({
      //   'system_role': system_role,
      //   'classCode': ctrl.classCode
      // }, function(data) { //Success callback
      //
      //   // Handle error
      //   if (typeof data.error == 'object') {
      //     $log.error("clever login error", data.error);
      //     ctrl.xpError.details = data.error.description;
      //   }
      //   else {
      //     $timeout(function() {
      //       $scope.$apply(function() {
      //         $log.info("redirect to %s", data.url);
      //         $window.location.href = data.url;
      //       });
      //     });
      //   }
      //
      // }, function(error) { //Failure callback
      //   $log.error("user signupWithClever error:",error);
      //   $location.path('/login');
      // });
    };

    ctrl.saveStudentInfo = function (isValid) {
      if (!isValid) {
        ctrl.xpError.details = 'Student account cannot be saved. Please fix the errors.';
        return;
      }
      // Additional verification for password1/password2 must match
      if (ctrl.student.password != ctrl.student.verify) {
        ctrl.xpError.details = 'Passwords do not match. Please re-enter values.';
        return;
      }

      UserSignupFactory.post({
        'email': ctrl.student.email,
        'username': ctrl.student.email,
        'password': ctrl.student.password,
        'first_name': ctrl.student.first_name,
        'last_name': ctrl.student.last_name,
        'system_role': 'STUDENT',
        'classId': ctrl.classId
      }).$promise.then(function (data) {
        // Handle and error object
        if (data.error) {
          if (data.error.description && data.error.description.message) {
            ctrl.xpError.details = data.error.description.message;
          } else if (data.error.type) {
            switch (data.error.type) {
              case "AlreadyInUse":
                ctrl.xpError.details = "You have already signed up for Exploros using this email account. " +
                  "Login and select Join a class using the provided class code.";
                break;
              case "application_error":
                ctrl.xpError.details = data.error.description;
                break;
              default:
                ctrl.xpError.details = "An error occurred while adding this new user. " +
                  "Check your name, email and password to make sure they are valid.";
            }
          } else {
            ctrl.xpError.details = "An error occurred while adding this new user. " +
              "Check your name, email and password to make sure they are valid.";
          }
          return;
        }
        // Show this page since it is active page
        loginService.login(ctrl.student.email, ctrl.student.password)
          .then(function (data) { //Success callback
            // if the return object is an error object then display the error information in the login dialog
            if (data.error) {
              $log.error("error in saveStudentInfo on user login attempt:", data.error);
              $location.path('/login').replace();
            } else if (!data.user) {
              $log.error('failed login on saveStudentInfo');
              return;
            } else {
              // need to reload class list to include this new class
              UserCourses.updateCachedCourses().then(function (courses) {
                // Added to class so navigate to this class
                ClassMode.goToClass(ctrl.classId);
              });
            }
          })
          .catch(function (error) { //Failure.  Just re-show the login
            $log.error("error on user login attempt:", error);
            $location.path('/login');
          });
      })
        .catch(function (error) { //Failure callback
          $log.error("error on user signup attempt:", error);
          ctrl.xpError.details = "An error occurred during signup. Your e-mail already exists.  " +
            "If you have already signed up you can reset your password on the login screen.";
        });
    };

    ctrl.onCancelLink = function () {
      if (ctrl.isStudentConnected || ctrl.isTeacherConnected) {
        ActiveMode.navigateToDefaultView();
      } else {
        $location.path('/login');
      }
    };
  }

  module.component('xpConnectToClass', {
    template: require('./connectToClass.jade'),
    controller: controller
  });
})();
