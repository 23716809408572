'use strict';
(function () {
var module = angular.module('xp-element-common', ['angularWidget']);
module.controller('clientCommonElementCtrl', ['$scope', 'widgetConfig', function ($scope, widgetConfig) {
//    widgetConfig.exportProperties({title: 'Element'});
	$scope.options = widgetConfig.getOptions($scope);
}]);

module.run(function ($http, $compile, $templateCache) {
  $compile(require('./template.jade'));
});

module.directive('xpWidget', ['$compile', 'ElementsRestService', 'RespondentType', 'SHARE_MODE', 'CorrectResponseId', 'ActiveExperience',
  function ($compile, ElementsRestService, RespondentType, SHARE_MODE, CorrectResponseId, ActiveExperience) {
	return {
		restrict: 'E',
		scope: {
			src: '=',
			options: '=',
			filteredStudent: '=',
			savingElementId: '=',
			scoreSaved: '&',
			selectionMade: '&',
			changed: '&',
			cached: '&'
		},
		replace: true,
		link: link
	};

	function link(scope, element, attrs) {
		var src = scope.src;
		var template = "<ng-include id=" + scope.options.element.id + " src='\"elements/" + src + "/template.jade\"'/>";
		var cTemplate = $compile(template)(scope);
		element.append(cTemplate);

		var respondentId;

		function filterAnswers(answers) {
			var retval = [];

			if (scope.filteredStudent !== null &&
				!angular.isUndefined(scope.filteredStudent) &&
				scope.filteredStudent.report !== 1 &&
				answers instanceof Array) {
				answers.forEach(function(answer){
					if (answer.user_id == scope.filteredStudent.uid) {
						retval.push(answer);
					}
				});

				return retval;
			}

			return answers;
		}
		scope.filterAnswers = filterAnswers;

		function getRespondent() {
			if (scope.filteredStudent !== null && !angular.isUndefined(scope.filteredStudent)) {
			  if (scope.filteredStudent.report !== 1) {
	        if (SHARE_MODE.isUsingSmallGroups(scope.share)) {
	          if (scope.filteredStudent.small_group &&
	              scope.filteredStudent.small_group !== 0) {
	            return scope.filteredStudent.small_group;
	          }
	        }
	        else {
	          return scope.filteredStudent.uid;
	        }
			  }
			}

			return respondentId;
		}

		scope.getRespondent = getRespondent;

		function setRespondent(respondent) {
			respondentId = respondent;
		}

		scope.setRespondent = setRespondent;

    function Respondent(respondent) {
      var _this = this;

      this.getType = function getType() {
        return RespondentType.USER;
      };

      this.getDisplayId = function getDisplayId() {
        if (_this.getType() === RespondentType.GROUP && context.getIsTeacherSmallGroupPost(respondent)) {
          return 'T';
        } else {
          return respondent;
        }
      };

      this.isSelected = function isSelected() {
        return false;
      };

      this.getSize = function getSize() {
        return 40;
      };

      this.select = function select() {
      };

      this.isCheckMark = function isCheckMark() {
        return false;
      };

      this.isHidden = function isHidden () {
        if (respondent == CorrectResponseId.CORRECTANSWERS && ActiveExperience.currentExperience().hideTeacherNotes) {
          return true;
        } else {
          return false;
        }
      };

      this.getDisplayName = function getDisplayName() {
        if (_this.getType() === RespondentType.GROUP) {
          if (context.userIsTeacher()) {
            return 'Teacher';
          } else {
            return 'Group ' + respondent;
          }
        }

        if (_this.isCheckMark()) {
          return 'Correct Answers';
        }

        return context.getUserDisplayName(_this.getDisplayId(respondent));
      };
    }

    scope.Respondent = Respondent;

    var unregisterOptionsWatch = scope.$watch('options', onOptionsSet, true);
    var context;

    function onOptionsSet() {
      if (scope.options.context) {
        if (scope.options.element &&
            scope.options.element.config &&
            scope.options.element.config.attributes) {
          scope.options.element.config.attributes.forEach(function(attribute) {
            switch (attribute.name)
            {
              case "share" :
                scope.share = attribute.value;
                break;
            }
          });
        }

        unregisterOptionsWatch();
        context = scope.options.context;
      }
    }

    scope.isQuiz = function() {
      return scope.options.quiz;
    }

    if(scope.options && scope.options.element && scope.options.context){
      if(scope.$parent && scope.$parent.previewEnabled){
        return;
      }
      var trackData = JSON.stringify({
        experience_id: scope.options.context.experienceId,
        scene_id: scope.options.scene_id,
        cluster_id: scope.options.cluster_id,
        element_id: scope.options.element.id,
        element_template_id: scope.options.element.template_id,
        element_type: scope.options.element.type,
        class_id: scope.options.context.clazz.classId,
        small_group_id: scope.options.context.groupId,
        status: scope.options.context.status
      });
      element[0].setAttribute('data-xp-bi-tracking-element',trackData);
    }
	}
}]);

module.constant('RespondentType', {USER: 'USER', GROUP: 'GROUP'});

module.directive('xpRespondent', ['RespondentType', function(RespondentType) {
  return {
    restrict: 'E',
    scope: {
      respondent: '='
    },
    templateUrl: 'xpRespondentDirective.tpl',
    link: link
  };

  function link(scope, element, attrs) {
    scope.RespondentType = RespondentType;
  }
}]);

module.constant('CorrectResponseId', {CORRECTANSWERS: -1});

module.directive('xpRespondentIcons', function () {
  return {
    restrict: 'E',
    scope: {
      respondents: '=',
      wrap: '&'
    },
    templateUrl: 'xpRespondentIconsDirective.tpl',
    link: link
  };

  function link(scope, element, attrs) {
    scope.isSelected = function(respondent) {
      return respondent.isSelected && respondent.isSelected();
    };

    if ('wrap' in scope) {
      scope.$watch('respondents', function () {
        if (scope.respondents instanceof Array) {
          scope.wrappedRespondents = scope.respondents.map(scope.wrap());
//          for (var zx = 0; zx < 25; ++zx) {scope.wrappedRespondents.push(scope.wrappedRespondents[0]);}
        }
      }, true);
    } else {
      scope.wrappedRespondents = scope.respondents;
//      for (var zx = 0; zx < 25; ++zx) {scope.wrappedRespondents.push(scope.wrappedRespondents[0]);}
    }

  }
});

module.directive('xpStudentFeedbackAlert', ['$timeout', '$sce', function ($timeout, $sce) {
  return {
    restrict: 'E',
    scope: {
      status: '=',
      retry: '&'
    },
    templateUrl: 'xpStudentFeedbackAlert.tpl',
    link: link
  };

  function link(scope, element, attrs) {
    var isVisible = false;

    scope.close = function() {
      isVisible = false;
    }

    scope.isVisible = function() {
      return isVisible;
    }

    scope.isCorrect = function() {
      return scope.status === 1;
    }

    scope.isPartial = function() {
      return scope.status === -1;
    }

    scope.isInCorrect = function() {
      return scope.status === 0;
    }

    scope.onRetry = function() {
      isVisible = false;
      scope.retry();
    }

    scope.$watch('status', function (newVal, oldVal) {
      if (newVal === 1 || newVal === 0 || newVal === -1) {
        isVisible = true;
        // if all responses are correct then close this after certain period
        if (newVal === 1) {
          $timeout(function() {
            isVisible = false;
          }, 5000);
        }
      }
      else {
        isVisible = false;
      }
    }, true);
  }
}]);

})();
