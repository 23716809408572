'use strict';
(function(){
var module = angular.module('xp-element-spire-concept-mastery-fluency-drill', ['client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'client.directives', 'xp-element-spire-common']);
module.controller('clientSpireConceptMasteryFluencyDrillElementCtrl', ['$scope', '$log', 'ElementsRestService', 'ElementsErrorService', 'ModalService', 'spire',
                                                                         function ($scope, $log, ElementsRestService, ElementsErrorService, ModalService, spire) {
  spire.configureScope($scope, parseElement, displayUserResponses, isResponseModified, "concept mastery fluency drill");

  var timedReadingModel = new spire.TimedReadingModel();
  $scope.timedReadingModel = timedReadingModel;

  function parseElement()
  {
    var element = $scope.options.element;
    var options = [];

    element.config.attributes.forEach(function(attribute)
      {
        var name = attribute.name;
        var value = attribute.value;
        switch (name)
        {
          case "words" :
            timedReadingModel.setPhrases(spire.parseWords(value));
            break;
        }
      });
  }

  function clearUserResponses()
  {
    timedReadingModel.clearResponse();
    $scope.comment.text = '';
  }

  function displayUserResponses(user)
  {
    clearUserResponses();
    var response = null;

    if (!angular.isUndefined(user) && user !== null)
    {
      response = $scope.studentResponses[user];
      if (angular.isUndefined(response) || response === null)
      {
        setEditing(!$scope.options.context.getViewingInactiveExperience());
      }
      else
      {
        setEditing(false);
        $scope.comment.text = response.comment;
      }
    }
    else
    {
      setEditing(false);
    }

    timedReadingModel.setResponse(response);
  }

  function setEditing(value)
  {
    timedReadingModel.setEditing(value);
  }

  function isEditing()
  {
    return timedReadingModel.isEditing();
  }

  $scope.isEditing = isEditing;

  $scope.isPastExperience = function() {
    return $scope.options.context.getViewingInactiveExperience();
  };

  $scope.canSubmit = function()
  {
    return isEditing() && timedReadingModel.getWordsPerMinute() !== null;
  };

  $scope.getEditMenuItems = function()
  {
    var editWordsOption =
    {
        text: '<div class="xp-element-menu-edit">Edit Words</div>',
        click: 'requestEdit()'
    };

    var editLastWordOption =
    {
      text: '<div class="xp-element-menu-edit">Edit Last Word</div>',
      click: 'requestEditLastWord()'
    };

    var menuOptions =
    [
      editWordsOption,
      ModalService.divider,
      {divider: true},
      editLastWordOption,
      {divider: true},
      {
        text: '<div class="xp-element-menu-delete">Delete</div>',
        click: 'requestDelete()'
      }
    ];

    if (isEditing())
    {
      var editOption = isMarkingLast() ? editWordsOption : editLastWordOption;

      menuOptions =
      [
        editOption,
        {divider: true},
        {
          text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
          click: 'cancelEdit()'
        }
      ];
    }

    return menuOptions;
  };

  $scope.requestEdit = function()
  {
    setEditing(true);
    setMarkingLast(false);
  };

  $scope.requestEditLastWord = function()
  {
    setEditing(true);
    setMarkingLast(true);
  };

  $scope.cancelEdit = function()
  {
    if (isResponseModified())
    {
      $scope.displayChangeWarning($scope.selectedStudent);
    }
    else
    {
      setEditing(false);
      setMarkingLast(false);
    }
  };

  $scope.didSubmit = function()
  {
    if ($scope.selectedStudent === null || !$scope.isTeacher || !$scope.canSubmit())
      return;

    var response = timedReadingModel.getResponse();
    response.comment = $scope.comment.text;

    ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.selectedStudent, -1, JSON.stringify(response),
      function() {
        timedReadingModel.setResponse(response);
        setEditing(false);
        setMarkingLast(false);
      },
      function(error) {
        ElementsErrorService.error(error);
      });
  };

  function isResponseModified()
  {
    return timedReadingModel.isModified() || isCommentModified();
  }

  function isCommentModified()
  {
    var selectedStudent = $scope.selectedStudent;
    if (selectedStudent !== null)
    {
      var response = $scope.studentResponses[selectedStudent];
      if (response)
      {
        return $scope.comment.text != response.comment;
      }
    }

    return $scope.comment.text.length > 0;
  }

  $scope.canSubmit = function()
  {
    return $scope.isEditing() && timedReadingModel.getWordsPerMinute() !== null;
  };

  function isMarkingLast()
  {
    return timedReadingModel.isMarkingLastPhrase();
  }

  function setMarkingLast(value)
  {
    timedReadingModel.setMarkingLastPhrase(value);
  }
}]);
})();
