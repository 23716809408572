'use strict';

(function () {
  var module = angular.module('client.components');

  controller.$inject = ['$resource', '$log', 'User', '$location', '$routeParams', 'ExperienceNavigator'];

  function controller($resource, $log, User, $location, $routeParams, ExperienceNavigator) {
    var ctrl = this;

    var xpId = $routeParams.id;
    if (!xpId) {
      $log.error("no experience id provided");
    }
    var xpLink = '/experience/' + xpId + '/activity/scene/0';
    ctrl.xpLink = xpLink;
    User.hasLoggedIn().then(function (hasLogged) {
      if (hasLogged && User.getId()) {
        ExperienceNavigator.navigateToExperience(xpLink, "", '/experience');
      } else {
        $location.path('/login');
      }
    });

  }

  module.component('classroomAssignment', {
    template: require('./classroomAssignment.jade'),
    controller: controller
  });

})();
