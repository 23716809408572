'use strict';

angular.module('client.services').factory('spireReportingModels', ['spireReportingService', function (spireReportingService) {

  function getPlacementTableModel(students, levels, placements) {
    return new PlacementDataTableModel(students, levels, placements);
  }

  function PlacementDataTableModel(students, levels, placements) {
    function parsePlacementScores(placements) {
      var scores = {};
      placements.forEach(function(placement) {
        scores[placement.student_id] = scores[placement.student_id] || {};
        if (scores[placement.student_id][placement.placement_id]) {
          scores[placement.student_id][placement.placement_id].push(placement);
        } else {
          scores[placement.student_id][placement.placement_id] = [placement];
        }
      });
      return scores;
    }

    var scores = parsePlacementScores(placements);

    this.getStudents = function(classId) {
      var classStudents = students.filter(function(student) { return !student.cid || student.cid === classId});
      return classStudents;
    };

    this.getLevels = function() {
      return levels;
    };

    this.hasMultipleScores = function(studentId, levelId) {
      return scores[studentId] && scores[studentId][levelId] && scores[studentId][levelId].length > 1;
    };

    this.getExperienceDate = function(studentId, levelId, index) {
      if (scores[studentId] && scores[studentId][levelId] && scores[studentId][levelId].length && index < scores[studentId][levelId].length) {
        return window.moment(scores[studentId][levelId][index].updated_at).format('M/DD/YY');
      }
    };

    this.getLastExperienceDate = function(studentId, levelId) {
      if (scores[studentId] && scores[studentId][levelId] && scores[studentId][levelId].length) {
        return window.moment(scores[studentId][levelId][scores[studentId][levelId].length - 1].updated_at).format('M/DD/YY');
      }
    };

    this.getErrorCount = function(studentId, levelId, index) {
      if (scores[studentId] && scores[studentId][levelId] && scores[studentId][levelId].length && index < scores[studentId][levelId].length) {
        return scores[studentId][levelId][index].incorrect_word_count;
      }
    };

    this.getExperienceId = function(studentId, levelId, index) {
      if (scores[studentId] && scores[studentId][levelId] && scores[studentId][levelId].length && index < scores[studentId][levelId].length) {
        return scores[studentId][levelId][index].experience_id;
      }
    };

    this.hasScore = function(studentId) {
      return scores[studentId];
    };

    this.getStudentPlacement = function(studentId) {
      if (scores[studentId]) {
        var maxPlacementId = 0;
        Object.keys(scores[studentId]).forEach(function(score) {
          scores[studentId][score].forEach(function(expScore) {
            if (expScore.placement_id > maxPlacementId) {
              maxPlacementId = expScore.placement_id;
            }
          });
        });
        if (maxPlacementId > 0) {
          var score = scores[studentId][maxPlacementId][scores[studentId][maxPlacementId].length - 1];
          var level = levels.find(function(level) { return level.id === maxPlacementId; });
          if (level) {
            score.placement = level.level_name;
          }
          return score;
        }
      }
    };

    function getScores(studentId, levelId) {
      if (scores[studentId] && scores[studentId][levelId]) {
        return scores[studentId][levelId];
      } else {
        return null;
      }
    }

    this.getStudentHasData = function(student) {
      return this.hasScore(student.id);
    };

    this.getHasPlacementData = function() {
      var studentsWithData = Object.keys(scores).map(function(student) { return true; });
      return studentsWithData && studentsWithData.length;
    };

    this.getCellDropdown = function(studentId, levelId) {
      var scores = getScores(studentId, levelId);
      var retval = {
        template: 'spirePlacementPopup.tpl',
        scores: scores
      };
      return retval;
    };
  }

  function getCAStudentDataTableModel(students, lessons, data) {
    return new CAStudentDataTableModel(students, lessons, data);
  }

  function CAStudentDataTableModel(students, lessons, scores) {
    function parseConceptAssessmentScores(scores) {
      spireReportingService.sortScoresByDate(scores);

      var retval = {};
      var studentsWithData = {};
      scores.forEach(function(score) {
        score.score = Math.round(score.score);
        var studentId = score.student_id;
        var level = score.level;
        var lessonId = score.lesson;
        var classId = score.class_id;

        var levelScores = spireReportingService.getOrCreateHive(retval, level);
        var lessonScores = spireReportingService.getOrCreateHive(levelScores, lessonId);
        var classScores = spireReportingService.getOrCreateHive(lessonScores, classId);
        var studentScores = spireReportingService.getOrCreateHive(classScores, studentId);

        if (!studentScores.displayScore) {
          studentScores.displayScore = score.score;
          studentScores.scores = [];
        }

        if (studentScores.displayScore < score.score) {
          studentScores.displayScore = score.score;
        }

        if (!studentsWithData[studentId]) {
          studentsWithData[studentId] = {};
        }

        if (!studentsWithData[studentId][level]) {
          studentsWithData[studentId][level] = 1;
        }

        studentScores.scores.push(score);
      });

      retval.studentsWithData = studentsWithData;
      retval.overall = spireReportingService.calculateOverallScores(retval);
      return retval;
    }

    var data = parseConceptAssessmentScores(scores);

    this.getStudents = function(classId) {
      var classStudents = students.filter(function(student) { return !student.cid || student.cid === classId});
      return classStudents;
    };

    this.getColumns = function(level) {
      if (level && lessons && lessons.hasOwnProperty(level)) {
        return lessons[level];
      } else {
        return [];
      }
    };

    this.getOverallCellValue = function(level, lesson, classId) {
      if (data.overall && data.overall[level] && data.overall[level][lesson.lesson])
        return data.overall[level][lesson.lesson][classId];

      return undefined;
    };

    this.getCellValue = function(level, student, lesson, classId) {
      return getDisplayScore(level, student, lesson, classId);
    };

    this.formatScore = function (score) {
      if (!angular.isUndefined(score)) {
        return score + '%';
      }
      return score;
    };

    this.getScoreClass = function(score) {
      if (angular.isUndefined(score) || score === null) {
        return 'undefined-score';
      }

      if (score >= 80) {
        return 'accomplished-score';
      }
      return 'needs-attention-score';
    };

    this.getCellDropdown = function(level, student, lesson, classId) {
      var scores = getScores(level, student, lesson, classId);
      var retval = {
        template: 'spireClassLevelCAPopup.tpl',
        scores: scores,
        lesson: lesson
      };
      return retval;
    };

    this.getLegend = function() {
      return 'spireClassLevelCALegend.tpl';
    };

    function getScore(level, student, lesson, classId) {
      try {
        if (data &&
          data[level] &&
          data[level][lesson.lesson] &&
          data[level][lesson.lesson][classId][student.id] &&
          data[level][lesson.lesson][classId][student.id] !== null &&
          !angular.isUndefined(data[level][lesson.lesson][classId][student.id])) {
            return data[level][lesson.lesson][classId][student.id];
          }
      }
      catch(e) {
        // ignore exception.
      }
      return undefined;
    }

    function getDisplayScore(level, student, lesson, classId) {
      var score = getScore(level, student, lesson, classId);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }

      return score.displayScore;
    }

    function getScores(level, student, lesson, classId) {
      var score = getScore(level, student, lesson, classId);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }

      return score.scores;
    }

    this.getStudentHasData = function(student, levelId) {
      return data.studentsWithData && data.studentsWithData[student.id] && data.studentsWithData[student.id][levelId];
    };
  }

  function getCMFDStudentDataTableModel(students, concepts, data) {
    return new CMFDStudentDataTableModel(students, concepts, data);
  }

  function CMFDStudentDataTableModel(students, concepts, scores) {
    function parseCMFDScores(scores) {
      spireReportingService.sortScoresByDate(scores);

      var retval = {};
      var studentsWithData = {};
      scores.forEach(function(score) {
        var studentId = score.student_id;
        var level = score.level;
        var conceptId = score.concept;
        var classId = score.class_id;

        var levelScores = spireReportingService.getOrCreateHive(retval, level);
        var conceptScores = spireReportingService.getOrCreateHive(levelScores, conceptId);
        var classScores = spireReportingService.getOrCreateHive(conceptScores, classId);
        var studentScores = spireReportingService.getOrCreateHive(classScores, studentId);

        if (!studentScores.scores) {
          studentScores.scores = [];
        }

        if (!studentsWithData[studentId]) {
          studentsWithData[studentId] = {};
        }

        if (!studentsWithData[studentId][level]) {
          studentsWithData[studentId][level] = 1;
        }

        studentScores.scores.push(score);
        studentScores.displayScore = score.score; // Display last score, it will be most recent because we sorted by date above
      });

      retval.studentsWithData = studentsWithData;
      retval.overall = spireReportingService.calculateOverallScores(retval);
      return retval;
    }

    var data = parseCMFDScores(scores);

    this.getStudents = function(classId) {
      var classStudents = students.filter(function(student) { return !student.cid || student.cid === classId});
      return classStudents;
    };

    this.getColumns = function(level) {
      if (level && concepts && concepts.hasOwnProperty(level)) {
        return concepts[level];
      } else {
        return [];
      }
    };

    this.getOverallCellValue = function(level, concept, classId) {
      if (data.overall && data.overall[level] && data.overall[level][concept.concept])
        return data.overall[level][concept.concept][classId];

      return undefined;
    };

    this.getCellValue = function(level, student, concept, classId) {
      return getDisplayScore(level, student, concept, classId);
    };

    this.formatScore = function (score) {
      if (!angular.isUndefined(score)) {
        return score + '&nbsp;';
      }
      return score;
    };

    this.getScoreClass = function(score) {
      if (angular.isUndefined(score) || score === null) {
        return 'undefined-score';
      }
      return 'accomplished-score';
    };

    this.getCellDropdown = function(level, student, concept, classId) {
      var scores = getScores(level, student, concept, classId);
      var retval = {
        template: 'spireClassLevelCMFDPopup.tpl',
        scores: scores
      };
      return retval;
    };

    this.getLegend = function() {
      return 'spireClassLevelCMFDLegend.tpl';
    };

    function getScore(level, student, concept, classId) {
      try {
        if (data &&
          data[level] &&
          data[level][concept.concept] &&
          data[level][concept.concept][classId] !== null &&
          data[level][concept.concept][classId][student.id] !== null &&
          !angular.isUndefined(data[level][concept.concept][classId][student.id])) {
          return data[level][concept.concept][classId][student.id];
        }
      }
      catch(e) {
        // ignore exception.
      }
      return undefined;
    }

    function getDisplayScore(level, student, concept, classId) {
      var score = getScore(level, student, concept, classId);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }
      return score.displayScore;
    }

    function getScores(level, student, concept, classId) {
      var score = getScore(level, student, concept, classId);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }
      return score.scores;
    }

    this.getStudentHasData = function(student, levelId) {
      return data.studentsWithData && data.studentsWithData[student.id] && data.studentsWithData[student.id][levelId];
    };
  }

  function getPrePostTableModel(students, data) {
    return new PrePostTableModel(students, data);
  }

  function PrePostTableModel(students, scores) {
    var columns = ['decodable_words_score', 'decodable_sentences_score', 'passage_correct', 'comprehension_score'];

    function parseScores(scores) {
      spireReportingService.sortScoresByDate(scores);

      var studentsWithData = {};
      var retval = {};
      scores.forEach(function(score){
        score.decodable_words_score = Math.round(score.decodable_words_score);
        score.decodable_sentences_score = Math.round(score.decodable_sentences_score);
        score.comprehension_score = Math.round(score.comprehension_score);
        score.prepost = score.prepost || 'pre';

        var studentId = score.student_id;
        var level = score.level;
        var prepost = score.prepost;
        var classId = score.class_id;

        var levelScores = spireReportingService.getOrCreateHive(retval, level);
        var prepostScores = spireReportingService.getOrCreateHive(levelScores, prepost);
        var classScores = spireReportingService.getOrCreateHive(prepostScores, classId);
        var studentScores = spireReportingService.getOrCreateHive(classScores, studentId);

        if (!studentScores.scores) {
          studentScores.scores = [];
        }

        if (!studentsWithData[studentId]) {
          studentsWithData[studentId] = {};
        }

        if (!studentsWithData[studentId][level]) {
          studentsWithData[studentId][level] = 1;
        }

        studentScores.scores.push(score);
        studentScores.displayScore = score; // Display last score, it will be most recent because we sorted by date above
      });

      retval.studentsWithData = studentsWithData;
      //retval.overall = calculateOverallScores(retval);
      return retval;
    }

    var data = parseScores(scores);

    this.getStudents = function(classId) {
      var classStudents = students.filter(function(student) { return !student.cid || student.cid === classId});
      return classStudents;
    };

    this.getValue = function(level, student, prepost, column, classId) {
      try {
        if (data &&
          data[level] &&
          data[level][prepost] &&
          data[level][prepost][classId] &&
          data[level][prepost][classId][student.id] &&
          data[level][prepost][classId][student.id].displayScore &&
          data[level][prepost][classId][student.id].displayScore[column] !== null &&
          !angular.isUndefined(data[level][prepost][classId][student.id].displayScore[column])) {
          return data[level][prepost][classId][student.id].displayScore[column];
        }
      }
      catch(e) {
        // ignore exception.
      }
      return undefined;
    };

    this.getOverallValue = function(level, prepost, column, classId) {
      try {
        if (data.overall &&
          data.overall[level] &&
          data.overall[level][prepost] &&
          data.overall[level][prepost][classId] &&
          data.overall[level][prepost][classId].score &&
          data.overall[level][prepost][classId].score[column]) {
          return data.overall[level][prepost][classId].score[column];
        }
      }
      catch(e) {
        // ignore exception.
      }
      return undefined;
    };

    this.getStudentHasData = function(student, levelId) {
      return data.studentsWithData && data.studentsWithData[student.id] && data.studentsWithData[student.id][levelId];
    };
  }

  function getMidTableModel(students, lessons, data) {
    return new MidTableModel(students, lessons, data);
  }

  function MidTableModel(students, lessons, scores) {
    function parseScores(scores) {
      spireReportingService.sortScoresByDate(scores);

      var studentsWithData = {};
      var retval = {};
      var experiences = {};
      scores.forEach(function(score) {
        score.score = Math.round(score.score);
        var studentId = score.student_id;
        var level = score.level;
        var lessonId = score.lesson;
        var classId = score.class_id;

        var levelScores = spireReportingService.getOrCreateHive(retval, level);
        var lessonScores = spireReportingService.getOrCreateHive(levelScores, lessonId);
        var classScores = spireReportingService.getOrCreateHive(lessonScores, classId);
        var studentScores = spireReportingService.getOrCreateHive(classScores, studentId);
        studentScores.displayScore = score.score;

        if (!studentsWithData[studentId]) {
          studentsWithData[studentId] = {};
        }

        if (!studentsWithData[studentId][level]) {
          studentsWithData[studentId][level] = 1;
        }

        var levelExperiences = spireReportingService.getOrCreateHive(experiences, level);
        var classExperiences = spireReportingService.getOrCreateHive(levelExperiences, classId);
        var studentExperiences = spireReportingService.getOrCreateHive(classExperiences, studentId);
        studentExperiences.experienceId = score.experience_id;
        studentExperiences.date = score.date;
      });

      retval.studentsWithData = studentsWithData;
      retval.overall = spireReportingService.calculateOverallScores(retval);
      retval.experiences = experiences;
      return retval;
    }

    var data = parseScores(scores);

    this.getStudents = function(classId) {
      var classStudents = students.filter(function(student) { return !student.cid || student.cid === classId});
      return classStudents;
    };

    this.getColumns = function(level) {
      if (level && lessons && lessons.hasOwnProperty(level)) {
        return lessons[level].slice(0, level == 3 ? 4 : 5); // Level three only uses the first four lessons all others use the first five.
      } else {
        return [];
      }
    };

    this.getOverallCellValue = function(level, lesson, classId) {
      if (data.overall && data.overall[level] && data.overall[level][lesson.lesson])
        return data.overall[level][lesson.lesson][classId];
      return undefined;
    };

    this.getCellValue = function(level, student, lesson, classId) {
      return getDisplayScore(level, student, lesson, classId);
    };

    this.formatScore = function (score) {
      if (!angular.isUndefined(score)) {
        return score + '%';
      }
      return score;
    };

    this.getScoreClass = function(score) {
      if (angular.isUndefined(score) || score === null) {
        return 'undefined-score';
      }

      if (score >= 80) {
        return 'accomplished-score';
      }
      return 'needs-attention-score';
    };

    this.getStudentValue = function(level, classId, student, key) {
      try {
        if (data &&
          data.experiences &&
          data.experiences[level] &&
          data.experiences[level][classId] &&
          data.experiences[level][classId][student.id] &&
          data.experiences[level][classId][student.id][key] !== null &&
          !angular.isUndefined(data.experiences[level][classId][student.id][key])) {
          return data.experiences[level][classId][student.id][key];
        }
      }
      catch(e) {
        // ignore exception.
      }
      return undefined;
    };

    this.getLegend = function() {
      return 'spireClassLevelCALegend.tpl';
    };

    function getScore(level, student, lesson, classId) {
      try {
        if (data &&
          data[level] &&
          data[level][lesson.lesson] &&
          data[level][lesson.lesson][classId] &&
          data[level][lesson.lesson][classId][student.id] !== null &&
          !angular.isUndefined(data[level][lesson.lesson][classId][student.id])) {
          return data[level][lesson.lesson][classId][student.id];
        }
      }
      catch(e) {
        // ignore exception.
      }
      return undefined;
    }

    function getDisplayScore(level, student, lesson, classId) {
      var score = getScore(level, student, lesson, classId);
      if (angular.isUndefined(score) || angular.isUndefined(score.displayScore)) {
        return undefined;
      }
      return score.displayScore;
    }

    this.getStudentHasData = function(student, levelId) {
      return data.studentsWithData && data.studentsWithData[student.id] && data.studentsWithData[student.id][levelId];
    };
  }

  function compareByFirstName(student1, student2) {
    if (student1.first_name.toLowerCase() < student2.first_name.toLowerCase())
      return -1;
    if (student1.first_name.toLowerCase() > student2.first_name.toLowerCase())
      return 1;
    return 0;
  }

  function compareByName(student1, student2) {
    if (student1.last_name.toLowerCase() < student2.last_name.toLowerCase())
      return -1;
    if (student1.last_name.toLowerCase() > student2.last_name.toLowerCase())
      return 1;
    return compareByFirstName(student1, student2);
  }

  function sortStudents(students) {
    students.sort(compareByName);
    return students;
  }

  return {
    getPlacementTableModel: getPlacementTableModel,
    getCAStudentDataTableModel: getCAStudentDataTableModel,
    getCMFDStudentDataTableModel: getCMFDStudentDataTableModel,
    getPrePostTableModel: getPrePostTableModel,
    getMidTableModel: getMidTableModel,
    viewValues: {
      placement: 'placement',
      cmfd: 'cmfd',
      ca: 'ca',
      mid: 'mid',
      prepost: 'prepost'
    },
    sortStudents: sortStudents,
  };

}]);