'use strict';
(function () {
  var module = angular.module('xp-element-draw', ['angularWidget', 'client.services', 'client.directives', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'uuid']);

  function isValueValid(value) {
    return value !== '' && value !== null && !angular.isUndefined(value);
  }

  function isValidAnswer(answer) {
    return isValueValid(answer) &&
      isValueValid(answer.user_data) &&
      isValueValid(answer.user_data.drawings) &&
      answer.user_data.drawings.length > 0 &&
      isValueValid(answer.user_data.drawings[0]) &&
      isValueValid(answer.user_data.drawings[0].shapeUrl) &&
      isValueValid(answer.user_data.drawings[0].imageUrl);
  }

  function getImageDataUrl(image, width, height) {
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.style['background-color'] = 'transparent';
    var ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, width, height);
    return canvas.toDataURL("image/png");
  }

  function testUrl(url) {
    var img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = function () {
      img.onload = undefined;
      var data = getImageDataUrl(img, img.width, img.height);
      return;
    };
    img.src = url;
  }

  module.directive('literallyCanvas', ['$timeout', 'CameraService', '$modal', 'ImageFileModificationService', 'loadImageWithOrientation', '$q',
    function ($timeout, CameraService, $modal, ImageFileModificationService, loadImageWithOrientation, $q) {

      window.LC.defineShape('XPImage', {
        constructor: function (args) {
          if (args === null) {
            args = {};
          }
          this.x = args.x || 0;
          this.y = args.y || 0;
          this.w = args.w || 0;
          this.h = args.h || 0;
          this.image = args.image || null;
          return this.image;
        },
        getBoundingRect: function () {
          return {
            x: this.x,
            y: this.y,
            width: this.w,
            height: this.h
          };
        },
        toJSON: function () {
          var img = this.image;
          var src;
          if (img.src.indexOf('data:image') !== 0) {
            src = getImageDataUrl(img, this.w, this.h);
          } else {
            src = img.src;
          }
          return {
            x: this.x,
            y: this.y,
            w: this.w,
            h: this.h,
            imageSrc: src
          };
        },
        fromJSON: function (data) {
          var img;
          img = new Image();
          if (data.imageSrc.indexOf('data:image') !== 0) {
            img.crossOrigin = "anonymous";
          }
          img.src = data.imageSrc;
          return window.LC.createShape('XPImage', {
            x: data.x,
            y: data.y,
            w: data.w,
            h: data.h,
            image: img
          });
        }
      });

      function renderXPImageToCanvas(ctx, shape, retryCallback) {
        if (shape.image === null) {
          return;
        }

        if (shape.image.complete) {
          var lx = shape.x;
          var lw = shape.w;
          if (lw < 0) {
            lx = lx + lw;
            lw = 0 - lw;
          }

          if (lw === 0) {
            lw = shape.image.width;
          }

          var ly = shape.y;
          var lh = shape.h;
          if (lh < 0) {
            ly = ly + lh;
            lh = 0 - lh;
          }

          if (lh === 0) {
            lh = shape.image.height;
          }

          return ctx.drawImage(shape.image, lx, ly, lw, lh);
        } else if (retryCallback) {
          shape.image.onload = retryCallback;
          return shape.image.onload;
        }
      }

      window.LC.defineCanvasRenderer('XPImage', renderXPImageToCanvas);

      window.LC.defineOptionsStyle('stampPicker', React.createClass({
        render: function () {
          var optionObject = this;
          var tool = optionObject.props.tool;

          var stamps = tool.stamps.map(function (value, index) {
            return React.DOM.div({
                'className': (tool.selectedIndex === index ? 'xp-draw-stamp-picker-cell selected' : 'xp-draw-stamp-picker-cell'),
                'key': value + index,
                'onClick': function (event) {
                  delete tool.img;
                  if (event && event.currentTarget &&
                    event.currentTarget.children &&
                    event.currentTarget.children[0] &&
                    event.currentTarget.children[0].nodeName == 'IMG') {
                    tool.img = event.currentTarget.children[0];
                  }
                  tool.file = value;
                  tool.selectedIndex = index;
                  optionObject.forceUpdate();
                }
              },
              React.DOM.img({
                'className': (tool.selectedIndex === index ? 'xp-draw-stamp-picker-preview selected' : 'xp-draw-stamp-picker-preview'),
                'src': value,
                'crossOrigin': 'anonymous',
                'ref': function (img) {
                  if (tool.selectedIndex === index) {
                    tool.img = img;
                  }
                }
              }));
          });

          return React.DOM.div({'className': 'xp-draw-stamp-picker-container'}, stamps);
        }
      }));

      window.LC.defineOptionsStyle('imagePicker', React.createClass({
        handleFile: function (e) {
          var optionObject = this;

          function onLoadFile(event) {
            optionObject.props.tool.file = event.target.result;
            optionObject.forceUpdate();
          }

          if (e.target.files === null || e.target.files.length === 0) {
            this.props.tool.file = null;
          } else {
            loadImageWithOrientation(e.target.files[0], {
              maxWidth: 800,
              maxHeight: 480,
              contain: true
            }).then(function (file) {
              var reader = new FileReader();
              reader.onload = onLoadFile;
              reader.readAsDataURL(file);
            });
          }
        },
        cameraModal: null,
        didClickCamera: function () {
          this.cameraModal = $modal(
            {
              backdrop: 'static',
              show: true,
              contentTemplate: 'xpDrawPhotoModal.html',
              scope: this.props.tool.scope,
              prefixEvent: 'cameraModal'
            });
        },
        closeModal: function () {
          if (this.cameraModal) {
            this.cameraModal.hide();
            this.cameraModal = undefined;
          }
        },

        render: function () {
          var optionObject = this;

          this.props.tool.scope.onCancelPhoto = function () {
            optionObject.closeModal();
          };

          this.props.tool.scope.onSavePhoto = function (data) {
            if (data) {
              optionObject.props.tool.file = data;
            } else {
              optionObject.props.tool.file = null;
            }

            optionObject.closeModal();
            optionObject.forceUpdate();
          };

          return React.DOM.div({'className': 'xp-draw-image-picker-container'},
            React.DOM.div({'className': 'xp-draw-image-picker-cell'},
              React.DOM.div({'className': 'square-button picture-upload-button-wrapper xp-draw-picture-upload-button-wrapper'},
                React.DOM.input({
                  'className': 'picture-upload-file-browse',
                  'type': 'file',
                  'accept': 'image/*',
                  'onChange': this.handleFile,
                  'title': 'Add image'
                }),
                React.DOM.svg({
                  'className': 'icon icon-ex-image',
                  'width': '40px',
                  'height': '40px'
                }, React.createElement('use', {
                  'xlinkHref': 'themes/exploros/icons/icons.svg#ex-image'
                })))),
            (CameraService.hasUserMedia ?
                React.DOM.div({'className': 'xp-draw-image-picker-cell'},
                  React.DOM.div({
                      'className': 'square-button xp-draw-camera-button-wrapper',
                      'title': 'Take a photo'
                    },
                    React.DOM.svg({
                      'className': 'icon icon-ex-image xp-draw-camera-button',
                      'width': '40px',
                      'height': '40px',
                      onClick: this.didClickCamera
                    }, React.createElement('use', {
                      'xlinkHref': 'themes/exploros/icons/icons.svg#camera'
                    }))))
                : null
            ),
            React.DOM.div({
                'className': 'xp-draw-image-picker-cell'
              },
              React.DOM.img({
                'className': 'xp-draw-image-picker-preview',
                'name': '',
                'src': this.props.tool.file
              }))
          );
        }
      }));

      function link(scope, element, attrs) {
        scope.options = scope.options || {};
        var lc = null;
        var drawingChangeUnsubscribe = null;

        var backgroundImageUrl = scope.backgroundImageUrl;
        var shapeData = scope.shapeData;
        var drawingWidth = scope.drawingWidth || 800;
        var drawingHeight = scope.drawingHeight || 480;
        var tools = scope.options.tools || '';
        var simpleVersion = angular.isUndefined(scope.options.simple_version) || scope.options.simple_version === null ? false : true === scope.options.simple_version;
        var stamps = scope.options.stamps || [];

        scope.$watch('initialized', function (value) {
          if (value) {
            $timeout(initCanvas, 0);
          }
        });

        scope.$watch('backgroundImageUrl', function (value) {
          backgroundImageUrl = value;

          if (backgroundImageUrl !== null && lc) {
            createBackgroundImageShapes(backgroundImageUrl)
              .then(function (resolvedShapes) {
                lc.backgroundShapes = resolvedShapes;
                lc.repaintLayer('background');
                scope.shapeData.image = backgroundImageUrl;
              });
          }
        });

        scope.$watch('shapeData', function (value) {
          if (value) {
            shapeData = value;

            value.getData = function () {
              if (lc) {
                return JSON.stringify(lc.getSnapshot());
              }

              return null;
            };

            value.getImage = function () {
              if (lc) {
                return lc.getImage({rect: {x: 0, y: 0, width: drawingWidth, height: drawingHeight}});
              }

              return null;
            };

            if (lc) {
              if (value.data) {
                lc.loadSnapshot(JSON.parse(value.data));
              } else {
                lc.clear();
              }

              scope.$emit('literallyCanvasDrawingChanged');
            }
          }
        }, true);

        function imageToolBegin(x, y, lc) {
          if (this.file === null) {
            return;
          }

          var img;

          if (this.img !== null && !angular.isUndefined(this.img)) {
            img = this.img;
          } else {
            img = new Image();
            if (this.file.indexOf('data:image') !== 0) {
              img.crossOrigin = "anonymous";
            }
            img.src = this.file;
          }

          this.currentShape = window.LC.createShape('XPImage', {x: x, y: y, image: img});
          return this.currentShape;
        }

        function imageToolContinue(x, y, lc) {
          if (this.file === null) {
            return;
          }

          this.currentShape.w = x - this.currentShape.x;
          this.currentShape.h = y - this.currentShape.y;

          return lc.drawShapeInProgress(this.currentShape);
        }

        function imageToolEnd(x, y, lc) {
          if (this.file === null) {
            return;
          }

          this.currentShape.w = x - this.currentShape.x;
          this.currentShape.h = y - this.currentShape.y;

          var shape = this.currentShape;
          var image = shape.image;
          var src = image.src;

          if (shape.w === 0) {
            shape.w = image.width;
          }

          if (shape.h === 0) {
            shape.h = image.height;
          }

          if (shape.w < 0) {
            shape.x += shape.w;
            shape.w = 0 - shape.w;
          }

          if (shape.h < 0) {
            shape.y += shape.h;
            shape.h = 0 - shape.h;
          }

          var x1 = Math.max(shape.x, 0);
          var x2 = Math.min(shape.x + shape.w, drawingWidth);
          var y1 = Math.max(shape.y, 0);
          var y2 = Math.min(shape.y + shape.h, drawingHeight);
          var targetWidth = x2 - x1;
          var targetHeight = y2 - y1;

          var newImage = new Image();
          var newShape = window.LC.createShape('XPImage', {
            x: x1,
            y: y1,
            w: targetWidth,
            h: targetHeight,
            image: newImage
          });
          newShape.w = targetWidth;
          newShape.h = targetHeight;
          window.LC.util.addImageOnload(newImage, function () {
            lc.saveShape(newShape);
          });
          newImage.src = ImageFileModificationService.scaleAndCropImageToDataURL(image, shape.w, shape.h, x1 - shape.x, y1 - shape.y, targetWidth, targetHeight);
        }

        // This needs to be in the link function so we have access to the scope for the camera dialog
        var ImagePicker = function () {
          this.name = 'ImagePicker';
          this.iconName = 'image';
          this.optionsStyle = 'imagePicker';
          this.file = null;
          this.scope = scope;
          this.usesSimpleAPI = true;
          this.didBecomeActive = function (lc) {
          };
          this.willBecomeInactive = function (lc) {
          };

          this.currentShape = null;

          this.begin = imageToolBegin;
          this['continue'] = imageToolContinue;
          this.end = imageToolEnd;
        };

        var Stamp = function () {
          var self = this;
          this.name = 'Stamp';
          this.iconName = 'stamp';
          this.optionsStyle = 'stampPicker';
          this.file = (stamps && stamps.length > 0 ? stamps[0] : null);
          this.scope = scope;
          this.stamps = stamps;
          this.selectedIndex = 0;
          this.usesSimpleAPI = true;
          this.didBecomeActive = function (lc) {
            self.file = (stamps && stamps.length > self.selectedIndex ? stamps[self.selectedIndex] : null);
          };
          this.willBecomeInactive = function (lc) {
          };

          this.currentShape = null;

          this.begin = imageToolBegin;
          this['continue'] = imageToolContinue;
          this.end = imageToolEnd;
        };

        function setTool(config, destArray, key, tool) {
          if (config.indexOf(key) !== -1) {
            destArray.push(tool);
          }
        }

        function initCanvas() {
          var toolsArray = [];
          setTool(tools, toolsArray, 'image', ImagePicker);
          toolsArray.push(window.LC.tools.Pencil, LC.tools.Eraser, LC.tools.Line, LC.tools.Rectangle, LC.tools.Ellipse);
          setTool(tools, toolsArray, 'text', window.LC.tools.Text);
          toolsArray.push(window.LC.tools.Pan);
          if (stamps.length > 0) {
            setTool(tools, toolsArray, 'stamp', Stamp);
          }
          if (!simpleVersion) {
            toolsArray.push(window.LC.tools.Eyedropper);
          }
          var options = {
            'imageSize': {width: drawingWidth, height: drawingHeight},
            'backgroundColor': '#fff',
            'tools': toolsArray,
            'imageURLPrefix': '/resources/literallyCanvas',
            'toolbarPosition': 'bottom',
            'secondaryColor': 'transparent',
            'simpleVersion': simpleVersion,
            'keyboardShortcuts': false
          };

          if (simpleVersion) {
            options.strokeWidths = [2, 5, 10, 20];
          }

          var promise;
          var blankBackground = false;

          if (backgroundImageUrl !== null) {
            promise = createBackgroundImageShapes(backgroundImageUrl);
          } else {
            var deffered = $q.defer();
            deffered.resolve();
            promise = deffered.promise;
            blankBackground = true;
          }

          promise.then(function (resolvedShapes) {
            if (resolvedShapes) {
              options.backgroundShapes = resolvedShapes;
            }
            lc = window.LC.init(element[0], options);
            lc.setColor('background', '#fff');

            if (lc && shapeData) {
              shapeData.hasLC = true;
              if (shapeData.data) {
                lc.loadSnapshot(JSON.parse(shapeData.data));
              } else {
                lc.clear();
              }
            }
          });

          element.on('$destroy', cleanUpDirective);
        }

        function cleanUpDirective() {
          shapeData.hasLC = false;
          if (drawingChangeUnsubscribe !== null) {
            drawingChangeUnsubscribe();
          }
        }

        function drawingChangeHandler() {
          if (shapeData) {
            shapeData.data = JSON.stringify(lc.getSnapshot());
            shapeData.image = lc.getImage();
          }
        }

        function createBackgroundImageShapes(bgImg) {
          var deferred = $q.defer();

          var img = new Image();
          img.crossOrigin = "anonymous";
          img.src = bgImg;

          window.LC.util.addImageOnload(img, function () {
            var imgShape = window.LC.createShape('Image', {x: 0, y: 0, image: img});
            deferred.resolve([imgShape]);
          });
          return deferred.promise;
        }

      }

      return {
        restrict: 'A',
        scope: {
          backgroundImageUrl: '=',
          shapeData: '=',
          drawingWidth: '=',
          drawingHeight: '=',
          initialized: '=',
          options: '='
        },
        replace: true,
        compile: function (element, attrs) {
          if (!attrs.drawingWidth) {
            attrs.drawingWidth = 800;
          }
          if (!attrs.drawingHeight) {
            attrs.drawingHeight = 480;
          }
          return link;
        }

      };
    }]);

  module.controller('clientDrawElementCtrl',
    ['$scope', 'widgetConfig', '$http', 'ElementUtilities', 'ElementsRestService', 'JSONStringUtility', '$log',
      'ModalService', '$timeout', 'rfc4122', 'BlobUtilities', 'SHARE_MODE', 'GATE_MODE', 'ElementsErrorService', '$q',
      'RespondentType', '$sce',
      function ($scope, widgetConfig, $http, ElementUtilities, ElementsRestService, JSONStringUtility, $log,
                ModalService, $timeout, rfc4122, BlobUtilities, SHARE_MODE, GATE_MODE, ElementsErrorServices, $q,
                RespondentType, $sce) {
        $scope.options = widgetConfig.getOptions($scope);
        $scope.elementInitialized = false;
        $scope.instructions = {};
        $scope.backgroundImageUrl = null;
        $scope.shapeData = {data: null, image: null};

        var max_screen_width = 960;

        $scope.gateMode = GATE_MODE.XPGateModeGated;
        $scope.SHARE_MODE = SHARE_MODE;

        var context = null;
        var element = null;

        $scope.inited = false;
        $scope.isTeacher = false;
        $scope.answers = {};
        $scope.selectedRespondent = null;
        $scope.respondents = [];
        var allRespondents = [];
        $scope.editing = false;
        $scope.share = SHARE_MODE.TEACHER;
        $scope.portionResponded = 0;
        $scope.savingData = false;
        $scope.canvasOptions = {tools: '', simple_version: false, stamps: []};

        var oldShapeData;

        function parseElement() {
          if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes) {
            return;
          }

          element.config.attributes.forEach(function (attribute) {
            var name = attribute.name;
            var value = attribute.value;
            switch (name) {
              case "title" :
                $scope.title = value;
                break;
              case "instructions" :
                $scope.instructions.question = $sce.trustAsHtml(value);
                break;
              case "question_image_url" :
                $scope.instructions.imageUrl = ElementUtilities.getElementURL(element, context.experienceId, value);
                break;
              case "background_image_url" :
                $scope.backgroundImageUrl = ElementUtilities.getElementURL(element, context.experienceId, value);
                break;
              case "gate_mode" :
                $scope.gateMode = attribute.value;
                break;
              case "share" :
                $scope.share = attribute.value;
                break;
              case "tools" :
                $scope.canvasOptions.tools = attribute.value;
                break;
              case "simple_version" :
                $scope.canvasOptions.simple_version = attribute.value === "true" || attribute.value === true;
                break;
              case "stamps" :
                $scope.canvasOptions.stamps = parseStamps(attribute.value);
                preloadStamps($scope.canvasOptions.stamps);
                break;
            }
          });
        }

        function parseStamps(stamps) {
          if (!stamps) {
            return [];
          }

          stamps = stamps instanceof Array ? stamps.concat() : [{name: "stamp", value: stamps.stamp}];
          stamps.forEach(function (stamp, index) {
            stamps[index] = ElementUtilities.getElementURL(element, context.experienceId, stamp.value);
            var stampURL = ElementUtilities.getElementURL(element, context.experienceId, stamp.value);

            if (stampURL) {
              stamps[index] = stampURL;
            } else {
              stamps[index] = stamp.value;
            }
          });

          return stamps;
        }

        function preloadStamps(stamps) {
          var promises = [];
          if (stamps instanceof Array) {
            stamps.forEach(function (stamp) {
              var deferred = $q.defer();
              var promise = deferred.promise;
              promises.push(promise);
              var img = new Image();
              img.crossOrigin = "anonymous";
              var handler = function () {
                img.onload = undefined;
                img.onerror = undefined;
                deferred.resolve();
              };
              img.onload = handler;
              img.onerror = handler;
              img.src = stamp;
            });
          }

          return $q.all(promises);
        }

        $scope.$on('pageElementsLoaded', function () {
          $timeout(function () {
            $scope.inited = true;
          }, 100);
        });

        var unregisterOptionsWatch = $scope.$watch('options', onOptionsSet, true);

        function onOptionsSet() {
          // unregisterOptionsWatch();
          if ($scope.options.context && !$scope.elementInitialized) {
            context = $scope.options.context;
            element = $scope.options.element;
            parseElement();

            var respondentId = getRespondentId();

            // Make sure to the previous answers are removed
            $scope.answers = {};
            $scope.respondents = [];
            allRespondents = [];

            $scope.selectedRespondent = respondentId;

            if (!$scope.options.context.getViewingInactiveExperience() ||
              ($scope.options.context.getViewingInactiveExperience() &&
                !context.getUserIsTeacher(respondentId))) {
              $scope.respondents.push(respondentId);
              allRespondents.push(respondentId);
            }

            $scope.isTeacher = context.userIsTeacher();
            $scope.respondentId = respondentId;

            loadAnswers();

            $scope.elementInitialized = true;
          }
        }

        $scope.$watch('selectedRespondent', onSelectedRespondentDataChange);
        $scope.$on('literallyCanvasDrawingChanged', onShapeDataChange);

        function onShapeDataChange() {
          if ($scope.shapeData && $scope.shapeData.getData) {
            oldShapeData = $scope.shapeData.getData();
          } else {
            oldShapeData = null;
          }
        }

        function onSelectedRespondentDataChange() {
          var answer = $scope.answers[$scope.selectedRespondent];
          if (answer && answer.user_data.drawings[0].image) {
            var drawing = answer.user_data.drawings[0];

            if (drawing.shapes) {
              $http.get(drawing.shapes, {
                transformResponse: function (value) {
                  return value;
                }
              }).then(function (response) {
                $scope.shapeData.data = response.data;
              });
            }

            if (drawing.image) {
              if ($scope.externalUrl(drawing.image)) {
                $scope.shapeData.image = drawing.image;
              } else {
                $http.get(drawing.image, {
                  params: {json: true},
                  transformResponse: function (value) {
                    return value;
                  }
                }).then(function (response) {
                  $scope.shapeData.data = response.url;
                });
                $scope.shapeData.image = drawing.image;
              }

            } else {
              $scope.shapeData.image = $scope.backgroundImageUrl;
            }

            $scope.editing = false;
          } else {
            $scope.shapeData.data = null;
            ++$scope.shapeData.changeIndex;
            $scope.shapeData.image = $scope.backgroundImageUrl;

            $scope.editing = context && $scope.selectedRespondent == $scope.respondentId &&
              !$scope.answers[$scope.selectedRespondent] &&
              !$scope.options.context.getViewingInactiveExperience();
          }
        }

        function getImageDataFromRemote(answer) {
          var context = $scope.options.context;
          var data = JSONStringUtility.parse(answer.user_data);
          if (!data || !data.drawings) {
            var deferred = $q.defer();
            deferred.resolve({});
            return deferred.promise;
          }
          var promisedUrls = data.drawings.map(function (drawing) {
            var shapeUrl = '/elements/' + element.id + '/experience/' + context.experienceId +
              '/attachment/' + drawing.shapesXid;

            var imageUrl = '/elements/' + element.id + '/experience/' + context.experienceId +
              '/attachment/' + drawing.imageXid;

            return $q.all({
              shape: $http.get(shapeUrl, {params: {json: true}}).then(function (response) {
                return response.data.url;
              }),
              image: $http.get(imageUrl, {params: {json: true}}).then(function (response) {
                return response.data.url;
              })
            })
              .then(function (urls) {
                return {
                  shapesXid: drawing.shapesXid,
                  shapeUrl: urls.shape,
                  imageXid: drawing.imageXid,
                  imageUrl: urls.image
                };
              });
          });

          return $q.all(promisedUrls).then(function (drawingsWithUrls) {
            data.drawings = drawingsWithUrls;
            answer.user_data = data;
            return answer;
          });

        }

        function updateAnswer(answer) {
          var deffered = $q.defer();
          deffered.resolve();
          var answerRespondentId = getAnswerRespondentId(answer);
          //answer.user_data = JSONStringUtility.parse(answer.user_data);

          // In share to teacher mode, only display user's own data and teacher data
          if ($scope.shareToTeacher() &&
            !context.userIsTeacher() &&
            answerRespondentId != $scope.respondentId &&
            !context.getUserIsTeacher(answerRespondentId) &&
            answerRespondentId !== 0) {
            return;
          }

          var validAnswer = false;

          if (answer.user_data && answer.user_data.drawings) {
            answer.user_data.drawings.forEach(function (drawing) {
              if (isValueValid(drawing.shapeUrl) && isValueValid(drawing.imageUrl)) {
                validAnswer = true;
                drawing.shapes = drawing.shapeUrl;
                drawing.image = drawing.imageUrl;
              }
            });
          }

          var currentAnswer = $scope.answers[answerRespondentId];
          var respondents = allRespondents;
          var image = null;

          if (validAnswer) {
            image = answer.user_data.drawings[0].image;
            $scope.answers[answerRespondentId] = answer;
          } else {
            answer = undefined;
            delete $scope.answers[answerRespondentId];
          }

          if ((!currentAnswer || !currentAnswer.user_data.drawings[0].image) && answerRespondentId != $scope.respondentId && image !== null) {
            respondents.push(answerRespondentId);
          }

          if (currentAnswer && currentAnswer.user_data.drawings[0].image && image === null) {
            for (var i = 1; i < respondents.length; ++i) {
              if (respondents[i] == answerRespondentId) {
                respondents.splice(i, 1);
              }
            }
          }

          var selfAnswer = $scope.answers[$scope.respondentId];

          if (!context.userIsTeacher() && $scope.gateMode == GATE_MODE.XPGateModeGated && (!selfAnswer || !selfAnswer.user_data || !selfAnswer.user_data.version)) {
            $scope.respondents = [$scope.respondentId];
          } else {
            $scope.respondents = allRespondents.concat();
          }

          // If we just deleted the selectedRespondent drawing, set the selected user to self
          if (answerRespondentId == $scope.selectedRespondent && !image) {
            $scope.selectedRespondent = $scope.respondentId;
          }

          var teacherAdjustment = $scope.answers.hasOwnProperty(context.clazz.teacher.uid) ? 1 : 0;

          if ($scope.isUsingSmallGroups()) {
            $scope.portionResponded = (Object.keys($scope.answers).length - teacherAdjustment) / (context.clazz.smallGroups);
          } else if (context.clazz.students.length) {
            $scope.portionResponded = (Object.keys($scope.answers).length - teacherAdjustment) / (context.clazz.students.length);
          } else {
            $scope.portionResponded = 0;
          }

          deffered.resolve(answer);
          return deffered.promise;
        }

        function loadAnswers() {
          if (!element || !element.id) {
            return;
          }

          var isInactive = $scope.options.context.getViewingInactiveExperience();

          ElementsRestService.getSharedState(context.experienceId, element.id, context.groupName, isInactive,
            function (result) {
              var promise;
              if ((result instanceof Array) && result[0] && result[0].user_data) {
                promise = $q.all($scope.filterAnswers(result).map(function (answer) {
                  return getImageDataFromRemote(answer).then(updateAnswer);
                }));
              } else {
                var deferred = $q.defer();
                deferred.resolve();
                promise = deferred.promise;
              }

              promise.then(function () {
                var service = $scope.options.elementRealtimeService;
                var EVENTS = service.EVENTS;
                service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
                $scope.$on('$destroy', function () {
                  service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
                });

                // Need to update the user state on initial loading. This must occur after any data has been loaded
                onSelectedRespondentDataChange();

                // Notify the widget that were are done loading the data
                widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
              });
            },
            function (error) {
              ElementsErrorService.error(error);
            });
        }

        function stateChangedNotificationHandler(e) {
          var message = e.detail;
          var state = message.record;
          if (state.element_id != $scope.options.element.id) {
            return;
          }

          $log.debug("Received draw state update: " + JSON.stringify(message));

          var answerRespondentId = getAnswerRespondentId(state);

          var currentState = $scope.answers[answerRespondentId];

          if (!currentState || currentState.timestamp != state.timestamp) {
            getImageDataFromRemote(state).then(updateAnswer).then(
              function () {
                $timeout(function () {
                  // if this is the current user then users data changed
                  if (answerRespondentId == $scope.selectedRespondent) {
                    $scope.$apply(onSelectedRespondentDataChange);
                  }
                  $scope.savingData = false;
                });
              }
            );
          }

        }

        var httpRegExp = new RegExp('^https?:\/\/');
        $scope.externalUrl = function (url) {
          return httpRegExp.test(url);
        };

        $scope.getEditMenuItemsForUser = function (respondentId) {
          var editOption = {
            text: '<div class="xp-element-menu-edit">Edit</div>',
            click: 'requestEdit("' + respondentId + '")'
          };

          if ($scope.editing) {
            editOption = {
              text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
              click: 'requestCancelEdit("' + respondentId + '")'
            };
          }

          var menuOptions = [editOption];

          if (!context.isReview) {
            menuOptions.push({
                divider: true
              });
            menuOptions.push({
                text: '<div class="xp-element-menu-delete">Delete</div>',
                click: 'requestDelete("' + respondentId + '")'
              });
          } else {
            menuOptions.push(
            {
              text: '<div class="xp-element-menu-edit">Approve</div>',
              click: 'approvePreviewRepsonses()'
            });
          }

          return menuOptions;
        };

        $scope.hasResponses = function() {
          return $scope.answers[$scope.respondentId];
        };

        $scope.requestEdit = function (respondentId) {
          startEdit(respondentId);
        };

        $scope.approvePreviewRepsonses = function() {
          ElementsRestService.approveAnalyticsUserState(context.experienceId, element.id)
          .then(function(res) {
            if (res && res.status && res.status == "Approved") {
              ModalService.show({
                message: "Analytic data for this element is now approved.",
                backdrop: 'static',
                buttons: [
                  {
                    title: 'Ok',
                    click: '$hide();'
                  }
                ]
              });
            }
          });
        };

        function startEdit(respondentId) {
          onSelectedRespondentDataChange();
          $scope.editing = true;
        }

        $scope.requestCancelEdit = function (respondentId) {
          verifyLoseChanges(function () {
            cancelEdit(respondentId);
          });
        };

        function cancelEdit(respondentId) {
          $scope.editing = false;
          var answer = $scope.answers[respondentId];
          $scope.shapeData.data = null;
        }

        $scope.requestDelete = function (respondentId) {
          ModalService.show(
            {
              title: 'Delete drawing?',
              buttons:
                [
                  {
                    title: 'Delete',
                    click: 'deleteDrawing(); $hide();'
                  },
                  {
                    title: 'Cancel',
                    click: '$hide()'
                  }
                ],
              deleteDrawing: function () {
                deleteDrawing(respondentId);
              }
            }
          );
        };

        function deleteDrawing(respondentId) {
          var oldAnswer = $scope.answers[$scope.selectedRespondent];

          saveDrawing(oldAnswer, null, null);
        }

        function saveDrawing(oldAnswer, shapes, imageURL) {
          var attachments = {};
          var blob;

          var shapesXid = '';
          if (shapes !== null) {
            shapesXid = rfc4122.v4();
            blob = new Blob([shapes], {type: 'application/json'});
            attachments[shapesXid] = blob;
          }

          var imageXid = '';

          if (imageURL !== null) {
            imageXid = rfc4122.v4();

            blob = BlobUtilities.dataURLToBlob(imageURL, 'image/png');
            attachments[imageXid] = blob;
          }

          var version = 1;

          if (oldAnswer && oldAnswer.user_data && oldAnswer.user_data.version) {
            version = oldAnswer.user_data.version + 1;
          }

          var userDataJSON = {
            'version': version,
            'drawings': [{
              'shapesXid': shapesXid,
              'imageXid': imageXid
            }]
          };

          var groupId = 0;
          var userId = context.userId;

          if ($scope.isUsingSmallGroups()) {
            if (context.userIsTeacher()) {
              if ($scope.selectedRespondent !== 0) {
                groupId = $scope.selectedRespondent;
                userId = oldAnswer.user_id; // Teacher must impersonate last person to edit because they might have an answer under their own id.
              }
            } else {
              groupId = $scope.selectedRespondent;
            }
          } else {
            if (context.userIsTeacher() && oldAnswer !== null && !angular.isUndefined(oldAnswer)) {
              userId = oldAnswer.user_id;
            }
          }

          if ($scope.options.context.isReview) {
            ElementsRestService.saveAnalyticsUserState(context.experienceId, element.id, userId, groupId, oldAnswer.user_data, userDataJSON,
            function() {
              var message = {
                detail: {
                  record:  {
                    user_id: userId,
                    small_gid: groupId,
                    element_id: element.id,
                    user_data: userDataJSON,
                    timestamp: Date(0)
                  }
                }
              };
              stateChangedNotificationHandler(message);
            },
            function(error) {
              ElementsErrorService.error(error);
            },
            attachments);
          } else {
            ElementsRestService.saveUserState(context.experienceId, element.id, userId, groupId, userDataJSON,
              function (result) {
              },
              function (error) {
                ElementsErrorService.error(error);
              },
              attachments);
          }
        }

        $scope.canSubmit = function() {
          return !$scope.options.context.isPreview || $scope.options.context.isReview;
        }

        $scope.didSubmit = function () {
          if ($scope.selectedRespondent === null || !$scope.options || !context || !element || !$scope.shapeData.getData || !$scope.shapeData.getImage) {
            return;
          }

          // Currently saving data
          $scope.savingData = true;

          var shapes = $scope.shapeData.getData();
          var image = $scope.shapeData.getImage();
          var imageURL = image.toDataURL("image/png");

          var oldAnswer = $scope.answers[$scope.selectedRespondent];

          saveDrawing(oldAnswer, shapes, imageURL);
        };

        function verifyLoseChanges(onYes) {
          if (!$scope.shapeData.getData) {
            onYes();
          }
          var shapes = $scope.shapeData.getData();
          if (angular.equals(oldShapeData, shapes)) {
            onYes();
          } else {
            ModalService.show(
              {
                title: 'Cancel edit?',
                message: 'You will lose all changes.',
                buttons:
                  [
                    {
                      title: 'Yes',
                      click: 'onYes();$hide();'
                    },
                    {
                      title: 'No',
                      click: '$hide()'
                    }
                  ],
                onYes: function () {
                  onYes();
                }
              }
            );
          }
        }

        function verifyUserChange(respondentId) {
          verifyLoseChanges(function () {
            $scope.selectedRespondent = respondentId;
          });
        }

        $scope.selectRespondent = function (respondentId) {
          if ($scope.selectedRespondent == respondentId) {
            return;
          }

          if ($scope.editing) {
            verifyUserChange(respondentId);
          } else {
            $scope.selectedRespondent = respondentId;
          }
        };

        $scope.canEditForRespondent = function (respondentId) {
          if (!context) {
            return;
          }

          if (context.isPreview && !context.isReview) {
            return false;
          }

          if (context.getViewingInactiveExperience()) {
            return false;
          }

          var selectedAnswer = $scope.answers[$scope.selectedRespondent];

          return (context.userIsTeacher() || $scope.respondentId == respondentId) && isValidAnswer(selectedAnswer);
        };

        $scope.isUsingSmallGroups = function () {
          return SHARE_MODE.isUsingSmallGroups($scope.share);
        };

        $scope.respondentIsUser = function (respondentId) {
          return !$scope.isUsingSmallGroups() || respondentId === 0;
        };

        $scope.respondentIsGroup = function (respondentId) {
          return !$scope.respondentIsUser(respondentId);
        };

        $scope.shareToTeacher = function () {
          return $scope.share == SHARE_MODE.TEACHER || $scope.share == SHARE_MODE.SMALL_GROUP_TEACHER;
        };

        $scope.shareToGroup = function () {
          return $scope.share == SHARE_MODE.GROUP || $scope.share == SHARE_MODE.SMALL_GROUP_GROUP;
        };

        function getRespondentId() {
          // see if there is a filtered user
          if ($scope.getRespondent()) {
            return $scope.getRespondent();
          }

          if (!$scope.isUsingSmallGroups()) {
            return context.userId;
          }

          if (context.userIsTeacher()) {
            return 0;
          }

          return context.groupId;
        }

        function getAnswerRespondentId(answer) {
          if (!$scope.isUsingSmallGroups()) {
            return answer.user_id;
          }

          if (context.getUserIsTeacher(answer.user_id)) {
            return 0;
          }

          return answer.small_gid;
        }

        $scope.getRespondentDisplayId = function (respondentId) {
          if ($scope.isUsingSmallGroups() && respondentId === 0) {
            return context.getTeacher();
          }

          return respondentId;
        };

        $scope.wrapRespondent = function (respondent) {
          var wrappedRespondent = new $scope.Respondent(respondent);
          wrappedRespondent.getType = function () {
            if (!$scope.isUsingSmallGroups() || respondent === 0) {
              return RespondentType.USER;
            }

            return RespondentType.GROUP;
          };

          wrappedRespondent.getDisplayId = function () {
            if ($scope.isUsingSmallGroups() && respondent === 0) {
              return context.getTeacher();
            }

            return respondent;
          };

          wrappedRespondent.isSelected = function () {
            return respondent == $scope.selectedRespondent;
          };

          wrappedRespondent.select = function () {
            $scope.selectRespondent(respondent);
          };

          return wrappedRespondent;
        };

      }]);

})();
