'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = [];

  function controller () {
    var ctrl = this;

    ctrl.$onChanges = function(changes) {
      if (changes.tableData && changes.tableData.currentValue) {
        ctrl.tableParams.settings({
          dataset: changes.tableData.currentValue
        });
      }
    };
    
  }

  module.component('xpElementResponses', {
    template: require('./elementResponses.jade'),
    controller: controller,
    bindings: {
      title: '=',
      responses: '<'
    }
  });

})();
