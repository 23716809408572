'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log', '$timeout', 'VideoStreamingService', 'ExperienceRealtimeService',
                        'ActiveExperience', 'StudentStreamingService'];

  function controller ($scope, $log, $timeout, VideoStreamingService, ExperienceRealtimeService,
                      ActiveExperience, StudentStreamingService) {
    var ctrl = this;

    ctrl.studentAllowedToRaiseHand = false;
    ctrl.studentHandIsUp = false;
    ctrl.teacherDisabledYourAudio = false;

    ctrl.$onInit = function () {
      ctrl.studentAudioState = VideoStreamingService.AUDIOSTATE.OFFLINE;
      ActiveExperience.getExperience(ActiveExperience.currentExperience().id, true).then(function(experience){
        ctrl.studentAllowedToRaiseHand = experience.allow_raise_hand;
      });
    };

    ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPStudentCanRaiseHandNotification,
        allowStudentRaiseHand);

    function allowStudentRaiseHand (e) {
      ctrl.studentAllowedToRaiseHand = e.detail.record.allow_raise_hand;
      if (!ctrl.studentAllowedToRaiseHand && ctrl.studentHandIsUp) {
        return VideoStreamingService.toggleStudentHandRaised(false);
      }
    }

    ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPTeacherEnabledStudentAudioNotification,
        enableStudentAudioNotification);

    function enableStudentAudioNotification (e) {
      enableStudentAudio(e.detail.record.enable_talking);
    }

    ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPStreamingStatusNotification,
        streamingStatusNotification);

    function streamingStatusNotification (e) {
      streamingStatusChanged(e.detail.record.teacherIsStreaming);
    }

    $scope.$on('$destroy', function () {
      ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPStudentCanRaiseHandNotification,
          allowStudentRaiseHand);
      ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPTeacherEnabledStudentAudioNotification,
          enableStudentAudioNotification);
      ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPStreamingStatusNotification,
          streamingStatusNotification);
    });

    function streamingStatusChanged(teacherIsStreaming) {
      if (!teacherIsStreaming) {
        setStudentNotSpeaking();
      }
    }

    function enableStudentAudio(enable) {
      if (enable) {
        VideoStreamingService.toggleStudentHandRaised(false);
        ctrl.studentAudioState = VideoStreamingService.AUDIOSTATE.PENDING;
        VideoStreamingService.setStudentStatus(ctrl.studentAudioState);
      } else {
        setStudentNotSpeaking();
        ctrl.teacherDisabledYourAudio = true;
        $timeout(function () {
          ctrl.teacherDisabledYourAudio = false;
          VideoStreamingService.setStudentStatus(ctrl.studentAudioState);
        }, 5000);
      }
    }

    function setStudentNotSpeaking() {
      $timeout(function() {
        ctrl.studentAudioState = VideoStreamingService.AUDIOSTATE.OFFLINE;
        VideoStreamingService.setStudentStatus(ctrl.studentAudioState);
        ctrl.audioEnabled = false;
        ctrl.studentHandIsUp = false;
      });
    }

    ctrl.raiseOrLowerHand = function() {
      VideoStreamingService.toggleStudentHandRaised(!ctrl.studentHandIsUp)
      .then(function(result){
        ctrl.studentHandIsUp = !ctrl.studentHandIsUp;
      }).catch(function(error){
        $log.error("Failed to raise/lower student hand" + error);
      });
    };

    ctrl.studentIsParticipating = function() {
      return ctrl.studentAudioState == VideoStreamingService.AUDIOSTATE.PENDING ||
              ctrl.studentAudioState == VideoStreamingService.AUDIOSTATE.SPEAKING ||
              ctrl.studentAudioState == VideoStreamingService.AUDIOSTATE.MUTED;
    };

    ctrl.studentIsPending = function() {
      return ctrl.studentAudioState == VideoStreamingService.AUDIOSTATE.PENDING;
    };

    ctrl.studentIsSpeaking = function() {
      return ctrl.studentAudioState == VideoStreamingService.AUDIOSTATE.SPEAKING;
    };

    ctrl.startSpeaking = function() {
      $timeout(function() {
        ctrl.studentAudioState = VideoStreamingService.AUDIOSTATE.SPEAKING;
        VideoStreamingService.setStudentStatus(ctrl.studentAudioState);
        ctrl.audioEnabled = true;
      });
    };

    ctrl.declineSpeaking = function() {
      ctrl.studentAudioState = VideoStreamingService.AUDIOSTATE.DECLINED;
      VideoStreamingService.setStudentStatus(ctrl.studentAudioState);
    };

    ctrl.speakOrMute = function() {
      if (ctrl.studentIsSpeaking()) {
        ctrl.muteStudent();
      } else {
        ctrl.startSpeaking();
      }
    };

    ctrl.muteStudent = function() {
      ctrl.studentAudioState = VideoStreamingService.AUDIOSTATE.MUTED;
      VideoStreamingService.setStudentStatus(ctrl.studentAudioState);
      ctrl.audioEnabled = false;
    };

    ctrl.studentIsMuted = function() {
      return ctrl.studentAudioState === VideoStreamingService.AUDIOSTATE.MUTED;
    };

  }

  module.component('studentControls', {
    template: require('./studentControls.jade'),
    controller: controller,
    bindings: {
      studentAudioState: '=',
      audioEnabled: '='
    }
  });
})();
