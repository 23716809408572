'use strict';

let tableModule = angular.module('xp-element-table',
  ['angularWidget', 'client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'client.directives', 'uuid', 'angular-inview']);

let UPDATE_TABLE_DATA_OPTIONS = Object.freeze({
  INITIALIZE_LAST_SEEN: 'INITIALIZE_LAST_SEEN',
  REVEAL_NEW_POSTS: 'REVEAL_NEW_POSTS'
});

function XPTablePost(postId, userId, groupId, timestamp, modified, modifiedByUserId, columnText) {
  this.postId = postId;
  this.userId = userId;
  this.groupId = groupId;
  this.timestamp = timestamp;
  this._modified = modified;
  this._modifiedByUserId = modifiedByUserId;
  this.columnText = columnText;
  this._isNew = false;

  this.lastModificationTimestamp = function ()// returns self.modified or self.timestamp
  {
    if (this._modified) {
      return this._modified;
    }
    return this.timestamp;
  };

  this.lastModifiedByUserId = function () {
    if (this._modified && (this._modifiedByUserId) > 0 && (this._modifiedByUserId !== this.userId)) {
      return this._modifiedByUserId;
    }
    return this.userId;
  };

  this.copyWithColumnText = function (columnText, timestamp, modifiedByUserId) {
    let newPost = new XPTablePost(this.postId, this.userId, this.groupId, this.timestamp, new Date(), modifiedByUserId, columnText.concat());
    return newPost;
  };
}

function XPTableUserState() {
  this.initFromXPElementState = function (elemState, posts) {
    return this.initWithId(elemState.id, elemState.experience_id,
      elemState.element_id, elemState.user_id, elemState.small_gid, new Date(elemState.timestamp), elemState.user_data, posts);
  };

  this.initWithId = function (id, experienceId, elementId, userId, groupId, timestamp, userDataJSON, posts) {
    this.id = id;
    this.experienceId = experienceId;
    this.elementId = elementId;
    this.userId = userId;
    this.groupId = groupId;
    this.timestamp = timestamp;
    this.userDataJSON = userDataJSON;
    this.posts = posts;

    return this;
  };

  // Some additional read-only properties
  // (in the future, these values could be potentially be embedded in the json data saved on the server
  // but currently it's easier (but less efficient) to implement them algorithmically, because the saved user_data consists of a JSON array of posts,
  // so that there is no good place to put these values (which don't belong to an individual post, but to the user_data as a whole).
  this.lastModifiedByUserId = function () {
    let modTimestamp = new Date();
    let modUserId = this.userId;
    if (this.posts && this.posts.length) {
      this.posts.forEach(function (post) {
        if (post.lastModificationTimestamp.getTime() > modTimestamp.getTime()) {
          modTimestamp = post.lastModificationTimestamp;
          modUserId = post.modifiedByUserId;
        }
      });
    }
    return modUserId;
  };

  this.lastModifiedTimestamp = function () {
    let modTimestamp = new Date();
    if (this.posts && this.posts.length) {
      this.posts.forEach(function (post) {
        if (post.lastModificationTimestamp.getTime() > modTimestamp.getTime()) {
          modTimestamp = post.lastModificationTimestamp;
        }
      });
    }
    return modTimestamp;
  };

  this.postedByUserId = function () {
    let postedById = this.lastModifiedByUserId;
    return postedById;
  };
}

tableModule.factory('XPTableElementData',
  ['SHARE_MODE', 'GATE_MODE', 'rfc4122', function (SHARE_MODE, GATE_MODE, rfc4122) {
    let XPTableElementData = function (userId, groupId, userIsTeacher, teacherId, teachers,
                                       readonly, gated, lastSeen, infoProvider, getDataIsFilteredFunc, getDataForCurrentUserFunc) {
      this.currentUserId = userId;
      this.currentGroupId = groupId;
      this.userIsTeacher = userIsTeacher;
      this.teacherId = teacherId;
      this.teachers = teachers;
      this.readonly = readonly;
      this.isGated = gated;
      this.lastSeen = lastSeen;
      this.previousLastSeen = null;
      this.infoProvider = infoProvider;
      this._allPosts = [];
      this._stateData = {};
      this.allPostsCount = 0;
      this.allStudentPostsCount = 0;
      this.myPostsCount = 0;
      this.columnIndexes = null;
      this.nameIndex = null;
      this.share = null;
      this.getDataIsFiltered = getDataIsFilteredFunc;
      this.getDataForCurrentUser = getDataForCurrentUserFunc;
      this.respondentsCount = 0;

      this.setShare = function (shareMode) {
        this.share = shareMode;
      };

      this.previousLastSeenTime = function () {
        if (!this.previousLastSeen) {
          return this.lastSeen;
        }
        return this.previousLastSeen;
      };

      this.myPosts = function () {
        let self = this;
        let posts = [];

        this._allPosts.forEach(function (post) {
          if (post.userId == self.currentUserId || (post.groupId > 0 && post.groupId == self.currentGroupId)) {
            posts.push(post);
          }
        });

        return posts;
      };

      this.userHasPosted = function (userId, groupId) {
        let stateId = (groupId && groupId > 0) ? groupId : userId;
        let userState = this._stateData[stateId];
        return userState && userState.posts && userState.posts.length > 0;   // NOTE: true even if posts are deleted or not visible to current user.
      };

      this.postsCount = function (userId, groupId) {
        // count only top level posts that have not been removed
        let count = 0;

        this._allPosts.forEach(function (post) {
          if (post.userId == userId || (groupId && groupId > 0 && groupId == post.groupId)) {
            ++count;
          } // post belongs to me
        });

        return count;
      };

      this.isPostEditable = function (post) {
        if (!post) {
          return false;
        } // failsafe
        if (this.userIsTeacher) {
          return true;
        }

        return post.userId == this.currentUserId || (post.groupId > 0 && post.groupId == this.currentGroupId);
      };

      this.setLastSeen = function (lastSeenTimestamp) {
        this.lastSeen = lastSeenTimestamp;
        if (!this.previousLastSeen) {
          this.previousLastSeen = lastSeenTimestamp;
        }
      };

      this.savePreviousLastSeen = function () {
        if (this.lastSeen) {
          this.previousLastSeen = this.lastSeen;
        }
      };

      this.setHasSeen = function (newLastSeenTimestamp) {  //TODO: this is not threadsafe.  If called from a thread other than the main UI thread, potential for problems.
        this.savePreviousLastSeen();
        this.setLastSeen(newLastSeenTimestamp);
      };

      this.getTimestampForUserId = function (userId, groupId) {
        if (userId <= 0) {
          return null;
        }
        let stateId = groupId && groupId > 0 ? groupId : userId;
        let stateData = this._stateData[stateId];
        return stateData ? stateData.timestamp : undefined;
      };

      this.getDisplayNameForUserId = function (userId) {
        if (userId <= 0) {
          return null;
        }
        if (this.infoProvider) {
          return this.infoProvider.getUserDisplayName(userId);
        }
        return "" + userId;
      };

      this.updateState = function (stateUpdateData, initLastSeen, revealNewPosts) {
        let newTableData = new XPTableElementData(this.currentUserId, this.currentGroupId, this.userIsTeacher,
          this.teacherId, this.teachers, this.readonly, this.isGated, this.lastSeen, this.infoProvider,
          this.getDataIsFiltered, this.getDataForCurrentUser);

        newTableData.setShare(this.share);

        let copiedStateData = angular.copy(this._stateData); // shallow copy of _stateData

        angular.extend(copiedStateData, stateUpdateData);
        newTableData.setStateData(copiedStateData, initLastSeen, null, revealNewPosts);

        return newTableData;
      };

      this.setStateData = function (newStateData, initLastSeen, defaultForLastSeen, revealNewPosts) {
//						BOOL bCurrentUserHasAtLeastOnePost = [self userHasPosted:self.currentUserId];
        let keyForCurrentUser = (this.currentGroupId && this.currentGroupId > 0) ? this.currentGroupId : this.currentUserId;
        let userWhoEditedMyPosts = -1;
        let mostRecentModTimestamp = new Date(0);
        let lastSeenFromServer = null;
        let self = this;

        Object.keys(newStateData).forEach(function (userKey) {
          let userState = newStateData[userKey];
          let modTimestamp = self.getModificationTime(userState.posts, userState.userId);  //most recent mod to any post
          mostRecentModTimestamp = mostRecentModTimestamp.getTime() > modTimestamp.getTime() ?
            mostRecentModTimestamp : modTimestamp;  // find most recent across all users

          if (userKey == keyForCurrentUser) {
            lastSeenFromServer = userState.timestamp;
            if (userState.posts !== null && userState.posts.length > 0) {
              if (modTimestamp.getTime() > userState.timestamp.getTime()) {
                userWhoEditedMyPosts = userKey;
              }
            }
          }
          self._stateData[userKey] = userState; // set or replace the state data for given user
        });

        if (initLastSeen) { // if we've just received data from the server for the first time
          // need to initialize the lastSeen & previousLastSeen machinery
          // get the timestamp from it
          if (!lastSeenFromServer && defaultForLastSeen) {
            lastSeenFromServer = defaultForLastSeen;
            // all posts will be considered new and unseen
          }
          if (lastSeenFromServer &&
            (userWhoEditedMyPosts == this.currentUserId || userWhoEditedMyPosts == -1) &&
            (!this.lastSeen || lastSeenFromServer.getTime() >= this.lastSeen.getTime())
          ) {
            this.savePreviousLastSeen();
            this.setLastSeen(lastSeenFromServer);  // start things off using our timestamp from the server, if there was one.
          }
        }
        // now that we've collected all the relevant information, update lastSeen and previousLastSeen as appropriate:
        if (revealNewPosts && (!this._isGated || this.userIsTeacher || this.userHasPosted(this.currentUserId, this.currentGroupId))) {
          this.savePreviousLastSeen();
          this.setLastSeen(mostRecentModTimestamp);
        } // else: our lastSeen doesn't change, and thus all new and modified posts queue up as unseen

        // collect all posts into one array, for setPosts
        let posts = [];
        Object.keys(this._stateData).forEach(function (userKey) {
          let tableStateData = self._stateData[userKey];
          if (self.getDataIsFiltered(tableStateData.userId) === false && self.getDataForCurrentUser(tableStateData.userId)) {
            posts.push.apply(posts, tableStateData.posts);
          }
        });
        this.setPosts(posts);
      };

      this.setPosts = function (posts) {
        let self = this;

        // (We can't completely filter out deleted posts here because would result in the posts being removed from the persistent repository when
        //	the state of this element gets saved).
        this._allPosts = posts.concat().sort(function (a, b) {
          return a.timestamp.getTime() - b.timestamp.getTime();
        });

        this.nonDeletedPosts = [];
        this._allPosts.forEach(function (post) {
          if (!self.postIsCompletelyHidden(post)) {
            self.nonDeletedPosts.push(post);
          }
        });

        let respondents = {};

        this.nonDeletedPosts.forEach(function (post) {
          if (self.postIsNotYetSeen(post, self.lastSeen)) {
            post.isNew = true;
          }

          // Fill the map with unique non-teacher respondents who have a non deleted answer.
          let respondentId;

          if (SHARE_MODE.isUsingSmallGroups(self.share)) {
            if (post.groupId === 0) {
              respondentId = post.userId;
            } else {
              respondentId = post.groupId;
            }
          } else {
            respondentId = post.userId;
          }

          if (respondentId != self.teacherId) {
            respondents[respondentId] = true;
          }
        });

        this.respondentsCount = Object.keys(respondents).length;

        let seenPosts = [];
        this.unseenPostsCount = 0;
        if (this.readonly) {
          seenPosts = this.nonDeletedPosts; // show everything.
        } else {
          let tempArray = [];
          this.nonDeletedPosts.forEach(function (post) {
            tempArray.push(post);
            if (self.postIsNotYetSeen(post, self.lastSeen)) {
              self.unseenPostsCount++;
            }
          });
          seenPosts = tempArray;
        }
        this.topLevelPosts = seenPosts;
        this.allPostsCount = this.nonDeletedPosts.length;

        this.allStudentPostsCount = 0;
        this.nonDeletedPosts.forEach(function (post) {
          if (post.userId != self.teacherId) {
            self.allStudentPostsCount++;
          }
        });
        this.myPostsCount = this.postsCount(this.currentUserId, this.currentGroupId);

        this.buildColumnIndexes();
      };

      this.buildColumnIndexes = function () {
        let self = this;
        this.columnIndexes = [];
        if (this.topLevelPosts && this.topLevelPosts.length) {
          let firstPost = this.topLevelPosts[0];  // we assume that all posts have the same number of columns

          for (let iCol = 0; iCol < firstPost.columnText.length; ++iCol) {
            this.columnIndexes.push(this.buildColumnIndexForCol(iCol));
          }
        }

        this.nameIndex = this.topLevelPosts.concat().sort(function (a, b) {
          return self.infoProvider.getUserDisplayName(a.userId).localeCompare(self.infoProvider.getUserDisplayName(b.userId));
        });
      };

      this.buildColumnIndexForCol = function (iCol) {
        let numericPosts = [];
        let nonnumericPosts = [];
        let regex = /\s*([0-9\.,]*)/;

        this.topLevelPosts.forEach(function (post) {
          let stringVal = post.columnText[iCol];
          if (stringVal && stringVal.length) {
            let match = stringVal.match(regex);
            if (match) {
              let captureString = match[0];

              let numberVal = parseFloat(captureString.replace(',', ''));
              if (numberVal != numberVal)
                // Because of javascript oddness, this is the best way to test for NaN see :

                // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN
              {
                nonnumericPosts.push(post);
              } else {
                numericPosts.push([post, numberVal]);
              }
            }
          } else {
            nonnumericPosts.push(post);
          }
        });

        numericPosts.sort(function (a, b) {
          return a[1] - b[1];
        });

        let numericPostsSorted = [];

        numericPosts.forEach(function (arr) {
          numericPostsSorted.push(arr[0]); // get just the XPTablePost
        });

        nonnumericPosts.sort(function (a, b) {
          let aVal = a.columnText[iCol];
          if (aVal === null) {
            aVal = '';
          }

          let bVal = b.columnText[iCol];
          if (bVal === null) {
            bVal = '';
          }

          return aVal.localeCompare(bVal);
        });

        let columnIndex = numericPostsSorted.concat(nonnumericPosts);
        return columnIndex;
      };

      this.postIsCompletelyHidden = function (post)
        // posts that have been deleted or filtered out, placeholder has already been seen or never will be.
      {
        if (this.isPostVisibleToCurrentUser(post)) {
          return false;
        }
        return true;
      };

      this.isPostVisibleToCurrentUser = function (post) {
        if (!post) {
          return false;
        } // failsafe, hopefully unecessary

        if (this.isGated && !(this.userIsTeacher) && !this.userHasPosted(this.currentUserId, this.currentGroupId)) {
          return false;
        } // if table is gated and student hasn't posted anything, no posts are visible

        if (!this.isPostSharedWithTeacherOnly(post) || // share mode is public = all users can see it
          (this.userIsTeacher)
        ) {
          return true;
        }
        // else sharedWithTeacherOnly
        return ((post.userId == this.currentUserId) || // I can see my own posts and replies.
          (this.isTeacherPost(post.userId)) || // and I can see posts and replies from the teacher
          (post.groupId == this.currentGroupId && post.groupId !== 0) // and I can see posts in my group
        );
      };

      this.isTeacherPost = function (userId) {
        return this.teachers.find(function (teacher) {
          return teacher.uid === userId;
        });
      };

      this.isPostSharedWithTeacherOnly = function (post) {
        if (this.share == SHARE_MODE.TEACHER || this.share == SHARE_MODE.SMALL_GROUP_TEACHER) {
          return true;  // share mode for the wall applies by default
        }
        return false;
      };

      this.postOrParentIsDeleted = function (post) {
        return false;
      };

      this.postHasBeenSeenByCurrentUser = function (post) {
        return !this.postIsNotYetSeen(post, this.lastSeen);
      };

      this.postIsNotYetSeen = function (post, lastSeenDate) {
        return ((lastSeenDate && post.lastModificationTimestamp().getTime() > lastSeenDate.getTime())) &&
          ((post.userId != 0 && post.userId != this.currentUserId) ||
            (post.groupId != 0 && post.groupId != this.currentGroupId));
      };

      this.getPostsForColumn = function (iCol) {
        let posts;
        if (iCol == -2) {
          posts = this.topLevelPosts;
        } else if (iCol == -1) {
          posts = this.nameIndex;
        } else {
          posts = this.columnIndexes[iCol];
        }
        return posts;
      };

      this.getPostForRow = function (iRow, iCol) {
        let posts;
        if (iCol < 0) {
          posts = this.topLevelPosts;
        } else {
          posts = this.columnIndexes[iCol];
        }
        return posts[iRow];
      };

      this.getPostForRowSortedByUserNameUsingInfoProvider = function (row, infoProvider) {
        if (this._allPosts.length) {
          let indexByUsername = this._allPosts.sort(function (a, b) {
            return infoProvider.getUserDisplayName(a).compare(infoProvider.getUserDisplayName(b));
          });

          return indexByUsername[row];
        }
        return null;
      };

      this.findPostWithId = function (postId) {
        return this.findPostWithIdInArray(postId, this._allPosts);
      };

      this.updatePost = function (originalPost, columnText) {
        let updatedPost = originalPost.copyWithColumnText(columnText, originalPost.timestamp, this.currentUserId);
        return this.prepareToSaveUpdatedPost(updatedPost, originalPost);
      };

      this.deletePost = function (originalPost) {
        return this.prepareToSaveDeletedPost(originalPost);
      };

      this.addNewPostWithColumnText = function (columnText, userIdForSave, groupIdForSave) {
        let postsToSave = [];
        postsToSave = this.postsForUserId(userIdForSave, groupIdForSave).concat();
        // creating a new post
        let postId = rfc4122.v4();
        let post = new XPTablePost(postId, this.currentUserId, this.currentGroupId, new Date(), null, -1, columnText);
        postsToSave.push(post);
        return postsToSave;
      };

      this.prepareToSaveUpdatedPost = function (updatedPost, originalPost) {
        let postsToSave = [];
        let updatingPostId = originalPost.postId;
        let userIdForSave = originalPost.userId;
        let groupIdForSave = SHARE_MODE.isUsingSmallGroups(self.share) ? originalPost.groupId : 0;
        if ((originalPost.groupId === 0 && originalPost.userId != this.currentUserId) ||
          (originalPost.groupId !== 0 && originalPost.groupId != this.currentGroupId)) // teacher is editing another user's post
        {
          if (!this.userIsTeacher) {
            return postsToSave;  // failsafe...should never execute this!
          }
        }
        postsToSave = this.postsForUserId(userIdForSave, groupIdForSave).concat();
        let postsToSaveIndex = this.indexOfPost(updatingPostId, postsToSave);
        if (-1 == postsToSaveIndex) {
          return postsToSave;
        }
        postsToSave[postsToSaveIndex] = updatedPost;

        return postsToSave;
      };

      this.prepareToSaveDeletedPost = function (postToDelete) {
        let postsToSave;
        let updatingPostId = postToDelete.postId;
        let userIdForSave = postToDelete.userId;
        let groupIdForSave = postToDelete.groupId;
        if ((postToDelete.groupId === 0 && postToDelete.userId != this.currentUserId) ||
          (postToDelete.groupId !== 0 && postToDelete.groupId != this.currentGroupId)) // teacher is editing another user's post
        {
          if (!this.userIsTeacher) {
            return postsToSave;  // failsafe...should never execute this!
          }
        }
        postsToSave = this.postsForUserId(userIdForSave, groupIdForSave).concat();
        let postsToSaveIndex = this.indexOfPost(updatingPostId, postsToSave);
        if (-1 == postsToSaveIndex) {
          return postsToSave;
        }
        postsToSave.splice(postsToSaveIndex, 1);
        return postsToSave;
      };

      this.getModificationTime = function (posts, userId) {
        let modTime = new Date(0);

        if (posts !== null) {
          posts.forEach(function (post) {
            modTime = modTime.getTime() > post.lastModificationTimestamp().getTime() ?
              modTime : post.lastModificationTimestamp();
          });
        }
        return modTime;
      };

      this.postsForUserId = function (userId, groupId) {
        let retval = [];
        this._allPosts.forEach(function (post) {
          if (post.userId == userId || (post.groupId > 0 && post.groupId == groupId)) {
            retval.push(post);
          }
        });
        return retval;
      };

      this.indexOfPost = function (postId, postArray) {
        let index = 0;
        let foundIndex = -1;
        postArray.forEach(function (post) {
          if (post.postId == postId) {
            foundIndex = index;
          }
          ++index;
        });
        return foundIndex;
      };

      this.findPostWithIdInArray = function (postId, postArray) {
        let foundPost = null;
        postArray.forEach(function (post) {
          if (post.postId == postId) {
            foundPost = post;
          }
        });
        return foundPost;
      };
    };

    return XPTableElementData;
  }]);


tableModule.factory('TableElementService',
  ['ElementsRestService', 'JSONStringUtility', function (ElementsRestService, JSONStringUtility) {

    function decodePost(userId, groupId, elementId, json) {
      return new XPTablePost(json.postId, userId, groupId, new Date(json.timestamp),
        'modified' in json ? new Date(json.modified) : null,
        'modifiedByUser' in json ? json.modifiedByUser : -1, json.columnText);
    }

    function decodePosts(elemState) {
      let userData = elemState.user_data;
      if (!userData) {
        return new XPTableUserState().initFromXPElementState(elemState, null);
      }

      // backward compatibility: userData consisted of an array of posts
      // but new versions could embed this array in a json dictionary, using the key @"posts".

      let userDataIsArray = userData.indexOf('[') === 0;

      let postsJson = null;
      if (userDataIsArray) {  // deal with legacy data (supplying defaults for new values)
        postsJson = JSONStringUtility.parse(userData);
        // backward compatibility: supply defaults for additional values
      } else {
        let tableData = JSONStringUtility.parse(userData);

        if (tableData === null) {
          postsJson = [];
        } else {
          postsJson = tableData.posts;
        }
        // FUTURE: extract additional values from top-level Json dict.
      }

      // parse the json string for each individual post.
      let arrayOfPosts = [];
      postsJson.forEach(function (postJson) {
        arrayOfPosts.push(decodePost(elemState.user_id, elemState.small_gid, elemState.element_id, postJson));
      });

      let userState = new XPTableUserState().initFromXPElementState(elemState, arrayOfPosts);
      return userState;
    }

    function tableStateFromElemState(elemState) {
      return decodePosts(elemState);
    }

    function encodePost(post, elementId) {
      if (!post) {
        return null;
      }

      let dictionary =
        {
          postId: post.postId,
          timestamp: post.timestamp.toISOString(),
          columnText: post.columnText
        };

      if (post._modified) {
        dictionary.modified = post._modified.toISOString();
        dictionary.modifiedByUser = post._modifiedByUserId;
      }
      return dictionary;
    }

    function encodePosts(posts, elementId) {
      if (!posts) {
        return null;
      }

      let postsJson = [];
      posts.forEach(function (post) {
        postsJson.push(encodePost(post, elementId));
      });

      return JSONStringUtility.stringify(postsJson);
    }

    return {
      getAllPosts: function (experienceId, elementId, groupName, isInactive, filter, isUsingSmallGroups, success, error) {
        filter = filter || function (result) {
          return result;
        };

        ElementsRestService.getSharedState(experienceId, elementId, groupName, isInactive,
          function (result) {
            let allStates = {};
            try {
              filter(result).forEach(function (elemState) {
                let userState = tableStateFromElemState(elemState);
                let stateId = userState.groupId && userState.groupId > 0 && isUsingSmallGroups ? userState.groupId : userState.userId;
                allStates[stateId] = userState;
              });
              success(allStates);
            }
            catch (err) {
              error(err);
            }
          },
          error);
      },
      savePosts: function (experienceId, elementId, userId, groupId, timestamp, posts, success, error) {
        try {
          let userData = encodePosts(posts, elementId);
          ElementsRestService.saveUserState(experienceId, elementId, userId, groupId, userData,
            function (data) {
              let userState = decodePosts(data);
              success(userState);
            },
            function (err) {
              error(err);
            }, null);
        }
        catch (err) {
          error(err);
        }
      },
      saveAnalyticsUserState: function(experienceId, elementId, userId, groupId, timestamp, oldPosts, posts, success, error) {
        try {
          let userData = encodePosts(posts, elementId);
          let oldUserData = encodePosts(oldPosts, elementId);
          ElementsRestService.saveAnalyticsUserState(experienceId, elementId, userId, groupId, oldUserData, userData,
            function (data) {
              let userState = decodePosts(userData);
              success(userState);
            },
            function (err) {
              error(err);
            }, null);
        }
        catch (err) {
          error(err);
        }
      },
      updateTableData: function (tableData, userState, options) {
        userState.user_id = parseInt(userState.user_id, 10);
        userState.small_gid = parseInt(userState.small_gid, 10);
        let tableUserState = decodePosts(userState);
        let updatedStateData = {};
        let stateId = userState.small_gid && userState.small_gid > 0 ? parseInt(userState.small_gid, 10) : parseInt(userState.user_id, 10);
        updatedStateData[stateId] = tableUserState;
        let initializeLastSeen = false;
        let revealNewPosts = false;
        if (options) {
          if (UPDATE_TABLE_DATA_OPTIONS.INITIALIZE_LAST_SEEN in options) {
            initializeLastSeen = options[UPDATE_TABLE_DATA_OPTIONS.INITIALIZE_LAST_SEEN];
          }
          if (UPDATE_TABLE_DATA_OPTIONS.REVEAL_NEW_POSTS in options) {
            revealNewPosts = options[UPDATE_TABLE_DATA_OPTIONS.REVEAL_NEW_POSTS];
          }
        }
        return tableData.updateState(updatedStateData, initializeLastSeen, revealNewPosts);
      }
    };
  }]);

tableModule.controller('clientTableElementCtrl',
  ['$scope', '$log', 'widgetConfig', 'ElementsRestService', 'JSONStringUtility', 'TableElementService',
    'ElementsErrorService', '$filter', '$modal', 'ModalService', '$element', '$window', 'SHARE_MODE', 'GATE_MODE',
    'XPTableElementData', '$sce',
    function ($scope, $log, widgetConfig, ElementsRestService, JSONStringUtility, TableElementService,
              ElementsErrorService, $filter, $modal, ModalService, $element, $window, SHARE_MODE, GATE_MODE, XPTableElementData, $sce) {
      // The element is the block of data provided by the source xml
      $scope.options = widgetConfig.getOptions($scope);

      $scope.instructions = {};

      let max_screen_width = 900;
      let responder_column_width = 150;

      $scope.gateMode = GATE_MODE.XPGateModeGated;

      $scope.SHARE_MODE = SHARE_MODE;
      $scope.share = SHARE_MODE.TEACHER;

      let NEW_POST_ID = 'new_post';
      $scope.NEW_POST_ID = NEW_POST_ID;
      let USER_COLUMN_ID = 'user_id';
      $scope.USER_COLUMN_ID = USER_COLUMN_ID;

      $scope.col1AsRowTitle = false;

      $scope.isActive = false;

      $scope.columnDefs = [];

      $scope.sortedByCol = -2;
      $scope.editingPostId = null;
      $scope.editingColumnId = null;
      $scope.allowNewPosts = false;
      $scope.activePosts = [];
      $scope.unseenPostsCount = 0;
      $scope.allowEditing = false;
      $scope.selectedUser = undefined;
      $scope.selectedGroup = undefined;
      $scope.selectedPostId = undefined;
      $scope.isTeacher = false;
      $scope.portionResponded = 0;
      $scope.initialized = false;
      $scope.respondentId = 0;

      let maxEntriesPerUser;
      let maxEntriesPerGroup;

      let tableElementData = null;
      let elementRectIsVisible = false;
      let context;
      let shareFromGroupId = 0;

      let newPostPlaceholder = new XPTablePost(NEW_POST_ID, -1, -1, new Date(), null, null, []);

      let parseElement = function () {
        if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes) {
          return;
        }

        $scope.options.element.config.attributes.forEach(function (attribute) {
          switch (attribute.name) {
            case "table_title" :
              if (attribute.value.length) {
                $scope.title = $sce.trustAsHtml(attribute.value);
              }
              break;
            case "instructions" :
              $scope.instructions.question = $sce.trustAsHtml(attribute.value);
              break;
            case "gate_mode" :
              if (attribute.value == "gated") {
                $scope.gateMode = GATE_MODE.XPGateModeGated;
              } else {
                $scope.gateMode = GATE_MODE.XPGateModeUngated;
              }
              break;
            case "col1_as_row_titles" :
              $scope.col1AsRowTitle = (attribute.value === "true" || attribute.value === true);
              break;
            case "max_entries_per_user" :
              maxEntriesPerUser = attribute.value;
              break;
            case "max_entries_per_group" :
              maxEntriesPerGroup = attribute.value;
              break;
            case "share" :
              $scope.share = attribute.value;
              break;
            case "columns" : {
              let columnIndex = 0;
              $scope.columns = [];

              let addColumn = function (column) {
                if (column.name === "column") {
                  $scope.columns.push(
                    {
                      title: column.value.title,
                      index: columnIndex
                    });
                  ++columnIndex;
                }
              };

              if (attribute.value instanceof Array) {
                attribute.value.forEach(addColumn);
              } else if (attribute.value && attribute.value.column) {
                // if single column is specified, value is just the column not an array.
                $scope.columns.push(
                  {
                    title: attribute.value.column.title,
                    index: columnIndex
                  });
              }
            }
          }
        });

        // Create the column definitions based on the definition for this table
        $scope.columnDefs = [{
          field: USER_COLUMN_ID,
          displayName: $sce.trustAsHtml(''),
          columnClass: 'xp-table-responder-column',
          headerClass: 'xp-table-responder-header',
          cellClass: 'xp-table-responder-cell',
          width: responder_column_width + 'px'
        }];

        $scope.columns.forEach(function (column) {
          let columnDef = {
            field: column.index,
            displayName: $sce.trustAsHtml(column.title),
            columnClass: 'xp-table-data-column',
            headerClass: 'xp-table-data-header',
            cellClass: 'xp-table-data-cell',
            width: ((max_screen_width - responder_column_width) / $scope.columns.length) + 'px',
            enableCellEdit: true
          };
          $scope.columnDefs.push(columnDef);
        });

        // configure the group id based on the share setting
        if ($scope.share == SHARE_MODE.SMALL_GROUP_TEACHER || $scope.share == SHARE_MODE.SMALL_GROUP_GROUP) {
          shareFromGroupId = context.groupId;
        }
      };

      $scope.currentUsersPost = function (cell) {
        return (($scope.selectedUser == cell.userId && !$scope.isUsingSmallGroups()) ||
            ($scope.selectedGroup == cell.groupId && $scope.isUsingSmallGroups()) ||
            $scope.options.context.isReview) &&
          cell.postId != NEW_POST_ID;
      };

      $scope.canEditForRespondent = function (respondentId) {
        if (!$scope.options.context) {
          return false;
        }

        if ($scope.options.context.getViewingInactiveExperience()) {
          return false;
        }

        return $scope.options.context.userIsTeacher() && $scope.options.context.isReview;
      };

      $scope.getEditMenuItemsForUser = function (respondentId) {
        var menuOptions = [];
        if ($scope.options.context.isReview) {
          menuOptions.push({
            text: '<div class="xp-element-menu-edit">Approve</div>',
            click: 'approvePreviewRepsonses()'
          });
        }

        return menuOptions;
      };

      $scope.approvePreviewRepsonses = function() {
        ElementsRestService.approveAnalyticsUserState($scope.options.context.experienceId, $scope.options.element.id)
        .then(function(res) {
          if (res && res.status && res.status == "Approved") {
            ModalService.show({
              message: "Analytic data for this element is now approved.",
              backdrop: 'static',
              buttons: [
                {
                  title: 'Ok',
                  click: '$hide();'
                }
              ]
            });
          }
        });
      };

      $scope.hasResponses = function () {
        return tableElementData && tableElementData.myPostsCount > 0;
      };

      $scope.getDataForCurrentUser = function (userId) {
        return $scope.options.studentId === undefined || $scope.options.studentId === userId;
      };

      function getDataIsFiltered(userId) {
        return !(!$scope.filteredUser || ($scope.filteredUser && $scope.filteredUser.uid == userId));
      }

      $scope.onColumnTextChange = function (postId) {
        if ($scope.changed && $scope.editingColumnTextNew && $scope.editingColumnTextNew.length && postId) {
          $scope.changed({
            elementId: $scope.options.element.id,
            selection: {text: $scope.editingColumnTextNew, postId: postId}
          });
        }
      };

      $scope.onColumnEditTextChange = function (postId) {
        if ($scope.changed && $scope.editingColumnText && $scope.editingColumnText.length && postId) {
          $scope.changed({
            elementId: $scope.options.element.id,
            selection: {text: $scope.editingColumnText, postId: postId}
          });
        }
      };

      function loadPosts(firstTime) {
        let options = $scope.options;
        if (!options.element || !options.element.id) {
          return;
        }

        let isInactive = $scope.options.context.getViewingInactiveExperience();

        TableElementService.getAllPosts($scope.options.context.experienceId, $scope.options.element.id,
          context.groupName, isInactive, $scope.filterAnswers, $scope.isUsingSmallGroups(), function (result) {
            // to make this thread safe (since we're reloading all the state data anyway) we create a new instance
            let newTableElementData = new XPTableElementData(context.userId, shareFromGroupId, context.userIsTeacher(),
              context.clazz.teacher.uid, context.clazz.teachers, context.getViewingInactiveExperience(),
              $scope.gateMode == GATE_MODE.XPGateModeGated,
              (firstTime ? null : tableElementData.lastSeen), $scope,
              getDataIsFiltered, $scope.getDataForCurrentUser);

            newTableElementData.setShare($scope.share);

            // Default selected user to current user
            if (!context.userIsTeacher()) {
              $scope.selectedUser = context.userId;
              $scope.selectedGroup = shareFromGroupId;
            }

            // when first loading data, hide all recently edited posts as unseen
            let lastSeenInitialDate = new Date(0);
            if ($scope.options.context.isPreview) {
              lastSeenInitialDate = new Date();
            }

            newTableElementData.setStateData(result, firstTime, lastSeenInitialDate, (!firstTime && isElementVisible()));
            tableElementData = newTableElementData;

            if (firstTime) {
              let service = options.elementRealtimeService;
              let EVENTS = service.EVENTS;

              service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);


              $scope.$on('$destroy', function () {
                service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
              });

              $scope.$on('$destroy', viewWillDisappear);

              // Notify the widget that were are done loading the data
              widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
            }

            $scope.allowNewPosts = shouldAllowNewPosts();
            reloadTableData();

            // Set existing posts as seen
            tableElementData.setHasSeen(new Date());

            if ($scope.cached) {
              let cachedValue = $scope.cached({elementId: $scope.options.element.id});
              if (cachedValue) {
                $scope.editingPostId = cachedValue.postId;
                if (cachedValue.postId === NEW_POST_ID) {
                  $scope.editingColumnTextNew = cachedValue.text;
                } else {
                  $scope.editingColumnTextNew = newPostPlaceholder.columnText.concat();
                  $scope.editingColumnText = cachedValue.text;
                }
              }
            }

            // if this user has any posted data then enable edit menu
            if (newTableElementData.userHasPosted(context.userId, shareFromGroupId)) {
              let currentUserPosts = newTableElementData.myPosts();
              if (currentUserPosts.length > 0) {
                if (shareFromGroupId > 0) {
                  $scope.selectedGroup = currentUserPosts[0].groupId;
                } else {
                  $scope.selectedUser = currentUserPosts[0].userId;
                }
              }
            }
          },
          function (error) {
            ElementsErrorService.error(error);
          });
      }

      function reloadTableData() {
        $scope.activePosts = getActivePosts();
        updatePortionResponded();
      }

      function updatePortionResponded() {
        if ($scope.isUsingSmallGroups()) {
          $scope.portionResponded = tableElementData.respondentsCount / context.clazz.smallGroups;
        } else if (context.clazz.students.length) {
          $scope.portionResponded = tableElementData.respondentsCount / context.clazz.students.length;
        } else {
          $scope.portionResponded = 0;
        }

      }

      $scope.$watch('options', function (newVal, oldVal) {
        let options = $scope.options;
        if (!options.element) {
          return;
        }

        let service = options.elementRealtimeService;
        let EVENTS = service.EVENTS;
        context = options.context;

        if (!$scope.initialized) {
          // Parse the configuration so we can build the table
          parseElement();

          $scope.isActive = !context.getViewingInactiveExperience();

          tableElementData = new XPTableElementData(context.userId, shareFromGroupId, context.userIsTeacher(),
            context.clazz.teacher.uid, context.getViewingInactiveExperience(),
            true, null, $scope, context.getDataIsFiltered);

          tableElementData.setShare($scope.share);
          newPostPlaceholder = createNewPostPlaceholder();

          $scope.isTeacher = context.userIsTeacher();
          $scope.respondentId = $scope.isUsingSmallGroups() ? context.groupId : context.userId;
          $scope.initialized = true;

          loadPosts(true);
        }

        // This is needed to force the grid to re-layout itself after is is populated with data
        //gridLayoutPlugin.updateGridLayout();
      }, true);

      function updateTableDataWithUserState(userState) {
        let bCurrentlyEditing = $scope.editingPostId !== null;
        let shouldRevealNewPosts = !bCurrentlyEditing;

        // If the user state info was created by the current user then all unseen posts should first be shown
        let stateId = userState.small_gid && userState.small_gid > 0 ? parseInt(userState.small_gid, 10) : parseInt(userState.user_id, 10);
        let currentStateId = shareFromGroupId && shareFromGroupId > 0 ? shareFromGroupId : context.userId;
        if (shouldRevealNewPosts && stateId === currentStateId) {
          $scope.postTableCellDidCancelEdit();
        }

        let options = {};
        options[UPDATE_TABLE_DATA_OPTIONS.REVEAL_NEW_POSTS] = shouldRevealNewPosts;
        let newTableData = TableElementService.updateTableData(tableElementData, userState, options);

        //don't display new posts if user is composing or editing a post
        if (bCurrentlyEditing) {
          let newCount = newTableData.unseenPostsCount;
          if (newCount <= 0) {
            newCount = 1;
          } // because (for TableElem) deleted posts don't show up as new
          $scope.unseenPostsCount += newCount;
        } else {
          tableElementData = newTableData;
          reloadTableData();
        }
      }

      function stateChangedNotificationHandler(e) {
        let message = e.detail;
        let state = message.record;

        if (state.element_id != $scope.options.element.id) {
          return;
        }

        $log.debug("Received table state update: " + JSON.stringify(message));

        if (state.user_id == $scope.options.context.userId &&
          Date(state.timestamp) == tableElementData.lastSeen) {
          return;
        }

        $scope.$apply(function () {
          updateTableDataWithUserState(state);
        });
      }

      function getTextForUserDisplayName(post) {
        if (tableElementData.isPostSharedWithTeacherOnly(post) && !$scope.getUserIsTeacher(post.userId)) {
          return $scope.getUserDisplayName(post.userId) + ' to Teacher';
        }

        return $scope.getUserDisplayName(post.userId);
      }

      function cellForPost(post) {
        let cell =
          {
            postId: post.postId,
            isPrivate: tableElementData.isPostSharedWithTeacherOnly(post), // note: this needs to be set before cell.userId gets set.
            userId: post.userId,
            groupId: post.groupId,
            timestamp: post.lastModificationTimestamp(),
            userCanEdit: $scope.isActive && tableElementData.isPostEditable(post),
            isNew: post.isNew,
            displayUserName: getTextForUserDisplayName(post),
            userIsTeacher: $scope.getUserIsTeacher(post.userId),
            columnText: post.columnText
          };

        post.isNew = false;

        return cell;
      }

      function viewWillDisappear() {
        //update last seen, it needed, but no need to wait for response
        touchRemote();
      }

      $scope.shouldShowUnseen = function () {
        return $scope.isActive && tableElementData.unseenPostsCount > 0;
      };

      //This is a ugly hack to update the saved timestamp for this element.
      //Simply "saving" our posts
      function touchRemote() {
        if (context.getViewingInactiveExperience()) {
          return;
        } // don't change the data for a read-only experience

        let lastSeenTimestamp = tableElementData.lastSeen;
        let previouslySavedLastSeen = tableElementData.getTimestampForUserId(context.userId, shareFromGroupId);

        if (lastSeenTimestamp && lastSeenTimestamp instanceof Date && lastSeenTimestamp.getTime() > 0 &&
          (!previouslySavedLastSeen || previouslySavedLastSeen.getTime() < lastSeenTimestamp.getTime()) &&
          ((tableElementData.myPosts() && tableElementData.myPosts().length > 0) || $scope.isTeacher)) { // if state has changed
          TableElementService.savePosts($scope.options.context.experienceId, $scope.options.element.id,
            context.userId, shareFromGroupId,
            lastSeenTimestamp, tableElementData.myPosts(), function (result) {
            }, function (error) {
              ElementsErrorService.error(error);
            });
        }
      }

      function hasVisibleStyle(element) {
        if (!(element instanceof Element)) {
          return true;
        }

        let styles = $window.getComputedStyle(element);
        if (styles.getPropertyValue('visibility') == 'hidden' || styles.getPropertyValue('display') == 'none') {
          return false;
        }

        if (element.parentNode) {
          return hasVisibleStyle(element.parentNode);
        }

        return true;
      }

      function isElementVisible() {
        return elementRectIsVisible && hasVisibleStyle($element[0]);
      }

      $scope.inViewHandler = function (inView) {
        elementRectIsVisible = inView;

        if (!isElementVisible()) {
          viewWillDisappear();
        }
      };

      $scope.onHeaderClick = function (headerFieldId) {
        let newSortedByCol = $scope.sortedByCol;

        if (headerFieldId == USER_COLUMN_ID) {
          newSortedByCol = -1;
        } else {
          newSortedByCol = headerFieldId;
        }

        if ($scope.sortedByCol != newSortedByCol) {
          $scope.sortedByCol = newSortedByCol;
          $scope.activePosts = getActivePosts();
        }
      };

      $scope.getEditMenuItemsForPost = function (postId) {
        if ($scope.editingPostId === postId) {
          let menuOptions =
            [
              {
                text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
                click: 'cancelEditPost("' + postId + '")'
              }
            ];
          return menuOptions;
        } else {
          let menuOptions =
            [
              {
                text: '<div class="xp-element-menu-edit">Edit</div>',
                click: 'requestEdit("' + postId + '")'
              },
            ];
          if (!$scope.options.context.isReview) {
            menuOptions.push({
                divider: true
              });
            menuOptions.push({
                text: '<div class="xp-element-menu-delete">Delete</div>',
                click: 'requestDelete("' + postId + '")'
              });
          }
          return menuOptions;
        }
      };

      function deselectCurrentPost() {
        $scope.selectedPostId = undefined;
        $scope.allowEditing = false;
      }

      $scope.selectUserPost = function (postId) {
        $scope.selectedPostId = postId;
        $scope.allowEditing = true;
        let post = tableElementData.findPostWithId(postId);
        $scope.selectedUser = post.userId;
      };

      $scope.selectGroupPost = function (postId) {
        $scope.selectedPostId = postId;
        $scope.allowEditing = true;
        let post = tableElementData.findPostWithId(postId);
        $scope.selectedGroup = post.groupId;
      };

      $scope.cancelEditPost = function (postId) {
        cancelEdit();
      };

      $scope.requestEdit = function (postId) {
        startEdit(postId);
      };

      function startEdit(postId) {
        $scope.editingPostId = postId;

        $scope.editingColumnTextNew = newPostPlaceholder.columnText.concat();

        if (postId != NEW_POST_ID) {
          let post = tableElementData.findPostWithId(postId);
          $scope.editingColumnText = post.columnText.concat();
          $scope.editingColumnId = null;
        }
      }

      $scope.requestDelete = function (postId) {
        let post = tableElementData.findPostWithId(postId);

        ModalService.show(
          {
            title: 'Delete post?',
            buttons:
              [
                {
                  title: 'Delete',
                  click: 'deletePost(); $hide();'
                },
                {
                  title: 'Cancel',
                  click: 'cancelDeletePost(); $hide();'
                }
              ],
            cancelDeletePost: function () {
              deselectCurrentPost();
            },
            deletePost: function () {
              deletePost(post);
            }
          }
        );
      };

      $scope.onCellClick = function (event, postId, columnId) {
        if (columnId != USER_COLUMN_ID) {
          if (postId == NEW_POST_ID && $scope.editingPostId != NEW_POST_ID) {
            startEdit(NEW_POST_ID);
          }

          if (postId == $scope.editingPostId) {
            $scope.editingColumnId = columnId;
          }
        }
      };

      $scope.didSubmit = function () {
        let userIdForSave;
        let groupIdForSave;
        let postsToSave;
        let columnText;

        if ($scope.editingPostId == NEW_POST_ID) {
          columnText = $scope.editingColumnTextNew.concat();
          userIdForSave = context.userId;
          groupIdForSave = shareFromGroupId;
          postsToSave = tableElementData.addNewPostWithColumnText(columnText, userIdForSave, groupIdForSave);
        } else {
          columnText = $scope.editingColumnText.concat();
          let originalPost = tableElementData.findPostWithId($scope.editingPostId);
          if (originalPost === null) {
            return;
          }

          userIdForSave = originalPost.userId;
          groupIdForSave = originalPost.groupId;
          postsToSave = tableElementData.updatePost(originalPost, columnText);
        }

        saveModifiedPosts(postsToSave, userIdForSave, groupIdForSave);
        cancelEdit();
      };

      $scope.postTableCellDidCancelEdit = function () {
        let unseenUpdatesCounter = $scope.unseenPostsCount;
        cancelEdit();
        if (unseenUpdatesCounter > 0) {  // if we skipped some updates
          loadPosts(false);	   // request full update of current state
          $scope.unseenPostsCount = 0;
        }
      };

      function cancelEdit() {
        $scope.editingColumnTextNew = newPostPlaceholder.columnText.concat();
        $scope.editingPostId = null;
        $scope.editingColumnId = null;
        deselectCurrentPost();

        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: null});
        }
      }

      function createNewPostPlaceholder() {
        let columnText = [];

        for (let i = 0; i < $scope.columns.length; ++i) {
          columnText.push('');
        }

        return new XPTablePost(NEW_POST_ID, context.userId, shareFromGroupId, new Date(), null, null, columnText);
      }

      function getActivePosts() {
        let posts = [];
        let cells = [];
        if (tableElementData !== null) {
          posts = tableElementData.getPostsForColumn($scope.sortedByCol);
        }

        if (shouldAllowNewPosts()) {
          posts = posts.concat(newPostPlaceholder);
        }

        posts.forEach(function (post) {
          cells.push(cellForPost(post));
        });

        return cells;
      }

      function deletePost(originalPost) {
        if (originalPost.isDeleted) {
          return;
        }

        let userIdForSave = originalPost.userId;
        let groupIdForSave = originalPost.groupId;

        let postsToSave = tableElementData.deletePost(originalPost);
        saveModifiedPosts(postsToSave, userIdForSave, groupIdForSave);

        // disable editing mode when a row is deleted
        deselectCurrentPost();
      }


      $scope.getUserGroup = function (userId) {
        return context && context.getUserGroup(userId);
      };

      function saveModifiedPosts(postsToSave, userIdForSave, groupIdForSave) {
        let timestampForLastSeen;
        if (userIdForSave == context.userId) {
          // if we are saving an edit to our own posts, then the changes should be displayed immediately (but highlighted as new)
          // NOTE that we don't have to check for wall not in view, nor gated (since this post would ungate it anyway).
          timestampForLastSeen = tableElementData.getModificationTime(postsToSave, userIdForSave);
        } else {
          // if we are (a teacher) saving changes to another user's posts,
          // then we shouldn't update the lastseen timestamp here (only the student's app can
          // know whether the wall is in view and thus whether the changes will get displayed. The code uses
          // the fact that this timestamp is less than the modification timestamp of the post to figure out that
          // a user other than self has posted an edit)
          timestampForLastSeen = tableElementData.getTimestampForUserId(userIdForSave, groupIdForSave); // leave the timestamp unchanged
        }

        if ($scope.options.context.isReview) {
          let originalPost = tableElementData.findPostWithId($scope.editingPostId);
          TableElementService.saveAnalyticsUserState($scope.options.context.experienceId, $scope.options.element.id, userIdForSave,
              groupIdForSave, timestampForLastSeen, [originalPost], postsToSave,
          function() {
            $scope.editing = false;
            if ($scope.changed) {
              $scope.changed({elementId: $scope.options.element.id, selection: null});
            }
            let record = {
              user_id: userIdForSave,
              small_gid: $scope.isUsingSmallGroups() ? groupIdForSave : 0,
              element_id: $scope.options.element.id,
              user_data: JSONStringUtility.stringify(postsToSave),
              timestamp: Date(0)
            };
            updateTableDataWithUserState(record);
          },
          function(error) {
            ElementsErrorService.error(error);
          });
        } else {
          TableElementService.savePosts($scope.options.context.experienceId, $scope.options.element.id,
            userIdForSave, groupIdForSave, timestampForLastSeen, postsToSave, function (result) {
            }, function (error) {
              ElementsErrorService.error(error);
            });
        }
      }

      function shouldAllowNewPosts() {
        if (context.getViewingInactiveExperience()) {
          return false;
        }

        if (context.userIsTeacher()) {
          return true;
        } // teacher can add unlimited posts
        return (
          (!maxEntriesPerUser || (tableElementData.myPostsCount < maxEntriesPerUser)) &&
          (!maxEntriesPerGroup || (tableElementData.allStudentPostsCount < maxEntriesPerGroup))
        );
      }

      // customized logic to get a shortened version of the user name
      $scope.getUserDisplayName = function (userId) {
        let user = context.clazz.userWithId(userId);
        return user.firstName.length && user.lastName.length ? user.firstName + ' ' + user.lastName.substring(0, 1) : user.email;
      };

      $scope.isHilighted = function (selectedId) {
        if ($scope.share == SHARE_MODE.SMALL_GROUP_TEACHER || $scope.share == SHARE_MODE.SMALL_GROUP_GROUP) {
          return selectedId == $scope.selectedGroup;
        } else {
          return selectedId == $scope.selectedUser;
        }
      };

      $scope.getUserIsTeacher = function (userId) {
        return context.getUserIsTeacher(userId);
      };

      function isToday(date) {
        let today = new Date();

        return today.getDate() == date.getDate() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear();
      }

      $scope.formatTimestamp = function (timestamp) {
        if (!timestamp) {
          return '';
        }

        return $filter('date')(timestamp, isToday(timestamp) ? 'hh:mm a' : 'MM/dd hh:mm a');
      };

      $scope.isUsingSmallGroups = function () {
        return SHARE_MODE.isUsingSmallGroups($scope.share);
      };

      $scope.canSubmit = function (cell) {
        let answer = $scope.editingColumnTextNew;
        if (cell.postId !== NEW_POST_ID) {
          answer = $scope.editingColumnText;
        }

        if ($scope.options.context.isPreview && !$scope.options.context.isReview) {
          return false;
        }

        if ($scope.editingColumnTextNew) {
          for (let i = 0; i < $scope.editingColumnTextNew.length; ++i) {
            let text = answer[i];
            if (text && text.length > 0) {
              return true;
            }
          }
        }

        return false;
      };
    }]);
