'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = [];

  function controller () {
    var ctrl = this;
  }

  module.component('xpStudentLearningLegend', {
    template: require('./standardsLegend.jade'),
    controller: controller
  });

})();