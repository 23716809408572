'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = [];

function controller() {
  var ctrl = this;
}

module.component('xpDistrictUsersNav', {
  template: require('./adminConsoleUsersNav.jade'),
  controller: controller,
});

})();