'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['$resource', '$log', '$location', '$scope', 'ActiveMode', 'UserManagedSubscriptions', 'SchoolAdminMode', 'DistrictAdminMode',
                      'userPermissions', 'PermissionConsts', 'ModalNavigation', 'xpAlert'];

function controller($resource, $log, $location, $scope, ActiveMode, UserManagedSubscriptions, SchoolAdminMode, DistrictAdminMode,
                    userPermissions, PermissionConsts, ModalNavigation, xpAlert) {

  var ctrl = this;

  ctrl.subscriptions = [];

  function getLicenseAlerts(licenseSeats) {
    var districtCounts = {};
    var exceededSchools = [];

    licenseSeats.forEach(function(lic){
      if (lic.allocated > lic.seats) {
        var added = exceededSchools.find(function(exc) { return lic.name === exc.name; });
        if (!added) {
          exceededSchools.push(lic);
        }
      }
      if (lic.districtSubscriptionId) {
        if (districtCounts[lic.districtSubscriptionId]) {
          districtCounts[lic.districtSubscriptionId].seats = districtCounts[lic.districtSubscriptionId].seats + lic.seats;
          districtCounts[lic.districtSubscriptionId].allocated = districtCounts[lic.districtSubscriptionId].allocated + lic.allocated;
        } else {
          districtCounts[lic.districtSubscriptionId] = {
              name: lic.districtSubscriptionName,
              seats: lic.seats,
              allocated: lic.allocated,
              url: lic.adminUrl,
              message: lic.adminMessage
            };
        }
      }
    });

    var messages = [];
    Object.keys(districtCounts).map(function (key) {
      var district = districtCounts[key];
      if (district.allocated > district.seats) {
        messages.push("You have exceeded your district " + district.name + " seat licenses by " + (district.allocated - district.seats) +
            ". Please adjust permissions <a href=http://help.exploros.com/en/articles/4942000-seat-licenses-for-admins target=blank> Learn more.</a> or " +
            "<a href=" + district.url + " target=blank> " + district.message + "</a>");
      }
    });

    exceededSchools.forEach(function(schoolLicense) {
      if (schoolLicense.districtSubscriptionId) {
        messages.push("One or more campuses have exceeded their " + schoolLicense.name + " seat licenses. Please " +
                "relocate seat licenses per campus by clicking " + schoolLicense.districtSubscriptionName + " Seats below.");
      } else {
        messages.push("You have exceeded your campus " + schoolLicense.name + " seat licenses by " + (schoolLicense.allocated - schoolLicense.seats) +
                      " seats. Please adjust permissions <a href=http://help.exploros.com/en/articles/4942000-seat-licenses-for-admins target=blank> Learn how.</a> or " +
                      "<a href=" + schoolLicense.adminUrl + " target=blank> " + schoolLicense.adminMessage + "</a>");
      }
    });
    return messages;
  }

  UserManagedSubscriptions.getConsoleSubscriptions()
  .then(function(subscriptions) {
    ctrl.subscriptions = (subscriptions || []).sort(function (a, b) {
      return (a.sort_order||0) - (b.sort_order||0);
    });
    var subscriptionIds = ctrl.subscriptions.map(function(sub) {
      return sub.subscription_id;
    });
    UserManagedSubscriptions.getExceededLicenseLimits(subscriptionIds)
    .then(function(licenseSeats){
      var messages = getLicenseAlerts(licenseSeats);
      if (messages && messages.length) {
        messages.forEach(function(msg) {
          xpAlert.error(msg);
        });
      }
    });
  });

  ctrl.navigateToUsers = function() {
    ActiveMode.navigateToUsersAndRosters();
  };

  ctrl.navigateToSeats = function(subscription) {
    if (SchoolAdminMode.isActiveMode()) {
      ModalNavigation.navigateToModal(
          '/subscription/' + subscription.subscription_id + '/license',
          $location.path()
        );
    } else {
      ModalNavigation.navigateToModal(
          '/subscription/' + subscription.subscription_id + '/license/schools',
          $location.path()
        );
    }
  };

  $scope.$on('$destroy', function() {
    xpAlert.clearAlerts();
  });
}

module.component('xpDistrictUsersAndSeats', {
  template: require('./adminConsoleUsersAndSeats.jade'),
  controller: controller,
});

})();
