'use strict';

(function () {
  var module = angular.module('client.components');

  controller.$inject = ['$resource', '$routeParams', '$log', '$location', 'SubscriptionMode', 'ModalNavigation', 'xpAlert', 'userPermissions', 'PermissionConsts', 'api_server'];

  function controller($resource, $routeParams, $log, $location, SubscriptionMode, ModalNavigation, xpAlert, userPermissions, PermissionConsts, api_server) {
    var ctrl = this;
    ctrl.schoolLicenses = [];
    ctrl.totalSeats = 0;
    ctrl.totalNewSeats = 0;
    ctrl.usedSeats = 0;
    ctrl.editing = false;
    ctrl.isValid = true;
    ctrl.errorMsg = "";
    var sortColumn = 'SCHOOL_NAME';
    var sortAscending = true;

    var subscriptionResource =
      $resource(api_server + '/subscriptions/admin/subscriptions/:subscriptionId/schools', {},
        {get: {method: 'GET', isArray: true, params: {subscriptionId: '@subscriptionId'}}});

    var lockSubscriptionResource =
      $resource(api_server + '/subscriptions/admin/subscriptions/:subscriptionId/lock', {},
        {lock: {method: 'GET', isArray: true, params: {subscriptionId: '@subscriptionId'}}});

    var unlockSubscriptionResource =
      $resource(api_server + '/subscriptions/admin/subscriptions/:subscriptionId/unlock', {},
        {unlock: {method: 'GET', isArray: true, params: {subscriptionId: '@subscriptionId'}}});

    var updateSubscriptionResource =
      $resource(api_server + '/subscriptions/admin/subscriptions/:subscriptionId', {},
        {post: {method: 'POST', isArray: true, params: {subscriptionId: '@subscriptionId'}}});

    function loadSubscriptionInfo() {
      if ($routeParams.subscriptionId) {
        subscriptionResource.get({subscriptionId: $routeParams.subscriptionId}).$promise
          .then(function (response) {
            ctrl.schoolLicenses = response || [];
            ctrl.totalSeats = 0;
            ctrl.totalNewSeats = 0;
            ctrl.usedSeats = 0;

            // Count up all used and available seats
            ctrl.schoolLicenses.forEach(function (schoolLicense) {
              ctrl.totalSeats = ctrl.totalSeats + schoolLicense.seats;
              ctrl.usedSeats = ctrl.usedSeats + schoolLicense.used_seats;
              schoolLicense.newSeats = schoolLicense.seats;
            });
            ctrl.totalNewSeats = ctrl.totalSeats;
            sortRows();
          })
          .catch(function (error) {
            $log.error("error in loading subscription info:", error);
          });
      }

      // Get the name of the subscription to display
      SubscriptionMode.getTitle().then(function (name) {
        ctrl.subscriptionName = name;
      });
    }

    ctrl.$onDestroy = function () {
      if (ctrl.editing) {
        unlockSubscriptionResource.unlock({subscriptionId: $routeParams.subscriptionId}).$promise
          .then(function (result) {
            ctrl.editing = false;
          })
          .catch(function (error) {
            $log.error("error unlocking subscription schools: ", error);
          });
      }
    };

    // Loads report information
    loadSubscriptionInfo();

    ctrl.hasEditPermission = function () {
      return userPermissions.hasPermission(PermissionConsts.edit_district_schools_subscriptions);
    };

    ctrl.startEditing = function () {
      lockSubscriptionResource.lock({subscriptionId: $routeParams.subscriptionId}).$promise
        .then(function (result) {
          ctrl.editing = true;
        })
        .catch(function (error) {
          $log.error("error locking subscription schools: ", error);
        });
    };

    ctrl.onSelectSortColumn = function (column) {
      if (column === sortColumn) {
        sortAscending = !sortAscending;
      } else {
        sortAscending = true;
      }
      sortColumn = column;
      sortRows();
    };

    function schoolNameCompare(a, b) {
      return compareStringFields(a.school_name, b.school_name);
    }

    function totalSeatCountCompare(a, b) {
      return a.seats - b.seats;
    }

    function dateCompare(a, b) {
      var aDate = new Date(a.ends_at);
      var bDate = new Date(b.ends_at);
      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
    }

    function usedSeatCountCompare(a, b) {
      return a.used_seats - b.used_seats;
    }

    function availableSeatCountCompare(a, b) {
      return (a.seats - a.used_seats) - (b.seats - b.used_seats);
    }

    function compareStringFields(a, b) {
      if (a === b || !angular.isString(a) && !angular.isString(b)) {
        return 0;
      }
      if (a === null || angular.isUndefined(a) || !angular.isString(a)) {
        return -1;
      }
      return a.localeCompare(b);
    }

    function getDescendingComparator(comparator) {
      return function (a, b) {
        return comparator(a, b) * -1;
      };
    }

    var sortFunctions = {
      'SCHOOL_NAME': schoolNameCompare,
      'TOTAL_SEATS': totalSeatCountCompare,
      'VALID_THROUGH': dateCompare,
      'USED_SEATS': usedSeatCountCompare,
      'AVAILABLE_SEATS': availableSeatCountCompare
    };

    function sortRows() {
      var comparator = sortFunctions[sortColumn] || schoolNameCompare;
      if (!sortAscending) {
        comparator = getDescendingComparator(comparator);
      }
      ctrl.schoolLicenses.sort(comparator);
    }

    ctrl.onSelectSchool = function (license) {
      // Navigate to the school subscription view with this license
      ModalNavigation.navigateToModal('/subscription/' + license.subscription_id + '/license', $location.path());
    };

    ctrl.onCancel = function () {
      unlockSubscriptionResource.unlock({subscriptionId: $routeParams.subscriptionId}).$promise
        .then(function (result) {
          ctrl.editing = false;
          ctrl.schoolLicenses.forEach(function (schoolLicense) {
            schoolLicense.newSeats = schoolLicense.seats;
          });
        })
        .catch(function (error) {
          $log.error("error unlocking subscription schools: ", error);
        });
    };

    function validNewSeats() {
      ctrl.isValid = true;
      // First check that the total seat count matches
      if (ctrl.totalNewSeats > ctrl.totalSeats) {
        ctrl.errorMsg = "You have allocated more than " + ctrl.totalSeats + " licenses which is the total available to your subscription.";
        ctrl.isValid = false;
      } else if (ctrl.totalNewSeats < ctrl.totalSeats) {
        ctrl.errorMsg = "You have allocated less than " + ctrl.totalSeats + " licenses which is the total available to your subscription.";
        ctrl.isValid = false;
      } else {
        // Check each license to make sure the new amount isn't less than the number used
        ctrl.schoolLicenses.forEach(function (schoolLicense) {
          if (!schoolLicense.newSeats && schoolLicense.newSeats !== 0) {
            ctrl.isValid = false;
            ctrl.errorMsg = "Total seats must be a valid number for every school.";
          } else if (schoolLicense.newSeats < schoolLicense.used_seats) {
            ctrl.isValid = false;
            ctrl.errorMsg = "Total seats for a school needs to be at least the number of used seats.";
          }
        });
      }
    }

    ctrl.onSubmit = function () {
      // Create the array to pass new assignments to back end
      var newSeats = ctrl.schoolLicenses.map(function (schoolLicense) {
        return {school_id: schoolLicense.school_id, seats: schoolLicense.newSeats};
      });
      // Call to the back-end to save all changes
      updateSubscriptionResource.post({subscriptionId: $routeParams.subscriptionId, school_seats: newSeats}).$promise
        .then(function (districtResponse) {
          var errorMsg = "";
          districtResponse.forEach(function (schoolResponse) {
            if (schoolResponse.status !== "success") {
              errorMsg += schoolResponse.error_message;
            }
          });
          if (errorMsg.length > 0) {
            xpAlert.error("Something went wrong. The following errors occurred: " + errorMsg, true);
          } else {
            unlockSubscriptionResource.unlock({subscriptionId: $routeParams.subscriptionId}).$promise
              .then(function (result) {
                xpAlert.success("Success! All seats are now in place", true);
                ctrl.editing = false;
                loadSubscriptionInfo();
              })
              .catch(function (error) {
                $log.error("error unlocking subscription schools: ", error);
              });
          }
        })
        .catch(function (error) {
          xpAlert.error("Something went wrong. Please refresh the page.", true);
          $log.error("error updating subscription info:", error);
        });
    };

    ctrl.onSeatKeydown = function (e) {
      var keyCode = (e.keyCode ? e.keyCode : e.which);
      // Allow numbers [0-9], backspace and delete
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 46) {
        e.preventDefault();
      }
    };

    ctrl.onNewSeatsChange = function () {
      ctrl.totalNewSeats = 0;
      ctrl.schoolLicenses.forEach(function (schoolLicense) {
        if (!isNaN(schoolLicense.newSeats)) {
          ctrl.totalNewSeats = ctrl.totalNewSeats + schoolLicense.newSeats;
        }
      });

      validNewSeats();
    };
  }

  module.component('xpSubscriptionLicenseSchools', {
    template: require('./subscriptionLicenseSchools.jade'),
    controller: controller,
  });

})();
