'use strict';
angular.module('xp-element-teacher_note', ['angularWidget', 'client.services'])
  .controller('clientTeacherNoteElementCtrl', ['$scope', 'widgetConfig', '$http', '$timeout', 'ActiveExperience', function ($scope, widgetConfig, $http, $timeout, ActiveExperience) {

    // The element is the block of data provided by the source xml
    $scope.options = widgetConfig.getOptions($scope);
    $scope.link_url = "";

    var DISPLAY_MODE = Object.freeze({kTeacherMode: 0, kStudentMode: 1});
    $scope.DISPLAY_MODE = DISPLAY_MODE;

    function parseElement() {
      var element = $scope.options.element;
      element.config.attributes.forEach(function (attribute) {
        var name = attribute.name;
        var value = attribute.value;
        switch (name) {
          case "title" :
            $scope.title = value;
            break;
          case "text" :
            $scope.text = value;
            break;
          case "linkTitle" :
            $scope.link_title = value;
            break;
          case "linkUrl" :
            $scope.link_url = value;
            break;
        }
      });
    }

    $scope.hideTeacherNotes = function() {
      return ActiveExperience.currentExperience() && ActiveExperience.currentExperience().hideTeacherNotes;
    };

    var element;
    var context;

    $scope.$watch('options', function () {
      var options = $scope.options;
      if (!options.element)
        return;

      element = options.element;
      context = options.context;

      $scope.displayMode = context.userIsTeacher() ? $scope.DISPLAY_MODE.kTeacherMode : $scope.DISPLAY_MODE.kStudentMode;

      parseElement();

      $timeout(function () {
        // Notify the widget that were are done loading the data
        widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
      });

    }, true);

  }]);
