'use strict';

//
/* Controllers */
//

clientControllers.controller('UserInfoDetailCtrl', ['$http', '$scope', '$route', '$rootScope', '$routeParams', 'UserInfoProfileFactory', 'UserInfoSettingsFactory', 'UserInfoDefaultFactory', '$location', 'User', 'UserPhotoFactory', 'CameraService',
  function ($http, $scope, $route, $rootScope, $routeParams, UserInfoProfileFactory, UserInfoSettingsFactory, UserInfoDefaultFactory, $location, User, UserPhotoFactory, CameraService) {

    // Call to initialize directive/service for camera
    $scope.hasUserMedia = CameraService.hasUserMedia;

    // Initialize the form
    var currentUserId = User.getId();

    $scope.standard = {id: 0};
    $scope.xpError = {};
    $scope.enableEmailUpdate = false;
    $scope.validationFailed = function () {
      // If error messages
      if ($scope.xpError.details)
        return $scope.xpError.details.length > 0;
      return false;
    };
    UserInfoDefaultFactory.get({}, {'id': currentUserId}, function (response) {
      $scope.standard = response;
    });

    UserInfoProfileFactory.get({}, {'id': currentUserId}, function (response) {
      // Assign the response INSIDE the callback
      $scope.profile = response;
    });

    UserInfoSettingsFactory.get({}, {'id': currentUserId}, function (response) {
      // Assign the response INSIDE the callback
      $scope.settings = response;

      if ($scope.settings && $scope.settings.auth) {
        for (var account in $scope.settings.auth) {
          if ($scope.settings.auth.hasOwnProperty(account)) {
            if ($scope.settings.auth[account].hasOwnProperty('email')) return;
          }
        }
        $scope.enableEmailUpdate = true;
      }
    });

    $scope.getUserEmailById = function () {
      return $scope.standard.email;
    };

    // ===================================
    // Save User Info
    // ===================================
    $scope.saveUserInfo = function (isValid) {
      // The form checker should validate, but just check flag in case
      if (isValid) {
        var yyyyMMDD = $scope.profile.date_of_birth;
        // Gather up the parameters
        var parameters =
            {
              'id': $scope.standard.id,
              'first_name': $scope.profile.first_name,
              'last_name': $scope.profile.last_name,
              //'email': $scope.profile.email,
              //'email_address': $scope.profile.email_address,
              'address': $scope.profile.address,
              'phone_number': $scope.profile.phone_number,
              'date_of_birth': yyyyMMDD,
              'gender': $scope.profile.gender,
              'about': $scope.profile.about,
              'facebook': $scope.profile.facebook,
              'twitter': $scope.profile.twitter,
              'useGravatar': "FALSE"
            };
        // Call to connect Teacher and Student
        UserInfoDefaultFactory.save(parameters, function (data) { //Success callback
          // Handle and error object
          if (typeof data.error == 'object') {
            // Error 'Saving User Detail Information Failed : ' + data.error.type + '-' + data.error.description);
            $scope.xpError.details = data.error.description;
          }
          else if (typeof data == 'object') {
            // All done, Show this page since it is active page
            $location.path('/userInfo');
          }
        }, function (error) { //Failure callback
          $location.path('/userInfo');
        });
      }
      else {
        $scope.xpError.details = 'Please resolve the errors before submitting the data.';
      }
    };
    // ===================================
    // Populate available camera options
    // ===================================

    // if this browser supports a camera, then add the take photo option
    var takePhoto;
    if ($scope.hasUserMedia)
      takePhoto = "Take Photo";

    $scope.cameraOptionList = [takePhoto, "Choose Photo From Gallery", "Remove"];

    $scope.showPageBasedOnSelection = function (cameraOption, $event) {
      // Determine which item user selected
      switch (cameraOption) {
        case $scope.cameraOptionList[0]:
          $location.path('/userInfo/camera');
          break;
        case $scope.cameraOptionList[1]:
          $location.path('/userInfo/upload');
          break;
        case $scope.cameraOptionList[3]:
          UserPhotoFactory.remove({}, {'id': currentUserId}, function () {
            $route.reload();
          });
          break;
      }
    };
  }]);
