'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('rosterNavigation', ['$location', 'CourseFactory', 'PermissionConsts', 'userClassPermissions',
   function($location, CourseFactory, PermissionConsts, userClassPermissions) {
    function link(scope, element, attrs)
    {
      scope.hasPermission = function() {
        return userClassPermissions.hasPermission(PermissionConsts.ui_campus_rostering);
      };

      scope.canRoster = function() {
        return scope.rostering;
      };

      scope.classRoster = function() {
        $location.path('/class/' + scope.classId + '/roster');
      };

      scope.otherStudents = function() {
        $location.path('/class/' + scope.classId + '/students');
      };

      scope.campusStudentList = function() {
        $location.path('/class/' + scope.classId + '/campus');
      };

      scope.importStudents = function() {
        if (!scope.exceededMaxClassSize) {
          $location.path('/class/' + scope.classId + '/import');
        }
      };

      CourseFactory.maxStudents().then(function(result){
        scope.exceededMaxClassSize = scope.students.length >= result.maxStudents;
        if (scope.exceededMaxClassSize) {
          scope.importStudentsTooltip = "Class size is limited to " + result.maxStudents + " students. You cannot add additional students to this class.";
        } else {
          scope.importStudentsTooltip = null;
        }
      });
    }

    return {
      replace: true,
      restrict: 'E',
      template: require("./rosterNavigation.jade"),
      link: link,
      scope: {
        selected: '=',
        classId: '=',
        students: '=',
        allStudents: '=',
        campusStudents: '=',
        rostering: '='
      }
    };

  }]);
})();