'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('DistrictAdminMode', ['$rootScope', '$q', '$location', '$log', '$routeParams', 'User', 'userDistrictAdminPermissions',
                                       'userSubscriptionPermissions', 'subscriptionReports', 'ReportNavigation', 'ModalNavigation',
    function ($rootScope, $q, $location, $log, $routeParams, User, userDistrictAdminPermissions,
        userSubscriptionPermissions, subscriptionReports, ReportNavigation, ModalNavigation) {

    // Track the current admin
    var inDistrictAdminMode = false;

    // Get the current district id from the URL
    function currentDistrictId() {
      return $routeParams.districtId ? $routeParams.districtId : null;
    }

    // Get the current subscription id from the URL
    function currentSubscriptionId() {
      return $routeParams.subscriptionId ? $routeParams.subscriptionId : null;
    }

    function defaultDistrictId() {
      return userDistrictAdminPermissions.getDistricts(User.getId())
        .then(function(districts){
          if (districts && districts.length) {
            return districts[0].district_id;
          } else {
            return null;
          }
        });
    }

    function defaultSubscriptionId() {
      return userDistrictAdminPermissions.getDistricts(User.getId())
        .then(function(districts){
          if (districts && districts.length) {
            return districts[0].subscription_id;
          } else {
            return null;
          }
        });
    }

    function defaultDistrictSubscriptionId(districtId) {
      return userDistrictAdminPermissions.getDistricts(User.getId())
      .then(function(districts){
        var district = districts.find(function(district) { return district.district_id === districtId; });
        if (district) {
          return district.subscription_id;
        } else {
          return null;
        }
      });
    }

    // test to see if we are in district admin mode
    function isActiveMode() {
      return inDistrictAdminMode;
    }

    var remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
      // If the current route is a district route then set correctly
      if (current && current.$$route && current.pathParams && current.pathParams.districtId) {
        inDistrictAdminMode = true;
      }
      else {
        inDistrictAdminMode = false;
      }
    });

    // Get the current report from the URL
    // named for brevity, in fact back-end returns report_key as report_id
    function currentReportKey() {
      return $routeParams.reportId;
    }

    // return the title when we are in district admin mode
    function getTitle() {
      return userDistrictAdminPermissions.getDistrict(currentDistrictId()).then(function(district){
        var title = "District Admin Console";
        if (district) {
          title = district.name + " Admin Console";
        }
        return title;
      });
    }

    function getDistricts() {
      return userDistrictAdminPermissions.getDistricts(User.getId());
    }

    function navigateToUsersAndLicenses() {
      return defaultDistrictId().then(function(districtId){
        return defaultSubscriptionId().then(function(subscriptionId) {
          $location.path('/district/' + districtId + '/subscription/' + subscriptionId + '/seats').search({});
        });
      });
    }

    function navigateToUsers(districtId) {
      if (!currentDistrictId() && !districtId) {
        return defaultDistrictId().then(function(districtId){
          return defaultSubscriptionId().then(function(subscriptionId) {
            $location.path('/district/' + districtId + '/subscription/' + subscriptionId + '/users').search({});
          });
        });
      } else if (districtId) {
        return defaultDistrictSubscriptionId(districtId).then(function(subscriptionId) {
          $location.path('/district/' + districtId + '/subscription/' + subscriptionId + '/users').search({});
        });
      } else {
        $location.path('/district/' + currentDistrictId() + '/subscription/' + currentSubscriptionId() + '/users').search({});
      }
    }

    function navigateToUsersAndRosters() {
      if (!currentDistrictId()) {
        return defaultDistrictId().then(function(districtId){
          return defaultSubscriptionId().then(function(subscriptionId) {
            ModalNavigation.navigateToModal(
                '/district/' + districtId + '/subscription/' + subscriptionId + '/manage',
                $location.path()
              );
          });
        });
      } else {
        ModalNavigation.navigateToModal(
            '/district/' + currentDistrictId() + '/subscription/' + currentSubscriptionId() + '/manage',
            $location.path()
          );
      }
    }

    function navigateToExperiences() {
      $location.path('/district/' + currentDistrictId() + '/subscription/' + currentSubscriptionId() + '/experiences').search({});
    }

    function navigateToReports() {
      $location.path('/district/' + currentDistrictId() + '/subscription/' + currentSubscriptionId() + '/reports').search({});
    }

    function getReports() {
      return subscriptionReports.getReports(currentSubscriptionId());
    }

    // Get the current report from the URL
    function currentReportId() {
      return $routeParams.reportId ? $routeParams.reportId : undefined;
    }

    function getReportData(filter) {
      return subscriptionReports.getReportData(currentSubscriptionId(), currentReportId(), filter);
    }

    function showReport(reportId) {
      ReportNavigation.navigateToReport(reportId, '/district/' + currentDistrictId() + '/subscription/' + currentSubscriptionId() + '/report/' + reportId,
          {}, $location.path());
    }

    function resetPermissions() {
    }

    return {
      isActiveMode: isActiveMode,
      getTitle: getTitle,
      getDistricts: getDistricts,
      getDistrictId: currentDistrictId,
      getSubscriptionId: currentSubscriptionId,
      navigateToUsersAndLicenses: navigateToUsersAndLicenses,
      navigateToUsers: navigateToUsers,
      navigateToUsersAndRosters: navigateToUsersAndRosters,
      navigateToExperiences: navigateToExperiences,
      navigateToReports: navigateToReports,
      getReports: getReports,
      getReportData: getReportData,
      showReport: showReport,
      resetPermissions: resetPermissions
    };

  }]);

})();