'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('ActiveMode', ['$rootScope', '$q', '$location', '$routeParams', 'ClassMode', 'CurriculumMode', 'SubscriptionMode',
    'DistrictAdminMode', 'SchoolAdminMode', '$log', 'userPermissions', 'PermissionConsts',
    function ($rootScope, $q, $location, $routeParams, ClassMode, CurriculumMode, SubscriptionMode,
              DistrictAdminMode, SchoolAdminMode, $log, userPermissions, PermissionConsts) {

      function getTitle() {
        if (ClassMode.isActiveMode()) {
          return ClassMode.getTitle();
        } else if (CurriculumMode.isActiveMode()) {
          return CurriculumMode.getTitle();
        } else if (SubscriptionMode.isActiveMode()) {
          return SubscriptionMode.getTitle();
        } else if (DistrictAdminMode.isActiveMode()) {
          return DistrictAdminMode.getTitle();
        } else if (SchoolAdminMode.isActiveMode()) {
          return SchoolAdminMode.getTitle();
        } else {
          return $q.when("All Classes");
        }
      }

      function navigateToDefaultView(districtId) {
        // If this user is a district admin then the default view is the District Admin console
        if (userPermissions.hasPermission(PermissionConsts.ui_show_admin_interface)) {
          DistrictAdminMode.getDistricts().then(function (districts) {
            if (districts && districts.length) {
              if (userPermissions.hasPermission(PermissionConsts.view_district_schools_subscriptions) ||
                userPermissions.hasPermission(PermissionConsts.edit_district_schools_subscriptions)) {
                DistrictAdminMode.navigateToUsersAndLicenses();
              } else {
                DistrictAdminMode.navigateToUsers(districtId);
              }
              return;
            }
            SchoolAdminMode.getSchools().then(function (schools) {
              if (schools && schools.length) {
                if (userPermissions.hasPermission(PermissionConsts.ispire_rosters_and_licenses)) {
                  SchoolAdminMode.navigateToUsersAndLicenses();
                } else {
                  SchoolAdminMode.navigateToUsers();
                }

              }
            });
          });
        }
        CurriculumMode.navigateToExperiences();
      }

      function navigateToExperiences() {
        if (ClassMode.isActiveMode()) {
          ClassMode.navigateToExperiences();
        } else if (CurriculumMode.isActiveMode()) {
          CurriculumMode.navigateToExperiences();
        } else if (SubscriptionMode.isActiveMode()) {
          SubscriptionMode.navigateToExperiences();
        } else if (DistrictAdminMode.isActiveMode()) {
          DistrictAdminMode.navigateToExperiences();
        } else if (SchoolAdminMode.isActiveMode()) {
          SchoolAdminMode.navigateToExperiences();
        }
      }

      function navigateToReports() {
        if (ClassMode.isActiveMode()) {
          ClassMode.navigateToReports();
        } else if (CurriculumMode.isActiveMode()) {
          CurriculumMode.navigateToReports();
        } else if (SubscriptionMode.isActiveMode()) {
          SubscriptionMode.navigateToReports();
        } else if (DistrictAdminMode.isActiveMode()) {
          DistrictAdminMode.navigateToReports();
        } else if (SchoolAdminMode.isActiveMode()) {
          SchoolAdminMode.navigateToReports();
        }
      }

      function navigateToPack() {
        if (ClassMode.isActiveMode()) {
          ClassMode.navigateToPack();
        } else if (CurriculumMode.isActiveMode()) {
          CurriculumMode.navigateToPack();
        } else if (SubscriptionMode.isActiveMode()) {
          SubscriptionMode.navigateToPack();
        }
      }

      function navigateToLicensePack() {
        if (ClassMode.isActiveMode()) {
          ClassMode.navigateToLicensePack();
        } else if (CurriculumMode.isActiveMode()) {
          CurriculumMode.navigateToLicensePack();
        } else if (SubscriptionMode.isActiveMode()) {
          SubscriptionMode.navigateToLicensePack();
        }
      }

      function navigateToAdminConsole() {
        if (DistrictAdminMode.isActiveMode()) {
          if (userPermissions.hasPermission(PermissionConsts.view_district_schools_subscriptions) ||
            userPermissions.hasPermission(PermissionConsts.edit_district_schools_subscriptions)) {
            DistrictAdminMode.navigateToUsersAndLicenses();
          } else {
            DistrictAdminMode.navigateToUsers();
          }
        } else if (SchoolAdminMode.isActiveMode()) {
          if (userPermissions.hasPermission(PermissionConsts.ispire_rosters_and_licenses)) {
            SchoolAdminMode.navigateToUsersAndLicenses();
          } else {
            SchoolAdminMode.navigateToUsers();
          }
        }
      }

      function navigateToUsers() {
        if (DistrictAdminMode.isActiveMode()) {
          DistrictAdminMode.navigateToUsers();
        } else if (SchoolAdminMode.isActiveMode()) {
          SchoolAdminMode.navigateToUsers();
        }
      }

      function navigateToUsersAndRosters() {
        if (DistrictAdminMode.isActiveMode()) {
          DistrictAdminMode.navigateToUsersAndRosters();
        } else if (SchoolAdminMode.isActiveMode()) {
          SchoolAdminMode.navigateToUsersAndRosters();
        }
      }

      function getReports() {
        if (ClassMode.isActiveMode()) {
          return ClassMode.getReports();
        } else if (CurriculumMode.isActiveMode()) {
          return CurriculumMode.getReports();
        } else if (SubscriptionMode.isActiveMode() || DistrictAdminMode.isActiveMode() || SchoolAdminMode.isActiveMode()) {
          return SubscriptionMode.getReports();
        } else {
          return $q.when([]);
        }
      }

      function findReportById(d, reports, reportId) {
        // loop through reports looking for the matching id
        var found = false;
        for (var i = 0; i < reports.length; ++i) {
          // must be kept using "==", cannot use "strict comparison"
          if (reports[i].report_id == reportId || reports[i].report_key == reportId) {
            d.resolve(reports[i]);
            found = true;
          }
        }
        if (!found) {
          d.reject();
        }
      }

      function getReport(reportId) {
        var d = $q.defer();

        if (ClassMode.isActiveMode()) {
          ClassMode.getReports().then(function (reports) {
            findReportById(d, reports, reportId);
          })
            .catch(function (error) {
              $log.error("error in getting report for class mode:", error);
              d.reject();
            });
        } else if (CurriculumMode.isActiveMode()) {
          CurriculumMode.getReports().then(function (reports) {
            findReportById(d, reports, reportId);
          })
            .catch(function (error) {
              $log.error("error in getting report for curriculum mode:", error);
              d.reject();
            });
        } else if (SubscriptionMode.isActiveMode()) {
          SubscriptionMode.getReports().then(function (reports) {
            findReportById(d, reports, reportId);
          })
            .catch(function (error) {
              $log.error("error in getting report for subscription mode:", error);
              d.reject();
            });
        } else if (DistrictAdminMode.isActiveMode()) {
          DistrictAdminMode.getReports().then(function (reports) {
            findReportById(d, reports, reportId);
          })
            .catch(function (error) {
              $log.error("error in getting report for district admin mode:", error);
              d.reject();
            });
        } else if (SchoolAdminMode.isActiveMode()) {
          SchoolAdminMode.getReports().then(function (reports) {
            findReportById(d, reports, reportId);
          })
            .catch(function (error) {
              $log.error("error in getting report for shool admin mode:", error);
              d.reject();
            });
        } else {
          return $q.when([]);
        }

        return d.promise;
      }

      function getReportData(filter) {
        if (ClassMode.isActiveMode()) {
          return ClassMode.getReportData(filter);
        } else if (CurriculumMode.isActiveMode()) {
          return CurriculumMode.getReportData(filter);
        } else if (SubscriptionMode.isActiveMode()) {
          return SubscriptionMode.getReportData(filter);
        } else if (DistrictAdminMode.isActiveMode()) {
          return DistrictAdminMode.getReportData(filter);
        } else if (SchoolAdminMode.isActiveMode()) {
          return SchoolAdminMode.getReportData(filter);
        }
      }

      function showReport(reportId) {
        if (ClassMode.isActiveMode()) {
          ClassMode.showReport(reportId);
        } else if (CurriculumMode.isActiveMode()) {
          CurriculumMode.showReport(reportId);
        } else if (SubscriptionMode.isActiveMode()) {
          SubscriptionMode.showReport(reportId);
        } else if (DistrictAdminMode.isActiveMode()) {
          DistrictAdminMode.showReport(reportId);
        } else if (SchoolAdminMode.isActiveMode()) {
          SchoolAdminMode.showReport(reportId);
        }
      }

      function showingExperiences() {
        return !showingPack() && !showingReports() && !showingAdminConsole() && !showingLicensePack();
      }

      function showingPack() {
        return $location.path().indexOf('class-pack') > -1;
      }

      function showingLicensePack() {
        return $location.path().indexOf('license-pack') > -1;
      }

      function showingReports() {
        return $location.path().indexOf('reports') > -1;
      }

      function showingAdminConsole() {
        return $location.path().indexOf('seats') > -1 || $location.path().indexOf('users') > -1 || $location.path().indexOf('manage') > -1;
      }

      function currentReportId() {
        return $routeParams.reportId ? $routeParams.reportId : undefined;
      }

      function resetPermissions() {
        userPermissions.resetPermissions();

        if (ClassMode.isActiveMode()) {
          return ClassMode.resetPermissions();
        } else if (CurriculumMode.isActiveMode()) {
          return CurriculumMode.resetPermissions();
        } else if (SubscriptionMode.isActiveMode()) {
          return SubscriptionMode.resetPermissions();
        } else if (DistrictAdminMode.isActiveMode()) {
          return DistrictAdminMode.resetPermissions();
        } else if (SchoolAdminMode.isActiveMode()) {
          return SchoolAdminMode.resetPermissions();
        }
      }

      return {
        getTitle: getTitle,
        isClassMode: function () {
          return ClassMode.isActiveMode();
        },
        isCurriculumMode: function () {
          return CurriculumMode.isActiveMode();
        },
        isSubscriptionMode: function () {
          return SubscriptionMode.isActiveMode();
        },
        isDistrictAdminMode: function () {
          return DistrictAdminMode.isActiveMode() || SchoolAdminMode.isActiveMode();
        },
        currentClassId: function () {
          return ClassMode.currentClassId();
        },
        currentSubscriptionId: function () {
          if (SubscriptionMode.isActiveMode()) {
            return SubscriptionMode.getSubscriptionId();
          } else if (DistrictAdminMode.isActiveMode()) {
            return DistrictAdminMode.getSubscriptionId();
          } else if (SchoolAdminMode.isActiveMode()) {
            return SchoolAdminMode.getSubscriptionId();
          } else {
            return null;
          }
        },
        currentSchoolId: function () {
          return $routeParams.schoolId ? $routeParams.schoolId : undefined;
        },
        currentDistrictId: function () {
          return DistrictAdminMode.getDistrictId();
        },
        navigateToExperiences: navigateToExperiences,
        navigateToReports: navigateToReports,
        navigateToPack: navigateToPack,
        navigateToLicensePack: navigateToLicensePack,
        navigateToAdminConsole: navigateToAdminConsole,
        navigateToUsers: navigateToUsers,
        navigateToUsersAndRosters: navigateToUsersAndRosters,
        showingExperiences: showingExperiences,
        showingPack: showingPack,
        showingLicensePack: showingLicensePack,
        showingReports: showingReports,
        showingAdminConsole: showingAdminConsole,
        getReports: getReports,
        getReport: getReport,
        getReportData: getReportData,
        showReport: showReport,
        currentReportId: currentReportId,
        navigateToDefaultView: navigateToDefaultView,
        resetPermissions: resetPermissions
      };

    }]);

})();
