'use strict';

(function () {
  let module = angular.module('client.components');

  controller.$inject = ['$scope', 'User', 'tourService'];

  function controller($scope, User, tourService) {
    let ctrl = this;

    ctrl.selectedTour = 0;
    ctrl.tours = [];

    ctrl.$onInit = function () {
      tourService.subscribe($scope, function () {
        ctrl.tours = tourService.getTours();
      });
    };

    ctrl.showOnboardingTaskList = function () {
      return tourService.showTaskList();
    };

    ctrl.cancelOnboarding = function () {
      return tourService.getCancelTour();
    };

    ctrl.closeOnboardingTaskList = function () {
      tourService.setCancelTour(true);
    };

    ctrl.cancelTour = function () {
      tourService.saveUserCurrentTourTopic(0);
    };

    ctrl.continueTour = function () {
      tourService.setCancelTour(false);
    };

    ctrl.getTourTopic = function () {
      return tourService.getTourTopic();
    };

    ctrl.changeTour = function (checkedTour) {
      ctrl.tours = ctrl.tours.map(function (tour) {
        if (checkedTour == tour) {
          tourService.setTour(tour.id);
        }
        return tour;
      });
    };
  }

  module.component('xpTaskList', {
    template: require('./taskList.jade'),
    controller: controller
  });

})();
