'use strict';
angular.module('xp-element-video', ['angularWidget', 'client.services'])
  .controller('clientVideoElementCtrl', ['$scope', 'widgetConfig', '$http', '$sce', '$timeout', function ($scope, widgetConfig, $http, $sce, $timeout) {
    function getVideoPath(path){
      // If the URL does not begin with HTTP then we need to prepend it
      if (!/^(f|ht)tps?:\/\//i.test(path)) {
        path = "http://" + path;
      }

      return path.replace("watch?v=", "embed/");
    }

	  $scope.videotype = "thirdparty";
	  $scope.alignment = undefined;
	  $scope.url = undefined;
	  $scope.videoURL = "";

    $scope.$watch('url', function(url) {
      $scope.videoURL = getVideoPath(url);
    });

	  $scope.$watch('options', function() {
		var options = $scope.options;
		if (!options.element)
			return;
		
		var element = $scope.options.element;
		element.config.attributes.forEach(function(attribute)
		{
			var name = attribute.name;
			var value = attribute.value;

			switch (name)
			{
			case 'url' :
				// if youtube in the name
				if (value.indexOf("youtube.com") > -1 || value.indexOf("youtu.be") > -1 || value.indexOf("vimeo.com") > -1)
					$scope.videotype = "thirdparty";
				else
					$scope.videotype = "uploaded";
				
				// Save the URL to the video
				$scope.url = value;
				break;
				
			case 'alignment' :
				if (value == "center")
					$scope.alignment = "video-element-alignment-center";
				else if (value == "right")
					$scope.alignment = "video-element-alignment-right";						
				break;
			}
		});
		$timeout(function() {
			// Notify the widget that were are done loading the data
			widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
		});
	  }, true);
	  
	  // The element is the block of data provided by the source xml
	  $scope.options = widgetConfig.getOptions($scope);
  }]);
