'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpReportExportDropdown', ['ExperienceNavigator', '$location',
   function(ExperienceNavigator, $location) {

     function link(scope, element, attrs)
     {
       scope.onExport = function() {
         // Make sure the user defined a callback function
         if (scope.onExportcsv) {
           scope.onExportcsv();
         }
       };
       
       scope.onPrint = function() {
         // Make sure the user defined a callback function
         if (scope.onPrintpdf) {
           scope.onPrintpdf();
         }
       };
     }

     return {
       replace: true,
       restrict: 'E',
       transclude: true,
       template: require('./reportExportDropdown.jade'),
       link: link,
       scope: {
         showexport: '=',
         showprint: '=',
         onExportcsv: '&',
         onPrintpdf: '&'
       }
     };
   }]);
})();
