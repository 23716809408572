'use strict';

angular.module('client.services').factory('ClassCodes', ['$resource', 'api_server',
  function ($resource, api_server) {
    var url = api_server + '/classes/:cid';
    return $resource(url, {cid: '@cid'}, {
      lock: {url: url + '/lock', method: 'PUT', params: {cid: '@cid'}},
      unlock: {url: url + '/unlock', method: 'PUT', params: {cid: '@cid'}},
      newCode: {url: url + '/new_code', method: 'PUT', params: {cid: '@cid'}}
    });
  }]);

angular.module('client.services').factory('ClassCodeValidator', ['$resource', 'api_server',
  function ($resource, api_server) {
    var url = api_server + '/classes/:code';
    return $resource(url, {code: '@code'}, {
      validateCode: {url: url + '/validate_code', method: 'GET', params: {code: '@code'}},
      validateCoteacherCode: {url: url + '/validate_coteacher_code', method: 'GET', params: {code: '@code'}}
    });
  }]);
