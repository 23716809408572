'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['$resource', '$routeParams', '$log', '$rootScope', 'api_server'];

function controller($resource, $routeParams, $log, $rootScope, api_server) {
  var ctrl = this;
  ctrl.availableSubscriptions = [];
  ctrl.classes = [];
  var sortColumn = 'DEFAULT';
  var sortAscending = true;

  var subscriptionResource =
    $resource(api_server + '/subscriptions/admin/subscriptions/:subscriptionId', {},
        {params: {subscriptionId: '@subscriptionId'}});

  var subscriptionSeatsResource =
    $resource(api_server + '/seats/subscription/:subscriptionId', {},
        {params: {subscriptionId: '@subscriptionId'}});

  function loadSubscriptionInfo() {
    if ($routeParams.subscriptionId) {
      subscriptionResource.get({subscriptionId: $routeParams.subscriptionId}).$promise
      .then(function(response) {
        response = response || {};
        response.active_classes = response.active_classes || [];
        response.past_classes = response.past_classes || [];
        var idNum = parseInt($routeParams.subscriptionId, 10);
        var activeSubscription = {
          id: idNum,
          subscription_id: idNum,
          name: response.name,
          description: response.description
        };
        response.active_classes.forEach(function(clazz) {
          clazz.subscriptions = [activeSubscription];
        });
        ctrl.availableSeats = response.seats;
        ctrl.usedSeats = response.used_seats;
        ctrl.classes = response.active_classes.concat(response.past_classes);
        ctrl.availableSubscriptions =  [activeSubscription];
        ctrl.endsAt = response.ends_at;
        ctrl.subscriptionName = response.name;
        sortRows();
      })
      .catch(function(error) {
        $log.error("error in loading subscription info:",error);
      });
    }
  }

  function updateLicensesUsed() {
    subscriptionSeatsResource.get({subscriptionId: $routeParams.subscriptionId}).$promise
    .then(function(response) {
      response = response || {};
      ctrl.availableSeats = response.seats;
      ctrl.usedSeats = response.used_seats;
    })
    .catch(function(error) {
      $log.error("error in updating licenses used:",error);
    });
  }

  ctrl.onCourseChange = function(course) {
    ctrl.classes.forEach(function(clazz) {
      if (clazz.cid == course.cid) {
        var newSubscriptions = [];
        course.licenses.forEach(function(license) {
          ctrl.availableSubscriptions.forEach(function(subscription) {
            if (license.subscription_id == subscription.subscription_id) {
              newSubscriptions.push(subscription);
            }
          });
        });
        clazz.subscriptions = newSubscriptions;
      }
    });
    updateLicensesUsed();
  };

  loadSubscriptionInfo();

  ctrl.onSelectSortColumn = function(column) {
    if (column === sortColumn) {
      sortAscending = !sortAscending;
    } else {
      sortAscending = true;
    }
    sortColumn = column;
    sortRows();
  };

  function classNameCompare(a, b) {
    return compareStringFields(a.class_name, b.class_name);
  }

  function classCodeCompare(a, b) {
    return compareStringFields(a.code, b.code);
  }

  function teacherNameCompare(a, b) {
    var cr = compareStringFields(a.last_name, b.last_name);
    return cr || compareStringFields(a.first_name, b.first_name);
  }

  function subscriptionCompare(a, b) {
    if (a.subscriptions === b.subscriptions ||
        a.subscriptions && a.subscriptions.length && a.subscriptions.length === 0 &&
        b.subscriptions && b.subscriptions.length && b.subscriptions.length === 0) {
      return 0;
    }

    if (a.subscriptions === null ||
        angular.isUndefined(a.subscriptions) ||
        a.subscriptions.length === 0) {
      return 1;
    }

    if (b.subscriptions === null ||
        angular.isUndefined(b.subscriptions) ||
        b.subscriptions.length === 0) {
      return -1;
    }

    return compareStringFields(a.subscriptions[0].name, b.subscriptions[0].name);
  }

  function studentCountCompare(a, b) {
    return a.students - b.students;
  }

  var defaultComparators = [subscriptionCompare, teacherNameCompare, classNameCompare];
  function defaultCompare(a, b) {
    return compoundCompare(a, b, defaultComparators);
  }

  function compareStringFields(a, b) {
    if (a === b || !angular.isString(a) && !angular.isString(b)) return 0;
    if (a === null || angular.isUndefined(a) || !angular.isString(a)) return -1;
    return a.localeCompare(b);
  }

  function compoundCompare(a, b, comparators) {
    var cr = 0;
    for (var i = 0; i < comparators.length; ++i) {
      cr = comparators[i](a, b);
      if (cr !== 0) return cr;
    }
    return cr;
  }

  function getDescendingComparator(comparator) {
    return function(a, b) {
      return comparator(a, b) * -1;
    };
  }

  var sortFunctions = {
    'DEFAULT': defaultCompare,
    'CLASS_NAME': classNameCompare,
    'TEACHER_NAME': teacherNameCompare,
    'STUDENT_COUNT': studentCountCompare,
    'CLASS_CODE': classCodeCompare,
    'SUBSCRIPTION': subscriptionCompare
  };

  function sortRows() {
    var comparator = sortFunctions[sortColumn] || defaultCompare;
    if (!sortAscending) {
      comparator = getDescendingComparator(comparator);
    }
    ctrl.classes.sort(comparator);
  }
}

module.component('xpSubscriptionLicense', {
  template: require('./subscriptionLicense.jade'),
  controller: controller,
});

function classLicenseController($log) {
  var ctrl = this;

  ctrl.copyClassCode = function(code, e) {
    e.stopPropagation();
    e.preventDefault();
    var copyElement = document.createElement("textarea");
    copyElement.style.position = 'fixed';
    copyElement.style.opacity = '0';
    copyElement.textContent = code;
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(copyElement);
    copyElement.select();
    document.execCommand('copy');
    body.removeChild(copyElement);
  };
}

module.component('xpSubscriptionLicenseClassList', {
  templateUrl: 'components/subscriptionLicense/subscriptionLicenseClassList.tmpl',
  bindings: {
    classes: '<',
    availableSubscriptions: '<',
    onCourseChange: '&'
  },
  controller: classLicenseController
});

classController.$inject = ['$log', '$resource', 'CourseAPI', 'xpAlert', 'ModalService', 'api_server'];

function classController($log, $resource, CourseAPI, xpAlert, ModalService, api_server) {
  var ctrl = this;
  var studentsResource =
    $resource(api_server + '/classes/:classId/students', {},
        {get: {isArray: true, params: {classId: '@classId'}}});

  var course;
  ctrl.selectedSubscriptions = [];
  var existingSubscriptions = ctrl.selectedSubscriptions.concat();

  ctrl.$onChanges = function(changesObj) {
    if (changesObj.isOpen.currentValue) {
      if (angular.isUndefined(ctrl.students)) {
        CourseAPI.get({cid: ctrl.clazz.cid}).$promise
        .then(function (responseCourse) {
          course = responseCourse;
          if (ctrl.availableSubscriptions instanceof Array && course.licenses instanceof Array) {
            ctrl.availableSubscriptions.forEach(function(available) {
              course.licenses.forEach(function(existing) {
               if(available.subscription_id === existing.subscription_id) {
                 ctrl.selectedSubscriptions.push(available);
               }
              });
            });
            existingSubscriptions = ctrl.selectedSubscriptions.concat();
          }
        })
        .then(function() {
          studentsResource.get({classId: ctrl.clazz.cid}).$promise
          .then(function(response) {
            response = response || [];
            ctrl.students = response;
          });
        })
        .catch(function(error) {
          $log.error("error in courses api get:",error);
        });
      }
    }
  };

  function updateLicensesOnServer(selectedSubscriptions) {
    var newSubscriptions = (course.licenses ? course.licenses.concat() : []);
    // Remove all available subscriptions
    ctrl.availableSubscriptions.forEach(function(available) {
      for (var i = 0; i < newSubscriptions.length; ++i) {
        if (newSubscriptions[i].subscription_id == available.subscription_id) {
          newSubscriptions.splice(i, 1);
          break;
        }
      }
    });
    // Add all selected subscriptions
    newSubscriptions = newSubscriptions.concat(selectedSubscriptions);
      course.licenses = newSubscriptions;

    CourseAPI.update(course).$promise
    .then(function (responseCourse) {
      if (responseCourse && responseCourse.isError) {
        var close = xpAlert.error("This class was not licensed because you would exceed your " + responseCourse.subscriptionName + " seat limit by " + responseCourse.exceeded + ".", true);
        restoreSubscriptions();
        ctrl.onCourseChange({course: course});
      }
      else {
        course = responseCourse;
        ctrl.selectedSubscriptions = selectedSubscriptions;
        existingSubscriptions = ctrl.selectedSubscriptions.concat();
        ctrl.onCourseChange({course: course});
      }
    });
  }

  function restoreSubscriptions() {
    ctrl.selectedSubscriptions = existingSubscriptions.concat();
    course.licenses = existingSubscriptions;
  }

  ctrl.onSelectSubscription = function(item) {
    if (!angular.isUndefined(course)) {
      var newSubscriptions = ctrl.selectedSubscriptions.concat();
      newSubscriptions.push(item);
      updateLicensesOnServer(newSubscriptions);
    }
  };

  ctrl.onRemoveSubscription = function(item) {
    ModalService.show({
      message: 'Are you sure you want to deactivate the license for this class? ' +
                'The class will lose access to all ' + item.name + '-licensed features.',
      buttons: [
        {
          title: 'Cancel',
          click: 'restoreSubscriptions(); $hide()',
          class: 'btn btn-default'
        },
        {
          title: 'OK',
          click: 'removeSubscription(); $hide()'
        }
      ],
      removeSubscription: function () {
        removeSubscription(item);
      },
      restoreSubscriptions: restoreSubscriptions
    });
  };

  function removeSubscription(item) {
    var newSubscriptions = ctrl.selectedSubscriptions.concat();
    for (var i=0; i < newSubscriptions.length; ++i) {
      if (newSubscriptions[i].id === item.id) {
        newSubscriptions.splice(i, 1);
        updateLicensesOnServer(newSubscriptions);
        return;
      }
    }
  }
}

module.component('xpSubscriptionLicenseClass', {
  templateUrl: 'components/subscriptionLicense/subscriptionLicenseClass.tmpl',
  controller: classController,
  bindings: {
    clazz: '<',
    isOpen: '<',
    availableSubscriptions: '<',
    onCourseChange: '&'
  }
});

module.component('xpMultiPicker', {
  templateUrl: 'components/subscriptionLicense/xpMultiPicker.tmpl',
  bindings: {
    available: '<',
    selected: '<',
    onSelect: '&',
    onRemove: '&',
    placeholder: '@',
    prompt: '@'
  }
});

module.component('xpLicensePicker', {
  template: "<xp-multi-picker available='$ctrl.available' selected='$ctrl.selected' " +
            "on-select='$ctrl.onSelect({item: item})' on-remove='$ctrl.onRemove({item:item})' " +
            "placeholder='{{\"Select licenses...\"}}' prompt='{{\"Choose licenses to add to the class.\"}}'>",
  bindings: {
    available: '<',
    selected: '<',
    onSelect: '&',
    onRemove: '&'
  }
});

})();
