'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('experienceTemplateStandards', ['standardsService', 'userPermissions', 'PermissionConsts', 'ModalService', 'SegmentFactory',
   function(standardsService, userPermissions, PermissionConsts, ModalService, SegmentFactory) {
    function link(scope, element, attrs)
    {
      var defaultCharsPerStandard = 5;
      scope.expanded = false;
      scope.experienceStandards = [];
      scope.experienceBreakouts = [];
      scope.selectedBreakouts = [];
      scope.selectedStandard = null;
      scope.numDisplayStandards = scope.maxStandards||5;
      scope.firstStandardIndex = 0;
      scope.lastStandardIndex = scope.numDisplayStandards - 1;

      function calculateMaxDisplay() {
        // need to compute the number that will actually fit based on passed in value and length of text
        var charCount = 0;
        charCount += scope.experienceStandards.reduce(function(a, b) {
          return a + b.expectation_code.length;
        }, 0) / scope.experienceStandards.length;
        scope.numDisplayStandards = Math.round((scope.maxStandards * defaultCharsPerStandard) / charCount);
        scope.lastStandardIndex = scope.numDisplayStandards - 1;
      }

      function showStandards() {
        return (scope.permissions || []).indexOf(PermissionConsts.ui_curriculum_show_experience_card_standards) != -1;
      }

      function sortByLevel(a, b, level) {
        var aCode = a.expectation_code.split('.');
        var bCode = b.expectation_code.split('.');
        if (aCode[level] && bCode[level]) {
          var aNum = parseInt(aCode[level], 10);
          var bNum = parseInt(bCode[level], 10);

          // parseint returns Nan if not a number so check both before comparing
          if (!isNaN(aNum) && !isNaN(bNum)) {
            if (aNum !== bNum) {
              return aNum - bNum;
            }
            else {
              return sortByLevel(a, b, level+1);
            }
          }
          else {
            var stringComp = aCode[level].localeCompare(bCode[level]);
            if (stringComp !== 0) {
              return stringComp;
            }
            else {
              return sortByLevel(a, b, level+1);
            }
          }
        }
        else {
          return 0;   // If invalid level then return equal
        }
      }

      function sortStandards(standards) {
        // Sort recursing down each level
        return standards.sort(function(a, b) {
          return sortByLevel(a, b, 0);
        });
      }

      scope.onExpandStandards = function() {
        SegmentFactory.track(SegmentFactory.events().STANDARDS_CLICKED);


        if (showStandards()) {        // if the user has permissions then show the standards
          scope.expanded = true;

          // Reset to the beginning of the standards
          scope.firstStandardIndex = 0;
          scope.lastStandardIndex = scope.numDisplayStandards - 1;
        }
        else {
          ModalService.show({
            feature: 'ui_curriculum_show_experience_card_standards',
            template: require('../../views/partials/modals/subscriptionFeatureModal.jade'),
            backdrop: 'static'
          });
        }
      };

      scope.onCollapseStandards = function() {
        scope.expanded = false;
        scope.selectedStandard = null;
        scope.selectedBreakouts = [];
      };

      scope.onExpandCollapseStandards = function() {
        if (scope.expanded) {
          scope.onCollapseStandards();
        }
        else {
          scope.onExpandStandards();
        }
      };

      scope.onSelectStandard = function(standard) {
        if (scope.showBreakouts) {
          // track the selected standard
          scope.selectedStandard = standard;

          // Filter the breakouts by the selected standard
          scope.selectedBreakouts = scope.experienceBreakouts.filter(function(breakout) {
            return standard.id === breakout.expectation_id;
          });
        }
      };

      scope.FormatExpectation = function(expectation) {
        if (expectation) {
          return expectation.charAt(0).toUpperCase() + expectation.slice(1) + ". Specifically:";
        }
      };

      scope.FormatBreakout = function(standard, breakout) {
        if (standard && breakout) {
          var codeIndex = breakout.breakout_code.split(".").pop();
          return "<strong>" + standard.expectation_code + "</strong>&nbsp;&nbsp;" + "(" + codeIndex + ") " + breakout.breakout_label;
        }
      };

      scope.nextPage = function() {
        scope.firstStandardIndex = Math.min(scope.firstStandardIndex + scope.numDisplayStandards, scope.experienceStandards.length - scope.numDisplayStandards);
        scope.lastStandardIndex = scope.firstStandardIndex + scope.numDisplayStandards - 1;
        scope.selectedStandard = null;
      };

      scope.prevPage = function() {
        scope.firstStandardIndex = Math.max(0, scope.firstStandardIndex - scope.numDisplayStandards);
        scope.lastStandardIndex = scope.firstStandardIndex + scope.numDisplayStandards - 1;
        scope.selectedStandard = null;
      };

      // Get the standards for this template/experience
      if (scope.breakouts) {
        var expectations = scope.standards.map(function(std) {
          return std.expectation;
        });
        scope.experienceStandards = sortStandards(expectations);
        calculateMaxDisplay();

        var breakouts = scope.standards.map(function(std) {
          return std.breakouts;
        });

        breakouts.forEach(function(bo) {
          scope.experienceBreakouts = scope.experienceBreakouts.concat(bo);
        });

        scope.experienceBreakouts = scope.experienceBreakouts.sort(function(a,b){
          return a.breakout_order - b.breakout_order;
        });

        // This activates (selects) a specific standard when initially showing
        if (scope.select >= 0 && scope.select < scope.standards.length) {
          scope.onExpandStandards();
          scope.onSelectStandard(scope.standards[scope.select].expectation);
        }
      } else if (scope.templateId && scope.standardSetId) {
        standardsService.templateExpectations(scope.templateId, scope.standardSetId).$promise
          .then(function(templateStandards){
            scope.experienceStandards = sortStandards(templateStandards);
            calculateMaxDisplay();
        });
        standardsService.templateBreakouts(scope.templateId).$promise
          .then(function(templateBreakouts) {
            scope.experienceBreakouts = templateBreakouts.sort(function(a,b){
              return a.breakout_order - b.breakout_order;
            });

            // This activates (selects) a specific standard when initially showing
            if (scope.select >= 0 && scope.select < scope.standards.length) {
              scope.onExpandStandards();
              scope.onSelectStandard(scope.standards[scope.select].expectation);
            }
        });
      }
    }

    return {
      replace: true,
      restrict: 'E',
      template: require("./templateStandards.jade"),
      link: link,
      scope: {
        standards: '=',
        standardSetId: '=',
        standardSetLabel: '=',
        templateId: '=',
        showBreakouts: '=',
        breakouts: '=',
        permissions: '=',
        maxStandards: '=',
        select: '=',
      }
    };

  }]);
})();
