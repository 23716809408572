'use strict';

angular.module('client.services').factory('elementStorage', ['$sessionStorage',
  function ($sessionStorage) {

  function getKey(user, experienceId, elementId) {
    var key = user + "::" + experienceId + "::" + elementId;
    return key;
  }

  function saveState(user, experienceId, elementId, value) {
    $sessionStorage[getKey(user, experienceId, elementId)] = value;
  }

  function clearState(user, experienceId, elementId) {
    if ($sessionStorage[getKey(user, experienceId, elementId)]) {
      delete $sessionStorage[getKey(user, experienceId, elementId)];
    }
  }

  function getState(user, experienceId, elementId) {
    return $sessionStorage[getKey(user, experienceId, elementId)];
  }

  return {
    saveState: saveState,
    clearState: clearState,
    getState: getState
  };
}]);