'use strict';

(function() {
var module = angular.module('client.components');

fullPageModalController.$inject = ['$location', 'ModalNavigation', '$attrs',
                                   'xpAlert', '$scope', 'ActiveMode'];

function fullPageModalController($location, ModalNavigation, $attrs, xpAlert, $scope, ActiveMode) {
  var ctrl = this;

  ctrl.back = function() {
    if ($attrs.onBack) {
      ctrl.onBack();
    }
    else {
      var backDestination = ModalNavigation.getBackDestination();
      if (backDestination) {
        $location.path(backDestination);
      }
      else {
        ActiveMode.navigateToDefaultView();
      }
    }
  };

  $scope.$on('$destroy', function() {
    xpAlert.clearAlerts();
  });
}

module.component('xpFullPageModal', {
  template: require('./fullPageModal.jade'),
  bindings: {
    onBack: '&',
    title: '@'
  },
  controller: fullPageModalController,
  transclude: true
});

})();

