'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = [ '$location', '$scope', '$log', 'CourseFactory', 'classIdService', 'User', 'ExperienceGroupListFactory',
                         'ModalService', 'xpAlert', 'RealtimeEvents', 'WebSocket', 'rosterService', 'userClassPermissions', 'PermissionConsts'];

  function controller($location, $scope, $log, CourseFactory, classIdService, User, ExperienceGroupListFactory,
                        ModalService, xpAlert, RealtimeEvents, WebSocket, rosterService, userClassPermissions, PermissionConsts) {
    var ctrl = this;

    ctrl.students = [];
    ctrl.allStudents = [];
    ctrl.campusStudents = [];
    ctrl.allowRostering = !userClassPermissions.hasPermission(PermissionConsts.ui_disable_class_rostering);

    function sortStudents(sortBy, reverse) {
      return function(a, b) {
        if (a && b) {
          return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase()) * (reverse ? -1 : 1);
        }
        else {
          return 0;   // if either is invalid then just return they are equal.  Shoul never really happen
        }
      }
    }

    ctrl.currentClassId = classIdService();
    CourseFactory.setCurrentCourse(ctrl.currentClassId);

    function getRoster(refresh) {
      rosterService.getAllRosters(ctrl.currentClassId, refresh).then(function(rosters) {
        ctrl.students = rosters.classRoster;
        ctrl.allStudents = rosters.teacherRoster;
        ctrl.campusStudents = rosters.campusRoster;
      });
    }

    function refreshClassStudents() {
      getRoster(true);
    }

    ctrl.addToClass = function(user) {
      // Add the selected students to the class
      CourseFactory.addStudents({cid: ctrl.currentClassId, students: angular.toJson([user])}).then(function(result){
        // if this returns an error then report it
        if (result && result.isError) {
          xpAlert.error("The student can't be added because you would exceed your " + result.subscriptionName + " seat limit by " + result.exceeded + ".", true);
        }
        else if (result && result.error) {
          CourseFactory.maxStudentsMsg().then(function(msg){
            xpAlert.error(msg, true);
          });
        } else {
          // Refresh the list of students in this class
          refreshClassStudents();
        }
      });
    };

    ctrl.deleteStudent = function(user) {
      ModalService.show(
        {
          title: 'Delete Student',
          message: 'This will delete your connection to this student. It does not delete the student account.',
          buttons: [
            {
              title: 'Delete',
              click: 'deleteStudent(); $hide()'
            },
            {
              title: 'Cancel',
              click: '$hide()'
            }
          ],
          deleteStudent: function () {
            CourseFactory.removeStudentsFromTeacher({students: angular.toJson([user])}).then(function(course){
              if (course && course.error) {
                xpAlert.error(course.error.description, true);
              } else {
                xpAlert.success("You successfully deleted the connection to " + user.first_name + " " + user.last_name + ".", true);

                // Refresh the students in the list
                refreshClassStudents();
              }
            });
          }
        }
      );
    };

    // Get the class
    CourseFactory.get(ctrl.currentClassId).then(function(course) {
      ctrl.courseName = course.group_name;

      // Get the list of students that are currently in this class
      getRoster(false);

      // If this is a teacher then we need to capture element state changes
      RealtimeEvents.on(WebSocket, RealtimeEvents.EVENTS.Students.XPNewStudentdNotification, newStudentNotificationHandler);
      $scope.$on('$destroy', function(){
        RealtimeEvents.removeListener(WebSocket, RealtimeEvents.EVENTS.Students.XPNewStudentdNotification, newStudentNotificationHandler);
      });
    });

    function newStudentNotificationHandler (e) {
      var cid = e.detail.record.cid;
      var students = e.detail.record.students;
      $log.debug('New student notification : ' + JSON.stringify(students));
      // if this user was added to this class
      if (cid === parseInt((ctrl.currentClassId + ''), 10)) {
        students.forEach(function (newStudent) {
          var existingStudent = ctrl.students.find(function (student) {
            return student.id === newStudent.id;
          });
          if (!existingStudent) {
            ctrl.students.push(newStudent);
          }
          // If adding to the class then we need to remove it from the all students list
          ctrl.allStudents = ctrl.allStudents.filter(function (student) {
            return student.id !== newStudent.id;
          });
        });
        return;
      }

      students.forEach(function (newStudent) {
        var existingTeacherStudent = ctrl.allStudents.find(function (student) {
          return student.id === newStudent.id;
        });
        if (existingTeacherStudent) {
          return;
        }
        // Add this to the all students list since they joined a different class
        ctrl.allStudents.push(newStudent);
        // Now perform the actual sort on the list
        ctrl.allStudents.sort(sortStudents(ctrl.predicate, ctrl.sortIsReversed));
      });
    }
  }

  module.component('xpClassAllStudents', {
    template: require('./students.jade'),
    controller: controller,
  });

})();
