'use strict';
/*jshint browser: true */
var taoModule = angular.module('xp-element-tao-assessment',
  ['angularWidget', 'client.services', 'client.directives']);

taoModule.controller('clientTaoElementCtrl',
  ['$scope', 'widgetConfig', '$timeout', '$sce', 'PresenceService', 'UserPresence', '$log', 'ElementsRestService', 'ElementsErrorService',
   'ScoreService', '$q', 'TaoTestService',
    function ($scope, widgetConfig, $timeout, $sce, PresenceService, UserPresence, $log, ElementsRestService, ElementsErrorService,
        ScoreService, $q, TaoTestService) {
      var ERROR_MESSAGE_STUDENT = 'We\'re sorry - the results can\'t be shown right now. Check back later.',
          ERROR_MESSAGE_TEACHER = ERROR_MESSAGE_STUDENT +
            ' If this problem persists or needs immediate attention, contact ' +
            '<a href="mailto:help@exploros.com?subject=Help with Unavailable Assessment Results&body=' +
            'Please describe the problem you\'re having:\n">help@exploros.com</a>.';

      // The element is the block of data provided by the source xml
      $scope.options = widgetConfig.getOptions($scope);
      $scope.userId = 0;
      $scope.isStudent = true;
      $scope.isVisible = false;
      $scope.isTesting = false;
      $scope.isTeacherPreview = false;
      $scope.isPastExperience = true;
      $scope.isLoading = true;
      $scope.isDeliveryLoaded = false;
      $scope.isLoadResults = false;
      $scope.isTestInitialized = false;
      $scope.isTestComplete = false;
      $scope.checkedStatus = false;

      $scope.iFrameId = "ltiLaunchForm_" + Math.round(Math.random() * 9999999);
      $scope.assessment = {};
      $scope.delivery = { url: "TaoDeliveryURL" };
      $scope.students = [];
      $scope.deliveryUrl = "none"; // Must assign a non-blank value to prevent forms from disabling themselves.
      $scope.countCompleted = 0;
      $scope.countCorrectItems = 0;

      $scope.testItems = [];
      $scope.selectedItem = undefined;

      $scope.currentPage = 0;
      $scope.pageSize = 10;
      $scope.lastPage = 0;
      $scope.isReversed = false;
      $scope.predicate = 'lastName';
      $scope.errors = [];
      $scope.itemCodes = [];

      function compareByFirstName(student1, student2) {
        if (student1.firstName.toLowerCase() < student2.firstName.toLowerCase()) {
          return -1;
        }
        if (student1.firstName.toLowerCase() > student2.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      }

      function compareByName(student1, student2) {
        if (student1.lastName.toLowerCase() < student2.lastName.toLowerCase()) {
          return -1;
        }
        if (student1.lastName.toLowerCase() > student2.lastName.toLowerCase()) {
          return 1;
        }
        return compareByFirstName(student1, student2);
      }

      /*****************************************/
      /****    Controller Init Methods      ****/
      /*****************************************/
      var calledForParseElementOnce = false;
      var parseElement = function () {
        if(calledForParseElementOnce){
          $log.warn("called for parseElement repeatedly");
          return;
        }
        calledForParseElementOnce = true;
        if ($scope.getRespondent()) {
          $scope.students = [$scope.options.context.clazz.userWithId($scope.getRespondent())];
        }
        else {
          $scope.students = $scope.options.context.getSelectedStudents();

          // For past results, we want to sort students by name (lastname, firstname)
          $scope.students.sort(compareByName);
        }

        if (!$scope.isLoading) {
          return;
        }

        if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes) {
          console.warn('Element config not found. $scope.options:', $scope.options);
          return;
        }

        // Get the presence for each student belonging to this teacher
        $scope.getPresence(function (presentStudents) {
          $scope.students.forEach(function (student) {
            // Default presence to missing then search presence array
            student.isPresent = false;
            for (var pIndex = 0; pIndex < presentStudents.length; ++pIndex) {
              if (presentStudents[pIndex].user_id === student.uid)
                student.isPresent = true;
            }
          });
        });

        $scope.isPastExperience = $scope.options.context.getViewingInactiveExperience();
        $scope.isStudent = $scope.options.context.userIsStudent($scope.userId);
        $scope.userId = $scope.options.context.userId;

        // *****************************************************
        // Load Base Data
        // *****************************************************
        var loadBaseData = function () {
          if ($scope.isStudent) {
            initStudentData();
          } else {
            initTeacherData();
          }

          updateTestComplete();
        };

        // *****************************************************
        // Load Assessment Element Config
        // *****************************************************
        $scope.options.element.config.attributes.forEach(function (attribute) {
          if (attribute.name == "title" || attribute.name == "instructions") {
            $scope.assessment[attribute.name] = $sce.trustAsHtml(attribute.value);
          }
          else {
            $scope.assessment[attribute.name] = attribute.value;
          }
        });

        loadBaseData();
      };

      // ********************************************************
      //  STUDENT VIEW OF THEIR RESULTS
      // ********************************************************
      var initStudentData = function () {
        if ($scope.isPastExperience) {
          $scope.isLoadResults = true;
          TaoTestService.getTestResults({eid: $scope.options.context.experienceId})
          .$promise.then(function(taoTest){
            // Need to create an array of test items for UI
            for (var index = 0; index < taoTest.items_cnt; ++index) {
              // Insert a value into the test items array
              $scope.testItems.push({identifier: index, label: index + 1, uri: index});
            }

            // Save the image references for past experiences
            if (taoTest.item_codes) {
              $scope.itemCodes = taoTest.item_codes;
            }

            $scope.lastPage = Math.ceil($scope.testItems.length / $scope.pageSize) - 1;

            $scope.students.forEach(function (student) {
              if (!student.results) {
                student.results = {};
              }

              if (student.uid === $scope.userId) {
                student.results[$scope.delivery.url] = { items: [] };
                if (taoTest.results[student.uid] && taoTest.results[student.uid].items) {
                  for (var itemId in taoTest.results[student.uid].items) {
                    if (taoTest.results[student.uid].items.hasOwnProperty(itemId)) {
                      student.results[$scope.delivery.url].items.push(taoTest.results[student.uid].items[itemId]);
                    }
                  }
                }

                if (taoTest.results[student.uid] && taoTest.results[student.uid].correctItemCount) {
                  $scope.countCorrectItems = taoTest.results[student.uid].correctItemCount;
                }
              } else {
                student.results[$scope.delivery.url] = {};
              }
            });

            $scope.isLoadResults = false;
          })
          .catch(function(error) {
            $log.error(error);
            return error;
          });
        }

        // Clean up after ourselves.
        $scope.$on('$destroy', function () {
          $scope.students.forEach(function (student) {
            if (student.uid === $scope.userId) {
              student.results[$scope.delivery.url] = undefined;
            }
          });
        });

        initComplete();
      };

      // ********************************************************
      //  TEACHER VIEW OF STUDENT RESULTS
      // ********************************************************
      var initTeacherData = function () {
        if ($scope.isPastExperience) {
          $scope.isLoadResults = true;
          TaoTestService.getTestResults({eid: $scope.options.context.experienceId})
          .$promise.then(function(taoTest){
            // Need to create an array of test items for UI
            for (var index = 0; index < taoTest.items_cnt; ++index) {
              // Insert a value into the test items array
              $scope.testItems.push({identifier: index, label: index + 1, uri: index});
            }

            // Save the image references for past experiences
            if (taoTest.item_codes) {
              $log.debug("Past experience image path: " + $scope.assessment.imagePath + " with codes: " + JSON.stringify(taoTest.item_codes));
              $scope.itemCodes = taoTest.item_codes;
            }

            $scope.lastPage = Math.ceil($scope.testItems.length / $scope.pageSize) - 1;

            $scope.students.forEach(function (student) {
              if (!student.results) {
                student.results = {};
              }

              if (taoTest.results[student.uid] && student.uid !== $scope.userId) {
                // Need to copy values into the array as indexed values instead of named values
                student.results[$scope.delivery.url] = { items: [] };
                if (taoTest.results[student.uid].items) {
                  for (var itemId in taoTest.results[student.uid].items) {
                    if (taoTest.results[student.uid].items.hasOwnProperty(itemId)) {
                      student.results[$scope.delivery.url].items.push(taoTest.results[student.uid].items[itemId]);
                    }
                  }

                  // Make sure the student completed the entire test before including them in the count
                  if ($scope.testItems.length === taoTest.results[student.uid].itemCount) {
                    $scope.countCompleted = $scope.countCompleted + 1;
                  }

                  populateItemSortOrder(taoTest.results, student);
                }
              }
            });

            $scope.isLoadResults = false;
          })
          .catch(function(error) {
            $log.error(error);
            return error;
          });

          // Clean up after ourselves.
          $scope.$on('$destroy', function () {
            $scope.students.forEach(function (student) {
              if (student.uid === $scope.userId) {
                student.results[$scope.delivery.url] = undefined;
              }
            });
          });

        }

        initComplete();
      };

      // Notify the widget that we are done loading the data
      var initComplete = function () {
        $timeout(function () {
          widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
          $scope.isLoading = false;
        });
      };

      $scope.$watch('options', parseElement, true);

      $scope.$on("$locationChangeStart", function (event) {
        if ($scope.isTesting && !window.confirm('Are you sure you want to leave this page? ' +
            'You might lose your work. To save it, choose Cancel and click the X.')) {
          event.preventDefault();
        }
      });

      $scope.getPresence = function (callback) {
        // If this is a teacher then get the presence information
        if (!$scope.isStudent) {
          // Need to get the present state for students in this experience
          UserPresence.present({
            id: $scope.userId,
            experience_id: $scope.options.context.experienceId
          }, function (presentStudents) {
            callback(presentStudents);
          });
        }
        else
          callback([]);
      };

      // Handle presence notifications
      PresenceService.on(PresenceService.EVENTS.XPPresenceReceivedNotification, presenceChangedNotificationHandler);
      $scope.$on('$destroy', function () {
        PresenceService.removeListener(PresenceService.EVENTS.XPPresenceReceivedNotification,
          presenceChangedNotificationHandler);
      });

      function presenceChangedNotificationHandler (e) {
        var state = e.detail.record;
        var from = e.detail.from;

        $log.debug('Received presence update: ' + JSON.stringify(e.detail));

        $scope.students.forEach(function (student) {
          if ((student.uid + '') === (from + '')) {
            $log.debug('Updating present state of student #' + student.user_id + ' to ' + state.status);
            $scope.$apply(student.isPresent = (state.status === 'online'));
          }
        });
      }

      /*****************************************/
      /****         Event Handlers          ****/
      /*****************************************/

      $scope.showTest = function () {
        // TAO tests should only ever be past experiences so they should never be shown
      };

      $scope.hideTest = function () {
        updateTestComplete().then(function(results){
          // Indicate that the test is no longer shown
          var userData = JSON.stringify({"showTest": false});
          ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.userId, 0, userData,
            function () {
              return;
            },
            function (error) {
              ElementsErrorService.error(error);
            });
        });
        $('#activity-content-container').removeClass('force-overlay');
        $('.experience-navbar').removeClass('hide');
        $('.content-with-nav-bar').removeClass('expand-beyond-screen');
        $scope.isVisible = false;
        $scope.isTeacherPreview = false;
        $scope.isTesting = false;
      };

      $scope.toggleVisible = function () {
        // A student cannot retake a test
        if (($scope.checkedStatus && !$scope.isTestComplete) || !$scope.isStudent) {
          $scope.isVisible = !$scope.isVisible;

          if (!$scope.isPastExperience) {
            if ($scope.isStudent || (!$scope.isStudent && $scope.isTeacherPreview)) {
              if ($scope.isVisible) {
                  $('.experience-navbar').addClass('hide');
                  $('.content-with-nav-bar').addClass('expand-beyond-screen');
              } else {
                  $('.experience-navbar').removeClass('hide');
                  $('.content-with-nav-bar').removeClass('expand-beyond-screen');
              }
            }

            if ($scope.isTestInitialized) {
              $scope.showTest();
            }
          }
        }
      };

      $scope.order = function (predicate) {
        if (typeof(predicate) === 'object' && predicate.label) {
          var objPredicate = 'results["' + $scope.delivery.url + '"].items["' + predicate.uri + '"].order';
          if ($scope.selectedItem != predicate) {
            $scope.selectedItem = predicate;
          } else {
            if (!$scope.isStudent) {
              $scope.isReversed = ($scope.predicate === objPredicate) ? !$scope.isReversed : false;
              $scope.predicate = objPredicate;
            }
          }
        } else {
          $scope.isReversed = ($scope.predicate === predicate) ? !$scope.isReversed : false;
          $scope.predicate = predicate;
        }
      };

      /**
       * Determine each student result's sort order and populates the items in the result set.
       *
       * @param results
       * @param student
       */
      var populateItemSortOrder = function (results, student) {
        for (var itemId in results[student.uid].items) {
          if (results[student.uid].items.hasOwnProperty(itemId)) {
            var thisItem = results[student.uid].items[itemId];
            thisItem.order = ($scope.isItemResultCorrect(thisItem) ?
              1 : $scope.isItemResultPartial(thisItem) ? 2 : $scope.isItemResultIncorrect(thisItem) ? 3 : null);
          }
        }
      };

      /**
       * When test frame os closed, check to see if student actually compelted the test
       */
      var updateTestComplete = function () {
          $scope.isTestComplete = true;
          $scope.checkedStatus = true;
          return $q.when(true);
      };

      /*****************************************/
      /****           UI Methods            ****/
      /*****************************************/
      $scope.isItemResultCorrect = function (itemResult) {
        return !itemResult ? false : itemResult.isCorrect;
      };
      $scope.isItemResultIncorrect = function (itemResult) {
        return !itemResult ? false : (!itemResult.isCorrect && itemResult.score === 0 && itemResult.isScored);
      };
      $scope.isItemResultPartial = function (itemResult) {
        return !itemResult ? false : (!itemResult.isCorrect && itemResult.score > 0);
      };
      $scope.isItemResultNone = function (itemResult) {
        return !itemResult ? true : !itemResult.isComplete || !itemResult.isScored;
      };
      $scope.prevPage = function () {
        $scope.currentPage = Math.max(0, $scope.currentPage - 1);
      };
      $scope.nextPage = function () {
        $scope.currentPage = Math.min($scope.lastPage, $scope.currentPage + 1);
      };

      $scope.formatResponse = function (str) {
        return str.replace(/[\[\]']+/g, '');
      };

      var logError = function (details) {
        var msg = ERROR_MESSAGE_STUDENT;
        if (!$scope.isStudent) {
          msg = ERROR_MESSAGE_TEACHER;
        }

        $scope.errors.push(msg);
        console.error(msg, details);
      };


    }
  ]
);

taoModule.filter('startFrom', function () {
  return function (input, start) {
    start = +start;
    return input.slice(start);
  };
});
