'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('UserManagedSubscriptions', ['User', '$resource', '$log', '$rootScope', '$routeParams', '$q', '$location',
    'userPermissions', 'PermissionConsts', 'api_server',
    function (User, $resource, $log, $rootScope, $routeParams, $q, $location, userPermissions, PermissionConsts, api_server) {
      var subscriptionsResource = $resource(api_server + '/subscriptions/admin/subscriptions', {}, {get: {isArray: true}});
      var subscriptionConsoleResource = $resource(api_server + '/subscriptions/admin/console', {}, {get: {isArray: true}});
      var subscriptionLimitsResource = $resource(api_server + '/subscriptions/admin/exceeded_licenses', {}, {post: {method: 'POST', isArray: true, params: {subscriptionIds: '@subscriptionIds'}}});
      var cachedSubscriptions = [];

      function reloadSubscriptions() {
        cachedSubscriptions = [];
        if (User && User.getId() && !User.isStudent() &&
            userPermissions.hasPermission(PermissionConsts.subscription_admin)) {
          return subscriptionsResource.get().$promise
            .then(function (response) {
              if (response) {
                return response.sort(function (a, b) {
                  return a.name.localeCompare(b.name);
                });
              }
              return [];
            })
            .catch(function (error) {
              $log.error(error);
            });
        } else {
          return $q.when([]);
        }
      }

      function getSubscriptions() {
        if (cachedSubscriptions && cachedSubscriptions.length) {
          return $q.when(cachedSubscriptions);
        }
        return updateCachedSubscriptions();
      }

      var updateCachedSubscriptions = function () {
        return reloadSubscriptions()
          .then(function (subscriptions) {
            cachedSubscriptions = subscriptions || [];
            return subscriptions;
          });
      };

      function getSubscription(subscriptionId) {
        return getSubscriptions().then(function (subscriptions) {
          var filteredSubscriptions = subscriptions.filter(function (subscription) {
            return subscription.subscription_id === subscriptionId;
          });
          return filteredSubscriptions[0];
        });
      }

      function getDefaultSubscription() {
        return getSubscriptions().then(function (subscriptions) {
          return subscriptions[0];
        });
      }

      function getConsoleSubscriptions() {
        return subscriptionConsoleResource.get().$promise
        .then(function (response) {
          if (response) {
            return response.sort(function (a, b) {
               return (a.sort_order||0) - (b.sort_order||0);
            });
          }
          return [];
        })
        .catch(function (error) {
          $log.error(error);
        });
      }

      function getExceededLicenseLimits(subscriptionIds) {
        return subscriptionLimitsResource.post({subscriptionIds: subscriptionIds}).$promise;
      }

      return {
        get: getSubscription,
        getSubscriptions: getSubscriptions,
        getDefaultSubscription: getDefaultSubscription,
        reloadSubscriptions: reloadSubscriptions,
        resetSubscriptions: function resetSubscriptions() {
          cachedSubscriptions = [];
        },
        getConsoleSubscriptions: getConsoleSubscriptions,
        getExceededLicenseLimits: getExceededLicenseLimits
      };
    }]);

})();

