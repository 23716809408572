'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('reportProgress', ['ExperienceNavigator',
   function(ExperienceNavigator) {
    function link(scope, element, attrs) {
    }

    return {
      replace: true,
      restrict: 'E',
      templateUrl: "fullPageReportProgress.tpl",
      link: link
    };

  }]);

})();