'use strict';
angular.module('xp-element-web', ['angularWidget', 'client.services'])
	.controller('clientWebElementCtrl', ['$scope', 'widgetConfig', '$http', '$sce', '$timeout',
	                                     function ($scope, widgetConfig, $http, $sce, $timeout) {

	// The element is the block of data provided by the source xml
		$scope.options = widgetConfig.getOptions($scope);
		$scope.url = "";

	function parseElement()
	{
		var element = $scope.options.element;

		element.config.attributes.forEach(function(attribute)
		{
			var name = attribute.name;
			var value = attribute.value;
			switch (name)
			{
				case "url" :
				{
					// if the URL is blank then create a default so it doesn't go to login screen
					if (value === null || value.length === 0)
						value = "http://www.exploros.com";

					// If the URL does not begin with HTTP then we need to prepend it
					if (!/^(f|ht)tps?:\/\//i.test(value)) {
						value = "http://" + value;
					}

					// finally call through a proxy
					// value = "/proxy?url=" + value; // encodeURIComponent(value);

					$scope.url = value;
				}
				break;
			}
		});
	}

	$scope.$watch('options', function() {
		var options = $scope.options;
		if (!options.element)
			return;

		parseElement();

		$timeout(function() {
			// Notify the widget that were are done loading the data
			widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
		  });
	}, true);

}]);
