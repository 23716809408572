'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('ClassMode', ['$rootScope', '$q', '$location', '$log', '$routeParams', '$cookies', 'User', 'UserDefaultInfo',
    'CourseFactory', 'userClassPermissions', 'classReports', 'ReportNavigation', 'CurriculumMode', 'curriculumReports',
    function ($rootScope, $q, $location, $log, $routeParams, $cookies, User, UserDefaultInfo,
              CourseFactory, userClassPermissions, classReports, ReportNavigation, CurriculumMode, curriculumReports) {

      // Track the current class
      var inClassMode = false;

      // Get the current class from the URL
      function currentClassId() {
        return $routeParams.classId ? $routeParams.classId : undefined;
      }

      // Get the current report from the URL
      function currentReportId() {
        return $routeParams.reportId ? $routeParams.reportId : undefined;
      }

      // test to see if we are in class mode
      function isActiveMode() {
        return inClassMode;
      }

      // return the title when we are in class mode
      function getTitle() {
        var d = $q.defer();
        CourseFactory.get(currentClassId()).then(function (course) {
          d.resolve(course.class_name);
        }, function (error) {
          d.reject();
        });
        return d.promise;
      }

      function userIsTeacher() {
        return User.getSystemRole() == User.ROLE.TEACHER;
      }

      function newCourseNotificationHandler(e) {
        var course = e.detail;
        if (!course.record) return;

        $log.debug("New course added: " + angular.toJson(course.record));
        if (userIsTeacher())
          $location.path('/class/' + course.record.cid);
      }

      function deleteCourseNotificationHandler(e) {
        var course = e.detail;
        $log.debug("Course deleted: " + angular.toJson(course));
        if (inClassMode && $location.path() == '/class/' + course.record.cid) {
          CurriculumMode.navigateToExperiences();
        }
      }

      $rootScope.$on('current_course_updated', function (event, args) {
        storeCurrentCourse();
      });

      var remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
        // If the current route is a class route then set correctly
        if (current && current.$$route && current.pathParams && current.pathParams.classId) {
          inClassMode = true;
          userClassPermissions.setUserClassPermissions(User.getId(), current.pathParams.classId);
        }
        else {
          inClassMode = false;
          userClassPermissions.clearPermissions();
        }
      });

      function goToClass(cid) {
        if (!parseInt(cid, 10)) {
          $log.debug('Invalid class cid. Aborting navigation to class.');
          return;
        }
        // Navigate to the default class view for this class
        $location.path('/class/' + cid).search({});
      }

      function storeCurrentCourse() {
        // if there is a valid course ID then save it to a cookie
        if (currentClassId()) {
          var data = {
            cid: currentClassId(),
            id: currentClassId(),
            user_id: User.getId()
          };
          $cookies.put('currentCourse', data);
        }
        else {
          $cookies.remove('currentCourse');
        }
      }

      function getStoredCurrentCourse() {
        // retrieve the current course cookie.  If for this user then return the id
        var currentCourse = $cookies.get('currentCourse');
        if (currentCourse && currentCourse.cid && currentCourse.user_id == User.getId()) {
          return currentCourse.cid;
        }
        else {
          return undefined;
        }
      }

      function navigateToSavedClass() {
        var classId = getStoredCurrentCourse();
        if (classId) {
          goToClass(classId);
          return $q.when(true);
        }
        else {
          return $q.when(false);
        }
      }

      function navigateToExperiences() {
        if (currentClassId()) {
          goToClass(currentClassId());
        }
      }

      function navigateToReports() {
        $location.path('/class/' + currentClassId() + '/reports');
      }

      function navigateToPack() {
        $location.path('/class/' + currentClassId() + '/class-pack');
      }

      function navigateToLicensePack() {
        $location.path('/class/' + currentClassId() + '/license-pack');
      }

      function getReports() {
        return CourseFactory.get(currentClassId()).then(function (course) {
          if (course && course.licenses && course.licenses.length) {
            return classReports.getReports(currentClassId()).then(function(classReports) {
              return curriculumReports.getReports().then(function(curriculumReports) {
               return classReports.concat(curriculumReports);
              });
            });
          } else {
            return [];
          }
        });
      }

      function getReportData(filter) {
        return classReports.getReportData(currentClassId(), currentReportId(), filter);
      }

      function showReport(reportId) {
        if (reportId.startsWith("curriculum")) {
          ReportNavigation.navigateToReport(reportId, '/experience/report/' + reportId,
            {}, $location.path());
        } else {
          ReportNavigation.navigateToReport(reportId, '/class/' + currentClassId() + '/report/' + reportId,
            {}, $location.path());
        }
      }

      function resetPermissions() {
        userClassPermissions.resetPermissions(User.getId(), currentClassId());
      }

      return {
        isActiveMode: isActiveMode,
        currentClassId: currentClassId,
        getTitle: getTitle,
        goToClass: goToClass,
        navigateToSavedClass: navigateToSavedClass,
        navigateToExperiences: navigateToExperiences,
        navigateToReports: navigateToReports,
        navigateToPack: navigateToPack,
        navigateToLicensePack: navigateToLicensePack,
        getReports: getReports,
        getReportData: getReportData,
        showReport: showReport,
        newCourseNotificationHandler: newCourseNotificationHandler,
        deleteCourseNotificationHandler: deleteCourseNotificationHandler,
        resetPermissions: resetPermissions
      };

    }]);

})();
