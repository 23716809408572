'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$log', 'ActiveMode', 'userPermissions', 'PermissionConsts', 'ModalService', 'UserConfig', 'User'];

function controller ($log, ActiveMode, userPermissions, PermissionConsts, ModalService, UserConfig, User) {
  var ctrl = this;

  ctrl.COLLECTIONS_ID_ALL = '616C6C';
  ctrl.summaryReports = [];
  ctrl.courseReports = [];
  ctrl.availableCourses = [];
  ctrl.selectedCourses = [];
  ctrl.loading = true;

  // Create a default all courses selection
  var allCourses = {id: "All", name: "All Courses", ticked: true};
  ctrl.reportFilter = [allCourses];

  ctrl.availableCourses.push(allCourses);

  ctrl.navigateToReport = function(reportId) {
    ActiveMode.showReport(reportId);
  };

  if(ActiveMode.isCurriculumMode() && !userPermissions.hasPermission(PermissionConsts.ui_curriculum_hide_reports_placeholder)){
    ModalService.show({
      feature: 'curriculum_reports_placeholder',
      template: require('../../views/partials/modals/subscriptionFeatureModal.jade'),
      backdrop: 'static',
    });

    ActiveMode.navigateToExperiences();
  }

  // Get the correct set of reports based on the current mode
  ActiveMode.getReports().then(function(reports) {
    ctrl.reports = reports;

    ctrl.summaryReports = reports.filter(function(report) {
      if (report.collections && report.collections.length) {
        var isGlobalCollection = report.collections.find(function(collection) { return collection.collection_uuid === ctrl.COLLECTIONS_ID_ALL; });
        return isGlobalCollection;
      }
    });

    ctrl.courseReports = reports.filter(function(report) {
      if (report.collections && report.collections.length) {
        report.collections.forEach(function(collection){
          if (collection.collection_uuid !== ctrl.COLLECTIONS_ID_ALL) {
            ctrl.availableCourses.push(
                {
                  id: collection.collection_uuid,
                  name: collection.name,
                  order: collection.sort_order
                }
            );
          }
        });
        var isGlobalCollection = report.collections.find(function(collection) { return collection.collection_uuid === ctrl.COLLECTIONS_ID_ALL; });
        return !isGlobalCollection;
      }
    });
    ctrl.availableCourses = ctrl.availableCourses.filter(function(obj, pos, arr) {
        return arr.map(mapObj => mapObj['id']).indexOf(obj['id']) === pos;
    })
    .sort(function(a, b) { return a.order - b.order; });

    UserConfig.get({userId: User.getId()}).$promise.then(function(config){
      if (config && config.length) {
        let setting = config.find(function(rec) { return rec.config_key == 'defaultReportCourse'});
        if (setting) {
          let keys = JSON.parse(setting.config_value);
          ctrl.reportFilter = ctrl.availableCourses.filter(function(avail) {
            let keyVal = keys.find(function(key) { return key == avail.id; });
            return keyVal;
          });
          ctrl.availableCourses.forEach(function(course) {
            let selectedFilter = ctrl.reportFilter.find(function(selected) {
              return selected.id == course.id;
            })
            course.ticked = selectedFilter ? true : false;
          });
        }
      }
    });

    ctrl.loading = false;
  })
  .catch(function(error) {
    $log.error("error in getting reports:",error);
    ctrl.reports = [];
  });

  ctrl.onCourseClicked = function(item) {
    // if this is NOT the all classes then make sure the ALL Courses is NOT checked
    if (item.id !== "All") {
      ctrl.availableCourses[0].ticked = false;
    }
    else {
      // Uncheck all other courses since all courses is currently selected
      ctrl.availableCourses.forEach(function(course, index) {
        if (index !== 0 && course.ticked)
          course.ticked = false;
      });
    }
  };

  ctrl.filterByCourse = function(report) {
    if (!ctrl.reportFilter.length || ctrl.reportFilter[0].id === 'All') {
      return true;
    }

    var reportInCollection = false;
    report.collections.forEach(function(collection){
      var inFilter = ctrl.reportFilter.find(function(filter) { return collection.collection_uuid === filter.id; });
      if (inFilter) {
        reportInCollection = true;
      }
    });

    return reportInCollection;
  }

  ctrl.onUpdateReportList = function() {
    ctrl.reportFilter = ctrl.selectedCourses;
    let courseIds = ctrl.selectedCourses.map(function(course) {
      return course.id;
    }).filter(function(course) {
      return course != "All";
    });
    UserConfig.save({userId: User.getId(), config: { defaultReportCourse: JSON.stringify(courseIds)}} );
  };
}

module.component('xpReportPicker', {
  template: require('./reportPicker.jade'),
  controller: controller,
});

})();
