'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpReportFilterDropdown', ['ExperienceNavigator', '$location',
   function(ExperienceNavigator, $location) {

     function link(scope, element, attrs)
     {
       scope.onClick = function(newSelection) {
         // Make sure the user defined a callback function
         if (scope.onSelect) {
           scope.onSelect({selection: newSelection});
         }
       };
     }

     return {
       replace: true,
       restrict: 'E',
       transclude: true,
       template: require('./reportFilterDropdown.jade'),
       link: link,
       scope: {
         title: '@',
         selections: '<',
         selected: '<',
         onSelect: '&',
         style: '@'
       }
     };
   }]);
})();
