'use strict';
(function(){
angular.module('xp-element-graphic-organizer', ['angularWidget', 'client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'client.directives'])
.controller('clientGraphicOrganizerElementCtrl', [
'$scope', '$rootScope', '$log', '$timeout', 'widgetConfig', 'ElementsRestService', 'ElementsErrorService', 'SHARE_MODE',
'GATE_MODE', 'JSONStringUtility', 'ElementUtilities', 'ModalService', 'CameraService', '$modal', 'rfc4122',
'loadImageWithOrientation', 'RespondentType', 'BlobUtilities', '$q', '$http', '$sce', 'ActiveExperience', 'CorrectResponseId',
function ($scope, $rootScope, $log, $timeout, widgetConfig, ElementsRestService, ElementsErrorService, SHARE_MODE,
          GATE_MODE, JSONStringUtility, ElementUtilities, ModalService, CameraService, $modal, rfc4122,
          loadImageWithOrientation, RespondentType, BlobUtilities, $q, $http, $sce, ActiveExperience, CorrectResponseId) {

	// The element is the block of data provided by the source xml
	$scope.options = widgetConfig.getOptions($scope);
  $scope.elementInitialized = false;
	$scope.instructions = {};
	$scope.gateMode = GATE_MODE.XPGateModeGated;
	$scope.isTeacher = false;
	$scope.SHARE_MODE = SHARE_MODE;
	$scope.share = SHARE_MODE.GROUP;
	$scope.images = false;
	$scope.imageUrl = null;
	$scope.labels = [];
	$scope.inputs = [];
	$scope.transformY = 0;
	$scope.transformX = 0;
	$scope.portionResponded = 0;
	$scope.respondents = [];
	$scope.selectedRespondent = null;
	$scope.editing = false;
	$scope.groupUser = [];
	$scope.results = [];
	$scope.hasCamera = CameraService.hasUserMedia;
	$scope.attachment = null;
	$scope.isIpad = /iPad.*AppleWebKit/i.test(navigator.userAgent);
	$scope.respondentDisplayName = null;
	$scope.horizontalAlign = "text-left";
	$scope.showShareIndicator = false;
	$scope.myAnswer = null;

	var RECT_TYPE = Object.freeze({
		LABEL: '#F32836',
		INPUT: '#24A7FE'
	});

	$scope.labelColor = RECT_TYPE.LABEL;
	$scope.inputColor = RECT_TYPE.INPUT;


	var parseElement = function()
	{
		if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes)
			return;

		// if already initialized then return
    if ($scope.elementInitialized) {
      return;
    }

		$scope.isTeacher = $scope.options.context.userIsTeacher();
		$scope.showShareIndicator = $scope.isTeacher;

		$scope.options.element.config.attributes.forEach(function(attribute)
		{
			switch (attribute.name)
			{
			case "instructions" :
				$scope.instructions.question = $sce.trustAsHtml(attribute.value);
			break;
			case "gate_mode" :
				if (attribute.value == "gated")
					$scope.gateMode = GATE_MODE.XPGateModeGated;
				else
					$scope.gateMode = GATE_MODE.XPGateModeUngated;
				break;
			case "images" :
				$scope.images = attribute.value === "true" || attribute.value === true;
			break;
			case "template_url" :
				$scope.imageUrl = ElementUtilities.getElementURL($scope.options.element, $scope.options.context.experienceId, attribute.value);
			break;
			case "transformX" :
				$scope.transformX = parseInt(attribute.value, 10);
			break;
			case "transformY" :
				$scope.transformY = parseInt(attribute.value, 10);
			break;
			case "labels" :
				var labels = attribute.value;
				if (!(labels instanceof Array))
				{
					labels =
						[
							{
								name: "labels",
								value: labels.label
							}
						];
				}
				labels.forEach(function(label) {
					$scope.labels.push( { text: $sce.trustAsHtml(label.value.text), x: label.value.x, y: label.value.y, width: label.value.width, height: label.value.height } );
				});
			break;
			case "inputs" :
				var inputs = attribute.value;
				if (!(inputs instanceof Array))
				{
					inputs =
						[
							{
								name: "inputs",
								value: inputs.input
							}
						];
				}
				inputs.forEach(function(input) {
					$scope.inputs.push({
										teacher_text: $sce.trustAsHtml(input.value.teacher_text),
										student_text: $sce.trustAsHtml(input.value.student_text),
										x: input.value.x,
										y: input.value.y,
										width: input.value.width,
										height: input.value.height,
										editing: false,
										data: null
									});
				});
			break;
			case "horizontal_alignment" :
				if (attribute.value == "left")
					$scope.horizontalAlign = "text-left";
				else if (attribute.value == "center")
					$scope.horizontalAlign = "text-center";
				else if (attribute.value == "right")
					$scope.horizontalAlign = "text-right";
				break;
			case "share" :
				$scope.share = attribute.value;
			break;
			}
		});
		$timeout(function() {
			// Notify the widget that were are done loading the data
			widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
		});

		var service = $scope.options.elementRealtimeService;
		var EVENTS = service.EVENTS;

		service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
		$scope.$on('$destroy', function(){
			service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
		});

		function stateChangedNotificationHandler(e) {
      var message = e.detail;
			var state = message.record;
			if (state.element_id != $scope.options.element.id)
				return;

			$log.debug ("Received observation tool state update: " + JSON.stringify(message));

			$log.debug ("updating all answers");
			$scope.$apply(addUserResponse(state, $scope.respondents));

			// if this is the selected user, then update visible data
			var respondent = $scope.isUsingSmallGroups() && !$scope.options.context.getUserIsTeacher(state.user_id) ? state.small_gid : state.user_id;
			if (respondent == $scope.selectedRespondent)
				selectUserData($scope.selectedRespondent);
		}

		loadAnswers();

		$scope.elementInitialized = true;
	};

  $rootScope.$on('teacher-notes', function() {
    if ($scope.isTeacher) {
      if (ActiveExperience.currentExperience().hideTeacherNotes) {
        selectUserData($scope.options.context.userId);
      } else {
        selectUserData(CorrectResponseId.CORRECTANSWERS);
      }
    }
  });

	$scope.$watch('options', parseElement, true);

	function loadAnswers()
	{
		if (!$scope.options.element.id)
			return;

    var isInactive = $scope.options.context.getViewingInactiveExperience();

    ElementsRestService.getSharedState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.groupName, isInactive,
		function(result) {
			var newRespondents = [];
			$scope.myAnswer = null;

      // See if this user has a cached value
      var hasCachedValue = false;
      if ($scope.cached) {
        var cachedValue = $scope.cached({elementId: $scope.options.element.id});
        if (cachedValue) {
          $scope.results.push( { user_id: $scope.options.context.userId, data: cachedValue } );
          hasCachedValue = true;
          $scope.showShareIndicator = true;
        }
      }

      // if this is a teacher then make sure they are in the list
      if ($scope.isTeacher && !$scope.options.context.getViewingInactiveExperience()) {
        newRespondents.push(CorrectResponseId.CORRECTANSWERS);
      }

      // add each users answer into scope
			if (result instanceof Array && !hasCachedValue)
			{
				$scope.filterAnswers(result).forEach(function(answer){
					addUserResponse(answer, newRespondents);
				});
			}

			$scope.respondents = newRespondents;

			// Notify the widget that were are done loading the data
			widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});

			if ($scope.myAnswer !== null && !hasCachedValue)
				setCurrentData($scope.myAnswer.user_data);

			// check to see if they submitted any data.  If not, then put them into edit mode immediately
			$scope.editing = !$scope.isTeacher &&
							 ($scope.myAnswer === null || $scope.myAnswer.user_data === undefined || $scope.myAnswer.user_data.length === 0) &&
							 !$scope.options.context.getViewingInactiveExperience();

      if (!ActiveExperience.currentExperience().hideTeacherNotes) {
        selectUserData(getRespondentId());
      }

			if (hasCachedValue) {
			  $scope.editing = true;
			}
		},
		function(error) {
			ElementsErrorService.error(error);
		});
	}

	function processAnswer(answer) {
	  return $q(function(resolve, reject) {
	    var context = $scope.options.context;

	    // See if the user data is valid
	    if (answer.user_data)
	    {
	      // Need to parse the user data into a json object
	      answer.user_data = JSONStringUtility.parse(answer.user_data);
	    }

      if (answer.user_data &&
	        answer.user_data[0] &&
	        answer.user_data[0].attachment &&
	        !answer.user_data[0].attachment.url &&
	        answer.user_data[0].attachment.xid) {
        var imageUrl = '/elements/' + $scope.options.element.id + '/experience/' + context.experienceId +
          '/attachment/' + answer.user_data[0].attachment.xid;

        $http.get(imageUrl, {params: {json: true}})
        .then(function(response) {
          answer.user_data[0].attachment.url = response.data.url;
        })
	      .finally(function() {
	        resolve(answer);
	      });
	    } else {
	      resolve(answer);
	    }
	  });
	}

	function addUserResponse(answer, newRespondents)
	{
		var context = $scope.options.context;

		// If this is a filtered view
		if ($scope.options.studentId === undefined || $scope.options.studentId === answer.user_id)
		{
			// Get the respondent ID which is either the user or group if using small groups
			var respondentId = $scope.isUsingSmallGroups() && !$scope.options.context.getUserIsTeacher(answer.user_id) ? answer.small_gid : answer.user_id;

			// Need to determine who this answer belongs to
			var answerRespondentId = $scope.isUsingSmallGroups() ? answer.small_gid : answer.user_id;
			var currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;

			// if this is a teacher or if this user owns this data
			if ($scope.isTeacher || answerRespondentId == currentRespondentId || answer.user_id == context.clazz.teacher.uid || SHARE_MODE.isShareToGroup($scope.share))
			{
			  processAnswer(answer)
			  .then(function(answer){
	        // If a valid answer then add data
	        if (answer.user_data && answer.user_data.length > 0)
	        {
	          // Keep track of the user who changed the value in this group
	          if ($scope.isUsingSmallGroups())
	            $scope.groupUser[answer.small_gid] = answer.user_id;

	          // See if this user has results in the array yet
	          var found = false;
	          for (var index = 0; index < $scope.results.length && !found; ++index)
	          {
	            // Is this user in the array
	            if ($scope.results[index].user_id == respondentId)
	            {
	              // Set the new values
	              $scope.results[index].data = answer.user_data;
	              found = true;
	            }
	          }

	          // Add this data into the full array of results if user did not already have data
	          if (!found)
	            $scope.results.push( { user_id: respondentId, data: answer.user_data } );

	          // Add each respondent into its own structure
	          var foundUser = newRespondents.some(function(respondent) {
	            return (answer.small_gid && answer.small_gid !== 0 && answer.small_gid === respondent) || (answer.small_gid === 0 && respondent === answer.user_id);
	          });

	          if (!foundUser)
	          {
	            // if the current user is the teacher then make sure their own avatar is always at the beginning
	            if ($scope.isTeacher && respondentId === context.clazz.teacher.uid)
	              newRespondents.splice(1, 0, respondentId);
	            else
	              newRespondents.push(respondentId);

	            // Calculate the portion of students that have responded to this element
	            var teacherAdjustment = newRespondents.indexOf(context.clazz.teacher.uid) === -1 ? 0 : 1;
	            if ($scope.isUsingSmallGroups())
	              $scope.portionResponded = (newRespondents.length - teacherAdjustment) / context.clazz.smallGroups;
	            else if (context.clazz.students.length)
	              $scope.portionResponded = (newRespondents.length - teacherAdjustment) / context.clazz.students.length;
	            else
	              $scope.portionResponded = 0;
	          }
	        }
	        else
	        {
	          // Remove this user since their data has been removed
	          var respondentIndex = $scope.respondents.indexOf(respondentId);

	          // if found then delete it
	          if (respondentIndex > -1)
	          {
	            // Remove this user from the respondents list
              $scope.respondents.splice(respondentIndex, 1);

	            // Make sure to remove the results from the array
	            var foundResp = false;
	            for (var indexResp = 0; indexResp < $scope.results.length && !foundResp; ++indexResp)
	            {
	              // Is this user in the array
	              if ($scope.results[indexResp].user_id == respondentId)
	              {
	                // Set the new values
	                $scope.results.splice(indexResp, 1);
	                foundResp = true;
	              }
	            }
	          }
	        }

	        if ((answer.small_gid && answer.small_gid !== 0 && answer.small_gid === context.groupId) || (answer.small_gid === 0 && context.userId === answer.user_id))
	        {
	          // Assign the current answer to current user
	          $scope.myAnswer = answer;

	          // Set editing flag if answer was deleted
	          $scope.editing = !context.userIsTeacher() &&
	                          ($scope.myAnswer === null || $scope.myAnswer.user_data === undefined || $scope.myAnswer.user_data.length === 0);

	          // Form should be defaulted to this value
	          setCurrentData(answer.user_data);
	        }

	        // Should we show the share indicator
          $scope.showShareIndicator = context.userIsTeacher() ||
                                      respondentHasData($scope.selectedRespondent, newRespondents) ||
                                      $scope.gateMode == GATE_MODE.XPGateModeUngated;
        });
			}
		}
		return undefined;
	}

	function setCurrentData(results)
	{
		// Iterate the results and find/insert the data into the scope
		results.forEach(function(result) {
			// if this is an attachment then assign it
			if (result.attachment)
			{
				// Clear out all the input data
				$scope.inputs.forEach(function(input) {
					input.data = null;
				});

				// Assign the attachment
				$scope.attachment = result.attachment;
			}
			else
			{
				// Iterate the display scope finding this matching input
				$scope.inputs.forEach(function(input) {
					// If this matches then set the data
					if (result.x === input.x && result.y === input.y) {
						input.data = result.data;
					}
				});

				// there is no attachment so clear it out
				$scope.attachment = null;
			}
		});
	}

	function respondentHasData(respondentId, newRespondents)
	{
		var foundUser = newRespondents.some(function(respondent) {
			return respondent === respondentId;
		});

		return foundUser;
	}

	$scope.selectUser = function(respondentId)
	{
		// if this user is already current then just bail here
		if ($scope.selectedRespondent === respondentId)
			return;

		// If the user is currently in edit mode, then we need to prompt them
		if ($scope.editing && respondentId != CorrectResponseId.CORRECTANSWERS)
		{
			ModalService.show(
				{
					title: 'Cancel Edit?',
					message: 'If you switch users, You will lose all unsubmitted changes.  Do you want to switch users?',
					buttons:
						[
							{
								title: 'Yes',
								click: 'selectUserData();$hide();'
							},
							{
								title: 'No',
								click: '$hide()'
							}
						],
						selectUserData: function(){selectUserData(respondentId);}
				});
		}
		else
			selectUserData(respondentId);
	};

	function setSelectedRespondent(respondentId)
	{
		$scope.selectedRespondent = respondentId;
		$scope.respondentDisplayName = $scope.isUsingSmallGroups() && !$scope.options.context.getUserIsTeacher($scope.selectedRespondent) ? "Group " + $scope.selectedRespondent : $scope.options.context.getUserDisplayName($scope.selectedRespondent);
	}

	function selectUserData(respondentId)
	{
		// Show data for the selected user
		setSelectedRespondent(respondentId);

		// Make sure to clear all inputs as the setting code simply sets the values the user input
		$scope.inputs.forEach(function(input) {
			input.data = null;
		});

		// Clear out the attachment
		$scope.attachment = null;

		// Select the users data now
		var bFoundResults = false;
		$scope.results.forEach(function(result) {
			// if this belongs to the current user then make it current
			if (result.user_id == respondentId)
			{
				// Set the current data to the UI
				setCurrentData(result.data);
				bFoundResults = true;
			}
		});

		// If this user isnt a teacher and has no data, then put them in edit mode
		$scope.editing = !$scope.isTeacher && !bFoundResults;
	}

  $scope.hasResponses = function() {
    return respondentHasData($scope.selectedRespondent, $scope.respondents);
  };

  $scope.showTeacherNotes = function() {
    return $scope.isTeacher &&
            ActiveExperience.currentExperience() &&
            !ActiveExperience.currentExperience().hideTeacherNotes;
  };

	$scope.onEditInput = function($event, input)
	{
		// Show the input control for this field
		input.editing = true;
	};

	$scope.onEndEditInput = function(input)
	{
		// No longer editing
		input.editing = false;
	};

	$scope.onChange = function() {
    if ($scope.changed) {
      var results = [];
      $scope.inputs.forEach(function(input) {
        if (input.data)
          results.push( {x: input.x, y: input.y, data: input.data } );

      });
      $scope.changed({elementId: $scope.options.element.id, selection: results});
    }
	};

	$scope.getEditMenuItemsForUser = function(respondentId)
	{
    let editUserId = $scope.options.context.getSelectedRespondentId($scope.isUsingSmallGroups(), respondentId);
	  var menuOptions = [];
		if ($scope.editing)
		{
			menuOptions.push({
					text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
					click: 'requestCancelEdit(' + editUserId +')'
				});
		} else {
      menuOptions.push({
        text: '<div class="xp-element-menu-edit">Edit</div>',
        click: 'requestEdit(' + editUserId +')'
      });
		}

		// A teacher can delete responses
		if ($scope.isTeacher)
		{
      if (respondentId != CorrectResponseId.CORRECTANSWERS && $scope.myAnswer !== null && $scope.myAnswer.user_data && $scope.myAnswer.user_data.length) {
  			menuOptions.push(
  			{
  				text: '<div class="xp-element-menu-delete">Delete</div>',
  				click: 'requestDelete(' + editUserId +')'
  			});
      }
      if ($scope.options.context.isReview) {
        menuOptions.push(
        {
          text: '<div class="xp-element-menu-edit">Approve</div>',
          click: 'approvePreviewRepsonses()'
        });
      }
		}

		return menuOptions;
	};

	$scope.requestEdit = function(respondentId) {
	  if ($scope.isTeacher) {
	    selectUserData(respondentId);
	  }

		$scope.editing = true;
	};

  $scope.approvePreviewRepsonses = function() {
    ElementsRestService.approveAnalyticsUserState($scope.options.context.experienceId, $scope.options.element.id)
    .then(function(res) {
      if (res && res.status && res.status == "Approved") {
        ModalService.show({
          message: "Analytic data for this element is now approved.",
          backdrop: 'static',
          buttons: [
            {
              title: 'Ok',
              click: '$hide();'
            }
          ]
        });
      }
    });
  };

	$scope.requestCancelEdit = function(respondentId)
	{
		ModalService.show(
			{
				title: 'Cancel Edit?',
				message: 'If you cancel your edits, You will lose all unsubmitted changes.',
				buttons:
					[
						{
							title: 'Yes',
							click: 'cancelEdit();$hide();'
						},
						{
							title: 'No',
							click: '$hide()'
						}
					],
				cancelEdit: function(){cancelEdit(respondentId);}
			}
		);
	};

	function cancelEdit(respondentId)
	{
		$scope.editing = false;

		// Reset the current values to what the user had before editing
		$scope.results.forEach(function(result) {
			if (result.user_id == respondentId)
				setCurrentData(result.data);
		});

    if ($scope.changed) {
      $scope.changed({elementId: $scope.options.element.id, selection: null});
    }
	}

	$scope.requestDelete = function(respondentId)
	{
		ModalService.show(
			{
				title: 'Delete?',
				message: 'Are you sure you want to delete your responses?',
				buttons:
					[
						{
							title: 'Delete',
							click: 'deleteData(); $hide();'
						},
						{
							title: 'Cancel',
							click: '$hide()'
						}
					],
				deleteData: function(){deleteData(respondentId);}
			}
		);
	};

	function deleteData(respondentId)
	{
    if ($scope.isTeacher) {
      selectUserData($scope.options.context.userId);
    }

    // Clear out the data
		for (var index = 0; index < $scope.inputs.length; ++index)
		{
			// set the data for each to null
			$scope.inputs[index].data = null;
		}

		// Clear the image
		$scope.attachment = null;

		$scope.doSubmit();

		// Since this is a teacher return to default
		setSelectedRespondent($scope.options.context.userId);
	}

	$scope.canEditForRespondent = function(respondentId) {
		if (!$scope.options.context) {
			return false;
		}

    if ($scope.options.context.isPreview && !$scope.options.context.isReview) {
      return false;
    }

		if ($scope.options.context.getViewingInactiveExperience())
			return false;

		return $scope.options.context.userIsTeacher() ||
				((($scope.options.context.userId == respondentId && !$scope.isUsingSmallGroups()) ||
				($scope.options.context.groupId == respondentId && $scope.isUsingSmallGroups())) &&
				$scope.hasResponses());
	};

	$scope.canSubmit = function()
	{
		// return immediately if not editing
		if (!$scope.editing)
			return false;

    if ($scope.options.context.isPreview && !$scope.options.context.isReview) {
      return false;
    }

		// if there is an image attached then this can be saved
		if ($scope.attachment)
			return true;

		// Loop through all the inputs and make sure there is data for at least one of them
		var hasData = false;
		for (var index = 0; index < $scope.inputs.length && !hasData; ++index)
		{
			if ($scope.inputs[index].data && $scope.inputs[index].data.length > 0)
				hasData = true;
		}

		// valid input
		return hasData;
	};

	function savePreviewData(userId, groupId, results, attachments) {
	  var oldResults = $scope.results.find(function(res) {
	    return (res.user_id && res.user_id == userId);
	  });

    // Save the new user state
    ElementsRestService.saveAnalyticsUserState($scope.options.context.experienceId, $scope.options.element.id, userId, groupId, oldResults.data, results,
    function() {
      $scope.editing = false;
      if ($scope.changed) {
        $scope.changed({elementId: $scope.options.element.id, selection: null});
      }
      let answer = {
          small_gid: groupId,
          user_id: userId,
          user_data: results
      };
      addUserResponse(answer, $scope.respondents);
    },
    function(error) {
      ElementsErrorService.error(error);
    }, attachments);
	}

	$scope.doSubmit = function() {
    if ($scope.selectedRespondent == CorrectResponseId.CORRECTANSWERS) {
      $scope.selectedRespondent = $scope.options.context.userId;
    }

		// If this is either the teacher or we are using small groups then the group is the selected respondent
    let groupId = $scope.options.context.getPostingGroupId($scope.isUsingSmallGroups());

		// The user ID is the respondent if not using small groups or the current respondent is the teacher
		var userId = $scope.options.context.userId;
		if ($scope.respondentIsUser($scope.selectedRespondent)) {
      userId = $scope.selectedRespondent;
		} else if ($scope.isTeacher && $scope.groupUser[$scope.selectedRespondent]) {
      userId = $scope.groupUser[$scope.selectedRespondent];
		}

		// Turn the inputs into just the data we need to save
		var results = [];
    var attachments = {};

		// if this just contains an attachment then save it
		if ($scope.attachment) {
		  attachments[$scope.attachment.xid] = $scope.attachment.file;
		  delete($scope.attachment.url);
		  delete($scope.attachment.file);

			// Add the attachment into the result
			results.push( {attachment: $scope.attachment } );
		} else {
			// Loop through all the inputs
			for (var index = 0; index < $scope.inputs.length; ++index)
			{
				// Insert a subset of the values
				if ($scope.inputs[index].data)
					results.push( {x: $scope.inputs[index].x, y: $scope.inputs[index].y, data: $scope.inputs[index].data } );
			}
		}

		// if this is a review then need to update existing records with new
		if ($scope.options.context.isReview) {
		  savePreviewData(userId, groupId, results, attachments);
		} else {
      // Save the new user state
      ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, userId, groupId, results,
      function() {
        $scope.editing = false;
        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: null});
        }
      },
      function(error) {
        ElementsErrorService.error(error);
      }, attachments);
		}
	};

	$scope.getUserGroup = function(userId)
	{
		return $scope.options.context && $scope.options.context.getUserGroup(userId);
	};

	$scope.isUsingSmallGroups = function()
	{
		return SHARE_MODE.isUsingSmallGroups($scope.share);
	};

	$scope.respondentIsUser = function(respondentId)
	{
		return !$scope.isUsingSmallGroups() ||
		        $scope.options.context.getUserIsTeacher(respondentId) ||
		        respondentId == CorrectResponseId.CORRECTANSWERS;
	};

	$scope.respondentIsTeacher = function(respondentId)
	{
		return $scope.options.context.getUserIsTeacher(respondentId);
	};

	$scope.respondentIsGroup = function(respondentId)
	{
		return !$scope.respondentIsUser(respondentId);
	};

	function getRespondentId()
	{
		if ($scope.getRespondent())
			return $scope.getRespondent();

		if (!$scope.respondents.includes($scope.options.context.userId) && $scope.options.context.userIsTeacher()) {
		  return CorrectResponseId.CORRECTANSWERS
		}

		if (!$scope.isUsingSmallGroups() || ($scope.isUsingSmallGroups() && $scope.options.context.userIsTeacher()))
			return $scope.options.context.userId;

		return $scope.options.context.groupId;
	}

	var cameraModal;

	$scope.onPhoto = function()
	{
		cameraModal = $modal(
		{
			backdrop: 'static',
			show: true,
			contentTemplate: 'clientGrahicOrganizerPhotoModal.html',
			scope: $scope,
			prefixEvent: 'cameraModal'
		});
	};

	function closeModal()
	{
		if (cameraModal)
		{
			cameraModal.hide();
			cameraModal = undefined;
		}
	}

	$scope.onCancelPhoto = function()
	{
		closeModal();
	};

	$scope.onDeletePhoto = function()
	{
		$scope.attachment = null;
	};

	$scope.onSavePhoto = function(data)
	{
		$scope.onSavePhotoData(data);
		closeModal();
	};

	$scope.onSavePhotoData = function(dataUrl)
	{
		var myblob = BlobUtilities.dataURLToBlob(dataUrl, 'image/png');

		var xid = rfc4122.v4(); // generate uuid
		var attachment =
		{
			xid: xid,
			type: 'image',
			file: myblob,
			url: dataUrl
		};

		$scope.attachment = attachment;
	};

  $scope.onSelect = function (files)
  {
		function onLoadFile(event) {
			$scope.onSavePhotoData(event.target.result);
		}

		if (files.length > 0) {
			var file = files[0];
			loadImageWithOrientation(files[0], {maxWidth: 800, maxHeight: 480, contain: true}).then(function(file){
				var reader = new FileReader();
				reader.onload = onLoadFile;
				reader.readAsDataURL(file);
			});
		}
  };

  $scope.wrapRespondent = function (respondent) {
    var wrappedRespondent = new $scope.Respondent(respondent);
    wrappedRespondent.getType = function() {
      if ($scope.respondentIsUser(respondent)) {
        return RespondentType.USER;
      }

      return RespondentType.GROUP;
    };

    wrappedRespondent.isSelected = function () {
      return respondent == $scope.selectedRespondent;
    };

    wrappedRespondent.isCheckMark = function () {
      return respondent === CorrectResponseId.CORRECTANSWERS;
    };

    wrappedRespondent.select = function () {
      $scope.selectUser(respondent);
    };

    return wrappedRespondent;
  };

}]);
})();
