'use strict';

(function () {
  let module = angular.module('client.components');

  controller.$inject = ['$scope', '$rootScope', '$http', '$routeParams', '$log', 'MasterFactory', 'ExperienceClassGroupFactory',
    '$location', 'User', 'ExperienceGroupListFactory', 'ExperienceSaveAndInvite', 'ExperienceInviteStudentsFactory',
    'StudentGroupNameFactory', 'ExperienceGetGroupSmallGroup',
    '$timeout', 'ModalService', 'CourseFactory', '$q', 'ActiveMode', 'ExperienceState', 'ReadingGroupService',
    'ReadingGroupDefaultsService', 'CourseAPI', 'googleService', 'ActiveExperience', 'CollectionsFactory',
    'userPermissions', 'PermissionConsts', 'UserTrials', 'canvasService', 'tourService'];

  function controller($scope, $rootScope, $http, $routeParams, $log, MasterFactory, ExperienceClassGroupFactory,
                      $location, User, ExperienceGroupListFactory, ExperienceSaveAndInvite, ExperienceInviteStudentsFactory,
                      StudentGroupNameFactory, ExperienceGetGroupSmallGroup,
                      $timeout, ModalService, CourseFactory, $q, ActiveMode, ExperienceState, ReadingGroupService,
                      ReadingGroupDefaultsService, CourseAPI, googleService, ActiveExperience, CollectionsFactory,
                      userPermissions, PermissionConsts, UserTrials, canvasService, tourService) {

    let ctrl = this;

    // Initialize the scope variables
    ctrl.template_name = {};
    ctrl.experience = {};
    ctrl.currentpage = 1;
    ctrl.xpError = {};
    ctrl.studentListModified = false;
    ctrl.groupSharing = false;
    ctrl.guidedNav = false;
    ctrl.features = [];
    ctrl.studentsInSelectedGroup = [];
    ctrl.studentGroups = [];
    ctrl.readingGroups = [];
    ctrl.smallGroupsTooltip = "Enables assignment of students for small group work, which is built into the experience.";
    ctrl.selfPacedTooltip = "Teacher gates are unlocked and you will not control lesson pacing - students will progress at their own pace. Also, student-facing feedback is turned on by default.";
    ctrl.isGoogleClassroom = false;
    ctrl.isCanvasClassroom = false;
    ctrl.isSchoologyClassroom = false;
    ctrl.lms_id = false;
    ctrl.googleClassIds = [];
    ctrl.canvasClassIds = [];
    ctrl.schoologyClassIds = [];
    ctrl.selfPaced = false;
    ctrl.isGoogleClassroomLessThan5MinutesAway = false;
    ctrl.assigningSingleClass = ActiveMode.isClassMode();
    ctrl.classes = [];
    ctrl.allClasses = false;
    ctrl.canSelfPace = false;
    ctrl.masterHasGates = false;

    // if this is a new experience then it will have a template value.  Need to error somehow if no template
    if ($routeParams.templateId) {
      // Grab the master template ID
      ctrl.masterItemId = parseInt($routeParams.templateId, 10);
      ctrl.parents = $routeParams.parents;
      ctrl.is_public = $routeParams.is_public;

      // Get the master template so we can populate the default values into the UI
      MasterFactory.master({}, {'template_id': ctrl.masterItemId}, function (master) {
        ctrl.template_name = "New Assignment: " + "<b>" + master.name + "</b>";

        // Default some of the values for the experience
        ctrl.experience.name = master.name;
        ctrl.experience.description = master.description;
        ctrl.experience.project_id = master.project_id;
        ctrl.experience.image_filename = master.image_filename;
        ctrl.experience.image_alt = master.image_alt;
        ctrl.student_facing_feedback = master.student_facing_feedback;
        ctrl.masterHasGates = master.hasGates;

        // Initialize the reading groups based on data from master
        if (master.reading_group_id >= 1) {
          ReadingGroupService.get({}, {group_id: master.reading_group_id}).$promise.then(function (rg) {
            ctrl.readingGroupDef = rg;
            // Add a placeholder for each of the levels in this group.  These will be arrays of student ID's
            ctrl.readingGroupDef.levels.forEach(function (level) {
              ctrl.readingGroups.push({reading_level: level.reading_level, students: []});
            });
            // Get the default reading groups for all class students and keep locally
            ReadingGroupDefaultsService.get({}, {
              class_id: ActiveMode.currentClassId(),
              group_id: master.reading_group_id
            }).$promise
              .then(function (defaultReadingGroups) {
                ctrl.defaultStudentReadingGroups = defaultReadingGroups;
              });
          });
        }

        // Does this experience template support group sharing?
        ctrl.groupSharing = master.has_groups == 1;
        if (ctrl.groupSharing) {
          ctrl.features.push({
            name: 'Small groups',
            description: 'Experiences published using this Master require the use of small student groups.'
          });
        }

        // Does this template support reading levels
        ctrl.hasReadingGroups = master.reading_group_id && master.reading_group_id >= 1;
        if (ctrl.hasReadingGroups) {
          ctrl.features.push({
            name: 'Reading groups',
            description: 'Experiences published using this Master require the use of reading groups.'
          });
        }

        // Does this experience template support teacher-controlled navigation?
        ctrl.guidedNav = master.is_guided == 1;
        if (ctrl.guidedNav) {
          ctrl.features.push({
            name: 'Teacher nav',
            description: 'Teachers will be able to control student navigation in Experiences published using this Master.'
          });
        }

        // if this is assigning to multiple classes then get the class list
        if (!ctrl.assigningSingleClass) {
          CollectionsFactory.assignableClasses({}, {
            'masterUUID': ctrl.masterItemId,
            'parents': ctrl.parents
          }, function (classes) {
            ctrl.classes = classes.map(function (cls) {
              return {
                id: cls.class_id,
                name: cls.name,
                checked: false,
                enabled: false,
                entitled: cls.entitled
              };
            }).sort(function (a, b) {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            ctrl.classes.forEach(function (cls) {
              getValidGroups(cls, master.reading_group_id);
            });
          });
        }

        // ============================================================================================
        // Initialize so we show page 1
        // ============================================================================================
        ctrl.showPage(1);
      });
    }

    ctrl.onSelectAllClasses = function () {
      ctrl.allClasses = !ctrl.allClasses;
      ctrl.classes.forEach(function (cls) {
        cls.checked = ctrl.allClasses && cls.enabled;
      });
    };

    function getValidGroups(cls, reading_group_id) {
      if (!cls.entitled) {
        cls.enabled = false;
        return;
      }

      if (!ctrl.groupSharing) {
        cls.enabled = true;
        return;
      }

      if (ctrl.groupSharing) {
        // Gather up the parameters from form
        ExperienceClassGroupFactory.get({
          'id': currentUserId,
          'cid': cls.id
        }).$promise.then(function (data) {
          if (data.groups && data.groups.length) {
            ExperienceGroupListFactory.get({
              'id': currentUserId,
              'gid': data.groups[0].gid
            }).$promise.then(function (data) {
              if (data.students && data.students.length) {
                let smallGroupStudents = data.students.filter(function (student) {
                  return student.small_group_id > 0;
                });
                if (smallGroupStudents.length === data.students.length) {
                  cls.enabled = true;
                }
              } else {
                cls.enabled = false;
              }
            });
          }
        });
      }
    }

    function setGoogle5MinutesMark(startingDatetime) {
      let diff = startingDatetime - (new Date());
      ctrl.isGoogleClassroomLessThan5MinutesAway = (diff <= ((5 * 60 * 1000)));
    }

    ctrl.dateFormatter = function (date) {
      return $rootScope.dateFormatter(date);
    };

    // Shows the correct page based on the image that was clicked (Details, Students, Assign)
    ctrl.showPage = function (index) {
      // Validate date if switching from step 1
      if (ctrl.currentpage === 1) {
        // See if the end date is before the start date
        if (ctrl.experience.endDate < ctrl.experience.startDate) {
          ctrl.postInvalidDates('The experience start date and time must be before the end date and time.');
          return;
        } else if (ctrl.experience.endDate < Date.now()) {
          ctrl.postInvalidDates('The experience end time has already passed.');
          return;
        }
        if (ctrl.assigningSingleClass) {
          tourService.setNextStepIncrement(2);
        }
      }

      if (index == 2) {
        tourService.gotoNextTourStep();
      }

      if (index === 3) {
        checkGoogleClassroom();
        getCollectionClassPermissions();
        tourService.gotoNextTourStep();
      }
      ctrl.currentpage = index;
    };

    ctrl.onChangedStartTime = function () {
      ctrl.experience.startDate.setHours(ctrl.experience.startTime.getHours());
      ctrl.experience.startDate.setMinutes(ctrl.experience.startTime.getMinutes());
      setGoogle5MinutesMark(ctrl.experience.startDate);
    };

    ctrl.onChangedEndTime = function () {
      ctrl.experience.endDate.setHours(ctrl.experience.endTime.getHours());
      ctrl.experience.endDate.setMinutes(ctrl.experience.endTime.getMinutes());
    };

    function readingGroupsAreValid() {
      let allStudentsInReadingGroup = true;

      // If this experience is using reading groups
      if (ctrl.hasReadingGroups) {
        // Loop through all students and make sure they are assigned to a reading group
        ctrl.studentsInSelectedGroup.forEach(function (selStudent) {
          let isInList = false;
          ctrl.readingGroups.forEach(function (readingGroup) {
            let studentIndex = readingGroup.students.indexOf(selStudent.id);
            if (studentIndex >= 0) {
              isInList = true;
            }
          });
          // If not found in any reading group then we need to exit with error so teacher can assign all students
          if (!isInList) {
            allStudentsInReadingGroup = false;
          }
        });

        // If not found any any reading group then error and bail on the save
        if (!allStudentsInReadingGroup) {
          ctrl.postInvalidReadingGroups();
        }
      }

      return allStudentsInReadingGroup;
    }

    function smallGroupsAreValid() {
      let validGroups = true;
      let deletingEmptyGroups = false;

      // If this experience is using groups then make sure each student has been placed into one
      if (ctrl.groupSharing) {
        // Loop through all the students in the list and make sure they have an entry in the student groups array
        ctrl.studentsInSelectedGroup.forEach(function (selStudent) {
          let inList = false;
          // Check to see if this is in any of the groups
          ctrl.studentGroups.forEach(function (group) {
            let index = group.indexOf(selStudent.id);
            if (index >= 0) {
              inList = true;
            }
          });

          // if in no list then not a valid group
          if (!inList) {
            validGroups = false;
          }
        });

        // If all students are assigned to a group, then make sure there are no empty groups
        if (validGroups) {
          // Remove any/all empty groups at the end of the list but always leave 2
          for (let pos = ctrl.studentGroups.length - 1; pos > 1; --pos) {
            // If this is empty then remove it
            if (ctrl.studentGroups[pos].length === 0) {
              ctrl.studentGroups.splice(pos, 1);
            }
          }

          // Each group needs to have a user
          ctrl.studentGroups = ctrl.studentGroups.filter(function (sg) {
            deletingEmptyGroups = (deletingEmptyGroups || sg.length === 0);
            return sg.length > 0;
          });
        }

        // if there are not student groups then create 2 empty groups since that is the minimum number.
        // This should only really happen if there are NO students added
        if (ctrl.studentGroups.length === 0) {
          ctrl.studentGroups.push([]);
          ctrl.studentGroups.push([]);
        }

        // if the counts are different then don' allow the user to publish
        if (!validGroups) {
          ctrl.postInvalidStudentGroups();
        } else if (deletingEmptyGroups) {
          ctrl.postDeletedEmptyGroups();
        }
      }

      return validGroups;
    }

    function groupsAreValid() {
      return readingGroupsAreValid() && smallGroupsAreValid();
    }

    function newExperience() {
      let experience = {};
      experience.experience_id = $routeParams.experience;
      experience.teacher_user_id = User.getId();
      experience.catalog_item_id = ctrl.masterItemId;
      experience.template_id = ctrl.masterItemId;
      experience.name = ctrl.experience.name;
      experience.section_name = ctrl.experience.section_name;
      // Calculate the start date based on Date and Time
      experience.starts_at = ctrl.experience.startDate;
      experience.starts_at = experience.starts_at.toString();
      // Calculate the start date based on Date and Time
      experience.ends_at = ctrl.experience.endDate;
      experience.ends_at = experience.ends_at.toString();
      experience.description = ctrl.experience.description;
      experience.notification = '';
      experience.small_groups = ctrl.studentGroups.length;
      experience.small_group_count = ctrl.studentGroups.length;
      experience.small_group_id = ctrl.selectedGroup ? ctrl.selectedGroup.gid : null;
      experience.is_guided = ctrl.guidedNav;
      experience.preview = false;
      experience.return_url = null;
      experience.project_id = ctrl.experience.project_id;
      experience.reading_group_id = ctrl.readingGroupDef ? ctrl.readingGroupDef.reading_group_id : -1;
      experience.student_facing_feedback = ctrl.student_facing_feedback || ctrl.selfPaced;
      experience.parents = ctrl.parents;
      experience.open_all_gates_on_activation = ctrl.selfPaced;

      return experience;
    }

    function assignMasterToClass() {
      let studentList = [];
      ctrl.studentsInSelectedGroup.forEach(function (student) {
        studentList.push({
          'username': student.username,
          'small_group': 0,
          user_id: student.id
        });
      });

      // Loop through all students and make sure they are assigned to a reading group
      ctrl.studentsInSelectedGroup.forEach(function (selStudent) {
        ctrl.readingGroups.forEach(function (readingGroup) {
          let studentIndex = readingGroup.students.indexOf(selStudent.id);
          if (studentIndex >= 0) {
            // Update the group for the student experience
            studentList.forEach(function (experienceStudent) {
              if (selStudent.username === experienceStudent.username) {
                experienceStudent.reading_level = readingGroup.reading_level;
              }
            });
          }
        });
      });

      let grpId = 1;
      ctrl.studentGroups.forEach(function (group) {
        group.forEach(function (studentInGroup) {
          // Find the email for this student.  It is needed for saving to small groups
          ctrl.studentsInSelectedGroup.forEach(function (student) {
            if (studentInGroup === student.id) {
              // Update the group for the student experience
              studentList.forEach(function (experienceStudent) {
                if (student.username === experienceStudent.username) {
                  experienceStudent.small_group = grpId;
                }
              });
            }
          });
        });
        ++grpId;
      });

      // Create a new experience
      let experience = newExperience();

      // convert list of students  array to string
      let studentListString = JSON.stringify(studentList);
      experience.students = studentListString;
      experience.class_id = $routeParams.classId;

      // First, save the new experience
      ExperienceSaveAndInvite.publishExperience(experience).then(function (oSave) {
        if (ctrl.isGoogleClassroom || ctrl.isCanvasClassroom || ctrl.isSchoologyClassroom) {
          canvasService.postAssignment(ctrl.lms_id, oSave.dataResult.id)
            .then(function (result) {
            })
            .catch(function (error) {
              if (!error) {
                return false;
              }
              let errorMessage = "There was a problem adding your assignment to Google.";
              if (ctrl.isCanvasClassroom) {
                errorMessage = "There was a problem adding your assignment to Canvas.";
              } else if (ctrl.isSchoologyClassroom) {
                errorMessage = "There was a problem adding your assignment to Schoology.";
              }
              ModalService.show(
                {
                  backdrop: 'static',
                  template: require('../../views/partials/modals/notifyPostToGoogleFailed.jade'),
                  message: errorMessage,
                  buttons: [
                    {
                      title: 'Ok',
                      click: '$hide()'
                    }
                  ]
                }
              );
            });
        }

        // After it saved, invite the correct students
        ExperienceSaveAndInvite.doInviteStudents(oSave.dataResult.id).then(function (oInvite) {
          // After full assigning the experience, open it
          ActiveMode.navigateToExperiences();
          ctrl.publishInProgress = false;

          tourService.gotoNextTourStep();

          // Update the trials based on publishing a new experience
          UserTrials.updateTrials();
        });
      });
    }

    function assignMasterToMultiClasses() {
      // Create a new experience
      let experience = newExperience();

      // Create a list of the selected classes
      let classIds = ctrl.classes.filter(function (cls) {
        return cls.checked;
      }).map(function (cls) {
        return cls.id;
      });

      experience.classes = classIds;

      // Publish this new experience to all the classes
      ExperienceSaveAndInvite.publishMCExperience(experience).$promise.then(function (experiences) {
        if (ctrl.isGoogleClassroom) {
          let success = true;
          experiences.forEach(function (newExperience) {
            let googleClass = ctrl.googleClassIds.find(function (cls) {
              return cls.clsId === newExperience.class_id;
            });
            if (googleClass) {
              googleService.postAssignment(googleClass.imsId, newExperience.id)
                .then(function (result) {
                })
                .catch(function (error) {
                  success = false;
                });
            }
          });
          if (!success) {
            let errorMessage = "There was a problem adding your assignment to Google.";
            ModalService.show(
              {
                backdrop: 'static',
                template: require('../../views/partials/modals/notifyPostToGoogleFailed.jade'),
                message: errorMessage,
                buttons: [
                  {
                    title: 'Ok',
                    click: '$hide()'
                  }
                ]
              }
            );
          }
        }

        if (ctrl.isCanvasClassroom) {
          let success = true;
          experiences.forEach(function (newExperience) {
            let canvasClass = ctrl.canvasClassIds.find(function (cls) {
              return cls.clsId === newExperience.class_id;
            });
            if (canvasClass) {
              canvasService.postAssignment(canvasClass.imsId, newExperience.id)
                .then(function (result) {
                })
                .catch(function (error) {
                  success = false;
                });
            }
          });
          if (!success) {
            let errorMessage = "There was a problem adding your assignment to Canvas.";
            ModalService.show(
              {
                backdrop: 'static',
                template: require('../../views/partials/modals/notifyPostToGoogleFailed.jade'),
                message: errorMessage,
                buttons: [
                  {
                    title: 'Ok',
                    click: '$hide()'
                  }
                ]
              }
            );
          }
        }

        if (ctrl.isSchoologyClassroom) {
          let success = true;

          experiences.forEach(function (newExperience) {
            let ssClass = ctrl.schoologyClassIds.find(function (cls) {
              return cls.clsId === newExperience.class_id;
            });

            if (ssClass) {
              canvasService.postAssignment(ssClass.imsId, newExperience.id)
                .then(function (result) {
                })
                .catch(function (error) {
                  $log.error("postAssignment failure: ", error);
                  success = false;
                });
            }
          });
          if (!success) {
            let errorMessage = "There was a problem adding your assignment to Schoology.";
            ModalService.show(
              {
                backdrop: 'static',
                template: require('../../views/partials/modals/notifyPostToGoogleFailed.jade'),
                message: errorMessage,
                buttons: [
                  {
                    title: 'Ok',
                    click: '$hide()'
                  }
                ]
              }
            );
          }
        }

        ActiveMode.navigateToExperiences();
        ctrl.publishInProgress = false;

        tourService.gotoNextTourStep();

        // Update the trials based on publishing a new experience
        UserTrials.updateTrials();
      });
    }

    // ======================================================================
    // Publish this experience.  Called when user hits submit button on the form
    // ======================================================================
    ctrl.publishInProgress = false;

    ctrl.publishExperience = function () {
      ctrl.publishInProgress = true;

      // If the start date is earlier than the current time then just set it to now
      if (ctrl.experience.startDate < Date.now()) {
        ctrl.experience.startDate = new Date();
        ctrl.experience.startTime = new Date();
      }

      // See if the end date is before the start date
      if (ctrl.experience.endDate < ctrl.experience.startDate) {
        ctrl.postInvalidDates('The experience start date and time must be before the end date and time.');
        ctrl.publishInProgress = false;
        return;
      }

      if (ctrl.assigningSingleClass) {
        assignMasterToClass();
      } else {
        assignMasterToMultiClasses();
      }
    }; // End of Publish


    // ============================================================================================
    // Based on Drop down selection, show the list of students' email
    // ============================================================================================
    let currentUserId = User.getId();

    ctrl.showStudentsInGroup = function (selectedGroup) {
      ctrl.selectedGroup = selectedGroup;

      // Gather up the parameters from form
      ExperienceGroupListFactory.get({
        'id': currentUserId,
        'gid': selectedGroup.gid
      }, function (data) { //Success callback
        // Handle and error object
        if (typeof data.error == 'object') {
          ctrl.xpError.details = data.error.description;
        } else {
          // This is student list according to selection
          ctrl.studentsInSelectedGroup = ActiveExperience.sortStudents(data.students, ActiveExperience.SORTBY.Name);
          ctrl.showPage(2);
          ctrl.studentListModified = false;

          // if this experience has reading groups then default them correctly
          if (ctrl.hasReadingGroups && ctrl.readingGroupDef) {
            // Clear the current reading groups as we are re-populating the list of students
            ctrl.readingGroups.forEach(function (readingGroup) {
              readingGroup.students = [];
            });
            let defaultReadingGroupLevel = ctrl.readingGroupDef.levels.find(function (level) {
              return level.default_level && level.default_level === true;
            });
            // Loop through student list and pre-populate each into their default group
            ctrl.studentsInSelectedGroup.forEach(function (student) {
              // Look for the default for this student
              let studentDefault = ctrl.defaultStudentReadingGroups.find(function (rgDefault) {
                return rgDefault.user_id === student.id;
              });
              if (studentDefault) {
                // We found a default for this student so now just put them into the correct object
                let currentReadingGroup = ctrl.readingGroups.find(function (grp) {
                  return grp.reading_level === studentDefault.reading_level;
                });
                if (currentReadingGroup) {
                  currentReadingGroup.students.push(student.id);
                }
              } else if (defaultReadingGroupLevel) {
                // If this student was never placed in any reading group for this class then put them in the reading group default.
                let defaultReadingGroup = ctrl.readingGroups.find(function (grp) {
                  return grp.reading_level === defaultReadingGroupLevel.reading_level;
                });
                if (defaultReadingGroup) {
                  defaultReadingGroup.students.push(student.id);
                }
              }
            });
          }

          // if this experience supports small groups
          if (ctrl.groupSharing) {
            // First, try to get existing small groups from the server
            ExperienceGetGroupSmallGroup.get({
                'id': currentUserId,
                'gid': selectedGroup.gid
              },
              function (data) {
                let initialSmallGroupCount = 0;
                if (data.small_groups && data.small_groups > 0) {
                  initialSmallGroupCount = data.small_groups;
                } else {
                  initialSmallGroupCount = (ctrl.studentsInSelectedGroup.length / 4) +
                    (ctrl.studentsInSelectedGroup.length / 4 > 0 ? 1 : 0);
                }

                // Reset groups to none
                ctrl.studentGroups = [];

                // Create enough empty entries in the student group list.  The default uses # / 4.
                for (let index = 0; index < initialSmallGroupCount; ++index)
                  ctrl.studentGroups.push([]);

                // Loop through student list and pre-populate each into their default group
                ctrl.studentsInSelectedGroup.forEach(function (student) {
                  // if this student has a valid group id
                  if (student.small_group_id > 0) {
                    ctrl.studentGroups[student.small_group_id - 1].push(student.id);
                  }
                });
              },
              function (error) { //Failure callback
                ActiveMode.navigateToExperiences();
              }
            );
          }
        }
      }, function (error) { //Failure callback
        ActiveMode.navigateToExperiences();
      });
    };

    // ============================================================================================
    // Flag to see if we have completed the page 1 fields
    // ============================================================================================
    ctrl.page1FilledOut = function () {
      ctrl.page1Completed = false;
      if (ctrl.experience) {
        let pageDataName = ctrl.experience.name;
        let pageDataStartDate = ctrl.experience.startDate;
        let pageDataEndDate = ctrl.experience.endDate;
        let pageDataDescription = ctrl.experience.description;

        if (pageDataName && pageDataStartDate && pageDataEndDate && pageDataDescription) {
          // Convert to string
          pageDataStartDate = pageDataStartDate.toString();
          pageDataEndDate = pageDataEndDate.toString();
          if ((pageDataName.length > 0) && (pageDataStartDate.length > 0) && (pageDataEndDate.length > 0) &&
            (pageDataDescription.length > 0)) {
            ctrl.page1Completed = true;
          }
        }
      }
      return ctrl.page1Completed;
    };
    ctrl.page1FilledOut();
    ctrl.groupList = [];
    ctrl.status = {
      isopen: false
    };

    // Gather up the parameters from form
    ExperienceClassGroupFactory.get({
      'id': currentUserId,
      'cid': ActiveMode.currentClassId()
    }, function (data) { //Success callback
      // Handle and error object
      if (typeof data.error == 'object') {
        ctrl.xpError.details = data.error.description;
      } else {
        ctrl.groupList = data.groups;
      }
    }, function (error) { //Failure callback
      ActiveMode.navigateToExperiences();
    });

    function checkGoogleClassroom() {
      if (ctrl.assigningSingleClass) {
        CourseAPI.get({cid: ActiveMode.currentClassId()}).$promise.then(function (currentCourse) {
          ctrl.isGoogleClassroom = currentCourse.googlePostAssignments || false;
          ctrl.isCanvasClassroom = currentCourse.canvasPostAssignments || false;
          ctrl.isSchoologyClassroom = currentCourse.schoologyPostAssignments || false;
          ctrl.lms_id = currentCourse.ims_id;
        });
      } else {
        let promises = ctrl.classes.filter(function (cls) {
          return cls.checked;
        }).map(function (fcls) {
          return CourseAPI.get({cid: fcls.id}).$promise;
        });

        $q.all(promises).then(function (results) {
          results.forEach(function (cls) {
            ctrl.isGoogleClassroom = cls.googlePostAssignments || ctrl.isGoogleClassroom;
            ctrl.isCanvasClassroom = cls.canvasPostAssignments || ctrl.isCanvasClassroom;
            ctrl.isSchoologyClassroom = cls.schoologyPostAssignments || ctrl.isSchoologyClassroom;
            if (cls.ims_id && cls.ims_id === -1) {
              ctrl.googleClassIds.push({clsId: cls.id, imsId: cls.ims_id});
            }
            if (cls.ims_id && cls.ims_id > 0) {
              ctrl.canvasClassIds.push({clsId: cls.id, imsId: cls.ims_id});
            }
            if (cls.ims_id && cls.ims_id === -4) {
              ctrl.schoologyClassIds.push({clsId: cls.id, imsId: cls.ims_id});
            }
          });
        });
      }
    }

    function getCollectionClassPermissions() {
      if (ctrl.masterHasGates && userPermissions.hasPermission(PermissionConsts.open_all_gates_on_activation)) {
        ctrl.canSelfPace = true;
      }
    }

    // ============================================================================================
    // From the Student list, teacher may want to trim the students, so remove the selected student
    // ============================================================================================
    ctrl.onDeleteStudent = function (studentEmail) {
      let tempArray = ctrl.studentsInSelectedGroup;
      for (let i = tempArray.length - 1; i >= 0; i--) {
        // Remove that item
        if (tempArray[i] == studentEmail) {
          tempArray.splice(i, 1);
        }
      }
      // Now, since we removed the item, reassign the list and show it
      ctrl.studentsInSelectedGroup = tempArray;
      ctrl.showPage(2);
      // Catch Flag
      ctrl.studentListModified = true;
    };


    // ============================================================================================
    // New Date Time Picker:
    // ============================================================================================
    let now = new Date();
    let nowPlusOneHour = new Date(now);
    nowPlusOneHour.setHours(now.getHours() + 2);
    ctrl.experience.startDate = now;
    ctrl.experience.startTime = now;
    ctrl.experience.endDate = nowPlusOneHour;
    ctrl.experience.endTime = nowPlusOneHour;

    setGoogle5MinutesMark(ctrl.experience.startDate);

    // Inform user they have invalid groups
    ctrl.postInvalidStudentGroups = function () {
      ModalService.show(
        {
          title: 'Invalid Groups',
          message: 'All students must be assigned to a small group, and every small group must have at least one member before this experience can be published.',
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        }
      );
    };

    ctrl.postDeletedEmptyGroups = function () {
      ModalService.show(
        {
          title: 'Removed Groups',
          message: 'Empty groups were found and were removed from this experience.  Any students assigned to groups after these have been re-assigned accordingly.',
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        }
      );
    };

    // Inform user they have invalid groups
    ctrl.postInvalidReadingGroups = function () {
      ModalService.show(
        {
          title: 'Invalid Reading Groups',
          message: 'All students must be assigned to a reading group before this experience can be published.',
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        }
      );
    };

    ctrl.postInvalidDates = function (msg) {
      ModalService.show(
        {
          title: 'Invalid Dates',
          message: msg,
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        }
      );
    };

    ctrl.postViewSaveListToNewGroup = function () {
      // Ask the user if they want to save this as a group of students
      ModalService.show(
        {
          title: 'Save Group',
          message: 'Would you like to save this group for future use?',
          showTextInput: true,
          textFieldValue: {value: ""},
          textFieldPlaceholder: 'Group Name',
          invalidValue: null,
          buttons: [
            {
              title: 'Save',
              click: 'saveGroup(textFieldValue);',
              type: 'submit'
            },
            {
              title: 'No',
              click: 'cancelGroup(); $hide()'
            }
          ],
          submit: 'onSaveStudentGroup(textFieldValue.value);',
          saveGroup: function (textFieldValue) {
            onSaveStudentGroup(textFieldValue.value);
          },
          cancelGroup: function () {
            onCancelStudentGroup();
          }
        }
      );
    };

    function onCancelStudentGroup() {
      ctrl.showPage(3);
    }


    function courseExists(name) {
      let deferred = $q.defer();

      let params = {
        teacher_user_id: User.getId(),
        cache: true
      };

      CourseFactory.list(params)
        .then(function (courses) {
          courses.forEach(function (course) {
            if (name.toUpperCase() == course.class_name.toUpperCase()) {
              deferred.resolve(name);
              return deferred.promise;
            }
          });
          deferred.resolve(null);
        });

      return deferred.promise;
    }

    function groupExists(name) {
      for (let index = 0; index < ctrl.groupList.length; ++index) {
        if (name.toUpperCase() == ctrl.groupList[index].group_name.toUpperCase()) {
          return true;
        }
      }
      return false;
    }

    function groupErrorDialog(error) {
      ModalService.error(error);
    }

    function onSaveStudentGroup(new_group_name) {
      // Name cannot be blank
      if (new_group_name.length === 0) {
        groupErrorDialog('A group name cannot be empty.');
        return;
      }

      // First, see if this belongs to a group the teacher has already defined
      if (groupExists(new_group_name)) {
        groupErrorDialog('You already have a group with that name. Please choose a different name.');
        return;
      }

      // Second, check to make sure this
      courseExists(new_group_name).then(function (matchingName) {
        // If the name is returned it indicates it already exists
        if (matchingName) {
          groupErrorDialog('You already have a class with that name. Please choose a different name.');
          return;
        }

        let studentList = [];
        ctrl.studentsInSelectedGroup.forEach(function (student) {
          studentList.push(student.username);
        });

        // convert list of students array to string
        let studentListString = JSON.stringify(studentList); // Shows up

        // Call the save
        StudentGroupNameFactory.update({}, {
          'id': User.getId(),
          'group_name': new_group_name,
          'students': studentListString,
          'cid': $routeParams.classId
        }, function (response) {
          ctrl.showPage(3);
        });
        ModalService.hideAll();
      })
        .catch(function (error) {
          $log.error("error in saving student group:", error);
          ModalService.hideAll();
        });
    }

    ctrl.setSmallGroup = function (studentId, index) {
      // First, remove this student from any group they are currently in
      ctrl.studentGroups.forEach(function (group) {
        // Loop over this array looking for the user
        for (let loop = 0; loop < group.length; ++loop) {
          if (group[loop] == studentId) {
            group.splice(loop, 1);
          }
        }
      });

      // Now add this user to the correct group
      ctrl.studentGroups[index].push(studentId);
    };

    ctrl.setReadingGroup = function (studentId, reading_level) {
      // First, remove this student from any group they are currently in
      ctrl.readingGroups.forEach(function (group) {
        // Loop over this array looking for the user
        let studentIndex = group.students.indexOf(studentId);
        if (studentIndex >= 0) {
          group.students.splice(studentIndex, 1);
        }
      });

      // Now add this user to the correct group
      let currentReadingGroup = ctrl.readingGroups.find(function (readingGroup) {
        return readingGroup.reading_level === reading_level;
      });
      if (currentReadingGroup) {
        currentReadingGroup.students.push(studentId);
      }
    };

    function isStudentInSmallGroup(studentId, groupIndex) {
      // Grab the current values
      let group = ctrl.studentGroups[groupIndex];

      // Check this group to see if the student exists in it
      return group.indexOf(studentId) > -1;
    }

    ctrl.studentInSmallGroup = function (studentId, groupIndex) {
      return isStudentInSmallGroup(studentId, groupIndex);
    };

    function isStudentInReadingGroup(studentId, reading_level) {
      // Grab the current values
      let group = ctrl.readingGroups.find(function (readingGroup) {
        return readingGroup.reading_level === reading_level;
      });

      // Check this group to see if the student exists in it
      return group && group.students.indexOf(studentId) > -1;
    }

    ctrl.studentInReadingGroup = function (studentId, reading_level) {
      return isStudentInReadingGroup(studentId, reading_level);
    };

    ctrl.readingGroupIndex = function (index) {
      return 'ABCDEFGHIJ'[index];
    };

    ctrl.getHiliteClass = function (studentId, groupIndex) {
      if (isStudentInSmallGroup(studentId, groupIndex)) {
        return "small-group-indicator-" + groupIndex;
      }

      return "";
    };

    ctrl.getReadingGroupHiliteClass = function (studentId, reading_level) {
      if (isStudentInReadingGroup(studentId, reading_level)) {
        return "small-group-indicator-" + (reading_level - 1);
      }

      return "";
    };

    ctrl.onAddSmallGroup = function () {
      // If there are less than 10 groups then add another one
      if (ctrl.studentGroups.length < 10) {
        ctrl.studentGroups.push([]);
      }
    };

    ctrl.onRemoveSmallGroup = function () {
      // If there are less than 10 groups then add another one
      if (ctrl.studentGroups.length > 2) {
        ctrl.studentGroups.splice(ctrl.studentGroups.length - 1, 1);
      }
    };

    // ============================================================================================
    //
    // ============================================================================================
    ctrl.checkStudentList = function () {
      // Validate any groups before allowing progression to the assignment screen
      if (ctrl.assigningSingleClass && groupsAreValid()) {
        // If the user did a delete, or so, then we know group list has changed from selection
        // Prompt the user with the Save Group Dialog
        if (ctrl.studentListModified) {
          ctrl.postViewSaveListToNewGroup();
        } else {
          ctrl.showPage(3);
        }
      }
    };

    ctrl.checkClasses = function () {
      if (!ctrl.assigningSingleClass) {
        let validClasses = false;
        ctrl.classes.forEach(function (cls) {
          if (cls.checked) {
            validClasses = true;
          }
        });
        if (validClasses) {
          ctrl.showPage(3);
        }
      }
    };

    ctrl.getCheckedClasses = function () {
      let checkedCount = 0;
      ctrl.classes.forEach(function (cls) {
        if (cls.checked) {
          checkedCount = checkedCount + 1;
        }
      });
      return checkedCount;
    };
  }

  module.component('xpAssignExperience', {
    template: require('./assignExperience.jade'),
    controller: controller
  });

})();
