'use strict';

/* Controllers */
//


clientControllers.controller('ResponsesDashboardCtrl', ['$scope', '$q', '$rootScope', '$routeParams', '$interval',
  'ActiveExperience', 'StudentFactory', 'ExperienceClassFactory', 'ActivityStateListFactory', '$location', 'User',
  '$log', '$window', 'ElementRealtimeService', 'ElementsRestService', 'JSONStringUtility',
  'Utils', 'ScoreService', 'ActiveExperienceService', 'tao_host',
  '$http', 'SegmentFactory', 'TaoTestService', 'QuizStateService', '$timeout', 'PermissionConsts', 'ModalService', 'VideoStreamingService',
  'StudentStreamingService', 'TeacherStreamingService', 'xpAlert', 'ActiveMode', 'EventAuditAnalyticsService',
  function ($scope, $q, $rootScope, $routeParams, $interval, ActiveExperience, StudentFactory, ExperienceClassFactory,
            ActivityStateListFactory, $location, User, $log, $window, ElementRealtimeService,
            ElementsRestService, JSONStringUtility,
            Utils, ScoreService, ActiveExperienceService, tao_host, $http, SegmentFactory, TaoTestService,
            QuizStateService, $timeout, PermissionConsts, ModalService, VideoStreamingService,
            StudentStreamingService, TeacherStreamingService, xpAlert, ActiveMode, EventAuditAnalyticsService) {

    $scope.activeDashboard = "responses";

    $scope.RESPONSES = Object.freeze({
         None: { id: 1, label: 'None'},
         FirstAttempt: { id: 2, label: 'First Attempt'},
         LastAttempt: { id: 3, label: 'Last Attempt'}});

    // Extract the experience Id from the URL
    var experienceId = parseInt($routeParams.id, 10);
    $scope.experience = null;
    $scope.class_not_started = 0;
    $scope.class_completed = 0;
    $scope.class_average = 0;
    $scope.class_average_time = 0;
    $scope.students = [];
    $scope.allStudents = null;
    $scope.allWalls = [];
    $scope.userState = [];
    $scope.groupSharing = false;
    $scope.studentGroups = [];
    $scope.scenePercentages = [];
    $scope.gatePercentages = [];
    $scope.elementPositions = {};
    $scope.taoElementItems = {};
    $scope.sortOrder = 0;
    $scope.resource_dashboard_suffix = '-blue';
    $scope.active_page = "dashboard";
    $scope.hasQuiz = false;
    $scope.quizId = 0;
    $scope.totalQuizElements = 0;
    $scope.showResponses = $scope.RESPONSES.None;
    $scope.hasAssessedElements = false;
    $scope.averageAssessedScore = -1; // None calculated
    $scope.averageLastAssessedScore = -1; // None calculated
    $scope.firstAverageQuizScore = 0;
    $scope.averageQuizScore = 0;
    $scope.containsTIAQuiz = false;

    $scope.packtype = $scope.userIsTeacher() ? "teacher" : "student";

    $scope.validationFailed = function () {
      return false;
    };

    $scope.isPastExperience = function () {
      return $scope.experience && $scope.experience.status == "INACTIVE";
    };

    function elementTypeFriendlyName(cluster, element, quizQuestionIndex) {
      if (cluster.type === 'quiz_cluster') {
        return "Quiz Question " + quizQuestionIndex;
      }
      var elementType = element.type;
      if (elementType == "poll") {
        return "Poll";
      }
      else if (elementType == "choice"){
        return "Choice";
      }
      else if (elementType == "quiz_choice") {
        return "Quiz Question " + quizQuestionIndex;
      }
      else if (elementType == "word_cloud") {
        return "Cloud";
      }
      else if (elementType == "wall") {
        return "Wall";
      }
      else if (elementType == "table") {
        return "Table";
      }
      else if (elementType == "draw") {
        return "Draw";
      }
      else if (elementType == "drag_drop_image") {
        return "Drag and Drop Image";
      }
      else if (elementType == "drag_drop_text") {
        return "Drag and Drop Text";
      }
      else if (elementType == "graphic_organizer") {
        return "Graphic Organizer";
      }
      else if (elementType == "tao_assessment") {
        return "Quiz Question";
      }
      else if (elementType == "fill_in_the_blank") {
        return "Fill in the Blank";
      }
      else if (elementType == "inline_choice") {
        return "Inline Choice";
      }
      else if (elementType == "hot_spot") {
        return "Hot Spot";
      }
      else if (elementType == "hot_text") {
        return "Hot Text";
      }
      else if (elementType == "multi_part") {
        return "Multiple Part";
      }
      else if (elementType == "spire_concept_assessment") {
        return "CA";
      }
      else if (elementType == "spire_concept_mastery_fluency_drill") {
        return "CMFD";
      }
      else if (elementType == "spire_mid_level_test") {
        return "Mid-test";
      }
      else if (elementType == "spire_pre_post_level_test") {
        var testType;
        // Parse the configuration for this element and find the test type
        var config = JSONStringUtility.parse(element.config);
        config.attributes.forEach(function (attribute) {
          if (attribute.name === "prepost") {
            testType = attribute.value;
          }
        });
        // Display the correct tool tip based on the test type
        if (testType == "pre") {
          return "Pre-test";
        }
        else {
          return "Post-test";
        }
      }
    }

    function elementTypeIsAssessed(elementType) {
      return elementType == "choice" || elementType == "quiz_choice" || elementType == "drag_drop_text" || elementType == "drag_drop_image" ||
              elementType == "fill_in_the_blank" || elementType == "tao_assessment" || elementType == "inline_choice" || elementType == "hot_spot" ||
              elementType == "hot_text" || elementType == "multi_part";
    }

    function elementTypeIsInteractive(elementType) {
      return elementTypeIsAssessed(elementType) || elementType == "poll" || elementType == "word_cloud" || elementType == "wall" ||
            elementType == "table" || elementType == "draw" || elementType == "graphic_organizer" || elementType == "spire_concept_assessment" ||
            elementType == "spire_concept_mastery_fluency_drill" || elementType === "spire_mid_level_test" || elementType === "spire_pre_post_level_test";
    }

    function isQuizResponse(response, elementType) {
      return elementType === "spire_concept_assessment" || elementType === "spire_concept_mastery_fluency_drill" ||
              elementType === "spire_mid_level_test" || elementType === "spire_pre_post_level_test" ||
              !response.score || (response.score && response.score.user_quiz_state_id);
    }

    function hasAssessmentData(response) {
      // This response has assessment data if there is a score
      return response && response.score ? true : false;
    }

    function calculateCorrectness(response, elementType) {
      var spireCAPassing = 0.80;
      if (response && response.score && elementType != "spire_concept_mastery_fluency_drill") {
        // if the correct responses match the total or for spire CA it needs to be 80 %
        if ((response.score.correct === response.score.total && !response.score.incorrect) || response.score.score === 100 ||
          (elementType == "spire_concept_assessment" && response.score.correct / response.score.total >= spireCAPassing)) {
          return "correct";
        }
        else if (response.score.correct === 0) {
          return "wrong";
        }
        else {
          return "partial";
        }
      }
      else
        return "Not Assessed";
    }

    function makeAssessmentTitle(title, isAssessed, elementType, response, index) {
      if (response.score && response.score.user_quiz_state_id) {
        return "Quiz Question " + index;
      }
      else if ((isAssessed && hasAssessmentData(response)) || elementType == "spire_concept_mastery_fluency_drill" ||
          elementType == "spire_concept_assessment" || elementType == "spire_pre_post_level_test" || elementType == "spire_mid_level_test") {
        return title + (index ? index : "") + ": ";
      }
      else
        return title;
    }

    function makePrePostTestDisplay(response) {
      var display = "<br>Part 1: ";
      display = display + Math.round((response.score.decodableWordScore / 100) * $scope.elementPositions[response.element_id].prePostCounts.words) +
                "/" + $scope.elementPositions[response.element_id].prePostCounts.words + " " + Math.round(response.score.decodableWordScore) + "%";

      display = display + "<br>Part 2: " + Math.round((response.score.decodableSentenceScore / 100) * $scope.elementPositions[response.element_id].prePostCounts.sentences) +
                "/" + $scope.elementPositions[response.element_id].prePostCounts.sentences + " " + Math.round(response.score.decodableSentenceScore) + "%";

      display = display + "<br>Part 3: " + response.score.passageLastWord + " WPM";

      display = display + "<br>Part 4: " + Math.round((response.score.comprehensionScore / 100) * $scope.elementPositions[response.element_id].prePostCounts.questions) +
                "/" + $scope.elementPositions[response.element_id].prePostCounts.questions + " " + Math.round(response.score.comprehensionScore) + "%";

      return display;
    }

    function makeCorrectOverTotal(isAssessed, elementType, response, index) {
      if (elementType == "spire_concept_assessment") {
        var calculatedDisplay = response.score.correct + "/" + response.score.total;
        if (response.score.total > 0) {
         calculatedDisplay = calculatedDisplay + " " + Math.round((response.score.correct / response.score.total) * 100) + "%";
        }
        return calculatedDisplay;
      }
      else if (elementType === "spire_concept_mastery_fluency_drill") {
        return response.score.cmfdScore + " WPM";
      }
      else if (elementType === "spire_pre_post_level_test") {
        return makePrePostTestDisplay(response);
      }
      else if (elementType === "spire_mid_level_test") {
        var value = "";
        if (response.score.midScores) {
          response.score.midScores.forEach(function(midScore, index){
            value = value + "<br>Concept " + (index + 1) + ": " + Math.round(midScore.score) + "%";
          })
        }
        return value;
      }
      else if (isAssessed && hasAssessmentData(response) && !response.score.user_quiz_state_id) {
        return response.score.correct + "/" + response.score.total;
      }
      else {
        return "";
      }
    }

    function calculateScore(isAssessed, elementType, response) {
      if (isAssessed && hasAssessmentData(response) && !response.score.user_quiz_state_id) {
        return response.score.score / 100;
      }
      else {
        return 0;
      }
    }

    function taoElementItemCount(element) {
      // Call to backend to get TAO data
      return TaoTestService.getTestResults({eid: experienceId})
      .$promise.then(function(results){
        $scope.taoElementItems[element.id] = results.items_cnt;
        return results.items_cnt;
      });
    }

    function elementCount(elements) {
      var elementPromises = [];
      elements.forEach(function (element) {
        // if this element is tao, then get the item count from API
        if (element.type == "tao_assessment") {
          elementPromises.push(taoElementItemCount(element));
        }
        else
          elementPromises.push(1);
      });
      return $q.all(elementPromises).then(function (totalElements) {
        var totalElementCount = 0;
        totalElements.forEach(function (elementCount) {
          totalElementCount = totalElementCount + elementCount;
        });
        return totalElementCount;
      });
    }

    function clusterElementCount(clusters) {
      var clusterPromises = [];
      clusters.forEach(function (cluster) {
        clusterPromises.push(elementCount(cluster.elements));
      });

      return $q.all(clusterPromises).then(function (clusterCounts) {
        var totalClusterElements = 0;
        clusterCounts.forEach(function (count) {
          totalClusterElements = totalClusterElements + count;
        });
        return totalClusterElements;
      });
    }

    function calculateElementsPerScene(scenes) {
      return $q.all(scenes.map(function (scene) {
        return clusterElementCount(scene.clusters);
      }));
    }

    function itemsPerElement(element) {
      return $scope.taoElementItems[element.id] ? $scope.taoElementItems[element.id] : 1;
    }

    function initStudents(experience) {
      var studentList = [];

      // Save full list of students
      $scope.allStudents = experience.students;

      // Put all the correct students from the experience into the local list of students
      for (var allIndex = 0; allIndex < experience.students.length; ++allIndex) {
        // Grab this student from the list of students in the experience
        var student = experience.students[allIndex];

        // if this is a student user then only copy it into the list
        if ($scope.userIsStudent() && User.getId() == student.user_id)
          studentList.push(student);
        else if (!$scope.userIsStudent() && User.getId() != student.user_id)
          studentList.push(student);
      }

      // Sort by default based on experience having small groups
      if (experience.small_groups && experience.small_groups > 0) {
        $scope.students = ActiveExperience.sortStudents(studentList, ActiveExperience.SORTBY.Group);
        $scope.sortOrder = 2;
      }
      else {
        $scope.students = ActiveExperience.sortStudents(studentList, ActiveExperience.SORTBY.Name);
        $scope.sortOrder = 1;
      }
    }

    // Watch for changes to the current experience.  If this is a teacher then the list of students may need to be updated
    $scope.$watch(function() {
      return ActiveExperience.currentExperience();
    },
    function(value) {
      // If this is a teacher and the experience changed then update scope variable
      if ($scope.userIsTeacher() && value && value.status !== 'ARCHIVED') {
          $scope.experience = value;
      }
    });

    function parsedWordCount(value) {
      if (value && value.split) {
        var split = value.split(',');
        return split.length;
      }
      return 0;
    }

    function parseQuestions(value) {
      if (value && value.length) {
        return value.length;
      }
      else {
        return 0;
      }
    }

    function getPrePostItemCount(element) {
      var counts = {words: 0, sentences: 0, questions: 0};
      var config = JSONStringUtility.parse(element.config);
      config.attributes.forEach(function(attribute){
        var name = attribute.name;
        var value = attribute.value;
        switch (name) {
          case "words":
            counts.words = parsedWordCount(value);
            break;
          case "sentences":
            if (!(value instanceof Array))
            {
              value =
                [
                  {
                    name: "sentence",
                    value: value.sentence
                  }
                ];
            }
            counts.sentences = parseQuestions(value);
            break;
        case "comprehension_questions":
          if (!(value instanceof Array))
          {
            value =
              [
                {
                  name: "comprehension_question",
                  value: value.question
                }
              ];
          }
          counts.questions = parseQuestions(value);
          break;
        }
      });

      return counts;
    }

    // Function that loads the experience
    $scope.getExperience = function () {
      // Get the Experience basics
      ActiveExperience.getExperience(experienceId).then( function (experience) {
        if (experience.status === 'ARCHIVED' && experience.teacher && experience.teacher.canvas_user) {
          $log.debug("Archived Experience for canvas user: " + JSON.stringify(experience));
          xpAlert.error("This learning experience has ended and no one participated so it has been removed.", true);
          ActiveMode.navigateToExperiences();
          return;
        }

        // Save the experience to the scope
        $scope.experience = experience;

        experience.activity.content.scenes.forEach(function(scene) {
          scene.clusters.forEach(function(cluster) {
            if (cluster.pretest_tia || cluster.posttest_tia) {
              $scope.containsTIAQuiz = true;
            }
          })
        })

        if (!experience.preview &&  experience.status === 'ACTIVE') {
          // Initialize streaming to current experience
          if ($scope.userIsStudent()) {
            StudentStreamingService.initStudentStreaming($scope.experience.id);
          } else {
            TeacherStreamingService.initTeacherStreaming($scope.experience.id);
          }

          VideoStreamingService.setCurrentScene($routeParams.sceneid);
          $scope.shareVideo = TeacherStreamingService.isTeacherStreamingVideo();
        }

        // subscribed users should default to show the first attempts
        if (ActiveExperience.hasPermission($scope.experience.id, PermissionConsts.ui_curriculum_show_dashboard_responses) &&
            ($scope.userIsTeacher() || $scope.isPastExperience()) && !$scope.containsTIAQuiz)
          $scope.showResponses = $scope.RESPONSES.FirstAttempt;

        // Set the Experience Preview mode
        $scope.previewEnabled = experience.preview;

        // if there are any small groups in this experience then it is using group sharing
        $scope.groupSharing = experience.small_groups && experience.small_groups > 0;

        // Initialize the students
        initStudents(experience);

        SegmentFactory.track(SegmentFactory.events().RESPONSE_DASHBOARD_VIEWED);

        // Loop through all the scenes and calculate the percentage of each
        var width = 0;
        for (var index = 1; index < experience.activity.content.scenes.length; ++index) {
          // Calculate the width of each of the scenes
          width = experience.activity.content.scenes[index].position - experience.activity.content.scenes[index - 1].position;
          $scope.scenePercentages.push({
            'scene_id': experience.activity.content.scenes[index].id,
            'width': width,
            'left': experience.activity.content.scenes[index - 1].position
          });
        }

        // Need to calculate the final scene width
        width = 100 - experience.activity.content.scenes[experience.activity.content.scenes.length - 1].position;
        $scope.scenePercentages.push({
          'scene_id': 9999,
          'width': width,
          'left': experience.activity.content.scenes[experience.activity.content.scenes.length - 1].position
        });

        // Calculate the % for each scene.  The elemnts will be based on the scene
        var scenePercent = 1 / experience.activity.content.scenes.length;

        // Calculate the elements in each scene
        calculateElementsPerScene(experience.activity.content.scenes).then(function (elementsPerScene) {
          var prevElementId = 0;
          // Loop through and find each gate saving its position
          experience.activity.content.scenes.forEach(function (scene, sceneIndex) {
            // Need to keep track of the current element index in scene.  This crosses clusters so we cannot use element index
            var elementIndexInScene = 1;
            scene.clusters.forEach(function (cluster) {
              // See if this is a quiz cluster
              if (cluster.type === 'quiz_cluster') {
                $scope.hasQuiz = true;
                $scope.quizId = cluster.id;
              }
              var quizQuestionIndex = 1;
              // Loop through all the elements and calculate rough positions in the display
              cluster.elements.forEach(function (element, elemIndex) {
                // if this is a gate then save the position
                if (element.type == "HTMLteacher_gate") {
                  $scope.gatePercentages.push({
                    'id': element.id,
                    'status': $scope.isPastExperience() ? 1 : element.progress,
                    'position': $scope.scenePercentages[sceneIndex].left + ((element.position / 100) * $scope.scenePercentages[sceneIndex].width)
                  });
                }

                // Assign the next experience ID to the previous element
                if (prevElementId && elementTypeIsInteractive(element.type)) {
                  $scope.elementPositions[prevElementId].nextId = element.id;
                }

                // Calculate the % for this element.  It needs to be based on element index in scene and % of scene
                // this is not an array but subscript syntax
                $scope.elementPositions[element.id] = {
                  'scene': sceneIndex,
                  'id': element.id,
                  'previousId': prevElementId,
                  'nextId': 0,
                  'position': ((elementIndexInScene / elementsPerScene[sceneIndex]) * scenePercent) + (sceneIndex * scenePercent),
                  'title': elementTypeFriendlyName(cluster, element, quizQuestionIndex),
                  'type': element.type,
                  'isAssessed': elementTypeIsAssessed(element.type),
                  'isInteractive': elementTypeIsInteractive(element.type),
                  'elementsInScene': elementsPerScene[sceneIndex],
                  'totalResponses': 0,
                  'correctResponses': 0,
                  'correctLastResponses': 0,
                  'spire': {},
                  'link': "/experience/" + experience.id + "/activity/scene/" + (sceneIndex + 1),
                  'anchor': element.id
                };

                // Track at a global level if any assessed elements (not quizzes)
                $scope.hasAssessedElements = $scope.hasAssessedElements || (elementTypeIsAssessed(element.type) && cluster.type !== 'quiz_cluster');

                // Need to track info about how this fits into a quiz
                if (cluster.type === 'quiz_cluster') {
                  // Track the total number of quiz choice elements so we know a student has finished
                  $scope.totalQuizElements = $scope.totalQuizElements + 1;

                  // Follow the quiz question index
                  $scope.elementPositions[element.id].quizQuestionIndex = quizQuestionIndex;
                  quizQuestionIndex = quizQuestionIndex + 1;
                }

                // Add extra values for spire type elements
                if (element.type == "spire_pre_post_level_test") {
                  $scope.elementPositions[element.id].prePostCounts = getPrePostItemCount(element);
                }

                // Increment the count of elements in the scene (this is incrementing total for all clusters)
                elementIndexInScene = elementIndexInScene + itemsPerElement(element);

                // Save the ID of the previous element
                if ($scope.elementPositions[element.id].isInteractive) {
                  prevElementId = element.id;
                }
              });
            });
          });

          // Now calculate response progress
          calculateProgress();
        });
      })
      .catch(function (err) {
        $log.error("error in get experience:", err);
      });
    };

    // Load the experience on startup
    $scope.getExperience();

    function calculateResponsePosition(allResponses, response) {
      var responseIndicatorPercentWidth = 2;
      // if this is a tao response then need to calculate based on element position and number of tao items
      if (response.score && response.score.tao_question_index !== null) {
        // Get the default left position for this element IF there were just one response
        var initialTAOLeft = $scope.elementPositions[response.element_id].position * 100;

        // Currently, All scene widths are exactly the same so we can use the first scene width for a calculation
        // for all scenes.  If this changes in the future then this logic will need to calculate the current scene the tao element is in
        var widthPerElement = $scope.scenePercentages[0].width / $scope.elementPositions[response.element_id].elementsInScene;

        // This displays each indicator 2% apart
        return Math.min(initialTAOLeft + (response.score.tao_question_index * widthPerElement) - (responseIndicatorPercentWidth / 2), 100 - (responseIndicatorPercentWidth / 2));
      }
      else {
        return Math.min(Math.max(($scope.elementPositions[response.element_id].position * 100) - (responseIndicatorPercentWidth / 2), 0), 100 - (responseIndicatorPercentWidth / 2));
      }
    }

    function updateQuizScores(students)
    {
      if ($scope.hasQuiz) {
        // get the quiz state for all users in this quiz
        QuizStateService.quizState(experienceId, $scope.quizId)
          .getState()
          .$promise.then(function(states){
            var studentCount = 0;
            var totalScore = 0;
            var firstTotalScore = 0;
            states.forEach(function(state){
              var student = students.find(function(st) { return st.user_id === state.user_id; });
              if (student && (state.status === 'finished' || state.status === 'retake')){
                if (!student.quizScore.firstFinished) {
                  student.quizScore.firstScore = state.score;
                  firstTotalScore = firstTotalScore + state.score;
                  totalScore = totalScore + state.score;
                  studentCount = studentCount + 1;
                } else {
                  totalScore = totalScore + (state.score - student.quizScore.score);
                }

                student.quizScore.score = state.score;
                student.quizScore.firstFinished = true;
                if (state.status !== 'retake' || $scope.isPastExperience()) {
                  student.quizScore.finished = true;
                }
              }
              if (student) {
                student.status = state.status;
              }
            });

            // Now calculate the class average
            if (studentCount > 0) {
              $scope.firstAverageQuizScore = Math.round(firstTotalScore / studentCount);
              $scope.averageQuizScore = Math.round(totalScore / studentCount);
            }
        })
        .catch(function (err) {
          $log.error("error in get quizState:", err);
        });
      }
    }

    function isScoredElement(assessment) {
      return assessment.isAssessed && assessment.score !== 'Not Assessed' && !assessment.quizStateId && (assessment.type === 'choice' || assessment.type === 'drag_drop_image' ||
              assessment.type === 'drag_drop_text' || assessment.type === 'fill_in_the_blank' || assessment.type === 'inline_choice' || assessment.type === 'hot_spot' ||
              assessment.type === 'hot_text' || assessment.type === 'multi_part');
    }

    function updateAssessedElementScores(students) {
      var totalAssessmentScores = 0;
      var totalLastAssessmentScores = 0;
      var totalStudents = 0;
      // Loop over all the response data per student
      students.forEach(function(student) {
        var assessedCount = 0;
        var correctCount = 0;
        var correctLastCount = 0;
        // Iterate the assessments tracking the scores
        student.assessments.forEach(function(assessment) {
          // if this item is assessed
          if (isScoredElement(assessment)) {
            assessedCount = assessedCount + 1;
            if (assessment.score === 'correct' || assessment.score === 'partial') {
              correctCount = correctCount + assessment.calcScore;
            }
            if (assessment.lastScore === 'correct' || assessment.lastScore === 'partial') {
              correctLastCount = correctLastCount + assessment.lastCalcScore;
            }
          }
        });
        // Calculate the student average
        if (assessedCount > 0) {
          student.assessedElementScore = Math.round((correctCount / assessedCount) * 100);
          student.assessedLastElementScore = Math.round((correctLastCount / assessedCount) * 100);
          totalAssessmentScores = totalAssessmentScores + student.assessedElementScore;
          totalLastAssessmentScores = totalLastAssessmentScores + student.assessedLastElementScore;
          totalStudents = totalStudents + 1;
        }
      });

      // Compute the experience average
      if (totalStudents > 0) {
        $scope.averageAssessedScore = Math.round(totalAssessmentScores / totalStudents);
        $scope.averageLastAssessedScore = Math.round(totalLastAssessmentScores / totalStudents);
      }
    }

    function getSceneForElement(element_id) {
      var position = $scope.elementPositions[element_id].scene;
      if (position) {
        return position;
      }
      else {
        return 0;
      }
    }

    function spireClassLevelElements(response) {
      if ($scope.elementPositions[response.element_id].type === 'spire_concept_assessment') {
        if ($scope.elementPositions[response.element_id].spire.caCorrect) {
          $scope.elementPositions[response.element_id].spire.caCorrect = $scope.elementPositions[response.element_id].spire.caCorrect + response.score.correct;
        }
        else {
          $scope.elementPositions[response.element_id].spire.caCorrect = response.score.correct;
        }
        $scope.elementPositions[response.element_id].spire.caTotal = response.score.total;
      }
      else if ($scope.elementPositions[response.element_id].type === 'spire_concept_mastery_fluency_drill') {
        if ($scope.elementPositions[response.element_id].spire.cmfdWPM) {
          $scope.elementPositions[response.element_id].spire.cmfdWPM = $scope.elementPositions[response.element_id].spire.cmfdWPM + response.score.cmfdScore;
        }
        else {
          $scope.elementPositions[response.element_id].spire.cmfdWPM = response.score.cmfdScore;
        }
      }
      else if ($scope.elementPositions[response.element_id].type === 'spire_pre_post_level_test') {
        if ($scope.elementPositions[response.element_id].spire.decodableWordScore) {
          $scope.elementPositions[response.element_id].spire.decodableWordScore = $scope.elementPositions[response.element_id].spire.decodableWordScore + response.score.decodableWordScore;
          $scope.elementPositions[response.element_id].spire.decodableSentenceScore = $scope.elementPositions[response.element_id].spire.decodableSentenceScore + response.score.decodableSentenceScore;
          $scope.elementPositions[response.element_id].spire.passageLastWord = $scope.elementPositions[response.element_id].spire.passageLastWord + response.score.passageLastWord;
          $scope.elementPositions[response.element_id].spire.comprehensionScore = $scope.elementPositions[response.element_id].spire.comprehensionScore + response.score.comprehensionScore;
        }
        else {
          $scope.elementPositions[response.element_id].spire.decodableWordScore = response.score.decodableWordScore;
          $scope.elementPositions[response.element_id].spire.decodableSentenceScore = response.score.decodableSentenceScore;
          $scope.elementPositions[response.element_id].spire.passageLastWord = response.score.passageLastWord;
          $scope.elementPositions[response.element_id].spire.comprehensionScore = response.score.comprehensionScore;
        }
      }
      else if ($scope.elementPositions[response.element_id].type === 'spire_mid_level_test') {
        if ($scope.elementPositions[response.element_id].spire.midScores) {
          if (response.score.midScores) {
            response.score.midScores.forEach(function(midScore, index){
              $scope.elementPositions[response.element_id].spire.midScores[index] = $scope.elementPositions[response.element_id].spire.midScores[index] + midScore.score;
            });
          }
        }
        else {
          if (response.score.midScores) {
            $scope.elementPositions[response.element_id].spire.midScores = [];
            response.score.midScores.forEach(function(midScore, index){
              $scope.elementPositions[response.element_id].spire.midScores.push(midScore.score);
            });
          }
        }
      }
    }

    function calculateProgress() {
      ActivityStateListFactory.get({}, {id: experienceId}, function (classActivity) {
        var totalNotStarted = 0;
        var totalFinished = 0;
        var totalProgress = 0;
        var classTime = 0;
        var studentList = [];
        var studentIdList = [];
        var student = null;

        // Put all the correct students from the experience into the local list of students
        for (var allIndex = 0; allIndex < $scope.allStudents.length; ++allIndex) {
          // Grab this student from the list of students in the experience
          student = $scope.allStudents[allIndex];

          // Default the progress and time on screen values in case this student never started
          student.assignment_progress = 0;
          student.time_on_screen = Utils.convertSecondsToDisplay(0);
          student.assessments = [];
          student.quizScore = {firstScore: 0, score: 0, finished: false};

          // The student list should contain all students in the class if this is the teacher or just the current
          // user if this is a student currently logged in
          if (($scope.userIsStudent() && User.getId() == student.user_id) ||
            (!$scope.userIsStudent() && User.getId() != student.user_id)) {
            studentList.push(student);
          }

          // The ID list should contain all activity results EXCEPT for those belonging to the teacher
          if (student.user_id != $scope.experience.teacher.id) {
            studentIdList.push(student.user_id);
          }
        }

        // Loop over the list of the class activity and total for this class
        var activeStudents = 0;
        for (var index = 0; index < classActivity.length; ++index) {
          student = classActivity[index];

          // if this is the teacher then just continue onto the next student
          if (studentIdList.indexOf(student.user_id) == -1)
            continue;
          else
            activeStudents++;

          // Locate the index of this in the total student list
          var studentIndex = 0;
          for (; studentIndex < studentList.length; ++studentIndex) {
            // see if this is the the current student
            if (studentList[studentIndex].user_id == student.user_id)
              break;
          }

          // Assign the individual values this student
          if (studentIndex < studentList.length) {
            // Assign individual values for display
            studentList[studentIndex].assignment_progress = student.assignment_progress * 100;
            studentList[studentIndex].time_on_screen = Utils.convertSecondsToDisplay(student.time_on_screen);
          }

          // Progress of 0 means they haven't started.  Should really not ever get this since we should only see students that have some progress
          if (student.assignment_progress === 0)
            totalNotStarted++;

          // Progress of 1 indicates they have finished
          if (student.assignment_progress == 1)
            totalFinished++;

          // Sum the total student progress
          totalProgress += student.assignment_progress;

          // Calculate the average amount finished
          classTime += student.time_on_screen;
        }

        // Calculates the number of students that haven't started and the number that have completed
        $scope.class_not_started = $scope.allStudents.length - activeStudents + totalNotStarted;
        $scope.class_completed = totalFinished;

        // Calculate the class average percent done
        $scope.class_average_progress = ($scope.allStudents && $scope.allStudents.length !== 0) ? (totalProgress / $scope.allStudents.length) * 100 : 0;

        // Calculate the class average of time spent
        var classAverageTime = activeStudents !== totalNotStarted ? classTime / (activeStudents - totalNotStarted) : 0;
        $scope.class_average_time = Utils.convertSecondsToDisplay(classAverageTime);

        // If there is a quiz then update scores
        updateQuizScores(studentList);

        // Get all the element state data for this experience
        updateStudentElementResponses(experienceId, studentList);

        // Assign the students to the list
        $scope.students = studentList;
      });
    }

    function updateStudentElementResponses(experienceId, studentList) {
      if (studentList.length) {
        ActiveExperience.getResponses(experienceId).then(function (results) {
          // Loop over the results and add a new assessment for each student
          results.forEach(function (response) {
            // Find the index of the student who owns this response.  Possible student was removed from class so just ignore values
            // that don't correspond to current students.  Could also be the dashboard for a student where we only show their results.
            var currentStudent = studentList.find(function (student) {
              return student.user_id === response.user_id;
            });
            if (currentStudent) {
              var titleIndex = null;
              if (response.score && response.score.tao_question_index !== null) {
                titleIndex = response.score.tao_question_index + 1;
              }
              else if ($scope.elementPositions[response.element_id].quizQuestionIndex) {
                titleIndex = $scope.elementPositions[response.element_id].quizQuestionIndex;
              }

              // calculates and returns the calculation result for this users response
              var responseStatus = calculateCorrectness(response, $scope.elementPositions[response.element_id].type);

              // Calculate the correct over total display value
              var correctOverTotal = makeCorrectOverTotal($scope.elementPositions[response.element_id].isAssessed,
                  $scope.elementPositions[response.element_id].type,
                  response, titleIndex);

              // Calculate the score for this element
              var elementScore = calculateScore($scope.elementPositions[response.element_id].isAssessed,
                  $scope.elementPositions[response.element_id].type,
                  response);

              // Find the assessment (if it exists) for this student and element
              var currentStudentElementAssessment = currentStudent.assessments.find(function(assessment) {
                return assessment.element_id === response.element_id;
              });

              // if this is the first assessed score for this student then add it.
              if (!currentStudentElementAssessment) {
                // insert the assessed elements adding the data for each student
                currentStudent.assessments.push({
                  element_id: response.element_id,
                  previousId: currentStudent.assessments.length - 1,
                  nextId: currentStudent.assessments.length + 1,
                  left: calculateResponsePosition(results, response),
                  isAssessed: $scope.elementPositions[response.element_id].isAssessed,
                  score: responseStatus,
                  lastScore: responseStatus,
                  calcScore: elementScore,
                  lastCalcScore: elementScore,
                  responseCount: isQuizResponse(response, $scope.elementPositions[response.element_id].type) ? 0 : 1,
                  correctOverTotal: correctOverTotal,
                  lastCorrectOverTotal: correctOverTotal,
                  type: $scope.elementPositions[response.element_id].type,
                  title: makeAssessmentTitle($scope.elementPositions[response.element_id].title,
                  $scope.elementPositions[response.element_id].isAssessed,
                  $scope.elementPositions[response.element_id].type,
                  response, titleIndex),
                  link: "/experience/" + $scope.experience.id + "/activity/scene/" + (getSceneForElement(response.element_id) + 1),
                  anchor: response.element_id,
                  quizStateId: response.score ? response.score.user_quiz_state_id : 0
                });

                // Adjust class elements for spire types
                spireClassLevelElements(response);

                // Increment the response count for overall element and correct
                $scope.elementPositions[response.element_id].totalResponses = $scope.elementPositions[response.element_id].totalResponses + 1;
                if (responseStatus === "correct") {
                  $scope.elementPositions[response.element_id].correctResponses = $scope.elementPositions[response.element_id].correctResponses + 1;
                  $scope.elementPositions[response.element_id].correctLastResponses = $scope.elementPositions[response.element_id].correctLastResponses + 1;
                }
              }
              else {
                // Increment/decrement the total number of correct last responses based on the status change from last to next
                if (currentStudentElementAssessment.lastScore === 'correct' && responseStatus !== 'correct') {
                  $scope.elementPositions[response.element_id].correctLastResponses = $scope.elementPositions[response.element_id].correctLastResponses - 1;
                  if (response.score && currentStudentElementAssessment.quizStateId && currentStudentElementAssessment.quizStateId === response.score.user_quiz_state_id) {
                    $scope.elementPositions[response.element_id].correctResponses = $scope.elementPositions[response.element_id].correctResponses - 1;
                  }
                }
                else if (currentStudentElementAssessment.lastScore !== 'correct' && responseStatus === 'correct') {
                  $scope.elementPositions[response.element_id].correctLastResponses = $scope.elementPositions[response.element_id].correctLastResponses + 1;
                  if (response.score && currentStudentElementAssessment.quizStateId && currentStudentElementAssessment.quizStateId === response.score.user_quiz_state_id) {
                    $scope.elementPositions[response.element_id].correctResponses = $scope.elementPositions[response.element_id].correctResponses + 1;
                  }
                }

                // Update the last score for the existing assessment
                if (currentStudent.status === 'retake') {
                  currentStudentElementAssessment.lastScore = "retake";
                  currentStudentElementAssessment.lastCalcScore = 0;
                } else {
                  currentStudentElementAssessment.lastScore = responseStatus;
                }
                if (response.score && ((currentStudentElementAssessment.quizStateId && currentStudentElementAssessment.quizStateId === response.score.user_quiz_state_id) ||
                    (response.score.user_quiz_state_id && currentStudentElementAssessment.quizStateId === 0))) {
                  currentStudentElementAssessment.score = responseStatus;
                }
                if (!isQuizResponse(response, $scope.elementPositions[response.element_id].type)) {
                  currentStudentElementAssessment.responseCount = currentStudentElementAssessment.responseCount + 1;
                }
                currentStudentElementAssessment.lastCorrectOverTotal = correctOverTotal;
                if (response.score && ((currentStudentElementAssessment.quizStateId && currentStudentElementAssessment.quizStateId === response.score.user_quiz_state_id) ||
                    (response.score.user_quiz_state_id && currentStudentElementAssessment.quizStateId === 0))) {
                  currentStudentElementAssessment.correctOverTotal = correctOverTotal;
                }
                currentStudentElementAssessment.lastCalcScore = elementScore;
                if (response.score && ((currentStudentElementAssessment.quizStateId && currentStudentElementAssessment.quizStateId === response.score.user_quiz_state_id) ||
                    (response.score.user_quiz_state_id && currentStudentElementAssessment.quizStateId === 0))) {
                  currentStudentElementAssessment.calcScore = elementScore;
                }
              }
            }
          });

          // if there are any assessed elements (other than quiz) then calculate scores
          if ($scope.hasAssessedElements) {
            updateAssessedElementScores($scope.students);
          }
        });
      }
    }

    $scope.changeDashboard = function (page) {
      ActiveExperienceService.setDashboard(page);
      $location.path('/experience/' + $scope.experience.id + '/dashboard/' + page);
    };

    $scope.onShowResponses = function() {
      // See if the teacher has permission to see responses
      if ($scope.userIsTeacher() && !ActiveExperience.hasPermission($scope.experience.id, PermissionConsts.ui_curriculum_show_dashboard_responses)) {
        ModalService.show({
          feature: 'ui_curriculum_show_dashboard_responses',
          template: require('../../views/partials/modals/subscriptionFeatureModal.jade'),
          backdrop: 'static',
        });
      }
    };

    $scope.setShowResponses = function(show) {
      $scope.showResponses = show;
    };

    $scope.showStudentAssessment = function(assessment, value) {
      return ($scope.showResponses.id === 2 && assessment.score === value) || ($scope.showResponses.id === 3 && assessment.lastScore === value);
    };

    // Handle element update notifications
    ElementRealtimeService.on(ElementRealtimeService.EVENTS.XPActivityStateChangedNotification, activityChangedNotificationHandler);
    $scope.$on('$destroy', function () {
      ElementRealtimeService.removeListener(ElementRealtimeService.EVENTS.XPActivityStateChangedNotification, activityChangedNotificationHandler);
    });

    // $scope.$on('onfocus', function (e, args) {
    //   EventAuditAnalyticsService.save({
    //     url: args.url,
    //     user_id: args.user_id,
    //     experience_id: $scope.experience.id,
    //     scene_template_id: $scope.currentSceneId,
    //     event_name: "Dashboard Browser Focus Start"
    //   }).$promise.then(function () {
    //     $log.info("Dashboard Browser Focus Start onfocus ", JSON.stringify(args));
    //   });
    //   e.preventDefault();
    // });

    // $scope.$on('onblur', function (e, args) {
    //   EventAuditAnalyticsService.save({
    //     url: args.url,
    //     user_id: args.user_id,
    //     experience_id: $scope.experience.id,
    //     scene_template_id: $scope.currentSceneId,
    //     event_name: "Dashboard Browser Focus End"
    //   }).$promise.then(function () {
    //     $log.info("Dashboard Browser Focus End onblur ", JSON.stringify(args));
    //   });
    //   e.preventDefault();
    // });

    function activityChangedNotificationHandler(e) {
      var state = e.detail;
      $log.debug("Received activity update: " + JSON.stringify(state));
      // make sure this update is for the current experience
      if (!$scope.experience || $scope.experience.id != state.record.experience_id) {
        return;
      }
      // Find the user that matches this state
      var student = $scope.students.find(function (currentStudent) {
        return currentStudent.user_id === state.record.user_id;
      });
      // See if the element change is for this student
      if (student) {
        // If the progress is complete then increment finished
        if (state.record.assignment_progress === 1 && student.assignment_progress < 100) {
          $scope.class_completed = $scope.class_completed + 1;
        }
        // if the progress when from 0 to something then decrement the not started
        if (state.record.assignment_progress > 0 && student.assignment_progress === 0) {
          $scope.class_not_started = $scope.class_not_started - 1;
        }
        student.assignment_progress = state.record.assignment_progress * 100;

        // re-calculate overall progress based on students new progress
        var totalProgress = 0;
        $scope.students.forEach(function(st) {
          totalProgress = totalProgress + st.assignment_progress;
       });

        // Calculate the class average percent done
        $scope.class_average_progress = ($scope.allStudents && $scope.allStudents.length !== 0) ? (totalProgress / $scope.allStudents.length) : 0;
      }
    }

    ElementRealtimeService.on(ElementRealtimeService.EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
    $scope.$on('$destroy', function () {
      ElementRealtimeService.removeListener(ElementRealtimeService.EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
    });

    function stateChangedNotificationHandler(e) {
      var state = e.detail;
      var studentsNeedingUpdate = [];
      // Pull together list of students from the group that need to be updated based on this state change
      if (state.record.small_gid) {
        studentsNeedingUpdate = $scope.students.filter(function (grpStudent) {
          return grpStudent.small_group === state.record.small_gid;
        });
      }
      else {
        // standard scenario of no groups is to update just the specified user
        studentsNeedingUpdate.push($scope.students.find(function (currentStudent) {
          return currentStudent.user_id === state.record.user_id;
        }));
      }

      // Loop over all the students (single or in the group) and update their data
      studentsNeedingUpdate.forEach(function (updateStudent) {
        // First, Get the new score for this element/user if it is assessed
        ScoreService.get({
          element_id: state.record.element_id,
          experience_id: state.record.experience_id,
          user_id: updateStudent.user_id
        }).$promise
          .then(function (responses) {
            var isQuizElement = false;
            // Iterate the list of responses
            responses.forEach(function (response) {
              // find the single student that matches the student from the response
              var student = $scope.students.find(function (currentStudent) {
                return currentStudent.user_id === response.user_id;
              });
              // See if the element change is for this student
              if (student) {
                // create the correct index for either the tao or the quiz item
                var titleIndex = null;
                if (response.score && response.score.tao_question_index !== null) {
                  titleIndex = response.score.tao_question_index + 1;
                }
                else if ($scope.elementPositions[response.element_id].quizQuestionIndex) {
                  titleIndex = $scope.elementPositions[response.element_id].quizQuestionIndex;
                }

                var responseStatus = calculateCorrectness(response, $scope.elementPositions[response.element_id].type);

                // Calculate the correct over total display value
                var correctOverTotal = makeCorrectOverTotal($scope.elementPositions[response.element_id].isAssessed,
                    $scope.elementPositions[response.element_id].type,
                    response, titleIndex);

                // Calculate the score for this element
                var elementScore = calculateScore($scope.elementPositions[response.element_id].isAssessed,
                    $scope.elementPositions[response.element_id].type,
                    response);

                // Filter to find the assessment that matches for this student
                var assessment = student.assessments.find(function (currentAssessment) {
                  return currentAssessment.element_id === response.element_id
                });

                if (assessment) {
                  assessment.title = makeAssessmentTitle($scope.elementPositions[response.element_id].title,
                    $scope.elementPositions[response.element_id].isAssessed,
                    $scope.elementPositions[response.element_id].type,
                    response, titleIndex);

                  // Increment/decrement the total number of correct last responses based on the status change from last to next
                  if (assessment.lastScore === 'correct' && responseStatus !== 'correct') {
                    $scope.elementPositions[response.element_id].correctLastResponses = $scope.elementPositions[response.element_id].correctLastResponses - 1;
                    if (response.score && assessment.quizStateId && assessment.quizStateId === response.score.user_quiz_state_id) {
                      $scope.elementPositions[response.element_id].correctResponses = $scope.elementPositions[response.element_id].correctResponses - 1;
                    }
                  }
                  else if (assessment.lastScore !== 'correct' && responseStatus === 'correct') {
                    $scope.elementPositions[response.element_id].correctLastResponses = $scope.elementPositions[response.element_id].correctLastResponses + 1;
                    if (response.score && assessment.quizStateId && assessment.quizStateId === response.score.user_quiz_state_id) {
                      $scope.elementPositions[response.element_id].correctResponses = $scope.elementPositions[response.element_id].correctResponses + 1;
                    }
                  }

                  assessment.lastScore = responseStatus;
                  if (response.score && ((assessment.quizStateId && assessment.quizStateId === response.score.user_quiz_state_id) ||
                      (response.score.user_quiz_state_id && assessment.quizStateId === 0))) {
                    assessment.score = responseStatus;
                  }
                  if (!isQuizResponse(response, $scope.elementPositions[response.element_id].type)) {
                    assessment.responseCount = responses.length;
                  }
                  assessment.lastCorrectOverTotal = correctOverTotal;
                  if (response.score && ((assessment.quizStateId && assessment.quizStateId === response.score.user_quiz_state_id) ||
                      (response.score.user_quiz_state_id && assessment.quizStateId === 0))) {
                    assessment.correctOverTotal = correctOverTotal;
                  }
                  assessment.lastCalcScore = elementScore;
                  if (response.score && ((assessment.quizStateId && assessment.quizStateId === response.score.user_quiz_state_id) ||
                      (response.score.user_quiz_state_id && assessment.quizStateId === 0))) {
                    assessment.calcScore = elementScore;
                  }
                }
                else {
                  student.assessments.push({
                    element_id: response.element_id,
                    previousId: student.assessments.length - 1,
                    nextId: student.assessments.length + 1,
                    left: calculateResponsePosition(responses, response),
                    isAssessed: $scope.elementPositions[response.element_id].isAssessed,
                    score: calculateCorrectness(response, $scope.elementPositions[response.element_id].type),
                    lastScore: responseStatus,
                    calcScore: elementScore,
                    lastCalcScore: elementScore,
                    responseCount: isQuizResponse(response, $scope.elementPositions[response.element_id].type) ? 0 : 1,
                    correctOverTotal: correctOverTotal,
                    lastCorrectOverTotal: correctOverTotal,
                    type: $scope.elementPositions[response.element_id].type,
                    title: makeAssessmentTitle($scope.elementPositions[response.element_id].title,
                      $scope.elementPositions[response.element_id].isAssessed,
                      $scope.elementPositions[response.element_id].type,
                      response, titleIndex),
                    link: "/experience/" + $scope.experience.id + "/activity/scene/" + (getSceneForElement(response.element_id) + 1),
                    anchor: response.element_id,
                    quizStateId: response.score ? response.score.user_quiz_state_id : 0
                  });

                  // Increment the response count for overall element and correct
                  $scope.elementPositions[response.element_id].totalResponses = $scope.elementPositions[response.element_id].totalResponses + 1;
                  if (responseStatus === "correct") {
                    $scope.elementPositions[response.element_id].correctResponses = $scope.elementPositions[response.element_id].correctResponses + 1;
                    $scope.elementPositions[response.element_id].correctLastResponses = $scope.elementPositions[response.element_id].correctLastResponses + 1;
                  }

                }
                // if this is a quiz choice element then we need to add its results into the calculation
                if (response.score && response.score.user_quiz_state_id) {
                  isQuizElement = true;
                }
              }
            });
            // if there are any assessed elements (other than quiz) then calculate scores
            if ($scope.hasAssessedElements) {
              // Execute update on digest since it is via notification
              $timeout(function() { updateAssessedElementScores($scope.students); }, 500);
            }
            // if there were any quiz choices updated then update the quiz score for this student
            if (isQuizElement) {
              // Execute update on digest since it is via notification
              $timeout(function() { updateQuizScores(studentsNeedingUpdate); }, 500);
            }
          });
      });
    }

    $scope.status = {
      isopen: false
    };

    function isStudentInSmallGroup(studentId, groupIndex) {
      // Grab the current values
      var group = $scope.studentGroups[groupIndex];

      // Check this group to see if the student exists in it
      return group.indexOf(studentId) > -1;
    }

    $scope.studentInSmallGroup = function (studentId, groupIndex) {
      return isStudentInSmallGroup(studentId, groupIndex);
    };

    $scope.getHiliteClass = function (studentId, groupIndex) {
      if (isStudentInSmallGroup(studentId, groupIndex))
        return "small-group-indicator-" + groupIndex;

      return "";
    };

    $scope.onSortByName = function () {
      $scope.students = ActiveExperience.sortStudents($scope.students, ActiveExperience.SORTBY.Name);
    };

    $scope.onSortByGroup = function () {
      $scope.students = ActiveExperience.sortStudents($scope.students, ActiveExperience.SORTBY.Group);
    };

    $scope.onSortByProgress = function () {
      $scope.students = ActiveExperience.sortStudents($scope.students, ActiveExperience.SORTBY.Progress);
    };

    $scope.calculateProgessWidth = function (sceneLength, studentProgress) {
      if (sceneLength && studentProgress) {
        if (studentProgress > sceneLength.left && studentProgress < sceneLength.left + sceneLength.width)
          return studentProgress - sceneLength.left + "%";
      }

      return sceneLength.width + "%";
    };
  }]);


