'use strict';
// fix for angular's bug with infinite digest loop on empty # in url
// https://github.com/angular/angular.js/issues/9635
function removeTrailingHashInUrl() {
  var trailingHash = /\#$/.test(window.location.href);
  if (trailingHash) {
    if ("pushState" in window.history) {
      history.pushState("", document.title, window.location.pathname + window.location.search);
    } else {
      window.location.href = window.location.href.replace(/\#$/, '');
    }
  }
}
removeTrailingHashInUrl();
