'use strict';
//
/* Controllers */
//

clientControllers.controller('UserSettingsCtrl', ['$http', '$scope', '$rootScope', '$routeParams', '$window', 'UserPasswordSettingsFactory',
  'UserInfoDefaultFactory', '$location', 'User',
  function ($http, $scope, $rootScope, $routeParams, $window, UserPasswordSettingsFactory,
            UserInfoDefaultFactory, $location, User) {

    //Instantiate an object to store your scope data in (Best Practices)
    $scope.standard = {id: 0};
    $scope.isTeacher = User.isTeacher();

    // Get current User Id
    var currentUserId = User.getId();
    // Populate screen
    UserInfoDefaultFactory.get({}, {'id': currentUserId}, function (response) {
      // Assign the response INSIDE the callback
      $scope.standard = response;
    });

    $scope.getUserEmailById = function () {
      return $scope.standard.email;
    };

  }]);
