'use strict';
(function () {

  // param: selectedElement - DOM reference to the element to grab, for example document.querySelector('table')
  // param: format - 'letter' or [heightInPixels, widthInPixels]
  // param: orientation - 'landscape' or 'portrait'
  // param: filename - name of the saved file
  // returns Promise
  function downloadPDF(selectedElement, format, orientation, filename, customPageBreak) {
    var pagebreak = {mode: ['avoid-all', 'css', 'legacy']};
    if (customPageBreak){
      pagebreak = customPageBreak;
    }
    return window.html2pdf()
      .from(selectedElement)
      .set({
        image: {type: 'jpeg', quality: 0.98},
        html2canvas: {scale: 2, removeContainer: true},
        jsPDF: {unit: 'px', format: format, orientation: orientation},
        pagebreak: pagebreak
      })
      .save(filename);
  }

  function createReportToPDF(ctrl) {
    return function (elementId, filename, scrollingId, format, orientation) {
      // This value allows the HTML to alter its appearance during the printing phase.  So any differences between the 
      // onscreen and print version can be shown/hidden using this variable.
      ctrl.isPrinting = true;
      var selectedElement = document.getElementById(elementId);
      
      var interval = window.setInterval(function () {
        if (ctrl.isPrinting) {
          if (!format) {
            format = 'letter';
          }
          if (!orientation) {
            orientation = 'portrait';
          }
          // if the table is too wide - use custom size for the page, accommodating for the margin
          var originalBodyStyle = document.querySelector('body').style.overflowY;
          document.querySelector('body').style.overflowY = 'auto';
          var originaLayoutStyle = document.querySelector('#layout').style.overflowY;
          document.querySelector('#layout').style.overflowY = 'auto';
          
          // if there is any scrolling div for on screen then we need to disable this so the entire page will be printed
          var scrollingElement = document.getElementById(scrollingId);
          var scrollingDivInitialOverflow = false;
          var scrollingDivInitialHeight = false;
          if (scrollingElement) {
            scrollingDivInitialOverflow = scrollingElement.style.overflowY;
            scrollingElement.style.overflowY = 'auto';
            scrollingDivInitialHeight = scrollingElement.style.height;
            scrollingElement.style.height = 'auto';
          }

          downloadPDF(selectedElement, format, orientation, filename)
            .then(function () {
              ctrl.isPrinting = false;

              document.querySelector('body').style.overflowY = originalBodyStyle;
              document.querySelector('#layout').style.overflowY = originaLayoutStyle;
              if (scrollingElement) {
                scrollingElement.style.overflowY = scrollingDivInitialOverflow;
                scrollingElement.style.height = scrollingDivInitialHeight;
              }
            });
        }
        window.clearInterval(interval);
      }, 100);
    };
  }

  function ngReport(ctrl, showHideVariable) {
    return function downloadNGReportPDF(elementId, filename) {
      ctrl[showHideVariable] = false;
      ctrl.isPrinting = true;
      var selectedElement = document.getElementById(elementId);

      var tableElement = 'table';
      var originalHeightOfTheTable = document.querySelector(tableElement).offsetHeight;
      var orignalPage = ctrl.tableParams.page();
      var originalCount = ctrl.tableParams.count();

      ctrl.tableParams.count(ctrl.tableParams.total());

      var interval = window.setInterval(function () {
        var tableGrew = document.querySelector(tableElement).offsetHeight === originalHeightOfTheTable;
        // check that it has pages and the size of the table grew, at the moment it's the only working way to validate the change
        if (ctrl.tableParams.generatePagesArray().length && tableGrew) {
          return;
        }
        if (ctrl.isPrinting) {
          var tableWidth = document.querySelector(tableElement).offsetWidth;
          var format = 'letter';
          var orientation = 'portrait';
          // if the table is too wide - use custom size for the page, accommodating for the margin
          if (tableWidth > 612) {
            format = [1250, tableWidth + 30];
            orientation = 'landscape';
          }
          var originalBodyStyle = document.querySelector('body').style.overflowY;
          document.querySelector('body').style.overflowY = 'auto';
          var originaLayoutStyle = document.querySelector('#layout').style.overflowY;
          document.querySelector('#layout').style.overflowY = 'auto';
          var originalReportOverflow = document.querySelector('.xp-report-container').style.overflowY;
          document.querySelector('.xp-report-container').style.overflowY = 'auto';
          var originalReportHeight = document.querySelector('.xp-report-container').style.height;
          document.querySelector('.xp-report-container').style.height = 'auto';

          var originalElementOverflow = selectedElement.style.overflowY;
          selectedElement.style.overflowY = 'auto';

          downloadPDF(selectedElement, format, orientation, filename, {mode: ['css', 'legacy'], avoid: ['td','tr']})
            .then(function () {
              ctrl.isPrinting = false;
              ctrl.tableParams.count(originalCount);
              ctrl.tableParams.page(orignalPage);
              ctrl[showHideVariable] = true;

              document.querySelector('body').style.overflowY = originalBodyStyle;
              document.querySelector('#layout').style.overflowY = originaLayoutStyle;
              document.querySelector('.xp-report-container').style.overflowY = originalReportOverflow;
              document.querySelector('.xp-report-container').style.height = originalReportHeight;
              
              selectedElement.style.overflowY = originalElementOverflow;
            });
        }
        window.clearInterval(interval);
      }, 100);
    };
  }
  angular.module('client.services').factory('reportToPDF', ['$log',
    function ($log) {
      return {
        createReportToPDF: createReportToPDF,
        ngReport: ngReport
      }
    }]);
}());
