'use strict';

angular.module('client.services').factory('reportLabels', ['$log',
  function ($log) {
    return function createLabelGetter(scopeVarName, scope) {
      return function getLabel(key, defaultValue) {
        return (scope[scopeVarName] || {})[key] || defaultValue;
      }
    };
  }]);

