'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = [];

  function controller () {
    var ctrl = this;
    
    ctrl.formatScore = function(score) {
      if (score == null) {
        return "-";
      }
      return score + "%";
    };
    
    ctrl.$onChanges = function(changes) {
      // Default current student to the first
      if (changes.units && changes.units.currentValue && !ctrl.selectedUnit) {
        ctrl.selectedUnit = changes.units.currentValue[0];
      }
    };
    
    ctrl.selectUnit = function(unit) {
      ctrl.selectedUnit = unit;
      if (ctrl.refresh) {
        ctrl.refresh({unit: unit});
      }
    };
  }

  module.component('xpStudentLearningHeader', {
    template: require('./standardsHeader.jade'),
    controller: controller,
    bindings: {
      units: '<',
      standards: '<',
      refresh: '&'
    }
  });

})();