'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log', 'VideoStreamingService', 'ExperienceRealtimeService', '$timeout'];

  function controller ($scope, $log, VideoStreamingService, ExperienceRealtimeService, $timeout) {
    var ctrl = this;

    ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPStudentRaisedHandNotification,
        studentRaisedHand);

    function studentRaisedHand (e) {
      var currentStudent = ctrl.students.find(function (student) {
        return student.user_id == e.detail.record.studentId;
      });
      if (currentStudent) {
        currentStudent.raisedHand = e.detail.record.handIsUp;
      }
    }

    ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPStudentAudioResponseNotification,
        studentAudioResponse);

    function studentAudioResponse (e) {
      setStudentAudioStatus(e.detail.record.studentId, e.detail.record.status);
    }

    function setStudentAudioStatus(studentId, status) {
      var currentStudent = ctrl.students.find(function (student) {
        return student.user_id == studentId;
      });
      if (currentStudent) {
        var oldStatus = currentStudent.audioStatus;
        currentStudent.audioStatus = status;
        if (currentStudent.audioStatus == VideoStreamingService.AUDIOSTATE.OFFLINE) {
          currentStudent.audioStreamEnabled = false;
        } else if (currentStudent.audioStatus == VideoStreamingService.AUDIOSTATE.DECLINED) {
          $timeout(function () {
            currentStudent.audioStreamEnabled = false;
            currentStudent.audioStatus = VideoStreamingService.AUDIOSTATE.OFFLINE;
          }, 5000);
        } else if (oldStatus == VideoStreamingService.AUDIOSTATE.PENDING && currentStudent.audioStatus == VideoStreamingService.AUDIOSTATE.SPEAKING) {
          currentStudent.audioStatus = VideoStreamingService.AUDIOSTATE.ACCEPTED;
          $timeout(function () {
            currentStudent.audioStatus = VideoStreamingService.AUDIOSTATE.SPEAKING;
          }, 5000);
        }
      }
    }

    $scope.$on('$destroy', function () {
      ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPStudentRaisedHandNotification,
          studentRaisedHand);
      ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPStudentAudioResponseNotification,
          studentAudioResponse);
    });

    ctrl.toggleStudentAudio = function(student) {
      student.audioStreamEnabled = !student.audioStreamEnabled;
      return VideoStreamingService.callOnStudent(student.user_id, student.audioStreamEnabled);
    };

    ctrl.getStudentAudioStatus = function(student) {
      if (student.audioStatus) {
        switch (student.audioStatus) {
        case VideoStreamingService.AUDIOSTATE.PENDING:
          return 'pending';
        case VideoStreamingService.AUDIOSTATE.ACCEPTED:
          return 'accepted';
        case VideoStreamingService.AUDIOSTATE.DECLINED:
          return 'declined';
        case VideoStreamingService.AUDIOSTATE.MUTED:
          return 'muted';
        }
      }
      return "";
    };
  }

  module.component('participatingStudents', {
    template: require('./participatingStudents.jade'),
    controller: controller,
    bindings: {
      students: '<'
    }
  });
})();
