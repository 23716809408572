'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log', '$rootScope', '$route', '$location', '$q'];

function controller ($scope, $log, $rootScope, $route, $location, $q) {
  var ctrl = this;

}

module.component('xpSchool', {
  template: require('./school.jade'),
  controller: controller,
});

})();
