'use strict';
angular.module('xp-element-link', ['angularWidget', 'client.services'])
  .controller('clientLinkElementCtrl', ['$scope', 'widgetConfig', '$http', '$timeout', function ($scope, widgetConfig, $http, $timeout) {
	   
		// The element is the block of data provided by the source xml
		$scope.options = widgetConfig.getOptions($scope);
		$scope.href = undefined;
	  
		var parseElement = function()
		{
			if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes)
				return;
			
			$scope.options.element.config.attributes.forEach(function(attribute)
			{
				switch (attribute.name)
				{
				case "url" : 
					$scope.href = attribute.value;
				break;
				}
			});

			$timeout(function() {
				// Notify the widget that were are done loading the data
				widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
			});
		};
		
		$scope.$watch('options', parseElement, true);

  }]);