'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('UserCourses', ['$q', 'User', 'CourseFactory', '$log', '$rootScope', 'UserDefaultInfo',
    function ($q, User, CourseFactory, $log, $rootScope, UserDefaultInfo) {
      var cachedCourses = [];

      function reloadCourses() {
        if (!User.getId()) {
          return $q.reject("No User");
        }

        var params = {};
        var promise;
        if (User.getSystemRole() == User.ROLE.TEACHER) {
          params.teacher_user_id = User.getId();
          promise = $q.when();
        }

        if (User.getSystemRole() == User.ROLE.STUDENT) {
          promise = UserDefaultInfo.getUserInfo().then(function (userInfo) {
            params.student_username = userInfo.username;
          });
        }

        if (!promise){
          return false;
        }

        return promise.then(function () {
          return CourseFactory.list(params).then(function (response) {
            return response || [];
          });
        });
      }

      function updateCachedCourses(update) {
        var cid = null;
        if (update && update.detail && update.detail.record && update.detail.record.cid) {
          cid = update.detail.record.cid;
        }

        // This resets/clears the current course so it will be reloaded on the next request
        CourseFactory.setCurrentCourse(cid);

        // Now reload so the new class is correctly downloaded
        return reloadCourses().then(function (courses) {
          cachedCourses = courses;
          return courses;
        });
      }

      function clearCachedCourses() {
        cachedCourses = [];
      }

      function getCourses() {
        if (cachedCourses.length > 0) {
          return $q.when(cachedCourses);
        }
        else {
          return updateCachedCourses();
        }
      }

      return {
        getCourses: getCourses,
        updateCachedCourses: updateCachedCourses,
        clearCachedCourses: clearCachedCourses
      }
    }]);

})();

