'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('SubscriptionMode', ['$rootScope', '$q', '$location', '$log', '$routeParams', 'User', 'UserManagedSubscriptions',
    'userSubscriptionPermissions', 'subscriptionReports', 'ReportNavigation',
    function ($rootScope, $q, $location, $log, $routeParams, User, UserManagedSubscriptions,
              userSubscriptionPermissions, subscriptionReports, ReportNavigation) {

      // Track the current subscription
      var inSubscriptionMode = false;

      // test to see if we are in subscription mode
      function isActiveMode() {
        return inSubscriptionMode;
      }

      var remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
        // If the current route is a class route then set correctly
        if (current && current.$$route && current.pathParams && current.pathParams.subscriptionId && !current.pathParams.districtId && !current.pathParams.schoolId) {
          inSubscriptionMode = true;
          userSubscriptionPermissions.setUserSubscriptionPermissions(User.getId(), current.pathParams.subscriptionId);
        }
        else {
          inSubscriptionMode = false;
          userSubscriptionPermissions.clearPermissions();
        }
      });

      // Get the current subscription from the URL
      function currentSubscriptionId() {
        return $routeParams.subscriptionId ? parseInt($routeParams.subscriptionId, 10) : undefined;
      }

      // Get the current report from the URL
      function currentReportId() {
        return $routeParams.reportId ? $routeParams.reportId : undefined;
      }

      // return the title when we are in class mode
      function getTitle() {
        var d = $q.defer();
        UserManagedSubscriptions.get(currentSubscriptionId()).then(function (subscription) {
          d.resolve(subscription.name);
        })
          .catch(function (error) {
            $log.error("error in getting user managed subscription:", error);
            d.reject();
          });
        return d.promise;
      }

      function goToSubscription(subscriptionId) {
        if (!parseInt(subscriptionId, 10)) {
          $log.debug('Invalid subscription subscriptionId. Aborting navigation to subscription.');
          return;
        }
        // Navigate to the default class view for this class
        $location.path('/subscription/' + subscriptionId);
      }

      function navigateToDefaultSubscription() {
        var d = $q.defer();
        UserManagedSubscriptions.getDefaultSubscription().then(function (subscription) {
          if (subscription) {
            goToSubscription(subscription.subscription_id);
            d.resolve(true);
          }
          else
            d.resolve(false);
        });
        return d.promise;
      }

      function navigateToExperiences() {
        $location.path('/subscription/' + currentSubscriptionId());
      }

      function navigateToReports() {
        $location.path('/subscription/' + currentSubscriptionId() + '/reports');
      }

      function navigateToPack() {
        $location.path('/subscription/' + currentSubscriptionId() + '/class-pack');
      }

      function navigateToLicensePack() {
        $location.path('/subscription/' + currentSubscriptionId() + '/license-pack');
      }

      function getReports() {
        return subscriptionReports.getReports(currentSubscriptionId());
      }

      function getDistrictReports(districtId) {
        return subscriptionReports.getDistrictReports(currentSubscriptionId(), districtId);
      }

      function getSchoolReports(schoolId) {
        return subscriptionReports.getSchoolReports(currentSubscriptionId(), schoolId);
      }

      function getReportData(filter) {
        return subscriptionReports.getReportData(currentSubscriptionId(), currentReportId(), filter);
      }

      function showReport(reportId) {
        ReportNavigation.navigateToReport(reportId, '/subscription/' + currentSubscriptionId() + '/report/' + reportId,
          {}, $location.path());
      }

      function showDistrictReport(subscriptionId, reportId) {
        ReportNavigation.navigateToReport(reportId, '/subscription/' + subscriptionId + '/report/' + reportId,
          {}, $location.path());
      }

      function resetPermissions() {
        userSubscriptionPermissions.resetPermissions(User.getId(), currentSubscriptionId());
      }

      return {
        isActiveMode: isActiveMode,
        getTitle: getTitle,
        goToSubscription: goToSubscription,
        navigateToDefaultSubscription: navigateToDefaultSubscription,
        getSubscriptionId: currentSubscriptionId,
        navigateToExperiences: navigateToExperiences,
        navigateToReports: navigateToReports,
        navigateToPack: navigateToPack,
        navigateToLicensePack: navigateToLicensePack,
        getReports: getReports,
        getDistrictReports: getDistrictReports,
        getSchoolReports: getSchoolReports,
        getReportData: getReportData,
        showReport: showReport,
        showDistrictReport: showDistrictReport,
        resetPermissions: resetPermissions
      };

    }]);

})();
