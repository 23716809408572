'use strict';

angular.module('client.services').factory('ChangeStudentPassword', ['UserPasswordSettingsFactory', 'ModalService',
  function (UserPasswordSettingsFactory, ModalService) {

    function changePassword(student) {
      // If the student is a google user then student should change it themself
      if (student.google_user) {
        ModalService.show(
          {
            title: 'Change Password',
            message: 'The student signed up for Exploros using a Google account. Google passwords cannot be changed via Exploros. ' +
            'Please have the student log into Google to reset their password.',
            buttons: [
              {
                title: 'Ok',
                click: '$hide()'
              }
            ]
          }
        );
      }
      else if (student.email && student.email.length > 0) {
        ModalService.show(
          {
            title: 'Change Password',
            message: 'The student signed up for Exploros using an email and can reset the password by clicking "Reset it" in the log in page. ' +
            'A temporary password will be sent to their email along with instructions on how to change the password once they\'ve logged in.',
            buttons: [
              {
                title: 'Ok',
                click: '$hide()'
              }
            ]
          }
        );
      }
      else {
        var config = {
          title: 'Change Student Password',
          backdrop: 'static',
          template: require('../../../views/partials/classes/student.jade'),
          student: student,
          cancel: function (scope, student) {
            scope.modal.hide();
          },
          save: function (scope, student) {
            if (!student.newpassword || student.newpassword.length < 6)
              student.invalidPwd = "Password must include at least 6 characters.";
            else {
              UserPasswordSettingsFactory.update({
                'id': student.id,
                'password': student.newpassword
              }, function (result) {
                if (result.error)
                  student.invalidPwd = result.error.description;
                else
                  scope.modal.hide();
              });
            }
          }
        };

        // Show the current student dialog
        ModalService.show(config);
      }
    }

    return {
      changePassword: changePassword
    };

  }]);
