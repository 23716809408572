'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['CourseFactory', 'classIdService', 'xpAlert', 'UserCourses', 'rosterService',
                        'userClassPermissions', 'PermissionConsts'];

  function controller(CourseFactory, classIdService, xpAlert, UserCourses, rosterService, userClassPermissions, PermissionConsts) {
    var ctrl = this;

    ctrl.currentClassId = classIdService();
    CourseFactory.setCurrentCourse(ctrl.currentClassId);

    ctrl.allStudents = [];
    ctrl.students = [];
    ctrl.campusStudents = [];
    ctrl.allowRostering = !userClassPermissions.hasPermission(PermissionConsts.ui_disable_class_rostering);

    getRoster(false);

    function getRoster(refresh) {
      rosterService.getAllRosters(ctrl.currentClassId, refresh).then(function(rosters) {
        ctrl.students = rosters.classRoster;
        ctrl.allStudents = rosters.teacherRoster;
        ctrl.campusStudents = rosters.campusRoster;
      });
    }

    ctrl.addToClass = function(user) {
      // Add the selected students to the class
      CourseFactory.addStudents({cid: ctrl.currentClassId, students: angular.toJson([user])}).then(function(result){
        // if this returns an error then report it
        if (result && result.isError) {
          xpAlert.error("The student can't be added because you would exceed your " + result.subscriptionName + " seat limit by " + result.exceeded + ".", true);
        }
        else if (result && result.error) {
          CourseFactory.maxStudentsMsg().then(function(msg){
            xpAlert.error(msg, true);
          });
        } else {
          // Refresh the list of students in this class
          refreshClassStudents();
        }
      });
    };


    function refreshClassStudents() {
      getRoster(true);
    }
  }

  module.component('xpCampusRoster', {
    template: require('./campus.jade'),
    controller: controller,
  });

})();