'use strict';

(function () {

  var module = angular.module('client.components');

  controller.$inject = ['$location', 'ActiveMode', 'NgTableParams', 'reportLabels', 'ReportingSchoolYearsService',
                        'noTypeReportsList', '$log', 'ReportNavigation', 'User', 'UserInfoDefaultFactory', 'teksService',
                        'reportToCSV', 'ExperienceNavigator'];

  function controller($location, ActiveMode, NgTableParams, reportLabels, ReportingSchoolYearsService,
                      noTypeReportsList, $log, ReportNavigation, User, UserInfoDefaultFactory, teksService,
                      reportToCSV, ExperienceNavigator) {

    var ctrl = this;

    var staticColumns = ['studentName'];

    ctrl.inited = false;
    ctrl.isInDrilldown = false;
    ctrl.isTIAReport = true;
    ctrl.summaries = {};

    ctrl.adminReport = {};
    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.year = ctrl.filterYears[0].value;
    ctrl.filterYear = ctrl.filterYears[0];
    ctrl.filterType = {id:null};
    ctrl.filterConcept = {id:null};

    ctrl.getLabel = new reportLabels('labels', ctrl);

    ctrl.getStyleClass = function (columnName) {
      return teksService.getStyleClass(staticColumns, columnName);
    };

    ctrl.getTitlePopup = function getTitlePopup(columnName, data, columnTitle) {
      return teksService.getTitlePopup(staticColumns, columnName, data, columnTitle);
    };

    ctrl.classes = [{id:'none',name:'No Value'}];
    ctrl.filterClass = ctrl.classes[0];

    function getFilter() {
      var filter = {};
      var query = $location.search();
      filter.class_id = parseInt(query.class_id,10);
      filter.include_archived = query.include_archived || ctrl.includeArchived;
      filter.year = query.year || ctrl.year;
      filter.teacher_id = parseInt(query.teacher_id,10);
      filter.type_id = parseInt(query.type_id, 10);
      filter.drilldown = query.drilldown || false;
      filter.metric_id = query.metric_id;
      return filter;
    }

    var filter = getFilter();
    if (filter.teacher_id) {
      UserInfoDefaultFactory.get({}, {'id': filter.teacher_id}).$promise
      .then(function (response) {
        ctrl.classTeacherName = response.first_name + " " + response.last_name;
      });
    }
    else {
      ctrl.classTeacherName = User.getCurrentUser().first_name + " " + User.getCurrentUser().last_name;
    }

    ctrl.metrics = [
      {name:'Pre Test Scores', id:'pretest'},
      {name:'Post Test Scores', id:'posttest'},
      {name: '', id: 'none', status: 'DIVIDER'},
      {name:'Average Quiz Item Score', id:'quiz'},
      {name:'Total Responses', id:'responses'},
      {name:'Time on Standard (min.)', id:'time_on_standard', label: 'label-summary-metric-time-on-standard', label_default:'Time on Standard (min.)'},
      ];
    ctrl.filterMetricName = ctrl.metrics[0].name;
    ctrl.filterMetric = ctrl.metrics[0].id;

    ctrl.setMetric = function setMetric(selection){
      teksService.setMetric(ctrl, selection);
    };

    ctrl.setClass = function setClass(selection) {
      teksService.setClass(ctrl, selection);
    };

    ctrl.toggleYear = function toggleYear(selection) {
      ctrl.year = selection.value;
      ctrl.filterYear = selection;
      ctrl.includeArchived = selection.archived;
      fetchAndUpdateReport();
    };

    ctrl.getUnitsForStandard = function getUnitsForStandard(selectedCode) {
      let code = ctrl.expectationCodes.find(function(expCode) {
        return expCode.short_expectation_code == selectedCode;
      });
      if (code) {
        let masters = ctrl.summaries[code.id];
        if (masters) {
          return masters.experiences.filter(function(exp) {
            return !exp.name.includes(' TIA ');
          }).map(function(nonTia) {
            return nonTia.unit;
          }).filter(function(unit, index, inputArray) {
            return inputArray.indexOf(unit) == index;
          });
        }
      }
      return [];
    };

    ctrl.selectUnit = function selectUnit() {
      var element = document.getElementById("tia-popover");
      if (element) {
        element.parentNode.removeChild(element);
      }
    };

    ctrl.getExperiencesForStandard = function getExperiencesForStandard(selectedCode, unit) {
      let code = ctrl.expectationCodes.find(function(expCode) {
        return expCode.short_expectation_code == selectedCode;
      });
      if (code) {
        let masters = ctrl.summaries[code.id];
        if (masters) {
          return masters.experiences.filter(function(exp) {
            return !exp.name.includes(' TIA ') && exp.unit == unit;
          });
        }
      }
      return [];
    };

    ctrl.goToPastExperience = function (expId) {
      var expUrl = '/experience/' + expId + '/dashboard/responses';
      var query = {
        class_id: ctrl.filterClass.id,
      };
      ExperienceNavigator.navigateToExperience(expUrl, "", $location.path(), query);
    };

    ctrl.columns = [];
    ctrl.standardExpectations = [];
    ctrl.expectationCodes = {};

    function fetchAndUpdateReport() {
      var filter = getFilter();
      filter.include_archived = ctrl.includeArchived;
      var isInDrilldown = filter.drilldown;

      ctrl.isInDrilldown = isInDrilldown;

      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.parent.title = (report.name || '').replace('TEKS', ctrl.getLabel('label-standard-name', 'TEKS'));
        ctrl.adminReport.id = report.report_id;
        ctrl.currentLevel = report.level;
        if(isInDrilldown){
          ctrl.currentLevel = teksService.LEVELS.CURRICULUM;
        }
        ctrl.labels = report.labels;
        ctrl.hasTypes = !noTypeReportsList.includes(report.report_kind);

        ctrl.metrics.forEach(function (metric) {
          if (metric.label){
            metric.name = ctrl.getLabel(metric.label, metric.label_default);
          }
        });

        ctrl.reportData = report.data.records;
        if (report.data.short_expectation_codes && report.data.short_expectation_codes.length) {
          ctrl.expectationCodes = report.data.short_expectation_codes.filter(function(sec) {
            return ctrl.reportData.find(function(row) {
              return sec.short_expectation_code == row.short_expectation_code && (row.pretest || row.posttest);
            });
          });
        } else {
          ctrl.expectationCodes = [];
        }
        ctrl.tiaTestScores = report.data.tiaTestScores;
        ctrl.tableParams = new NgTableParams(
          {
            // items per page
            count: 12,
            sorting: { filterDate: "desc" }
          },
          {
            dataset: [],
            // options for page size
            counts: [],
          }
        );

        if (filter.year) {
          ctrl.filterYear = ctrl.filterYears.filter(function(year) {
            return year.value === parseInt(filter.year, 10);
          })[0] || ctrl.filterYears[0];
        }

        if(filter.metric_id){
          ctrl.filterMetric = ctrl.metrics.filter(function (rec) {
            return rec.id === filter.metric_id;
          }).map(function (rec) {
            return rec.id;
          })[0] || ctrl.metrics[0].id;

          ctrl.filterMetricName = ctrl.metrics.filter(function (rec) {
            return rec.id === ctrl.filterMetric;
          })[0].name;
        }

        ctrl.allowArchived = teksService.isDistrictReport(ctrl);

        if (report.data.records.length > 0) {
          ctrl.allowArchived = false;
          ctrl.classes = teksService.generateUniqueClassList(report.data.records);
          ctrl.filterClass = ctrl.classes[0];

          if(isInDrilldown){
            ctrl.filterClass = ctrl.classes.filter(function (rec) {
              return rec.id === filter.class_id;
            })[0];
          }

          var firstRecord = report.data.records[0] || {};
          if(firstRecord.teacher_first_name || firstRecord.teacher_last_name){
            ctrl.classTeacherName = firstRecord.teacher_first_name + ' ' + firstRecord.teacher_last_name;
          }
        }

        if(!report.data.short_expectation_codes){
          ctrl.data = [];
          return;
        }

        ctrl.summaries = teksService.getSummaryCells((report.data.summaries || []).filter(function (r) {
          return (r.class_id === ctrl.filterClass.id || !r.class_id);
        }));

        teksService.updateReport(ctrl);
        return report;
      });
    }

    fetchAndUpdateReport().then(function () {
      ctrl.inited = true;
    })
    .catch(function(error) {
      $log.error("error in updating report:",error);
      ctrl.inited = true;
    });

    ctrl.downloadCSV = reportToCSV(
      function() { return teksService.exportColumns(ctrl); },
      function() { return teksService.exportData(ctrl); },
      function() { return teksService.exportFile(ctrl); },
      ','
    );
  }

  module.component('curriculumTeksTiaSummary', {
    require: {parent: '^^xpReport'},
    template: require('./curriculumTIASummary.jade'),
    controller: controller,
  });

})();