'use strict';

(function() {
  var module = angular.module('client.components');

  reportSummaryHeader.$inject = ['ReportingSchoolYearsService'];

  function reportSummaryHeader(ReportingSchoolYearsService) {
    var ctrl = this;
    
    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.filterYear = ctrl.filterYears[0];

    var allCourses = {id: "All", name: "All"};
    
    ctrl.$onChanges = function(changes) {
      if (changes.courses && changes.courses.currentValue) {
        var newArray = changes.courses.currentValue.slice(0);
        if (newArray.length > 1) {
          newArray.unshift(allCourses);
        }
        ctrl.reportCourses = newArray;
        if (!ctrl.filterCourse) {
          ctrl.filterCourse = ctrl.reportCourses[0];
        }
      }
    };
    
    ctrl.downloadPDF = function() {
      if (ctrl.refresh) {
        ctrl.refresh({downloadPDF: true});
      }
    };
    
    ctrl.downloadCSV = function() {
      if (ctrl.refresh) {
        ctrl.refresh({downloadCSV: true});
      }
    };
    
    ctrl.toggleYear = function(selectedYear) {
      if (ctrl.refresh) {
        ctrl.refresh({year: selectedYear});
        ctrl.filterYear = selectedYear;
      }
    };
    
    ctrl.toggleCourse = function(selectedCourse) {
      if (ctrl.refresh) {
        ctrl.refresh({course: selectedCourse});
        ctrl.filterCourse = selectedCourse;
      }
    };
  }

  module.component('xpReportSummary', {
    template: require('./reportSummary.jade'),
    bindings: {
      tiles: '=',
      showyear: '=',
      filterYear: '=',
      filterCourse: '=',
      showexport: '=',
      courses: '<',
      refresh: '&'
    },
    controller: reportSummaryHeader
  });

})();

