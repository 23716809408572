'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpExperienceNotification', function() {
    return {
      restrict: 'EA',
      controller: ['$scope', '$log', '$route', 'ExperienceRealtimeService', 'ActiveMode', 'ModalService', 'TeacherStreamingService',
       function($scope, $log, $route, ExperienceRealtimeService, ActiveMode, ModalService, TeacherStreamingService) {

        var updateExperienceListener = $scope.$watchCollection("experience", function (newVal, oldVal) {
          if (newVal) {
            //Unbind after experience is retrieved
            updateExperienceListener();
            registerUpdateExperienceListener();
          }
        });

        function registerUpdateExperienceListener() {
          if (!$scope.isPastExperience()) {
            // Create a handler for experience updates
            ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPUpdateExperienceNotification,
              updateExperienceNotificationHandler);

            $scope.$on('$destroy', function () {
              ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPUpdateExperienceNotification,
                updateExperienceNotificationHandler);
            });
          }

          function updateExperienceNotificationHandler(e) {
            var message = e.detail;
            var experience = message && message.record || {};

            if (parseInt(experience.id, 0) !== $scope.experience.id) return;

            $log.debug("Received experience update for current experience: " + JSON.stringify(experience));

            var expStatus = (experience.status || '').toUpperCase();

            // Immediately stop any video sharing so there are is no more cost
            if (expStatus === 'INACTIVE' || expStatus === 'ARCHIVED') {
              if ($scope.userIsTeacher()) {
                TeacherStreamingService.stopVideoSharing(experience.id);
              }
            }

            // If we receive a message that the current experience has ended, notify the user
            if (expStatus === 'INACTIVE') {
              notifyExperienceEnded(experience.id);
            } else if (expStatus === 'ARCHIVED') {
              notifyExperienceArchived(experience.id);
            }
          }
        }

        // ============================================================================================
        // User notifications
        // ============================================================================================

        function notifyExperienceEnded(experienceId) {
          $log.debug('Notifying user that experience has ended');

          var config = {
            backdrop: 'static',
            keyboard: false,
            template: require('../../views/partials/modals/notifyExperienceEndedModal.jade'),
            refreshRoute: function () {
              $route.reload();
            },
            leaveActiveExperience: function() {
              ActiveMode.navigateToDefaultView();
            }
          };

          ModalService.show(config);
        }

        function notifyExperienceArchived(experienceId) {
          $log.debug('Notifying user that experience has been archived');
          var config = {
            backdrop: 'static',
            keyboard: false,
            template: require('../../views/partials/modals/notifyExperienceArchivedModal.jade'),
            refreshRoute: function () {
              $route.reload();
            },
            leaveActiveExperience: function() {
              ActiveMode.navigateToDefaultView();
            }
          };

          ModalService.show(config);
        }
      }]
    }
  });
})();
