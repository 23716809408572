'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$location', '$log', 'CourseFactory', 'classIdService', 'User',
                        'ModalService', 'xpAlert', 'ClassCodes', 'RealtimeEvents', 'WebSocket', 'ChangeStudentPassword', 'UserCourses',
                        'googleService', 'canvasService', '$rootScope', 'ActiveMode', 'schoologyService', 'rosterService',
                        'userClassPermissions', 'PermissionConsts'
  ];

  function controller($scope, $location, $log, CourseFactory, classIdService, User,
                      ModalService, xpAlert, ClassCodes, RealtimeEvents, WebSocket, ChangeStudentPassword, UserCourses,
                      googleService, canvasService, $rootScope, ActiveMode, schoologyService, rosterService,
                      userClassPermissions, PermissionConsts) {
  var ctrl = this;
  ctrl.isGoogleClassroom = false;
  ctrl.isCanvasClassroom = false;
  ctrl.isSchoologyClassroom = false;
  ctrl.isClasslinkClassroom = false;
  ctrl.lmsId = 0;
  ctrl.importDate = false;
  ctrl.allowRostering = !userClassPermissions.hasPermission(PermissionConsts.ui_disable_class_rostering);
  ctrl.isIntegration = false;

  var noStudentsMsg = "Want some help adding students? " +
                      "<a href='http://help.exploros.com/getting-started-with-exploros/creating-classes-and-adding-students/adding-students-to-a-class' target='_blank'>" +
                      "Please read this article.</a>";
  var lockedClassMsg = "The class is now locked. To let more students join this class, unlock it.";

  ctrl.currentClassId = classIdService();
  CourseFactory.setCurrentCourse(ctrl.currentClassId);

  ctrl.classLocked = false;
  ctrl.importStudentsTooltip = null;

  ctrl.students = [];
  ctrl.studentList = [];
  ctrl.allStudents = [];
  ctrl.campusStudents = [];

  ctrl.showDirections = function() {
    ModalService.show({
      template: require('../../views/partials/modals/rosterDirections.jade'),
      placement: 'center',
      animation: 'am-fade-and-scale',
      backdrop: 'static',
      classCode: ctrl.classCode,
      keyboard: false,
      printDirections: function() {
        var printDivCSS = '<link rel="stylesheet" href="http://netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css" type="text/css" media="screen" charset="utf-8">';
        printDivCSS += '<link rel="stylesheet" href="themes/exploros/css/theme.css", type="text/css" media="screen" charset="utf-8">';
        window.frames.print_frame.document.body.innerHTML=printDivCSS + document.getElementById("studentDirections").innerHTML;
        window.frames.print_frame.window.focus();
        window.frames.print_frame.window.print();
        window.frames.print_frame.document.body.innerHTML="";
      }
    });
  };

  ctrl.lockClass = function() {
    // Call to lock this class
    ClassCodes.lock({cid: ctrl.currentClassId}).$promise.then(function(newClass) {
      ctrl.classLocked = newClass.locked;
      xpAlert.success(lockedClassMsg, true);
    });
  };

  ctrl.unLockClass = function() {
    // Call to unlock this class
    ClassCodes.unlock({cid: ctrl.currentClassId}).$promise.then(function(newClass) {
      ctrl.classLocked = newClass.locked;
      xpAlert.clearAlerts();
    });
  };

  ctrl.generateCode = function() {
    // Call to lock this class
    ClassCodes.newCode({cid: ctrl.currentClassId}).$promise.then(function(newClass) {
      UserCourses.updateCachedCourses().then(function(courses){
        ctrl.classCode = newClass.code;
      });
    });
  };

  function getRoster(refresh) {
    return rosterService.getAllRosters(ctrl.currentClassId, refresh).then(function(rosters) {
      ctrl.students = rosters.classRosterWithTeachers;
      ctrl.studentList = rosters.classRoster;
      ctrl.allStudents = rosters.teacherRoster;
      ctrl.campusStudents = rosters.campusRoster;

      // if there are no students in this class then show the default message on how to add them
      if (ctrl.studentList.length === 0) {
        xpAlert.success(noStudentsMsg, true);
      }

      return rosters;
    });
  }

  // Get the class
  CourseFactory.get(ctrl.currentClassId).then(function(course) {
    ctrl.courseName = course.group_name;
    ctrl.classCode = course.code;
    ctrl.classLocked = course.locked;
    ctrl.isGoogleClassroom = course.ims_id === -1;
    ctrl.isCanvasClassroom = course.ims_id > 0;
    ctrl.isSchoologyClassroom = course.ims_id === -4;
    ctrl.isClasslinkClassroom = course.ims_id === -8;
    ctrl.isIntegration = course.ims_id ? true : false;
    ctrl.lmsId = course.ims_id;
    ctrl.importDate = $rootScope.dateTimeFormatter(course.lastSynched, false);

    // Get the list of students that are currently in this class, belong to this teacher, and are in the campus
    getRoster(false);

    // If this is a teacher then we need to capture element state changes
    RealtimeEvents.on(WebSocket, RealtimeEvents.EVENTS.Students.XPNewStudentdNotification, newStudentNotificationHandler);
    $scope.$on('$destroy', function(){
      RealtimeEvents.removeListener(WebSocket, RealtimeEvents.EVENTS.Students.XPNewStudentdNotification, newStudentNotificationHandler);
    });
  });

  function sortStudents(sortBy, reverse) {
    return function(a, b) {
      if (a && b) {
        return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase()) * (reverse ? -1 : 1);
      }
      else {
        return 0;   // if either is invalid then just return they are equal.  Shoul never really happen
      }
    }
  }

  function newStudentNotificationHandler (e) {
    var students = e.detail.record.students;
    var cid = e.detail.record.cid;
    $log.debug('New student notification : ' + JSON.stringify(students));

    students.forEach(function (newStudent) {
      var existingTeacherStudent = ctrl.allStudents.find(function (student) {
        return newStudent.id === student.id;
      });
      if (!existingTeacherStudent) {
        ctrl.allStudents.push(newStudent);
      }

      if (cid !== parseInt(('' + ctrl.currentClassId), 10)) {
        return;
      }
      var existingStudent = ctrl.students.find(function (student) {
        return newStudent.id === student.id;
      });
      if (existingStudent) {
        return;
      }
      ctrl.students.push(newStudent);
      ctrl.students.sort(sortStudents('last_name', false));
    });
  }

  ctrl.syncWithGoogleDialog = function syncWithGoogleDialog(){
    ModalService.show(
      {
        title: 'Sync Class with Google Classroom',
        message: 'This action will sync this class with its Google Classroom class. Some students may be added or removed from this class in the process.',
        buttons: [
          {
            title: 'Cancel',
            class: 'btn-default',
            click: '$hide()'
          },
          {
            title: 'Sync',
            click: 'syncClassWithGoogle(); $hide()'
          }
        ],
        syncClassWithGoogle: function syncClassWithGoogle() {
          googleService.syncCourse(ctrl.lmsId, ctrl.currentClassId).then(function (result) {
            if (result && result.name) {
              if (result.name === "class_size") {
                return CourseFactory.maxStudentsMsg().then(function(msg){
                  return xpAlert.error(msg, true);
                });
              } else {
                return xpAlert.error("An error occurred updating this Google classroom.", true);
              }
            }
            if (result && result.course && result.course.lastSynched) {
                ctrl.importDate = $rootScope.dateTimeFormatter(result.course.lastSynched, false);
            }
            getRoster(true).then(function () {
              var msg = '<b>Successful Google Classroom Sync!</b> ' + result.added + ' student(s) added,' + ' and '+ result.removed + ' student(s) removed.';
              if (result.failed) {
                msg = msg + " " + result.failed + " students couldn't be added.";
              }
              if (result.failedDomain) {
                msg = msg + " " + result.failedDomain + " students couldn't be added due to invalid e-mail domains.";
              }
              if (!result.sufficientSeats) {
                msg = msg + " However, you exceeded your seat license limit. <a href=\"http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers\" target=blank> Learn more.</a>";
              }
              xpAlert.success(msg,true);
            });
          });
        }
      }
    );
  };

  ctrl.syncWithCanvasDialog = function syncWithCanvasDialog(){
    ModalService.show(
      {
        title: 'Sync Class with Canvas',
        message: 'This action will sync this class with its Canvas course. Some students may be added or removed from this class in the process.',
        buttons: [
          {
            title: 'Cancel',
            class: 'btn-default',
            click: '$hide()'
          },
          {
            title: 'Sync',
            click: 'syncClassWithCanvas(); $hide()'
          }
        ],
        syncClassWithCanvas: function syncClassWithCanvas() {
          canvasService.syncCourse(ctrl.lmsId, ctrl.currentClassId).then(function (result) {
            if (result && result.name) {
              if (result.name === "class_size") {
                return CourseFactory.maxStudentsMsg().then(function(msg){
                  return xpAlert.error(msg, true);
                });
              } else {
                return xpAlert.error("An error occurred updating this Canvas course.", true);
              }
            }
            if (result && result.lastSynched) {
                ctrl.importDate = $rootScope.dateTimeFormatter(result.lastSynched, false);
            }
            getRoster(true).then(function () {
              var msg = '<b>Successful Canvas Sync!</b> ' + result.added + ' student(s) added,' + ' and '+ result.removed + ' student(s) removed.';
              if (result.failed) {
                msg = msg + " " + result.failed + " students couldn't be added.";
              }
              if (result.failedDomain) {
                msg = msg + " " + result.failedDomain + " students couldn't be added due to invalid e-mail domains.";
              }
              if (!result.sufficientSeats) {
                msg = msg + " Note that you exceeded your seat license limit. <a href=\"http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers\" target=blank> Learn more.</a>";
              }
              xpAlert.success(msg,true);
            });
          });
        }
      }
    );
  };

  ctrl.syncWithSchoologyDialog = function syncWithSchoologyDialog(){
    ModalService.show(
      {
        title: 'Sync Class with Schoology',
        message: 'This action will sync this class with its Schoology class. Some students may be added or removed from this class in the process.',
        buttons: [
          {
            title: 'Cancel',
            class: 'btn-default',
            click: '$hide()'
          },
          {
            title: 'Sync',
            click: 'syncClassWithSchoology(); $hide()'
          }
        ],
        syncClassWithSchoology: function syncClassWithSchoology() {
          schoologyService.syncCourse(ctrl.lmsId, ctrl.currentClassId).then(function (result) {
            if (result && result.name) {
              if (result.name === "class_size") {
                return CourseFactory.maxStudentsMsg().then(function(msg){
                  return xpAlert.error(msg, true);
                });
              } else {
                return xpAlert.error("An error occurred updating this Canvas course.", true);
              }
            }
            if (result && result.lastSynched) {
                ctrl.importDate = $rootScope.dateTimeFormatter(result.lastSynched, false);
            }
            getRoster(true).then(function () {
              var msg = '<b>Successful Schoology Sync!</b> ' + result.added + ' student(s) added,' + ' and '+ result.removed + ' student(s) removed.';
              if (result.failed) {
                msg = msg + " " + result.failed + " students couldn't be added.";
              }
              if (result.failedDomain) {
                msg = msg + " " + result.failedDomain + " students couldn't be added due to invalid e-mail domains.";
              }
              if (!result.sufficientSeats) {
                msg = msg + " Note that you exceeded your seat license limit. <a href=\"http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers\" target=blank> Learn more.</a>";
              }
              xpAlert.success(msg,true);
            });
          });
        }
      }
    );
  };

  function removeUserFromClass(user) {
    if (user.role === 'TEACHER') {
      return CourseFactory.removeTeachers({cid: ctrl.currentClassId, teachers: angular.toJson([user])});
    } else {
      return CourseFactory.removeStudents({cid: ctrl.currentClassId, students: angular.toJson([user])});
    }
  }

  ctrl.removeFromClass = function(user) {
    ModalService.show(
      {
        title: user.role === 'TEACHER' ? 'Remove Teacher' : 'Remove Student',
        message: 'Do you want to remove ' + user.first_name + " " + user.last_name + " from " + ctrl.courseName + '?',
        buttons: [
          {
            title: 'Yes',
            click: 'removeStudents(); $hide()'
          },
          {
            title: 'No',
            click: '$hide()'
          }
        ],
        removeStudents: function () {
          // Refresh the students in the list
          removeUserFromClass(user).then(function(course){
            if (course && course.error) {
              xpAlert.error(course.error.description, true);
            } else {
              if (user.role === 'TEACHER' && user.user_id === User.getId()) {
                UserCourses.updateCachedCourses(ctrl.currentClassId);
                ActiveMode.navigateToDefaultView();
              } else {
                getRoster(true);
              }
            }
          });
        }
      }
    );
  };
}

module.component('xpClassRoster', {
  template: require('./roster.jade'),
  controller: controller,
});

})();
