'use strict';
(function(){

var module = angular.module('client.services');
module.factory('xpAlert', ['$sce', function($sce){

  /****************************************************/
  /** Global error handling using bootstrap alerts ****/
  /****************************************************/
  var alerts = [];
  var alertType = {
      ERROR: 'ERROR',
      SUCCESS: 'SUCCESS',
      INFO: 'INFO'
  };

  var alertKind = {
    GENERAL: 'GENERAL',
    TRIAL: 'TRIAL'
  };

  function getAlerts() {
    return alerts;
  }

  function clearAlerts(kind) {
    if (kind) {
      alerts = alerts.filter(function(alert) { return alert.kind !== kind; });
    }
    else {
      alerts.length = 0;
    }
  }

  function removeAlert(entry) {
    var index = alerts.indexOf(entry);
    if (index > -1) {
      alerts.splice(index, 1);
    }
  }

  function addAlert(type, message, clear, kind) {
    if (clear === true) {
      clearAlerts();
    }

    if (!kind) {
      kind = alertKind.GENERAL;
    }

    var entry = {
        type: type,
        kind: kind,
        message: $sce.trustAsHtml(message),
        close: function () {
          removeAlert(entry);
        }
    };

    alerts.push(entry);

    return entry.close;
  }

  function error(message, clear, kind) {
    return addAlert(alertType.ERROR, message, clear, kind);
  }

  function success(message, clear, kind) {
    return addAlert(alertType.SUCCESS, message, clear, kind);
  }

  function info(message, clear, kind) {
    return addAlert(alertType.INFO, message, clear, kind);
  }

  function hasAlerts(kind) {
    if (!kind) {
      return alerts.length;
    } else {
      var kindAlerts = alerts.filter(function(alert) { return alert.kind === kind; });
      return kindAlerts && kindAlerts.length;
    }
  }

  return {
    alertType: alertType,
    alertKind: alertKind,
    error: error,
    success: success,
    info: info,
    clearAlerts: clearAlerts,
    removeAlert: removeAlert,
    getAlerts: getAlerts,
    hasAlerts: hasAlerts
  };

}]);

})();