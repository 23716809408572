'use strict';

/* Controllers */
// 'ui.bootstrap'

clientControllers.controller('CameraCtrl', ['$scope', '$log', 'User', 'UserInfoDefaultFactory', 'CameraService', 'UserIconUploadService', '$location', 'ImageFileModificationService',
  function ($scope, $log, User, UserInfoDefaultFactory, CameraService, UserIconUploadService, $location, ImageFileModificationService) {

    // Call to initialize directive/service for camera
    $scope.hasUserMedia = CameraService.hasUserMedia;

    // Get the current user Id
    var currentUserId = User.getId();
    $scope.standard = {id: 0};
    UserInfoDefaultFactory.get({}, {'id': currentUserId}, function (response) {
      $scope.standard = response;
    });

    $scope.getUserEmailById = function () {
      return $scope.standard.email;
    };
    $scope.snapShotImage = 'resources/profile-icon.png';

    // User wants to sa
    $scope.onSaveImage = function (data) {
      // Call the service to upload this data to the server
      ImageFileModificationService.scaleImgFromURL(data, 150, 150, false).then(function (data) {
        UserIconUploadService.uploadIconFromDataURL(currentUserId, data).progress(function (evt) {
          $log.log('percent: ' + parseInt(100.0 * evt.loaded / evt.total, 10));
        }).success(function (data, status, headers, config) {
          // file is uploaded successfully
          $location.path('/userInfo/userInfoDetail');
        });
      });
    };

    $scope.onCancel = function () {
      $location.path('/userInfo/userInfoDetail');
    };
  }]);
