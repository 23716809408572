'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('chartDirective', ['ExperienceNavigator', '$location',
  function() {
    return {
      restrict: 'CA',
      scope: {
              options: '=',
              data: '=',
              type: '='
          },
      link: function(scope, element, attributes) {
        var createChart = function() {
          var cvs = element[0];
          var chartContext = cvs.getContext("2d");

          return new Chart(chartContext, {
            type: scope.type,
            data: scope.data,
            options: scope.options
          });

        }

        scope.$watch('data', function (value) {
          if (value) {
            if (scope.currentChart) {
              scope.currentChart.destroy();
            }
            scope.currentChart = createChart();
          }
        });
      }
    }
  }]);
})();
