'use strict';

angular.module('client.services').factory('ClassImportStudents', ['$resource', 'api_server', function ($resource, api_server) {
  var url = api_server + '/classes/:cid';
  return $resource(url, {cid: '@cid'}, {
    import: {url: url + '/import', method: 'POST', params: {cid: '@cid'}},
    sendEmail: {url: url + '/email', method: 'PUT', params: {cid: '@cid'}},
    sendInstructions: {url: url + '/instructions_email', method: 'PUT', params: {cid: '@cid'}}
  });
}]);
