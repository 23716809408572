'use strict';
angular.module('xp-element-HTMLtext', ['angularWidget', 'client.services', 'ngSanitize'])
  .controller('clientHTMLTextElementCtrl', ['$scope', 'widgetConfig', '$http', '$sce', '$timeout', function ($scope, widgetConfig, $http, $sce, $timeout) {
	   
		// The element is the block of data provided by the source xml
		$scope.options = widgetConfig.getOptions($scope);
		$scope.format = "plain";
		$scope.text = "";
	  
		var parseElement = function()
		{
			if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes)
				return;
			
			$scope.options.element.config.attributes.forEach(function(attribute)
			{
				switch (attribute.name)
				{
				case "background_color" : 
					$scope.format = attribute.value;
				break;
				case "text":
					$scope.text = $sce.trustAsHtml(attribute.value);
				break;
				}
			});

			$timeout(function() {
				// Notify the widget that were are done loading the data
				widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
			  });
		};
		
		$scope.$watch('options', parseElement, true);
  }]);