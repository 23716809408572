'use strict';
angular.module('xp-element-HTMLteacher_note', ['angularWidget', 'client.services'])
  .controller('clientHTMLTeacherNoteElementCtrl', ['$scope', 'widgetConfig', 'ElementsRestService', 'ElementsErrorService', '$sce', '$timeout', '$log', 'ActiveExperience',
    function ($scope, widgetConfig, ElementsRestService, ElementsErrorService, $sce, $timeout, $log, ActiveExperience) {
      // The element is the block of data provided by the source xml
      $scope.options = widgetConfig.getOptions($scope);
      $scope.collapsed = true;
      $scope.link_url = "";

      var DISPLAY_MODE = Object.freeze({kTeacherMode: 0, kStudentMode: 1});
      $scope.DISPLAY_MODE = DISPLAY_MODE;

      function parseElement() {
        var element = $scope.options.element;
        element.config.attributes.forEach(function (attribute) {
          var name = attribute.name;
          var value = attribute.value;
          switch (name) {
            case "title" :
              $scope.title = value;
              break;
            case "text" :
              $scope.text = $sce.trustAsHtml(value);
              break;
            case "linkTitle" :
              $scope.link_title = value;
              break;
            case "linkUrl" :
              $scope.link_url = value;
              break;
          }
        });
      }

      function extractAndParserUserData(state) {
        if(!state){
          return {
            unlocked: false
          };
        }
        // for gates
        if(!state.user_data){
          return {
            unlocked: false
          };
        }
        if(state.user_data === 'unlocked'){
          return {
            unlocked: true
          };
        }
        try {
          return JSON.parse(state.user_data) || {};
        }
        catch (e) {
          $log.error("error in parsing user state for teacher note:",state);
          return {};
        }
      }

      function getUserState(experienceId, elementId, userId, callback) {
        var userData = {
          unlocked: false,
          collapsed: true
        };

        if ($scope.displayMode != $scope.DISPLAY_MODE.kTeacherMode) {
          return callback(userData);
        }
        if ($scope.options.context.getViewingInactiveExperience()){
          userData = {
            unlocked: true,
            collapsed: false
          };
          return callback(userData);
        }
        ElementsRestService.getUserState(experienceId, elementId, userId, function (result, error) {
          if(error){
            $log.error("error in getting user state for teacher note:",error);
          }
          userData = extractAndParserUserData(result);
          callback(userData, error);
        });
      }

      $scope.hideTeacherNotes = function() {
        return ActiveExperience.currentExperience() && ActiveExperience.currentExperience().hideTeacherNotes;
      };

      var element;
      var context;

      $scope.$watch('options', function (val) {
        var options = val;
        if (!options.element) return;

        element = options.element;
        context = options.context;

        $scope.displayMode = context.userIsTeacher() ? $scope.DISPLAY_MODE.kTeacherMode : $scope.DISPLAY_MODE.kStudentMode;

        parseElement();

        getUserState(context.experienceId, element.id, context.userId, function (userData, error) {
          if (!error && userData) {
            $scope.collapsed = userData.hasOwnProperty('collapsed') ? userData.collapsed : true;
          }
          else {
            ElementsErrorService.error(error);
          }
        });

        // Notify the experience that we are done loading the data for this element
        $timeout(function () {
          widgetConfig.exportProperties({elementId: options.element.id, readyToDisplay: true});
        });

      }, true);

      $scope.onCollapse = function (collapsed) {
        var options = $scope.options;
        element = options.element;
        context = options.context;

        getUserState(context.experienceId, element.id, context.userId, function (userData, error) {
          //Set the "collapsed" property in the user_data field
          userData.collapsed = collapsed;

          // If able to get the user state then save it with the new status
          if (!error && userData) {
            ElementsRestService.saveUserState(context.experienceId, element.id, context.userId, 0, JSON.stringify(userData), function (res) {
            }, function (error) {
              ElementsErrorService.error(error);
            });
          }
          else {
            ElementsErrorService.error(error);
          }
        });
      };
    }]);
