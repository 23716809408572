'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$http', '$scope', '$rootScope', '$routeParams','ForgotPasswordFactory',
                        '$location', 'User', 'ModalService'];

  function controller ($http, $scope, $rootScope, $routeParams, ForgotPasswordFactory, $location, User, ModalService) {

    //Instantiate an object to store your scope data in (Best Practices)
    $scope.data = { emailAddress: "" };
    $scope.explorosImage = "resources/exploros_logo.svg";

    $scope.error =  {
                      nameValid: true
                    };

    $scope.nameChanged = function()
    {
      $scope.error.nameValid = true;
      $scope.error.text = undefined;
    };

    $scope.sendPassword = function () {
      // Gather up the parameters from form
      ForgotPasswordFactory.post({'username':  $scope.data.emailAddress},
        function(data) {
          // Handle and error object
          if(typeof data.error == 'object') {
            // Default to the return string
            $scope.error.nameValid = false;
            $scope.error.text = data.error.description;

            // if this is just an unknown user then set to default error message
            if (data.error.description === "unknown user")
              $scope.error.text = "Email address is unrecognized or invalid. Please try again.";
          }
          else {
            ModalService.show(
              {
                title: 'Email Sent',
                message: 'An email with your new password information was sent to you.',
                buttons: [
                  {
                    title: 'Ok',
                    click: 'gotoLogin(); $hide()'
                  }
                ],
                gotoLogin: function() {
                  $location.path("/login");
                }
              }
            );
          }
      });
    };
  }

  module.component('xpResetPassword', {
    template: require('./resetPassword.jade'),
    controller: controller,
  });

})();
