'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log', 'ActiveExperience', 'ModalService'];

function controller ($scope, $log, ActiveExperience, ModalService) {
  var ctrl = this;

  ctrl.experience = ActiveExperience.currentExperience();

  if (ctrl.experience) {
    ctrl.scheduled = ctrl.experience.status === 'PENDING';
  }

  // Watch for changes to the current experience.
  $scope.$watch(function() {
    return ActiveExperience.currentExperience();
  },
  function(value) {
    if (value) {
      ctrl.experience = value;
      ctrl.scheduled = ctrl.experience.status === 'PENDING';
    }
  });

  // Function to get the Day of Week (Sun-Sat)
  ctrl.dateFormatterDayOfWeek = function (systemDate) {
    if (systemDate === null) {
      return "";
    }
    var parsedDate = Date.parse(systemDate);
    var day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var d = new Date(parsedDate);
    return day[d.getDay()];
  };

  // Function to get the Month (Jan-Dec)
  ctrl.dateFormatterMonth = function (systemDate) {
    if (systemDate === null) {
      return "";
    }
    var month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var d = new Date(systemDate);
    return month[d.getMonth()];
  };

  // Function to get the Day part of month (1-31)
  ctrl.dateFormatterDayNumberOfMonth = function (systemDate) {
    if (!systemDate) {
      return "";
    }
    var parsedDate = Date.parse(systemDate);
    var d = new Date(parsedDate);
    return d.getDate();
  };

  ctrl.changeExperienceStartDate = function () {
    if (ctrl.editable && ctrl.scheduled) {
      ModalService.show({
        title: 'Change Start Date',
        backdrop: 'static',
        template: require('./calendarUI.jade'),
        endDate: ctrl.experience.starts_at,
        save: function (startDate) {
          ActiveExperience.setStartDate(ctrl.experience.id, startDate.toString())
          .then(function(result) {
            if (!result.error) {
              ctrl.experience.starts_at = startDate;
            } else {
              ModalService.show(
                {
                  title: 'Date Error',
                  message: result.error.description,
                  buttons: [
                    {
                      title: 'Ok',
                      click: '$hide()'
                    }
                  ]
                }
              );
            }
          }).catch(function(err) {
            $log.error(err);
          });
        }
      });
    }
  };

  ctrl.changeExperienceEndDate = function () {
    if (ctrl.editable) {
      ModalService.show({
        title: 'Change End Date',
        backdrop: 'static',
        template: require('./calendarUI.jade'),
        endDate: ctrl.experience.ends_at,
        save: function (endDate) {
          ActiveExperience.setEndDate(ctrl.experience.id, endDate.toString())
          .then(function(result) {
            if (!result.error) {
              ctrl.experience.ends_at = endDate;
            } else {
              ModalService.show(
                {
                  title: 'Date Error',
                  message: result.error.description,
                  buttons: [
                    {
                      title: 'Ok',
                      click: '$hide()'
                    }
                  ]
                }
              );
            }
          }).catch(function(err) {
            $log.error(err);
          });
        }
      });
    }
  };
}

module.component('xpDateRange', {
  template: require('./dates.jade'),
  bindings: {
    visible: '<',
    editable: '<'
  },
  controller: controller,
});

})();
