'use strict';

/* Controllers */

global.clientControllers = angular.module('client.controllers',
  ['ui.bootstrap', 'ngResource', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'btorfs.multiselect', 'ui.select', 'client.directives', 'client.filters']);

//----------------------------------------------------------
//
// Global Module Configurations
//
// ---------------------------------------------------------

// Configure angular-strap dropdowns
clientControllers.config(function ($dropdownProvider) {
  angular.extend($dropdownProvider.defaults, {
    animation: 'none'
  });
});

// Configure ui-select controls
clientControllers.config(function (uiSelectConfig) {
  uiSelectConfig.theme = 'bootstrap';
});

clientControllers.controller('AppCtrl', ['$route', '$scope', '$rootScope', '$routeParams', '$location', 'User', 'loginService',
                                         'PageService', 'ThemeService', 'LoginFactory', '$log',
  function ($route, $scope, $rootScope, $routeParams, $location, User, loginService, PageService, ThemeService, LoginFactory, $log) {
    this.$route = $route;
    this.$location = $location;
    this.$routeParams = $routeParams;

    $scope.bodyClass = PageService.getBodyClass();
    $scope.theme = ThemeService.getTheme();

    $rootScope.$on('XP_HINT_DASHBOARD_MODE', function (event, args) {
      // user is in edit more or user selected Back arrow
      $scope.showNavBar = args.displayLHSMenu;
    });

    $rootScope.$on('pageBodyClassUpdated', function (event, args) {
      $scope.bodyClass = args.bodyClass;
    });

    // This will re-login the user on a browser refresh
    loginService.initLoginBySessionOrToken();
  }]);
