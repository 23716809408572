'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = ['ModalService', 'epsHelpService'];

  function controller (ModalService, epsHelpService) {
    var ctrl = this;

    ctrl.$onDestroy = function() {
      epsHelpService.reset();
    }

    ctrl.showEPSHelp = function() {
      return epsHelpService.showHelp();
    };

    ctrl.showHelp = function() {
      var config = {
        title: 'EPS Help',
        backdrop: 'static',
        template: require('../onBoarding/epsTutorials.jade'),
        eps: epsHelpService.getEps(),
        spireTutorial: function() {
          window.open('http://ispire.com/tutorials/', '_blank');
        },
        starTutorial: function() {
          window.open('https://epscurriculumsupport.wikipage.io/c/113737762/spire+star/', '_blank');
        },
        wwTutorial: function() {
          window.open('http://wordlywise3000.com/tutorials/', '_blank');
        }
      };

      ModalService.show(config);
    };
  }

  module.component('xpEpsHelp', {
    template: require('./epsHelp.jade'),
    controller: controller
  });

})();