'use strict';

(function () {

  var LEVELS = {
    DISTRICT: 'district',
    SCHOOL: 'school'
  };

  function abbreviateNumber(num) {
    num = Number(num) || 0;
    if (num < 1000) {
      return '' + num;
    }
    var power = ((num).toPrecision(2).split("e")[1] || [0, 0]).slice(1);
    var triplets = Math.floor(power / 3);
    var numericResult = (num / Math.pow(10, triplets * 3)).toFixed(1);
    if (numericResult.length > 4) {
      numericResult = (num / Math.pow(10, triplets * 3)).toFixed();
    }
    return numericResult + ['', 'K', 'M', 'B', 'T'][triplets];
  }

  function getFieldName(masterIndex, baseField) {
    return 'ex_' + masterIndex + '_' + baseField;
  }

  function renderAvgResp(val) {
    return val ? val : '-';
  }

  function renderQuizScore(val) {
    return (!val && val !== 0) ? '-' : val + '%';
  }

  function renderTotalResponses(rec) {
    if (rec.total_responses) {
      return rec.total_responses;
    } else {
      return '';
    }
  }

  function renderBreakouts(rec) {
    if (rec.breakouts_in_standard) {
      return rec.breakout_touched_count + '/' + rec.breakouts_in_standard;
    } else {
      return '';
    }
  }


  function calculateBreakoutsDonePercentage(rec) {
    if (rec.breakouts_in_standard && rec.breakout_touched_count) {
      return parseInt(rec.breakout_touched_count, 10) / parseInt(rec.breakouts_in_standard, 10);
    }
  }

  function getCustomElementBackgroundColor(columnName, value) {
    if (!columnName) {
      return;
    }
    var numValue = parseFloat(value) || 0;
    var percentage = 0;
    if (columnName.indexOf('number_of_breakouts') > -1) {
      if (!numValue) {
        return '#ffffff';
      }
      return 'rgba(170,170,170,' + (numValue) + ')';
    }
    if (columnName.indexOf('responses') > -1) {
      if (!numValue) {
        return '#ffffff';
      }
      return 'rgba(253, 180, 147,' + (numValue / 18) + ')';
    }
    if (columnName.indexOf('total_responses') > -1) {
      if (!numValue) {
        return '#ffffff';
      }
      return 'rgba(253, 180, 147,' + (numValue / 60) + ')';
    }
    if (columnName.indexOf('quiz') > -1) {
      if (isNaN(numValue)) {
        return '#ffffff';
      } else if (numValue > 49.5 && numValue < 50.5) {
        return '#ffffff';
      } else if (numValue < 50) {
        return 'rgba(230, 129, 114, ' + (1 - numValue * 0.02) + ')';
      } else if (numValue > 50) {
        return 'rgba(160, 223, 253, ' + (numValue * 0.02 - 1) + ')';
      }
    }
    if (columnName.indexOf('time_on_standard') > -1) {
      if (!numValue) {
        return '#ffffff';
      } else {
        // max value here is 60 minutes
        percentage = ((numValue / 60) / (60 / 100));
        if (percentage > 99) {
          percentage = 100;
        }
        return 'rgba(245, 211, 40, ' + percentage * 0.01 + ')';
      }
    }
    if (columnName.indexOf('total_time') > -1) {
      if (!numValue) {
        return '#ffffff';
      } else {
        // max value here is 500 minutes
        percentage = ((numValue / 60) / (500 / 100));
        if (percentage > 99) {
          percentage = 100;
        }
        return 'rgba(245, 211, 40, ' + percentage * 0.01 + ')';
      }
    }
    return '';
  }

  function getTimeOnTask(timeInSeconds) {
    var value = Math.round((timeInSeconds || 0) / 60);
    return value ? abbreviateNumber(value) : '-';
  }

  function formatExperienceSummaryDataRow(ctrl, allRecs, expectationCodes, expectationsLabels) {
    var byKey = {};

    var keyColumn = 'class_id';
    allRecs.forEach(function (rec) {
      var key = rec[keyColumn];
      if (!byKey[key]) {
        byKey[key] = [];
      }
      byKey[key].push(rec);
    });

    return Object.keys(byKey).map(function (userId) {
      var recs = byKey[userId];
      var firstRec = recs[0];

      var row = {};

      if (isDistrictReport(ctrl)) {
        row.schoolName = firstRec.school_name;
      }

      row.teacherName = firstRec.teacher_first_name + ' ' + firstRec.teacher_last_name;
      row.teacherId = firstRec.teacher_user_id;
      row.className = firstRec.class_name;
      row.classId = firstRec.class_id;
      row.archivedInfo = firstRec.archived_info || false;

      expectationCodes.forEach(function (code) {
        var rec = recs.filter(function (r) {
          return r.short_expectation_code === code;
        })[0] || {};

        row.expectation = expectationsLabels[code];

        row[getFieldName(code, 'number_of_breakouts')] = renderBreakouts(rec);
        row[getFieldName(code, 'number_of_breakouts') + '_bg'] = getCustomElementBackgroundColor('number_of_breakouts', calculateBreakoutsDonePercentage(rec));

        row[getFieldName(code, 'quiz')] = renderQuizScore(rec.avg_quiz_item_score);
        row[getFieldName(code, 'quiz') + '_bg'] = getCustomElementBackgroundColor('quiz', rec.avg_quiz_item_score);

        row[getFieldName(code, 'responses')] = renderAvgResp(rec.responses);
        row[getFieldName(code, 'responses') + '_bg'] = getCustomElementBackgroundColor('responses', rec.responses);

        row[getFieldName(code, 'time_on_standard')] = getTimeOnTask(rec.time_on_standard);
        row[getFieldName(code, 'time_on_standard') + '_bg'] = getCustomElementBackgroundColor('time_on_standard', rec.time_on_standard);

        row[getFieldName(code, 'total_responses')] = renderTotalResponses(rec);
        row[getFieldName(code, 'total_responses') + '_bg'] = getCustomElementBackgroundColor('total_responses', rec.total_responses);

        row[getFieldName(code, 'total_time')] = getTimeOnTask(rec.total_time);
        row[getFieldName(code, 'total_time') + '_bg'] = getCustomElementBackgroundColor('total_time', rec.total_time);
      });
      return row;
    });


  }

  function setColumns(ctrl, standardExpectations, filterColumn, expectationLabels) {
    var firstColumns = [];
    if (isDistrictReport(ctrl)) {
      firstColumns.push(
        {
          field: 'schoolName',
          title: 'School',
          titleName: 'School',
          filter: {schoolName: 'text'},
          sortable: 'schoolName',
          class: 'xp-report-medium-cell'
        }
      );
    }
    firstColumns.push(
      {
        field: 'teacherName',
        title: 'Teacher',
        titleName: 'Teacher',
        filter: {teacherName: 'text'},
        sortable: 'teacherName',
        class: 'xp-report-medium-cell'
      }
    );
    firstColumns.push(
      {
        field: 'className',
        title: 'Class',
        titleName: 'Class',
        filter: {className: 'text'},
        sortable: 'className',
        class: 'xp-report-medium-cell'
      }
    );
    var columns = [];
    standardExpectations.forEach(function (code) {
      if (filterColumn === 'number_of_breakouts') {
        columns.push({
          field: getFieldName(code, 'number_of_breakouts'),
          titleName: code,
          class: 'text-center xp-report-tiny-cell',
          title: code,
          headerTitle: expectationLabels[code],
          sortable: false
        });
      }
      if (filterColumn === 'total_responses') {
        columns.push({
          field: getFieldName(code, 'total_responses'),
          titleName: code,
          class: 'text-center xp-report-tiny-cell',
          title: code,
          headerTitle: expectationLabels[code],
          sortable: false
        });
      }
      if (filterColumn === 'responses') {
        columns.push({
          field: getFieldName(code, 'responses'),
          titleName: code,
          class: 'text-center xp-report-tiny-cell',
          title: code,
          headerTitle: expectationLabels[code],
          sortable: false
        });
      }
      if (filterColumn === 'quiz') {
        columns.push({
          field: getFieldName(code, 'quiz'),
          titleName: code,
          class: 'text-center xp-report-tiny-cell',
          title: code,
          headerTitle: expectationLabels[code],
          sortable: false
        });
      }
      if (filterColumn === 'time_on_standard') {
        columns.push({
          field: getFieldName(code, 'time_on_standard'),
          titleName: code,
          class: 'text-center xp-report-tiny-cell',
          title: code,
          headerTitle: expectationLabels[code],
          sortable: false
        });
      }
      if (filterColumn === 'total_time') {
        columns.push({
          field: getFieldName(code, 'total_time'),
          titleName: code,
          class: 'text-center xp-report-tiny-cell',
          title: code,
          headerTitle: expectationLabels[code],
          sortable: false
        });
      }
    });

    ctrl.columns = firstColumns.concat(columns);
  }

  function renderReport(ctrl, standardExpectations, filterColumn, data) {
    setColumns(ctrl, standardExpectations, filterColumn, ctrl.standardExpectationLabels);
    ctrl.tableParams.settings({
      dataset: data
    });
  }

  function calculateExpectationCodes(data, typeId, conceptId) {
    return data[conceptId + ':' + typeId].codes;
  }

  function calculateExpectationLabels(data, typeId, conceptId) {
    return data[conceptId + ':' + typeId].expectations;
  }

  function generateUniqueTypeConceptFunction(filteredColumn) {
    return function getUniqueByColumn(data) {
      var columnId = filteredColumn + '_id';
      var columnName = filteredColumn + '_label';
      var idToName = {};
      Object.keys(data).forEach(function (key) {
        idToName[data[key][columnId]] = data[key][columnName];
      });
      return Object.keys(idToName)
        .sort(function (a, b) {
          return a.localeCompare(b, 'en', {numeric: true});
        })
        .map(function (id) {
          return {
            id: parseInt(id, 10) || 0,
            name: idToName[id]
          };
        });
    };
  }


  function getReportFilter(ctrl) {
    return function (rec) {
      return (
        rec.type_id === ctrl.filterType.id &&
        rec.concept_id === ctrl.filterConcept.id
      );
    };
  }

  function updateReport(ctrl) {
    var reportData = (ctrl.reportData || []).filter(getReportFilter(ctrl));
    ctrl.standardExpectations = calculateExpectationCodes(ctrl.expectationCodes, ctrl.filterType.id, ctrl.filterConcept.id);
    ctrl.standardExpectationLabels = calculateExpectationLabels(ctrl.expectationCodes, ctrl.filterType.id, ctrl.filterConcept.id);
    ctrl.data = formatExperienceSummaryDataRow(ctrl, reportData, ctrl.standardExpectations, ctrl.standardExpectationLabels);
    renderReport(ctrl, ctrl.standardExpectations, ctrl.filterMetric, ctrl.data);
  }

  function isDistrictReport(ctrl) {
    return ctrl.currentLevel === LEVELS.DISTRICT;
  }

  function isSchoolReport(ctrl) {
    return ctrl.currentLevel === LEVELS.SCHOOL;
  }

  var module = angular.module('client.components');

  controller.$inject = ['$location', 'ActiveMode', 'NgTableParams', '$log', 'reportLabels',
    'noTypeReportsList', 'ReportNavigation', 'ReportingSchoolYearsService'];

  function controller($location, ActiveMode, NgTableParams, $log, reportLabels,
                      noTypeReportsList, ReportNavigation, ReportingSchoolYearsService) {

    var ctrl = this;

    var staticColumns = ['schoolName', 'studentName', 'className', 'teacherName'];

    ctrl.adminReport = {};
    ctrl.currentLevel = false;
    ctrl.hasTypes = true;
    ctrl.LEVELS = LEVELS;

    ctrl.getLabel = new reportLabels('labels', ctrl);

    ctrl.getStyleClass = function (columnName) {
      if (!staticColumns || !columnName) {
        return;
      }
      if (staticColumns.indexOf(columnName) > -1) {
        return 'xp-report-medium-cell';
      } else {
        return 'xp-report-small-box';
      }
    };

    ctrl.getTitlePopup = function getTitlePopup(columnName, data, columnTitle) {
      if (!staticColumns || !columnName) {
        return;
      }
      if (staticColumns.indexOf(columnName) > -1) {
        return columnTitle + ': ' + data;
      } else {
        return columnTitle;
      }
    };

    ctrl.isInDrilldown = false;

    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.year = ctrl.filterYears[0].value;
    ctrl.filterYear = ctrl.filterYears[0];
    ctrl.includeArchived = false;
    ctrl.allowFilterYears = false;

    ctrl.toggleYear = function toggleYear(selectedYear) {
      ctrl.year = selectedYear.value;
      ctrl.filterYear = selectedYear;
      ctrl.includeArchived = selectedYear.archived;
      fetchAndUpdateReport();
    };

    ctrl.columns = [];
    ctrl.standardExpectations = [];
    ctrl.expectationCodes = {};
    ctrl.metrics = [
      {name: 'Average Quiz Item Score', id: 'quiz'},
      {name: 'Average Responses per Student', id: 'responses'},
      {name: 'Average Time on Standard (min.)', id: 'time_on_standard'},
      {name: 'Number of Breakouts Addressed', id: 'number_of_breakouts'},
      {name: 'Total Responses', id: 'total_responses'},
      {name: 'Total Time on Standards (min.)', id: 'total_time'}
    ];
    ctrl.filterMetricName = ctrl.metrics[0].name;
    ctrl.filterMetric = ctrl.metrics[0].id;

    ctrl.setMetric = function setMetric(selection) {
      ctrl.filterMetricName = selection.name;
      ctrl.filterMetric = selection.id;
      setColumns(ctrl, ctrl.standardExpectations, selection.id, ctrl.standardExpectationLabels);
    };

    ctrl.types = [{id: 'none', name: 'No Value'}];
    ctrl.filterType = ctrl.types[0];

    ctrl.concepts = [{id: 'none', name: 'No Value'}];
    ctrl.filterConcept = ctrl.concepts[0];

    ctrl.setType = function setType(selection) {
      if (!selection || !selection.id) {
        ctrl.filterType = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterType = selection;
      return updateReport(ctrl);
    };

    ctrl.setConcept = function setConcept(selection) {
      if (!selection || !selection.id) {
        ctrl.filterConcept = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterConcept = selection;
      ctrl.types = filterTypes(ctrl.expectationCodes).filter(function filterOutByConcept(rec) {
        return ctrl.expectationCodes[ctrl.filterConcept.id + ':' + rec.id];
      });
      ctrl.filterType = ctrl.types[0];
      return updateReport(ctrl);
    };

    ctrl.goToStudentSummary = function goToStudentSummary(teacherId, classId) {
      if (!teacherId || !classId) {
        return false;
      }
      ReportNavigation.navigateToReport(ctrl.adminReport.id, $location.path(),
        {
          teacher_id: teacherId,
          class_id: classId,
          concept_id: ctrl.filterConcept.id,
          type_id: ctrl.filterType.id,
          metric_id: ctrl.filterMetric,
          studentsummary: 'yes',
          drilldown2: true,
          include_archived: ctrl.includeArchived ? 'yes' : null,
          year: ctrl.year
        },
        $location.path(),
        {
          concept_id: ctrl.filterConcept.id,
          type_id: ctrl.filterType.id,
          metric_id: ctrl.filterMetric,
          include_archived: ctrl.includeArchived ? 'yes' : null,
          year: ctrl.year
        });
    };

    ctrl.classReportDrilldown = function classReportDrilldown(teacherId, classId) {
      if (!teacherId || !classId) {
        return false;
      }
      ReportNavigation.navigateToReport(ctrl.adminReport.id, $location.path(),
        {
          teacher_id: teacherId,
          class_id: classId,
          concept_id: ctrl.filterConcept.id,
          type_id: ctrl.filterType.id,
          metric_id: ctrl.filterMetric,
          drilldown: true,
          include_archived: ctrl.includeArchived ? 'yes' : null,
          year: ctrl.year
        },
        $location.path(),
        {
          concept_id: ctrl.filterConcept.id,
          type_id: ctrl.filterType.id,
          metric_id: ctrl.filterMetric,
          include_archived: ctrl.includeArchived ? 'yes' : null,
          year: ctrl.year
        });
    };

    ctrl.data = [];
    ctrl.reportData = [];
    ctrl.tableParams = new NgTableParams(
      {
        // items per page
        count: 12,
        sorting: {filterDate: "desc"}
      },
      {
        dataset: [],
        // options for page size
        counts: []
      }
    );

    function filterConcepts(data) {
      var concepts = generateUniqueTypeConceptFunction('concept')(data);
      var history = concepts.filter(function (concept) {
        return (concept.name || '').toLowerCase() === ('History').toLowerCase();
      })[0];
      if (history) {
        return [history].concat(concepts.filter(function (concept) {
          return concept.id !== history.id;
        }));
      }
      return concepts;
    }

    function filterTypes(data) {
      var concepts = generateUniqueTypeConceptFunction('type')(data);
      var readiness = concepts.filter(function (rec) {
        return (rec.name || '').toLowerCase() === ('Knowledge and Skills - Readiness').toLowerCase();
      })[0];
      if (readiness) {
        return [readiness].concat(concepts.filter(function (rec) {
          return rec.id !== readiness.id;
        }));
      }
      return concepts;
    }

    function getFilter() {
      var filter = {};
      var query = $location.search();
      filter.class_id = parseInt(query.class_id, 10);
      filter.include_archived = query.include_archived === 'yes';
      filter.year = parseInt(query.year, 10);
      filter.teacher_id = parseInt(query.teacher_id, 10);
      filter.type_id = parseInt(query.type_id, 10);
      filter.concept_id = parseInt(query.concept_id, 10);
      filter.drilldown = query.drilldown || false;
      filter.metric_id = query.metric_id;
      filter.studentsummary = query.studentsummary === 'yes';
      filter.show_suggested = query.show_suggested;
      return filter;
    }

    function fetchAndUpdateReport() {
      var filter = getFilter();
      filter.include_archived = ctrl.includeArchived;
      filter.year = ctrl.year;
      var isInDrilldown = filter.studentsummary;

      ctrl.isInDrilldown = isInDrilldown;

      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.adminReport.id = report.report_id;
        ctrl.currentLevel = report.level;
        ctrl.labels = report.labels;
        if (isInDrilldown) {
          ctrl.currentLevel = LEVELS.CURRICULUM;
        }

        ctrl.hasTypes = !noTypeReportsList.includes(report.report_kind);

        ctrl.reportData = report.data.records;
        ctrl.expectationCodes = report.data.short_expectation_codes || {};
        if (report.data.short_expectation_codes) {
          ctrl.concepts = filterConcepts(ctrl.expectationCodes);
          ctrl.filterConcept = ctrl.concepts[0];
          ctrl.types = filterTypes(ctrl.expectationCodes).filter(function filterOutByConcept(rec) {
            return ctrl.expectationCodes[ctrl.filterConcept.id + ':' + rec.id];
          });
        }

        if (filter.concept_id) {
          ctrl.filterConcept = ctrl.concepts.filter(function (rec) {
            return rec.id === filter.concept_id;
          })[0] || ctrl.concepts[0];
        }

        if (!ctrl.filterType && !filter.type_id) {
          ctrl.filterType = ctrl.types[0];
        } else if ((!ctrl.filterType || ctrl.filterType.id === 'none') && report.data.short_expectation_codes) {
          ctrl.types = filterTypes(ctrl.expectationCodes).filter(function filterOutByConcept(rec) {
            return ctrl.expectationCodes[ctrl.filterConcept.id + ':' + rec.id];
          });
          ctrl.filterType = ctrl.types.filter(function (rec) {
            return rec.id === filter.type_id;
          })[0] || ctrl.types[0];
        }

        if (filter.year) {
          ctrl.filterYear = ctrl.filterYears.filter(function (year) {
            return year.value === parseInt(filter.year, 10);
          })[0] || ctrl.filterYears[0];
        }

        if (filter.metric_id) {
          ctrl.filterMetric = ctrl.metrics.filter(function (rec) {
            return rec.id === filter.metric_id;
          }).map(function (rec) {
            return rec.id;
          })[0] || ctrl.metrics[0].id;

          ctrl.filterMetricName = ctrl.metrics.filter(function (rec) {
            return rec.id === ctrl.filterMetric;
          })[0].name;
        }

        ctrl.allowFilterYears = isDistrictReport(ctrl) || isSchoolReport(ctrl);

        if (!report.data.short_expectation_codes) {
          ctrl.data = [];
          return;
        }

        updateReport(ctrl);
        return report;
      });
    }

    ctrl.inited = false;

    var preFilter = getFilter();
    ctrl.includeArchived = preFilter.include_archived || false;
    ctrl.year = preFilter.year || ctrl.filterYears[0].value;
    ctrl.showSuggested = preFilter.show_suggested === 'yes';

    fetchAndUpdateReport()
      .then(function () {
        ctrl.inited = true;
      })
      .catch(function (error) {
        $log.error("error in updating report:", error);
        ctrl.inited = true;
      });

  }

  module.component('schoolDistrictTeksSummary', {
    template: require('./schoolDistrictTEKSSummary.jade'),
    controller: controller
  });

})();
