'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', 'User', 'widgetConfig'];

function controller ($scope, User, widgetConfig) {
  var ctrl = this;

  ctrl.isTeacher = User.isTeacher();

  ctrl.$onInit = function() {
    // if there are any links then parse them into a json object
    if (ctrl.xpLinks) {
      ctrl.pdLinks = JSON.parse(ctrl.xpLinks);
      ctrl.pdLinks.forEach(function(link) {
        if (link.reading_level == 1) {
          link.url = link.url + "?system_role=";
          if (ctrl.isTeacher) {
            link.url = link.url + "teacher";
          } else {
            link.url = link.url + "student";
          }
        }
      });
    }

    // The component scope is isolate so we need to get the options from the parent.
    // The problem is we cannot pass the student reading group into the component because the component
    // is actually fully created on the authoring side and embedded in html.  So we need to access the
    // current context here to get the reading level for the current student
    if (!ctrl.isTeacher) {
      var options = $scope.$parent.options;
      // Get the current student information
      var student = options.context.clazz.userWithId(User.getId());
      if (student) {
        // Find the correct url based on the student level
        ctrl.studentLink = ctrl.pdLinks.find(function(link){
          return link.reading_level == student.reading_level;
        });
      }
    }
  }
}

module.component('xpPassageDifferentiator', {
  template: require('./passageDifferentiator.jade'),
  controller: controller,
  bindings: {
    xpLinks: '@',
    xpText: '@'
  }
});

})();
