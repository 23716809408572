'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('UserDefaultInfo', ['$q',
    'User', 'CourseFactory', '$log', '$rootScope',
    'UserInfoDefaultFactory', 'segment', 'SegmentFactory',
    function ($q, User, CourseFactory, $log, $rootScope, UserInfoDefaultFactory, segment,
              SegmentFactory) {
      var userInfo = null;

      function removeUserInfo() {
        userInfo = null;
      }

      function getUserInfo() {
        if (userInfo) {
          return $q.when(userInfo);
        }
        return reloadUserInfo();
      }

      function reloadUserInfo() {
        if (!User.getId()) {
          reloadUserInfo();
          return $q.reject('user is not logged in');
        }
        return UserInfoDefaultFactory.get({}, {'id': User.getId()}).$promise
          .then(function (response) {
            userInfo = response;

            // Callback for the loading of Segment integrations
            segment.ready(function () {
              if (!userInfo || !userInfo.system_role) {
                return;
              }
              var role = userInfo.system_role.toUpperCase();
              if (role !== User.ROLE.STUDENT && role !== User.ROLE.NONE) {
                SegmentFactory.onReady(response);
              }
            });

            return userInfo;
          });
      }

      return {
        getUserInfo: getUserInfo,
        reloadUserInfo: reloadUserInfo,
        removeUserInfo: removeUserInfo
      };
    }]);

})();

