'use strict';

(function () {
  var module = angular.module('client.services');

  module.service('CurriculumMode', ['$rootScope', '$q', '$location', '$log', '$routeParams', 'User',
    'userCurriculumPermissions', 'curriculumReports', 'ReportNavigation',
    function ($rootScope, $q, $location, $log, $routeParams, User,
              userCurriculumPermissions, curriculumReports, ReportNavigation) {

      // test to see if we are in district mode
      function isActiveMode() {
        return $location.path().indexOf('/experience') !== -1 && $location.path().indexOf('/experiences') === -1;
      }

      var remove = $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
        // If the current route is a curriculum route then set correctly
        if (current && current.$$route && current.$$route.originalPath && current.$$route.originalPath.indexOf('/experience') != -1) {
          userCurriculumPermissions.setUserCurriculumPermissions(User.getId());
        }
        else {
          userCurriculumPermissions.clearPermissions();
        }
      });

      // Get the current report from the URL
      // named for brevity, in fact back-end returns report_key as report_id
      function currentReportKey() {
        return $routeParams.reportId;
      }

      // return the title when we are in curriculum mode
      function getTitle() {
        return $q.when("All Classes");
      }

      function navigateToExperiences() {
        $location.path('/experience');
      }

      function navigateToReports() {
        $location.path('/experience/reports');
      }

      function navigateToPack() {
        $location.path('/experience/class-pack');
      }

      function navigateToLicensePack() {
        $location.path('/experience/license-pack');
      }

      function getReports() {
        return curriculumReports.getReports();
      }

      function getReportData(filter) {
        return curriculumReports.getReportData(currentReportKey(), filter);
      }

      function showReport(reportKey) {
        ReportNavigation.navigateToReport(reportKey, '/experience/report/' + reportKey,
          {}, $location.path());
      }

      function resetPermissions() {
        userCurriculumPermissions.resetPermissions(User.getId());
      }

      return {
        isActiveMode: isActiveMode,
        getTitle: getTitle,
        navigateToExperiences: navigateToExperiences,
        navigateToReports: navigateToReports,
        navigateToPack: navigateToPack,
        navigateToLicensePack: navigateToLicensePack,
        getReports: getReports,
        getReportData: getReportData,
        showReport: showReport,
        resetPermissions: resetPermissions
      };
    }]);
})();
