'use strict';

/* Controllers */
// 'ui.bootstrap'

clientControllers.controller('UploadCtrl', ['$scope', 'User', 'UserIconUploadService', 'UserInfoDefaultFactory', '$location', '$log', 'ImageFileModificationService',
  function($scope, User, UserIconUploadService, UserInfoDefaultFactory, $location, $log, ImageFileModificationService) {

    $scope.standard = {id: 0};

    // Get the current user Id
    var currentUserId = User.getId();

    UserInfoDefaultFactory.get({}, {'id': currentUserId}, function(response) {
      $scope.standard = response;
    });

    $scope.getUserEmailById = function() {
      return $scope.standard.email;
    };

    $scope.onFileSelect = function($files) {
      // Only support uploading one file
      if ($files.length > 0) {
        ImageFileModificationService.scaleFile($files[0], 150, 150, false).then(function(data) {
          UserIconUploadService.uploadIconFromDataURL(User.getId(), data).progress(function(evt) {
            $log.debug('percent: ' + parseInt(100.0 * evt.loaded / evt.total, 10));
          }).success(function(data, status, headers, config) {
            // file is uploaded successfully
            $location.path('/userInfo/userInfoDetail');
          });
        });
      }
    };
  }]);
