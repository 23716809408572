'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['CourseFactory', 'classIdService', '$location', 'User', 'ImportValidation', 'ClassImportStudents',
                        'ModalService', 'xpAlert', 'rosterService', 'userClassPermissions', 'PermissionConsts'];

function controller (CourseFactory, classIdService, $location, User, ImportValidation, ClassImportStudents,
                      ModalService, xpAlert, rosterService, userClassPermissions, PermissionConsts) {
  var ctrl = this;

  ctrl.currentClassId = classIdService();
  CourseFactory.setCurrentCourse(ctrl.currentClassId);

  ctrl.classStudents = [];
  ctrl.allStudents = [];
  ctrl.campusStudents = [];
  ctrl.allowRostering = !userClassPermissions.hasPermission(PermissionConsts.ui_disable_class_rostering);

  // Show the default alert message when first trying to import
  ModalService.show(
    {
      title: 'Import Students (CSV)',
      message: 'This import option is not commonly used, and should be reserved for students who do not have ' +
        'school-based email addresses (typically elementary students). Before using this option, we recommend ' +
        'you read our <a target="_blank" href="http://help.exploros.com/getting-started-with-exploros/additional-tips/single-sign-on-sso-best-practices" >SSO Best Practices article.</a>',
      buttons: [
        {
          title: 'Cancel',
          click: 'cancelImport(); $hide();'
        },
        {
          title: 'Proceed',
          click: '$hide()'
        }
      ],
      cancelImport: function () {
        classRoster();
      },
    }
  );

  function classRoster() {
    $location.path('/class/' + ctrl.currentClassId + '/roster');
  }


  function getRoster(refresh) {
    rosterService.getAllRosters(ctrl.currentClassId, refresh).then(function(rosters) {
      ctrl.classStudents = rosters.classRoster;
      ctrl.allStudents = rosters.teacherRoster;
      ctrl.campusStudents = rosters.campusRoster;
    });
  }

  // Get the class
  CourseFactory.get(ctrl.currentClassId).then(function(course) {
    ctrl.courseName = course.group_name;

    // Get the list of students that are currently in this class
    getRoster(false);
  });

  ctrl.currentPage = 1;
  ctrl.studentcsv = "";

  ctrl.inputData = function() {
    xpAlert.clearAlerts();
    ctrl.currentPage = 1;
  };

  function isCSVFile(file) {
    if (!file.name.endsWith(".csv")) {
      xpAlert.error("Invalid import file. Only csv files can be imported", true);
      return false;
    }
    else {
     return true;
    }
  }

  ctrl.onFileSelect = function(files) {
    if (files.length > 0) {
      var file = files[0];
      if (isCSVFile(file)) {
        var reader = new FileReader();
        reader.onload = onLoadFile;
        reader.readAsText(file);
      }
    }
  };

  function onLoadFile(event) {
    var fileContents = event.target.result;

    // Look for the first Line Feed.  If this file was created on the Mac, it will only have a carriage return so look for that if no line feed
    var endOfFirstLine = fileContents.indexOf("\n");
    if (endOfFirstLine == -1)
      endOfFirstLine = fileContents.indexOf("\r");

    // The data to import is everything after the header line
    ctrl.studentcsv = fileContents.substring(endOfFirstLine + 1);

    // Validate the contents from the file
    ctrl.parseAndValidateStudents();
  }

  ctrl.parseAndValidateStudents = function() {
    // Get the import and convert it to JSON to work with
    var content = {csv: ctrl.studentcsv ? ctrl.studentcsv : "", separator: ",", header: true, studentImport: true };
    ctrl.students = ImportValidation.convertToJSON(content);

    if (ctrl.students.length === 0) {
      xpAlert.clearAlerts();
      xpAlert.error("Invalid Import file. No students found.");
      return;
    }

    // Check the individual students for validity
    ctrl.validateAllStudents();
  };

  function validateStudents() {
    return ImportValidation.validateImportStudents(ctrl.students, false).then(function(students){
      ctrl.students = students;

      // Check for any errors in the students
      ctrl.hasErrors = ImportValidation.checkForErrors(students);

      // Tell the user if there are any errors
      xpAlert.clearAlerts();
      if (ctrl.hasErrors)
        xpAlert.error("You have conflicts in your import data. You must resolve all these conflicts before you can import.");
      else
        xpAlert.success("Review the students to import. Click Next when ready.");

      return !ctrl.hasErrors;
    });
  }

  ctrl.validateAllStudents = function() {
    // Check the individual students for validity
    validateStudents().then(function(validStudents){
      ctrl.currentPage = 2;
    });
  };

  ctrl.preview = function() {
    if (!ctrl.hasErrors) {
      xpAlert.clearAlerts();
      xpAlert.success("Review the list to make sure everything is correct.");
      ctrl.currentPage = 3;
    }
  };

  ctrl.remove = function(student)
  {
    ctrl.students = ctrl.students.filter(function(st) {
      return st != student;
    });

    // This will re-check all the students
    ctrl.validateAllStudents();
  };

  ctrl.import = function () {
    ClassImportStudents.import({}, {'cid': ctrl.currentClassId, 'students': angular.toJson(ctrl.students)}, function(result) {
      if (result && result.error) {
          xpAlert.error(result.error.description, true);
      }
      else {
        var msg = "Success! The following accounts were added to " + ctrl.courseName + ". Save the passwords for your records; they cannot be shown again.";
        if (!result.sufficientSeats) {
          msg = msg + " Note that you exceeded your seat license limit. <a href=\"http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers\" target=blank> Learn more.</a>";
        }
        xpAlert.success(msg, true);
        ctrl.currentPage = 4;

        // Reload the students so the numbers update
        getRoster(true);
      }
    });
  };

  ctrl.email = function()
  {
    // Send import data to the server
    ClassImportStudents.sendEmail({}, {'cid': ctrl.currentClassId, 'students': ctrl.students}, function(result) {
      ModalService.show(
          {
            title: 'Email Sent',
            message: 'An email containing the student account information was sent to you.',
            buttons: [
              {
                title: 'Ok',
                click: '$hide()'
              }
            ]
          }
        );
    });
  };
}


module.component('xpClassImport', {
  template: require('./import.jade'),
  controller: controller,
});

})();
