'use strict';

angular.module('client.services').factory('SavedFilterState', ['$q', '$sessionStorage', 'User',
  function ($q, $sessionStorage, User) {

    var sessionStateVariable = 'reportState_' + User.getId();
    var sessionFilterVariable = 'reportFilter_' + User.getId();

    return {
      getState: function () {
        return $sessionStorage[sessionStateVariable];
      },
      setState: function (reportFilterState) {
        $sessionStorage[sessionStateVariable] = reportFilterState;
      },
      getFilter: function () {
        return $sessionStorage[sessionFilterVariable];
      },
      setFilter: function (reportFilter) {
        $sessionStorage[sessionFilterVariable] = reportFilter;
      }
    };

  }]);
