'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$element', '$attrs', '$log', 'VideoStreamingService', 'OT', 'User', 'ActiveExperience',
                        'TeacherStreamingService', 'StudentStreamingService', '$timeout'];

  function controller ($scope, $element, $attrs, $log, VideoStreamingService, OT, User, ActiveExperience,
                        TeacherStreamingService, StudentStreamingService, $timeout) {
    var ctrl = this;

    ctrl.apiKey = null;
    ctrl.session = null;
    ctrl.subscriberSession = null;
    ctrl.studentAudioState = VideoStreamingService.AUDIOSTATE.OFFLINE;
    ctrl.videoEnabled = false;
    ctrl.audioEnabled = false;

    ctrl.$onInit = function () {
      ctrl.studentAudioState = VideoStreamingService.AUDIOSTATE.OFFLINE;
      ctrl.videoEnabled = false;
      ctrl.audioEnabled = User.isTeacher();

      return VideoStreamingService.getMediaToken(ctrl.getExperienceId()).then(function (mediaToken) {
        if (!mediaToken || !mediaToken.token) {
          $log.error("Media Token data not present");
          return;
        }

        ctrl.apiKey = mediaToken.apiKey;

        // Initialize a new session with Opentok
        var session = OT.initSession(mediaToken.apiKey, mediaToken.sessionID);
        if (!session) {
          $log.error("Could not initialize a session with opentok");
          return;
        }

        // The subscriber must intialize handlers "Before" the session connects.
        ctrl.subscriberSession = session;

        // Now connect to the session
        $timeout(function(){
          session.connect(mediaToken.token, function(err) {
            if (err) {
              $log.error("Failed to connect to session: " + err);
              return;
            }
            ctrl.session = session;
          });
        });
      }).catch(function(error){
        $log.error("Failed to getMediaToken: " + error);
      });
    };

    ctrl.getExperienceId = function() {
      if (ActiveExperience.currentExperience()) {
        return ActiveExperience.currentExperience().id;
      }
      return null;
    };

    ctrl.isTeacherStreamingVideo = function() {
      return TeacherStreamingService.isTeacherStreamingVideo();
    };

    ctrl.isStudentSeeingStreaming = function() {
      return StudentStreamingService.isStudentSeeingStreaming();
    };

    ctrl.isStudentStreamingAudio = function() {
      return ctrl.studentAudioState === VideoStreamingService.AUDIOSTATE.SPEAKING ||
              ctrl.studentAudioState === VideoStreamingService.AUDIOSTATE.MUTED;
    };

    ctrl.getStudents = function() {
      if (ActiveExperience.currentExperience()) {
        return ActiveExperience.sortStudents(ActiveExperience.currentExperience().students, ActiveExperience.SORTBY.Name);
      }
      return [];
    };

    ctrl.studentsCanRaiseHands = function() {
      return VideoStreamingService.getStudentCanRaiseHand();
    };

    ctrl.countStudentsWithHandRaised = function() {
      var handsRaised = 0;
      ctrl.getStudents().forEach(function(student){
        if (student.raisedHand) {
          handsRaised = handsRaised + 1;
        }
      });
      return handsRaised;
    };

    ctrl.$onDestroy = function () {
      if (ctrl.session) {
        $timeout(function() {
          ctrl.session.off();
          ctrl.session.destroy();
          ctrl.session = null;
        });
      }
    };
  }

  module.component('session', {
    template: require('./session.jade'),
    controller: controller
  });
})();
