'use strict';

(function () {
  var module = angular.module('client.components');

  module.directive('accountSettingsProfile', function () {
    return {
      restrict: 'E',
      scope: {},
      bindToController: {
        userIsStudent: '&'
      },
      template: require('./accountSettingsProfile.jade'),
      controller: ['$log', '$timeout', '$rootScope', 'User', 'UserInfoDefaultFactory', 'UserIconService', 'UserIconUploadService', 'UserPhotoFactory', 'CameraService', 'ImageFileModificationService', 'loadImageWithOrientation', function ($log, $timeout, $rootScope, User, UserInfoDefaultFactory, UserIconService, UserIconUploadService, UserPhotoFactory, CameraService, ImageFileModificationService, loadImageWithOrientation) {
        var $ctrl = this;

        this.user = User.getCurrentUser();
        var currentUserId = User.getId();

        this.saving = false;
        this.saved = false;
        this.showSnapshot = false;

        if (this.user.canvas_user) {
          this.lms = "Canvas";
        } else if (this.user.gg4l_user) {
          this.lms = "gg4l";
        } else if (this.user.google_user) {
          this.lms = "Google";
        } else if (this.user.schoology_user) {
          this.lms = "Schoology";
        } else if (this.user.clever_user) {
          this.lms = "Clever";
        } else if (this.user.classLink_user) {
          this.lms = "Classlink";
        } else {
          this.lms = "";
        }
        this.changedProfilePhoto = false;

        this.hasUserMedia = CameraService.hasUserMedia();

        this.salutations = ['Mr.', 'Ms.', 'Mrs.', 'Dr.'];

        this.setSalutation = function (salutation) {
          this.user.salutation = salutation;
        };

        this.onSaveSnapshot = function (data) {
          // Call the service to upload this data to the server
          ImageFileModificationService.scaleImgFromURL(data, 150, 150, false)
            .then(function (data) {
              UserIconUploadService.uploadIconFromDataURL(currentUserId, data)
                .progress(function (evt) {
                  $log.debug('percent: ' + parseInt(100.0 * evt.loaded / evt.total, 10));
                })
                .finally(function () {
                  $ctrl.toggleSnapshot();
                });
            });
        };

        this.toggleSnapshot = function () {
          $ctrl.showSnapshot = !$ctrl.showSnapshot;
        };

        this.onFileSelect = function (files) {
          if (files.length > 0) {
            var file = files[0];
            var options = {
              maxWidth: 150, maxHeight: 150, contain: true
            };
            loadImageWithOrientation(file, options)
              .then(function (file) {
                // Only support uploading one file
                var $files = [file];
                if ($files.length > 0) {
                  ImageFileModificationService.scaleFile($files[0], 150, 150, false)
                    .then(function (data) {
                      UserIconUploadService.uploadIconFromDataURL(currentUserId, data)
                        .progress(function (evt) {
                          $log.debug('percent: ' + parseInt(100.0 * evt.loaded / evt.total, 10));
                        })
                        .success(function (data, status, headers, config) {
                          // file is uploaded successfully
                          UserIconService.profilePictureUpdated(currentUserId);
                        });
                    });
                }
              });
          }
        };

        this.removePhoto = function () {
          UserPhotoFactory.remove({}, {'id': currentUserId}, function () {
            UserIconService.profilePictureUpdated(currentUserId);
          });
        };

        this.isExplorosUser = function () {
          return !this.user.canvas_user && !this.user.gg4l_user && !this.user.google_user && !this.user.schoology_user && !this.user.clever_user && !this.user.classLink_user;
        };

        this.saveUserInfo = function (isValid) {
          // The form checker should validate, but just check flag in case
          if (!isValid) {
            this.xpError.details = 'Please resolve the errors before submitting the data.';
            return null;
          }

          var params = {
            'id': this.user.id,
            'salutation': this.user.salutation,
            'first_name': this.user.first_name,
            'last_name': this.user.last_name,
            'address': this.user.address,
            'phone_number': this.user.phone_number,
            'date_of_birth': this.user.date_of_birth,
            'gender': this.user.gender,
            'about': this.user.about,
            'facebook': this.user.facebook,
            'twitter': this.user.twitter
          };

          this.saving = true;

          UserInfoDefaultFactory.save(params).$promise
            .then(function (user) {
              if (typeof user.error == 'object') {
                $ctrl.xpError.details = user.error.description;
              } else if (typeof user == 'object') {
                $ctrl.user = user;
                UserIconService.profilePictureUpdated(currentUserId);
              }
            })
            .catch(function (error) {
              $log.error("error in saving user info", error);
              $ctrl.saving = false;
            })
            .finally(function () {
              $ctrl.saving = false;
              $ctrl.saved = true;

              $timeout(function () {
                $ctrl.saved = false;
              }, 3000);
            });
        };
      }],
      controllerAs: '$ctrl'
    };
  });

  module.directive('accountSettingsAccounts', ['$timeout', function ($timeout) {
    return {
      restrict: 'E',
      scope: {},
      template: require('./accountSettingsAccounts.jade'),
      controller: ['$log', 'User', 'UserPasswordSettingsFactory', 'CourseFactory', 'UserInfoDefaultFactory', '$sessionStorage', '$resource', 'api_server',
        function ($log, User, UserPasswordSettingsFactory, CourseFactory, UserInfoDefaultFactory, $sessionStorage, $resource, api_server) {
          var $ctrl = this;

          var IconService = $resource(api_server + '/users/:userId/photo/exists', {userId: '@userId'}, {
            'get': {method: 'GET'}
          });

          this.user = User.getCurrentUser();

          var currentUserId = User.getId();

          this.hasGoogleClasses = true;
          this.unlinkFromGoogle = false;

          var params = {};
          if (User.getSystemRole() == User.ROLE.TEACHER) {
            params.teacher_user_id = User.getId();
          } else if (User.getSystemRole() == User.ROLE.STUDENT) {
            params.student_username = $ctrl.user.username;
          }

          CourseFactory.list(params)
            .then(function (courses) {
              var googleCourses = courses.filter(function (course) {
                try {
                  var settings = angular.fromJson(course.settings) || {};
                  if (settings && settings.ims && settings.ims.ims_id === -1 && settings.ims.classroom) {
                    return true;
                  } else {
                    return false;
                  }
                  // if (settings && settings.google && settings.google.classroom) {
                  //   return true;
                  // } else {
                  //   return false;
                  // }
                }
                catch (e) {
                  //
                }
              });
              $ctrl.hasGoogleClasses = googleCourses && googleCourses.length;
            });

          this.saving = false;
          this.saved = false;

          this.linkedAccounts = [];

          (function getLinkedAccounts() {
            if ($ctrl.user.google_user) {
              $ctrl.linkedAccounts.push($ctrl.user);
            }
          })();

          this.showLinkedAccounts = function () {
            return this.linkedAccounts.length > 0;
          };

          this.isGoogleUser = function () {
            return $ctrl.user.google_user && !this.hasGoogleClasses;
          };

          this.disconnectFromGoogle = function () {
            $ctrl.unlinkFromGoogle = true;
            $ctrl.hasGoogleClasses = false;
            $ctrl.linkedAccounts = [];
          };

          this.savePasswordChanges = function (isValid) {
            // Verify password matches
            if (this.pwd1 != this.pwd2) {
              return;
            }

            if (!isValid) {
              return;
            }

            var params = {
              'id': currentUserId, 'password': this.pwd1
            };

            UserPasswordSettingsFactory.update(params).$promise
              .then(function (data) {
                if (data.error) {
                  $ctrl.xpError.details = data.error.description;
                } else if ($ctrl.unlinkFromGoogle) {
                  UserInfoDefaultFactory.deleteLms({id: currentUserId, ims: -1}).$promise
                    .then(function (newUser) {
                      $ctrl.user = newUser;
                      User.setCurrentUser(newUser);
                      delete $sessionStorage['gravatar_' + currentUserId];
                      IconService.get({}, {'userId': currentUserId}, function (response) {
                        $sessionStorage['gravatar_' + currentUserId] = JSON.stringify(response);
                      });
                    });
                }
              })
              .catch(function (error) {
                $log.error("error in resetting user password", error);
                $ctrl.saving = false;
              })
              .finally(function () {
                $ctrl.saving = false;
                $ctrl.saved = true;

                $timeout(function () {
                  $ctrl.saved = false;
                }, 3000);
              });
          };
        }],
      controllerAs: '$ctrl'
    };
  }]);

  module.directive('accountSettingsSchool', function () {
    return {
      restrict: 'E',
      scope: {},
      template: require('./accountSettingsSchool.jade'),
      controller: ['SchoolsFactory', 'User', 'UserInfoDefaultFactory', 'SegmentFactory', '$log', 'ActiveMode',
        function (SchoolsFactory, User, UserInfoDefaultFactory, SegmentFactory, $log, ActiveMode) {
          var $ctrl = this;

          this.user = User.getCurrentUser();

          this.zipcode = null;
          this.savedSchool = null;
          this.selectedSchool = null;
          this.filteredSchools = [];
          this.error = null;

          this.editing = false;
          this.checking = false;
          this.checked = false;
          this.saving = false;
          this.schoolId = null;

          getSavedSchool(this.user);

          function getSavedSchool(user) {
            $ctrl.schoolId = user.school_id;

            if (!$ctrl.schoolId) {
              $ctrl.editing = true;
              return;
            }

            var query = {
              id: user.school_id
            };

            return SchoolsFactory.get(query).$promise
              .then(function (data) {
                $ctrl.savedSchool = data;
                return data;
              })
              .catch(function (error) {
                $log.error("error in getting school:", error);
              });
          }

          this.toggleEditMode = function () {
            this.editing = !this.editing;
          };

          this.getSchools = function (isValid) {
            if (!isValid) {
              return;
            }

            var query = {
              zipcode: this.zipcode
            };

            this.checking = true;
            this.error = null;

            SchoolsFactory.list(query).$promise
              .then(function (data) {
                $ctrl.filteredSchools = data.schools;
                return data.schools;
              })
              .catch(function (error) {
                $log.error("error in getting school:", error);
                $ctrl.filteredSchools = [];
              })
              .finally(function () {
                $ctrl.checking = false;
                $ctrl.checked = true;
              });
          };

          this.canCheckZip = function (form) {
            form = form || {};
            return !form.$invalid && !form.$pristine;
          };

          this.saveSchool = function (isValid) {
            if (!isValid) {
              return;
            }

            var query = {
              id: this.user.id, school_id: this.selectedSchool.id
            };

            var nces_id = this.selectedSchool.nces_id;

            this.saving = true;

            UserInfoDefaultFactory.saveSchool(query).$promise
              .then(function (newUser) {
                $ctrl.user = newUser;
                User.setCurrentUser(newUser);
                $ctrl.savedSchool = getSavedSchool(newUser);
                ActiveMode.resetPermissions();

                SegmentFactory.track(SegmentFactory.events().SCHOOL_ADDED); // track analytics event
                SegmentFactory.group(nces_id, $ctrl.selectedSchool); // add user to school's group
                $ctrl.editing = false;
                $ctrl.checked = false;
                return newUser;
              })
              .catch(function (error) {
                $log.error("error in saving school:", error);
                if (error.data && error.data.error) {
                  if (error.data.error == "Domain not match") {
                    $ctrl.error = "The school selected does not match your email domain.  Please try again, or leave school field blank.";
                  } else {
                    $ctrl.error = "The following error occurred saving the school settings: " + error.data.error;
                  }
                } else {
                  $ctrl.error = "An error occurred saving the school settings.";
                }
              })
              .finally(function () {
                $ctrl.saving = false;
                $ctrl.selectedSchool = null;
              });
          };
        }],
      controllerAs: '$ctrl'
    };
  });

  module.directive('accountSettingsLicenses', function () {
    return {
      restrict: 'E',
      scope: {},
      template: require('./accountSettingsLicenses.jade'),
      controller: ['$rootScope', 'LicensePlans', 'User', 'PermissionConsts', 'licensePackService', 'userPermissions',
        function ($rootScope, LicensePlans, User, PermissionConsts, licensePackService, userPermissions) {
          var $ctrl = this;

          this.user = User.getCurrentUser();

          LicensePlans.getExposed({'userId': User.getId()}, function (licenses) {
            $ctrl.licenses = licenses.map(function (license) {
              if (license.permissions) {
                if (license.permissions.includes(PermissionConsts.ui_license_pack)) {
                  license.licensePack = true;
                }
                if (license.permissions.includes(PermissionConsts.subscription_admin)) {
                  license.admin = true;
                }
                if (license.permissions.includes(PermissionConsts.ui_tia_proctor)) {
                  license.proctor = true;
                }
              }
              if (license.settings) {
                let proctorKey = license.settings.find(function (setting) {
                  return setting.key == 'is_proctor';
                });
                if (proctorKey && proctorKey.value.toLowerCase() == 'yes') {
                  license.proctor = true;
                }
              }
              return license;
            });
          });

          this.formatDate = function (planDate) {
            return $rootScope.dateTimeFormatter(planDate);
          };

          this.onLicenseSynch = function (license) {
            licensePackService.setSynch(license.subscription_id, license.synch);
          };

        }],
      controllerAs: '$ctrl'
    };
  });

  module.directive('accountSettingsTheme', function () {
    return {
      restrict: 'E',
      scope: {},
      template: require('./accountSettingsTheme.jade'),
      controller: ['$rootScope', '$timeout', 'themeService', 'User', function ($rootScope, $timeout, themeService, User) {
        var $ctrl = this;

        $ctrl.user = User.getCurrentUser();
        $ctrl.saved = false;

        themeService.getThemes().then(function (themes) {
          $ctrl.themes = themes.map(function (theme) {
            if ($ctrl.user.theme_id === theme.id) {
              theme.selected = true;
            }
            return theme;
          });
        });

        $ctrl.getBackground = function (theme) {
          var style = {};
          if (theme.theme_type === 1) {
            style['background-color'] = theme.color1;
          } else if (theme.theme_type === 2) {
            style['background-repeat'] = 'no-repeat';
            style['background-size'] = 'cover';
            style['background-image'] = "linear-gradient(" + theme.color1 + "," + theme.color2 + ")";
          } else if (theme.theme_type === 3) {
            style['background-image'] = "url(backgrounds\/" + theme.id + "\/thumbnail)";
            style['background-repeat'] = "no-repeat";
            style['background-size'] = "cover";
          }
          return style;
        };

        $ctrl.onSelectTheme = function (selectedTheme) {
          $ctrl.themes = $ctrl.themes.map(function (theme) {
            if (selectedTheme == theme) {
              theme.selected = true;
            } else if (theme.selected) {
              theme.selected = false;
            }
            return theme;
          });
        };

        $ctrl.onSaveTheme = function () {
          var selectedTheme = $ctrl.themes.find(function (theme) {
            return theme.selected == true;
          });
          if (selectedTheme) {
            themeService.setTheme(selectedTheme.id).then(function (newUser) {
              User.setCurrentUser(newUser);
              $ctrl.saved = true;

              $timeout(function () {
                $ctrl.saved = false;
              }, 3000);
            });
          }
        };
      }],
      controllerAs: '$ctrl'
    };
  });

})();

