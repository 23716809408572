'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = [];

  function controller () {
    var ctrl = this;

    ctrl.formatScore = function(score) {
      if (score == null) {
        return "-";
      }
      return score + "%";
    };
  }

  module.component('xpStudentLearningCluster', {
    template: require('./standardsCluster.jade'),
    controller: controller,
    bindings: {
      cluster: '<',
      showexperiences: '<',
      experiences: '<',
      unit: '<',
      preview: '<'
    }
  });

})();
