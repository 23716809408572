'use strict';
(function () {
  angular.module('xp-element-flashcard', ['angularWidget', 'client.services', 'client.directives', 'ngAnimate', 'mgcrea.ngStrap', 'ngSanitize'])
    .controller('clientFlashcardElementCtrl',
      ['$scope', '$rootScope', '$log', 'widgetConfig', '$http', 'ElementsRestService', 'ElementUtilities',
        'JSONStringUtility', '$timeout', 'SHARE_MODE', 'GATE_MODE', 'ElementsErrorService', 'flashcardWords',
        'IconOverlays', 'RespondentType', '$sce', 'ModalService', '$q', 'ActiveExperience', 'CorrectResponseId',
        'randomize',
        function ($scope, $rootScope, $log, widgetConfig, $http, ElementsRestService, ElementUtilities,
                  JSONStringUtility, $timeout, SHARE_MODE, GATE_MODE, ElementsErrorService, flashcardWords,
                  IconOverlays, RespondentType, $sce, ModalService, $q, ActiveExperience, CorrectResponseId,
                  randomize) {

          $scope.instructions = {};
          $scope.href = null;
          $scope.random = false;
          $scope.collecton = null;
          $scope.level = null;
          $scope.lesson = null;
          $scope.flashcards = [];
          $scope.currentCard = 0;
          $scope.showFront = true;
          $scope.isTeacher = false;
          $scope.share = SHARE_MODE.TEACHER;
          $scope.respondents = [];
          $scope.portionResponded = 0;
          $scope.isFlipped = false;
          $scope.isFlipping = false;

          let context = null;
          let element = null;

          // The element is the block of data provided by the source xml
          $scope.options = widgetConfig.getOptions($scope);

          let parseElement = function () {
            if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes) {
              return;
            }

            $scope.options.element.config.attributes.forEach(function (attribute) {
              switch (attribute.name) {
                case "text" :
                  $scope.instructions.question = $sce.trustAsHtml(attribute.value);
                  break;
                case "image_url" :
                  $scope.instructions.imageUrl = ElementUtilities.getElementURL($scope.options.element, $scope.options.context.experienceId, attribute.value);
                  break;
                case "url" :
                  $scope.href = attribute.value;
                  break;
                case "style" :
                  if (attribute.value == "default") {
                    $scope.instructions.border = "image-element-border";
                  }
                  break;
                case "alignment" :
                  if (attribute.value == "left") {
                    $scope.instructions.alignment = "image-element-alignment-left";
                  } else if (attribute.value == "right") {
                    $scope.instructions.alignment = "image-element-alignment-right";
                  }
                  break;
                case "randomize" :
                  $scope.random = attribute.value;
                  break;
                case "collection" :
                  $scope.collection = attribute.value;
                  break;
                case "level" :
                  $scope.level = attribute.value;
                  break;
                case "lesson" :
                  $scope.lesson = attribute.value;
                  break;
              }
            });

            $timeout(function () {
              // Notify the widget that were are done loading the data
              widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
            });
          };

          let unregisterOptionsWatch = $scope.$watch('options', onOptionsSet, true);

          function loadFlashCards() {
            flashcardWords($scope.collection, $scope.level, $scope.lesson).then(function (results) {
              let words = results.map(function (res) {
                return {
                  word: res.word,
                  definition: $sce.trustAsHtml(res.description),
                  image: res.image,
                  imageAltText: res.image_alt_text,
                  footer: res.footer
                };
              });
              if ($scope.random) {
                $scope.flashcards = randomize.shuffle(words);
              } else {
                $scope.flashcards = words;
              }
            });
          };

          function loadAnswers() {
            let isInactive = context.getViewingInactiveExperience();
            ElementsRestService.getSharedState(context.experienceId, element.id, context.groupName, isInactive,
              function (result) {
                if (result && result.length) {
                  result.forEach(function (answer) {
                    $scope.respondents.push({'id': answer.user_id, 'type': RespondentType.USER});
                  });
                  $scope.portionResponded = ($scope.respondents.length) / context.clazz.students.length;
                }
              },
              function (error) {
                ElementsErrorService.error(error);
              });

            if ($scope.isTeacher) {
              let service = $scope.options.elementRealtimeService;
              let EVENTS = service.EVENTS;

              service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
              $scope.$on('$destroy', function () {
                service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
              });
            }
          }

          function stateChangedNotificationHandler(e) {
            let message = e.detail;
            let state = message.record;
            if (state.element_id != $scope.options.element.id) {
              return;
            }

            $log.debug("Received flashcard state update: " + JSON.stringify(message));

            if (state.user_id) {
              state.user_id = parseInt(state.user_id, 10);
            }

            if (state.user_id && state.user_data) {
              let inList = $scope.respondents.find(function (respondent) {
                return respondent.id === state.user_id;
              });
              if (!inList) {
                $scope.respondents.push({'id': state.user_id, 'type': RespondentType.USER});
                $scope.portionResponded = ($scope.respondents.length) / context.clazz.students.length;
              }
            }
          }

          function onOptionsSet() {
            if ($scope.options.context) {
              // if not yet initialized
              if (!$scope.initialized) {
                context = $scope.options.context;
                element = $scope.options.element;
                parseElement();
                $scope.isTeacher = context.userIsTeacher();
                $scope.selectedRespondent = context.userId;
                loadFlashCards();
                if ($scope.isTeacher) {
                  loadAnswers();
                }
                $scope.initialized = true;
              }
            }
          }

          $scope.hasResponses = function () {
            return $scope.respondents.length;
          };

          function submitChange() {
            if ($scope.isTeacher) {
              return;
            }
            ElementsRestService.getUserState(context.experienceId, element.id, $scope.selectedRespondent, function (result, error) {
              if (!result || !result.user_data) {
                let response = {
                  viewed: true
                };
                ElementsRestService.saveUserState(context.experienceId, element.id, $scope.selectedRespondent, 0, response,
                  function () {
                  },
                  function (error) {
                    ElementsErrorService.error(error);
                  });
              }
            });
          }

          $scope.onPrevious = function () {
            $scope.showFront = true;
            $scope.isFlipped = false;
            if ($scope.currentCard > 0) {
              $scope.currentCard = $scope.currentCard - 1;
            } else {
              $scope.currentCard = $scope.flashcards.length - 1;
            }
            submitChange();
          };

          $scope.onFlip = function () {
            $scope.isFlipping = true;
            $timeout(function () {
              $scope.isFlipping = false;
            }, 250);
            $scope.showFront = !$scope.showFront;
            $scope.isFlipped = !$scope.isFlipped;
            submitChange();
          };

          $scope.onNext = function () {
            $scope.showFront = true;
            $scope.isFlipped = false;
            if ($scope.currentCard < $scope.flashcards.length - 1) {
              $scope.currentCard = $scope.currentCard + 1;
            } else {
              $scope.currentCard = 0;
            }
            submitChange();
          };

          $scope.selectRespondent = function (respondentId) {
            $scope.selectedRespondent = respondentId;
          };

          $scope.wrapRespondent = function (respondent) {
            let wrappedRespondent = new $scope.Respondent(respondent.id);
            wrappedRespondent.getType = function () {
              return respondent.type;
            };

            wrappedRespondent.isSelected = function () {
              return respondent.id === $scope.selectedRespondent;
            };

            wrappedRespondent.isCheckMark = function () {
              return respondent.showCheckMark;
            };

            wrappedRespondent.select = function () {
              $scope.selectRespondent(respondent.id);
            };

            wrappedRespondent.getOverlay = function getOverlay() {
              return null;
            };

            return wrappedRespondent;
          };

        }]);

  angular.module('client.services').factory('flashcardWords', ['$resource', 'api_server', function ($resource, api_server) {
    var getFlashcardsResource = $resource(api_server + '/wordlist/collection/:collection/level/:level/lesson/:lesson',
      {collection: '@collection', level: '@level', lesson: '@lesson'},
      {
        'get': {method: 'GET', isArray: true}
      });

    function getFlashcardWords(collection, level, lesson) {
      return getFlashcardsResource.get({collection: collection, level: level, lesson: lesson}).$promise;
    }

    return getFlashcardWords;
  }]);

})();
