'use strict';

/*jshint -W083 */

/* Controllers */
//

clientControllers.controller('PackCtrl', ['$scope', '$rootScope', '$routeParams', '$window',
  '$location', 'User', 'PackDetails', 'JSONStringUtility', 'ActivityStateFactory', 'ActiveExperience',
  '$log', 'VideoStreamingService', 'StudentStreamingService', 'TeacherStreamingService',
  function ($scope, $rootScope, $routeParams, $window, $location, User, PackDetails,
            JSONStringUtility, ActivityStateFactory, ActiveExperience,
            $log, VideoStreamingService, StudentStreamingService, TeacherStreamingService) {

    // Extract the experience Id from the URL
    var experienceId = parseInt($routeParams.id, 10);
    var type = $routeParams.type;
    $scope.scenePacks = [];
    $scope.resource_pack_suffix = '-blue';
    $scope.active_page = "pack";
    $scope.lastSceneIndex = -1;

    $scope.packtype = $scope.userIsTeacher() ? "teacher" : "student";

    // Set the correct highlighting of tab based on the type
    $scope.radioModelPack = type == "teacher" ? 300 : 400;

    $scope.isPastExperience = function () {
      return $scope.experience && $scope.experience.status == "INACTIVE";
    };

    $scope.validationFailed = function () {
      return false;
    };

    $scope.getLastIndex = function () {
      if (!$scope.userIsTeacher()) {
        ActivityStateFactory.get({}, {'id': experienceId, 'user_id': User.getId()}, function (state) {
          if (state !== null && (state.max_scene_id || $scope.isPastExperience())) {
            // Find the index of the scene with this scene id
            var index = 0;
            $scope.experience.activity.content.scenes.forEach(function (scene) {
              // if the scene id matches then this is the last unlocked scene
              if (scene.id == state.max_scene_id)
                $scope.lastSceneIndex = index;

              // increment the index
              index++;
            });

            // Check for a matching reading level
            if ($scope.experience.reading_group_id && $scope.experience.reading_group_id > 0) {
              // Find the current student in the experience so we know what reading_level we should filter on
              var currentStudent = $scope.experience.students.find(function (student) {
                return student.user_id === User.getId();
              });
              if (currentStudent) {
                $scope.student_reading_level = currentStudent.reading_level;
              }
            }

            // Load the pack information from the experience
            $scope.scenePacks = $scope.getPackList($scope.experience.pack.containers, type == 'teacher');
          }
        });
      }
      else {
        // Load the pack information from the experience
        $scope.scenePacks = $scope.getPackList($scope.experience.pack.containers, type == 'teacher');
      }
    };

    // Function that loads the experience
    $scope.getExperience = function () {
      ActiveExperience.getExperience(experienceId).then( function (experience) {
        // Save the experience info
        $scope.experience = experience;

        // Set the Experience Preview mode
        $scope.previewEnabled = experience.preview;

        // Get the last scene index
        $scope.getLastIndex();

        if (!experience.preview &&  experience.status === 'ACTIVE') {
          // Initialize streaming to current experience
          if ($scope.userIsStudent()) {
            StudentStreamingService.initStudentStreaming($scope.experience.id);
          } else {
            TeacherStreamingService.initTeacherStreaming($scope.experience.id);
          }

          VideoStreamingService.setCurrentScene($routeParams.sceneid);
          $scope.shareVideo = TeacherStreamingService.isTeacherStreamingVideo();
        }

      })
      .catch(function (err) {
        $log.error("error in get class_activity_pack:", err);
      });
    };

    // Load the experience on startup
    $scope.getExperience();

    // ----------------------------
    // Functions
    // ----------------------------
    $scope.getPackList = function (containers, teacherItems) {
      var scenePackList = [];
      var packList = [];
      for (var itemIndex in containers) {

        // Flag to monitor buckets of SCENE resource and General Resources
        var sceneType = containers[itemIndex].type;
        var sceneName = containers[itemIndex].name;
        if (sceneType == 'experience')
          sceneName = 'General';

        // Authoring doesn't capatilize the name so we will do it manually if necessary
        if (sceneName.charAt(0) == 's')
          sceneName = 'S' + sceneName.substr(1, sceneName.length - 1);

        // Now, get just data in resources
        for (var resourceIndex in containers[itemIndex].resources) {
          // Get only the correct system roles(student or teacher)
          if (teacherItems && containers[itemIndex].resources[resourceIndex].system_role == 'teacher' ||
            !teacherItems && containers[itemIndex].resources[resourceIndex].system_role == 'student') {
            var packAsset = {};

            // Set the media type
            packAsset.media_type = containers[itemIndex].resources[resourceIndex].type;

            // First, handle the configuration parameters.  This needs to be converted to JSON object
            var config = JSONStringUtility.parse(containers[itemIndex].resources[resourceIndex].config);

            // Iterate over all the values in the config
            config.attributes.forEach(function (attribute) {
              var name = attribute.name;
              var value = attribute.value;
              switch (name) {
                case "title" :
                  packAsset.title = value;
                  break;
                case "description" :
                  packAsset.description = value;
                  break;
                case "url" :
                  packAsset.asset = value;
                  break;
                case "reading_level" :
                  packAsset.reading_level = parseInt(value, 10);
                  break;
              }
            });

            // assets is in a loop but there should only ever really be just one per pack
            var assets = containers[itemIndex].resources[resourceIndex].assets;
            assets.forEach(function (asset) {
              packAsset.id = asset.id;
              packAsset.xid = asset.xid;
              packAsset.url = asset.url;
              packAsset.reading_level = asset.reading_level;
            });

            // For any summary type pack we need to pass the user type
            if (packAsset.reading_level == 1) {
              packAsset.asset = packAsset.asset + "?system_role=";
              if ($scope.userIsTeacher()) {
                packAsset.asset = packAsset.asset + "teacher";
              } else {
                packAsset.asset = packAsset.asset + "student";
              }
            }

            // If this is a student and they have the correct reading level or if there is no specified reading level
            var includePackItem = !$scope.student_reading_level || ($scope.student_reading_level && !packAsset.reading_level) ||
                                  $scope.student_reading_level === packAsset.reading_level || packAsset.reading_level === -1;

            // Add this pack item to the list
            if (includePackItem) {
              packList.push(packAsset);
            }
          }
        }
        // All Done, with this container--
        if (packList.length > 0) {
          var oPackScene = {};
          oPackScene.sceneName = sceneName;
          oPackScene.packs = packList;

          // If this is a valid scene then add it to the list
          if (validPack(sceneName))
            scenePackList.push(oPackScene);
        }
        packList = [];
      }
      return scenePackList;
    };

    function validPack(studentPackScene) {
      // Handle General
      if (studentPackScene.indexOf("General") > -1)
        return true;

      // if no locks then all packs are available
      if ($scope.lastSceneIndex === -1)
        return true;

      // Find the scene index at the end of the scene name
      var indexOnly = 0;
      var matches = studentPackScene.match(/\d+$/);
      if (matches) {
        // The first element will be the number
        indexOnly = parseInt(matches[0], 10);

        // Index is zero based
        return isNaN(indexOnly) || indexOnly <= ($scope.lastSceneIndex + 1);
      }
    }

    $scope.getPackUrl = function (pack) {
      if (!pack) return;

      if (pack.url) {
        return pack.url;
      }

      return !/^(f|ht)tps?:\/\//i.test(pack.asset) ? "http://" + pack.asset : pack.asset;
    };

    $scope.onChangePack = function (type) {
      $location.path('/experience/' + $scope.experience.id + '/pack/' + type);
    };

  }]);


