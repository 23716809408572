'use strict';

angular.module('client.services').factory('epsHelpService', ['User', 'LicensePlans',
                            function (User, LicensePlans) {

  var hasEPSPlan = -1;
  var eps = {ww: false, spire: false, other: false};

  function CheckLicensePlans() {
    return LicensePlans.getAssignable({'userId': User.getId()}).$promise.then(function (result) {
      eps = {ww: false, spire: false, other: false};
      if (result && result.length) {
        result.forEach(function (license) {
          if (license.plan_token.toLowerCase() === 'spire' || license.plan_token.toLowerCase() === 'ispire') {
            eps.spire = true;
          } else if (license.plan_token.toLowerCase() === 'wordlywise') {
            eps.ww = true;
          } else {
            eps.other = true;
          }
        });
      }
      hasEPSPlan = (eps.ww || eps.spire) && !eps.other;
      return hasEPSPlan;
    });
  }

  function showHelp() {
    if (hasEPSPlan != -1 && !User.getId()) {
      hasEPSPlan = -1;
    }
    if (hasEPSPlan != -1) {
      return hasEPSPlan;
    } else if (User.getId()) {
      hasEPSPlan = false;
      if (User.isTeacher()) {
        return CheckLicensePlans().then(function(result) {
          return hasEPSPlan;
        });
      }
    } else {
      return false;
    }
  }

  function getEps() {
    return eps;
  }

  function reset() {
    hasEPSPlan = -1;
  }

  return {
    showHelp: showHelp,
    getEps: getEps,
    reset: reset
  };

}]);