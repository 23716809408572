'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = ['$q', 'ActiveMode', 'spireReportingService', 'spireReportingModels', 'reportToCSV', 'spireExportService', '$routeParams', 'rosterService'];

function controller ($q, ActiveMode, spireReportingService, spireReportingModels, reportToCSV, spireExportService, $routeParams, rosterService) {
  var ctrl = this;

  var views = [
    {label: 'Placement Test', value: spireReportingModels.viewValues.placement},
    {label: 'Concept Mastery Fluency Drill', value: spireReportingModels.viewValues.cmfd},
    {label: 'Concept Assessment', value: spireReportingModels.viewValues.ca},
    {label: 'Pre/Post-Test', value: spireReportingModels.viewValues.prepost},
    {label: 'Mid-Level Test', value: spireReportingModels.viewValues.mid}
  ];

  ctrl.inited = false;
  ctrl.views = views;
  ctrl.viewValues = spireReportingModels.viewValues;

  ctrl.hasDistrict = false;
  ctrl.hasStatus = false;

  ctrl.data = {};
  ctrl.data.view = views[0];
  var viewState = {};
  ctrl.exportLevels = [];
  ctrl.showlevels = false;
  ctrl.currentStatuses = null;

  // See if there is a class specified on the URL as a parameter.  This is used for drill down to class data
  var filter = {};
  if ($routeParams.class) {
    filter.class_id = $routeParams.class;
    ActiveMode.getTitle().then(function(className){
      ctrl.class = {
        cid: parseInt(filter.class_id, 10),
        class_name: className
      }
    });
  }

  if ($routeParams.classId) {
    ActiveMode.getTitle().then(function(className){
      ctrl.class = {
        cid: parseInt($routeParams.classId, 10),
        class_name: className
      }
    });
  }

  ActiveMode.getReportData(filter).then(function(data) {
    parseReportData(data);
    ctrl.inited = true;
  });

  function updateReportData(statuses) {
    // If this report is being filtered by status then the data needs to be re-queried
    if (statuses && statuses.length > 0) {
      if (ctrl.currentStatuses && ctrl.currentStatuses.length == statuses.length && statuses.every(function(value, index) {return value.id === ctrl.currentStatuses[index].id; })) {
        return $q.when();
      }
      ctrl.currentStatuses = statuses;
      var statusIds = statuses.map(function(status) { return status.id; });
      var statusReq = {};
      if (statusIds[0] != "All") {
        statusReq.statuses = statusIds;
      }
      if ($routeParams.class) {
          statusReq.class_id = $routeParams.class;
      }
      return ActiveMode.getReportData(statusReq).then(function(data) {
        parseReportData(data);
        return;
      });
    } else {
      return $q.when();
    }
  }

  ctrl.onRefresh = function(view, level, currentClass, statuses) {
    // Just set the current view in the controller
    if (view) {
      ctrl.data.view = view;
      if (view.value !== views[0].value) {
        ctrl.showlevels = true;
      } else {
        ctrl.showlevels = false;
      }
    }

    updateReportData(statuses).then(function() {
      // Filter data by specified level
      if (level) {
        ctrl.data.level = level.id;
        ctrl.exportLevels = [level.id];
      }

      // filter data by specified class assuming we allow filtering by classid
      if (ActiveMode.isSubscriptionMode() && currentClass && currentClass.id)
        ctrl.data.classId = currentClass.id;
    });
  };

  ctrl.downloadCSV = reportToCSV(
      function () {
        return spireExportService.parseExportColumns(ctrl.exportData, ctrl.exportLevels, false, false, ctrl.data.view.value, ctrl.viewValues, ctrl.hasDistrict, ctrl.hasStatus);
      }, function () {
        return spireExportService.parseExportData(ctrl.exportData, ctrl.exportLevels, null, ctrl.data.view.value, ctrl.viewValues, [ctrl.class], ctrl.hasDistrict, ctrl.hasStatus);
      }, function () {
        return spireExportService.getTitle(ctrl.parent.title, ctrl.exportLevels, ctrl.data.view.label) + '.csv';
      },
      ','
    );

  function parseReportData(data) {
    ctrl.exportData = data;
    var classes = spireReportingService.parseClassNames(data.students);
    var students = spireReportingService.parseStudents(data.students, data.otherStudents);
    var lessons = spireReportingService.parseToLevels(data.lessons, 'lesson');
    var concepts = spireReportingService.parseToLevels(data.concepts, 'concept');
    var levels = spireReportingService.getLevels(lessons);

    students.forEach(function(student) {
      ctrl.hasDistrict = ctrl.hasDistrict || student.lms_source;
      ctrl.hasStatus = ctrl.hasStatus || student.learning_status_id;
    });

    ctrl.models = {};
    ctrl.models[ctrl.viewValues.placement] = spireReportingModels.getPlacementTableModel(students, data.levels, data.placements);
    ctrl.models[ctrl.viewValues.ca] = spireReportingModels.getCAStudentDataTableModel(students, lessons, data.ca);
    ctrl.models[ctrl.viewValues.cmfd] = spireReportingModels.getCMFDStudentDataTableModel(students, concepts, data.cmfd);
    ctrl.models[ctrl.viewValues.prepost] = spireReportingModels.getPrePostTableModel(students, data.pre);
    ctrl.models[ctrl.viewValues.mid] = spireReportingModels.getMidTableModel(students, lessons, data.mid);

    // Initialize local levels reference.  Set default level to last (latest)
    ctrl.levels = levels;
    if (levels.length > 0 && !ctrl.data.level) {
      ctrl.data.level = ctrl.levels[ctrl.levels.length - 1];
      ctrl.exportLevels = [ctrl.data.level];
    }

    // Create a list for filter display
    ctrl.filterLevels = [];
    levels.forEach(function(level) {
      var name = "Level " + level;
      ctrl.filterLevels.push({id: level, name: name });
    });

    // Set classes for use in filter
    if (classes && classes.length) {
      ctrl.classes = classes;
      if (!ctrl.data.classId) {
        ctrl.data.classId = classes[0].id;
      }
    }
    else if (data.class_id) {
      ctrl.data.classId = data.class_id;
    }

    rosterService.getLearningStatus(-6).then(function(statuses) {
      ctrl.statuses = statuses.map(function(status) {
        return {
          id: status.id,
          name: status.learning_status_name
        }
      });
    });
  }

  ctrl.reportHasData = function() {
    return (ctrl.levels && ctrl.data.level && ctrl.levels.length > 0) || (ctrl.models[ctrl.viewValues.placement].getHasPlacementData());
  };
}

module.component('xpSpireClassLevelProgress', {
  require: {parent: '^^xpReport'},
  template: require('./spireClassLevelProgress.jade'),
  controller: controller,
});

})();
