'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['$scope', '$resource', '$routeParams', '$log', 'ImportValidation', 'importTeachersClassesStudents',
                      'xpAlert', 'ModalService', 'LicenseValidation', '$q', 'User'];

function controller($scope, $resource, $routeParams, $log, ImportValidation, importTeachersClassesStudents,
                  xpAlert, ModalService, LicenseValidation, $q, User) {
  var ctrl = this;
  ctrl.currentPage = "SelectFile";
  ctrl.isImporting = false;

  // Show the default alert message when first trying to import
  var noStudentsMsg = "Before importing your class, we recommend you read <a href=\"http://www.exploros.com/sso\" target=_blank>" +
                      "Student Single Sign On (SSO) Best Practices</a>.";
  xpAlert.success(noStudentsMsg, true);

  ctrl.subscriptionId = $routeParams.subscriptionId;

  ctrl.inputData = function() {
    xpAlert.clearAlerts();
    ctrl.currentPage = 'SelectFile';
  };

  ctrl.onImportClasses = function(files) {
    if (files.length > 0) {
      var file = files[0];
      if (isCSVFile(file)) {
        var reader = new FileReader();
        reader.onload = onLoadClassFile;
        reader.readAsText(file);
      }
    }
  };

  function onLoadClassFile(event) {
    var fileContents = event.target.result;

    // Look for the first Line Feed.  If this file was created on the Mac, it will only have a carriage return so look for that if no line feed
    var endOfFirstLine = fileContents.indexOf("\n");
    if (endOfFirstLine == -1)
      endOfFirstLine = fileContents.indexOf("\r");

    // The data to import is everything after the header line
    if (endOfFirstLine !== -1) {
      ctrl.studentcsv = fileContents.substring(endOfFirstLine + 1);
    }
    else {
      ctrl.studentcsv = "";
    }

    // Validate the contents from the file
    ctrl.parseAndValidateStudents();
  }

  ctrl.parseAndValidateStudents = function() {
    // Get the import and convert it to JSON to work with
    var content = {csv: ctrl.studentcsv ? ctrl.studentcsv : "", separator: ",", header: true, classImport: true };
    ctrl.students = ImportValidation.convertToJSON(content);

    if (ctrl.students.length === 0) {
      xpAlert.clearAlerts();
      xpAlert.error("Invalid Import file. No students found.");
      return;
    }

    // if the teachers and classes are valid then move to the next screen
    validateTeachersAndClasses().then(function(validClasses) {
      if (validClasses) {
        // Check to make sure all the teachers have access to the current license subscription
        validateAvailableLicenses().then(function(validLicenses) {
          // if the teachers all had access to this license then move to the next screen
          if (validLicenses) {
            // Check the individual students for validity
            ctrl.validateAllStudents();
          }
        });
      }
    });
  };

  function validateTeachersAndClasses() {
    return ImportValidation.validateTeachersClasses(ctrl.students).then(function(errorMsg){
      // Display any errors
      if (errorMsg.length > 0) {
        xpAlert.clearAlerts();
        xpAlert.error(errorMsg);
      }
      // Set error state if any errors with the students
      ctrl.hasErrors = errorMsg.length > 0;
      return !ctrl.hasErrors;
    });
  }

  function validateStudents() {
    return ImportValidation.validateImportStudents(ctrl.students, true).then(function(students){
      ctrl.students = students;

      // Check for any errors in the students
      ctrl.hasErrors = ImportValidation.checkForErrors(students);

      // Tell the user if there are any errors
      xpAlert.clearAlerts();
      if (ctrl.hasErrors)
        xpAlert.error("You have conflicts in your import data. You must resolve all conflicts before you can import.");
      else
        xpAlert.success("Review the students to import. Click Next when ready.");

      return !ctrl.hasErrors;
    });
  }

  ctrl.validateAllStudents = function() {
    // Check the individual students for validity
    validateStudents().then(function(validStudents){
      ctrl.currentPage = 'ValidateStudents';
    });
  };

  ctrl.previewStudents = function() {
    if (!ctrl.hasErrors) {
      xpAlert.clearAlerts();
      xpAlert.success("Review the classes and students. Click Import when ready.");
      ctrl.currentPage = 'ReviewStudents';
    }
  };

  function validateAvailableLicenses() {
    // Get a unique list of teacher names contained in the import file
    var teacherNames =  ctrl.students.map(function(student) {
      return student.teacher;
    });
    var uniqueTeachers = teacherNames.filter(function(name, index, self) {
      return self.indexOf(name) === index;
    });

    // Call the service to see if the user has the licenses
    return LicenseValidation.post({'subid': ctrl.subscriptionId, 'teachers': uniqueTeachers}).$promise.then(function(teachers) {
      // Iterate the results to see if each teacher has access
      var errors = "";
      teachers.forEach(function(teacher) {
        // if any error then add it to display string
        if (teacher.status === 'error') {
          errors += errors.length > 0 ? " and " : "";
          errors += "Teacher " + teacher.teacher + " does not have access to the current subscription.";
        }
      });

      // if there are any teachers that don't support this license plan then inform the user
      if (errors.length > 0) {
        xpAlert.error(errors, true);
        return false;
      }
      else {
        return true;
      }
    });
  }

  ctrl.removeStudent = function(student) {
    ctrl.students = ctrl.students.filter(function(st) {
      return st != student;
    });

    // This will re-check all the students
    ctrl.validateAllStudents();
  };

  ctrl.importStudents = function () {
    if (!ctrl.isImporting) {
      ctrl.isImporting = true;
      importTeachersClassesStudents.import({}, { 'subid': ctrl.subscriptionId, 'students': angular.toJson(ctrl.students)})
      .$promise.then(function(result) {
        if (result && result.isError && result.exceeded) {
          xpAlert.error("You cannot import these new students to this class because you would exceed your " + result.subscriptionName + " seat limit by " + result.exceeded + ".", true);
        }
        else if (result && (result.error || result.isError)) {
          xpAlert.error("Something went wrong, please try again later.", true);
        }
        else {
          xpAlert.success("Success! The following accounts were added. Save the passwords for your records; they cannot be shown again.", true);
          ctrl.importedClasses = result.classes;
          ctrl.currentPage = "StudentsImported";
        }
        ctrl.isImporting = false;
      })
      .catch(function(error){
        $log.error("An error occurred importing these students. " + error);
        ctrl.isImporting = false;
        xpAlert.error("Something went wrong, please try again later.", true);
      });
    }
  };

  ctrl.emailStudents = function()
  {
    // Send import data to the server
    importTeachersClassesStudents.sendEmail({}, { 'subid': ctrl.subscriptionId, 'userId': User.getId(), 'students': ctrl.importedClasses})
    .$promise.then(function(result) {
      ModalService.show(
        {
          title: 'Email Sent',
          message: 'An email containing the student account information was sent to you and the teacher for each class.',
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        }
      );
    });
  };

  function onLoadTeacherFile(event) {
    var fileContents = event.target.result;

    // Look for the first Line Feed.  If this file was created on the Mac, it will only have a carriage return so look for that if no line feed
    var endOfFirstLine = fileContents.indexOf("\n");
    if (endOfFirstLine == -1)
      endOfFirstLine = fileContents.indexOf("\r");

    // The data to import is everything after the header line
    if (endOfFirstLine !== -1) {
      ctrl.teachercsv = fileContents.substring(endOfFirstLine + 1);
    }
    else {
      ctrl.teachercsv = "";
    }

    // Validate the contents from the file
    ctrl.parseAndValidateTeachers();
  }

  ctrl.parseAndValidateTeachers = function() {
    // Get the import and convert it to JSON to work with
    var content = {csv: ctrl.teachercsv ? ctrl.teachercsv : "", separator: ",", header: true, teacherImport: true };
    ctrl.teachers = ImportValidation.convertToJSON(content);

    if (ctrl.teachers.length === 0) {
      xpAlert.clearAlerts();
      xpAlert.error("Invalid Import file. No teachers found.");
      return;
    }

    // Validates all teacher data before importing
    ctrl.validateAllTeachers();
  };

  function validateTeachers() {
    return ImportValidation.validateImportTeachers(ctrl.teachers, ctrl.subscriptionId).then(function(teachers){
      ctrl.teachers = teachers;

      // Check for any errors in the teachers
      ctrl.hasErrors = ImportValidation.checkForErrors(teachers);

      // Tell the user if there are any errors
      xpAlert.clearAlerts();
      if (ctrl.hasErrors)
        xpAlert.error("You have conflicts in your import data. You must resolve all conflicts before you can import.");
      else
        xpAlert.success("Review the teachers to import. Click Next when ready.");

      return !ctrl.hasErrors;
    });
  }

  ctrl.validateAllTeachers = function() {
    // Check the individual teachers for validity
    validateTeachers().then(function(validTeachers){
      ctrl.currentPage = 'ValidateTeachers';
    });
  };

  ctrl.previewTeachers = function() {
    if (!ctrl.hasErrors) {
      xpAlert.clearAlerts();
      xpAlert.success("Review the teachers. Click Import when ready.");
      ctrl.currentPage = 'ReviewTeachers';
    }
  };

  function isCSVFile(file) {
    if (!file.name.endsWith(".csv")) {
      xpAlert.error("Invalid import file. Only csv files can be imported", true);
      return false;
    }
    else {
     return true;
    }
  }

  ctrl.onImportTeachers = function(files) {
    if (files.length > 0) {
      var file = files[0];
      if (isCSVFile(file)) {
        var reader = new FileReader();
        reader.onload = onLoadTeacherFile;
        reader.readAsText(file);
      }
    }
  };

  ctrl.removeTeacher = function(teacher) {
    ctrl.teachers = ctrl.teachers.filter(function(tc) {
      return tc !== teacher;
    });

    // Validates all teacher data before importing
    ctrl.validateAllTeachers();
  };

  ctrl.importTeachers = function () {
    if (!ctrl.isImporting) {
      ctrl.isImporting = true;
      importTeachersClassesStudents.importTeachers({}, { 'subid': ctrl.subscriptionId, 'teachers': angular.toJson(ctrl.teachers)})
      .$promise.then(function(result) {
        if (result && (result.error || result.isError)) {
          xpAlert.error("Something went wrong, please try again later.", true);
        }
        else {
          xpAlert.success("Success! The following teachers were added. Save the passwords for your records; they cannot be shown again.", true);
          ctrl.teachers = result.teachers;
          ctrl.currentPage = "TeachersImported";
        }
        ctrl.isImporting = false;
      })
      .catch(function(error){
        $log.error("An error occurred importing these teachers. " + error);
        ctrl.isImporting = false;
        xpAlert.error("Something went wrong, please try again later.", true);
      });
    }
  };

  ctrl.emailTeachers = function()
  {
    // Send import data to the server
    importTeachersClassesStudents.sendEmail({}, { 'subid': ctrl.subscriptionId, 'userId': User.getId(), 'teachers': ctrl.teachers})
    .$promise.then(function(result) {
      ModalService.show(
        {
          title: 'Email Sent',
          message: 'An email containing the teacher account information was sent to you.',
          buttons: [
            {
              title: 'Ok',
              click: '$hide()'
            }
          ]
        }
      );
    });
  };
}

module.component('xpSubscriptionImport', {
  template: require('./subscriptionImport.jade'),
  controller: controller,
});

})();
