'use strict';

angular.module('client.services').factory('HelpVideoService', ['$resource', '$log', 'api_server',
  function ($resource, $log, api_server) {

    var helpVideoResource = $resource(api_server + '/users/:id/help_videos', {id: '@id'},
      {
        'get': {method: 'GET', isArray: true}
      });

    function getVideos(id) {
      return helpVideoResource.get({id: id}).$promise;
    }

    return {
      getVideos: getVideos
    };
  }]);
