'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = ['$scope', 'tourService'];

  function controller ($scope, tourService) {
    var ctrl = this;

    ctrl.tourSteps = [];
    ctrl.onboardingIndex = null;

    ctrl.$onInit = function() {
      tourService.subscribe($scope, function() {
        ctrl.onboardingIndex = null;
        if (tourService.showTours() && tourService.getTourSteps().length) {
          ctrl.tourSteps = tourService.getTourSteps();
          ctrl.onboardingIndex = tourService.getTourStepIndex();
        }
      });
    };

    ctrl.showOnboardingStep = function() {
      return tourService.showOnboardingStep();
    };

    ctrl.onNext = function() {
      tourService.gotoNextTourStep();
    };

    ctrl.onPrevious = function() {
      tourService.gotoNextTourStep();
    };

    ctrl.exitOnboardingTour = function() {
      tourService.setCancelTour(true);
    };

    ctrl.doneOnboardingTour = function() {
      tourService.saveUserCurrentTourTopic(0);
    };
  }

  module.component('xpOnBoarding', {
    template: require('./onBoarding.jade'),
    controller: controller
  });

})();
