'use strict';

angular.module('client.services').factory('spireExportService', ['spireReportingService', '$rootScope',
  function (spireReportingService, $rootScope) {

    function getTitle(parentTitle, levels, reportType) {
      let title = (parentTitle || 'Progress Report') + ' - ';
      if (levels.length > 1) {
        title = title + 'Levels ';
      } else {
        title = title + 'Level ';
      }
      levels.forEach(function (level, index) {
        if (index > 0) {
          title = title + ",";
        }
        title = title + level;
      });
      title = title + ' - ' + reportType;
      return title;
    }

    function getCoreColumns(isMultipleSchools, isAdmin, includeAttempt, hasDistrict, hasStatus) {
      let csvColumns = [];
      if (isMultipleSchools) {
        csvColumns.push(
          {
            name: 'School',
            field: 'schoolName',
            wrap: true
          }
        );
      }
      csvColumns.push(
        {
          name: 'Level',
          field: 'levelName',
          wrap: true
        }
      );
      if (isAdmin) {
        csvColumns.push(
          {
            name: 'Teacher',
            field: 'teacherName',
            wrap: true
          }
        );
        csvColumns.push(
          {
            name: 'Email',
            field: 'teacherEmail',
            wrap: true
          });
      }
      csvColumns.push(
        {
          name: 'Class',
          field: 'className',
          wrap: true
        }
      );
      csvColumns.push(
        {
          name: 'Student',
          field: 'studentName',
          wrap: true
        }
      );
      csvColumns.push(
        {
          name: 'Username',
          field: 'userName',
          wrap: true
        }
      );
      csvColumns.push(
        {
          name: 'Class Status',
          field: 'inClass',
          wrap: true
        }
      );
      if (hasDistrict) {
        csvColumns.push(
          {
            name: 'District ID',
            field: 'districtId',
            wrap: true
          }
        );
      }
      if (hasStatus) {
        csvColumns.push(
          {
            name: 'Status',
            field: 'status',
            wrap: true
          }
        );
      }
      if (includeAttempt) {
        csvColumns.push(
          {
            name: 'Attempt',
            field: 'attempt',
            wrap: true
          }
        );
      } else {
        csvColumns.push(
          {
            name: 'Date',
            field: 'date',
            wrap: true
          }
        );
      }
      return csvColumns;
    }

    function getCAColumns(isMultipleSchools, isAdmin, columns, hasDistrict, hasStatus) {
      let csvColumns = getCoreColumns(isMultipleSchools, isAdmin, true, hasDistrict, hasStatus);
      columns.forEach(function (column) {
        csvColumns.push({
          name: "\"" + column.export_column + "\"",
          field: 'level_' + column.level + '_lesson_' + column.lesson,
          wrap: true
        });
        csvColumns.push({
          name: 'date',
          field: 'date_' + column.level + '_' + column.lesson,
          wrap: true
        });
      });
      return csvColumns;
    }

    function getCMFDColumns(isMultipleSchools, isAdmin, columns, hasDistrict, hasStatus) {
      let csvColumns = getCoreColumns(isMultipleSchools, isAdmin, true, hasDistrict, hasStatus);
      columns.forEach(function (column) {
        csvColumns.push({
          name: "\"" + column.export_column + "\"",
          field: 'level_' + column.level + '_concept_' + column.concept,
          wrap: true
        });
        csvColumns.push({
          name: 'date',
          field: 'date_' + column.level + '_' + column.concept,
          wrap: true
        });
      });
      return csvColumns;
    }

    function getMidColumns(isMultipleSchools, isAdmin, columns, hasDistrict, hasStatus) {
      let csvColumns = getCoreColumns(isMultipleSchools, isAdmin, false, hasDistrict, hasStatus);
      columns.forEach(function (column) {
        csvColumns.push({
          name: "\"" + column.export_column + "\"",
          field: 'level_' + column.level + '_lesson_' + column.lesson,
          wrap: true
        });
      });
      return csvColumns;
    }

    function getFixedPrePostColumns() {
      let columns = [];
      columns.push(
        {
          name: 'Pre Test DW',
          field: 'ptdw',
          wrap: true
        });
      columns.push(
        {
          name: 'Pre Test DS',
          field: 'ptds',
          wrap: true
        });
      columns.push(
        {
          name: 'Pre Test CWPM',
          field: 'ptcwpm',
          wrap: true
        });
      columns.push(
        {
          name: 'Pre Test Comp',
          field: 'ptcomp',
          wrap: true
        });
      columns.push(
        {
          name: 'Post Test DW',
          field: 'podw',
          wrap: true
        });
      columns.push(
        {
          name: 'Post Test DS',
          field: 'pods',
          wrap: true
        });
      columns.push(
        {
          name: 'Post Test CWPM',
          field: 'pocwpm',
          wrap: true
        });
      columns.push(
        {
          name: 'Post Test Comp',
          field: 'pocomp',
          wrap: true
        });
      return columns;
    }

    function getPrePostColumns(isMultipleSchools, isAdmin, hasDistrict, hasStatus) {
      let csvColumns = getCoreColumns(isMultipleSchools, isAdmin, false, hasDistrict, hasStatus);
      return csvColumns.concat(getFixedPrePostColumns());
    }

    function getPlacementColumns(isAdmin, hasDistrict, hasStatus) {
      let columns = [];
      if (isAdmin) {
        columns.push(
          {
            name: 'Teacher',
            field: 'teacherName',
            wrap: true
          });
        columns.push(
          {
            name: 'Email',
            field: 'teacherEmail',
            wrap: true
          });
        columns.push(
          {
            name: 'Class',
            field: 'className',
            wrap: true
          });
      }
      columns.push(
        {
          name: 'Student',
          field: 'studentName',
          wrap: true
        });
      columns.push(
        {
          name: 'Username',
          field: 'userName',
          wrap: true
        });
      columns.push(
        {
          name: 'Class Status',
          field: 'inClass',
          wrap: true
        }
      );
      if (hasDistrict) {
        columns.push(
          {
            name: 'District ID',
            field: 'districtId',
            wrap: true
          });
      }
      if (hasStatus) {
        columns.push(
          {
            name: 'Status',
            field: 'status',
            wrap: true
          });
      }
      columns.push(
        {
          name: "Level",
          field: "level",
          wrap: true
        });
      columns.push(
        {
          name: "Date",
          field: "date",
          wrap: true
        });
      columns.push(
        {
          name: "Errors",
          field: "errors",
          wrap: true
        });
      columns.push(
        {
          name: "Teacher Comment",
          field: "comment",
          wrap: true
        });

      return columns;
    }

    function parseExportColumns(data, levels, isMultipleSchools, isAdmin, currentView, viewValues, hasDistrict, hasStatus) {
      let columns = [];
      if (currentView === viewValues.ca || currentView === viewValues.mid) {
        let numericLevels = levels.map(function (level) {
          return parseInt(level, 10);
        });
        let lessons = spireReportingService.parseToLevels(data.lessons, 'lesson');
        Object.keys(lessons).forEach(function (key) {
          columns = columns.concat(lessons[key].filter(function (column, index) {
            let midColumnCount = column.level === 3 ? 4 : 5;
            return (numericLevels.includes(column.level) && (currentView === viewValues.ca || index < midColumnCount));
          }));
        });
        if (currentView === viewValues.ca) {
          return getCAColumns(isMultipleSchools, isAdmin, columns, hasDistrict, hasStatus);
        } else {
          return getMidColumns(isMultipleSchools, isAdmin, columns, hasDistrict, hasStatus);
        }
      } else if (currentView === viewValues.cmfd) {
        let numericLevels = levels.map(function (level) {
          return parseInt(level, 10);
        });
        let concepts = spireReportingService.parseToLevels(data.concepts, 'concept');
        Object.keys(concepts).forEach(function (key) {
          columns = columns.concat(concepts[key].filter(function (column) {
            return numericLevels.includes(column.level);
          }));
        });
        return getCMFDColumns(isMultipleSchools, isAdmin, columns, hasDistrict, hasStatus);
      } else if (currentView === viewValues.prepost) {
        return getPrePostColumns(isMultipleSchools, isAdmin, hasDistrict, hasStatus);
      } else if (currentView === viewValues.placement) {
        return getPlacementColumns(isAdmin, hasDistrict, hasStatus);
      }

      return [];
    }

    function getExportClassInfo(classes, classId, attempt) {
      let row = {attempt: attempt};
      let classRec = classes.find(function (cls) {
        return cls.cid === classId;
      });
      if (classRec) {
        row.classId = classRec.cid;
        row.schoolName = classRec.school_name;
        row.teacherName = classRec.first_name + " " + classRec.last_name;
        row.teacherEmail = classRec.email;
        row.className = classRec.class_name;
      }
      return row;
    }

    function getExportStudentInfo(students, studentId, row) {
      let student = students.find(function (student) {
        return student.id === studentId;
      });
      row.studentId = studentId;
      if (student) {
        row.studentName = student.first_name + ' ' + student.last_name;
        row.userName = student.username;
        row.inClass = student.inClass ? "In Class" : "Not Currently in Class";
        row.districtId = student.districtId;
        row.status = student.status;
      }
    }

    function getCMFDRow(classes, students, csvRows, score) {
      let matchingRow = null;
      let attempt = 1;

      csvRows.forEach(function (row) {
        if (row.classId === score.class_id && row.studentId === score.student_id && row.levelName === score.level) {
          if (!row["level_" + score.level + "_concept_" + score.concept]) {
            matchingRow = row;
          } else {
            attempt = attempt + 1;
          }
        }
      });

      if (!matchingRow) {
        matchingRow = getExportClassInfo(classes, score.class_id, attempt);
        getExportStudentInfo(students, score.student_id, matchingRow);
        matchingRow.levelName = score.level;
        csvRows.push(matchingRow);
      }

      return matchingRow;
    }

    function getCMFDStudentExportData(classes, students, levels, filteredClasses, scores) {
      spireReportingService.sortScoresByDate(scores);
      let csvRows = [];
      scores.forEach(function (score) {
        if (levels.includes(score.level) && (!filteredClasses || filteredClasses.includes(score.class_id))) {
          let row = getCMFDRow(classes, students, csvRows, score);
          if (row) {
            row["level_" + score.level + "_concept_" + score.concept] = Math.round(score.score);
            row["date_" + score.level + "_" + score.concept] = $rootScope.dateTimeFormatter(score.date, false);
          }
        }
      });

      spireReportingService.sortRowsByClassAndStudent(csvRows);
      return csvRows;
    }

    function getCARow(classes, students, csvRows, score) {
      let matchingRow = null;
      let attempt = 1;

      csvRows.forEach(function (row) {
        if (row.classId === score.class_id && row.studentId === score.student_id && row.levelName === score.level) {
          if (!row["level_" + score.level + "_lesson_" + score.lesson]) {
            matchingRow = row;
          } else {
            attempt = attempt + 1;
          }
        }
      });

      if (!matchingRow) {
        matchingRow = getExportClassInfo(classes, score.class_id, attempt);
        getExportStudentInfo(students, score.student_id, matchingRow);
        matchingRow.levelName = score.level;
        csvRows.push(matchingRow);
      }

      return matchingRow;
    }

    function getCAStudentExportData(classes, students, levels, filteredClasses, scores) {
      spireReportingService.sortScoresByDate(scores);
      let csvRows = [];
      scores.forEach(function (score) {
        if (levels.includes(score.level) && (!filteredClasses || filteredClasses.includes(score.class_id))) {
          let row = getCARow(classes, students, csvRows, score);
          if (row) {
            row["level_" + score.level + "_lesson_" + score.lesson] = Math.round(score.score);
            row["date_" + score.level + "_" + score.lesson] = $rootScope.dateTimeFormatter(score.date, false);
          }
        }
      });

      spireReportingService.sortRowsByClassAndStudent(csvRows);
      return csvRows;
    }

    function getPrePostStudentExportData(classes, students, levels, filteredClasses, scores) {
      spireReportingService.sortScoresByDate(scores);
      let csvRows = [];
      scores.forEach(function (score) {
        if (levels.includes(score.level) && (!filteredClasses || filteredClasses.includes(score.class_id))) {
          let row = getExportClassInfo(classes, score.class_id, 1);
          getExportStudentInfo(students, score.student_id, row);
          row.date = $rootScope.dateTimeFormatter(score.date, false);
          row.levelName = score.level;
          if (score.prepost === 'pre') {
            row.ptdw = Math.round(score.decodable_words_score);
            row.ptds = Math.round(score.decodable_sentences_score);
            row.ptcwpm = score.passage_correct;
            row.ptcomp = Math.round(score.comprehension_score);
          } else {
            row.podw = Math.round(score.decodable_words_score);
            row.pods = Math.round(score.decodable_sentences_score);
            row.pocwpm = score.passage_correct;
            row.pocomp = Math.round(score.comprehension_score);
          }
          csvRows.push(row);
        }
      });
      spireReportingService.sortRowsByClassAndStudent(csvRows);
      return csvRows;
    }

    function getMidLevelStudentExportData(classes, students, levels, filteredClasses, scores) {
      spireReportingService.sortScoresByDate(scores);
      let csvRows = [];
      scores.forEach(function (score) {
        if (levels.includes(score.level) && (!filteredClasses || filteredClasses.includes(score.class_id))) {
          let row = getCARow(classes, students, csvRows, score);
          if (row) {
            row["level_" + score.level + "_lesson_" + score.lesson] = Math.round(score.score);
            row["date"] = $rootScope.dateTimeFormatter(score.date, false);
          }
        }
      });

      spireReportingService.sortRowsByClassAndStudent(csvRows);
      return csvRows;
    }

    function getPlacementRow(classes, students, csvRows, levels, placement) {
      let newRow = getExportClassInfo(classes, placement.class_id);
      getExportStudentInfo(students, placement.student_id, newRow);
      let currentLevel = levels.find(function (level) {
        return level.id === placement.placement_id;
      });
      if (currentLevel) {
        newRow["level"] = currentLevel.level_name;
      }
      csvRows.push(newRow);

      return newRow;
    }

    function getPlacementStudentExportData(classes, students, filteredClasses, levels, placements) {
      let csvRows = [];
      placements.forEach(function (placement) {
        if (!filteredClasses || filteredClasses.includes(placement.class_id)) {
          let row = getPlacementRow(classes, students, csvRows, levels, placement);
          if (row) {
            row["date"] = window.moment(placement.updated_at).format('M/DD/YY');
            row["errors"] = placement.incorrect_word_count;
            row["comment"] = placement.comment;
          }
        }
      });

      return csvRows;
    }

    function parseExportData(data, filteredlevels, filteredClasses, currentView, viewValues, exportClasses, hasDistrict, hasStatus) {
      let classes = [];
      if (data.classes) {
        classes = spireReportingService.parseClasses(data.classes);
      } else if (exportClasses) {
        classes = exportClasses;
      }

      let students = spireReportingService.parseStudents(data.students, data.otherStudents);
      let numericLevels = filteredlevels.map(function (level) {
        return parseInt(level, 10);
      });

      if (currentView === viewValues.cmfd) {
        return getCMFDStudentExportData(classes, students, numericLevels, filteredClasses, data.cmfd);
      } else if (currentView === viewValues.ca) {
        return getCAStudentExportData(classes, students, numericLevels, filteredClasses, data.ca);
      } else if (currentView === viewValues.prepost) {
        return getPrePostStudentExportData(classes, students, numericLevels, filteredClasses, data.pre);
      } else if (currentView === viewValues.mid) {
        return getMidLevelStudentExportData(classes, students, numericLevels, filteredClasses, data.mid);
      } else if (currentView === viewValues.placement) {
        return getPlacementStudentExportData(classes, students, filteredClasses, data.levels, data.placements);
      } else {
        return [];
      }
    }

    return {
      getTitle: getTitle,
      parseExportColumns: parseExportColumns,
      parseExportData: parseExportData
    };
  }]);
