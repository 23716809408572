'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = [];

  function controller () {
    var ctrl = this;
    ctrl.chartType = 'line';
    ctrl.width = 600;
    ctrl.height = 400;

    ctrl.chartOptions = {
      title: {
        display: true,
        text: "Line Chart"
      },
      legend: {
        display: false
      },
      elements: {
        point: {
          radius: 2
        },
        line: {
          borderWidth: 1
        }
      },
      tooltips: {
        mode: 'nearest'
      }
    };
    
    ctrl.$onChanges = function(changes) {
      if (changes.chartTitle && changes.chartTitle.currentValue) {
        ctrl.chartOptions.title.text = changes.chartTitle.currentValue;
      }
    };
  }

  module.component('xpLineChart', {
    template: require('./lineChart.jade'),
    controller: controller,
    bindings: {
      chartData: '=',
      chartTitle: '<'
    }
  });

})();
