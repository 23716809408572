'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('tiaStudents', ['UserPresence', 'PresenceService', 'ElementRealtimeService', 'ActiveExperience', 'QuizStateService', 'JSONStringUtility',
    function(UserPresence, PresenceService, ElementRealtimeService, ActiveExperience, QuizStateService, JSONStringUtility) {
      function link(scope, element, attrs)
      {
        scope.scoreTitle = 'Test Completion';

        scope.$watch('testComplete', function (isTestComplete) {
          scope.scoreTitle = (isTestComplete ? 'Score' : 'Test Completion');
          if (isTestComplete) {
            updateScores();
          }
        });

        UserPresence.present({id: scope.teacherId, experience_id: scope.experienceId}).$promise.then(function (presentStudents) {
          if (scope.students && scope.students.length && presentStudents && presentStudents.length) {
            presentStudents.forEach(function (presentStudent) {
              var expStudent = scope.students.find(function (student) {
                return presentStudent.user_id === student.uid;
              });
              if (expStudent) {
                expStudent.isPresent = true;
              }
            });
          }
        });

        function updateStudentResponses(response) {
          let currentStudent = scope.students.find(function(student) {
            return student.uid == response.user_id;
          });
          if (currentStudent) {
            if (response.status && response.status === 'finished') {
              currentStudent.progress = "Completed";
              return;
            }
            if (response.element_id) {
              if (!currentStudent.responses) {
                currentStudent.responses = [];
              }
              let currentResponse = currentStudent.responses.find(function(current) {
                return current == response.element_id;
              });
              if (!currentResponse) {
                currentStudent.responses.push(response.element_id);
                currentStudent.progress = Math.round((currentStudent.responses.length / scope.testElements) * 100) + '%';
              }
            }
          }
        }

        if (!scope.testComplete) {
          QuizStateService.quizState(scope.experienceId, scope.quizId).getState().$promise.then(function (states) {
            ActiveExperience.getResponses(scope.experienceId).then(function (responses) {
              if (scope.students && scope.students.length && responses && responses.length) {
                responses.forEach(function(response) {
                  if (response.score) {
                    updateStudentResponses(response);
                  }
                });
                states.forEach(function (state) {
                  updateStudentResponses({
                    user_id: state.user_id,
                    status: state.status
                  });
                });
              }
            });
          });
        }

        function updateScores() {
          QuizStateService.quizState(scope.experienceId, scope.quizId).getState().$promise.then(function(scores){
            if (scope.students && scope.students.length && scores && scores.length) {
              scores.forEach(function(score) {
                if (score.status == 'finished' && score.final_score == 1) {
                  let currentStudent = scope.students.find(function(student) {
                    return student.uid == score.user_id;
                  });
                  if (currentStudent) {
                    currentStudent.score = score.score + '%';
                  }
                }
              });
            }
          });
        }

        if (scope.testComplete) {
          updateScores();
        }

        PresenceService.on(PresenceService.EVENTS.XPPresenceReceivedNotification, presenceChangedNotificationHandler);
        ElementRealtimeService.on(ElementRealtimeService.EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
        ElementRealtimeService.on(ElementRealtimeService.EVENTS.XPQuizStatusUpdate, quizStateChangedNotificationHandler);
        element.on('$destroy', function () {
          PresenceService.removeListener(PresenceService.EVENTS.XPPresenceReceivedNotification, presenceChangedNotificationHandler);
          ElementRealtimeService.removeListener(ElementRealtimeService.EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
          ElementRealtimeService.removeListener(ElementRealtimeService.EVENTS.XPQuizStatusUpdate, quizStateChangedNotificationHandler);
        });

        function quizStateChangedNotificationHandler(e) {
          var state = e.detail;
          if (!state || !state.record || state.record.quiz_id !== scope.quizId || state.record.experience_id !== scope.experienceId) {
            return;
          }
          updateStudentResponses({
            user_id: state.from,
            element_id: state.record.element_id,
            status: state.record.status
          });
        }

        function presenceChangedNotificationHandler(e) {
          let state = e.detail.record;
          let studentId = parseInt(e.detail.from, 10);

          let student = scope.students.find(function (student) {
            return studentId === student.uid;
          });
          if (student) {
            student.isPresent = (state.status === 'online');
          }
        }

        function checkForString(isString) {
          return isString !== "";
        }

        function ValidResponse(userData) {
          var response = JSONStringUtility.parse(userData);
          if (!response) {
            return false;
          }
          if (response.length === 0) {
            return false;
          }
          if (response.selection && !response.selection.length) {
            return false;
          }
          if (response.length && !response.some(checkForString)) {
            return false;
          }

          return true;
        }

        function stateChangedNotificationHandler(e) {
          if (ValidResponse(e.detail.record.user_data)) {
            updateStudentResponses({
              user_id: e.detail.from,
              element_id: e.detail.record.element_id
            });
          }
        }

      }

      return {
        restrict: 'E',
        scope: {
          students: '=',
          showStudents: '=',
          testActive: '=',
          testComplete: '=',
          teacherId: '=',
          experienceId: '=',
          quizId: '=',
          testElements: '='
        },
        templateUrl: 'tiaStudentsDirective.tpl',
        link: link
      };
  }]);

})();