'use strict';
//
/* Controllers */
//

clientControllers.controller('ChangePasswordFormCtrl', ['$http', '$scope', '$routeParams', 'UserInfoProfileFactory', 'UserInfoDefaultFactory', '$location', 'User', 'UserPasswordSettingsFactory',
  function ($http, $scope, $routeParams, UserInfoProfileFactory, UserInfoDefaultFactory, $location, User, UserPasswordSettingsFactory) {

    // Instantiate an object to store your scope data in (Best Practices)
    $scope.standard = {};
    $scope.user = {};
    $scope.xpError = {};

    $scope.validationFailed = function () {
      // If error messages
      if ($scope.xpError.details)
        return $scope.xpError.details.length > 0 ? true : false;
      return false;
    };

    var currentUserId = User.getId();

    // Populate screen
    UserInfoDefaultFactory.get({}, {'id': currentUserId}, function (response) {
      // Assign the response INSIDE the callback
      $scope.standard = response;
    });

    // ===================================
    // callback for ng-click 'savePasswordChanges'
    // ===================================

    $scope.savePasswordChanges = function (isValid) {
      // Verify password matches
      if ($scope.user.new_password1 != $scope.user.new_password2) {
        $scope.xpError.details = 'Passwords do not match, please re-enter values.';
        return;
      }
      if (isValid) {
        UserPasswordSettingsFactory.update({
          'id': currentUserId,
          'password': $scope.user.new_password1
        }, function (data) { //Success callback
          // Handle and error object
          if (typeof data.error == 'object') {
            $scope.xpError.details = data.error.description;
          }
          else if (typeof data == 'object') {
            // Show this page since it is active page
            $location.path('/userSettings').replace();
          }

        });
      }
      else {
        //
        $scope.xpError.details = "Enter a password.";
      }
    };

    $scope.getUserEmailById = function () {
      return $scope.standard.email;
    };
  }]);
