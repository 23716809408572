"use strict";

angular.module("angularWidget", ['element-templates']).factory('widgetConfig', ['$rootScope', function ($rootScope) {
  return {
    exportProperties: function (props) {
      if (props) {
        $rootScope.$broadcast("exportPropertiesUpdated", props);
      }
      return props;
    },
    getOptions: function (scope) {
      // This is just a stub so that we can reroute to angular widget
      // in the future if we want to.
      return scope.options;
    }
  };
}]);
