'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log', 'VideoStreamingService', 'ActiveExperience'];

  function controller ($scope, $log, VideoStreamingService, ActiveExperience) {
    var ctrl = this;

    ctrl.teacherAllowRaiseHand = false;

    ctrl.$onInit = function () {
      if (!ActiveExperience.currentExperience()) {
        return;
      }

      ctrl.videoEnabled = false;
      if (ctrl.teacher) {
        ctrl.audioEnabled = true;
      } else {
        ctrl.audioEnabled = false;
      }

      ActiveExperience.getExperience(ActiveExperience.currentExperience().id, true).then(function(experience){
        return VideoStreamingService.allowRaiseHand(experience.allow_raise_hand)
        .then(function(result){
          ctrl.teacherAllowRaiseHand = experience.allow_raise_hand;
        });
      });
    };

    ctrl.allowStudentsToRaiseHand = function() {
      return VideoStreamingService.allowRaiseHand(!ctrl.teacherAllowRaiseHand)
      .then(function(result){
        ctrl.teacherAllowRaiseHand = !ctrl.teacherAllowRaiseHand;
      });
    };

    ctrl.showVideo = function() {
      ctrl.videoEnabled = !ctrl.videoEnabled;
    };

    ctrl.enableAudio = function() {
      ctrl.audioEnabled = !ctrl.audioEnabled;
    };
  }

  module.component('videoControls', {
    template: require('./videoControls.jade'),
    controller: controller,
    bindings: {
      teacher: '<',
      audioEnabled: '=',
      videoEnabled: '='
    }
  });
})();
