'use strict';

angular.module('client.services').factory('StudentStreamingService', ['$log', '$q', '$routeParams', 'ExperienceRealtimeService', 'ActiveExperience',
  'VideoStreamingService',
  function ($log, $q, $routeParams, ExperienceRealtimeService, ActiveExperience,
            VideoStreamingService) {

    var realtimeInitialized = false;
    var currentExperienceId = null;
    var isStudentInStreaming = false;

    function isStudentSeeingStreaming() {
      return isStudentInStreaming && currentExperienceId;
    }

    function teacherEanbledStreamingForStudent(experienceId, enable) {
      if (enable && experienceId === parseInt($routeParams.id, 10)) {
        startStreaming(experienceId);
        isStudentInStreaming = true;
      } else {
        isStudentInStreaming = false;
      }
    }

    function initializeRealitime() {
      if (!realtimeInitialized) {
        ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPMediaStartedNotification,
          teacherMediaStarted);

        function teacherMediaStarted(e) {
          if (ActiveExperience.currentExperience() && e.detail.record.id === ActiveExperience.currentExperience().id) {
            startStreaming(e.detail.record.id);
          }
        }

        ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPStreamingStatusNotification,
          streamingStatusNotification);

        function streamingStatusNotification(e) {
          streamingStatusChanged(e.detail.record.id, e.detail.record.teacherIsStreaming);
        }

        realtimeInitialized = true;
      }
    }

    function streamingStatusChanged(experienceId, teacherIsStreaming) {
      teacherEanbledStreamingForStudent(experienceId, teacherIsStreaming);
    }

    function startStreaming(experienceId) {
      currentExperienceId = experienceId;
    }

    function initStudentStreaming(experienceId) {
      initializeRealitime();

      return ActiveExperience.getExperience(experienceId, true).then(function (experience) {
        if (!experience || !experience.media_session) {
          $log.info("invalid experience or media session: %s", experienceId);
          return false;
        }

        isStudentInStreaming = experience.teacher_streaming;
        if (isStudentInStreaming) {
          if (currentExperienceId !== experienceId) {
            // This is necessary to "reset" the teacher values for this student when the student refreshes their browser
            VideoStreamingService.setStudentStatus(VideoStreamingService.AUDIOSTATE.OFFLINE);
            VideoStreamingService.toggleStudentHandRaised(false);
          }
          startStreaming(experienceId);
        }
        return true;
      }).catch(function (error) {
        $log.warn("Failed to get the current experience: %s", experienceId, error);
        return false;
      });
    }

    function stopStudentSharing() {
      isStudentInStreaming = false;
      VideoStreamingService.setStudentStatus(VideoStreamingService.AUDIOSTATE.OFFLINE);
      currentExperienceId = null;
      VideoStreamingService.toggleStudentHandRaised(false);
    }

    return {
      initStudentStreaming: initStudentStreaming,
      isStudentSeeingStreaming: isStudentSeeingStreaming,
      stopStudentSharing: stopStudentSharing
    };
  }]);

