'use strict';

angular.module('client.services').factory('themeService', ['$resource', '$q', '$location', 'CourseFactory', '$log', 'User', 'api_server',
  function ($resource, $q, $location, CourseFactory, $log, User, api_server) {

    var themesResource = $resource(api_server + '/backgrounds', {},
      {
        'get': {method: 'GET', isArray: true}
      });

    function getThemes() {
      return themesResource.get().$promise;
    }

    var themeResource = $resource(api_server + '/backgrounds/:id', {id: '@id'},
      {
        'get': {method: 'GET'}
      });

    function getTheme(id) {
      return themeResource.get({id: id}).$promise;
    }

    var userThemeResource = $resource(api_server + '/backgrounds/:id', {id: '@id'},
      {
        'put': {method: 'PUT'}
      });

    function setTheme(id) {
      return userThemeResource.put({id: id}).$promise;
    }

    return {
      getThemes: getThemes,
      getTheme: getTheme,
      setTheme: setTheme
    };
  }]);
