'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['$log', 'ActiveMode', 'DistrictAdminMode', 'AdminConsoleService', 'xpAlert', 'reportToCSV',
                      'userDistrictAdminPermissions', 'userSchoolAdminPermissions', 'adminConsoleUsersService'];

function controller($log, ActiveMode, DistrictAdminMode, AdminConsoleService, xpAlert, reportToCSV,
                    userDistrictAdminPermissions, userSchoolAdminPermissions, adminConsoleUsersService) {

  var ctrl = this;

  ctrl.hasTeacherErrors = false;
  ctrl.hasClassErrors = false;
  ctrl.isDistrictAdmin = false;
  ctrl.downloadFile = "";
  ctrl.isImporting = false;

  // Show the default alert message when first trying to import
  var noStudentsMsg = "Before importing your class, we recommend you read <a href=\"http://www.exploros.com/sso\" target=_blank>" +
                      "Student Single Sign On (SSO) Best Practices</a>.";
  xpAlert.success(noStudentsMsg, true);

  if (DistrictAdminMode.isActiveMode()) {
    ctrl.isDistrictAdmin = true;
    ctrl.downloadFile = "/downloads/District_Teacher_Import_Template.csv";
    userDistrictAdminPermissions.getDistrict(ActiveMode.currentDistrictId()).then(function(district){
      ctrl.Name = district.name;
    });

    AdminConsoleService.schools(ActiveMode.currentSubscriptionId()).then(function(schools){
      ctrl.schools = schools;
    });
  } else {
    ctrl.downloadFile = "/downloads/Teacher_Import_Template.csv";
    userSchoolAdminPermissions.getSchool(ActiveMode.currentSchoolId()).then(function(school){
      ctrl.Name = school.name;
    });
  }

  ctrl.downloadCampuses = reportToCSV(
    function () {
      return adminConsoleUsersService.campusHeaders();
    }, function () {
      return ctrl.schools;
    }, function () {
      return ctrl.districtName + ' Schools.csv';
    }
  );

  ctrl.downloadTeacherErrors = reportToCSV(
    function () {
      return adminConsoleUsersService.teacherErrorHeaders();
    }, function () {
      return ctrl.teacherErrors;
    }, function () {
      return ctrl.districtName + ' Teachers.csv';
    }
  );

  ctrl.downloadClassErrors = reportToCSV(
    function () {
      return adminConsoleUsersService.classErrorHeaders();
    }, function () {
      return ctrl.studentErrors;
    }, function () {
      return ctrl.districtName + ' Classes.csv';
    }
  );

  this.onTeachersFileSelect = function(files) {
    if (files.length > 0) {
      var file = files[0];
      if (adminConsoleUsersService.isCSVFile(file)) {
        var reader = new FileReader();
        reader.onload = onLoadTeacherFile;
        reader.readAsText(file);
      }
      else {
        xpAlert.error("Invalid import file. Only csv files can be imported", true);
      }
    }
  };

  function onLoadTeacherFile(event) {
    var fileContents = event.target.result;
    xpAlert.clearAlerts();
    ctrl.isImporting = true;
    adminConsoleUsersService.importTeacherFile(fileContents, ActiveMode.currentSubscriptionId(), ctrl.schools, ActiveMode.currentSchoolId(), false)
    .then(function(results){
      if (results && results.hasErrors) {
        ctrl.hasTeacherErrors = true;
        ctrl.teacherErrors = results.teachers;
      }
      ctrl.isImporting = false;
    }).catch(function(error) {
      $log.error("Failed to import teachers:", error);
      ctrl.isImporting = false;
    });
  }

  this.onClassesFileSelect = function(files) {
    if (files.length > 0) {
      var file = files[0];
      if (adminConsoleUsersService.isCSVFile(file)) {
        var reader = new FileReader();
        reader.onload = onLoadClassesFile;
        reader.readAsText(file);
      }
      else {
        xpAlert.error("Invalid import file. Only csv files can be imported", true);
      }
    }
  };

  function onLoadClassesFile(event) {
    var fileContents = event.target.result;
    xpAlert.clearAlerts();
    ctrl.isImporting = true;
    adminConsoleUsersService.importClassFile(fileContents, ActiveMode.currentSubscriptionId()).then(function(results){
      if (results && results.hasErrors) {
        ctrl.hasStudentErrors = true;
        ctrl.studentErrors = results.students;
      }
      ctrl.isImporting = false;
    }).catch(function(error) {
      $log.error("Failed to import classes:", error);
      ctrl.isImporting = false;
    });
  }

}

module.component('xpAdminImportRosters', {
  template: require('./adminConsoleImportRosters.jade'),
  controller: controller,
});

})();
