'use strict';
//
/* Controllers */
//

clientControllers.controller('WelcomeCtrl', ['$http', '$scope', '$routeParams', '$location', 'User', 'ActiveMode',
                                function ($http,$scope, $routeParams,  $location, User, ActiveMode) {

	$scope.studentUser = true;

	// Based on user type, show correct message
	if(User.getSystemRole() == 'TEACHER')
	{
		$scope.studentUser = false;
	}
	else if ( User.getSystemRole() == 'STUDENT')
	{
		// Based on user info, we have not been invited yet
		$scope.studentUser = true;
	}

	//
	// Refresh experience list againg, if valid list then it will show, otherwise they get welcome screen again.
	//
	$scope.onCheckAgain = function () {
	   ActiveMode.navigateToDefaultView();
    };
}]);