'use strict';

angular.module('client.services').factory('ImportValidation', ['$resource', '$log', '$q', 'CourseFactory', 'api_server',
  function ($resource, $log, $q, CourseFactory, api_server) {

    var matchResource = $resource(api_server + '/users/match', {}, {
      post: {
        method: 'POST',
        isArray: true
      }
    });

    function hasData(line) {
      for (var name in line) {
        if (line.hasOwnProperty(name)) {
          if (line[name].length && line[name].length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    function convertToJSON(content) {
      // Determine the type of line delimiter.
      var eofChar = content.csv.indexOf("\n") != -1 ? '\n' : '\r';

      var lines = content.csv.split(eofChar);
      var result = [];
      var start = 0;
      var columnVal;

      var headers = [];
      if (content.header) {
        if (content.classImport) {
          headers = ["teacher", "classname", "firstname", "lastname", "username", "password", "email", "status"];
        } else if (content.teacherImport) {
          headers = ["givenName", "familyName", "email", "password"];
        } else if (content.districtTeacherImport) {
          headers = ["schoolId", "givenName", "familyName", "email", "password"];
        } else {
          headers = ["firstname", "lastname", "username", "password", "email", "status"];
        }
      }

      for (var i = start; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(new RegExp(content.separator + '(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)'));
        if (currentline.length > 1) {
          if (content.header) {
            for (var j = 0; j < headers.length; j++) {
              columnVal = currentline[j];
              if (columnVal || columnVal === "") {
                obj[headers[j]] = columnVal.trim();
              }
            }
          } else {
            for (var k = 0; k < currentline.length; k++) {
              columnVal = currentline[k];
              if (columnVal || columnVal === "") {
                obj[k] = columnVal.trim();
              }
            }
          }
          if (hasData(obj)) {
            result.push(obj);
          }
        }
      }
      return result;
    }

    function validateEmail(email) {
      var re = /^[a-zA-Z0-9!#$%&'*\.+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*\.+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
      return re.test(email);
    }

    function validateUsername(username) {
      var re = /^[a-z0-9#$%&'*!@\.+\/=?^_`{|}~-]+$/i;
      return re.test(username);
    }

    function clearErrors(user) {
      if (user.firstname_error) {
        delete user.firstname_error;
      }
      if (user.lastname_error) {
        delete user.lastname_error;
      }
      if (user.username_error) {
        delete user.username_error;
      }
      if (user.password_error) {
        delete user.password_error;
      }
      if (user.email_error) {
        delete user.email_error;
      }
    }

    function checkClassForMaxStudents(students, maxStudents) {
      var exceededMax = false;

      // Iterate all the student records and build up an object with the count of students per unique class/teacher
      var classRecs = {};

      students.forEach(function (cl) {
        var key = cl.classname + ' ' + cl.teacher;
        if (!classRecs.hasOwnProperty(key)) {
          classRecs[key] = {
            studentsCount: 1
          };
        } else {
          classRecs[key].studentsCount = classRecs[key].studentsCount + 1;
          classRecs[key].classname = cl.classname;
        }
      });

      var classesList = Object.keys(classRecs);
      classesList.forEach(function (cl) {
        var cls = classRecs[cl];
        if (cls.studentsCount > maxStudents) {
          exceededMax = true;
        }
      });

      return exceededMax;
    }

    function validateTeachersClasses(students) {
      var line = 2;
      var teachers = [];
      var classes = [];

      students.forEach(function (student) {
        // If the teacher and class values exist, then they must have a length and be valid
        if (student.teacher === "") {
          if (teachers.indexOf("Missing") === -1) {
            teachers.push("Missing");
          }
        }
        if (student.teacher && (!validateEmail(student.teacher))) {
          if (teachers.indexOf(student.teacher) === -1) {
            teachers.push(student.teacher);
          }
        }
        if (student.classname === "") {
          classes.push(line);
        }
        line = line + 1;
      });

      // See if any of the classes have more than the MAX_STUDENTS being imported
      return CourseFactory.maxStudents().then(function (results) {
        // Ask the server to match the classes and teachers
        return matchClasses(students).then(function (matchedClasses) {
          var errorMsg = "";
          var exceededMaxSize = checkClassForMaxStudents(students, results.maxStudents);
          if (exceededMaxSize) {
            return CourseFactory.maxStudentsMsg(function (msg) {
              errorMsg += msg;
            });
          }
          if (teachers.length || classes.length) {
            if (teachers.length) {
              errorMsg += "Invalid teacher emails - " + teachers.toString() + ". ";
            }
            if (classes.length) {
              errorMsg += " Missing class on line(s) " + classes.toString() + ".";
            }
          }
          // loop over the matched classes and add any extra errors to the string
          matchedClasses.forEach(function (mClass) {
            if (mClass.error) {
              errorMsg += mClass.error;
            }
          });
          return errorMsg;
        });
      });
    }

    function matchClasses(students) {
      return matchResource.post({'import': {"classes": students}}).$promise.then(function (result) {
        return result;
      })
        .catch(function (error) {
          $log.error("An error occurred matching these students. " + error);
        });
    }

    function validateImportStudents(students, withTeachers) {
      // Walk through all students validating there content
      var existingNames = [];

      var checkedStudents = students.map(function (student) {
        clearErrors(student);

        // Check required fields
        if (!student.firstname) {
          student.firstname_error = "Missing first name";
        } else if (student.firstname.length > 30) {
          student.firstname_error = "Name too long. Limit to 30 characters";
        }
        if (!student.lastname) {
          student.lastname_error = "Missing last name";
        } else if (student.lastname.length > 30) {
          student.lastname_error = "Name too long. Limit to 30 characters";
        }
        if (!student.username) {
          student.username_error = "Missing username";
        } else if (student.username.length > 64) {
          student.username_error = "Name too long. Limit to 64 characters";
        }
        if (!student.password) {
          student.password_error = "Missing password";
        } else if (student.password.length < 6) {
          student.password_error = "Password must include at least 6 characters";
        } else if (student.password.length > 30) {
          student.password_error = "Password too long. Limit to 30 characters";
        }

        // username must be alpha numeric
        if (student.username && !validateUsername(student.username)) {
          student.username_error = "Username must be alphanumeric";
        }

        // Make sure email is valid if supplied
        if (student.email && !validateEmail(student.email)) {
          student.email_error = "Invalid email format";
        } else if (student.email && student.email.length > 255) {
          student.email_error = "Email too long. Limit to 255 characters";
        }

        // Make sure the username is unique within the import file if we are importing into a single class
        if (existingNames.indexOf(student.username) !== -1 && !student.teacher) {
          student.username_error = "Duplicate username";
        }

        // Make sure to add this name to the list
        existingNames.push(student.username);

        return student;
      });

      // Ask the server to match the students
      return matchStudents(checkedStudents, withTeachers).then(function (validatedStudents) {
        return validatedStudents;
      });
    }

    function checkForErrors(users) {
      var hasErrors = false;
      users.forEach(function (user) {
        if (user.school_error || user.firstname_error || user.lastname_error || user.username_error || user.password_error || user.email_error) {
          hasErrors = true;
        }
      });
      return hasErrors;
    }

    function matchStudents(students, withTeachers) {
      if (withTeachers) {
        return matchResource.post({'import': {"districtStudents": students}}).$promise.then(function (result) {
          return result;
        })
          .catch(function (error) {
            $log.error("An error occurred matching these students. " + error);
          });
      } else {
        return matchResource.post({'import': {"students": students}}).$promise.then(function (result) {
          return result;
        })
          .catch(function (error) {
            $log.error("An error occurred matching these students. " + error);
          });
      }
    }

    function validateTeacherFields(teachers) {
      // Walk through all students validating there content
      var existingNames = [];

      return teachers.map(function (teacher) {
        clearErrors(teacher);
        // Make sure all 4 required fields exist
        if (!teacher.givenName) {
          teacher.firstname_error = "Missing first name";
        } else if (teacher.givenName.length > 30) {
          teacher.firstname_error = "Name too long. Limit to 30 characters";
        }
        if (!teacher.familyName) {
          teacher.lastname_error = "Missing last name";
        } else if (teacher.familyName.length > 30) {
          teacher.lastname_error = "Name too long. Limit to 30 characters";
        }
        // Make sure email is valid if supplied
        if (!teacher.email) {
          teacher.email_error = "Missing e-mail";
        } else if (!validateEmail(teacher.email)) {
          teacher.email_error = "Invalid e-mail format";
        } else if (teacher.email.length > 255) {
          teacher.email_error = "E-mail too long. Limit to 255 characters";
        }
        if (!teacher.password) {
          teacher.password_error = "Missing password";
        } else if (teacher.password.length < 6) {
          teacher.password_error = "Password must include at least 6 characters";
        } else if (teacher.password.length > 30) {
          teacher.password_error = "Password too long. Limit to 30 characters";
        }

        // Make sure the email is unique within the import file if we are importing into a single class
        var nameIndex = existingNames.indexOf(teacher.email);
        if (nameIndex !== -1) {
          teacher.email_error = "Duplicate e-mail";
        }

        // Make sure to add this name to the list
        existingNames.push(teacher.email);

        return teacher;
      });
    }

    function validateImportTeachers(teachers, subscriptionId) {
      // Check the basic fields of the teacher
      var checkedTeachers = validateTeacherFields(teachers);

      // Ask the server to match the teachers
      return matchTeachers(checkedTeachers, subscriptionId).then(function (validatedTeachers) {
        return validatedTeachers;
      });
    }

    function matchTeachers(teachers, subscriptionId) {
      return matchResource.post({
        'import': {
          "teachers": teachers,
          'subid': subscriptionId
        }
      }).$promise.then(function (result) {
        return result;
      })
        .catch(function (error) {
          $log.error("An error occurred matching these teachers. " + error);
        });
    }

    function matchDistrictTeachers(teachers) {
      return matchResource.post({'import': {"districtTeachers": teachers}}).$promise.then(function (result) {
        return result;
      })
        .catch(function (error) {
          $log.error("An error occurred matching these teachers. " + error);
        });
    }

    function validateDistrictImportTeachers(teachers, schools) {
      // Check the basic fields of the teacher
      var checkedTeachers = validateTeacherFields(teachers);

      var checkedSchoolTeachers = checkedTeachers.map(function (teacher) {
        if (!teacher.schoolId) {
          teacher.school_error = "Missing school ID";
        } else {
          var school = schools.find(function (schoolRec) {
            return schoolRec.id == teacher.schoolId;
          });
          if (!school) {
            teacher.school_error = "Invalid school ID";
          }
        }
        return teacher;
      });

      // Ask the server to match the teachers
      return matchDistrictTeachers(checkedSchoolTeachers).then(function (validatedTeachers) {
        return validatedTeachers;
      });
    }

    return {
      convertToJSON: convertToJSON,
      validateTeachersClasses: validateTeachersClasses,
      validateImportStudents: validateImportStudents,
      checkForErrors: checkForErrors,
      validateImportTeachers: validateImportTeachers,
      validateDistrictImportTeachers: validateDistrictImportTeachers
    };
  }]);


