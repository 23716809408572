'use strict';

/**
 * angular-segment-analytics Configuration Settings
 * @file segment.config.js
 */

angular.module('client').config(['segmentProvider',
  function (segmentProvider) {
    segmentProvider
      .setAutoload(false)
      .setCondition(function (User) {
        var role = User.getSystemRole();
        var blacklistedRoles = [User.ROLE.STUDENT, User.ROLE.NONE];

        return (blacklistedRoles.indexOf(role) < 0);
      });
  }]);
