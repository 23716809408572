'use strict';
(function () {
  function returnEmptyArray(){
    return [];
  }
  angular.module('client.services').factory('reportToCSV', ['$log',
    function ($log) {
      // expects two functions:
      // columnsAndFieldsGetter with array of [{name:'column name', field: 'field1', wrap: true/false}, {...} ...]
      // wrap: true forced to add "" around the value
      // dataGetter to return array with data like [ {field1: '', field2: '', ...} ]
      return function createCSVReportDownloader(columnsAndFieldsGetter, dataGetter, customNameGetter, customSeparator,
                                                prependRowsGetter, appendRowsGetter) {
        return function downloadReportCSV() {
          var customPrependRows = (prependRowsGetter || returnEmptyArray)();
          var customAppendRows = (appendRowsGetter || returnEmptyArray)();
          var separator = customSeparator || ',';
          var nameGetter = customNameGetter || function () {
            return 'export.csv';
          };
          var columnsAndFields = columnsAndFieldsGetter();

          var headers = [columnsAndFields.map(function (columnsAndField) {
            return columnsAndField.name;
          })];

          var csvData = dataGetter().map(function (row) {
            return columnsAndFields.map(function (columnAndField) {
              if (row[columnAndField.field] === null){
                return '-';
              }
              if (columnAndField.wrap) {
                return JSON.stringify(row[columnAndField.field]);
              }
              return row[columnAndField.field];
            });
          });
          var csv = customPrependRows.concat(headers).concat(csvData).concat(customAppendRows)
            .map(function (row) {
              return row.join(separator);
            })
            .join('\n');

          var filename = nameGetter();

          var blob = new Blob([csv], {type: 'text/csv'});
          var link = document.createElement('a');
          link.download = filename;
          link.href = window.URL.createObjectURL(blob);
          link.onclick = function(e) {
            // revokeObjectURL needs a delay to work properly
            var that = this;
            setTimeout(function() {
                window.URL.revokeObjectURL(that.href);
            }, 1500);
          };

          link.click();
          link.remove();
        }
      };
    }]);
}());
