'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['$resource', '$log', '$location', '$scope', 'ActiveMode', 'DistrictAdminMode', 'xpAlert', 'AdminConsoleService', 'ModalNavigation',
                      'NgTableParams', 'userPermissions', 'PermissionConsts'];

function controller($resource, $log, $location, $scope, ActiveMode, DistrictAdminMode, xpAlert, AdminConsoleService, ModalNavigation,
                    NgTableParams, userPermissions, PermissionConsts) {
  var ctrl = this;

  ctrl.tableParams = new NgTableParams(
    {
      // items per page
      count: 10,
    },
    {
      dataset: [],
    }
  );

  ctrl.initialized = false;
  ctrl.admins = [];
  ctrl.teachers = [];
  ctrl.students = [];
  ctrl.isDistrictAdmin = DistrictAdminMode.isActiveMode();

  ctrl.canAddUsers = function() {
    return !userPermissions.hasPermission(PermissionConsts.ui_disable_rostering);
  };

  // Get the number of Admins, teachers and students in the district
  AdminConsoleService.users(ActiveMode.currentSubscriptionId()).then(function(users){
    ctrl.admins = users.admins;
    ctrl.teachers = users.teachers;
    ctrl.students = users.students;
    ctrl.initialized = true;

    var teachersNotAdmins = ctrl.teachers.filter(function(teacher) {
      var adminUser = ctrl.admins.find(function(admin) {
        return teacher.id === admin.id;
      });
      return !adminUser;
    });

    ctrl.users = ctrl.admins.concat(teachersNotAdmins).concat(ctrl.students);

    ctrl.tableParams.settings({
      dataset: sortAndFilterByUser(ctrl.users)
    });

    if (!ctrl.teachers.length && !ctrl.students.length) {
      var noUsersMsg = "Welcome to Exploros! Please see this " +
      "<a href='http://help.exploros.com/getting-started-with-exploros/creating-classes-and-adding-students/adding-students-to-a-class' target='_blank'>" +
      "video.</a> on how to get started with your admin console section";
      xpAlert.info(noUsersMsg, true);
    }
  }).catch(function (error) {
    $log.error("error in getting district users: ", error);
  });

  function sortAndFilterByUser(data) {
    return data
      .filter(function (rec) {
        return true;
      });
  }

  ctrl.inviteTeachers = function() {
    xpAlert.clearAlerts();
    var path;
    if (ctrl.isDistrictAdmin) {
      path = '/district/' + ActiveMode.currentDistrictId() + '/subscription/' + ActiveMode.currentSubscriptionId() + '/users/teachers';
    } else {
      path = '/school/' + ActiveMode.currentSchoolId() + '/subscription/' + ActiveMode.currentSubscriptionId() + '/users/teachers';
    }

    ModalNavigation.navigateToModal(path, $location.path());
  };

  ctrl.importCSV = function() {
    xpAlert.clearAlerts();
    var path;
    if (ctrl.isDistrictAdmin) {
      path = '/district/' + ActiveMode.currentDistrictId() + '/subscription/' + ActiveMode.currentSubscriptionId() + '/users/roster';
    } else {
      path = '/school/' + ActiveMode.currentSchoolId() + '/subscription/' + ActiveMode.currentSubscriptionId() + '/users/roster';
    }
    ModalNavigation.navigateToModal(path, $location.path());
  };

  $scope.$on('$destroy', function() {
    xpAlert.clearAlerts();
  });

}

module.component('xpDistrictUsers', {
  template: require('./adminConsoleUsers.jade'),
  controller: controller,
});

})();
