'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log', 'SavedFilterState', 'ReportingSchoolYearsService'];

function controller ($scope, $log, SavedFilterState, ReportingSchoolYearsService) {
  var ctrl = this;
  ctrl.selectedSchools = [];
  ctrl.availableSchools = [];
  ctrl.savedFilteredSchools = [];

  ctrl.selectedTeachers = [];
  ctrl.availableTeachers = [];
  ctrl.savedFilteredTeachers = [];

  ctrl.selectedClasses = [];
  ctrl.availableClasses = [];
  ctrl.savedFilteredClasses = [];

  ctrl.selectedCourses = [];
  ctrl.availableCourses = [];
  ctrl.savedFilteredCourses = [];

  ctrl.selectedLevels = [];
  ctrl.availableLevels = [];
  ctrl.savedFilteredLevels = [];

  ctrl.selectedStudents = [];
  ctrl.availableStudents = [];
  ctrl.savedFilteredStudents = [];

  ctrl.selectedStatuses = [];
  ctrl.availableStatuses = [];
  ctrl.savedFilteredStatuses = [];

  // Create a default all schools selection
  var allSchools = {id: "All", name: "All Schools", ticked: true};

  // Create a default all teachers selection
  var allTeachers = {teacher_user_id: "All", first_name: "All", last_name: "Teachers", ticked: true};

  // Create a default all classes selection
  var allClasses = {id: "All", name: "All", ticked: true};

  // Create a default all courses selection
  var allCourses = {id: "All", name: "All", ticked: true};

  // Create a default all students selection
  var allStudents = {id: "All", name: "All", ticked: true};

  // Create a default all levels selection
  var allLevels = {id: "All", name: "All Levels", ticked: true};

  // Create a default all statuses selection
  var allStatuses = {id: "All", name: "All Statuses", ticked: true};

  // Initialize date to 1 year from today
  var startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);
  ctrl.date = { startDate: startDate, endDate: new Date()};

  // Initialize the years filter
  ctrl.filterYears = ReportingSchoolYearsService.get();
  ctrl.selectedYear = ctrl.filterYears[0];

  ctrl.InitializeFilters = function() {
    // Initialize the filter based on session storage values
    var savedFilterState = SavedFilterState.getState();
    if (savedFilterState) {
      ctrl.view = savedFilterState.view;
      ctrl.savedFilteredSchools = savedFilterState.schools;
      ctrl.savedFilteredTeachers = savedFilterState.teachers;
      ctrl.savedFilteredStudent = savedFilterState.student;
      ctrl.savedFilteredStudents = savedFilterState.students;
      ctrl.savedFilteredLevels = savedFilterState.levels;
      ctrl.selectedLevel = savedFilterState.level;
      ctrl.selectedClass = savedFilterState.class;
      ctrl.savedFilteredClasses = savedFilterState.classes;
      ctrl.selectedCourse = savedFilterState.course;
      ctrl.savedFilteredCourses = savedFilterState.courses;
      ctrl.savedFilteredStatuses = savedFilterState.statuses;
      ctrl.date = savedFilterState.dates;
      ctrl.selectedYear = savedFilterState.year;

      // Call the report to update itself with the saved filters
      if (ctrl.refresh) {
        var reportFilter = {
              view: ctrl.view,
              schools: ctrl.savedFilteredSchools,
              teachers: ctrl.selectedTeachers,
              student: ctrl.selectedStudent,
              students: ctrl.selectedStudents,
              levels: ctrl.selectedLevels,
              level: ctrl.selectedLevel,
              class: ctrl.selectedClass,
              classes: ctrl.savedFilteredClasses,
              courses: ctrl.savedFilteredCourses,
              statuses: ctrl.savedFilteredStatuses,
              dates: ctrl.date,
              year: ctrl.selectedYear
            };
        ctrl.refresh(reportFilter);
      }
    }
  }

  ctrl.$onChanges = function(changes) {
    // Prepend the all school onto the schools list
    if (changes.schools && changes.schools.currentValue) {
      var newArray = changes.schools.currentValue.slice(0);
      newArray.unshift(allSchools);
      ctrl.availableSchools = newArray;
      ctrl.setCurrentSchools();
    }

    // Prepend the all teacher onto the teachers list
    if (changes.teachers && changes.teachers.currentValue) {
      var newTeacherArray = changes.teachers.currentValue.slice(0);
      newTeacherArray.unshift(allTeachers);
      ctrl.availableTeachers = newTeacherArray;
      ctrl.setCurrentTeachers();
    }

    // Default current view to the first
    if (changes.views && changes.views.currentValue && !ctrl.view) {
      ctrl.view = changes.views.currentValue[0];
    }

    // Prepend the All levels to the levels list
    if (changes.levels && changes.levels.currentValue) {
      var newLevelArray = changes.levels.currentValue.slice(0);
      newLevelArray.unshift(allLevels);
      ctrl.availableLevels = newLevelArray;
      ctrl.setCurrentLevels();
      if (!ctrl.selectedLevel) {
        ctrl.selectedLevel = changes.levels.currentValue[changes.levels.currentValue.length - 1];
      }
    }

    // See if the single select option is specified
    if (changes.singlelevel && changes.singlelevel.currentValue) {
      ctrl.multiSelectLevels = changes.singlelevel.currentValue;
    }

    // Prepend the All classes to the classes list
    if (changes.classes && changes.classes.currentValue) {
      var newClassArray = changes.classes.currentValue.slice(0);
      newClassArray.unshift(allClasses);
      ctrl.availableClasses = newClassArray;
      ctrl.setCurrentClasses();
    }

    // Prepend the All courses to the courses list
    if (changes.courses && changes.courses.currentValue) {
      var newCourseArray = changes.courses.currentValue.slice(0);
      newCourseArray.unshift(allCourses);
      ctrl.availableCourses = newCourseArray;
      ctrl.setCurrentCourses();
    }

    // Prepend the All statuses to the statuses list
    if (changes.statuses && changes.statuses.currentValue) {
      var newStatusesArray = changes.statuses.currentValue.slice(0);
      newStatusesArray.unshift(allStatuses);
      ctrl.availableStatuses = newStatusesArray;
      ctrl.setCurrentStatuses();
    }

    // Default current class to the first
    if (changes.singleclass && changes.classes && changes.classes.currentValue && !ctrl.selectedClass) {
      ctrl.selectedClass = changes.classes.currentValue[0];
    }

    // Prepend the All students to the students list
    if (changes.students && changes.students.currentValue) {
      var newStudentArray = changes.students.currentValue.slice(0);
      newStudentArray.unshift(allStudents);
      ctrl.availableStudents = newStudentArray;
      ctrl.setCurrentStudents();
    }

    if (changes.selectedStudent && changes.selectedStudent.currentValue) {
      ctrl.selectedStudent = changes.selectedStudent.currentValue;
    }

    // Default current student to the first
    if (changes.singlestudent && changes.students && changes.students.currentValue && !ctrl.selectedStudent) {
      ctrl.selectedStudent = changes.students.currentValue[0];
    }

    // Any changes requires setting filters
    ctrl.InitializeFilters();
  };

  ctrl.setCurrentSchools = function() {
    if (ctrl.availableSchools.length > 0 && ctrl.savedFilteredSchools && ctrl.savedFilteredSchools.length > 0) {
      // Need to uncheck All schools since it is on by default
      ctrl.availableSchools[0].ticked = false;

      // Loop through and tick all available schools that were saved as part of the filter
      ctrl.availableSchools.forEach(function(availableSchool) {
        // See if this school exists in the saved filter list
        ctrl.savedFilteredSchools.forEach(function(filteredSchool) {
          if (availableSchool.id === filteredSchool.id) {
            availableSchool.ticked = true;
          }
        });
      });
    }
  };

  ctrl.setCurrentTeachers = function() {
    if (ctrl.availableTeachers.length > 0 && ctrl.savedFilteredTeachers && ctrl.savedFilteredTeachers.length > 0) {
      // Need to uncheck All Teachers since it is on by default
      ctrl.availableTeachers[0].ticked = false;

      // Loop through and tick all available teachers that were saved as part of the filter
      ctrl.availableTeachers.forEach(function(availableTeacher) {
        // See if this teacher exists in the saved filter list
        ctrl.savedFilteredTeachers.forEach(function(filteredTeacher) {
          if (availableTeacher.teacher_user_id === filteredTeacher.teacher_user_id) {
            availableTeacher.ticked = true;
          }
        });
      });
    }
  };

  ctrl.setCurrentClasses = function() {
    if (ctrl.availableClasses.length > 0 && ctrl.savedFilteredClasses && ctrl.savedFilteredClasses.length > 0) {
      // Need to uncheck All classes since it is on by default
      ctrl.availableClasses[0].ticked = false;

      // Loop through and tick all available classes that were saved as part of the filter
      ctrl.availableClasses.forEach(function(availableClass) {
        // See if this class exists in the saved filter list
        ctrl.savedFilteredClasses.forEach(function(filteredClass) {
          if (availableClass.id === filteredClass.id) {
            availableClass.ticked = true;
          }
        });
      });
    }
  };

  ctrl.setCurrentCourses = function() {
    if (ctrl.availableCourses.length > 0 && ctrl.savedFilteredCourses && ctrl.savedFilteredCourses.length) {
      // Need to uncheck All courses since it is on by default
      ctrl.availableCourses[0].ticked = false;

      // Loop through and tick all available courses that were saved as part of the filter
      ctrl.availableCourses.forEach(function(availableCourse) {
        // See if this course exists in the saved filter list
        ctrl.savedFilteredCourses.forEach(function(filteredCourse) {
          if (availableCourse.id === filteredCourse.id) {
            availableCourse.ticked = true;
          }
        });
      });
    }
  };

  ctrl.setCurrentLevels = function() {
    if (ctrl.availableLevels.length > 0 && ctrl.savedFilteredLevels && ctrl.savedFilteredLevels.length > 0) {
      // Need to uncheck All Levels since it is on by default
      ctrl.availableLevels[0].ticked = false;

      // Loop through and tick all available levels that were saved as part of the filter
      ctrl.availableLevels.forEach(function(availableLevel) {
        // See if this level exists in the saved filter list
        ctrl.savedFilteredLevels.forEach(function(filteredLevel) {
          if (availableLevel.id === filteredLevel.id) {
            availableLevel.ticked = true;
          }
        });
      });
    }
  };

  ctrl.setCurrentStatuses = function() {
    if (ctrl.availableStatuses.length > 0 && ctrl.savedFilteredStatuses && ctrl.savedFilteredStatuses.length > 0) {
      // Need to uncheck All statuses since it is on by default
      ctrl.availableStatuses[0].ticked = false;

      // Loop through and tick all available statuses that were saved as part of the filter
      ctrl.availableStatuses.forEach(function(availableStatus) {
        // See if this status exists in the saved filter list
        ctrl.savedFilteredStatuses.forEach(function(filteredStatus) {
          if (availableStatus.id === filteredStatus.id) {
            availableStatus.ticked = true;
          }
        });
      });
    }
  };

  ctrl.setCurrentStudents = function() {
    if (ctrl.availableStudents.length > 0 && ctrl.savedFilteredStudents) {
      // Need to uncheck All Students since it is on by default
      ctrl.availableStudents[0].ticked = true;
    }
  };

  // This code makes sure that the "All Schools" selection and any school selections are kept exclusive.
  // Basically you cannot select All Schools and individual schools at the same time.
  ctrl.onSchoolClicked = function(item) {
    // if this is NOT the all schools then make sure the ALL Schools is NOT checked
    if (item.id !== "All") {
      ctrl.availableSchools[0].ticked = false;
    }
    else {
      // Un-check all other schools since all schools is currently selected
      ctrl.availableSchools.forEach(function(school, index) {
        if (index !== 0 && school.ticked) {
          school.ticked = false;
        }
      });
    }
  };

  // This code makes sure that the "All Teachers" selection and any teacher selections are kept exclusive.
  // Basically you cannot select All Teachers and individual teachers at the same time.
  ctrl.onTeacherClicked = function(item) {
    // if this is NOT the all teachers then make sure the ALL Teachers is NOT checked
    if (item.teacher_user_id !== "All") {
      ctrl.availableTeachers[0].ticked = false;
    }
    else {
      // Uncheck all other teachers since all teachers is currently selected
      ctrl.availableTeachers.forEach(function(teacher, index) {
        if (index !== 0 && teacher.ticked)
          teacher.ticked = false;
      });
    }
  };

  // This code makes sure that the "All Classes" selection and any class selections are kept exclusive.
  // Basically you cannot select All Classes and individual classes at the same time.
  ctrl.onClassClicked = function(item) {
    // if this is NOT the all classes then make sure the ALL Classes is NOT checked
    if (item.id !== "All") {
      ctrl.availableClasses[0].ticked = false;
    }
    else {
      // Uncheck all other classes since all classes is currently selected
      ctrl.availableClasses.forEach(function(clazz, index) {
        if (index !== 0 && clazz.ticked)
          clazz.ticked = false;
      });
    }
  };

  // This code makes sure that the "All Courses" selection and any course selections are kept exclusive.
  // Basically you cannot select All Courses and individual courses at the same time.
  ctrl.onCourseClicked = function(item) {
    // if this is NOT the all classes then make sure the ALL Courses is NOT checked
    if (item.id !== "All") {
      ctrl.availableCourses[0].ticked = false;
    }
    else {
      // Uncheck all other courses since all courses is currently selected
      ctrl.availableCourses.forEach(function(course, index) {
        if (index !== 0 && course.ticked)
          course.ticked = false;
      });
    }
  };

  ctrl.onStatusClicked = function(item) {
    // if this is NOT the all statuses then make sure the ALL statuses is NOT checked
    if (item.id !== "All") {
      ctrl.availableStatuses[0].ticked = false;
    }
    else {
      // Uncheck all other statuses since all statuses is currently selected
      ctrl.availableStatuses.forEach(function(status, index) {
        if (index !== 0 && status.ticked)
          status.ticked = false;
      });
    }
  };

  // This code makes sure that the "All Levels" selection and any level selections are kept exclusive.
  // Basically you cannot select All Levels and individual levels at the same time.
  ctrl.onLevelClicked = function(item) {
    // if this is NOT the all levels then make sure the ALL Levels is NOT checked
    if (item.id !== "All") {
      ctrl.availableLevels[0].ticked = false;
    }
    else {
      // Uncheck all other teachers since all teachers is currently selected
      ctrl.availableLevels.forEach(function(level, index) {
        if (index !== 0 && level.ticked)
          level.ticked = false;
      });
    }
  };

  // This code makes sure that the "All Students" selection and any student selections are kept exclusive.
  // Basically you cannot select All Students and individual students at the same time.
  ctrl.onStudentClicked = function(item) {
    // if this is NOT the all students then make sure the ALL students is NOT checked
    if (item.id !== "All") {
      ctrl.availableStudents[0].ticked = false;
    }
    else {
      // Uncheck all other students since all students is currently selected
      ctrl.availableStudents.forEach(function(student, index) {
        if (index !== 0 && student.ticked)
          student.ticked = false;
      });
    }
  };

  ctrl.onUpdateReport = function() {
    if (ctrl.refresh) {
      var reportFilter = {
          view: ctrl.view,
          schools: ctrl.selectedSchools,
          teachers: ctrl.selectedTeachers,
          student: ctrl.selectedStudent,
          students: ctrl.selectedStudents,
          levels: ctrl.selectedLevels,
          level: ctrl.selectedLevel,
          class: ctrl.selectedClass,
          classes: ctrl.selectedClasses,
          courses: ctrl.selectedCourses,
          statuses: ctrl.selectedStatuses,
          dates: ctrl.date,
          subscribed: ctrl.subscribed,
          year: ctrl.selectedYear};
      SavedFilterState.setState(reportFilter);
      ctrl.refresh(reportFilter);
    }
  };
}

module.component('xpReportFilter', {
  template: require('./reportFilter.jade'),
  controller: controller,
  bindings: {
    style: '@',
    showdate: '=',
    showyear: '=',
    showsubscribed: '=',
    subscribed: '<',
    schools: '<',
    teachers: '<',
    students: '<',
    selectedStudent: '<',
    singlestudent: '=',
    views: '<',
    showlevels: '=',
    levels: '<',
    singlelevel: '=',
    classes: '<',
    courses: '<',
    statuses: '<',
    refresh: '&'
  }
});

})();
