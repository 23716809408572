'use strict';

(function () {

  var module = angular.module('client.components');

  controller.$inject = ['$location', 'ActiveMode', 'NgTableParams', 'reportLabels', 'ReportingSchoolYearsService',
                        'noTypeReportsList', '$log', 'ReportNavigation', 'User', 'UserInfoDefaultFactory', 'teksService',
                        'reportToCSV'];

  function controller($location, ActiveMode, NgTableParams, reportLabels, ReportingSchoolYearsService,
                      noTypeReportsList, $log, ReportNavigation, User, UserInfoDefaultFactory, teksService,
                      reportToCSV) {

    var ctrl = this;

    var staticColumns = ['schoolName', 'studentName', 'className', 'teacherName'];

    ctrl.inited = false;
    ctrl.isInDrilldown = false;
    ctrl.isTIAReport = true;
    ctrl.export = false;
    ctrl.exportData = null;
    ctrl.exportTIAScores = null;
    ctrl.loadingData = false;

    ctrl.adminReport = {};
    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.year = ctrl.filterYears[0].value;
    ctrl.filterYear = ctrl.filterYears[0];
    ctrl.filterType = {id:null};
    ctrl.filterConcept = {id:null};

    ctrl.getLabel = new reportLabels('labels', ctrl);

    ctrl.getStyleClass = function (columnName) {
      return teksService.getStyleClass(staticColumns, columnName);
    };

    ctrl.getTitlePopup = function getTitlePopup(columnName, data, columnTitle) {
      return teksService.getTitlePopup(staticColumns, columnName, data, columnTitle);
    };

    let reportSchoolList = [];
    let allSchools = {id:'none', name:'All Schools'};
    ctrl.schools = [allSchools];
    ctrl.filterSchool = ctrl.schools[0];

    let reportTeacherList = [];
    let allTeachers = {id:'none', name:'All Teachers'};
    ctrl.teachers = [allTeachers];
    ctrl.filterTeacher = ctrl.teachers[0];

    ctrl.classes = [{id:'none', name:'No Value'}];
    ctrl.filterClass = ctrl.classes[0];

    ctrl.metrics = [
      {name:'Pre Test Scores', id:'pretest'},
      {name:'Post Test Scores', id:'posttest'},
      {name: '', id: 'none', status: 'DIVIDER'},
      {name:'Average Quiz Item Score', id:'quiz'},
      {name:'Total Responses', id:'responses'},
      {name:'Time on Standard (min.)', id:'time_on_standard', label: 'label-summary-metric-time-on-standard', label_default:'Time on Standard (min.)'},
      ];
    ctrl.filterMetricName = ctrl.metrics[0].name;
    ctrl.filterMetric = ctrl.metrics[0].id;

    ctrl.setSchool = function setSchool(selection) {
      ctrl.filterTeacher = ctrl.teachers[0];
      teksService.setSchool(ctrl, selection);
      if (selection.id != 'none') {
        let schoolTeachers = reportTeacherList.filter(function(teacher) {
          return teacher.schoolId == selection.id;
        });
        ctrl.teachers = [allTeachers].concat(schoolTeachers);
      }
      else {
        ctrl.teachers = [allTeachers].concat(reportTeacherList);
      }
    }

    ctrl.setTeacher = function setTeacher(selection) {
      teksService.setTeacher(ctrl, selection);
    }

    ctrl.setMetric = function setMetric(selection){
      teksService.setMetric(ctrl, selection);
    };

    ctrl.setClass = function setClass(selection) {
      teksService.setClass(ctrl, selection);
    };

    ctrl.toggleYear = function toggleYear(selection) {
      ctrl.year = selection.value;
      ctrl.filterYear = selection;
      ctrl.includeArchived = selection.archived;
      fetchAndUpdateReport();
    };

    ctrl.classReportDrilldown = function classReportDrilldown(teacherId, classId) {
      if(!teacherId || !classId){
        return false;
      }
      ReportNavigation.navigateToReport(ctrl.adminReport.id, $location.path(),
        {
          teacher_id: teacherId,
          class_id: classId,
          metric_id: ctrl.filterMetric,
          drilldown: true,
          year: ctrl.year
        },
        $location.path(),
        {
          school_id: ctrl.filterSchool.id,
          teacher_id: ctrl.filterTeacher.id,
          metric_id: ctrl.filterMetric,
          year: ctrl.year
        });
    };

    function getFilter() {
      var filter = {};
      var query = $location.search();
      filter.include_archived = query.include_archived || ctrl.includeArchived;
      filter.year = query.year || ctrl.year;
      filter.school_id = parseInt(query.school_id,10);
      filter.teacher_id = parseInt(query.teacher_id,10);
      filter.type_id = parseInt(query.type_id, 10);
      filter.drilldown = query.drilldown || false;
      filter.metric_id = query.metric_id;
      filter.export = ctrl.export;
      return filter;
    }

    function fetchAndUpdateReport() {
      var filter = getFilter();
      filter.include_archived = ctrl.includeArchived;
      var isInDrilldown = filter.drilldown;

      ctrl.isInDrilldown = isInDrilldown;

      ctrl.loadingData = true;
      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.loadingData = false;
        ctrl.parent.title = (report.name || '').replace('TEKS', ctrl.getLabel('label-standard-name', 'TEKS'));
        ctrl.adminReport.id = report.report_id;
        ctrl.currentLevel = report.level;
        if(isInDrilldown){
          ctrl.currentLevel = teksService.LEVELS.CURRICULUM;
        }
        ctrl.labels = report.labels;
        ctrl.hasTypes = !noTypeReportsList.includes(report.report_kind);

        ctrl.metrics.forEach(function (metric) {
          if (metric.label){
            metric.name = ctrl.getLabel(metric.label, metric.label_default);
          }
        });

        ctrl.reportData = report.data.records;
        if (report.data.short_expectation_codes && report.data.short_expectation_codes.length) {
          ctrl.expectationCodes = report.data.short_expectation_codes.filter(function(sec) {
            return ctrl.reportData.find(function(row) {
              return sec.short_expectation_code == row.short_expectation_code && (row.pretest || row.posttest);
            });
          });
        } else {
          ctrl.expectationCodes = [];
        }
        ctrl.tiaTestScores = report.data.tiaTestScores;
        ctrl.tableParams = new NgTableParams(
          {
            // items per page
            count: 12,
            sorting: { filterDate: "desc" }
          },
          {
            dataset: [],
            // options for page size
            counts: [],
          }
        );

        if (filter.year) {
          ctrl.filterYear = ctrl.filterYears.filter(function(year) {
            return year.value === parseInt(filter.year, 10);
          })[0] || ctrl.filterYears[0];
        }

        if(filter.metric_id){
          ctrl.filterMetric = ctrl.metrics.filter(function (rec) {
            return rec.id === filter.metric_id;
          }).map(function (rec) {
            return rec.id;
          })[0] || ctrl.metrics[0].id;

          ctrl.filterMetricName = ctrl.metrics.filter(function (rec) {
            return rec.id === ctrl.filterMetric;
          })[0].name;
        }

        ctrl.allowArchived = teksService.isDistrictReport(ctrl);

        if (report.data.records.length > 0) {
          ctrl.allowArchived = false;

          reportSchoolList = teksService.generateUniqueSchoolList(report.data.records);
          ctrl.schools = [allSchools].concat(reportSchoolList);
          if (filter.school_id) {
            ctrl.filterSchool = ctrl.schools.find(function(school){ return school.id == filter.school_id; });
          } else {
            ctrl.filterSchool = ctrl.schools[0];
          }

          reportTeacherList = teksService.generateUniqueTeacherList(report.data.records);
          ctrl.teachers = [allTeachers].concat(reportTeacherList);
          if (filter.teacher_id) {
            ctrl.filterTeacher = ctrl.teachers.find(function(teacher){ return teacher.id == filter.teacher_id; });
          } else {
            ctrl.filterTeacher = ctrl.teachers[0];
          }

          if(isInDrilldown){
            ctrl.filterClass = ctrl.classes.filter(function (rec) {
              return rec.id === filter.class_id;
            })[0];
          }
        }

        if(!report.data.short_expectation_codes){
          ctrl.data = [];
          return;
        }

        teksService.updateReport(ctrl);
        return report;
      })
      .catch(function(error) {
        $log.error("error in fetching report:",error);
        ctrl.loadingData = false;
      });
    }

    function fetchAndUpdateExport() {
      var filter = getFilter();
      filter.include_archived = ctrl.includeArchived;

      ctrl.loadingData = true;
      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.loadingData = false;
        ctrl.exportData = report.data.records;
        ctrl.exportTIAScores = report.data.tiaTestScores;
      })
      .catch(function(error) {
        $log.error("error in fetching report:",error);
        ctrl.loadingData = false;
      });
    }

    fetchAndUpdateReport().then(function () {
      ctrl.inited = true;
    })
    .catch(function(error) {
      $log.error("error in updating report:",error);
      ctrl.inited = true;
    });

    function loadExportData() {
      ctrl.export = true;
      return fetchAndUpdateExport().then(function() {
        ctrl.export = false;
      })
    };

    ctrl.downloadCSV = function downloadCSV() {
      return loadExportData().then(function() {
        return ctrl.exportCSV();
      });
    }

    ctrl.exportCSV = reportToCSV(
      function() { return teksService.exportColumns(ctrl); },
      function() { return teksService.exportData(ctrl, ctrl.exportData, ctrl.exportTIAScores); },
      function() { return teksService.exportFile(ctrl); },
      ','
    );
  }

  module.component('districtTeksTiaSummary', {
    require: {parent: '^^xpReport'},
    template: require('./districtTIASummary.jade'),
    controller: controller,
  });

})();