'use strict';
//
/* Controllers */
// http://www.objectpartners.com/2013/08/21/using-services-and-messages-to-share-data-between-controllers-in-angularjs/

clientControllers.controller('ConnectToTeacherCtrl', ['$http', '$rootScope', '$scope', '$routeParams', 'PageService',
  'isStudentUser', 'ConnectToTeacherValidateFactory', 'ConnectToTeacherFactory', 'loginService', 'UserSignupFactory',
  'GoogleOauthUrlFactory', '$location', '$timeout', '$window', 'User', 'ActiveMode', '$log',
  function($http, $rootScope, $scope, $routeParams, PageService, isStudentUser, ConnectToTeacherValidateFactory,
           ConnectToTeacherFactory, loginService, UserSignupFactory, GoogleOauthUrlFactory, $location, $timeout,
           $window, User, ActiveMode, $log) {

    $scope.connection = {};
    $scope.student = {};

    $scope.explorosImage = "resources/exploros_logo.svg";

    $scope.hasNavBar = isStudentUser;

    // Initialize
    PageService.activatePage1();

    $scope.page1active = true;
    $scope.page2active = false;
    $scope.page3active = false;

    $scope.xpError = {};
    $scope.xpError.details1 = '';
    $scope.xpError.details2 = '';
    $scope.xpError.details3 = '';
    $scope.showEmailForm = false;
    $scope.validationFailed = function(pageIndex) {
      if (pageIndex == 1 && $scope.xpError.details1)
        return $scope.xpError.details1.length > 0 ? true : false;
      else if (pageIndex == 2 && $scope.xpError.details2)
        return $scope.xpError.details2.length > 0 ? true : false;
      else if (pageIndex == 3 && $scope.xpError.details3)
        return $scope.xpError.details3.length > 0 ? true : false;

      return false;
    };

    // Waiting on events to show correct page
    $scope.$on('page1Activated', function() {
      $scope.page1active = true;
      $scope.page2active = false;
      $scope.page3active = false;
    });
    $scope.$on('page2Activated', function() {
      $scope.page1active = false;
      $scope.page2active = true;
      $scope.page3active = false;
      // Set up scope to populate section
      $scope.verified_teacherId = PageService.getTeacherCode();
      $scope.verified_full_name = PageService.getTeacherName();
    });
    $scope.$on('page3Activated', function() {
      $scope.page2active = false;
      $scope.page1active = false;
      $scope.page3active = true;
    });
    // ====================================
    // User selected Cancel button
    // ====================================
    $scope.onCancelLink = function() {
      if (isStudentUser) {
        ActiveMode.navigateToDefaultView();
      }
      else {
        $location.path('/login');
      }
    };
    // ====================================
    // Submit First page
    // ====================================
    $scope.connectToTeacher = function(isValid) {
      $scope.connectTeacher = {};
      var teacherCode = $scope.connection.teacherId;
      $scope.xpError.details1 = '';
      if (!teacherCode) {
        $scope.xpError.details1 = 'Enter a valid teacher code.';
        return;
      }


      // Gather up the parameters from form
      ConnectToTeacherValidateFactory.get({
        'code': teacherCode
      }, function(data) { //Success callback
        // Handle and error object
        if (typeof data.error == 'object') {
          // Add a period
          $scope.xpError.details1 = data.error.description + '.';
        }
        else if (typeof data == 'object') {
          // Show this page since it is active page
          PageService.activatePage2(teacherCode, data.name);
        }

      }, function(error) { //Failure callback
        $scope.onCancelLink();
      });
    };
    // ====================================
    // Submit Second page
    // ====================================
    $scope.connectToTeacherVerified = function() {
      // Show this page since it is active page
      $scope.xpError.details2 = '';
      if (isStudentUser) {
        var teacherCode = $scope.verified_teacherId;
        // Call to connect Teacher and Student
        ConnectToTeacherFactory.get({
          'code': teacherCode
        }, function(data) { //Success callback
          if(data.error){
            $log.error("expected error in connectToTeacherVerified on ConnectToTeacherFactory.get:", data.error);
          }
          // Handle and error object
          if (typeof data.error == 'object') {
            $scope.xpError.details2 = data.error.description;
          }
          else if (typeof data == 'object' && data.status == 'OK') {
            // All done, Show this page since it is active page
            ActiveMode.navigateToDefaultView();
          }
        }, function(error) { //Failure callback
          $log.error("error in connectToTeacherVerified on ConnectToTeacherFactory.get:", error);
          ActiveMode.navigateToDefaultView();
        });
      }
      else {
        // This is new user, gather the student information
        PageService.activatePage3();
      }
    };

    // ====================================
    // Submit Third page
    // ====================================
    $scope.saveStudentInfo = function(isValid) {
      $scope.xpError.details3 = '';
      if(!isValid){
        $scope.xpError.details3 = 'Student account cannot be saved. Please fix the errors.';
        return;
      }
      // Additional verification for password1/password2 must match
      if ($scope.student.password1 != $scope.student.password2) {
        $scope.xpError.details3 = 'Passwords do not match. Please re-enter values.';
        return;
      }

      UserSignupFactory.post({
        'email': $scope.student.email,
        'username': $scope.student.email,
        'password': $scope.student.password1,
        'first_name': $scope.student.first_name,
        'last_name': $scope.student.last_name,
        'system_role': 'STUDENT',
        'teacherCode': $scope.verified_teacherId
      }).$promise
        .then(function(data) {
          // Handle and error object
          if (typeof data.error == 'object') {
            $scope.xpError.details3 = data.error.description;
            return;
          }
          // Show this page since it is active page
          loginService.login($scope.student.email, $scope.student.password1)
            .then(function(data) { //Success callback
              if(data.error){
                $log.error("error in saveStudentInfo on user login attempt:",data.error);
              }
              // if the return object is an error object then display the error information in the login dialog
              if (data.error) {
                $location.path('/login').replace();
              }
              if(!data.user){
                $log.error('failed login on saveStudentInfo');
                return;
              }

              // Call to connect Teacher and Student
              ConnectToTeacherFactory.get({
                'code': $scope.verified_teacherId
              }, function(result) {
                // Handle and error object
                if (typeof result.error == 'object') {
                  $scope.xpError.details3 = result.error.description;
                }
                else if (typeof result == 'object' && result.status == 'OK') {
                  // Show this page since it is active page
                  ActiveMode.navigateToDefaultView();
                }

              }, function(error) { //Failure callback
                $log.error("error in saveStudentInfo on ConnectToTeacherFactory.get:", error);
                $location.path('/login');
              });

            })
            .catch(function(error) { //Failure.  Just re-show the login
              $log.error("error on user login attempt:", error);
              $location.path('/login');
            });
        })
        .catch(function(error) { //Failure callback
          $log.error("error on user signup attempt:", error);
          $location.path('/login');
        });
    };

    /**
     * @function signupWithGoogle
     */
    $scope.signupWithGoogle = function() {
      $scope.xpError.details3 = '';

      var teacherCode = $scope.verified_teacherId;
      var system_role = 'STUDENT';

      GoogleOauthUrlFactory.signup({
        'system_role': system_role,
        'teacherCode': teacherCode
      }, function(data) { //Success callback

        // Handle error
        if (typeof data.error == 'object') {
          $scope.xpError.details3 = data.error.description;
        }
        else {
          $timeout(function() {
            $scope.$apply(function() {
              $window.location.href = data.url;
            });
          });
        }

      }, function(error) { //Failure callback
        $log.error("user signupWithGoogle error:",error);
        $location.path('/login');
      });
    };
  }]);
