'use strict';

(function() {
var module = angular.module('client.components');

fullPageReportController.$inject = ['$location', 'ReportNavigation', 'ActiveMode', '$attrs', '$scope'];

function fullPageReportController($location, ReportNavigation, ActiveMode, $attrs, $scope) {
  var ctrl = this;

  ctrl.loaded = false;

  ctrl.back = function() {
    if ($attrs.onBack) {
      ctrl.onBack();
    } else {
      var backDestination = ReportNavigation.getBackDestination(ActiveMode.currentReportId());
      if (backDestination) {
        $location.path(backDestination.destination).search('').search(backDestination.params);
      }
      else {
        ActiveMode.navigateToDefaultView();
      }
    }
  };
}

module.component('xpFullPageReport', {
  template: require('./fullPageReport.jade'),
  bindings: {
    onBack: '&',
    name: '@',
    component: '@'
  },
  controller: fullPageReportController
});

})();

