module.exports = {
  port : 7777,
  newrelic: false,
  realtime: {
    url: 'wss://realtime-future.exploros.com/realtime'
  },
  cookies : {
    token_cookie_name : 'future_exploros_token',
    session_details_cookie_name : 'future_session_details',
    domain : '.exploros.com',
    old_cookie_name : 'exploros_token'
  }
};

