'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log'];

  function controller ($scope, $log) {
    var ctrl = this;

    ctrl.onWatch = function() {
      ctrl.changed({video: ctrl.video});
      ctrl.player.src({src: ctrl.video.video_url, type: 'video/mp4'});
      ctrl.player.play();
      ctrl.video.playing = true;
    };

    ctrl.$onInit = function () {
    };

    ctrl.$onDestroy = function () {
    };
  }

  module.component('xpVideoLink', {
    template: require('./videoLink.jade'),
    controller: controller,
    bindings: {
      video: '<',
      player: '<',
      changed: '&'
    }
  });
})();