'use strict';
angular.module('xp-element-image', ['angularWidget', 'client.services'])
  .controller('clientImageElementCtrl', ['$scope', 'widgetConfig', '$http', 'ElementUtilities', '$timeout', function ($scope, widgetConfig, $http, ElementUtilities, $timeout) {

		// The element is the block of data provided by the source xml
		$scope.options = widgetConfig.getOptions($scope);
		$scope.border = undefined;
		$scope.alignment = undefined;
		$scope.link = undefined;

		var parseElement = function()
		{
			if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes)
				return;

			$scope.options.element.config.attributes.forEach(function(attribute)
			{
				switch (attribute.name)
				{
				case "url" :
					$scope.imageUrl = ElementUtilities.getElementURL($scope.options.element, $scope.options.context.experienceId, attribute.value);
					break;
				case "style" :
					if (attribute.value == "default")
						$scope.border = "image-element-border";
				break;
				case "alignment" :
					if (attribute.value == "center")
						$scope.alignment = "image-element-alignment-center";
					else if (attribute.value == "right")
						$scope.alignment = "image-element-alignment-right";
				break;
				case "link" :
					$scope.link = attribute.value;
				break;
				case "alternate_text" :
				  $scope.alternate_text = attribute.value;
				break;
				}
			});

			$timeout(function() {
				// Notify the widget that were are done loading the data
				widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
			});

		};

		$scope.$watch('options', parseElement, true);

		$scope.onClickedImage = function()
		{
			// If there is a link
			if ($scope.link)
				window.open($scope.link, '_blank');
		};

  }]);