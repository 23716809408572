'use strict';
angular.module('d3', []);
'use strict';
angular.module('d3').factory('d3', [function () {
	  var d3 = {
			    version: "3.5.4"
			  };
			  var d3_arraySlice = [].slice, d3_array = function(list) {
			    return d3_arraySlice.call(list);
			  };
			  var d3_document = this.document;
			  function d3_documentElement(node) {
			    return node && (node.ownerDocument || node.document).documentElement;
			  }
			  function d3_window(node) {
			    return node && node.ownerDocument ? node.ownerDocument.defaultView : node;
			  }
			  if (d3_document) {
			    try {
			      d3_array(d3_document.documentElement.childNodes)[0].nodeType;
			    } catch (e) {
			      d3_array = function(list) {
			        var i = list.length, array = new Array(i);
			        while (i--) array[i] = list[i];
			        return array;
			      };
			    }
			  }
			  if (!Date.now) Date.now = function() {
			    return +new Date();
			  };
			  if (d3_document) {
			    try {
			      d3_document.createElement("DIV").style.setProperty("opacity", 0, "");
			    } catch (error) {
			      var d3_element_prototype = this.Element.prototype, d3_element_setAttribute = d3_element_prototype.setAttribute, d3_element_setAttributeNS = d3_element_prototype.setAttributeNS, d3_style_prototype = this.CSSStyleDeclaration.prototype, d3_style_setProperty = d3_style_prototype.setProperty;
			      d3_element_prototype.setAttribute = function(name, value) {
			        d3_element_setAttribute.call(this, name, value + "");
			      };
			      d3_element_prototype.setAttributeNS = function(space, local, value) {
			        d3_element_setAttributeNS.call(this, space, local, value + "");
			      };
			      d3_style_prototype.setProperty = function(name, value, priority) {
			        d3_style_setProperty.call(this, name, value + "", priority);
			      };
			    }
			  }
			  d3.scale = {};
			  function d3_scaleExtent(domain) {
			    var start = domain[0], stop = domain[domain.length - 1];
			    return start < stop ? [ start, stop ] : [ stop, start ];
			  }
			  function d3_scaleRange(scale) {
			    return scale.rangeExtent ? scale.rangeExtent() : d3_scaleExtent(scale.range());
			  }
			  function d3_class(ctor, properties) {
			    for (var key in properties) {
			      Object.defineProperty(ctor.prototype, key, {
			        value: properties[key],
			        enumerable: false
			      });
			    }
			  }
			  d3.map = function(object, f) {
			    var map = new d3_Map();
			    if (object instanceof d3_Map) {
			      object.forEach(function(key, value) {
			        map.set(key, value);
			      });
			    } else if (Array.isArray(object)) {
			      var i = -1, n = object.length, o;
			      if (arguments.length === 1) while (++i < n) map.set(i, object[i]); else while (++i < n) map.set(f.call(object, o = object[i], i), o);
			    } else {
			      for (var key in object) map.set(key, object[key]);
			    }
			    return map;
			  };
			  function d3_Map() {
			    this._ = Object.create(null);
			  }
			  var d3_map_proto = "__proto__", d3_map_zero = "\x00";
			  d3_class(d3_Map, {
			    has: d3_map_has,
			    get: function(key) {
			      return this._[d3_map_escape(key)];
			    },
			    set: function(key, value) {
			      return this._[d3_map_escape(key)] = value;
			    },
			    remove: d3_map_remove,
			    keys: d3_map_keys,
			    values: function() {
			      var values = [];
			      for (var key in this._) values.push(this._[key]);
			      return values;
			    },
			    entries: function() {
			      var entries = [];
			      for (var key in this._) entries.push({
			        key: d3_map_unescape(key),
			        value: this._[key]
			      });
			      return entries;
			    },
			    size: d3_map_size,
			    empty: d3_map_empty,
			    forEach: function(f) {
			      for (var key in this._) f.call(this, d3_map_unescape(key), this._[key]);
			    }
			  });
			  function d3_map_escape(key) {
			    return (key += "") === d3_map_proto || key[0] === d3_map_zero ? d3_map_zero + key : key;
			  }
			  function d3_map_unescape(key) {
			    return (key += "")[0] === d3_map_zero ? key.slice(1) : key;
			  }
			  function d3_map_has(key) {
			    return d3_map_escape(key) in this._;
			  }
			  function d3_map_remove(key) {
			    return (key = d3_map_escape(key)) in this._ && delete this._[key];
			  }
			  function d3_map_keys() {
			    var keys = [];
			    for (var key in this._) keys.push(d3_map_unescape(key));
			    return keys;
			  }
			  function d3_map_size() {
			    var size = 0;
			    for (var key in this._) ++size;
			    return size;
			  }
			  function d3_map_empty() {
			    for (var key in this._) return false;
			    return true;
			  }
			  var abs = Math.abs;
			  d3.range = function(start, stop, step) {
			    if (arguments.length < 3) {
			      step = 1;
			      if (arguments.length < 2) {
			        stop = start;
			        start = 0;
			      }
			    }
			    if ((stop - start) / step === Infinity) throw new Error("infinite range");
			    var range = [], k = d3_range_integerScale(abs(step)), i = -1, j;
			    start *= k, stop *= k, step *= k;
			    if (step < 0) while ((j = start + step * ++i) > stop) range.push(j / k); else while ((j = start + step * ++i) < stop) range.push(j / k);
			    return range;
			  };
			  function d3_range_integerScale(x) {
			    var k = 1;
			    while (x * k % 1) k *= 10;
			    return k;
			  }
			  d3.scale.ordinal = function() {
			    return d3_scale_ordinal([], {
			      t: "range",
			      a: [ [] ]
			    });
			  };
			  function d3_scale_ordinal(domain, ranger) {
			    var index, range, rangeBand;
			    function scale(x) {
			      return range[((index.get(x) || (ranger.t === "range" ? index.set(x, domain.push(x)) : NaN)) - 1) % range.length];
			    }
			    function steps(start, step) {
			      return d3.range(domain.length).map(function(i) {
			        return start + step * i;
			      });
			    }
			    scale.domain = function(x) {
			      if (!arguments.length) return domain;
			      domain = [];
			      index = new d3_Map();
			      var i = -1, n = x.length, xi;
			      while (++i < n) if (!index.has(xi = x[i])) index.set(xi, domain.push(xi));
			      return scale[ranger.t].apply(scale, ranger.a);
			    };
			    scale.range = function(x) {
			      if (!arguments.length) return range;
			      range = x;
			      rangeBand = 0;
			      ranger = {
			        t: "range",
			        a: arguments
			      };
			      return scale;
			    };
			    scale.rangePoints = function(x, padding) {
			      if (arguments.length < 2) padding = 0;
			      var start = x[0], stop = x[1], step = domain.length < 2 ? (start = (start + stop) / 2, 
			      0) : (stop - start) / (domain.length - 1 + padding);
			      range = steps(start + step * padding / 2, step);
			      rangeBand = 0;
			      ranger = {
			        t: "rangePoints",
			        a: arguments
			      };
			      return scale;
			    };
			    scale.rangeRoundPoints = function(x, padding) {
			      if (arguments.length < 2) padding = 0;
			      var start = x[0], stop = x[1], step = domain.length < 2 ? (start = stop = Math.round((start + stop) / 2), 
			      0) : (stop - start) / (domain.length - 1 + padding) | 0;
			      range = steps(start + Math.round(step * padding / 2 + (stop - start - (domain.length - 1 + padding) * step) / 2), step);
			      rangeBand = 0;
			      ranger = {
			        t: "rangeRoundPoints",
			        a: arguments
			      };
			      return scale;
			    };
			    scale.rangeBands = function(x, padding, outerPadding) {
			      if (arguments.length < 2) padding = 0;
			      if (arguments.length < 3) outerPadding = padding;
			      var reverse = x[1] < x[0], start = x[reverse - 0], stop = x[1 - reverse], step = (stop - start) / (domain.length - padding + 2 * outerPadding);
			      range = steps(start + step * outerPadding, step);
			      if (reverse) range.reverse();
			      rangeBand = step * (1 - padding);
			      ranger = {
			        t: "rangeBands",
			        a: arguments
			      };
			      return scale;
			    };
			    scale.rangeRoundBands = function(x, padding, outerPadding) {
			      if (arguments.length < 2) padding = 0;
			      if (arguments.length < 3) outerPadding = padding;
			      var reverse = x[1] < x[0], start = x[reverse - 0], stop = x[1 - reverse], step = Math.floor((stop - start) / (domain.length - padding + 2 * outerPadding));
			      range = steps(start + Math.round((stop - start - (domain.length - padding) * step) / 2), step);
			      if (reverse) range.reverse();
			      rangeBand = Math.round(step * (1 - padding));
			      ranger = {
			        t: "rangeRoundBands",
			        a: arguments
			      };
			      return scale;
			    };
			    scale.rangeBand = function() {
			      return rangeBand;
			    };
			    scale.rangeExtent = function() {
			      return d3_scaleExtent(ranger.a[0]);
			    };
			    scale.copy = function() {
			      return d3_scale_ordinal(domain, ranger);
			    };
			    return scale.domain(domain);
			  }
			  d3.color = d3_color;
			  function d3_color() {}
			  d3_color.prototype.toString = function() {
			    return this.rgb() + "";
			  };
			  d3.hsl = d3_hsl;
			  function d3_hsl(h, s, l) {
			    return this instanceof d3_hsl ? void (this.h = +h, this.s = +s, this.l = +l) : arguments.length < 2 ? h instanceof d3_hsl ? new d3_hsl(h.h, h.s, h.l) : d3_rgb_parse("" + h, d3_rgb_hsl, d3_hsl) : new d3_hsl(h, s, l);
			  }
			  var d3_hslPrototype = d3_hsl.prototype = new d3_color();
			  d3_hslPrototype.brighter = function(k) {
			    k = Math.pow(.7, arguments.length ? k : 1);
			    return new d3_hsl(this.h, this.s, this.l / k);
			  };
			  d3_hslPrototype.darker = function(k) {
			    k = Math.pow(.7, arguments.length ? k : 1);
			    return new d3_hsl(this.h, this.s, k * this.l);
			  };
			  d3_hslPrototype.rgb = function() {
			    return d3_hsl_rgb(this.h, this.s, this.l);
			  };
			  function d3_hsl_rgb(h, s, l) {
			    var m1, m2;
			    h = isNaN(h) ? 0 : (h %= 360) < 0 ? h + 360 : h;
			    s = isNaN(s) ? 0 : s < 0 ? 0 : s > 1 ? 1 : s;
			    l = l < 0 ? 0 : l > 1 ? 1 : l;
			    m2 = l <= .5 ? l * (1 + s) : l + s - l * s;
			    m1 = 2 * l - m2;
			    function v(h) {
			      if (h > 360) h -= 360; else if (h < 0) h += 360;
			      if (h < 60) return m1 + (m2 - m1) * h / 60;
			      if (h < 180) return m2;
			      if (h < 240) return m1 + (m2 - m1) * (240 - h) / 60;
			      return m1;
			    }
			    function vv(h) {
			      return Math.round(v(h) * 255);
			    }
			    return new d3_rgb(vv(h + 120), vv(h), vv(h - 120));
			  }
			  var ε = 1e-6, ε2 = ε * ε, π = Math.PI, τ = 2 * π, τε = τ - ε, halfπ = π / 2, d3_radians = π / 180, d3_degrees = 180 / π;
			  function d3_sgn(x) {
			    return x > 0 ? 1 : x < 0 ? -1 : 0;
			  }
			  function d3_cross2d(a, b, c) {
			    return (b[0] - a[0]) * (c[1] - a[1]) - (b[1] - a[1]) * (c[0] - a[0]);
			  }
			  function d3_acos(x) {
			    return x > 1 ? 0 : x < -1 ? π : Math.acos(x);
			  }
			  function d3_asin(x) {
			    return x > 1 ? halfπ : x < -1 ? -halfπ : Math.asin(x);
			  }
			  function d3_sinh(x) {
			    return ((x = Math.exp(x)) - 1 / x) / 2;
			  }
			  function d3_cosh(x) {
			    return ((x = Math.exp(x)) + 1 / x) / 2;
			  }
			  function d3_tanh(x) {
			    return ((x = Math.exp(2 * x)) - 1) / (x + 1);
			  }
			  function d3_haversin(x) {
			    return (x = Math.sin(x / 2)) * x;
			  }
			  d3.hcl = d3_hcl;
			  function d3_hcl(h, c, l) {
			    return this instanceof d3_hcl ? void (this.h = +h, this.c = +c, this.l = +l) : arguments.length < 2 ? h instanceof d3_hcl ? new d3_hcl(h.h, h.c, h.l) : h instanceof d3_lab ? d3_lab_hcl(h.l, h.a, h.b) : d3_lab_hcl((h = d3_rgb_lab((h = d3.rgb(h)).r, h.g, h.b)).l, h.a, h.b) : new d3_hcl(h, c, l);
			  }
			  var d3_hclPrototype = d3_hcl.prototype = new d3_color();
			  d3_hclPrototype.brighter = function(k) {
			    return new d3_hcl(this.h, this.c, Math.min(100, this.l + d3_lab_K * (arguments.length ? k : 1)));
			  };
			  d3_hclPrototype.darker = function(k) {
			    return new d3_hcl(this.h, this.c, Math.max(0, this.l - d3_lab_K * (arguments.length ? k : 1)));
			  };
			  d3_hclPrototype.rgb = function() {
			    return d3_hcl_lab(this.h, this.c, this.l).rgb();
			  };
			  function d3_hcl_lab(h, c, l) {
			    if (isNaN(h)) h = 0;
			    if (isNaN(c)) c = 0;
			    return new d3_lab(l, Math.cos(h *= d3_radians) * c, Math.sin(h) * c);
			  }
			  d3.lab = d3_lab;
			  function d3_lab(l, a, b) {
			    return this instanceof d3_lab ? void (this.l = +l, this.a = +a, this.b = +b) : arguments.length < 2 ? l instanceof d3_lab ? new d3_lab(l.l, l.a, l.b) : l instanceof d3_hcl ? d3_hcl_lab(l.h, l.c, l.l) : d3_rgb_lab((l = d3_rgb(l)).r, l.g, l.b) : new d3_lab(l, a, b);
			  }
			  var d3_lab_K = 18;
			  var d3_lab_X = .95047, d3_lab_Y = 1, d3_lab_Z = 1.08883;
			  var d3_labPrototype = d3_lab.prototype = new d3_color();
			  d3_labPrototype.brighter = function(k) {
			    return new d3_lab(Math.min(100, this.l + d3_lab_K * (arguments.length ? k : 1)), this.a, this.b);
			  };
			  d3_labPrototype.darker = function(k) {
			    return new d3_lab(Math.max(0, this.l - d3_lab_K * (arguments.length ? k : 1)), this.a, this.b);
			  };
			  d3_labPrototype.rgb = function() {
			    return d3_lab_rgb(this.l, this.a, this.b);
			  };
			  function d3_lab_rgb(l, a, b) {
			    var y = (l + 16) / 116, x = y + a / 500, z = y - b / 200;
			    x = d3_lab_xyz(x) * d3_lab_X;
			    y = d3_lab_xyz(y) * d3_lab_Y;
			    z = d3_lab_xyz(z) * d3_lab_Z;
			    return new d3_rgb(d3_xyz_rgb(3.2404542 * x - 1.5371385 * y - .4985314 * z), d3_xyz_rgb(-.969266 * x + 1.8760108 * y + .041556 * z), d3_xyz_rgb(.0556434 * x - .2040259 * y + 1.0572252 * z));
			  }
			  function d3_lab_hcl(l, a, b) {
			    return l > 0 ? new d3_hcl(Math.atan2(b, a) * d3_degrees, Math.sqrt(a * a + b * b), l) : new d3_hcl(NaN, NaN, l);
			  }
			  function d3_lab_xyz(x) {
			    return x > .206893034 ? x * x * x : (x - 4 / 29) / 7.787037;
			  }
			  function d3_xyz_lab(x) {
			    return x > .008856 ? Math.pow(x, 1 / 3) : 7.787037 * x + 4 / 29;
			  }
			  function d3_xyz_rgb(r) {
			    return Math.round(255 * (r <= .00304 ? 12.92 * r : 1.055 * Math.pow(r, 1 / 2.4) - .055));
			  }
			  d3.rgb = d3_rgb;
			  function d3_rgb(r, g, b) {
			    return this instanceof d3_rgb ? void (this.r = ~~r, this.g = ~~g, this.b = ~~b) : arguments.length < 2 ? r instanceof d3_rgb ? new d3_rgb(r.r, r.g, r.b) : d3_rgb_parse("" + r, d3_rgb, d3_hsl_rgb) : new d3_rgb(r, g, b);
			  }
			  function d3_rgbNumber(value) {
			    return new d3_rgb(value >> 16, value >> 8 & 255, value & 255);
			  }
			  function d3_rgbString(value) {
			    return d3_rgbNumber(value) + "";
			  }
			  var d3_rgbPrototype = d3_rgb.prototype = new d3_color();
			  d3_rgbPrototype.brighter = function(k) {
			    k = Math.pow(.7, arguments.length ? k : 1);
			    var r = this.r, g = this.g, b = this.b, i = 30;
			    if (!r && !g && !b) return new d3_rgb(i, i, i);
			    if (r && r < i) r = i;
			    if (g && g < i) g = i;
			    if (b && b < i) b = i;
			    return new d3_rgb(Math.min(255, r / k), Math.min(255, g / k), Math.min(255, b / k));
			  };
			  d3_rgbPrototype.darker = function(k) {
			    k = Math.pow(.7, arguments.length ? k : 1);
			    return new d3_rgb(k * this.r, k * this.g, k * this.b);
			  };
			  d3_rgbPrototype.hsl = function() {
			    return d3_rgb_hsl(this.r, this.g, this.b);
			  };
			  d3_rgbPrototype.toString = function() {
			    return "#" + d3_rgb_hex(this.r) + d3_rgb_hex(this.g) + d3_rgb_hex(this.b);
			  };
			  function d3_rgb_hex(v) {
			    return v < 16 ? "0" + Math.max(0, v).toString(16) : Math.min(255, v).toString(16);
			  }
			  function d3_rgb_parse(format, rgb, hsl) {
			    var r = 0, g = 0, b = 0, m1, m2, color;
			    m1 = /([a-z]+)\((.*)\)/i.exec(format);
			    if (m1) {
			      m2 = m1[2].split(",");
			      switch (m1[1]) {
			       case "hsl":
			        {
			          return hsl(parseFloat(m2[0]), parseFloat(m2[1]) / 100, parseFloat(m2[2]) / 100);
			        }

			       case "rgb":
			        {
			          return rgb(d3_rgb_parseNumber(m2[0]), d3_rgb_parseNumber(m2[1]), d3_rgb_parseNumber(m2[2]));
			        }
			      }
			    }
			    if (color = d3_rgb_names.get(format.toLowerCase())) {
			      return rgb(color.r, color.g, color.b);
			    }
			    if (format != null && format.charAt(0) === "#" && !isNaN(color = parseInt(format.slice(1), 16))) {
			      if (format.length === 4) {
			        r = (color & 3840) >> 4;
			        r = r >> 4 | r;
			        g = color & 240;
			        g = g >> 4 | g;
			        b = color & 15;
			        b = b << 4 | b;
			      } else if (format.length === 7) {
			        r = (color & 16711680) >> 16;
			        g = (color & 65280) >> 8;
			        b = color & 255;
			      }
			    }
			    return rgb(r, g, b);
			  }
			  function d3_rgb_hsl(r, g, b) {
			    var min = Math.min(r /= 255, g /= 255, b /= 255), max = Math.max(r, g, b), d = max - min, h, s, l = (max + min) / 2;
			    if (d) {
			      s = l < .5 ? d / (max + min) : d / (2 - max - min);
			      if (r == max) h = (g - b) / d + (g < b ? 6 : 0); else if (g == max) h = (b - r) / d + 2; else h = (r - g) / d + 4;
			      h *= 60;
			    } else {
			      h = NaN;
			      s = l > 0 && l < 1 ? 0 : h;
			    }
			    return new d3_hsl(h, s, l);
			  }
			  function d3_rgb_lab(r, g, b) {
			    r = d3_rgb_xyz(r);
			    g = d3_rgb_xyz(g);
			    b = d3_rgb_xyz(b);
			    var x = d3_xyz_lab((.4124564 * r + .3575761 * g + .1804375 * b) / d3_lab_X), y = d3_xyz_lab((.2126729 * r + .7151522 * g + .072175 * b) / d3_lab_Y), z = d3_xyz_lab((.0193339 * r + .119192 * g + .9503041 * b) / d3_lab_Z);
			    return d3_lab(116 * y - 16, 500 * (x - y), 200 * (y - z));
			  }
			  function d3_rgb_xyz(r) {
			    return (r /= 255) <= .04045 ? r / 12.92 : Math.pow((r + .055) / 1.055, 2.4);
			  }
			  function d3_rgb_parseNumber(c) {
			    var f = parseFloat(c);
			    return c.charAt(c.length - 1) === "%" ? Math.round(f * 2.55) : f;
			  }
			  var d3_rgb_names = d3.map({
			    aliceblue: 15792383,
			    antiquewhite: 16444375,
			    aqua: 65535,
			    aquamarine: 8388564,
			    azure: 15794175,
			    beige: 16119260,
			    bisque: 16770244,
			    black: 0,
			    blanchedalmond: 16772045,
			    blue: 255,
			    blueviolet: 9055202,
			    brown: 10824234,
			    burlywood: 14596231,
			    cadetblue: 6266528,
			    chartreuse: 8388352,
			    chocolate: 13789470,
			    coral: 16744272,
			    cornflowerblue: 6591981,
			    cornsilk: 16775388,
			    crimson: 14423100,
			    cyan: 65535,
			    darkblue: 139,
			    darkcyan: 35723,
			    darkgoldenrod: 12092939,
			    darkgray: 11119017,
			    darkgreen: 25600,
			    darkgrey: 11119017,
			    darkkhaki: 12433259,
			    darkmagenta: 9109643,
			    darkolivegreen: 5597999,
			    darkorange: 16747520,
			    darkorchid: 10040012,
			    darkred: 9109504,
			    darksalmon: 15308410,
			    darkseagreen: 9419919,
			    darkslateblue: 4734347,
			    darkslategray: 3100495,
			    darkslategrey: 3100495,
			    darkturquoise: 52945,
			    darkviolet: 9699539,
			    deeppink: 16716947,
			    deepskyblue: 49151,
			    dimgray: 6908265,
			    dimgrey: 6908265,
			    dodgerblue: 2003199,
			    firebrick: 11674146,
			    floralwhite: 16775920,
			    forestgreen: 2263842,
			    fuchsia: 16711935,
			    gainsboro: 14474460,
			    ghostwhite: 16316671,
			    gold: 16766720,
			    goldenrod: 14329120,
			    gray: 8421504,
			    green: 32768,
			    greenyellow: 11403055,
			    grey: 8421504,
			    honeydew: 15794160,
			    hotpink: 16738740,
			    indianred: 13458524,
			    indigo: 4915330,
			    ivory: 16777200,
			    khaki: 15787660,
			    lavender: 15132410,
			    lavenderblush: 16773365,
			    lawngreen: 8190976,
			    lemonchiffon: 16775885,
			    lightblue: 11393254,
			    lightcoral: 15761536,
			    lightcyan: 14745599,
			    lightgoldenrodyellow: 16448210,
			    lightgray: 13882323,
			    lightgreen: 9498256,
			    lightgrey: 13882323,
			    lightpink: 16758465,
			    lightsalmon: 16752762,
			    lightseagreen: 2142890,
			    lightskyblue: 8900346,
			    lightslategray: 7833753,
			    lightslategrey: 7833753,
			    lightsteelblue: 11584734,
			    lightyellow: 16777184,
			    lime: 65280,
			    limegreen: 3329330,
			    linen: 16445670,
			    magenta: 16711935,
			    maroon: 8388608,
			    mediumaquamarine: 6737322,
			    mediumblue: 205,
			    mediumorchid: 12211667,
			    mediumpurple: 9662683,
			    mediumseagreen: 3978097,
			    mediumslateblue: 8087790,
			    mediumspringgreen: 64154,
			    mediumturquoise: 4772300,
			    mediumvioletred: 13047173,
			    midnightblue: 1644912,
			    mintcream: 16121850,
			    mistyrose: 16770273,
			    moccasin: 16770229,
			    navajowhite: 16768685,
			    navy: 128,
			    oldlace: 16643558,
			    olive: 8421376,
			    olivedrab: 7048739,
			    orange: 16753920,
			    orangered: 16729344,
			    orchid: 14315734,
			    palegoldenrod: 15657130,
			    palegreen: 10025880,
			    paleturquoise: 11529966,
			    palevioletred: 14381203,
			    papayawhip: 16773077,
			    peachpuff: 16767673,
			    peru: 13468991,
			    pink: 16761035,
			    plum: 14524637,
			    powderblue: 11591910,
			    purple: 8388736,
			    rebeccapurple: 6697881,
			    red: 16711680,
			    rosybrown: 12357519,
			    royalblue: 4286945,
			    saddlebrown: 9127187,
			    salmon: 16416882,
			    sandybrown: 16032864,
			    seagreen: 3050327,
			    seashell: 16774638,
			    sienna: 10506797,
			    silver: 12632256,
			    skyblue: 8900331,
			    slateblue: 6970061,
			    slategray: 7372944,
			    slategrey: 7372944,
			    snow: 16775930,
			    springgreen: 65407,
			    steelblue: 4620980,
			    tan: 13808780,
			    teal: 32896,
			    thistle: 14204888,
			    tomato: 16737095,
			    turquoise: 4251856,
			    violet: 15631086,
			    wheat: 16113331,
			    white: 16777215,
			    whitesmoke: 16119285,
			    yellow: 16776960,
			    yellowgreen: 10145074
			  });
			  d3_rgb_names.forEach(function(key, value) {
			    d3_rgb_names.set(key, d3_rgbNumber(value));
			  });
			  d3.scale.category10 = function() {
			    return d3.scale.ordinal().range(d3_category10);
			  };
			  d3.scale.category20 = function() {
			    return d3.scale.ordinal().range(d3_category20);
			  };
			  d3.scale.category20b = function() {
			    return d3.scale.ordinal().range(d3_category20b);
			  };
			  d3.scale.category20c = function() {
			    return d3.scale.ordinal().range(d3_category20c);
			  };
			  var d3_category10 = [ 2062260, 16744206, 2924588, 14034728, 9725885, 9197131, 14907330, 8355711, 12369186, 1556175 ].map(d3_rgbString);
			  var d3_category20 = [ 2062260, 11454440, 16744206, 16759672, 2924588, 10018698, 14034728, 16750742, 9725885, 12955861, 9197131, 12885140, 14907330, 16234194, 8355711, 13092807, 12369186, 14408589, 1556175, 10410725 ].map(d3_rgbString);
			  var d3_category20b = [ 3750777, 5395619, 7040719, 10264286, 6519097, 9216594, 11915115, 13556636, 9202993, 12426809, 15186514, 15190932, 8666169, 11356490, 14049643, 15177372, 8077683, 10834324, 13528509, 14589654 ].map(d3_rgbString);
			  var d3_category20c = [ 3244733, 7057110, 10406625, 13032431, 15095053, 16616764, 16625259, 16634018, 3253076, 7652470, 10607003, 13101504, 7695281, 10394312, 12369372, 14342891, 6513507, 9868950, 12434877, 14277081 ].map(d3_rgbString);
			  d3.svg = {};
			  function d3_functor(v) {
			    return typeof v === "function" ? v : function() {
			      return v;
			    };
			  }
			  d3.functor = d3_functor;
			  d3.svg.symbol = function() {
			    var type = d3_svg_symbolType, size = d3_svg_symbolSize;
			    function symbol(d, i) {
			      return (d3_svg_symbols.get(type.call(this, d, i)) || d3_svg_symbolCircle)(size.call(this, d, i));
			    }
			    symbol.type = function(x) {
			      if (!arguments.length) return type;
			      type = d3_functor(x);
			      return symbol;
			    };
			    symbol.size = function(x) {
			      if (!arguments.length) return size;
			      size = d3_functor(x);
			      return symbol;
			    };
			    return symbol;
			  };
			  function d3_svg_symbolSize() {
			    return 64;
			  }
			  function d3_svg_symbolType() {
			    return "circle";
			  }
			  function d3_svg_symbolCircle(size) {
			    var r = Math.sqrt(size / π);
			    return "M0," + r + "A" + r + "," + r + " 0 1,1 0," + -r + "A" + r + "," + r + " 0 1,1 0," + r + "Z";
			  }
			  var d3_svg_symbols = d3.map({
			    circle: d3_svg_symbolCircle,
			    cross: function(size) {
			      var r = Math.sqrt(size / 5) / 2;
			      return "M" + -3 * r + "," + -r + "H" + -r + "V" + -3 * r + "H" + r + "V" + -r + "H" + 3 * r + "V" + r + "H" + r + "V" + 3 * r + "H" + -r + "V" + r + "H" + -3 * r + "Z";
			    },
			    diamond: function(size) {
			      var ry = Math.sqrt(size / (2 * d3_svg_symbolTan30)), rx = ry * d3_svg_symbolTan30;
			      return "M0," + -ry + "L" + rx + ",0" + " 0," + ry + " " + -rx + ",0" + "Z";
			    },
			    square: function(size) {
			      var r = Math.sqrt(size) / 2;
			      return "M" + -r + "," + -r + "L" + r + "," + -r + " " + r + "," + r + " " + -r + "," + r + "Z";
			    },
			    "triangle-down": function(size) {
			      var rx = Math.sqrt(size / d3_svg_symbolSqrt3), ry = rx * d3_svg_symbolSqrt3 / 2;
			      return "M0," + ry + "L" + rx + "," + -ry + " " + -rx + "," + -ry + "Z";
			    },
			    "triangle-up": function(size) {
			      var rx = Math.sqrt(size / d3_svg_symbolSqrt3), ry = rx * d3_svg_symbolSqrt3 / 2;
			      return "M0," + -ry + "L" + rx + "," + ry + " " + -rx + "," + ry + "Z";
			    }
			  });
			  d3.svg.symbolTypes = d3_svg_symbols.keys();
			  var d3_svg_symbolSqrt3 = Math.sqrt(3), d3_svg_symbolTan30 = Math.tan(30 * d3_radians);
			  function d3_identity(d) {
			    return d;
			  }
			  d3.rebind = function(target, source) {
			    var i = 1, n = arguments.length, method;
			    while (++i < n) target[method = arguments[i]] = d3_rebind(target, source, source[method]);
			    return target;
			  };
			  function d3_rebind(target, source, method) {
			    return function() {
			      var value = method.apply(source, arguments);
			      return value === source ? target : value;
			    };
			  }
			  d3.interpolateRgb = d3_interpolateRgb;
			  function d3_interpolateRgb(a, b) {
			    a = d3.rgb(a);
			    b = d3.rgb(b);
			    var ar = a.r, ag = a.g, ab = a.b, br = b.r - ar, bg = b.g - ag, bb = b.b - ab;
			    return function(t) {
			      return "#" + d3_rgb_hex(Math.round(ar + br * t)) + d3_rgb_hex(Math.round(ag + bg * t)) + d3_rgb_hex(Math.round(ab + bb * t));
			    };
			  }
			  d3.interpolateObject = d3_interpolateObject;
			  function d3_interpolateObject(a, b) {
			    var i = {}, c = {}, k;
			    for (k in a) {
			      if (k in b) {
			        i[k] = d3_interpolate(a[k], b[k]);
			      } else {
			        c[k] = a[k];
			      }
			    }
			    for (k in b) {
			      if (!(k in a)) {
			        c[k] = b[k];
			      }
			    }
			    return function(t) {
			      for (k in i) c[k] = i[k](t);
			      return c;
			    };
			  }
			  d3.interpolateArray = d3_interpolateArray;
			  function d3_interpolateArray(a, b) {
			    var x = [], c = [], na = a.length, nb = b.length, n0 = Math.min(a.length, b.length), i;
			    for (i = 0; i < n0; ++i) x.push(d3_interpolate(a[i], b[i]));
			    for (;i < na; ++i) c[i] = a[i];
			    for (;i < nb; ++i) c[i] = b[i];
			    return function(t) {
			      for (i = 0; i < n0; ++i) c[i] = x[i](t);
			      return c;
			    };
			  }
			  d3.interpolateNumber = d3_interpolateNumber;
			  function d3_interpolateNumber(a, b) {
			    a = +a, b = +b;
			    return function(t) {
			      return a * (1 - t) + b * t;
			    };
			  }
			  d3.interpolateString = d3_interpolateString;
			  function d3_interpolateString(a, b) {
			    var bi = d3_interpolate_numberA.lastIndex = d3_interpolate_numberB.lastIndex = 0, am, bm, bs, i = -1, s = [], q = [];
			    a = a + "", b = b + "";
			    while ((am = d3_interpolate_numberA.exec(a)) && (bm = d3_interpolate_numberB.exec(b))) {
			      if ((bs = bm.index) > bi) {
			        bs = b.slice(bi, bs);
			        if (s[i]) s[i] += bs; else s[++i] = bs;
			      }
			      if ((am = am[0]) === (bm = bm[0])) {
			        if (s[i]) s[i] += bm; else s[++i] = bm;
			      } else {
			        s[++i] = null;
			        q.push({
			          i: i,
			          x: d3_interpolateNumber(am, bm)
			        });
			      }
			      bi = d3_interpolate_numberB.lastIndex;
			    }
			    if (bi < b.length) {
			      bs = b.slice(bi);
			      if (s[i]) s[i] += bs; else s[++i] = bs;
			    }
			    return s.length < 2 ? q[0] ? (b = q[0].x, function(t) {
			      return b(t) + "";
			    }) : function() {
			      return b;
			    } : (b = q.length, function(t) {
			      for (var i = 0, o; i < b; ++i) s[(o = q[i]).i] = o.x(t);
			      return s.join("");
			    });
			  }
			  var d3_interpolate_numberA = /[-+]?(?:\d+\.?\d*|\.?\d+)(?:[eE][-+]?\d+)?/g, d3_interpolate_numberB = new RegExp(d3_interpolate_numberA.source, "g");
			  d3.interpolate = d3_interpolate;
			  function d3_interpolate(a, b) {
			    var i = d3.interpolators.length, f;
			    while (--i >= 0 && !(f = d3.interpolators[i](a, b))) ;
			    return f;
			  }
			  d3.interpolators = [ function(a, b) {
			    var t = typeof b;
			    return (t === "string" ? d3_rgb_names.has(b) || /^(#|rgb\(|hsl\()/.test(b) ? d3_interpolateRgb : d3_interpolateString : b instanceof d3_color ? d3_interpolateRgb : Array.isArray(b) ? d3_interpolateArray : t === "object" && isNaN(b) ? d3_interpolateObject : d3_interpolateNumber)(a, b);
			  } ];
			  d3.interpolateRound = d3_interpolateRound;
			  function d3_interpolateRound(a, b) {
			    b -= a;
			    return function(t) {
			      return Math.round(a + b * t);
			    };
			  }
			  function d3_uninterpolateNumber(a, b) {
			    b = (b -= a = +a) || 1 / b;
			    return function(x) {
			      return (x - a) / b;
			    };
			  }
			  function d3_uninterpolateClamp(a, b) {
			    b = (b -= a = +a) || 1 / b;
			    return function(x) {
			      return Math.max(0, Math.min(1, (x - a) / b));
			    };
			  }
			  function d3_format_precision(x, p) {
			    return p - (x ? Math.ceil(Math.log(x) / Math.LN10) : 1);
			  }
			  d3.round = function(x, n) {
			    return n ? Math.round(x * (n = Math.pow(10, n))) / n : Math.round(x);
			  };
			  var d3_formatPrefixes = [ "y", "z", "a", "f", "p", "n", "µ", "m", "", "k", "M", "G", "T", "P", "E", "Z", "Y" ].map(d3_formatPrefix);
			  d3.formatPrefix = function(value, precision) {
			    var i = 0;
			    if (value) {
			      if (value < 0) value *= -1;
			      if (precision) value = d3.round(value, d3_format_precision(value, precision));
			      i = 1 + Math.floor(1e-12 + Math.log(value) / Math.LN10);
			      i = Math.max(-24, Math.min(24, Math.floor((i - 1) / 3) * 3));
			    }
			    return d3_formatPrefixes[8 + i / 3];
			  };
			  function d3_formatPrefix(d, i) {
			    var k = Math.pow(10, abs(8 - i) * 3);
			    return {
			      scale: i > 8 ? function(d) {
			        return d / k;
			      } : function(d) {
			        return d * k;
			      },
			      symbol: d
			    };
			  }
			  function d3_locale_numberFormat(locale) {
			    var locale_decimal = locale.decimal, locale_thousands = locale.thousands, locale_grouping = locale.grouping, locale_currency = locale.currency, formatGroup = locale_grouping && locale_thousands ? function(value, width) {
			      var i = value.length, t = [], j = 0, g = locale_grouping[0], length = 0;
			      while (i > 0 && g > 0) {
			        if (length + g + 1 > width) g = Math.max(1, width - length);
			        t.push(value.substring(i -= g, i + g));
			        if ((length += g + 1) > width) break;
			        g = locale_grouping[j = (j + 1) % locale_grouping.length];
			      }
			      return t.reverse().join(locale_thousands);
			    } : d3_identity;
			    return function(specifier) {
			      var match = d3_format_re.exec(specifier), fill = match[1] || " ", align = match[2] || ">", sign = match[3] || "-", symbol = match[4] || "", zfill = match[5], width = +match[6], comma = match[7], precision = match[8], type = match[9], scale = 1, prefix = "", suffix = "", integer = false, exponent = true;
			      if (precision) precision = +precision.substring(1);
			      if (zfill || fill === "0" && align === "=") {
			        zfill = fill = "0";
			        align = "=";
			      }
			      switch (type) {
			       case "n":
			        comma = true;
			        type = "g";
			        break;

			       case "%":
			        scale = 100;
			        suffix = "%";
			        type = "f";
			        break;

			       case "p":
			        scale = 100;
			        suffix = "%";
			        type = "r";
			        break;

			       case "b":
			       case "o":
			       case "x":
			       case "X":
			        if (symbol === "#") prefix = "0" + type.toLowerCase();

			       case "c":
			        exponent = false;

			       case "d":
			        integer = true;
			        precision = 0;
			        break;

			       case "s":
			        scale = -1;
			        type = "r";
			        break;
			      }
			      if (symbol === "$") prefix = locale_currency[0], suffix = locale_currency[1];
			      if (type == "r" && !precision) type = "g";
			      if (precision != null) {
			        if (type == "g") precision = Math.max(1, Math.min(21, precision)); else if (type == "e" || type == "f") precision = Math.max(0, Math.min(20, precision));
			      }
			      type = d3_format_types.get(type) || d3_format_typeDefault;
			      var zcomma = zfill && comma;
			      return function(value) {
			        var fullSuffix = suffix;
			        if (integer && value % 1) return "";
			        var negative = value < 0 || value === 0 && 1 / value < 0 ? (value = -value, "-") : sign === "-" ? "" : sign;
			        if (scale < 0) {
			          var unit = d3.formatPrefix(value, precision);
			          value = unit.scale(value);
			          fullSuffix = unit.symbol + suffix;
			        } else {
			          value *= scale;
			        }
			        value = type(value, precision);
			        var i = value.lastIndexOf("."), before, after;
			        if (i < 0) {
			          var j = exponent ? value.lastIndexOf("e") : -1;
			          if (j < 0) before = value, after = ""; else before = value.substring(0, j), after = value.substring(j);
			        } else {
			          before = value.substring(0, i);
			          after = locale_decimal + value.substring(i + 1);
			        }
			        if (!zfill && comma) before = formatGroup(before, Infinity);
			        var length = prefix.length + before.length + after.length + (zcomma ? 0 : negative.length), padding = length < width ? new Array(length = width - length + 1).join(fill) : "";
			        if (zcomma) before = formatGroup(padding + before, padding.length ? width - after.length : Infinity);
			        negative += prefix;
			        value = before + after;
			        return (align === "<" ? negative + value + padding : align === ">" ? padding + negative + value : align === "^" ? padding.substring(0, length >>= 1) + negative + value + padding.substring(length) : negative + (zcomma ? value : padding + value)) + fullSuffix;
			      };
			    };
			  }
			  var d3_format_re = /(?:([^{])?([<>=^]))?([+\- ])?([$#])?(0)?(\d+)?(,)?(\.-?\d+)?([a-z%])?/i;
			  var d3_format_types = d3.map({
			    b: function(x) {
			      return x.toString(2);
			    },
			    c: function(x) {
			      return String.fromCharCode(x);
			    },
			    o: function(x) {
			      return x.toString(8);
			    },
			    x: function(x) {
			      return x.toString(16);
			    },
			    X: function(x) {
			      return x.toString(16).toUpperCase();
			    },
			    g: function(x, p) {
			      return x.toPrecision(p);
			    },
			    e: function(x, p) {
			      return x.toExponential(p);
			    },
			    f: function(x, p) {
			      return x.toFixed(p);
			    },
			    r: function(x, p) {
			      return (x = d3.round(x, d3_format_precision(x, p))).toFixed(Math.max(0, Math.min(20, d3_format_precision(x * (1 + 1e-15), p))));
			    }
			  });
			  function d3_format_typeDefault(x) {
			    return x + "";
			  }
			  d3.requote = function(s) {
			    return s.replace(d3_requote_re, "\\$&");
			  };
			  var d3_requote_re = /[\\\^\$\*\+\?\|\[\]\(\)\.\{\}]/g;
			  var d3_time = d3.time = {}, d3_date = Date;
			  function d3_date_utc() {
			    this._ = new Date(arguments.length > 1 ? Date.UTC.apply(this, arguments) : arguments[0]);
			  }
			  d3_date_utc.prototype = {
			    getDate: function() {
			      return this._.getUTCDate();
			    },
			    getDay: function() {
			      return this._.getUTCDay();
			    },
			    getFullYear: function() {
			      return this._.getUTCFullYear();
			    },
			    getHours: function() {
			      return this._.getUTCHours();
			    },
			    getMilliseconds: function() {
			      return this._.getUTCMilliseconds();
			    },
			    getMinutes: function() {
			      return this._.getUTCMinutes();
			    },
			    getMonth: function() {
			      return this._.getUTCMonth();
			    },
			    getSeconds: function() {
			      return this._.getUTCSeconds();
			    },
			    getTime: function() {
			      return this._.getTime();
			    },
			    getTimezoneOffset: function() {
			      return 0;
			    },
			    valueOf: function() {
			      return this._.valueOf();
			    },
			    setDate: function() {
			      d3_time_prototype.setUTCDate.apply(this._, arguments);
			    },
			    setDay: function() {
			      d3_time_prototype.setUTCDay.apply(this._, arguments);
			    },
			    setFullYear: function() {
			      d3_time_prototype.setUTCFullYear.apply(this._, arguments);
			    },
			    setHours: function() {
			      d3_time_prototype.setUTCHours.apply(this._, arguments);
			    },
			    setMilliseconds: function() {
			      d3_time_prototype.setUTCMilliseconds.apply(this._, arguments);
			    },
			    setMinutes: function() {
			      d3_time_prototype.setUTCMinutes.apply(this._, arguments);
			    },
			    setMonth: function() {
			      d3_time_prototype.setUTCMonth.apply(this._, arguments);
			    },
			    setSeconds: function() {
			      d3_time_prototype.setUTCSeconds.apply(this._, arguments);
			    },
			    setTime: function() {
			      d3_time_prototype.setTime.apply(this._, arguments);
			    }
			  };
			  var d3_time_prototype = Date.prototype;
			  function d3_time_interval(local, step, number) {
			    function round(date) {
			      var d0 = local(date), d1 = offset(d0, 1);
			      return date - d0 < d1 - date ? d0 : d1;
			    }
			    function ceil(date) {
			      step(date = local(new d3_date(date - 1)), 1);
			      return date;
			    }
			    function offset(date, k) {
			      step(date = new d3_date(+date), k);
			      return date;
			    }
			    function range(t0, t1, dt) {
			      var time = ceil(t0), times = [];
			      if (dt > 1) {
			        while (time < t1) {
			          if (!(number(time) % dt)) times.push(new Date(+time));
			          step(time, 1);
			        }
			      } else {
			        while (time < t1) times.push(new Date(+time)), step(time, 1);
			      }
			      return times;
			    }
			    function range_utc(t0, t1, dt) {
			      try {
			        d3_date = d3_date_utc;
			        var utc = new d3_date_utc();
			        utc._ = t0;
			        return range(utc, t1, dt);
			      } finally {
			        d3_date = Date;
			      }
			    }
			    local.floor = local;
			    local.round = round;
			    local.ceil = ceil;
			    local.offset = offset;
			    local.range = range;
			    var utc = local.utc = d3_time_interval_utc(local);
			    utc.floor = utc;
			    utc.round = d3_time_interval_utc(round);
			    utc.ceil = d3_time_interval_utc(ceil);
			    utc.offset = d3_time_interval_utc(offset);
			    utc.range = range_utc;
			    return local;
			  }
			  function d3_time_interval_utc(method) {
			    return function(date, k) {
			      try {
			        d3_date = d3_date_utc;
			        var utc = new d3_date_utc();
			        utc._ = date;
			        return method(utc, k)._;
			      } finally {
			        d3_date = Date;
			      }
			    };
			  }
			  d3_time.year = d3_time_interval(function(date) {
			    date = d3_time.day(date);
			    date.setMonth(0, 1);
			    return date;
			  }, function(date, offset) {
			    date.setFullYear(date.getFullYear() + offset);
			  }, function(date) {
			    return date.getFullYear();
			  });
			  d3_time.years = d3_time.year.range;
			  d3_time.years.utc = d3_time.year.utc.range;
			  d3_time.day = d3_time_interval(function(date) {
			    var day = new d3_date(2e3, 0);
			    day.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
			    return day;
			  }, function(date, offset) {
			    date.setDate(date.getDate() + offset);
			  }, function(date) {
			    return date.getDate() - 1;
			  });
			  d3_time.days = d3_time.day.range;
			  d3_time.days.utc = d3_time.day.utc.range;
			  d3_time.dayOfYear = function(date) {
			    var year = d3_time.year(date);
			    return Math.floor((date - year - (date.getTimezoneOffset() - year.getTimezoneOffset()) * 6e4) / 864e5);
			  };
			  [ "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday" ].forEach(function(day, i) {
			    i = 7 - i;
			    var interval = d3_time[day] = d3_time_interval(function(date) {
			      (date = d3_time.day(date)).setDate(date.getDate() - (date.getDay() + i) % 7);
			      return date;
			    }, function(date, offset) {
			      date.setDate(date.getDate() + Math.floor(offset) * 7);
			    }, function(date) {
			      var day = d3_time.year(date).getDay();
			      return Math.floor((d3_time.dayOfYear(date) + (day + i) % 7) / 7) - (day !== i);
			    });
			    d3_time[day + "s"] = interval.range;
			    d3_time[day + "s"].utc = interval.utc.range;
			    d3_time[day + "OfYear"] = function(date) {
			      var day = d3_time.year(date).getDay();
			      return Math.floor((d3_time.dayOfYear(date) + (day + i) % 7) / 7);
			    };
			  });
			  d3_time.week = d3_time.sunday;
			  d3_time.weeks = d3_time.sunday.range;
			  d3_time.weeks.utc = d3_time.sunday.utc.range;
			  d3_time.weekOfYear = d3_time.sundayOfYear;
			  function d3_locale_timeFormat(locale) {
			    var locale_dateTime = locale.dateTime, locale_date = locale.date, locale_time = locale.time, locale_periods = locale.periods, locale_days = locale.days, locale_shortDays = locale.shortDays, locale_months = locale.months, locale_shortMonths = locale.shortMonths;
			    function d3_time_format(template) {
			      var n = template.length;
			      function format(date) {
			        var string = [], i = -1, j = 0, c, p, f;
			        while (++i < n) {
			          if (template.charCodeAt(i) === 37) {
			            string.push(template.slice(j, i));
			            if ((p = d3_time_formatPads[c = template.charAt(++i)]) != null) c = template.charAt(++i);
			            if (f = d3_time_formats[c]) c = f(date, p == null ? c === "e" ? " " : "0" : p);
			            string.push(c);
			            j = i + 1;
			          }
			        }
			        string.push(template.slice(j, i));
			        return string.join("");
			      }
			      format.parse = function(string) {
			        var d = {
			          y: 1900,
			          m: 0,
			          d: 1,
			          H: 0,
			          M: 0,
			          S: 0,
			          L: 0,
			          Z: null
			        }, i = d3_time_parse(d, template, string, 0);
			        if (i != string.length) return null;
			        if ("p" in d) d.H = d.H % 12 + d.p * 12;
			        var localZ = d.Z != null && d3_date !== d3_date_utc, date = new (localZ ? d3_date_utc : d3_date)();
			        if ("j" in d) date.setFullYear(d.y, 0, d.j); else if ("w" in d && ("W" in d || "U" in d)) {
			          date.setFullYear(d.y, 0, 1);
			          date.setFullYear(d.y, 0, "W" in d ? (d.w + 6) % 7 + d.W * 7 - (date.getDay() + 5) % 7 : d.w + d.U * 7 - (date.getDay() + 6) % 7);
			        } else date.setFullYear(d.y, d.m, d.d);
			        date.setHours(d.H + (d.Z / 100 | 0), d.M + d.Z % 100, d.S, d.L);
			        return localZ ? date._ : date;
			      };
			      format.toString = function() {
			        return template;
			      };
			      return format;
			    }
			    function d3_time_parse(date, template, string, j) {
			      var c, p, t, i = 0, n = template.length, m = string.length;
			      while (i < n) {
			        if (j >= m) return -1;
			        c = template.charCodeAt(i++);
			        if (c === 37) {
			          t = template.charAt(i++);
			          p = d3_time_parsers[t in d3_time_formatPads ? template.charAt(i++) : t];
			          if (!p || (j = p(date, string, j)) < 0) return -1;
			        } else if (c != string.charCodeAt(j++)) {
			          return -1;
			        }
			      }
			      return j;
			    }
			    d3_time_format.utc = function(template) {
			      var local = d3_time_format(template);
			      function format(date) {
			        try {
			          d3_date = d3_date_utc;
			          var utc = new d3_date();
			          utc._ = date;
			          return local(utc);
			        } finally {
			          d3_date = Date;
			        }
			      }
			      format.parse = function(string) {
			        try {
			          d3_date = d3_date_utc;
			          var date = local.parse(string);
			          return date && date._;
			        } finally {
			          d3_date = Date;
			        }
			      };
			      format.toString = local.toString;
			      return format;
			    };
			    d3_time_format.multi = d3_time_format.utc.multi = d3_time_formatMulti;
			    var d3_time_periodLookup = d3.map(), d3_time_dayRe = d3_time_formatRe(locale_days), d3_time_dayLookup = d3_time_formatLookup(locale_days), d3_time_dayAbbrevRe = d3_time_formatRe(locale_shortDays), d3_time_dayAbbrevLookup = d3_time_formatLookup(locale_shortDays), d3_time_monthRe = d3_time_formatRe(locale_months), d3_time_monthLookup = d3_time_formatLookup(locale_months), d3_time_monthAbbrevRe = d3_time_formatRe(locale_shortMonths), d3_time_monthAbbrevLookup = d3_time_formatLookup(locale_shortMonths);
			    locale_periods.forEach(function(p, i) {
			      d3_time_periodLookup.set(p.toLowerCase(), i);
			    });
			    var d3_time_formats = {
			      a: function(d) {
			        return locale_shortDays[d.getDay()];
			      },
			      A: function(d) {
			        return locale_days[d.getDay()];
			      },
			      b: function(d) {
			        return locale_shortMonths[d.getMonth()];
			      },
			      B: function(d) {
			        return locale_months[d.getMonth()];
			      },
			      c: d3_time_format(locale_dateTime),
			      d: function(d, p) {
			        return d3_time_formatPad(d.getDate(), p, 2);
			      },
			      e: function(d, p) {
			        return d3_time_formatPad(d.getDate(), p, 2);
			      },
			      H: function(d, p) {
			        return d3_time_formatPad(d.getHours(), p, 2);
			      },
			      I: function(d, p) {
			        return d3_time_formatPad(d.getHours() % 12 || 12, p, 2);
			      },
			      j: function(d, p) {
			        return d3_time_formatPad(1 + d3_time.dayOfYear(d), p, 3);
			      },
			      L: function(d, p) {
			        return d3_time_formatPad(d.getMilliseconds(), p, 3);
			      },
			      m: function(d, p) {
			        return d3_time_formatPad(d.getMonth() + 1, p, 2);
			      },
			      M: function(d, p) {
			        return d3_time_formatPad(d.getMinutes(), p, 2);
			      },
			      p: function(d) {
			        return locale_periods[+(d.getHours() >= 12)];
			      },
			      S: function(d, p) {
			        return d3_time_formatPad(d.getSeconds(), p, 2);
			      },
			      U: function(d, p) {
			        return d3_time_formatPad(d3_time.sundayOfYear(d), p, 2);
			      },
			      w: function(d) {
			        return d.getDay();
			      },
			      W: function(d, p) {
			        return d3_time_formatPad(d3_time.mondayOfYear(d), p, 2);
			      },
			      x: d3_time_format(locale_date),
			      X: d3_time_format(locale_time),
			      y: function(d, p) {
			        return d3_time_formatPad(d.getFullYear() % 100, p, 2);
			      },
			      Y: function(d, p) {
			        return d3_time_formatPad(d.getFullYear() % 1e4, p, 4);
			      },
			      Z: d3_time_zone,
			      "%": function() {
			        return "%";
			      }
			    };
			    var d3_time_parsers = {
			      a: d3_time_parseWeekdayAbbrev,
			      A: d3_time_parseWeekday,
			      b: d3_time_parseMonthAbbrev,
			      B: d3_time_parseMonth,
			      c: d3_time_parseLocaleFull,
			      d: d3_time_parseDay,
			      e: d3_time_parseDay,
			      H: d3_time_parseHour24,
			      I: d3_time_parseHour24,
			      j: d3_time_parseDayOfYear,
			      L: d3_time_parseMilliseconds,
			      m: d3_time_parseMonthNumber,
			      M: d3_time_parseMinutes,
			      p: d3_time_parseAmPm,
			      S: d3_time_parseSeconds,
			      U: d3_time_parseWeekNumberSunday,
			      w: d3_time_parseWeekdayNumber,
			      W: d3_time_parseWeekNumberMonday,
			      x: d3_time_parseLocaleDate,
			      X: d3_time_parseLocaleTime,
			      y: d3_time_parseYear,
			      Y: d3_time_parseFullYear,
			      Z: d3_time_parseZone,
			      "%": d3_time_parseLiteralPercent
			    };
			    function d3_time_parseWeekdayAbbrev(date, string, i) {
			      d3_time_dayAbbrevRe.lastIndex = 0;
			      var n = d3_time_dayAbbrevRe.exec(string.slice(i));
			      return n ? (date.w = d3_time_dayAbbrevLookup.get(n[0].toLowerCase()), i + n[0].length) : -1;
			    }
			    function d3_time_parseWeekday(date, string, i) {
			      d3_time_dayRe.lastIndex = 0;
			      var n = d3_time_dayRe.exec(string.slice(i));
			      return n ? (date.w = d3_time_dayLookup.get(n[0].toLowerCase()), i + n[0].length) : -1;
			    }
			    function d3_time_parseMonthAbbrev(date, string, i) {
			      d3_time_monthAbbrevRe.lastIndex = 0;
			      var n = d3_time_monthAbbrevRe.exec(string.slice(i));
			      return n ? (date.m = d3_time_monthAbbrevLookup.get(n[0].toLowerCase()), i + n[0].length) : -1;
			    }
			    function d3_time_parseMonth(date, string, i) {
			      d3_time_monthRe.lastIndex = 0;
			      var n = d3_time_monthRe.exec(string.slice(i));
			      return n ? (date.m = d3_time_monthLookup.get(n[0].toLowerCase()), i + n[0].length) : -1;
			    }
			    function d3_time_parseLocaleFull(date, string, i) {
			      return d3_time_parse(date, d3_time_formats.c.toString(), string, i);
			    }
			    function d3_time_parseLocaleDate(date, string, i) {
			      return d3_time_parse(date, d3_time_formats.x.toString(), string, i);
			    }
			    function d3_time_parseLocaleTime(date, string, i) {
			      return d3_time_parse(date, d3_time_formats.X.toString(), string, i);
			    }
			    function d3_time_parseAmPm(date, string, i) {
			      var n = d3_time_periodLookup.get(string.slice(i, i += 2).toLowerCase());
			      return n == null ? -1 : (date.p = n, i);
			    }
			    return d3_time_format;
			  }
			  var d3_time_formatPads = {
			    "-": "",
			    _: " ",
			    "0": "0"
			  }, d3_time_numberRe = /^\s*\d+/, d3_time_percentRe = /^%/;
			  function d3_time_formatPad(value, fill, width) {
			    var sign = value < 0 ? "-" : "", string = (sign ? -value : value) + "", length = string.length;
			    return sign + (length < width ? new Array(width - length + 1).join(fill) + string : string);
			  }
			  function d3_time_formatRe(names) {
			    return new RegExp("^(?:" + names.map(d3.requote).join("|") + ")", "i");
			  }
			  function d3_time_formatLookup(names) {
			    var map = new d3_Map(), i = -1, n = names.length;
			    while (++i < n) map.set(names[i].toLowerCase(), i);
			    return map;
			  }
			  function d3_time_parseWeekdayNumber(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 1));
			    return n ? (date.w = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseWeekNumberSunday(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i));
			    return n ? (date.U = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseWeekNumberMonday(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i));
			    return n ? (date.W = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseFullYear(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 4));
			    return n ? (date.y = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseYear(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 2));
			    return n ? (date.y = d3_time_expandYear(+n[0]), i + n[0].length) : -1;
			  }
			  function d3_time_parseZone(date, string, i) {
			    return /^[+-]\d{4}$/.test(string = string.slice(i, i + 5)) ? (date.Z = -string, 
			    i + 5) : -1;
			  }
			  function d3_time_expandYear(d) {
			    return d + (d > 68 ? 1900 : 2e3);
			  }
			  function d3_time_parseMonthNumber(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 2));
			    return n ? (date.m = n[0] - 1, i + n[0].length) : -1;
			  }
			  function d3_time_parseDay(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 2));
			    return n ? (date.d = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseDayOfYear(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 3));
			    return n ? (date.j = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseHour24(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 2));
			    return n ? (date.H = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseMinutes(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 2));
			    return n ? (date.M = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseSeconds(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 2));
			    return n ? (date.S = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_parseMilliseconds(date, string, i) {
			    d3_time_numberRe.lastIndex = 0;
			    var n = d3_time_numberRe.exec(string.slice(i, i + 3));
			    return n ? (date.L = +n[0], i + n[0].length) : -1;
			  }
			  function d3_time_zone(d) {
			    var z = d.getTimezoneOffset(), zs = z > 0 ? "-" : "+", zh = abs(z) / 60 | 0, zm = abs(z) % 60;
			    return zs + d3_time_formatPad(zh, "0", 2) + d3_time_formatPad(zm, "0", 2);
			  }
			  function d3_time_parseLiteralPercent(date, string, i) {
			    d3_time_percentRe.lastIndex = 0;
			    var n = d3_time_percentRe.exec(string.slice(i, i + 1));
			    return n ? i + n[0].length : -1;
			  }
			  function d3_time_formatMulti(formats) {
			    var n = formats.length, i = -1;
			    while (++i < n) formats[i][0] = this(formats[i][0]);
			    return function(date) {
			      var i = 0, f = formats[i];
			      while (!f[1](date)) f = formats[++i];
			      return f[0](date);
			    };
			  }
			  d3.locale = function(locale) {
			    return {
			      numberFormat: d3_locale_numberFormat(locale),
			      timeFormat: d3_locale_timeFormat(locale)
			    };
			  };
			  var d3_locale_enUS = d3.locale({
			    decimal: ".",
			    thousands: ",",
			    grouping: [ 3 ],
			    currency: [ "$", "" ],
			    dateTime: "%a %b %e %X %Y",
			    date: "%m/%d/%Y",
			    time: "%H:%M:%S",
			    periods: [ "AM", "PM" ],
			    days: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
			    shortDays: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
			    months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
			    shortMonths: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
			  });
			  d3.format = d3_locale_enUS.numberFormat;
			  function d3_scale_bilinear(domain, range, uninterpolate, interpolate) {
			    var u = uninterpolate(domain[0], domain[1]), i = interpolate(range[0], range[1]);
			    return function(x) {
			      return i(u(x));
			    };
			  }
			  function d3_scale_nice(domain, nice) {
			    var i0 = 0, i1 = domain.length - 1, x0 = domain[i0], x1 = domain[i1], dx;
			    if (x1 < x0) {
			      dx = i0, i0 = i1, i1 = dx;
			      dx = x0, x0 = x1, x1 = dx;
			    }
			    domain[i0] = nice.floor(x0);
			    domain[i1] = nice.ceil(x1);
			    return domain;
			  }
			  function d3_scale_niceStep(step) {
			    return step ? {
			      floor: function(x) {
			        return Math.floor(x / step) * step;
			      },
			      ceil: function(x) {
			        return Math.ceil(x / step) * step;
			      }
			    } : d3_scale_niceIdentity;
			  }
			  var d3_scale_niceIdentity = {
			    floor: d3_identity,
			    ceil: d3_identity
			  };
			  d3.ascending = d3_ascending;
			  function d3_ascending(a, b) {
			    return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
			  }
			  function d3_bisector(compare) {
			    return {
			      left: function(a, x, lo, hi) {
			        if (arguments.length < 3) lo = 0;
			        if (arguments.length < 4) hi = a.length;
			        while (lo < hi) {
			          var mid = lo + hi >>> 1;
			          if (compare(a[mid], x) < 0) lo = mid + 1; else hi = mid;
			        }
			        return lo;
			      },
			      right: function(a, x, lo, hi) {
			        if (arguments.length < 3) lo = 0;
			        if (arguments.length < 4) hi = a.length;
			        while (lo < hi) {
			          var mid = lo + hi >>> 1;
			          if (compare(a[mid], x) > 0) hi = mid; else lo = mid + 1;
			        }
			        return lo;
			      }
			    };
			  }
			  var d3_bisect = d3_bisector(d3_ascending);
			  d3.bisectLeft = d3_bisect.left;
			  d3.bisect = d3.bisectRight = d3_bisect.right;
			  d3.bisector = function(f) {
			    return d3_bisector(f.length === 1 ? function(d, x) {
			      return d3_ascending(f(d), x);
			    } : f);
			  };
			  function d3_scale_polylinear(domain, range, uninterpolate, interpolate) {
			    var u = [], i = [], j = 0, k = Math.min(domain.length, range.length) - 1;
			    if (domain[k] < domain[0]) {
			      domain = domain.slice().reverse();
			      range = range.slice().reverse();
			    }
			    while (++j <= k) {
			      u.push(uninterpolate(domain[j - 1], domain[j]));
			      i.push(interpolate(range[j - 1], range[j]));
			    }
			    return function(x) {
			      var j = d3.bisect(domain, x, 1, k) - 1;
			      return i[j](u[j](x));
			    };
			  }
			  d3.scale.linear = function() {
			    return d3_scale_linear([ 0, 1 ], [ 0, 1 ], d3_interpolate, false);
			  };
			  function d3_scale_linear(domain, range, interpolate, clamp) {
			    var output, input;
			    function rescale() {
			      var linear = Math.min(domain.length, range.length) > 2 ? d3_scale_polylinear : d3_scale_bilinear, uninterpolate = clamp ? d3_uninterpolateClamp : d3_uninterpolateNumber;
			      output = linear(domain, range, uninterpolate, interpolate);
			      input = linear(range, domain, uninterpolate, d3_interpolate);
			      return scale;
			    }
			    function scale(x) {
			      return output(x);
			    }
			    scale.invert = function(y) {
			      return input(y);
			    };
			    scale.domain = function(x) {
			      if (!arguments.length) return domain;
			      domain = x.map(Number);
			      return rescale();
			    };
			    scale.range = function(x) {
			      if (!arguments.length) return range;
			      range = x;
			      return rescale();
			    };
			    scale.rangeRound = function(x) {
			      return scale.range(x).interpolate(d3_interpolateRound);
			    };
			    scale.clamp = function(x) {
			      if (!arguments.length) return clamp;
			      clamp = x;
			      return rescale();
			    };
			    scale.interpolate = function(x) {
			      if (!arguments.length) return interpolate;
			      interpolate = x;
			      return rescale();
			    };
			    scale.ticks = function(m) {
			      return d3_scale_linearTicks(domain, m);
			    };
			    scale.tickFormat = function(m, format) {
			      return d3_scale_linearTickFormat(domain, m, format);
			    };
			    scale.nice = function(m) {
			      d3_scale_linearNice(domain, m);
			      return rescale();
			    };
			    scale.copy = function() {
			      return d3_scale_linear(domain, range, interpolate, clamp);
			    };
			    return rescale();
			  }
			  function d3_scale_linearRebind(scale, linear) {
			    return d3.rebind(scale, linear, "range", "rangeRound", "interpolate", "clamp");
			  }
			  function d3_scale_linearNice(domain, m) {
			    return d3_scale_nice(domain, d3_scale_niceStep(d3_scale_linearTickRange(domain, m)[2]));
			  }
			  function d3_scale_linearTickRange(domain, m) {
			    if (m == null) m = 10;
			    var extent = d3_scaleExtent(domain), span = extent[1] - extent[0], step = Math.pow(10, Math.floor(Math.log(span / m) / Math.LN10)), err = m / span * step;
			    if (err <= .15) step *= 10; else if (err <= .35) step *= 5; else if (err <= .75) step *= 2;
			    extent[0] = Math.ceil(extent[0] / step) * step;
			    extent[1] = Math.floor(extent[1] / step) * step + step * .5;
			    extent[2] = step;
			    return extent;
			  }
			  function d3_scale_linearTicks(domain, m) {
			    return d3.range.apply(d3, d3_scale_linearTickRange(domain, m));
			  }
			  function d3_scale_linearTickFormat(domain, m, format) {
			    var range = d3_scale_linearTickRange(domain, m);
			    if (format) {
			      var match = d3_format_re.exec(format);
			      match.shift();
			      if (match[8] === "s") {
			        var prefix = d3.formatPrefix(Math.max(abs(range[0]), abs(range[1])));
			        if (!match[7]) match[7] = "." + d3_scale_linearPrecision(prefix.scale(range[2]));
			        match[8] = "f";
			        format = d3.format(match.join(""));
			        return function(d) {
			          return format(prefix.scale(d)) + prefix.symbol;
			        };
			      }
			      if (!match[7]) match[7] = "." + d3_scale_linearFormatPrecision(match[8], range);
			      format = match.join("");
			    } else {
			      format = ",." + d3_scale_linearPrecision(range[2]) + "f";
			    }
			    return d3.format(format);
			  }
			  var d3_scale_linearFormatSignificant = {
			    s: 1,
			    g: 1,
			    p: 1,
			    r: 1,
			    e: 1
			  };
			  function d3_scale_linearPrecision(value) {
			    return -Math.floor(Math.log(value) / Math.LN10 + .01);
			  }
			  function d3_scale_linearFormatPrecision(type, range) {
			    var p = d3_scale_linearPrecision(range[2]);
			    return type in d3_scale_linearFormatSignificant ? Math.abs(p - d3_scale_linearPrecision(Math.max(abs(range[0]), abs(range[1])))) + +(type !== "e") : p - (type === "%") * 2;
			  }
			  var d3_subclass = {}.__proto__ ? function(object, prototype) {
			    object.__proto__ = prototype;
			  } : function(object, prototype) {
			    for (var property in prototype) object[property] = prototype[property];
			  };
			  function d3_vendorSymbol(object, name) {
			    if (name in object) return name;
			    name = name.charAt(0).toUpperCase() + name.slice(1);
			    for (var i = 0, n = d3_vendorPrefixes.length; i < n; ++i) {
			      var prefixName = d3_vendorPrefixes[i] + name;
			      if (prefixName in object) return prefixName;
			    }
			  }
			  var d3_vendorPrefixes = [ "webkit", "ms", "moz", "Moz", "o", "O" ];
			  function d3_selection(groups) {
			    d3_subclass(groups, d3_selectionPrototype);
			    return groups;
			  }
			  var d3_select = function(s, n) {
			    return n.querySelector(s);
			  }, d3_selectAll = function(s, n) {
			    return n.querySelectorAll(s);
			  }, d3_selectMatches = function(n, s) {
			    var d3_selectMatcher = n.matches || n[d3_vendorSymbol(n, "matchesSelector")];
			    d3_selectMatches = function(n, s) {
			      return d3_selectMatcher.call(n, s);
			    };
			    return d3_selectMatches(n, s);
			  };
			  if (typeof Sizzle === "function") {
			    d3_select = function(s, n) {
			      return Sizzle(s, n)[0] || null;
			    };
			    d3_selectAll = Sizzle;
			    d3_selectMatches = Sizzle.matchesSelector;
			  }
			  d3.selection = function() {
			    return d3.select(d3_document.documentElement);
			  };
			  var d3_selectionPrototype = d3.selection.prototype = [];
			  d3_selectionPrototype.select = function(selector) {
			    var subgroups = [], subgroup, subnode, group, node;
			    selector = d3_selection_selector(selector);
			    for (var j = -1, m = this.length; ++j < m; ) {
			      subgroups.push(subgroup = []);
			      subgroup.parentNode = (group = this[j]).parentNode;
			      for (var i = -1, n = group.length; ++i < n; ) {
			        if (node = group[i]) {
			          subgroup.push(subnode = selector.call(node, node.__data__, i, j));
			          if (subnode && "__data__" in node) subnode.__data__ = node.__data__;
			        } else {
			          subgroup.push(null);
			        }
			      }
			    }
			    return d3_selection(subgroups);
			  };
			  function d3_selection_selector(selector) {
			    return typeof selector === "function" ? selector : function() {
			      return d3_select(selector, this);
			    };
			  }
			  d3_selectionPrototype.selectAll = function(selector) {
			    var subgroups = [], subgroup, node;
			    selector = d3_selection_selectorAll(selector);
			    for (var j = -1, m = this.length; ++j < m; ) {
			      for (var group = this[j], i = -1, n = group.length; ++i < n; ) {
			        if (node = group[i]) {
			          subgroups.push(subgroup = d3_array(selector.call(node, node.__data__, i, j)));
			          subgroup.parentNode = node;
			        }
			      }
			    }
			    return d3_selection(subgroups);
			  };
			  function d3_selection_selectorAll(selector) {
			    return typeof selector === "function" ? selector : function() {
			      return d3_selectAll(selector, this);
			    };
			  }
			  var d3_nsPrefix = {
			    svg: "http://www.w3.org/2000/svg",
			    xhtml: "http://www.w3.org/1999/xhtml",
			    xlink: "http://www.w3.org/1999/xlink",
			    xml: "http://www.w3.org/XML/1998/namespace",
			    xmlns: "http://www.w3.org/2000/xmlns/"
			  };
			  d3.ns = {
			    prefix: d3_nsPrefix,
			    qualify: function(name) {
			      var i = name.indexOf(":"), prefix = name;
			      if (i >= 0) {
			        prefix = name.slice(0, i);
			        name = name.slice(i + 1);
			      }
			      return d3_nsPrefix.hasOwnProperty(prefix) ? {
			        space: d3_nsPrefix[prefix],
			        local: name
			      } : name;
			    }
			  };
			  d3_selectionPrototype.attr = function(name, value) {
			    if (arguments.length < 2) {
			      if (typeof name === "string") {
			        var node = this.node();
			        name = d3.ns.qualify(name);
			        return name.local ? node.getAttributeNS(name.space, name.local) : node.getAttribute(name);
			      }
			      for (value in name) this.each(d3_selection_attr(value, name[value]));
			      return this;
			    }
			    return this.each(d3_selection_attr(name, value));
			  };
			  function d3_selection_attr(name, value) {
			    name = d3.ns.qualify(name);
			    function attrNull() {
			      this.removeAttribute(name);
			    }
			    function attrNullNS() {
			      this.removeAttributeNS(name.space, name.local);
			    }
			    function attrConstant() {
			      this.setAttribute(name, value);
			    }
			    function attrConstantNS() {
			      this.setAttributeNS(name.space, name.local, value);
			    }
			    function attrFunction() {
			      var x = value.apply(this, arguments);
			      if (x == null) this.removeAttribute(name); else this.setAttribute(name, x);
			    }
			    function attrFunctionNS() {
			      var x = value.apply(this, arguments);
			      if (x == null) this.removeAttributeNS(name.space, name.local); else this.setAttributeNS(name.space, name.local, x);
			    }
			    return value == null ? name.local ? attrNullNS : attrNull : typeof value === "function" ? name.local ? attrFunctionNS : attrFunction : name.local ? attrConstantNS : attrConstant;
			  }
			  function d3_collapse(s) {
			    return s.trim().replace(/\s+/g, " ");
			  }
			  d3_selectionPrototype.classed = function(name, value) {
			    if (arguments.length < 2) {
			      if (typeof name === "string") {
			        var node = this.node(), n = (name = d3_selection_classes(name)).length, i = -1;
			        if (value = node.classList) {
			          while (++i < n) if (!value.contains(name[i])) return false;
			        } else {
			          value = node.getAttribute("class");
			          while (++i < n) if (!d3_selection_classedRe(name[i]).test(value)) return false;
			        }
			        return true;
			      }
			      for (value in name) this.each(d3_selection_classed(value, name[value]));
			      return this;
			    }
			    return this.each(d3_selection_classed(name, value));
			  };
			  function d3_selection_classedRe(name) {
			    return new RegExp("(?:^|\\s+)" + d3.requote(name) + "(?:\\s+|$)", "g");
			  }
			  function d3_selection_classes(name) {
			    return (name + "").trim().split(/^|\s+/);
			  }
			  function d3_selection_classed(name, value) {
			    name = d3_selection_classes(name).map(d3_selection_classedName);
			    var n = name.length;
			    function classedConstant() {
			      var i = -1;
			      while (++i < n) name[i](this, value);
			    }
			    function classedFunction() {
			      var i = -1, x = value.apply(this, arguments);
			      while (++i < n) name[i](this, x);
			    }
			    return typeof value === "function" ? classedFunction : classedConstant;
			  }
			  function d3_selection_classedName(name) {
			    var re = d3_selection_classedRe(name);
			    return function(node, value) {
			      if (c = node.classList) return value ? c.add(name) : c.remove(name);
			      var c = node.getAttribute("class") || "";
			      if (value) {
			        re.lastIndex = 0;
			        if (!re.test(c)) node.setAttribute("class", d3_collapse(c + " " + name));
			      } else {
			        node.setAttribute("class", d3_collapse(c.replace(re, " ")));
			      }
			    };
			  }
			  d3_selectionPrototype.style = function(name, value, priority) {
			    var n = arguments.length;
			    if (n < 3) {
			      if (typeof name !== "string") {
			        if (n < 2) value = "";
			        for (priority in name) this.each(d3_selection_style(priority, name[priority], value));
			        return this;
			      }
			      if (n < 2) {
			        var node = this.node();
			        return d3_window(node).getComputedStyle(node, null).getPropertyValue(name);
			      }
			      priority = "";
			    }
			    return this.each(d3_selection_style(name, value, priority));
			  };
			  function d3_selection_style(name, value, priority) {
			    function styleNull() {
			      this.style.removeProperty(name);
			    }
			    function styleConstant() {
			      this.style.setProperty(name, value, priority);
			    }
			    function styleFunction() {
			      var x = value.apply(this, arguments);
			      if (x == null) this.style.removeProperty(name); else this.style.setProperty(name, x, priority);
			    }
			    return value == null ? styleNull : typeof value === "function" ? styleFunction : styleConstant;
			  }
			  d3_selectionPrototype.property = function(name, value) {
			    if (arguments.length < 2) {
			      if (typeof name === "string") return this.node()[name];
			      for (value in name) this.each(d3_selection_property(value, name[value]));
			      return this;
			    }
			    return this.each(d3_selection_property(name, value));
			  };
			  function d3_selection_property(name, value) {
			    function propertyNull() {
			      delete this[name];
			    }
			    function propertyConstant() {
			      this[name] = value;
			    }
			    function propertyFunction() {
			      var x = value.apply(this, arguments);
			      if (x == null) delete this[name]; else this[name] = x;
			    }
			    return value == null ? propertyNull : typeof value === "function" ? propertyFunction : propertyConstant;
			  }
			  d3_selectionPrototype.text = function(value) {
			    return arguments.length ? this.each(typeof value === "function" ? function() {
			      var v = value.apply(this, arguments);
			      this.textContent = v == null ? "" : v;
			    } : value == null ? function() {
			      this.textContent = "";
			    } : function() {
			      this.textContent = value;
			    }) : this.node().textContent;
			  };
			  d3_selectionPrototype.html = function(value) {
			    return arguments.length ? this.each(typeof value === "function" ? function() {
			      var v = value.apply(this, arguments);
			      this.innerHTML = v == null ? "" : v;
			    } : value == null ? function() {
			      this.innerHTML = "";
			    } : function() {
			      this.innerHTML = value;
			    }) : this.node().innerHTML;
			  };
			  d3_selectionPrototype.append = function(name) {
			    name = d3_selection_creator(name);
			    return this.select(function() {
			      return this.appendChild(name.apply(this, arguments));
			    });
			  };
			  function d3_selection_creator(name) {
			    function create() {
			      var document = this.ownerDocument, namespace = this.namespaceURI;
			      return namespace ? document.createElementNS(namespace, name) : document.createElement(name);
			    }
			    function createNS() {
			      return this.ownerDocument.createElementNS(name.space, name.local);
			    }
			    return typeof name === "function" ? name : (name = d3.ns.qualify(name)).local ? createNS : create;
			  }
			  d3_selectionPrototype.insert = function(name, before) {
			    name = d3_selection_creator(name);
			    before = d3_selection_selector(before);
			    return this.select(function() {
			      return this.insertBefore(name.apply(this, arguments), before.apply(this, arguments) || null);
			    });
			  };
			  d3_selectionPrototype.remove = function() {
			    return this.each(d3_selectionRemove);
			  };
			  function d3_selectionRemove() {
			    var parent = this.parentNode;
			    if (parent) parent.removeChild(this);
			  }
			  d3.set = function(array) {
			    var set = new d3_Set();
			    if (array) for (var i = 0, n = array.length; i < n; ++i) set.add(array[i]);
			    return set;
			  };
			  function d3_Set() {
			    this._ = Object.create(null);
			  }
			  d3_class(d3_Set, {
			    has: d3_map_has,
			    add: function(key) {
			      this._[d3_map_escape(key += "")] = true;
			      return key;
			    },
			    remove: d3_map_remove,
			    values: d3_map_keys,
			    size: d3_map_size,
			    empty: d3_map_empty,
			    forEach: function(f) {
			      for (var key in this._) f.call(this, d3_map_unescape(key));
			    }
			  });
			  d3_selectionPrototype.data = function(value, key) {
			    var i = -1, n = this.length, group, node;
			    if (!arguments.length) {
			      value = new Array(n = (group = this[0]).length);
			      while (++i < n) {
			        if (node = group[i]) {
			          value[i] = node.__data__;
			        }
			      }
			      return value;
			    }
			    function bind(group, groupData) {
			      var i, n = group.length, m = groupData.length, n0 = Math.min(n, m), updateNodes = new Array(m), enterNodes = new Array(m), exitNodes = new Array(n), node, nodeData;
			      if (key) {
			        var nodeByKeyValue = new d3_Map(), keyValues = new Array(n), keyValue;
			        for (i = -1; ++i < n; ) {
			          if (nodeByKeyValue.has(keyValue = key.call(node = group[i], node.__data__, i))) {
			            exitNodes[i] = node;
			          } else {
			            nodeByKeyValue.set(keyValue, node);
			          }
			          keyValues[i] = keyValue;
			        }
			        for (i = -1; ++i < m; ) {
			          if (!(node = nodeByKeyValue.get(keyValue = key.call(groupData, nodeData = groupData[i], i)))) {
			            enterNodes[i] = d3_selection_dataNode(nodeData);
			          } else if (node !== true) {
			            updateNodes[i] = node;
			            node.__data__ = nodeData;
			          }
			          nodeByKeyValue.set(keyValue, true);
			        }
			        for (i = -1; ++i < n; ) {
			          if (nodeByKeyValue.get(keyValues[i]) !== true) {
			            exitNodes[i] = group[i];
			          }
			        }
			      } else {
			        for (i = -1; ++i < n0; ) {
			          node = group[i];
			          nodeData = groupData[i];
			          if (node) {
			            node.__data__ = nodeData;
			            updateNodes[i] = node;
			          } else {
			            enterNodes[i] = d3_selection_dataNode(nodeData);
			          }
			        }
			        for (;i < m; ++i) {
			          enterNodes[i] = d3_selection_dataNode(groupData[i]);
			        }
			        for (;i < n; ++i) {
			          exitNodes[i] = group[i];
			        }
			      }
			      enterNodes.update = updateNodes;
			      enterNodes.parentNode = updateNodes.parentNode = exitNodes.parentNode = group.parentNode;
			      enter.push(enterNodes);
			      update.push(updateNodes);
			      exit.push(exitNodes);
			    }
			    var enter = d3_selection_enter([]), update = d3_selection([]), exit = d3_selection([]);
			    if (typeof value === "function") {
			      while (++i < n) {
			        bind(group = this[i], value.call(group, group.parentNode.__data__, i));
			      }
			    } else {
			      while (++i < n) {
			        bind(group = this[i], value);
			      }
			    }
			    update.enter = function() {
			      return enter;
			    };
			    update.exit = function() {
			      return exit;
			    };
			    return update;
			  };
			  function d3_selection_dataNode(data) {
			    return {
			      __data__: data
			    };
			  }
			  d3_selectionPrototype.datum = function(value) {
			    return arguments.length ? this.property("__data__", value) : this.property("__data__");
			  };
			  d3_selectionPrototype.filter = function(filter) {
			    var subgroups = [], subgroup, group, node;
			    if (typeof filter !== "function") filter = d3_selection_filter(filter);
			    for (var j = 0, m = this.length; j < m; j++) {
			      subgroups.push(subgroup = []);
			      subgroup.parentNode = (group = this[j]).parentNode;
			      for (var i = 0, n = group.length; i < n; i++) {
			        if ((node = group[i]) && filter.call(node, node.__data__, i, j)) {
			          subgroup.push(node);
			        }
			      }
			    }
			    return d3_selection(subgroups);
			  };
			  function d3_selection_filter(selector) {
			    return function() {
			      return d3_selectMatches(this, selector);
			    };
			  }
			  d3_selectionPrototype.order = function() {
			    for (var j = -1, m = this.length; ++j < m; ) {
			      for (var group = this[j], i = group.length - 1, next = group[i], node; --i >= 0; ) {
			        if (node = group[i]) {
			          if (next && next !== node.nextSibling) next.parentNode.insertBefore(node, next);
			          next = node;
			        }
			      }
			    }
			    return this;
			  };
			  d3_selectionPrototype.sort = function(comparator) {
			    comparator = d3_selection_sortComparator.apply(this, arguments);
			    for (var j = -1, m = this.length; ++j < m; ) this[j].sort(comparator);
			    return this.order();
			  };
			  function d3_selection_sortComparator(comparator) {
			    if (!arguments.length) comparator = d3_ascending;
			    return function(a, b) {
			      return a && b ? comparator(a.__data__, b.__data__) : !a - !b;
			    };
			  }
			  function d3_noop() {}
			  d3.dispatch = function() {
			    var dispatch = new d3_dispatch(), i = -1, n = arguments.length;
			    while (++i < n) dispatch[arguments[i]] = d3_dispatch_event(dispatch);
			    return dispatch;
			  };
			  function d3_dispatch() {}
			  d3_dispatch.prototype.on = function(type, listener) {
			    var i = type.indexOf("."), name = "";
			    if (i >= 0) {
			      name = type.slice(i + 1);
			      type = type.slice(0, i);
			    }
			    if (type) return arguments.length < 2 ? this[type].on(name) : this[type].on(name, listener);
			    if (arguments.length === 2) {
			      if (listener == null) for (type in this) {
			        if (this.hasOwnProperty(type)) this[type].on(name, null);
			      }
			      return this;
			    }
			  };
			  function d3_dispatch_event(dispatch) {
			    var listeners = [], listenerByName = new d3_Map();
			    function event() {
			      var z = listeners, i = -1, n = z.length, l;
			      while (++i < n) if (l = z[i].on) l.apply(this, arguments);
			      return dispatch;
			    }
			    event.on = function(name, listener) {
			      var l = listenerByName.get(name), i;
			      if (arguments.length < 2) return l && l.on;
			      if (l) {
			        l.on = null;
			        listeners = listeners.slice(0, i = listeners.indexOf(l)).concat(listeners.slice(i + 1));
			        listenerByName.remove(name);
			      }
			      if (listener) listeners.push(listenerByName.set(name, {
			        on: listener
			      }));
			      return dispatch;
			    };
			    return event;
			  }
			  d3.event = null;
			  function d3_eventPreventDefault() {
			    d3.event.preventDefault();
			  }
			  function d3_eventSource() {
			    var e = d3.event, s;
			    while (s = e.sourceEvent) e = s;
			    return e;
			  }
			  function d3_eventDispatch(target) {
			    var dispatch = new d3_dispatch(), i = 0, n = arguments.length;
			    while (++i < n) dispatch[arguments[i]] = d3_dispatch_event(dispatch);
			    dispatch.of = function(thiz, argumentz) {
			      return function(e1) {
			        try {
			          var e0 = e1.sourceEvent = d3.event;
			          e1.target = target;
			          d3.event = e1;
			          dispatch[e1.type].apply(thiz, argumentz);
			        } finally {
			          d3.event = e0;
			        }
			      };
			    };
			    return dispatch;
			  }
			  d3_selectionPrototype.on = function(type, listener, capture) {
			    var n = arguments.length;
			    if (n < 3) {
			      if (typeof type !== "string") {
			        if (n < 2) listener = false;
			        for (capture in type) this.each(d3_selection_on(capture, type[capture], listener));
			        return this;
			      }
			      if (n < 2) return (n = this.node()["__on" + type]) && n._;
			      capture = false;
			    }
			    return this.each(d3_selection_on(type, listener, capture));
			  };
			  function d3_selection_on(type, listener, capture) {
			    var name = "__on" + type, i = type.indexOf("."), wrap = d3_selection_onListener;
			    if (i > 0) type = type.slice(0, i);
			    var filter = d3_selection_onFilters.get(type);
			    if (filter) type = filter, wrap = d3_selection_onFilter;
			    function onRemove() {
			      var l = this[name];
			      if (l) {
			        this.removeEventListener(type, l, l.$);
			        delete this[name];
			      }
			    }
			    function onAdd() {
			      var l = wrap(listener, d3_array(arguments));
			      onRemove.call(this);
			      this.addEventListener(type, this[name] = l, l.$ = capture);
			      l._ = listener;
			    }
			    function removeAll() {
			      var re = new RegExp("^__on([^.]+)" + d3.requote(type) + "$"), match;
			      for (var name in this) {
			        if (match = name.match(re)) {
			          var l = this[name];
			          this.removeEventListener(match[1], l, l.$);
			          delete this[name];
			        }
			      }
			    }
			    return i ? listener ? onAdd : onRemove : listener ? d3_noop : removeAll;
			  }
			  var d3_selection_onFilters = d3.map({
			    mouseenter: "mouseover",
			    mouseleave: "mouseout"
			  });
			  if (d3_document) {
			    d3_selection_onFilters.forEach(function(k) {
			      if ("on" + k in d3_document) d3_selection_onFilters.remove(k);
			    });
			  }
			  function d3_selection_onListener(listener, argumentz) {
			    return function(e) {
			      var o = d3.event;
			      d3.event = e;
			      argumentz[0] = this.__data__;
			      try {
			        listener.apply(this, argumentz);
			      } finally {
			        d3.event = o;
			      }
			    };
			  }
			  function d3_selection_onFilter(listener, argumentz) {
			    var l = d3_selection_onListener(listener, argumentz);
			    return function(e) {
			      var target = this, related = e.relatedTarget;
			      if (!related || related !== target && !(related.compareDocumentPosition(target) & 8)) {
			        l.call(target, e);
			      }
			    };
			  }
			  d3_selectionPrototype.each = function(callback) {
			    return d3_selection_each(this, function(node, i, j) {
			      callback.call(node, node.__data__, i, j);
			    });
			  };
			  function d3_selection_each(groups, callback) {
			    for (var j = 0, m = groups.length; j < m; j++) {
			      for (var group = groups[j], i = 0, n = group.length, node; i < n; i++) {
			        if (node = group[i]) callback(node, i, j);
			      }
			    }
			    return groups;
			  }
			  d3_selectionPrototype.call = function(callback) {
			    var args = d3_array(arguments);
			    callback.apply(args[0] = this, args);
			    return this;
			  };
			  d3_selectionPrototype.empty = function() {
			    return !this.node();
			  };
			  d3_selectionPrototype.node = function() {
			    for (var j = 0, m = this.length; j < m; j++) {
			      for (var group = this[j], i = 0, n = group.length; i < n; i++) {
			        var node = group[i];
			        if (node) return node;
			      }
			    }
			    return null;
			  };
			  d3_selectionPrototype.size = function() {
			    var n = 0;
			    d3_selection_each(this, function() {
			      ++n;
			    });
			    return n;
			  };
			  function d3_selection_enter(selection) {
			    d3_subclass(selection, d3_selection_enterPrototype);
			    return selection;
			  }
			  var d3_selection_enterPrototype = [];
			  d3.selection.enter = d3_selection_enter;
			  d3.selection.enter.prototype = d3_selection_enterPrototype;
			  d3_selection_enterPrototype.append = d3_selectionPrototype.append;
			  d3_selection_enterPrototype.empty = d3_selectionPrototype.empty;
			  d3_selection_enterPrototype.node = d3_selectionPrototype.node;
			  d3_selection_enterPrototype.call = d3_selectionPrototype.call;
			  d3_selection_enterPrototype.size = d3_selectionPrototype.size;
			  d3_selection_enterPrototype.select = function(selector) {
			    var subgroups = [], subgroup, subnode, upgroup, group, node;
			    for (var j = -1, m = this.length; ++j < m; ) {
			      upgroup = (group = this[j]).update;
			      subgroups.push(subgroup = []);
			      subgroup.parentNode = group.parentNode;
			      for (var i = -1, n = group.length; ++i < n; ) {
			        if (node = group[i]) {
			          subgroup.push(upgroup[i] = subnode = selector.call(group.parentNode, node.__data__, i, j));
			          subnode.__data__ = node.__data__;
			        } else {
			          subgroup.push(null);
			        }
			      }
			    }
			    return d3_selection(subgroups);
			  };
			  d3_selection_enterPrototype.insert = function(name, before) {
			    if (arguments.length < 2) before = d3_selection_enterInsertBefore(this);
			    return d3_selectionPrototype.insert.call(this, name, before);
			  };
			  function d3_selection_enterInsertBefore(enter) {
			    var i0, j0;
			    return function(d, i, j) {
			      var group = enter[j].update, n = group.length, node;
			      if (j != j0) j0 = j, i0 = 0;
			      if (i >= i0) i0 = i + 1;
			      while (!(node = group[i0]) && ++i0 < n) ;
			      return node;
			    };
			  }
			  d3.select = function(node) {
			    var group;
			    if (typeof node === "string") {
			      group = [ d3_select(node, d3_document) ];
			      group.parentNode = d3_document.documentElement;
			    } else {
			      group = [ node ];
			      group.parentNode = d3_documentElement(node);
			    }
			    return d3_selection([ group ]);
			  };
			  d3.selectAll = function(nodes) {
			    var group;
			    if (typeof nodes === "string") {
			      group = d3_array(d3_selectAll(nodes, d3_document));
			      group.parentNode = d3_document.documentElement;
			    } else {
			      group = nodes;
			      group.parentNode = null;
			    }
			    return d3_selection([ group ]);
			  };
			  function d3_true() {
			    return true;
			  }
			  var d3_timer_queueHead, d3_timer_queueTail, d3_timer_interval, d3_timer_timeout, d3_timer_active, d3_timer_frame = this[d3_vendorSymbol(this, "requestAnimationFrame")] || function(callback) {
			    setTimeout(callback, 17);
			  };
			  d3.timer = function(callback, delay, then) {
			    var n = arguments.length;
			    if (n < 2) delay = 0;
			    if (n < 3) then = Date.now();
			    var time = then + delay, timer = {
			      c: callback,
			      t: time,
			      f: false,
			      n: null
			    };
			    if (d3_timer_queueTail) d3_timer_queueTail.n = timer; else d3_timer_queueHead = timer;
			    d3_timer_queueTail = timer;
			    if (!d3_timer_interval) {
			      d3_timer_timeout = clearTimeout(d3_timer_timeout);
			      d3_timer_interval = 1;
			      d3_timer_frame(d3_timer_step);
			    }
			  };
			  function d3_timer_step() {
			    var now = d3_timer_mark(), delay = d3_timer_sweep() - now;
			    if (delay > 24) {
			      if (isFinite(delay)) {
			        clearTimeout(d3_timer_timeout);
			        d3_timer_timeout = setTimeout(d3_timer_step, delay);
			      }
			      d3_timer_interval = 0;
			    } else {
			      d3_timer_interval = 1;
			      d3_timer_frame(d3_timer_step);
			    }
			  }
			  d3.timer.flush = function() {
			    d3_timer_mark();
			    d3_timer_sweep();
			  };
			  function d3_timer_mark() {
			    var now = Date.now();
			    d3_timer_active = d3_timer_queueHead;
			    while (d3_timer_active) {
			      if (now >= d3_timer_active.t) d3_timer_active.f = d3_timer_active.c(now - d3_timer_active.t);
			      d3_timer_active = d3_timer_active.n;
			    }
			    return now;
			  }
			  function d3_timer_sweep() {
			    var t0, t1 = d3_timer_queueHead, time = Infinity;
			    while (t1) {
			      if (t1.f) {
			        t1 = t0 ? t0.n = t1.n : d3_timer_queueHead = t1.n;
			      } else {
			        if (t1.t < time) time = t1.t;
			        t1 = (t0 = t1).n;
			      }
			    }
			    d3_timer_queueTail = t0;
			    return time;
			  }
			  d3_selectionPrototype.transition = function(name) {
			    var id = d3_transitionInheritId || ++d3_transitionId, ns = d3_transitionNamespace(name), subgroups = [], subgroup, node, transition = d3_transitionInherit || {
			      time: Date.now(),
			      ease: d3_ease_cubicInOut,
			      delay: 0,
			      duration: 250
			    };
			    for (var j = -1, m = this.length; ++j < m; ) {
			      subgroups.push(subgroup = []);
			      for (var group = this[j], i = -1, n = group.length; ++i < n; ) {
			        if (node = group[i]) d3_transitionNode(node, i, ns, id, transition);
			        subgroup.push(node);
			      }
			    }
			    return d3_transition(subgroups, ns, id);
			  };
			  d3_selectionPrototype.interrupt = function(name) {
			    return this.each(name == null ? d3_selection_interrupt : d3_selection_interruptNS(d3_transitionNamespace(name)));
			  };
			  var d3_selection_interrupt = d3_selection_interruptNS(d3_transitionNamespace());
			  function d3_selection_interruptNS(ns) {
			    return function() {
			      var lock, active;
			      if ((lock = this[ns]) && (active = lock[lock.active])) {
			        if (--lock.count) delete lock[lock.active]; else delete this[ns];
			        lock.active += .5;
			        active.event && active.event.interrupt.call(this, this.__data__, active.index);
			      }
			    };
			  }
			  function d3_transition(groups, ns, id) {
			    d3_subclass(groups, d3_transitionPrototype);
			    groups.namespace = ns;
			    groups.id = id;
			    return groups;
			  }
			  var d3_transitionPrototype = [], d3_transitionId = 0, d3_transitionInheritId, d3_transitionInherit;
			  d3_transitionPrototype.call = d3_selectionPrototype.call;
			  d3_transitionPrototype.empty = d3_selectionPrototype.empty;
			  d3_transitionPrototype.node = d3_selectionPrototype.node;
			  d3_transitionPrototype.size = d3_selectionPrototype.size;
			  d3.transition = function(selection, name) {
			    return selection && selection.transition ? d3_transitionInheritId ? selection.transition(name) : selection : d3.selection().transition(selection);
			  };
			  d3.transition.prototype = d3_transitionPrototype;
			  d3_transitionPrototype.select = function(selector) {
			    var id = this.id, ns = this.namespace, subgroups = [], subgroup, subnode, node;
			    selector = d3_selection_selector(selector);
			    for (var j = -1, m = this.length; ++j < m; ) {
			      subgroups.push(subgroup = []);
			      for (var group = this[j], i = -1, n = group.length; ++i < n; ) {
			        if ((node = group[i]) && (subnode = selector.call(node, node.__data__, i, j))) {
			          if ("__data__" in node) subnode.__data__ = node.__data__;
			          d3_transitionNode(subnode, i, ns, id, node[ns][id]);
			          subgroup.push(subnode);
			        } else {
			          subgroup.push(null);
			        }
			      }
			    }
			    return d3_transition(subgroups, ns, id);
			  };
			  d3_transitionPrototype.selectAll = function(selector) {
			    var id = this.id, ns = this.namespace, subgroups = [], subgroup, subnodes, node, subnode, transition;
			    selector = d3_selection_selectorAll(selector);
			    for (var j = -1, m = this.length; ++j < m; ) {
			      for (var group = this[j], i = -1, n = group.length; ++i < n; ) {
			        if (node = group[i]) {
			          transition = node[ns][id];
			          subnodes = selector.call(node, node.__data__, i, j);
			          subgroups.push(subgroup = []);
			          for (var k = -1, o = subnodes.length; ++k < o; ) {
			            if (subnode = subnodes[k]) d3_transitionNode(subnode, k, ns, id, transition);
			            subgroup.push(subnode);
			          }
			        }
			      }
			    }
			    return d3_transition(subgroups, ns, id);
			  };
			  d3_transitionPrototype.filter = function(filter) {
			    var subgroups = [], subgroup, group, node;
			    if (typeof filter !== "function") filter = d3_selection_filter(filter);
			    for (var j = 0, m = this.length; j < m; j++) {
			      subgroups.push(subgroup = []);
			      for (var group = this[j], i = 0, n = group.length; i < n; i++) {
			        if ((node = group[i]) && filter.call(node, node.__data__, i, j)) {
			          subgroup.push(node);
			        }
			      }
			    }
			    return d3_transition(subgroups, this.namespace, this.id);
			  };
			  d3.transform = function(string) {
			    var g = d3_document.createElementNS(d3.ns.prefix.svg, "g");
			    return (d3.transform = function(string) {
			      if (string != null) {
			        g.setAttribute("transform", string);
			        var t = g.transform.baseVal.consolidate();
			      }
			      return new d3_transform(t ? t.matrix : d3_transformIdentity);
			    })(string);
			  };
			  function d3_transform(m) {
			    var r0 = [ m.a, m.b ], r1 = [ m.c, m.d ], kx = d3_transformNormalize(r0), kz = d3_transformDot(r0, r1), ky = d3_transformNormalize(d3_transformCombine(r1, r0, -kz)) || 0;
			    if (r0[0] * r1[1] < r1[0] * r0[1]) {
			      r0[0] *= -1;
			      r0[1] *= -1;
			      kx *= -1;
			      kz *= -1;
			    }
			    this.rotate = (kx ? Math.atan2(r0[1], r0[0]) : Math.atan2(-r1[0], r1[1])) * d3_degrees;
			    this.translate = [ m.e, m.f ];
			    this.scale = [ kx, ky ];
			    this.skew = ky ? Math.atan2(kz, ky) * d3_degrees : 0;
			  }
			  d3_transform.prototype.toString = function() {
			    return "translate(" + this.translate + ")rotate(" + this.rotate + ")skewX(" + this.skew + ")scale(" + this.scale + ")";
			  };
			  function d3_transformDot(a, b) {
			    return a[0] * b[0] + a[1] * b[1];
			  }
			  function d3_transformNormalize(a) {
			    var k = Math.sqrt(d3_transformDot(a, a));
			    if (k) {
			      a[0] /= k;
			      a[1] /= k;
			    }
			    return k;
			  }
			  function d3_transformCombine(a, b, k) {
			    a[0] += k * b[0];
			    a[1] += k * b[1];
			    return a;
			  }
			  var d3_transformIdentity = {
			    a: 1,
			    b: 0,
			    c: 0,
			    d: 1,
			    e: 0,
			    f: 0
			  };
			  d3.interpolateTransform = d3_interpolateTransform;
			  function d3_interpolateTransform(a, b) {
			    var s = [], q = [], n, A = d3.transform(a), B = d3.transform(b), ta = A.translate, tb = B.translate, ra = A.rotate, rb = B.rotate, wa = A.skew, wb = B.skew, ka = A.scale, kb = B.scale;
			    if (ta[0] != tb[0] || ta[1] != tb[1]) {
			      s.push("translate(", null, ",", null, ")");
			      q.push({
			        i: 1,
			        x: d3_interpolateNumber(ta[0], tb[0])
			      }, {
			        i: 3,
			        x: d3_interpolateNumber(ta[1], tb[1])
			      });
			    } else if (tb[0] || tb[1]) {
			      s.push("translate(" + tb + ")");
			    } else {
			      s.push("");
			    }
			    if (ra != rb) {
			      if (ra - rb > 180) rb += 360; else if (rb - ra > 180) ra += 360;
			      q.push({
			        i: s.push(s.pop() + "rotate(", null, ")") - 2,
			        x: d3_interpolateNumber(ra, rb)
			      });
			    } else if (rb) {
			      s.push(s.pop() + "rotate(" + rb + ")");
			    }
			    if (wa != wb) {
			      q.push({
			        i: s.push(s.pop() + "skewX(", null, ")") - 2,
			        x: d3_interpolateNumber(wa, wb)
			      });
			    } else if (wb) {
			      s.push(s.pop() + "skewX(" + wb + ")");
			    }
			    if (ka[0] != kb[0] || ka[1] != kb[1]) {
			      n = s.push(s.pop() + "scale(", null, ",", null, ")");
			      q.push({
			        i: n - 4,
			        x: d3_interpolateNumber(ka[0], kb[0])
			      }, {
			        i: n - 2,
			        x: d3_interpolateNumber(ka[1], kb[1])
			      });
			    } else if (kb[0] != 1 || kb[1] != 1) {
			      s.push(s.pop() + "scale(" + kb + ")");
			    }
			    n = q.length;
			    return function(t) {
			      var i = -1, o;
			      while (++i < n) s[(o = q[i]).i] = o.x(t);
			      return s.join("");
			    };
			  }
			  d3_transitionPrototype.tween = function(name, tween) {
			    var id = this.id, ns = this.namespace;
			    if (arguments.length < 2) return this.node()[ns][id].tween.get(name);
			    return d3_selection_each(this, tween == null ? function(node) {
			      node[ns][id].tween.remove(name);
			    } : function(node) {
			      node[ns][id].tween.set(name, tween);
			    });
			  };
			  function d3_transition_tween(groups, name, value, tween) {
			    var id = groups.id, ns = groups.namespace;
			    return d3_selection_each(groups, typeof value === "function" ? function(node, i, j) {
			      node[ns][id].tween.set(name, tween(value.call(node, node.__data__, i, j)));
			    } : (value = tween(value), function(node) {
			      node[ns][id].tween.set(name, value);
			    }));
			  }
			  d3_transitionPrototype.attr = function(nameNS, value) {
			    if (arguments.length < 2) {
			      for (value in nameNS) this.attr(value, nameNS[value]);
			      return this;
			    }
			    var interpolate = nameNS == "transform" ? d3_interpolateTransform : d3_interpolate, name = d3.ns.qualify(nameNS);
			    function attrNull() {
			      this.removeAttribute(name);
			    }
			    function attrNullNS() {
			      this.removeAttributeNS(name.space, name.local);
			    }
			    function attrTween(b) {
			      return b == null ? attrNull : (b += "", function() {
			        var a = this.getAttribute(name), i;
			        return a !== b && (i = interpolate(a, b), function(t) {
			          this.setAttribute(name, i(t));
			        });
			      });
			    }
			    function attrTweenNS(b) {
			      return b == null ? attrNullNS : (b += "", function() {
			        var a = this.getAttributeNS(name.space, name.local), i;
			        return a !== b && (i = interpolate(a, b), function(t) {
			          this.setAttributeNS(name.space, name.local, i(t));
			        });
			      });
			    }
			    return d3_transition_tween(this, "attr." + nameNS, value, name.local ? attrTweenNS : attrTween);
			  };
			  d3_transitionPrototype.attrTween = function(nameNS, tween) {
			    var name = d3.ns.qualify(nameNS);
			    function attrTween(d, i) {
			      var f = tween.call(this, d, i, this.getAttribute(name));
			      return f && function(t) {
			        this.setAttribute(name, f(t));
			      };
			    }
			    function attrTweenNS(d, i) {
			      var f = tween.call(this, d, i, this.getAttributeNS(name.space, name.local));
			      return f && function(t) {
			        this.setAttributeNS(name.space, name.local, f(t));
			      };
			    }
			    return this.tween("attr." + nameNS, name.local ? attrTweenNS : attrTween);
			  };
			  d3_transitionPrototype.style = function(name, value, priority) {
			    var n = arguments.length;
			    if (n < 3) {
			      if (typeof name !== "string") {
			        if (n < 2) value = "";
			        for (priority in name) this.style(priority, name[priority], value);
			        return this;
			      }
			      priority = "";
			    }
			    function styleNull() {
			      this.style.removeProperty(name);
			    }
			    function styleString(b) {
			      return b == null ? styleNull : (b += "", function() {
			        var a = d3_window(this).getComputedStyle(this, null).getPropertyValue(name), i;
			        return a !== b && (i = d3_interpolate(a, b), function(t) {
			          this.style.setProperty(name, i(t), priority);
			        });
			      });
			    }
			    return d3_transition_tween(this, "style." + name, value, styleString);
			  };
			  d3_transitionPrototype.styleTween = function(name, tween, priority) {
			    if (arguments.length < 3) priority = "";
			    function styleTween(d, i) {
			      var f = tween.call(this, d, i, d3_window(this).getComputedStyle(this, null).getPropertyValue(name));
			      return f && function(t) {
			        this.style.setProperty(name, f(t), priority);
			      };
			    }
			    return this.tween("style." + name, styleTween);
			  };
			  d3_transitionPrototype.text = function(value) {
			    return d3_transition_tween(this, "text", value, d3_transition_text);
			  };
			  function d3_transition_text(b) {
			    if (b == null) b = "";
			    return function() {
			      this.textContent = b;
			    };
			  }
			  d3_transitionPrototype.remove = function() {
			    var ns = this.namespace;
			    return this.each("end.transition", function() {
			      var p;
			      if (this[ns].count < 2 && (p = this.parentNode)) p.removeChild(this);
			    });
			  };
			  var d3_ease_default = function() {
			    return d3_identity;
			  };
			  var d3_ease = d3.map({
			    linear: d3_ease_default,
			    poly: d3_ease_poly,
			    quad: function() {
			      return d3_ease_quad;
			    },
			    cubic: function() {
			      return d3_ease_cubic;
			    },
			    sin: function() {
			      return d3_ease_sin;
			    },
			    exp: function() {
			      return d3_ease_exp;
			    },
			    circle: function() {
			      return d3_ease_circle;
			    },
			    elastic: d3_ease_elastic,
			    back: d3_ease_back,
			    bounce: function() {
			      return d3_ease_bounce;
			    }
			  });
			  var d3_ease_mode = d3.map({
			    "in": d3_identity,
			    out: d3_ease_reverse,
			    "in-out": d3_ease_reflect,
			    "out-in": function(f) {
			      return d3_ease_reflect(d3_ease_reverse(f));
			    }
			  });
			  d3.ease = function(name) {
			    var i = name.indexOf("-"), t = i >= 0 ? name.slice(0, i) : name, m = i >= 0 ? name.slice(i + 1) : "in";
			    t = d3_ease.get(t) || d3_ease_default;
			    m = d3_ease_mode.get(m) || d3_identity;
			    return d3_ease_clamp(m(t.apply(null, d3_arraySlice.call(arguments, 1))));
			  };
			  function d3_ease_clamp(f) {
			    return function(t) {
			      return t <= 0 ? 0 : t >= 1 ? 1 : f(t);
			    };
			  }
			  function d3_ease_reverse(f) {
			    return function(t) {
			      return 1 - f(1 - t);
			    };
			  }
			  function d3_ease_reflect(f) {
			    return function(t) {
			      return .5 * (t < .5 ? f(2 * t) : 2 - f(2 - 2 * t));
			    };
			  }
			  function d3_ease_quad(t) {
			    return t * t;
			  }
			  function d3_ease_cubic(t) {
			    return t * t * t;
			  }
			  function d3_ease_cubicInOut(t) {
			    if (t <= 0) return 0;
			    if (t >= 1) return 1;
			    var t2 = t * t, t3 = t2 * t;
			    return 4 * (t < .5 ? t3 : 3 * (t - t2) + t3 - .75);
			  }
			  function d3_ease_poly(e) {
			    return function(t) {
			      return Math.pow(t, e);
			    };
			  }
			  function d3_ease_sin(t) {
			    return 1 - Math.cos(t * halfπ);
			  }
			  function d3_ease_exp(t) {
			    return Math.pow(2, 10 * (t - 1));
			  }
			  function d3_ease_circle(t) {
			    return 1 - Math.sqrt(1 - t * t);
			  }
			  function d3_ease_elastic(a, p) {
			    var s;
			    if (arguments.length < 2) p = .45;
			    if (arguments.length) s = p / τ * Math.asin(1 / a); else a = 1, s = p / 4;
			    return function(t) {
			      return 1 + a * Math.pow(2, -10 * t) * Math.sin((t - s) * τ / p);
			    };
			  }
			  function d3_ease_back(s) {
			    if (!s) s = 1.70158;
			    return function(t) {
			      return t * t * ((s + 1) * t - s);
			    };
			  }
			  function d3_ease_bounce(t) {
			    return t < 1 / 2.75 ? 7.5625 * t * t : t < 2 / 2.75 ? 7.5625 * (t -= 1.5 / 2.75) * t + .75 : t < 2.5 / 2.75 ? 7.5625 * (t -= 2.25 / 2.75) * t + .9375 : 7.5625 * (t -= 2.625 / 2.75) * t + .984375;
			  }
			  d3_transitionPrototype.ease = function(value) {
			    var id = this.id, ns = this.namespace;
			    if (arguments.length < 1) return this.node()[ns][id].ease;
			    if (typeof value !== "function") value = d3.ease.apply(d3, arguments);
			    return d3_selection_each(this, function(node) {
			      node[ns][id].ease = value;
			    });
			  };
			  d3_transitionPrototype.delay = function(value) {
			    var id = this.id, ns = this.namespace;
			    if (arguments.length < 1) return this.node()[ns][id].delay;
			    return d3_selection_each(this, typeof value === "function" ? function(node, i, j) {
			      node[ns][id].delay = +value.call(node, node.__data__, i, j);
			    } : (value = +value, function(node) {
			      node[ns][id].delay = value;
			    }));
			  };
			  d3_transitionPrototype.duration = function(value) {
			    var id = this.id, ns = this.namespace;
			    if (arguments.length < 1) return this.node()[ns][id].duration;
			    return d3_selection_each(this, typeof value === "function" ? function(node, i, j) {
			      node[ns][id].duration = Math.max(1, value.call(node, node.__data__, i, j));
			    } : (value = Math.max(1, value), function(node) {
			      node[ns][id].duration = value;
			    }));
			  };
			  d3_transitionPrototype.each = function(type, listener) {
			    var id = this.id, ns = this.namespace;
			    if (arguments.length < 2) {
			      var inherit = d3_transitionInherit, inheritId = d3_transitionInheritId;
			      try {
			        d3_transitionInheritId = id;
			        d3_selection_each(this, function(node, i, j) {
			          d3_transitionInherit = node[ns][id];
			          type.call(node, node.__data__, i, j);
			        });
			      } finally {
			        d3_transitionInherit = inherit;
			        d3_transitionInheritId = inheritId;
			      }
			    } else {
			      d3_selection_each(this, function(node) {
			        var transition = node[ns][id];
			        (transition.event || (transition.event = d3.dispatch("start", "end", "interrupt"))).on(type, listener);
			      });
			    }
			    return this;
			  };
			  d3_transitionPrototype.transition = function() {
			    var id0 = this.id, id1 = ++d3_transitionId, ns = this.namespace, subgroups = [], subgroup, group, node, transition;
			    for (var j = 0, m = this.length; j < m; j++) {
			      subgroups.push(subgroup = []);
			      for (var group = this[j], i = 0, n = group.length; i < n; i++) {
			        if (node = group[i]) {
			          transition = node[ns][id0];
			          d3_transitionNode(node, i, ns, id1, {
			            time: transition.time,
			            ease: transition.ease,
			            delay: transition.delay + transition.duration,
			            duration: transition.duration
			          });
			        }
			        subgroup.push(node);
			      }
			    }
			    return d3_transition(subgroups, ns, id1);
			  };
			  function d3_transitionNamespace(name) {
			    return name == null ? "__transition__" : "__transition_" + name + "__";
			  }
			  function d3_transitionNode(node, i, ns, id, inherit) {
			    var lock = node[ns] || (node[ns] = {
			      active: 0,
			      count: 0
			    }), transition = lock[id];
			    if (!transition) {
			      var time = inherit.time;
			      transition = lock[id] = {
			        tween: new d3_Map(),
			        time: time,
			        delay: inherit.delay,
			        duration: inherit.duration,
			        ease: inherit.ease,
			        index: i
			      };
			      inherit = null;
			      ++lock.count;
			      d3.timer(function(elapsed) {
			        var delay = transition.delay, duration, ease, timer = d3_timer_active, tweened = [];
			        timer.t = delay + time;
			        if (delay <= elapsed) return start(elapsed - delay);
			        timer.c = start;
			        function start(elapsed) {
			          if (lock.active > id) return stop();
			          var active = lock[lock.active];
			          if (active) {
			            --lock.count;
			            delete lock[lock.active];
			            active.event && active.event.interrupt.call(node, node.__data__, active.index);
			          }
			          lock.active = id;
			          transition.event && transition.event.start.call(node, node.__data__, i);
			          transition.tween.forEach(function(key, value) {
			            if (value = value.call(node, node.__data__, i)) {
			              tweened.push(value);
			            }
			          });
			          ease = transition.ease;
			          duration = transition.duration;
			          d3.timer(function() {
			            timer.c = tick(elapsed || 1) ? d3_true : tick;
			            return 1;
			          }, 0, time);
			        }
			        function tick(elapsed) {
			          if (lock.active !== id) return 1;
			          var t = elapsed / duration, e = ease(t), n = tweened.length;
			          while (n > 0) {
			            tweened[--n].call(node, e);
			          }
			          if (t >= 1) {
			            transition.event && transition.event.end.call(node, node.__data__, i);
			            return stop();
			          }
			        }
			        function stop() {
			          if (--lock.count) delete lock[id]; else delete node[ns];
			          return 1;
			        }
			      }, 0, time);
			    }
			  }
			  d3.svg.axis = function() {
			    var scale = d3.scale.linear(), orient = d3_svg_axisDefaultOrient, innerTickSize = 6, outerTickSize = 6, tickPadding = 3, tickArguments_ = [ 10 ], tickValues = null, tickFormat_;
			    function axis(g) {
			      g.each(function() {
			        var g = d3.select(this);
			        var scale0 = this.__chart__ || scale, scale1 = this.__chart__ = scale.copy();
			        var ticks = tickValues == null ? scale1.ticks ? scale1.ticks.apply(scale1, tickArguments_) : scale1.domain() : tickValues, tickFormat = tickFormat_ == null ? scale1.tickFormat ? scale1.tickFormat.apply(scale1, tickArguments_) : d3_identity : tickFormat_, tick = g.selectAll(".tick").data(ticks, scale1), tickEnter = tick.enter().insert("g", ".domain").attr("class", "tick").style("opacity", ε), tickExit = d3.transition(tick.exit()).style("opacity", ε).remove(), tickUpdate = d3.transition(tick.order()).style("opacity", 1), tickSpacing = Math.max(innerTickSize, 0) + tickPadding, tickTransform;
			        var range = d3_scaleRange(scale1), path = g.selectAll(".domain").data([ 0 ]), pathUpdate = (path.enter().append("path").attr("class", "domain"), 
			        d3.transition(path));
			        tickEnter.append("line");
			        tickEnter.append("text");
			        var lineEnter = tickEnter.select("line"), lineUpdate = tickUpdate.select("line"), text = tick.select("text").text(tickFormat), textEnter = tickEnter.select("text"), textUpdate = tickUpdate.select("text"), sign = orient === "top" || orient === "left" ? -1 : 1, x1, x2, y1, y2;
			        if (orient === "bottom" || orient === "top") {
			          tickTransform = d3_svg_axisX, x1 = "x", y1 = "y", x2 = "x2", y2 = "y2";
			          text.attr("dy", sign < 0 ? "0em" : ".71em").style("text-anchor", "middle");
			          pathUpdate.attr("d", "M" + range[0] + "," + sign * outerTickSize + "V0H" + range[1] + "V" + sign * outerTickSize);
			        } else {
			          tickTransform = d3_svg_axisY, x1 = "y", y1 = "x", x2 = "y2", y2 = "x2";
			          text.attr("dy", ".32em").style("text-anchor", sign < 0 ? "end" : "start");
			          pathUpdate.attr("d", "M" + sign * outerTickSize + "," + range[0] + "H0V" + range[1] + "H" + sign * outerTickSize);
			        }
			        lineEnter.attr(y2, sign * innerTickSize);
			        textEnter.attr(y1, sign * tickSpacing);
			        lineUpdate.attr(x2, 0).attr(y2, sign * innerTickSize);
			        textUpdate.attr(x1, 0).attr(y1, sign * tickSpacing);
			        if (scale1.rangeBand) {
			          var x = scale1, dx = x.rangeBand() / 2;
			          scale0 = scale1 = function(d) {
			            return x(d) + dx;
			          };
			        } else if (scale0.rangeBand) {
			          scale0 = scale1;
			        } else {
			          tickExit.call(tickTransform, scale1, scale0);
			        }
			        tickEnter.call(tickTransform, scale0, scale1);
			        tickUpdate.call(tickTransform, scale1, scale1);
			      });
			    }
			    axis.scale = function(x) {
			      if (!arguments.length) return scale;
			      scale = x;
			      return axis;
			    };
			    axis.orient = function(x) {
			      if (!arguments.length) return orient;
			      orient = x in d3_svg_axisOrients ? x + "" : d3_svg_axisDefaultOrient;
			      return axis;
			    };
			    axis.ticks = function() {
			      if (!arguments.length) return tickArguments_;
			      tickArguments_ = arguments;
			      return axis;
			    };
			    axis.tickValues = function(x) {
			      if (!arguments.length) return tickValues;
			      tickValues = x;
			      return axis;
			    };
			    axis.tickFormat = function(x) {
			      if (!arguments.length) return tickFormat_;
			      tickFormat_ = x;
			      return axis;
			    };
			    axis.tickSize = function(x) {
			      var n = arguments.length;
			      if (!n) return innerTickSize;
			      innerTickSize = +x;
			      outerTickSize = +arguments[n - 1];
			      return axis;
			    };
			    axis.innerTickSize = function(x) {
			      if (!arguments.length) return innerTickSize;
			      innerTickSize = +x;
			      return axis;
			    };
			    axis.outerTickSize = function(x) {
			      if (!arguments.length) return outerTickSize;
			      outerTickSize = +x;
			      return axis;
			    };
			    axis.tickPadding = function(x) {
			      if (!arguments.length) return tickPadding;
			      tickPadding = +x;
			      return axis;
			    };
			    axis.tickSubdivide = function() {
			      return arguments.length && axis;
			    };
			    return axis;
			  };
			  var d3_svg_axisDefaultOrient = "bottom", d3_svg_axisOrients = {
			    top: 1,
			    right: 1,
			    bottom: 1,
			    left: 1
			  };
			  function d3_svg_axisX(selection, x0, x1) {
			    selection.attr("transform", function(d) {
			      var v0 = x0(d);
			      return "translate(" + (isFinite(v0) ? v0 : x1(d)) + ",0)";
			    });
			  }
			  function d3_svg_axisY(selection, y0, y1) {
			    selection.attr("transform", function(d) {
			      var v0 = y0(d);
			      return "translate(0," + (isFinite(v0) ? v0 : y1(d)) + ")";
			    });
			  }

      return d3;
  }]);
'use strict';
angular.module('d3').config([
  '$provide',
  function ($provide) {
    var d3WorldCloudDecorator = function ($delegate) {
      var d3 = $delegate;
      (function (exports) {
        function cloud() {
          var size = [
              256,
              256
            ], text = cloudText, font = cloudFont, fontSize = cloudFontSize, fontStyle = cloudFontNormal, fontWeight = cloudFontNormal, rotate = cloudRotate, padding = cloudPadding, spiral = archimedeanSpiral, words = [], timeInterval = Infinity, event = d3.dispatch('word', 'end'), timer = null, cloud = {};
          cloud.start = function () {
            var board = zeroArray((size[0] >> 5) * size[1]), bounds = null, n = words.length, i = -1, tags = [], data = words.map(function (d, i) {
                d.text = text.call(this, d, i);
                d.font = font.call(this, d, i);
                d.style = fontStyle.call(this, d, i);
                d.weight = fontWeight.call(this, d, i);
                d.rotate = rotate.call(this, d, i);
                d.size = ~~fontSize.call(this, d, i);
                d.padding = cloudPadding.call(this, d, i);
                return d;
              }).sort(function (a, b) {
                return b.size - a.size;
              });
            if (timer)
              clearInterval(timer);
            timer = setInterval(step, 0);
            step();
            return cloud;
            function step() {
              var start = +new Date(), d;
              while (+new Date() - start < timeInterval && ++i < n && timer) {
                d = data[i];
                d.x = size[0] * (Math.random() + 0.5) >> 1;
                d.y = size[1] * (Math.random() + 0.5) >> 1;
                cloudSprite(d, data, i);
                if (place(board, d, bounds)) {
                  tags.push(d);
                  event.word(d);
                  if (bounds)
                    cloudBounds(bounds, d);
                  else
                    bounds = [
                      {
                        x: d.x + d.x0,
                        y: d.y + d.y0
                      },
                      {
                        x: d.x + d.x1,
                        y: d.y + d.y1
                      }
                    ];
                  d.x -= size[0] >> 1;
                  d.y -= size[1] >> 1;
                }
              }
              if (i >= n) {
                cloud.stop();
                event.end(tags, bounds);
              }
            }
          };
          cloud.stop = function () {
            if (timer) {
              clearInterval(timer);
              timer = null;
            }
            return cloud;
          };
          cloud.timeInterval = function (x) {
            if (!arguments.length)
              return timeInterval;
            timeInterval = x == null ? Infinity : x;
            return cloud;
          };
          function place(board, tag, bounds) {
            var perimeter = [
                {
                  x: 0,
                  y: 0
                },
                {
                  x: size[0],
                  y: size[1]
                }
              ], startX = tag.x, startY = tag.y, maxDelta = Math.sqrt(size[0] * size[0] + size[1] * size[1]), s = spiral(size), dt = Math.random() < 0.5 ? 1 : -1, t = -dt, dxdy, dx, dy;
            while (dxdy = s(t += dt)) {
              dx = ~~dxdy[0];
              dy = ~~dxdy[1];
              if (Math.min(dx, dy) > maxDelta)
                break;
              tag.x = startX + dx;
              tag.y = startY + dy;
              if (tag.x + tag.x0 < 0 || tag.y + tag.y0 < 0 || tag.x + tag.x1 > size[0] || tag.y + tag.y1 > size[1])
                continue;
              if (!bounds || !cloudCollide(tag, board, size[0])) {
                if (!bounds || collideRects(tag, bounds)) {
                  var sprite = tag.sprite, w = tag.width >> 5, sw = size[0] >> 5, lx = tag.x - (w << 4), sx = lx & 127, msx = 32 - sx, h = tag.y1 - tag.y0, x = (tag.y + tag.y0) * sw + (lx >> 5), last;
                  for (var j = 0; j < h; j++) {
                    last = 0;
                    for (var i = 0; i <= w; i++) {
                      board[x + i] |= last << msx | (i < w ? (last = sprite[j * w + i]) >>> sx : 0);
                    }
                    x += sw;
                  }
                  delete tag.sprite;
                  return true;
                }
              }
            }
            return false;
          }
          cloud.words = function (x) {
            if (!arguments.length)
              return words;
            words = x;
            return cloud;
          };
          cloud.size = function (x) {
            if (!arguments.length)
              return size;
            size = [
              +x[0],
              +x[1]
            ];
            return cloud;
          };
          cloud.font = function (x) {
            if (!arguments.length)
              return font;
            font = d3.functor(x);
            return cloud;
          };
          cloud.fontStyle = function (x) {
            if (!arguments.length)
              return fontStyle;
            fontStyle = d3.functor(x);
            return cloud;
          };
          cloud.fontWeight = function (x) {
            if (!arguments.length)
              return fontWeight;
            fontWeight = d3.functor(x);
            return cloud;
          };
          cloud.rotate = function (x) {
            if (!arguments.length)
              return rotate;
            rotate = d3.functor(x);
            return cloud;
          };
          cloud.text = function (x) {
            if (!arguments.length)
              return text;
            text = d3.functor(x);
            return cloud;
          };
          cloud.spiral = function (x) {
            if (!arguments.length)
              return spiral;
            spiral = spirals[x + ''] || x;
            return cloud;
          };
          cloud.fontSize = function (x) {
            if (!arguments.length)
              return fontSize;
            fontSize = d3.functor(x);
            return cloud;
          };
          cloud.padding = function (x) {
            if (!arguments.length)
              return padding;
            padding = d3.functor(x);
            return cloud;
          };
          return d3.rebind(cloud, event, 'on');
        }
        function cloudText(d) {
          return d.text;
        }
        function cloudFont() {
          return 'serif';
        }
        function cloudFontNormal() {
          return 'normal';
        }
        function cloudFontSize(d) {
          return Math.sqrt(d.value);
        }
        function cloudRotate() {
          return (~~(Math.random() * 6) - 3) * 30;
        }
        function cloudPadding() {
          return 1;
        }
        function cloudSprite(d, data, di) {
          if (d.sprite)
            return;
          c.clearRect(0, 0, (cw << 5) / ratio, ch / ratio);
          var x = 0, y = 0, maxh = 0, n = data.length;
          di--;
          while (++di < n) {
            d = data[di];
            c.save();
            c.font = d.style + ' ' + d.weight + ' ' + ~~((d.size + 1) / ratio) + 'px ' + d.font;
            var w = c.measureText(d.text + 'm').width * ratio, h = d.size << 1;
            if (d.rotate) {
              var sr = Math.sin(d.rotate * cloudRadians), cr = Math.cos(d.rotate * cloudRadians), wcr = w * cr, wsr = w * sr, hcr = h * cr, hsr = h * sr;
              w = Math.max(Math.abs(wcr + hsr), Math.abs(wcr - hsr)) + 31 >> 5 << 5;
              h = ~~Math.max(Math.abs(wsr + hcr), Math.abs(wsr - hcr));
            } else {
              w = w + 31 >> 5 << 5;
            }
            if (h > maxh)
              maxh = h;
            if (x + w >= cw << 5) {
              x = 0;
              y += maxh;
              maxh = 0;
            }
            if (y + h >= ch)
              break;
            c.translate((x + (w >> 1)) / ratio, (y + (h >> 1)) / ratio);
            if (d.rotate)
              c.rotate(d.rotate * cloudRadians);
            c.fillText(d.text, 0, 0);
            c.restore();
            d.width = w;
            d.height = h;
            d.xoff = x;
            d.yoff = y;
            d.x1 = w >> 1;
            d.y1 = h >> 1;
            d.x0 = -d.x1;
            d.y0 = -d.y1;
            x += w;
          }
          var pixels = c.getImageData(0, 0, (cw << 5) / ratio, ch / ratio).data, sprite = [];
          while (--di >= 0) {
            d = data[di];
            var w = d.width, w32 = w >> 5, h = d.y1 - d.y0, p = d.padding;
            for (var i = 0; i < h * w32; i++)
              sprite[i] = 0;
            x = d.xoff;
            if (x == null)
              return;
            y = d.yoff;
            var seen = 0, seenRow = -1;
            for (var j = 0; j < h; j++) {
              for (var i = 0; i < w; i++) {
                var k = w32 * j + (i >> 5), m = pixels[(y + j) * (cw << 5) + (x + i) << 2] ? 1 << 31 - i % 32 : 0;
                if (p) {
                  if (j)
                    sprite[k - w32] |= m;
                  if (j < w - 1)
                    sprite[k + w32] |= m;
                  m |= m << 1 | m >> 1;
                }
                sprite[k] |= m;
                seen |= m;
              }
              if (seen)
                seenRow = j;
              else {
                d.y0++;
                h--;
                j--;
                y++;
              }
            }
            d.y1 = d.y0 + seenRow;
            d.sprite = sprite.slice(0, (d.y1 - d.y0) * w32);
          }
        }
        function cloudCollide(tag, board, sw) {
          sw >>= 5;
          var sprite = tag.sprite, w = tag.width >> 5, lx = tag.x - (w << 4), sx = lx & 127, msx = 32 - sx, h = tag.y1 - tag.y0, x = (tag.y + tag.y0) * sw + (lx >> 5), last;
          for (var j = 0; j < h; j++) {
            last = 0;
            for (var i = 0; i <= w; i++) {
              if ((last << msx | (i < w ? (last = sprite[j * w + i]) >>> sx : 0)) & board[x + i])
                return true;
            }
            x += sw;
          }
          return false;
        }
        function cloudBounds(bounds, d) {
          var b0 = bounds[0], b1 = bounds[1];
          if (d.x + d.x0 < b0.x)
            b0.x = d.x + d.x0;
          if (d.y + d.y0 < b0.y)
            b0.y = d.y + d.y0;
          if (d.x + d.x1 > b1.x)
            b1.x = d.x + d.x1;
          if (d.y + d.y1 > b1.y)
            b1.y = d.y + d.y1;
        }
        function collideRects(a, b) {
          return a.x + a.x1 > b[0].x && a.x + a.x0 < b[1].x && a.y + a.y1 > b[0].y && a.y + a.y0 < b[1].y;
        }
        function archimedeanSpiral(size) {
          var e = size[0] / size[1];
          return function (t) {
            return [
              e * (t *= 0.1) * Math.cos(t),
              t * Math.sin(t)
            ];
          };
        }
        function rectangularSpiral(size) {
          var dy = 4, dx = dy * size[0] / size[1], x = 0, y = 0;
          return function (t) {
            var sign = t < 0 ? -1 : 1;
            switch (Math.sqrt(1 + 4 * sign * t) - sign & 3) {
            case 0:
              x += dx;
              break;
            case 1:
              y += dy;
              break;
            case 2:
              x -= dx;
              break;
            default:
              y -= dy;
              break;
            }
            return [
              x,
              y
            ];
          };
        }
        function zeroArray(n) {
          var a = [], i = -1;
          while (++i < n)
            a[i] = 0;
          return a;
        }
        var cloudRadians = Math.PI / 180, cw = 1 << 11 >> 5, ch = 1 << 11, canvas, ratio = 1;
          canvas = document.createElement('canvas');
          canvas.width = 1;
          canvas.height = 1;
          ratio = Math.sqrt(canvas.getContext('2d').getImageData(0, 0, 1, 1).data.length >> 2);
          canvas.width = (cw << 5) / ratio;
          canvas.height = ch / ratio;
        var c = canvas.getContext('2d'), spirals = {
            archimedean: archimedeanSpiral,
            rectangular: rectangularSpiral
          };
        c.fillStyle = 'red';
        c.textAlign = 'center';
        exports.cloud = cloud;
      }(d3.layout || (d3.layout = {})));
      d3.scale.gray10 = function(index) {
      	return d3.scale.ordinal().range(d3_categorygray);
      };
      var d3_categorygray = ["#000000","#323232","#5A5A5A","#787878","#969696","#B4B4B4","#D2D2D2"];
      return d3;
    };
    $provide.decorator('d3', d3WorldCloudDecorator);
  }
]);
'use strict';
angular.module('d3').directive('wordcloud', [
  'd3',
  function (d3) {
    return {
      restrict: 'E',
      scope: {
        width: '=',
        height: '@',
        fontFamily: '@',
        fontSize: '@',
        words: '=',
        hilite: '=',
        selected: '=',
        onClick: '&',
        onHover: '&'
      },
      link: function postLink(scope, element, attrs) {
        var width = 800;
        var height = 600;
        var fontFamily = "\"Helvetica Neue\",Helvetica,Arial,sans-serif";
        var fontSize = 30;
        var words;
        if (angular.isDefined(scope.width))
          width = scope.width;
        if (angular.isDefined(attrs.height))
          height = attrs.height;
        if (angular.isDefined(attrs.fontFamily))
          fontFamily = attrs.fontFamily;
        if (angular.isDefined(attrs.fontSize))
          fontSize = attrs.fontSize * 1 || 0;
        if (angular.isDefined(scope.words))
            words = scope.words;
        if (angular.isDefined(scope.words) && angular.isArray(words)) {
          words = scope.words;
        } else if (angular.element(element).find('word').length > 0) {
          var subelements = angular.element(element).find('word');
          words = [];
          angular.forEach(subelements, function (word) {
            words.push(angular.element(word).text());
            angular.element(word).remove();
          });
        } else if (element.text().length > 0) {
          words = element.text().split(',');
          element.text('');
        } else {
          return;
        }
        scope.$watch('words', function(newWords)
		{
			cloudFactory(newWords);
		})
		scope.$watch('selected', function(value){
            var result = d3.select(element[0]).selectAll("svg").selectAll('text');
            
            // Reset all the words to the default color
            var currentIndex = 0;
            result.each(function (d) {
            	var curElem = result[0][currentIndex];
            	curElem.style.fill = curElem.getAttribute("oldfill");
            	++currentIndex;
            });

            // Select just the word that was picked
            currentIndex = 0;
            result.each(function (d) {
            	// if the current element is the same value then hilite it
            	if (d.text == value)
            	{
	            	var curElem = result[0][currentIndex];
	            	curElem.style.fill = '#ff9900';
            	}
            	++currentIndex;
            });
			
		})
		scope.$watch('hilite', function(value){
			// if this is clearing the hilite then just return
			if (value == 0)
				return;
			
            var result = d3.select(element[0]).selectAll("svg").selectAll('text');
            var currentIndex = 0;

            // Reset all the words to the default color
            result.each(function (d) {
            	var curElem = result[0][currentIndex];
            	curElem.style.fill = curElem.getAttribute("oldfill");
            	++currentIndex;
            });
            
            // Loop through all the words and see if this user submitted this each word
            currentIndex = 0;
            result.each(function (d) {
            	d.respondents.forEach(function(userId){
	            	if (userId == value)
	            	{
	            		var curElem = result[0][currentIndex];
	            		curElem.style.fill = '#ff9900';
	            	}
            	});
            	++currentIndex;
            })
		})
        if (!angular.isNumber(fontSize) || fontSize <= 0) {
          element.text('wordcloud: font-size attribute not valid. font-size ' + attrs.fontSize + ' -> ' + fontSize);
          return;
        }
        var cloudFactory = function (words) {
          if (d3.select(element[0]).selectAll("svg")[0][0] != undefined){
            d3.select(element[0]).selectAll("svg").remove();
          }
          var fill = d3.scale.gray10();
          d3.layout.cloud().size([
            width,
            height
          ]).words(words.map(function (d) {
            return {
              text: d.word,
              count: d.count,
              respondents: d.respondents,
              size: fontSize + ((d.count - 1) * (words.fontIncrement != null ? words.fontIncrement : 0))
            };
          })).rotate(function () {
            return 0; // ~~(Math.random() * 2) * -90;
          }).text(function(d) { 
            return d.text; 
          }).font(fontFamily).fontSize(function (d) {
            return d.size;
          }).on('end', draw).start();
          function draw(words) {
            var height_translate = height / 2;
            var width_translate = width / 2;
            var rootElement = element[0];
            d3.select(rootElement).append('svg').attr('width', width).attr('height', height).append('g').attr('transform', 'translate(' + width_translate + ',' + height_translate + ')').selectAll('text').data(words).enter().append('text').style('font-size', function (d) {
              return d.size + 'px';
            }).style('font-family', fontFamily).style('fill', function (d, i) {
              return fill(d.count);
            }).attr('oldfill', function (d, i) {
                return fill(d.count);
            }).attr('text-anchor', 'middle').attr('transform', function (d) {
              return 'translate(' + [
                d.x,
                d.y
              ] + ')rotate(' + d.rotate + ')';
            }).text(function (d) {
              return d.text;
            }).attr('user-id', function (d) {
                return d.text.users;
            }).on('click', function (d) {
              scope.onClick({ element: d });
            }).on('mouseover', function (d) {
              scope.onHover({ element: d });
            });
          }
        };
        // cloudFactory(words);
      }
    };
  }
]);
