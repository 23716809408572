'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpGg4lSignin', ['$window', function($window) {

    function link(scope, element, attrs)
    {
      scope.onLogin = function() {
        if (scope.login) {
          scope.login();
        }
      };
    }

    return {
      replace: true,
      restrict: 'E',
      template: require('./gg4lSignin.jade'),
      link: link,
      scope: {
        text: '@',
        smallText: '@',
        login: '&'
      }
    }
  }]);
})();
