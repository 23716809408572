'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['$resource', '$log', 'ActiveMode', 'api_server'];

function controller($resource, $log, $rootScope, ActiveMode, api_server) {
  var ctrl = this;

  var districtResource =
    $resource(api_server  + '/subscriptions/admin/district/:districtId', {},
        {params: {districtId: '@districtId'}});

  function loadDistrictInfo() {
    if (ActiveMode.currentDistrictId) {
      districtResource.get({districtId: ActiveMode.currentDistrictId}).$promise
      .then(function(response) {
        response = response || {};


        response.active_classes = response.active_classes || [];
        response.past_classes = response.past_classes || [];
        var idNum = parseInt(ActiveMode.currentDistrictId, 10);
        var activeSubscription = {
          id: idNum,
          subscription_id: idNum,
          name: response.name,
          description: response.description
        };
        response.active_classes.forEach(function(clazz) {
          clazz.subscriptions = [activeSubscription];
        });
        ctrl.availableSeats = response.seats;
        ctrl.usedSeats = response.used_seats;
        ctrl.classes = response.active_classes.concat(response.past_classes);
        ctrl.availableSubscriptions =  [activeSubscription];
        ctrl.endsAt = response.ends_at;
        ctrl.subscriptionName = response.name;
//        sortRows();



      })
      .catch(function(error) {
        $log.error("error in log district info:",error);
      });
    }
  }

  loadDistrictInfo();

}

module.component('xpDistrictLicense', {
  template: require('./districtLicense.jade'),
  controller: controller,
});

})();
