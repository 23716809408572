'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$rootScope', '$scope', '$location', '$routeParams', '$log', 'User', 'PageService', 'PermissionConsts', 'userPermissions',
                        'userClassPermissions', 'userSubscriptionPermissions', 'UserLastActive', 'themeService', 'Utils',
                        'PresenceValidator', 'ActiveExperience', 'ModalService'];

  function controller ($rootScope, $scope, $location, $routeParams, $log, User, PageService, PermissionConsts, userPermissions,
      userClassPermissions, userSubscriptionPermissions, UserLastActive, themeService, Utils,
      PresenceValidator, ActiveExperience, ModalService) {
    var ctrl = this;

    $scope.standard = {};

    $scope.navView = PageService.getNavView();
    $scope.teacherCode = PageService.getTeacherCode();
    $scope.mainClass = PageService.getMainClass();

    // Flag for page loading : used for packs
    $scope.pageLoadedCompleted = false;

    // Check if the client is a mobile device (only checking iOS and Android currently)
    $scope.isMobile = Utils.isMobile();

    // This defaults the maxwidth
    $scope.maxwidth = 962;

    //Instantiate an object to store your scope data in (Best Practices)
    $scope.messages = '';
    $scope.showIncTemplate = false;

    var gettingTheme = false;

    $scope.userIsTeacher = function () {
      return User.getSystemRole() == User.ROLE.TEACHER;
    };

    $scope.userIsStudent = function () {
      return User.getSystemRole() == User.ROLE.STUDENT;
    };

    $scope.userTheme = null;
    $scope.userBackground = function () {
      var style = {};
      if (User.getCurrentUser().theme_id && (!$scope.userTheme || User.getCurrentUser().theme_id != $scope.userTheme.id)) {
        if (!gettingTheme) {
          gettingTheme = true;
          themeService.getTheme(User.getCurrentUser().theme_id).then(function(theme) {
            $scope.userTheme = theme;
            gettingTheme = false;
          }).catch(function(err) {
            gettingTheme = false;
            $log.error("Failed to get users theme: " + error);
          });
        }
      } else if (!User.getCurrentUser().theme_id) {
        $scope.userTheme = null;
      }
      if ($scope.userTheme) {
        if ($scope.userTheme.theme_type === 1) {
          style['background-color'] = $scope.userTheme.color1;
        } else if ($scope.userTheme.theme_type === 2) {
          style['background-repeat'] = 'no-repeat';
          style['background-size'] = 'cover';
          style['background-image'] = "linear-gradient(" + $scope.userTheme.color1 + "," + $scope.userTheme.color2 + ")";
        } else if ($scope.userTheme.theme_type === 3) {
          style['background-image'] = "url(backgrounds\/" + $scope.userTheme.id + "\/image)";
        }
      }
      return style;
    }

    $scope.permissions = PermissionConsts;
    $scope.hasPermission = function (permission) {
      return userPermissions.hasPermission(permission) ||
      userClassPermissions.hasPermission(permission) ||
      userSubscriptionPermissions.hasPermission(permission);
    };

    $scope.hasExperiencePermission = function(permission) {
      var experienceId = parseInt($routeParams.id, 10);
      return experienceId && ActiveExperience.hasPermission(experienceId, permission);
    }

    $scope.$on('$viewContentLoaded', function () {
      // $scope.msg= $route.current.templateUrl + ' is loaded !!';
      $scope.pageLoadedCompleted = true;
    });

    $rootScope.$on('$routeChangeStart', function (event, current, prev) {
      UserLastActive.set();
      if (current && current.$$route && current.$$route.resolve) {

        // Show a loading message until promises are resolved
        $scope.loadingView = true;
        // Hide all modals
        ModalService.hideAll();
      }
    });

    $rootScope.$on('$routeChangeSuccess', function (event, current, prev) {
      var title = (current && current.$$route && current.$$route.title) ? current.$$route.title : '';
      var navView = (current && current.$$route && current.$$route.navView) ? current.$$route.navView : '';
      var controller = (current && current.$$route && current.$$route.controller) ? current.$$route.controller : null;

      PageService.updateNavView(navView);
      PageService.updateBodyClass(current);
      PageService.updateMainClass(current);

      // Hide loading message
      $scope.loadingView = false;
    });

    // Save USER object to session storage - emitted when UserInfoDefaultFactory.get() or save() is called
    $rootScope.$on('USER.updated', function (event, args) {
      $scope.standard = args;
    });

    $rootScope.$on('pageMainClassUpdated', function (event, args) {
      $scope.mainClass = args.mainClass;
    });

    $rootScope.$on('navViewUpdated', function (event, args) {
      $scope.navView = args.navView;
    });

    // All Alerts will go through here
    $rootScope.changeView = function (view) {
      $location.path(view);
    };

    // Setup service to check on timeout status
    PresenceValidator.start();
  }

  module.component('xpApplication', {
    template: require('./application.jade'),
    controller: controller,
  });
})();
