'use strict';

angular.module('UtilsModule', []).provider('Utils', function () {
  var utils = {};

  utils.convertSecondsToDisplay = function (time) {
    var hours = 0;
    var minutes = 0;

    if (time > 0) {
      hours = Math.floor(time / 3600);
      time = time - hours * 3600;

      // Now minutes
      minutes = Math.round(time / 60);
    }

    return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
  };

  utils.isMobile = function () {
    return navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/) ? true : false;
  };

  this.$get = ['$rootScope', '$timeout', '$log', function ($rootScope, $timeout, $log) {
    return utils;
  }];
});

