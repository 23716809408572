'use strict';

/**
 * File to attach code to the document on load
 */

// This is a fix for safari on the iPad.  When entering data into an input field or text area, the keyboard pops up scrolling the content
// up in the window.  If you click somewhere in the HTML view the keyboard goes away but the content does not correctly scroll back to its
// correct position.  This code below is a bit of a hack that tells safari to re-scroll when any input loses focus.
if (/(iPad|iPhone|iPod)/g.test( navigator.userAgent ))
{
	document.addEventListener('focusin', function(e) {
		var target = e.target;
		if (target.type == 'textarea' || target.type == 'text' || target.type == 'password')
		{
			var listener = function(e) {
				if (listener && typeof listener == 'function')
				{
					document.removeEventListener('focusout', listener, false);					
				    window.setTimeout(function() {
				        window.scrollTo(document.body.scrollLeft, document.body.scrollTop);
				    }, 0);
				}				
			};
			
			document.addEventListener('focusout', listener, false);
		}		
	}, false);
}
