'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$log', 'DistrictAdminMode', 'ActiveMode', 'SubscriptionMode'];

function controller ($log, DistrictAdminMode, ActiveMode, SubscriptionMode) {
  var ctrl = this;

  ctrl.COLLECTIONS_ID_ALL = '616C6C';
  ctrl.loading = true;

  ctrl.summaryReports = [];
  ctrl.courseReports = [];

  ctrl.availableCourses = [];
  ctrl.selectedCourses = [];

  // Create a default all courses selection
  var allCourses = {id: "All", name: "All Courses", ticked: true};
  ctrl.reportFilter = [allCourses];

  ctrl.availableCourses.push(allCourses);

  ctrl.navigateToReport = function(report) {
    SubscriptionMode.showDistrictReport(report.subscription_id, report.report_id);
  };

  function getReports() {
    if (DistrictAdminMode.isActiveMode()) {
      return SubscriptionMode.getDistrictReports(ActiveMode.currentDistrictId());
    } else {
      return SubscriptionMode.getSchoolReports(ActiveMode.currentSchoolId());
    }
  }

  // Get the correct set of district reports based on the current user
  getReports().then(function(reports) {
    ctrl.summaryReports = reports.filter(function(report) {
      if (report.collections && report.collections.length) {
        var isGlobalCollection = report.collections.find(function(collection) { return collection.collection_uuid === ctrl.COLLECTIONS_ID_ALL; });
        return isGlobalCollection;
      }
    });
    ctrl.courseReports = reports.filter(function(report) {
      if (report.collections && report.collections.length) {
        report.collections.forEach(function(collection){
          if (collection.collection_uuid !== ctrl.COLLECTIONS_ID_ALL) {
            ctrl.availableCourses.push(
                {
                  id: collection.collection_uuid,
                  name: collection.name,
                  order: collection.sort_order
                }
            );
          }
        });
        var isGlobalCollection = report.collections.find(function(collection) { return collection.collection_uuid === ctrl.COLLECTIONS_ID_ALL; });
        return !isGlobalCollection;
      }
    });
    ctrl.availableCourses = ctrl.availableCourses.filter(function(obj, pos, arr) {
        return arr.map(mapObj => mapObj['id']).indexOf(obj['id']) === pos;
    })
    .sort(function(a, b) { return a.order - b.order; });

    ctrl.loading = false;
  })
  .catch(function(error) {
    $log.error("error in getting reports:",error);
  });

  // This code makes sure that the "All Courses" selection and any course selections are kept exclusive.
  // Basically you cannot select All Courses and individual courses at the same time.
  ctrl.onCourseClicked = function(item) {
    // if this is NOT the all classes then make sure the ALL Courses is NOT checked
    if (item.id !== "All") {
      ctrl.availableCourses[0].ticked = false;
    }
    else {
      // Uncheck all other courses since all courses is currently selected
      ctrl.availableCourses.forEach(function(course, index) {
        if (index !== 0 && course.ticked)
          course.ticked = false;
      });
    }
  };

  ctrl.filterByCourse = function(report) {
    if (!ctrl.reportFilter.length || ctrl.reportFilter[0].id === 'All') {
      return true;
    }

    var reportInCollection = false;
    report.collections.forEach(function(collection){
      var inFilter = ctrl.reportFilter.find(function(filter) { return collection.collection_uuid === filter.id; });
      if (inFilter) {
        reportInCollection = true;
      }
    });

    return reportInCollection;
  }

  ctrl.onUpdateReportList = function() {
    ctrl.reportFilter = ctrl.selectedCourses;
  };

}

module.component('xpAdminReportPicker', {
  template: require('./adminConsoleReportPicker.jade'),
  controller: controller,
});

})();
