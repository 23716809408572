'use strict';
(function () {
var module = angular.module('xp-html-common', ['angularWidget']);

module.directive('xpExpandableText', ['$window', function ($window) {
  return {
    restrict: 'E',
    replace: false,
    template: require('./expandable.jade'),
    link: link,
    scope: {
      'title': '=',
      'text': '=',
      'linkTitle': '=',
      'linkUrl': '=',
      'collapsed': '=',
      'onCollapse': '&',
      'displayMode': '=',
      'isGate': '@',
      'buttonState': '=',
      'onUnlock': '&'
      }
  };
  function link(scope, element, attrs) {
    // Find the collapsable element
    var collapsibleEl = angular.element(element[0].querySelector('.client-html-element-box'));

    scope.collapsible = false;
    scope.isCollapsed = true;

    var DISPLAY_MODE = Object.freeze({kTeacherMode: 0, kStudentMode: 1});
    scope.DISPLAY_MODE = DISPLAY_MODE;
    var BUTTON_STATE = Object.freeze({NONE: 0, DONE: 1, UNLOCK: 2, UNLOCKED: 3});
    scope.BUTTON_STATE = BUTTON_STATE;

    var linesOfText = 0;
    var collapsibleContainer = angular.element(collapsibleEl);
    var collapsibleElement = angular.element(collapsibleEl.children()[0]);

    scope.onCollapse = scope.onCollapse();
    scope.onUnlock = scope.onUnlock();

    // This is the most important part of making the directive work.  In order to determine the number of lines of text
    // that an element requires the element itself must be created within the dom.  The only reasonable way to know this
    // is to watch something in the dom to see if it changes.  The only solution I found (Google) suggested by several people was
    // to watch the height of your element in the DOM and when it changed then you know the DOM was constructed.
    scope.$watch( function() { return element[0].offsetHeight; },
      function(newValue, oldValue) {
        if (!newValue || newValue === 0) return;
        scope.initialize(collapsibleContainer, collapsibleElement);
        scope.setCollapse(collapsibleContainer, collapsibleElement);
      });

    scope.initialize = function ($elContainer, $el) {
      var childEl1;
      var childEl2;

      if (linesOfText === 0) {
        angular.forEach($el.children(), function (child1) {
          if (child1.offsetWidth > 0 && child1.offsetHeight > 0) {
            childEl1 = angular.element(child1);
            if (child1.children.length > 0) {
              angular.forEach(childEl1.children(), function (child2) {
                childEl2 = angular.element(child2);
                linesOfText += getLinesOfText(childEl2[0]);
              });
            }
            else {
              linesOfText += getLinesOfText(childEl1[0]);
            }
          }
        });
      }

      // Is element collapsible?
      scope.collapsible = (linesOfText >= 6);
    };

    scope.$watch('collapsed', function() {
      scope.isCollapsed = scope.collapsed;
      scope.setCollapse(collapsibleContainer, collapsibleElement);
    });

    scope.setCollapse = function ($elContainer, $el) {
      if (!scope.collapsible) return;

      var isChrome = (navigator && navigator.userAgent.toLowerCase().indexOf('chrome') > -1);
      var nativeLineClamp = (isChrome && $el[0].style.hasOwnProperty('webkitLineClamp'));

      if (scope.isCollapsed) {
        if (nativeLineClamp) {
          $el.addClass('line-clamp');
        }
        else {
          jQuery($el).trunk8({
            lines: 4,
            fill: '&hellip;',
            tooltip: false
          });
        }
      }
      else {
        if (nativeLineClamp) {
          $el.removeClass('line-clamp');
        }
        else {
          if (jQuery($el).data('trunk8')) {
            jQuery($el).trunk8('revert');
          }
        }
      }
    };

    function getLinesOfText($el) {
      var height = $el.offsetHeight;
      var line_height = getLineHeight($el);
      var rows = height / line_height;
      return Math.round(rows);
    }

    function getLineHeight($el) {
      var styles = $window.getComputedStyle($el, null);
      var font_size = styles.fontSize;
      var scale = 1.15;
      return Math.floor(parseInt(font_size, 10) * scale);
    }

    // Find the expand/collapse area at the bottom of the element
    var toggleEl = angular.element(element[0].querySelector('.client-html-element-box-bottom'));
    toggleEl.bind('click', function () {
      scope.toggleCollapse(collapsibleContainer, collapsibleElement);
    });

    scope.toggleCollapse = function ($elContainer, $el) {
      if (!scope.collapsible) return;
      scope.isCollapsed = !scope.isCollapsed;
      scope.setCollapse(collapsibleContainer, collapsibleElement);
      scope.onCollapse(scope.isCollapsed);
    };

    scope.unlock = function() {
      if (scope.onUnlock !== undefined) {
        var func = scope.onUnlock();
        if (angular.isFunction(func))
          func();
      }
    };

  }
}]);
})();
