'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log', 'TeacherStreamingService',
                        'StudentStreamingService', 'User'];

  function controller ($scope, $log, TeacherStreamingService,
                        StudentStreamingService, User) {
    var ctrl = this;
    
    ctrl.studentHasAcceptedStreaming = false;

    ctrl.userJoinedLivestream = function() {
      return ctrl.studentHasAcceptedStreaming || User.isTeacher();
    };

    ctrl.isTeacherStreamingVideo = function() {
      return TeacherStreamingService.isTeacherStreamingVideo();
    };

    ctrl.isStudentSeeingStreaming = function() {
      return StudentStreamingService.isStudentSeeingStreaming();
    };
  }

  module.component('videoStreaming', {
    template: require('./videoStreaming.jade'),
    controller: controller,
  });
})();
