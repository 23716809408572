(function() {
  var app;

  app = angular.module("ngOnboarding", []);

  app.provider("ngOnboardingDefaults", function() {
    return {
      options: {
        overlay: true,
        overlayOpacity: 0.6,
        showButtons: true,
        nextButtonText: 'Next',
        previousButtonText: 'Previous',
        showDoneButton: true,
        doneButtonText: 'Done',
        closeButtonText: '×',
        showStepInfo: true
      },
      $get: function() {
        return this.options;
      },
      set: function(keyOrHash, value) {
        var k, v, _results;
        if (typeof keyOrHash === 'object') {
          _results = [];
          for (k in keyOrHash) {
            v = keyOrHash[k];
            _results.push(this.options[k] = v);
          }
          return _results;
        } else {
          return this.options[keyOrHash] = value;
        }
      }
    };
  });

  app.directive('onboardingPopover', [
    'ngOnboardingDefaults', '$sce', '$timeout', function(ngOnboardingDefaults, $sce, $timeout) {
      return {
        restrict: 'E',
        scope: {
          enabled: '=',
          steps: '=',
          onExitCallback: '=',
          onDoneCallback: '=',
          index: '=stepIndex',
          onNext: '=',
          onPrevious: '='
        },
        replace: true,
        link: function(scope, element, attrs) {
          var attributesToClear, curStep, setupOverlay, setupPositioning;
          curStep = null;
          attributesToClear = ['title', 'top', 'right', 'bottom', 'left', 'width', 'height', 'position'];
          scope.stepCount = scope.steps.length;
          scope.polygon = {x: 0, y: 0, x1: 0, y1: 0, x2: 0, y2: 0};
          const maxSVG = 500;
          scope.svg = {width: maxSVG, height: maxSVG};
          scope.next = function() {
            if (scope.onNext) {
              return scope.onNext();
            } else {
              return scope.index = scope.index + 1;
            }
          };
          scope.previous = function() {
            if (scope.onPrevious) {
                return scope.onPrevious();
            } else {
              return scope.index = scope.index - 1;
            }
          };
          scope.close = function() {
            if (scope.onExitCallback) {
              return scope.onExitCallback();
            }
          };
          scope.done = function() {
            if (scope.onDoneCallback) {
              return scope.onDoneCallback();
            }
          };
          scope.isEnabled = function() {
            if (scope.enabled) {
              return scope.enabled();
            }
            return false;
          };
          scope.$watch(function() {
            if (scope.enabled) {
              return scope.enabled();
            } else {
              return false;
            }
          },
          function(newVal) {
            return newVal;
          });
          scope.$watch('index', function(newVal, oldVal) {
            var attr, k, v, _i, _len;
            if (newVal === null) {
              setupOverlay(false);
              return;
            }
            curStep = scope.steps[scope.index];
            scope.lastStep = scope.index + 1 === scope.steps.length;
            scope.showNextButton = scope.index + 1 < scope.steps.length;
            scope.showPreviousButton = false;
            for (_i = 0, _len = attributesToClear.length; _i < _len; _i++) {
              attr = attributesToClear[_i];
              scope[attr] = null;
            }
            for (k in ngOnboardingDefaults) {
              v = ngOnboardingDefaults[k];
              if (curStep[k] === void 0) {
                scope[k] = v;
              }
            }
            for (k in curStep) {
              v = curStep[k];
              scope[k] = v;
            }
            ngOnboardingDefaults.showButtons = curStep.showButtons;
            scope.description = $sce.trustAsHtml(scope.description);
            scope.nextButtonText = $sce.trustAsHtml(scope.nextButtonText);
            scope.previousButtonText = $sce.trustAsHtml(scope.previousButtonText);
            scope.doneButtonText = $sce.trustAsHtml(scope.doneButtonText);
            scope.closeButtonText = $sce.trustAsHtml(scope.closeButtonText);
            setupOverlay();
            return setupPositioning();
          });
          setupOverlay = function(showOverlay) {
            if (showOverlay == null) {
              showOverlay = true;
            }
            $('.onboarding-focus').removeClass('onboarding-focus');
            if (showOverlay) {
              if (curStep['focusOn'] && scope.overlay) {
                return $(curStep['focusOn']).addClass('onboarding-focus');
              }
            }
          };
          setupPositioning = function() {
            const arrowCenter = 40;
            const arrowOffset = 20;
            const arrowPtOffset = 20;
            var attachTo, bottom, left, right, top, xMargin, yMargin;
            attachTo = curStep['attachTo'];
            scope.position = curStep['position'];
            xMargin = 15;
            yMargin = 15;
            if (attachTo) {
              if (!(scope.left || scope.right)) {
                left = null;
                right = null;

                if (scope.position === 'right') {
                  if (curStep['xOffset'] && curStep['xOffset'] + curStep['width'] + $(attachTo).offset().left + $(attachTo).outerWidth() > $(window).width()) {
                    curStep['xOffset'] = $(window).width() - curStep['width'] - $(attachTo).offset().left - $(attachTo).outerWidth() - xMargin;
                  }
                  scope.svg.width = ((curStep['xOffset'] ? curStep['xOffset'] : 0) + xMargin);
                  left = $(attachTo).offset().left + $(attachTo).outerWidth();
                  scope.arrowLeft = -1 * ((curStep['xOffset'] ? curStep['xOffset'] : 0) + xMargin);
                  scope.polygon.x = (curStep['arrowXOffset'] ? curStep['arrowXOffset'] : 0);
                  scope.polygon.x1 = -1 * scope.arrowLeft;
                  scope.polygon.x2 = -1 * scope.arrowLeft;
                } else if (scope.position === 'left') {
                  if (curStep['xOffset'] && curStep['xOffset'] - curStep['width'] + $(attachTo).offset().left < 0) {
                    curStep['xOffset'] = (-1 * ($(attachTo).offset().left - xMargin)) + curStep['width'];
                  }
                  left = $(attachTo).offset().left - (curStep['width'] ? curStep['width'] : 0);
                  scope.arrowLeft = (curStep['width'] ? curStep['width'] : 0) - 5;
                  scope.svg.width = ((curStep['xOffset'] ? (-1 * curStep['xOffset']) : 0) + xMargin);
                  scope.polygon.x = scope.svg.width + (curStep['arrowXOffset'] ? curStep['arrowXOffset'] : 0);
                  scope.polygon.x1 = 0;
                  scope.polygon.x2 = 0;
                } else if (scope.position === 'top' || scope.position === 'bottom') {
                  if (curStep['xOffset'] && curStep['xOffset'] + $(attachTo).offset().left < 0) {
                    curStep['xOffset'] = -1 * ($(attachTo).offset().left - xMargin);
                  }
                  scope.svg.width = maxSVG;
                  left = $(attachTo).offset().left;
                  scope.arrowLeft = -1 * ((curStep['xOffset'] ? curStep['xOffset'] : 0) - ($(attachTo).outerWidth() / 2) + arrowPtOffset);
                  if (scope.arrowLeft > 0) {
                    scope.polygon.x = ($(attachTo).outerWidth() / 2) - (curStep['xOffset'] ? curStep['xOffset'] : 0) + (curStep['arrowXOffset'] ? curStep['arrowXOffset'] : 0);
                    scope.arrowLeft = 0;
                  } else {
                    scope.polygon.x = arrowPtOffset + (curStep['arrowXOffset'] ? curStep['arrowXOffset'] : 0);
                  }
                  const arrowLeftOffset = (curStep['arrowCenter'] ? curStep['arrowCenter'] : arrowCenter) - (curStep['arrowHeight'] ? curStep['arrowHeight'] / 2 : arrowOffset);
                  const arrowRightOffset = (curStep['arrowCenter'] ? curStep['arrowCenter'] : arrowCenter) + (curStep['arrowHeight'] ? curStep['arrowHeight'] / 2 : arrowOffset);
                  scope.polygon.x1 = (-1 * scope.arrowLeft) + arrowLeftOffset;
                  scope.polygon.x2 = (-1 * scope.arrowLeft) + arrowRightOffset;
                }
                if (curStep['xOffset']) {
                  if (left !== null) {
                    left = left + curStep['xOffset'];
                  }
                  if (right !== null) {
                    right = right - curStep['xOffset'];
                  }
                }
                scope.left = left;
                scope.right = right;
              }
              if (!(scope.top || scope.bottom)) {
                top = null;
                bottom = null;
                if (scope.position === 'left' || scope.position === 'right') {
                  scope.svg.height = maxSVG;
                  top = $(attachTo).offset().top;
                  scope.arrowTop = -1 * ((curStep['yOffset'] ? curStep['yOffset'] : 0) - ($(attachTo).outerHeight() / 2) + arrowPtOffset);
                  if (scope.arrowTop > 0) {
                    scope.polygon.y = ($(attachTo).outerHeight() / 2) - (curStep['yOffset'] ? curStep['yOffset'] : 0) + (curStep['arrowYOffset'] ? curStep['arrowYOffset'] : 0);
                    scope.arrowTop = 0;
                  } else {
                    scope.polygon.y = arrowPtOffset + (curStep['arrowYOffset'] ? curStep['arrowYOffset'] : 0);
                  }
                  const arrowTopOffset = (curStep['arrowCenter'] ? curStep['arrowCenter'] : arrowCenter) - (curStep['arrowHeight'] ? curStep['arrowHeight'] / 2 : arrowOffset);
                  const arrowBottomOffset = (curStep['arrowCenter'] ? curStep['arrowCenter'] : arrowCenter) + (curStep['arrowHeight'] ? curStep['arrowHeight'] / 2 : arrowOffset);
                  scope.polygon.y1 = (-1 * scope.arrowTop) + arrowTopOffset;
                  scope.polygon.y2 = (-1 * scope.arrowTop) + arrowBottomOffset;
                } else if (scope.position === 'bottom') {
                  scope.svg.height = ((curStep['yOffset'] ? curStep['yOffset'] : 0) + yMargin);
                  top = $(attachTo).offset().top + $(attachTo).outerHeight();
                  scope.arrowTop = -1 * ((curStep['yOffset'] ? curStep['yOffset'] : 0) + yMargin);
                  scope.polygon.y = (curStep['arrowYOffset'] ? curStep['arrowYOffset'] : 0);
                  scope.polygon.y1 = -1 * scope.arrowTop;
                  scope.polygon.y2 = -1 * scope.arrowTop;
                } else if (scope.position === 'top') {
                  bottom = $(window).height() - $(attachTo).offset().top + yMargin;
                }
                if (curStep['yOffset']) {
                  if (top !== null) {
                    top = top + curStep['yOffset'];
                  }
                  if (bottom !== null) {
                    bottom = bottom - curStep['yOffset'];
                  }
                }
                scope.top = top;
                scope.bottom = bottom;
              }
            }
            if (scope.position && scope.position.length) {
              return scope.positionClass = "onboarding-" + scope.position;
            } else {
              return scope.positionClass = null;
            }
          };
          if (scope.steps.length && !scope.index) {
            return scope.index = 0;
          }
        },
        templateUrl: "taskListBalloon.tpl",
      };
    }
  ]);

    app.directive('onboardingBalloonArrow', ['$sce', '$timeout', function($sce, $timeout) {
      return {
        restrict: 'E',
        scope: {
          left: '=',
          top: '=',
          arrow: '=',
          svg: '='
        },
        replace: true,
        link: function(scope, element, attrs) {
        },
        templateUrl: "taskListArrow.tpl",
      };
    }
  ]);

}).call(this);
