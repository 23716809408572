'use strict';

angular.module('client.services').factory('subscriptionReports', ['$q', '$resource', '$log', 'api_server',
  function ($q, $resource, $log, api_server) {

  var subscriptionReports = $resource(api_server + '/subscriptions/admin/subscriptions/:subscription_id/reports');
  var districtReports = $resource(api_server + '/subscriptions/admin/subscriptions/:subscription_id/districts/:district_id/reports');
  var schoolReports = $resource(api_server + '/subscriptions/admin/subscriptions/:subscription_id/schools/:school_id/reports');
  var subscriptionReportData = $resource(api_server + '/subscriptions/admin/subscriptions/:subscription_id/reports/:report_id', null, {
    'query': {method: 'GET', isArray: false}
  });

  function getReports(subscriptionId) {
    if (!subscriptionId) {
      return $q.reject('Invalid subscription id');
    }

    return subscriptionReports.query({subscription_id: subscriptionId}).$promise;
  }

  function getDistrictReports(subscriptionId, districtId) {
    if (!subscriptionId) {
      return $q.reject('Invalid subscription id');
    }
    if (!districtId) {
      return $q.reject('Invalid district id');
    }

    return districtReports.query({subscription_id: subscriptionId, district_id: districtId}).$promise;
  }

  function getSchoolReports(subscriptionId, schoolId) {
    if (!subscriptionId) {
      return $q.reject('Invalid subscription id');
    }
    if (!schoolId) {
      return $q.reject('Invalid school id');
    }

    return schoolReports.query({subscription_id: subscriptionId, school_id: schoolId}).$promise;
  }

  function getReportData(subscriptionId, reportId, filter) {
    if (!subscriptionId) {
      return $q.reject('Invalid subscription id');
    }
    if (!reportId) {
      return $q.reject('Invalid report id');
    }

    return subscriptionReportData.query({
      subscription_id: subscriptionId, report_id: reportId, filter: filter
    }).$promise;
  }

  return {
    getReports: getReports,
    getDistrictReports: getDistrictReports,
    getSchoolReports: getSchoolReports,
    getReportData: getReportData
  };
}]);

