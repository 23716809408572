'use strict';
angular.module('xp-element-teacher_gate', ['angularWidget', 'client.services'])
.controller('clientTeacherGateElementCtrl', ['$scope', 'widgetConfig', 'ElementsRestService', 'ElementsErrorService', '$timeout', '$log', function ($scope, widgetConfig, ElementsRestService, ElementsErrorService, $timeout, $log) {
	$scope.options = widgetConfig.getOptions($scope);

	var DISPLAY_MODE = Object.freeze({kTeacherMode : 0, kStudentMode : 1});
	$scope.DISPLAY_MODE = DISPLAY_MODE;
	var BUTTON_STATE = Object.freeze({NONE : 0, DONE : 1, UNLOCK : 2, UNLOCKED : 3});
	$scope.BUTTON_STATE = BUTTON_STATE;

	$scope.buttonState = BUTTON_STATE.NONE;

	function parseElement()
	{
		var element = $scope.options.element;
		element.config.attributes.forEach(function(attribute)
			{
				var name = attribute.name;
				var value = attribute.value;
				switch (name)
				{
					case "teacher_title" :
						if ($scope.displayMode == DISPLAY_MODE.kTeacherMode)
						{
							$scope.title = value;
						}
					break;
					case "teacher_text" :
						if ($scope.displayMode == DISPLAY_MODE.kTeacherMode)
						{
							$scope.text = value;
						}
						break;
					case "student_title" :
						if ($scope.displayMode == DISPLAY_MODE.kStudentMode)
						{
							$scope.title = value;
						}
						break;
					case "student_text" :
						if ($scope.displayMode == DISPLAY_MODE.kStudentMode)
						{
							$scope.text = value;
						}
						break;
				}
			});
	}

  function extractAndParserUserData(state) {
    if(!state){
      return {
        unlocked: false
      };
    }
    // for gates
    if(!state.user_data){
      return {
        unlocked: false
      };
    }
    if(state.user_data === 'unlocked'){
      return {
        unlocked: true
      };
    }
    try {
      return JSON.parse(state.user_data) || {};
    }
    catch (e) {
      $log.error("error in parsing user state for teacher gate:",state);
      return {};
    }
  }

	function getUserState(experienceId, elementId, userId, callback) {
		var userData = {
			unlocked: false
		};

		if ($scope.displayMode != $scope.DISPLAY_MODE.kTeacherMode) {
			return callback(userData);
		}
    if ($scope.options.context.getViewingInactiveExperience()){
      userData = {
        unlocked: true,
        collapsed: false
      };
      return callback(userData);
    }
    ElementsRestService.getUserState(experienceId, elementId, userId, function (result, error) {
      if(error){
        $log.error("error in getting user state for teacher gate:",error);
      }
      userData = extractAndParserUserData(result);

      callback(userData, error);
    });
	}

	function unlock(experienceId, elementId, userId, callback, error)
	{
		getUserState(experienceId, element.id, context.userId, function(userData, error) {
			if (error)
			{
				ElementsErrorService.error(error);
			}
			else
			{
				//Set the "unlocked" property in the user_data field
				userData.unlocked = true;

				ElementsRestService.saveUserState(context.experienceId, elementId, userId, 0, JSON.stringify(userData), callback, error);
			}
		});
	}

	var element;
	var context;

	$scope.$watch('options', function() {
		var options = $scope.options;
		if (!options.element)
			return;

		element = options.element;
		context = options.context;

		$scope.displayMode = context.userIsTeacher() ? $scope.DISPLAY_MODE.kTeacherMode : $scope.DISPLAY_MODE.kStudentMode;

		parseElement();

		getUserState(context.experienceId, element.id, context.userId, function (userData, error) {
			if (error) {
				ElementsErrorService.error(error);
			}
			else {
				$scope.buttonState = userData.hasOwnProperty('unlocked') && userData.unlocked ? BUTTON_STATE.UNLOCKED : BUTTON_STATE.UNLOCK;
			}
		});

		$timeout(function() {
			// Notify the widget that were are done loading the data
			widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
		});
	}, true);

	$scope.done = function()
	{
		$scope.buttonState = BUTTON_STATE.UNLOCK;
	};

	$scope.unlock = function()
	{
		if ($scope.displayMode != $scope.DISPLAY_MODE.kTeacherMode) return; // just to be safe

		unlock(context.experienceId, element.id, context.userId, function(result){
			$scope.buttonState = BUTTON_STATE.UNLOCKED;
		}, function(error){
			ElementsErrorService.error(error);
		});
	};
  }]);
