'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpElementScorePopup', ['$timeout', '$window', '$location', function($timeout, $window, $location) {
    var template =
      "<a bs-popover data-bs-show='showPopop' data-container='body' data-placement='top auto' ng-click='onShowPopup()' data-trigger='manual' data-content-template='{{display}}'><div ng-transclude></div></a>";
    
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        display: '@display',
        showFirstAttempt: '=',
        element: '=',
        elements: '=',
        first: '=',
        last: '='
      },
      template: template,
      link: function (scope, element, attrs) {
        scope.showPopop = false;
        var $body = angular.element($window.document);
        
        scope.$watch("element", function (currentValue, previousValue) {
          if (currentValue.show) {
            scope.onShowPopup();
            currentValue.show = false;
          }
        }, true);
        
        scope.onShowPopup = function() {
          if (!scope.showPopop) {
            scope.showPopop = true;
            scope.element.isShowingPopup = true;
            $timeout(function() {
              $body.on("click", hidePopup);
            }, 0, false);
          }
        };
        
        var hidePopup = function() {
          if (scope.showPopop) {
            $timeout(function() {
              scope.$apply(function() {
                scope.showPopop = false;
                scope.element.isShowingPopup = false;
              });              
            });
            $body.off("click", hidePopup);
          }
        };

        scope.calculateScore = function(item) {
          if (scope.showFirstAttempt) {
            return Math.round((item.correctResponses / item.totalResponses) * 100);
          }
          else {
            return Math.round((item.correctLastResponses / item.totalResponses) * 100);            
          }
        };
        
        scope.calculateSpireScore = function(element) {
          var display = "";
          if (element.spire.caCorrect && element.spire.caTotal) {
            display = ": " + (Math.round((element.spire.caCorrect / element.totalResponses) * 10) / 10) + "/" + element.spire.caTotal;
            display = display + " " + Math.round(((element.spire.caCorrect / element.totalResponses) / element.spire.caTotal) * 100) + "%";
          }
          else if (element.spire.cmfdWPM) {
            display = ": " + (Math.round((element.spire.cmfdWPM / element.totalResponses) * 10) / 10) + " WPM";
          }
          else if (element.spire.decodableWordScore) {
            display = "<br>Part 1: " + Math.round(((element.spire.decodableWordScore / element.totalResponses / 100) * scope.elements[element.id].prePostCounts.words) * 10) / 10 +
                      "/" + scope.elements[element.id].prePostCounts.words + " " + Math.round(element.spire.decodableWordScore / element.totalResponses) + "%";
            display = display + "<br>Part 2: " + Math.round(((element.spire.decodableSentenceScore / element.totalResponses / 100) * scope.elements[element.id].prePostCounts.sentences) * 10) / 10 +
                      "/" + scope.elements[element.id].prePostCounts.sentences + " " + Math.round(element.spire.decodableSentenceScore / element.totalResponses) + "%";
            display = display + "<br>Part 3: " + Math.round((element.spire.passageLastWord / element.totalResponses) * 10) / 10 + " WPM";
            display = display + "<br>Part 4: " + Math.round(((element.spire.comprehensionScore / element.totalResponses / 100) * scope.elements[element.id].prePostCounts.questions) * 10) / 10 +
                      "/" + scope.elements[element.id].prePostCounts.questions + " " + Math.round(element.spire.comprehensionScore / element.totalResponses) + "%";
          }
          else if (element.spire.midScores) {
            // Iterate the midscores adding each
            element.spire.midScores.forEach(function(midScore, index){
              display = display + "<br>Concept " + (index + 1) + ": " + Math.round(midScore / element.totalResponses) + "%";
            })
          }
          return display;
        }
        
        scope.onPreviousElement = function(currentElement) {
          hidePopup();
          if (scope.elements[currentElement.previousId]) {
            scope.elements[currentElement.previousId].show = true;
          }
        };
        
        scope.onNextElement = function(currentElement) {
          hidePopup();
          if (scope.elements[currentElement.nextId]) {
            scope.elements[currentElement.nextId].show = true;
          }
        };
        
        scope.onElementLink = function(element) {
          $location.path(element.link);
          $location.hash(element.anchor);
        };
        
        // Safe remove.
        scope.$on("$destroy", function () {
          $body.off("click", hidePopup);
        });
      }
    };
  }]);
})();