'use strict';

/* Filters */

var module = angular.module('client.filters', []);

module.filter('propertyFilter', function () {
  return function (items, props) {
    var out = [];
    if (angular.isArray(items)) {
      items.forEach(function (item) {
        var itemMatches = false;
        var keys = Object.keys(props);
        var optionValue = '';
        for (var i = 0; i < keys.length; i++) {
          optionValue = item[keys[i]] ? optionValue + item[keys[i]].toString().toLowerCase().replace(/ /g, '') : '';
        }
        for (var j = 0; j < keys.length; j++) {
          var text = props[keys[j]].toLowerCase().replace(/ /g, '');
          if (optionValue.indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }
        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }
    return out;
  };
});

module.filter('pluralize', function() {
  return function(ordinal, noun) {
    if (ordinal == 1) {
      return ordinal + ' ' + noun;
    } else {
      var plural = noun;
      if (noun.substr(noun.length - 2) == 'us') {
        plural = plural.substr(0, plural.length - 2) + 'i';
      } else if (noun.substr(noun.length - 2) == 'ch' || noun.charAt(noun.length - 1) == 'x' || noun.charAt(noun.length - 1) == 's') {
        plural += 'es';
      } else if (noun.charAt(noun.length - 1) == 'y' && ['a','e','i','o','u'].indexOf(noun.charAt(noun.length - 2)) == -1) {
        plural = plural.substr(0, plural.length - 1) + 'ies';
      } else if (noun.substr(noun.length - 2) == 'is') {
        plural = plural.substr(0, plural.length - 2) + 'es';
      } else {
        plural += 's';
      }
      return ordinal + ' ' + plural;
    }
  };
});

module.filter('trusted', ['$sce', function($sce){
	return function(html) {
		return $sce.trustAsHtml(html);
	};
}]);

module.filter('trustedURL', ['$sce', function ($sce) {
  return function(url) {
    return $sce.trustAsResourceUrl(url);
  };
}]);
