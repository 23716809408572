'use strict';

(function() {
var module = angular.module('client.components');

fixedPageModalController.$inject = ['xpAlert'];

function fixedPageModalController(xpAlert) {
  var ctrl = this;
  ctrl.xpAlert = xpAlert;
}

module.component('xpFixedPageModal', {
  template: require('./fixedPageModal.jade'),
  controller: fixedPageModalController,
  transclude: true
});

})();

