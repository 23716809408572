'use strict';

angular.module('client.services').factory('noTypeReportsList', ['$log',
  function ($log) {
    return {
      includes: function (reportKind) {
        return [
          'school-teks-summary-no-type',
          'district-teks-summary-no-type',
          'curriculum-teks-summary-no-type',
          'curriculum-teks-details-no-type'
        ].indexOf(reportKind) > -1;
      }
    };
  }]);

