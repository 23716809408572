'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$log', 'User', 'HelpVideoService'];

  function controller ($scope, $log, User, HelpVideoService) {
    var ctrl = this;
    ctrl.player = videojs.Player;
    ctrl.options = {
      fluid: true,
      controls: true,
      aspectRatio: '16:9',
      autoplay: false,
      sources: []
    };

    function videoEnded() {
      var currentVideoUrl = ctrl.player.currentSrc();
      var currentVideoIndex = ctrl.videos.findIndex(function(video) { return video.video_url === currentVideoUrl; });
      if (currentVideoIndex != -1 && currentVideoIndex < ctrl.videos.length - 1) {
        ctrl.player.src({src: ctrl.videos[currentVideoIndex + 1].video_url, type: 'video/mp4'});
        ctrl.player.play();
        ctrl.videos[currentVideoIndex].playing = false;
        ctrl.videos[currentVideoIndex + 1].playing = true;
        ctrl.videoTitle = ctrl.videos[currentVideoIndex + 1].name;
        ctrl.videoDescription = ctrl.videos[currentVideoIndex + 1].description;
      }
    }

    ctrl.onChangedVideo = function(video) {
      var currentVideoUrl = ctrl.player.currentSrc();
      var currentVideoIndex = ctrl.videos.findIndex(function(video) { return video.video_url === currentVideoUrl; });
      if (currentVideoIndex != -1) {
        ctrl.videos[currentVideoIndex].playing = false;
        ctrl.videoTitle = video.name;
        ctrl.videoDescription = video.description;
      }
    };

    ctrl.$onInit = function () {
      HelpVideoService.getVideos(User.getId()).then(function(videos) {
        ctrl.videos = videos;
        if (ctrl.videos && ctrl.videos.length) {
          ctrl.options.sources.push({src: ctrl.videos[0].video_url, type: 'video/mp4', poster: ctrl.videos[0].video_thumb});
          ctrl.options.poster = ctrl.videos[0].video_thumb;
          ctrl.videos[0].playing = true;
          ctrl.videoTitle = ctrl.videos[0].name;
          ctrl.videoDescription = ctrl.videos[0].description;
        }
        ctrl.player = videojs('helpvideo', ctrl.options, function onPlayerReady() {
          this.on('ended', videoEnded);
        });
      });
    };

    ctrl.$onDestroy = function () {
      // destroy player
      if (ctrl.player) {
        ctrl.player.dispose();
      }
    };

  }

  module.component('xpHelpVideos', {
    template: require('./helpVideos.jade'),
    controller: controller,
  });
})();