'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['ActiveMode', 'DistrictAdminMode', 'userDistrictAdminPermissions', 'userSchoolAdminPermissions'];

function controller(ActiveMode, DistrictAdminMode, userDistrictAdminPermissions, userSchoolAdminPermissions) {
  var ctrl = this;

  if (DistrictAdminMode.isActiveMode()) {
    userDistrictAdminPermissions.getDistrict(ActiveMode.currentDistrictId()).then(function(district){
      ctrl.Name = district.name;
    });
  } else {
    userSchoolAdminPermissions.getSchool(ActiveMode.currentSchoolId()).then(function(school){
      ctrl.Name = school.name;
    });
  }
}

module.component('xpDistrictUsersModal', {
  template: require('./adminConsoleUsersModal.jade'),
  controller: controller,
});

})();