'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpGoogleDoc', ['GoogleDrive', function(GoogleDrive) {
    
    function link(scope, element, attrs)
    {
      scope.onOpenDriveDoc = function(doc) {
        if (scope.googleUser) {
          GoogleDrive.open(doc.url);
        }
      };
      
      scope.isGoogleUser = function() {
        return scope.googleUser;
      };
      
      scope.instructionText = function() {
        if (scope.googleUser) {
          return "Click to open in Google Drive";
        }
        else {
          return "Only Google users can view this file";
        }
      };
    }
    
    return {
      replace: true,
      restrict: 'E',
      template: require('./googleDoc.jade'),
      link: link,
      scope: {
        doc: '=',
        googleUser: '='
      }
    };
  }]);
})();
