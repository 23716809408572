'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$element', '$attrs', '$log', 'VideoStreamingService', 'OT'];

  function controller ($scope, $element, $attrs, $log, VideoStreamingService, OT) {
    var ctrl = this;

    ctrl.streams = [];

    function initSubscriber() {
      // Initialize all the callback handlers from opentok
      initializeSessionHandlers();
    }

    ctrl.$onChanges = function(changes) {
      if (changes.session && !changes.session.previousValue && changes.session.currentValue) {
        initSubscriber(changes.session.currentValue);
      }
    };

    ctrl.$onDestroy = function () {
      ctrl.streams = [];
    };

    function initializeSessionHandlers() {
      // Dispatched locally by the Session object when the connection is established
      ctrl.session.on("sessionConnected", function (event) {
        $log.info("The session connected.");
      });

      // The Session object dispatches SessionDisconnectEvent object when a session has disconnected
      ctrl.session.on("sessionDisconnected", function (event) {
        $log.info("The session disconnected.");
      });

      // A new stream, published by another client, has been created on this session
      ctrl.session.on("streamCreated", function (event) {
        $log.info("A new stream was created.");
        ctrl.streams.push(event.stream);
      });

      // A stream from another client has stopped publishing to the session.
      ctrl.session.on("streamDestroyed", function (event) {
        $log.info("A stream was destroyed.");
        ctrl.streams = ctrl.streams.filter(function(stream){
          return stream.id !== event.stream.id
        });
      });

      // The Session object dispatches a connectionCreated event when a client (including your own) connects to a Session
      ctrl.session.on("connectionCreated", function (event) {
        $log.info("A connection was created.");
      });

      // The Session object dispatches a connectionCreated event when a client (including your own) connects to a Session
      ctrl.session.on("connectionDestroyed", function (event) {
        $log.info("A connection was destroyed.");
      });
    }
  }

  module.component('subscriber', {
    template: require('./subscriber.jade'),
    controller: controller,
    bindings: {
      session: '<'
    }
  });
})();
