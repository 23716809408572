'use strict';
angular.module('xp-element-drag_drop_image', ['angularWidget', 'client.services', 'client.directives', 'draganddrop', 'ngAnimate', 'mgcrea.ngStrap', 'ngSanitize'])
	.controller('clientDragDropImageElementCtrl',
		['$scope', '$rootScope', 'widgetConfig', '$http', 'ElementsRestService', 'ElementUtilities', '$log',
		'JSONStringUtility', '$timeout', 'SHARE_MODE', 'GATE_MODE', 'ElementsErrorService',
		'IconOverlays', 'RespondentType', '$sce', '$q', 'ActiveExperience', 'CorrectResponseId', 'randomize',
		function ($scope, $rootScope, widgetConfig, $http, ElementsRestService, ElementUtilities, $log,
			JSONStringUtility, $timeout, SHARE_MODE, GATE_MODE, ElementsErrorService,
			IconOverlays, RespondentType, $sce, $q, ActiveExperience, CorrectResponseId, randomize) {

		$scope.instructions = {};
		$scope.labels = [];
		$scope.choices = [];
		$scope.allowDuplicates = false;
		$scope.editing = false;
		$scope.respondents = [];
		$scope.selectedRespondent = null;
		$scope.isTeacher = false;
		$scope.responses = [];
		$scope.displayResponses = [];
		$scope.answeredAll = false;
		$scope.answerCount = 0;
		$scope.allResponses = [];
		$scope.isDirty = false;
		$scope.allowEditing = false;
		$scope.status = [];
		$scope.hasDistractors = false;
		$scope.isAssessed = false;
		$scope.initialized = false;

		var context = null;
		var element = null;
		var share = null;

		$scope.gateMode = GATE_MODE.XPGateModeGated;
		$scope.SHARE_MODE = SHARE_MODE;
		$scope.share = SHARE_MODE.TEACHER;

		// The element is the block of data provided by the source xml
		$scope.options = widgetConfig.getOptions($scope);

		var parseElement = function()
		{
			if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes)
				return;

			$scope.options.element.config.attributes.forEach(function(attribute)
			{
				var value;
				switch (attribute.name)
				{
				case "text" :
					$scope.instructions.question = $sce.trustAsHtml(attribute.value);
				break;
				case "image_url" :
					$scope.imageUrl = ElementUtilities.getElementURL($scope.options.element, $scope.options.context.experienceId, attribute.value);
					break;
				case "duplicates" :
					$scope.allowDuplicates = attribute.value === "true" || attribute.value === true;
				break;
				case "share" :
					$scope.share = attribute.value;
				break;
				case "labels" :
					value = attribute.value;
					if (!(value instanceof Array))
					{
						value =
							[
								{
									name: "label",
									value: value.label
								}
							];
					}
					value.forEach(function(option)
					{
						$scope.labels.push(
						{
							valid: true,
							assessed: option.value.assessed === "true" || option.value.assessed === true,
							text : option.value.text,
							display_text: $sce.trustAsHtml(option.value.text),
							position : { x: option.value.x, y: option.value.y, width: option.value.width, height: option.value.height },
							used : false
						});

						// Keep track to see if any items are assessed
						$scope.isAssessed = $scope.isAssessed || (option.value.assessed === "true" || option.value.assessed === true);

						// Count the valid number of answers
						$scope.answerCount = $scope.answerCount + 1;
					});
				break;
				case "distractors" :
					value = attribute.value;
					if (!(value instanceof Array))
					{
						value =
							[
								{
									name: "distractor",
									value: value.distractor
								}
							];
					}
					value.forEach(function(option)
					{
						$scope.labels.push(
						{
							assessed: false,
							valid: false,
							text: option.value.text,
							display_text : $sce.trustAsHtml(option.value.text),
							used : false
						});
					});
				break;
				}
			});

			// copy the labels into the choices
			for (var index = 0; index < $scope.labels.length; ++index)
			{
				// ignore any empty values
				if ($scope.labels[index].text)
				{
					// If this is allowing duplicates, then don't put a duplicate into the array
					if (!$scope.allowDuplicates || !inArray($scope.choices, $scope.labels[index].text))
						$scope.choices.push($scope.labels[index]);
				}
			}

			// Randomize the labels
			$scope.choices = randomize.shuffle($scope.choices);

			// Allocate enough space in the arrays for all responses
      $scope.responses = createBlankArray($scope.answerCount);

			$timeout(function() {
				// Notify the widget that were are done loading the data
				widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
			});

		};

    function createBlankArray(size) {
      var result = [];
      for (var index = 0; index < size; ++index) {
        result.push("");
      }
      return result;
    }

		function inArray(choices, value)
		{
			// Iterate over the choices looking for the value
			for (var loop = 0; loop < choices.length; ++loop)
			{
				// if found then it was in the array
				if (choices[loop].text == value)
					return true;
			}

			return false;
		}

		var unregisterOptionsWatch = $scope.$watch('options', onOptionsSet, true);

		function onOptionsSet()
		{
			if ($scope.options.context)
			{
				// if not yet initialized
				if (!$scope.initialized)
				{
					context = $scope.options.context;
					element = $scope.options.element;
					parseElement();
					$scope.isTeacher = context.userIsTeacher();
					$scope.selectRespondent($scope.isUsingSmallGroups() ? context.groupId : context.userId);

	        loadAnswers();
					$scope.initialized = true;
				}
			}
		}

	  $scope.$watch('savingElementId', function(newValue, oldValue) {
	    // Submit the response selected in this element
	    if (newValue && newValue.elementId === $scope.options.element.id){
	      $scope.didSubmit(newValue);
	    }
	  }, true);

    $rootScope.$on('teacher-notes', function() {
      if ($scope.isTeacher) {
        if (ActiveExperience.currentExperience().hideTeacherNotes) {
          $scope.selectRespondent(context.userId);
        } else {
          $scope.selectRespondent(CorrectResponseId.CORRECTANSWERS);
        }
      }
    });

		function loadAnswers()
		{
			if (!element || !element.id)
				return;

			// Clear any existing responses as they will be reloaded
			$scope.respondents = [];
			$scope.allResponses = [];

			var isInactive = $scope.options.context.getViewingInactiveExperience();

			// if this is the teacher then add the set of correct answers for them
			if ($scope.isTeacher && (!$scope.options.context.getViewingInactiveExperience() ||
          ($scope.options.context.filteredUser && $scope.options.context.filteredUser.report === 1))) {

				// Add the correct responses to the teacher into the response set
				$scope.respondents.push({ id: CorrectResponseId.CORRECTANSWERS, type: RespondentType.USER, showCheckMark: true });

				var validResponses = [];
				for (var index = 0; index < $scope.labels.length; ++index)
				{
					// Push the assigned label into the valid response list
					validResponses[index] = $scope.labels[index].text;
				}

				$scope.allResponses.push( { respondentId: CorrectResponseId.CORRECTANSWERS, type: RespondentType.USER, response: validResponses, correct: 1 } );
			}

			ElementsRestService.getSharedState($scope.options.context.experienceId, element.id, context.groupName, isInactive,
			  function(result) {

				if (result && Array.isArray(result) && result.length) {
					$scope.filterAnswers(result).forEach(function(answer){
						updateAnswer(answer);
					});
				}

				// If the current student does NOT have an values then they can go directly to editing mode
				if (!$scope.isTeacher)
				{
				  // Determine the current user/current group id
		      var currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;

		      // First check to see if any responses for this element are in the cache
		      var isCached = false;
          if ($scope.cached) {
            var cachedValue = $scope.cached({elementId: $scope.options.element.id});
            if (cachedValue) {
              var allCorrect = checkResponses(cachedValue);
              $scope.allResponses.push( { respondentId: currentRespondentId, type: RespondentType.USER, response: cachedValue, correct: allCorrect } );
              $scope.isDirty = true;
              isCached = true;
            }
          }

		      var found = false;
          if (!isCached) {
  					$scope.allResponses.forEach(function(response)
  					{
  						// if this response matches the current user then
  						if (response.respondentId == currentRespondentId)
  							found = true;
  					});
          }

					$scope.selectRespondent($scope.isUsingSmallGroups() ? context.groupId : context.userId);

					if ($scope.isDirty) {
            finishedAnswers();
					}

					if (isCached) {
			      $scope.status = [];
					}

					// If no record found then automatically start editing
					$scope.editing = !found || isCached || $scope.options.quiz;
				}	else if (ActiveExperience.currentExperience() && !ActiveExperience.currentExperience().hideTeacherNotes) {
					// Just select the teacher by default
					$scope.selectRespondent($scope.getRespondent() ? $scope.getRespondent() : CorrectResponseId.CORRECTANSWERS);
				}

				var service = $scope.options.elementRealtimeService;
				var EVENTS = service.EVENTS;
				service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
				$scope.$on('$destroy', function(){
					service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
				});

				// Notify the widget that were are done loading the data
				widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
			},
			function(error) {
				ElementsErrorService.error(error);
			});
		}

		function updateRespondents(answer)
		{
			var answerRespondentId = $scope.isUsingSmallGroups() ? answer.small_gid : answer.user_id;
			var currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;
			var respondentType = $scope.isUsingSmallGroups() ? RespondentType.GROUP : RespondentType.USER;

			// If this user is submitting real data then make sure they are in the list of users
			var respondents = $scope.respondents;
			if (answer.user_data && answer.user_data.length > 0 && ($scope.isTeacher || answerRespondentId === currentRespondentId))
			{
				// See if they are already in the list
				var inList = false;
				respondents.forEach(function(respondent){
					if (respondent.id === answerRespondentId)
						inList = true;
				});

				// add to list if necessary
				if (!inList)
					respondents.push({'id': answerRespondentId, 'type': respondentType });
			}

			// Sort if this is groups
			if ($scope.isUsingSmallGroups())
				respondents.sort();

			if ($scope.isUsingSmallGroups())
				$scope.portionResponded = (respondents.length - 1) / context.clazz.smallGroups;
			else if (context.clazz.students.length)
				$scope.portionResponded = (respondents.length - 1) / context.clazz.students.length;
			else
				$scope.portionResponded = 0;
		}

		function updateAnswer(answer) {
      // Exception case for no responses from server
      if (!answer.user_data) {
        return;
      }

      var context = $scope.options.context;

			var respondentId = $scope.isUsingSmallGroups() ?  answer.small_gid : answer.user_id;

			// parse this into a JSON object
			answer.user_data = JSONStringUtility.parse(answer.user_data);

			// See if this user has a response already
			var found = false;
			$scope.allResponses.forEach(function(response)
			{
				// if the respondent id matches then just update the responses
				if (response.respondentId === respondentId)
				{
					response.response = answer.user_data;
					response.correct = checkResponses(answer.user_data);
					found = true;
				}
			});

			// Insert this into the full set of responses
			if (!found)
			{
				// Check to see if this user correctly answered
				var allCorrect = checkResponses(answer.user_data);

				// Add this student into the response set
				$scope.allResponses.push( { respondentId: respondentId, type: RespondentType.USER, response: answer.user_data, correct: allCorrect } );
			}

			// if this is the current user then set these responses into the current response object
			if ($scope.selectedRespondent == respondentId)
			{
				$scope.responses = answer.user_data;
				updateChoiceStates();
			}

			// Update the respondents based on this answer
			updateRespondents(answer);
			return undefined;
		}

		function checkResponses(responses)
		{
			var allCorrect = true;
			var someCorrect = false;

			// Iterate over the responses and see if each correctly matches the correct response
			for (var index = 0; index < $scope.labels.length; ++index)
			{
				// See if this matches the nth value in the source
				if ($scope.labels[index].assessed && $scope.labels[index].text != responses[index])
					allCorrect = false;
				else if ($scope.labels[index].assessed && $scope.labels[index].text === responses[index])
					someCorrect = true;
			}

			return allCorrect ? 1 : (someCorrect ? -1 : 0);
		}

    function mapCorrectToIcon(correct) {
      if (correct === -1) return IconOverlays.MINUS;
      if (correct === 1) return IconOverlays.CHECK;
      return IconOverlays.CROSS;
    }

    $scope.correctOverlay = function(respondentId)
    {
      if (!$scope.isTeacher || !$scope.isAssessed)
        return undefined;

      // Search through all the responses
      for (var index = 0; index < $scope.allResponses.length; ++index)
      {
        // if this the same group
        if (respondentId === $scope.allResponses[index].respondentId) {
          return mapCorrectToIcon($scope.allResponses[index].correct);
        }
      }

      return IconOverlays.CROSS;
    };

		function stateChangedNotificationHandler(e) {
		  var message = e.detail;
			var state = message.record;
			if (state.element_id != $scope.options.element.id) return;

			$log.debug ("Received drag-n-drop-image state update: " + JSON.stringify(message));

			// Need to convert user and group id's to integers
			if (state.user_id)
				state.user_id = parseInt(state.user_id, 10);
			if (state.small_gid)
				state.small_gid = parseInt(state.small_gid, 10);

			$scope.$apply(updateAnswer(state));
		}

		function selectRespondentData(respondentId)
		{
			var found = false;

			// Select this respondents data into the active set
			$scope.allResponses.forEach(function(response){
				if (response.respondentId == respondentId)
				{
					// We found responses for this user so reset the UI to reflect their values
					$scope.responses = response.response.slice(0);
					updateChoiceStates();
					found = true;
				}
			});

			// if the selected user does not have responses then clear the values
			if (!found) {
        $scope.responses = createBlankArray($scope.answerCount);
        if (respondentId) {
          updateChoiceStates();
        } else {
          $scope.displayResponses = [];
        }
        $scope.status = [];
      }

			$scope.editing = false;
		}

	  $scope.canEditForRespondent = function(respondentId) {
      if (!$scope.options.context)
        return false;

      if ($scope.options.context.isPreview) {
        return false;
      }

      return $scope.isTeacher || ($scope.allowEditing && !$scope.options.quiz);
    };

    $scope.hasResponses = function() {
      return $scope.respondents.length;
    };

		$scope.getUserGroup = function(userId)
		{
			return context && context.getUserGroup(userId);
		};

		$scope.selectRespondent = function(respondentId)
		{
			// Set the respondents data
			$scope.selectedRespondent = respondentId;

			// This populates the UI with the selected data
			selectRespondentData(respondentId);

			// tell UI this respondent has data
			respondentHasData(respondentId);
		};

    $scope.selectChoice = function(choice) {
      $scope.choices.forEach(function(ch) {
        ch.selected = false;
      });
      choice.selected = !choice.selected;
    }

		function respondentHasData(respondentId)
		{
			$scope.allowEditing = false;

			// Teacher cannot edit
			if (!$scope.isTeacher)
			{
				// if this is NOT a past experience
				if (!$scope.options.context.getViewingInactiveExperience())
				{
					// Loop through data and see if this user has any data
					$scope.allResponses.forEach(function(response){
						// if this response belongs to this user or the group this user is in
						if (response.respondentId === respondentId)
							$scope.allowEditing = true;
					});
				}
			} else {
			  $scope.allowEditing = CorrectResponseId.CORRECTANSWERS != context.userId;
			}
		}

		$scope.teacherIsSelected = function()
		{
			// If the selected user is the teacher
			return $scope.isTeacher &&
			        !$scope.options.context.getViewingInactiveExperience();
		};

		$scope.getEditMenuItemsForUser = function(userId)
		{
      let editUserId = context.getSelectedRespondentId($scope.isUsingSmallGroups(), userId);
			var menuOptions;
			menuOptions =
			[
				{
					text: '<div class="xp-element-menu-edit">Edit</div>',
					click: 'editResponses(' + editUserId +')'
				}
			];

			if ($scope.editing)
			{
				menuOptions =
				[
					{
						text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
						click: 'cancelEdit(' + editUserId +')'
					}
				];
			}

			return menuOptions;
		};

		$scope.editResponses = function(userId)
		{
      // reset the assessment status flag to hide the alert if it is showing
      $scope.assessmentStatus = false;

      $scope.selectRespondent(userId);

      // Clear the status for when in student facing feedback
      $scope.status = [];

			// Editing mode
			$scope.editing = true;
		};

		$scope.cancelEdit = function(userId)
		{
      // Re-selects original data for this user
		  selectRespondentData(userId);

      if ($scope.changed) {
        $scope.changed({elementId: $scope.options.element.id, selection: null});
      }

			// Not longer in editing mode
			$scope.editing = false;
		};

    $scope.onRetry = function() {
      $scope.editResponses($scope.selectedRespondent);
    };

    $scope.hidingTeacherNotes = function() {
      return ActiveExperience.currentExperience() && ActiveExperience.currentExperience().hideTeacherNotes && $scope.selectedRespondent == null;
    };

    function displayStudentFacingFeedback() {
      // if this is a student they are not editing and they are viewing their own data
      if (($scope.options.context.getStudentFacingFeedback() || $scope.options.context.getViewingInactiveExperience()) &&
          !$scope.isTeacher && !$scope.editing && $scope.isAssessed && !$scope.options.quiz) {

        // Determine the current user/current group id
        var currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;

        // Make sure the student has data
        var studentResponses = $scope.allResponses.filter(function(response) { return response.respondentId == currentRespondentId; });

        // if any results were found return success
        return studentResponses && studentResponses.length;
      }
      return false;
    }

		function updateChoiceStates()
		{
		  // update the display array to correctly display HTML version of values
		  $scope.displayResponses = [];
      $scope.responses.forEach(function(response, index){
        $scope.displayResponses[index] = $sce.trustAsHtml(response);
      });

			// if not allowing duplicates then mark all used elements
			if (!$scope.allowDuplicates)
			{
				// first, reset all choices to available
				$scope.choices.forEach(function(choice){
					choice.used = false;
				});

				// Now loop through all the current users responses and mark as used appropriately
				$scope.responses.forEach(function(response){
					// Loop through the choices and mark as used
					for (var i = 0; i < $scope.choices.length; ++i)
					{
						// if the name of the response matches the name of the choice AND it has not already been set to used
						if ($scope.choices[i].text == response && $scope.choices[i].used === false)
						{
							$scope.choices[i].used = true;
							break;
						}
					}
				});
			}

			// if this is a teacher then check all the responses to see if they are correct
			if (($scope.isTeacher && !$scope.editing) || displayStudentFacingFeedback())
			{
				// Clear the current status markers
				$scope.status = [];

				// Loop over the responses and mark each
				for (var index = 0; index < $scope.responses.length; ++index)
				{
					// See if the response is correct
					if ($scope.labels[index].assessed === false)
						$scope.status[index] = 0;
					else if ($scope.responses[index] === $scope.labels[index].text)
						$scope.status[index] = 1;
					else
						$scope.status[index] = -1;
				}
			}
		}

		// Drop handler.
		$scope.onDrop = function (data, event, dropIndex) {
			// Don't allow dropping anything if this user is not currently editing
			if ($scope.editing)
			{
				// Get custom object data.  This is the word that is being dropped
				var choice = data['json/custom-object'];

				// Save the answer at the correct index.  See if this is a text element being dropped or an index being moved
				if (choice && choice.text)
					$scope.responses[dropIndex] = choice.text;
				else if (!isNaN(choice))
				{
					$scope.responses[dropIndex] = $scope.responses[choice];
					$scope.responses[choice] = "";
				}

		    if ($scope.selectionMade) {
		      $scope.selectionMade({selected: true});
		    }

        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: $scope.responses});
        }

				// User made a change so set dirty flag
				$scope.isDirty = true;

				// See if the user finished answering all choices
				finishedAnswers();

				// Disable/enable choices appropriately based on answer state
				updateChoiceStates();
			}
		};

    $scope.onClickDrop = function(dropIndex) {
      if ($scope.editing) {

        // Get custom object data.  This is the word that is being dropped
        var choice = $scope.choices.find(function(ch) { return ch.selected; });
        if (choice) {
          // Save the answer at the correct index.  See if this is a text element being dropped or an index being moved
          if (choice.text) {
            $scope.responses[dropIndex] = choice.text;
          }

          if ($scope.selectionMade) {
            $scope.selectionMade({selected: true});
          }

          if ($scope.changed) {
            $scope.changed({elementId: $scope.options.element.id, selection: $scope.responses});
          }

          // User made a change so set dirty flag
          $scope.isDirty = true;

          // See if the user finished answering all choices
          finishedAnswers();

          // Disable/enable choices appropriately based on answer state
          updateChoiceStates();
        }
      }
    };

  $scope.onDropRemove = function(data, event, dropIndex) {
    if ($scope.editing) {
      // Get custom object data. This is the index of the item being removed
      var index = data['json/custom-object'];

      // Only adjust responses if this was removed from the drop targets
      if (index < $scope.responses.length) {
        // Remove this from the responses
        $scope.responses[index] = undefined;

        // Update the selection list at the bottom of the screen
        updateChoiceStates();

        // Need to hide the submit button
        $scope.answeredAll = false;
      }
    }
  };

		$scope.onDragOver = function(event)
		{
			if (!$scope.editing)
				event.dataTransfer.dropEffect = "none";
		};

		$scope.didSubmit = function(savingElement) {
			if (!$scope.selectedRespondent || !$scope.options || !context || !element) {
			  return;
			}

			// Save the answers to the server
			saveAnswers(savingElement);

			// Adjust the flag to hide the submit button
			$scope.answeredAll = false;

			// Disable editing
			if (!$scope.options.quiz) {
			  $scope.editing = false;
			}
		};

		function saveAnswers(savingElement) {
      if ($scope.selectedRespondent == CorrectResponseId.CORRECTANSWERS) {
        $scope.selectedRespondent = context.userId;
      }

      let groupId = $scope.options.context.getPostingGroupId($scope.isUsingSmallGroups());
			var userId = context.userId;

      // post the responses to the server
      ElementsRestService.saveUserState($scope.options.context.experienceId, element.id, userId, groupId, $scope.responses,
      function() {
        var answer = { user_id: userId, small_gid: groupId, user_data: $scope.responses };
        // Calls back to quiz letting it know the score has been saved
        if ($scope.scoreSaved) {
          $scope.scoreSaved({ finished: (savingElement && savingElement.finished)});
        }
        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: null});
        }
        updateAnswer(answer);
        respondentHasData($scope.selectedRespondent);
        $scope.isDirty = false;

        // Show status immediately after student submits
        if ($scope.options.context.getStudentFacingFeedback() && $scope.isAssessed && !$scope.options.quiz) {
          $scope.assessmentStatus = checkResponses(answer.user_data);
        }
      },
      function(error) {
        ElementsErrorService.error(error);
      });
		}

		function finishedAnswers()
		{
			var allAnswersProvided = $scope.isDirty && $scope.responses;

			// Make sure the user has filled in all the responses
			if ($scope.responses && $scope.responses.length === $scope.answerCount)
			{
				// Make sure each element has data in it
				var validCount = 0;
				$scope.responses.forEach(function(response) {
					validCount = validCount + 1;

					// if the response is blank then not done
					if (!response || response.length === 0)
						allAnswersProvided = false;
				});

				// If there are not enough valid responses then the user is not done
				if (validCount != $scope.answerCount)
					allAnswersProvided = false;
			}
			else
				allAnswersProvided = false;

			$scope.answeredAll = allAnswersProvided;
		}

		$scope.isUsingSmallGroups = function()
		{
			return SHARE_MODE.isUsingSmallGroups($scope.share);
		};

    $scope.wrapRespondent = function (respondent) {
      var wrappedRespondent = new $scope.Respondent(respondent.id);
      wrappedRespondent.getType = function() {
        return respondent.type;
      };

      wrappedRespondent.isSelected = function () {
        return respondent.id === $scope.selectedRespondent;
      };

      wrappedRespondent.isCheckMark = function() {
        return respondent.showCheckMark;
      };

      wrappedRespondent.select = function () {
        $scope.selectRespondent(respondent.id);
      };

      wrappedRespondent.getOverlay = function getOverlay() {
        if (!respondent.showCheckMark) {
          return $scope.correctOverlay(respondent.id);
        } else {
          return null;
        }
      };

      return wrappedRespondent;
    };

}]);
