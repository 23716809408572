'use strict';
angular.module('xp-element-audio', ['angularWidget', 'client.services'])
  .controller('clientAudioElementCtrl', ['$scope', 'widgetConfig', '$http', '$sce', '$timeout', function ($scope, widgetConfig, $http, $sce, $timeout) {

	// The element is the block of data provided by the source xml
	$scope.options = widgetConfig.getOptions($scope);
	
	$scope.$watch('options', function() {
		var options = $scope.options;
		if (!options.element)
			return;
		
		$timeout(function() {
			// Notify the widget that were are done loading the data
			widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
		});
		
	}, true);
}]);
