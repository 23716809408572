'use strict';

(function() {
var module = angular.module('client.components');

controller.$inject = ['$location', '$scope', 'PermissionConsts', 'UserManagedSubscriptions', '$log',
                      '$window', 'User', 'UserCourses', 'ActiveMode', 'ClassMode', 'SubscriptionMode',
                      'CurriculumMode', 'DistrictAdminMode', 'SchoolAdminMode', 'RealtimeEvents', 'WebSocket',
                      'userPermissions', 'tourService'];

function controller($location, $scope, PermissionConsts, UserManagedSubscriptions, $log,
                      $window, User, UserCourses, ActiveMode, ClassMode, SubscriptionMode,
                      CurriculumMode, DistrictAdminMode, SchoolAdminMode, RealtimeEvents, WebSocket,
                      userPermissions, tourService) {

  var ctrl = this;

  ctrl.$window = $window;
  ctrl.permissions = PermissionConsts;
  ctrl.subscriptions = [];

  ctrl.isActive = function (path) {
    if ($location.search().class_id)
      return parseInt(path.match(/\d+/g), 10) === parseInt($location.search().class_id, 10);

    return $location.path() === path;
  };

  ctrl.getCurrentSubscription = function () {
    return ActiveMode.currentSubscriptionId();
  };

  ctrl.getCurrentDistrict = function() {
    return ActiveMode.currentDistrictId();
  };

  ctrl.getCurrentSchool = function() {
    return ActiveMode.currentSchoolId();
  };

  ctrl.goToSubscription = function(subscriptionId) {
    SubscriptionMode.goToSubscription(subscriptionId);
  };

  ctrl.goToCourse = function(cid) {
    if (tourService.canNavigate("class-list")) {
      ClassMode.goToClass(cid);
    }
  };

  ctrl.goToCurriculum = function() {
    if (tourService.canNavigate("experiences-header")) {
      CurriculumMode.navigateToExperiences();
    }
  };

  ctrl.isCurriculumActive = function() {
    return CurriculumMode.isActiveMode();
  };

  ctrl.isSubscriptionActive = function() {
    return SubscriptionMode.isActiveMode();
  };

  ctrl.isDistrictAdminActive = function() {
    return DistrictAdminMode.isActiveMode();
  };

  ctrl.isSchoolAdminActive = function() {
    return SchoolAdminMode.isActiveMode();
  };

  ctrl.curriculumTitle = function() {
    return CurriculumMode.getTitle();
  };

  ctrl.userIsTeacher = function() {
    return User.getSystemRole() == User.ROLE.TEACHER;
  };

  ctrl.userIsStudent = function() {
    return User.getSystemRole() == User.ROLE.STUDENT;
  };

  ctrl.isDistrictAdmin = function() {
    return userPermissions.hasPermission(ctrl.permissions.ui_show_admin_interface) && ctrl.districts && ctrl.districts.length;
  };

  ctrl.isSchoolAdmin = function() {
    return userPermissions.hasPermission(ctrl.permissions.ui_show_admin_interface) && ctrl.schools && ctrl.schools.length;
  };

  ctrl.getDistrictTitle = function(district) {
    if (ctrl.districts.length > 1) {
      return district.name + " Admin Console";
    } else {
      return "District Admin Console";
    }
  };

  ctrl.getSchoolTitle = function(school) {
    if (ctrl.schools.length > 1) {
      return school.name + " Admin Console";
    } else {
      return "School Admin Console";
    }
  };

  ctrl.goToDistrictAdmin = function(districtId) {
    ActiveMode.navigateToDefaultView(districtId);
  };

  ctrl.goToSchoolAdmin = function() {
    ActiveMode.navigateToDefaultView();
  };

  WebSocket.on(RealtimeEvents.EVENTS.Course.XPNewCourseNotification, newCourseNotificationHandler);
  $scope.$on('$destroy', function () {
    WebSocket.removeListener(RealtimeEvents.EVENTS.Course.XPNewCourseNotification, newCourseNotificationHandler);
  });

  function newCourseNotificationHandler(e) {
    var message = e.detail;
    UserCourses.updateCachedCourses().then(function(courses){
      ctrl.courses = courses || [];
      ClassMode.goToClass(message.record.cid);
    });
  }

  ctrl.$onInit = function() {
    UserCourses.getCourses()
    .then(function(courses){
      ctrl.courses = courses || [];
    });
    UserManagedSubscriptions.getSubscriptions()
    .then(function(subscriptions) {
      ctrl.subscriptions = (subscriptions || []).sort(function (a, b) {
        return (a.sort_order||0) - (b.sort_order||0);
      });
    });
    CurriculumMode.getTitle()
    .then(function(title) {
      ctrl.curriculumTitle = title;
    })
    DistrictAdminMode.getDistricts()
    .then(function(districts) {
      ctrl.districts = districts;
    });
    SchoolAdminMode.getSchools()
    .then(function(schools) {
      ctrl.schools = schools;
    });
    tourService.gotoNextTourStep();
  };
}

module.component('navSidebar', {
  template: require('./navSidebar.jade'),
  controller: controller
});

})();

