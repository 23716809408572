'use strict';

angular.module('client').config(['$logProvider', 'appConfig', function($logProvider, appConfig) {
  var debug = false;
  if (appConfig.ENV === 'development') {
    debug = true;
  }
  if (window.location.search.search('debug=true') > 0) {
    debug = true;
  } else if (window.location.search.search('debug=false') > 0){
    debug = false;
  }
  $logProvider.debugEnabled(debug);
}]);

