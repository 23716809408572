'use strict';
//
/* Controllers */
//

clientControllers.controller('UserInfoCtrl', ['$scope', 'UserInfoProfileFactory','UserInfoDefaultFactory', 'UserCodeFactory', 'User',
								function ($scope, UserInfoProfileFactory,UserInfoDefaultFactory, UserCodeFactory, User) {

	//Instantiate an object to store your scope data in (Best Practices)
	$scope.profile = {};
	var currentUserId = User.getId();

	UserInfoProfileFactory.get({}, {'id': currentUserId},function(response) {
		$scope.profile = response;

		if( User.getSystemRole() == 'TEACHER')
		{
			$scope.system_role_title = 'Exploros Teacher';
			$scope.studentUser = false;

			// Get the teacher code
			UserCodeFactory.get({}, {'id': currentUserId},function(response) {
				// Assign the response INSIDE the callback
				$scope.teacherCode = response;
			});
		}
		else if ( User.getSystemRole() == 'STUDENT')
		{
			$scope.system_role_title = 'Exploros Student';
			$scope.studentUser = true;
		}
	});

	$scope.getUserEmailById = function()
	{
		return $scope.profile.email;
	};
}]);
