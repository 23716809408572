'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = ['$location', 'MasterFactory', 'ExperienceSaveAndInvite', 'ExperienceNavigator', 'User'];

  function controller ($location, MasterFactory, ExperienceSaveAndInvite, ExperienceNavigator, User) {
    var ctrl = this;
    
    ctrl.experiencesByStandardForUnit = function() {
      return ctrl.experiences.filter(function(exp){
        return exp.standards.includes(ctrl.standard) && exp.collection === ctrl.unit.uuid;
      })
    };
    
    ctrl.experiencesByStandardNotInUnit = function() {
      return ctrl.experiences.filter(function(exp){
        return exp.standards.includes(ctrl.standard) && exp.collection !== ctrl.unit.uuid;
      });
    };
    
    ctrl.onPreview = function(experience) {
      var oParams = {};
      MasterFactory.master({}, {'template_id': experience.template_id}, function (response) {
        oParams.name = response.name + " (Preview)";
        oParams.section_name = "Preview of " + response.name;
        oParams.teacher_user_id = User.getId();
        oParams.catalog_item_id = experience.template_id;
        oParams.template_id = experience.template_id;
        // Calculate the start date based on Date and Time
        oParams.starts_at = new Date();
        oParams.starts_at = oParams.starts_at.toString();
        oParams.ends_at = null;
        oParams.status = "DRAFT";
        oParams.description = response.description;
        oParams.notification = response.notification;
        oParams.students = "";
        oParams.small_groups = 0;
        oParams.is_guided = response.is_guided;
        oParams.preview = true;
        oParams.return_url = null;
        oParams.project_id = response.project_id;
        oParams.class_id = null;
        oParams.parents = null;

        ExperienceSaveAndInvite.publishExperience(oParams).then(
          function (oSave)        // Response Handler #1
          {
            var experience_id = oSave.dataResult.id;
            var loc = '/experience/' + experience_id + '/activity/scene/0';
            ExperienceNavigator.setPreview(true);
            ExperienceNavigator.navigateToExperience(loc, "", $location.path());
          });
      });
    };
  }

  module.component('xpStudentLearningExperiences', {
    template: require('./standardsExperiences.jade'),
    controller: controller,
    bindings: {
      experiences: '<',
      standard: '<',
      unit: '<',
      preview: '<'
    }
  });

})();