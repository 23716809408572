'use strict';
(function(){
var module = angular.module('xp-element-spire-pre-post-level-test', ['client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'client.directives', 'xp-element-spire-common']);
module.controller('clientSpirePrePostLevelTestElementCtrl',
  ['$scope', '$log', 'ElementsRestService', 'ElementsErrorService', 'ModalService', 'spire', '$timeout',
function ($scope, $log, ElementsRestService, ElementsErrorService, ModalService, spire, $timeout) {
  spire.configureScope($scope, parseElement, displayUserResponses, isResponseModified, "pre/post level test");

  var wordsModel = new spire.ReadingModel();
  $scope.wordsModel = wordsModel;
  var sentencesModel = new spire.ReadingModel();
  $scope.sentencesModel = sentencesModel;
  var timedReadingModel = new spire.TimedReadingModel();
  $scope.timedReadingModel = timedReadingModel;
  var questionsModel = new spire.ReadingModel();
  $scope.questionsModel = questionsModel;
  $scope.accordionState = {};
  $scope.BREAK_PHRASE = spire.BREAK_PHRASE;

  function resetAccordion()
  {
    $scope.accordionState.words = false;
    $scope.accordionState.sentences = false;
    $scope.accordionState.timedReading = false;
    $scope.accordionState.questions = false;
  }

  resetAccordion();

  $scope.$watch('accordionState.timedReading', function (value) {
    $timeout(function () {
      $scope.showTimer = value;
    });
  });

  function parseElement()
  {
    var element = $scope.options.element;
    var options = [];

    element.config.attributes.forEach(function(attribute)
      {
        var name = attribute.name;
        var value = attribute.value;
        switch (name)
        {
          case "words":
            wordsModel.setPhrases(spire.parseWords(value));
            break;
          case "sentences":
            if (!(value instanceof Array))
            {
              value =
                [
                  {
                    name: "sentence",
                    value: value.sentence
                  }
                ];
            }
            parseSentences(value, sentencesModel);
            $scope.sentences = value;
            break;
          case "comprehension_questions":
            if (!(value instanceof Array))
            {
              value =
                [
                  {
                    name: "comprehension_question",
                    value: value.question
                  }
                ];
            }
            $scope.questions = parseQuestions(value, questionsModel);
            break;
          case "passage":
            parsePassage(value, timedReadingModel);
            $scope.passage = value;
            break;
        }
      });
  }

  function parseSentences(sentences, model)
  {
    var phrases = [];
    var index = 1;
    if (!angular.isUndefined(sentences) && sentences !== null)
    {
      sentences.forEach(function(sentence){
        phrases.push(new spire.Phrase("" + index, true));
        ++index;
      });
    }

    model.setPhrases(phrases);
  }

  function QuestionSection(text, type)
  {
    this.text = text;
    this.type = type;
  }

  function parseQuestions(questions, model)
  {
    var phrases = [];
    var nq = [];
    if (!angular.isUndefined(questions) && questions !== null)
    {
      questions.forEach(function(questionObj, index){
        var question = questionObj.value;
        var sections = [];
        var openMode = true;
        var start = 0;
        var end = question.indexOf('(', start);

        while (end != -1)
        {
          sections.push(new QuestionSection(question.substring(start, end + (!openMode)), openMode ? 'normal' : 'answer'));
          openMode = !openMode;
          start = end + openMode;
          if (start >= question.length)
          {
            break;
          }
          end = question.indexOf(openMode ? '(' : ')', start);
        }

        if (start < question.length)
        {
          sections.push(new QuestionSection(question.substring(start), openMode ? 'normal' : 'answer'));
        }

        nq.push(sections);

        phrases.push(new spire.Phrase("" + (index + 1), true));
      });
    }

    model.setPhrases(phrases);
    return nq;
  }

  function parsePassage(passage, model)
  {
    var phrases = [];
    var passageSentences = [];
    if (passage && passage.split)
    {
      var sentenceSplit = passage.replace("\r\n", "\n").split(/[\r\n]/);
      sentenceSplit.forEach(function(sentence){
        var split = sentence.split(/\s+/);
        split.forEach(function(value){
          var text = value.trim();
          if (text.length > 0)
          {
            var phrase = new spire.Phrase(text, true);
            phrases.push(phrase);
          }
        });
        if (phrases.length > 0 && phrases[phrases.length - 1] == spire.BREAK_PHRASE)
        {
          phrases.push(new spire.Phrase("", false));
        }
        phrases.push(spire.BREAK_PHRASE);
      });
    }

    model.setPhrases(phrases);
  }

  function clearUserResponses()
  {
    wordsModel.setResponse(null);
    sentencesModel.setResponse(null);
    questionsModel.setResponse(null);
    timedReadingModel.clearResponse();
    $scope.comment.text = '';
    resetAccordion();
  }

  function displayUserResponses(user)
  {
    clearUserResponses();
    var response = null;

    if (!angular.isUndefined(user) && user !== null)
    {
      response = $scope.studentResponses[user];
      if (angular.isUndefined(response) || response === null)
      {
        setEditing(!$scope.options.context.getViewingInactiveExperience());
      }
      else
      {
        setEditing(false);
        wordsModel.setResponse(response.words);
        sentencesModel.setResponse(response.sentences);
        questionsModel.setResponse(response.comprehension_questions);
        timedReadingModel.setResponse(response.passage);
        $scope.comment.text = response.comment;
      }
    }
    else
    {
      setEditing(false);
    }
  }

  function setEditing(value)
  {
    wordsModel.setEditing(value);
    sentencesModel.setEditing(value);
    questionsModel.setEditing(value);
    timedReadingModel.setEditing(value);
  }

  function isEditing()
  {
    return timedReadingModel.isEditing();
  }

  $scope.isEditing = isEditing;

  $scope.getEditMenuItems = function()
  {
    var editWordsOption =
    {
        text: '<div class="xp-element-menu-edit">Edit</div>',
        click: 'requestEdit()'
    };

    var editLastWordOption =
    {
      text: '<div class="xp-element-menu-edit">Edit Last Word</div>',
      click: 'requestEditLastWord()'
    };

    var menuOptions =
    [
      editWordsOption,
      ModalService.divider,
      {divider: true},
      editLastWordOption,
      {divider: true},
      {
        text: '<div class="xp-element-menu-delete">Delete</div>',
        click: 'requestDelete()'
      }
    ];

    if (isEditing())
    {
      var editOption = isMarkingLast() ? editWordsOption : editLastWordOption;

      menuOptions =
      [
        editOption,
        {divider: true},
        {
          text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
          click: 'cancelEdit()'
        }
      ];
    }

    return menuOptions;
  };

  $scope.requestEdit = function()
  {
    setEditing(true);
    setMarkingLast(false);
  };

  $scope.requestEditLastWord = function()
  {
    setEditing(false);
    timedReadingModel.setEditing(true);
    setMarkingLast(true);
  };

  $scope.cancelEdit = function()
  {
    if (isResponseModified())
    {
      $scope.displayChangeWarning($scope.selectedStudent);
    }
    else
    {
      setEditing(false);
      setMarkingLast(false);
    }
  };

  $scope.didSubmit = function()
  {
    if ($scope.selectedStudent === null || !$scope.isTeacher || !$scope.canSubmit())
      return;

    var response = {
        "words": wordsModel.getResponse(),
        "sentences": sentencesModel.getResponse(),
        "comprehension_questions": questionsModel.getResponse(),
        "passage": timedReadingModel.getResponse(),
        "comment": $scope.comment.text
    };

    ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.selectedStudent, -1, JSON.stringify(response),
      function() {
        wordsModel.setResponse(response.words);
        sentencesModel.setResponse(response.sentences);
        questionsModel.setResponse(response.questions);
        timedReadingModel.setResponse(response.passage);
        setEditing(false);
        setMarkingLast(false);
      },
      function(error) {
        ElementsErrorService.error(error);
      });
  };

  function isResponseModified()
  {
    return wordsModel.isModified() || sentencesModel.isModified() || questionsModel.isModified() || timedReadingModel.isModified() || isCommentModified();
  }

  function isCommentModified()
  {
    var selectedStudent = $scope.selectedStudent;
    if (selectedStudent !== null)
    {
      var response = $scope.studentResponses[selectedStudent];
      if (response)
      {
        return $scope.comment.text != response.comment;
      }
    }

    return $scope.comment.text.length > 0;
  }

  $scope.canSubmit = function()
  {
    return $scope.isEditing() && timedReadingModel.getWordsPerMinute() !== null;
  };

  function isMarkingLast()
  {
    return timedReadingModel.isMarkingLastPhrase();
  }

  function setMarkingLast(value)
  {
    timedReadingModel.setMarkingLastPhrase(value);
  }
}]);
})();
