'use strict';
angular.module('xp-element-HTMLteacher_gate', ['angularWidget', 'client.services'])
  .controller('clientHTMLTeacherGateElementCtrl', ['$scope', 'widgetConfig', 'ElementsRestService', 'ElementsErrorService', '$sce', '$timeout', '$log',
    function ($scope, widgetConfig, ElementsRestService, ElementsErrorService, $sce, $timeout, $log) {
      $scope.options = widgetConfig.getOptions($scope);
      $scope.gate = true;

      var DISPLAY_MODE = Object.freeze({kTeacherMode: 0, kStudentMode: 1});
      $scope.DISPLAY_MODE = DISPLAY_MODE;
      var BUTTON_STATE = Object.freeze({NONE: 0, DONE: 1, UNLOCK: 2, UNLOCKED: 3});
      $scope.BUTTON_STATE = BUTTON_STATE;

      $scope.buttonState = BUTTON_STATE.NONE;
      $scope.progress = 0;

      function parseElement() {
        var element = $scope.options.element;
        element.config.attributes.forEach(function (attribute) {
          var name = attribute.name;
          var value = attribute.value;
          switch (name) {
            case "teacher_title" :
              if ($scope.displayMode == DISPLAY_MODE.kTeacherMode) {
                $scope.title = value;
              }
              break;
            case "teacher_text" :
              if ($scope.displayMode == DISPLAY_MODE.kTeacherMode) {
                $scope.text = $sce.trustAsHtml(value);
              }
              break;
            case "student_title" :
              if ($scope.displayMode == DISPLAY_MODE.kStudentMode) {
                $scope.title = value;
              }
              break;
            case "student_text" :
              if ($scope.displayMode == DISPLAY_MODE.kStudentMode) {
                $scope.text = $sce.trustAsHtml(value);
              }
              break;
          }
        });
      }

      var element;
      var context;

      $scope.$watch('options', function (val) {
        var options = val;
        if (!options.element)
          return;

        element = options.element;
        context = options.context;

        // If past experience then always show open gate.  otherwise, base this on the progress
        $scope.progress = context.getViewingInactiveExperience() ? 1 : options.element.progress;

        $scope.displayMode = context.userIsTeacher() ? $scope.DISPLAY_MODE.kTeacherMode : $scope.DISPLAY_MODE.kStudentMode;

        parseElement();

        getUserState(context.experienceId, element.id, context.userId, function (userData, error) {
          if (!error && userData) {
            $scope.buttonState = userData.hasOwnProperty('unlocked') && userData.unlocked ? BUTTON_STATE.UNLOCKED : BUTTON_STATE.UNLOCK;
            $scope.collapsed = userData.hasOwnProperty('collapsed') ? userData.collapsed : true;
          }
          else {
            ElementsErrorService.error(error);
          }
        });

        // Notify the widget that we are done loading the data
        $timeout(function () {
          widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
        });

      }, true);

      function extractAndParserUserData(state) {
        if(!state){
          return {
            unlocked: false
          };
        }
        // for gates
        if(!state.user_data){
          return {
            unlocked: false
          };
        }
        if(state.user_data === 'unlocked'){
          return {
            unlocked: true
          };
        }
        try {
          return JSON.parse(state.user_data) || {};
        }
        catch (e) {
          $log.error("error in parsing user state for html teacher gate:",state);
          return {};
        }
      }


      function getUserState(experienceId, elementId, userId, callback) {
        var userData = {
          unlocked: false,
          collapsed: true
        };

        if ($scope.displayMode != $scope.DISPLAY_MODE.kTeacherMode) {
          return callback(userData);
        }
        if ($scope.options.context.getViewingInactiveExperience()){
          userData = {
            unlocked: true,
            collapsed: false
          };
          return callback(userData);
        }

        ElementsRestService.getUserState(experienceId, elementId, userId, function (result, error) {
          if(error){
            $log.error("error in getting user state for html teacher gate:",error);
          }
          userData = extractAndParserUserData(result);

          callback(userData, error);
        });
      }

      function unlock(experienceId, elementId, userId, callback, error) {
        getUserState(experienceId, element.id, context.userId, function (userData, error) {
          if (!error && userData) {
            //Set the "unlocked" property in the user_data field
            userData.unlocked = true;
            ElementsRestService.saveUserState(experienceId, elementId, userId, 0, JSON.stringify(userData), callback, error);
          }
          else {
            ElementsErrorService.error(error);
          }
        });
      }

      $scope.onCollapse = function (collapsed) {
        var options = $scope.options;
        element = options.element;
        context = options.context;

        getUserState(context.experienceId, element.id, context.userId, function (userData, error) {
          //Set the "collapsed" property in the user_data field
          userData.collapsed = collapsed;

          if (!error && userData) {
            ElementsRestService.saveUserState(context.experienceId, element.id, context.userId, 0, JSON.stringify(userData), function (res) {
            }, function (error) {
              ElementsErrorService.error(error);
            });
          }
          else {
            ElementsErrorService.error(error);
          }
        });
      };

      $scope.unlock = function () {
        unlock(context.experienceId, element.id, context.userId, function (result) {
          $scope.buttonState = BUTTON_STATE.UNLOCKED;
        }, function (error) {
          ElementsErrorService.error(error);
        });
      };
    }]);
