'use strict';

clientControllers.controller('CourseSettingsCtrl', ['$scope', '$controller', '$q', '$timeout', '$log', '$rootScope', '$location', 'User', 'CourseAPI',
                                                    'CourseFactory', 'CourseModalFactory', 'UserCodeFactory', 'ModalNavigation', 'userClassPermissions',
                                                    'ActiveMode', 'ModalService', 'xpAlert', 'UserDefaultInfo', 'canvasService', 'schoologyService',
  function ($scope, $controller, $q, $timeout, $log, $rootScope, $location, User, CourseAPI,
            CourseFactory, CourseModalFactory, UserCodeFactory, ModalNavigation, userClassPermissions,
            ActiveMode, ModalService, xpAlert, UserDefaultInfo, canvasService, schoologyService) {

    $scope.addStudentsToCourse = function () {
      ModalNavigation.navigateToModal("/class/" + ActiveMode.currentClassId() + "/roster", $location.path());
    };

    $scope.courseSettings = function () {
      CourseFactory.get(ActiveMode.currentClassId()).then(function (currentCourse) {
        CourseModalFactory.settings(currentCourse)
          .then(function (response) {
            var course = response.course;
            var action = response.action;
            var remove_students = response.remove_students || false;
            var settings = {
                canvasPostAssignments: course.canvasPostAssignments,
                canvasPostGrades: course.canvasPostGrades,
                googlePostAssignments: course.googlePostAssignments,
                googlePostGrades: course.googlePostGrades,
                schoologyPostAssignments: course.schoologyPostAssignments,
                schoologyPostGrades: course.schoologyPostGrades
            };

            switch (action) {
              case 'update':
                return updateCourse(course.cid, course.class_name, course.grade, course.subject, course.licenses, settings);
              case 'archive':
                return archiveCourse(course.cid, remove_students);
              case 'linkCanvas':
                var canvasCourseId = response.canvasCourse.course_id;
                var canvasSectionId = response.canvasCourse.section_id;
                var canvasClassName = response.canvasCourse.name;
                return linkCanvasCourse(course.cid, canvasClassName, canvasCourseId, canvasSectionId);
              case 'linkSchoology':
                var schoologyCourseId = response.schoologyCourse.course_id;
                var schoologySectionId = parseInt(response.schoologyCourse.section_id, 10);
                var schoologyClassName = response.schoologyCourse.name;
                return linkSchoologyCourse(course.cid, schoologyClassName, schoologyCourseId, schoologySectionId);
              default:
                return updateCourse(course.cid, course.class_name, course.grade, course.subject, course.licenses, settings);
            }

          }).catch(function (error) {
          $log.error(error);
        });
      }).catch(function (error) {
        $log.error(error);
      });
    };

    $scope.manageSubscriptionLicenses = function () {
      if (ActiveMode.currentSubscriptionId()) {
        ModalNavigation.navigateToModal(
          '/subscription/' + ActiveMode.currentSubscriptionId() + '/license',
          $location.path());
      }
    };

    $scope.viewSchoolLicenses = function () {
      if (ActiveMode.currentSubscriptionId()) {
        ModalNavigation.navigateToModal(
          '/subscription/' + ActiveMode.currentSubscriptionId() + '/license/schools',
          $location.path());
      }
    };

    $scope.importSubscriptionUsers = function () {
      if (ActiveMode.currentSubscriptionId()) {
        ModalNavigation.navigateToModal(
          '/subscription/' + ActiveMode.currentSubscriptionId() + '/import',
          $location.path());
      }
    };

    $scope.districtLicenses = function () {
      if (ActiveMode.currentDistrictId()) {
        ModalNavigation.navigateToModal(
          '/district/' + ActiveMode.currentDistrictId() + '/license',
          $location.path());
      }
    };

    function updateCourse(id, group_name, grade, subject, licenses, settings) {
      var d = $q.defer();

      var params = {
        cid: id,
        class_name: group_name,
        grade: grade,
        subject: subject,
        licenses: licenses,
        settings: settings
      };

      CourseFactory.update(params)
        .then(function (response) {
          if (response.error) {
            ModalService.show({
              message: response.error.description,
              backdrop: 'static',
              buttons: [
                {
                  title: 'Ok',
                  click: '$hide();'
                }
              ]
            });
          }
          // Clear and reget the permissions in case the licensing changed
          userClassPermissions.resetPermissions(User.getId(), id);
          d.resolve(response);
        });

      return d.promise;
    }

    function archiveCourse(id, remove_students) {
      return CourseFactory.archive(id, remove_students)
        .then(function (response) {
          ActiveMode.navigateToDefaultView();
          return response;
        });
    }

    function linkCanvasCourse(id, canvasName, canvasCourseId, canvasSectionId) {
      return UserDefaultInfo.getUserInfo().then(function (userInfo) {
        var settings = {
            canvasPostAssignments: true
        };
        return canvasService.linkCourse(userInfo, id, canvasName, canvasCourseId, canvasSectionId, settings)
        .then(function (course) {
          if (course) {
            var postFix = course.added == 1 ? " student!" : " students!";
            if (course.failed) {
              postFix = postFix + " " + course.failed + " students couldn't be added.";
            }
            if (course.failedDomain) {
              postFix = postFix + " " + course.failedDomain + " students couldn't be added due to invalid e-mail domains.";
            }
            if (!course.sufficientSeats) {
              postFix = postFix + " However, you exceeded your seat license limit. <a href=\"http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers\" target=blank> Learn more.</a>";
            }
            xpAlert.success("You successfully linked a Canvas Class with " + course.added + postFix, true);
            return course;
          } else {
            xpAlert.error("An error occurred linking this class to Canvas.", true);
            return null;
          }
        });
      });
    }

    function linkSchoologyCourse(id, name, courseId, sectionId) {
      return UserDefaultInfo.getUserInfo().then(function (userInfo) {
        var settings = {
            schoologyPostAssignments: true
        };
        return schoologyService.linkCourse(userInfo, id, name, courseId, sectionId, settings)
        .then(function (course) {
          if (course) {
            var postFix = course.added == 1 ? " student!" : " students!";
            if (course.failed) {
              postFix = postFix + " " + course.failed + " students couldn't be added.";
            }
            if (course.failedDomain) {
              postFix = postFix + " " + course.failedDomain + " students couldn't be added due to invalid e-mail domains.";
            }
            if (!course.sufficientSeats) {
              postFix = postFix + " However, you exceeded your seat license limit. <a href=\"http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers\" target=blank> Learn more.</a>";
            }
            xpAlert.success("You successfully linked a Schoology Class with " + course.added + postFix, true);
            return course;
          } else {
            xpAlert.error("An error occurred linking this class to Schoology.", true);
            return null;
          }
        });
      });
    }

    function getUserCode() {
      var d = $q.defer();
      UserCodeFactory.get({}, {'id': User.getId()}).$promise
        .then(function (response) {
          d.resolve(response.code);
        });
      return d.promise;
    }
  }
]);
