'use strict';

angular.module('client.services').factory('canvasService', ['$resource', '$q', 'CourseFactory', '$log', 'User', 'api_server',
  function ($resource, $q, CourseFactory, $log, User, api_server) {

    var imsResource = $resource(api_server + '/users/:id/ims', {id: '@id'},
      {
        'get': {method: 'GET', isArray: true}
      });

    function getIms(id) {
      return imsResource.get({id: id}).$promise;
    }

    var coursesResource = $resource(api_server + '/ims/courses/list', {ims_id: '@ims_id'},
      {
        'get': {method: 'GET', isArray: true}
      });

    function getCourses(ims_id) {
      return coursesResource.get({ims_id: ims_id}).$promise;
    }

    var studentsResource = $resource(api_server + '/ims/courses/:id/students', {
        id: '@id',
        ims_id: '@ims_id',
        section_id: '@section_id'
      },
      {
        'get': {method: 'GET', isArray: true}
      });

    function getStudents(ims_id, section_id, id) {
      return studentsResource.get({id: id, ims_id: ims_id, section_id: section_id}).$promise;
    }

    var courseResource = $resource(api_server + '/ims/courses/course/:id', {id: '@id'},
      {
        'get': {method: 'GET'}
      });

    function getCourse(id) {
      return courseResource.get({id: id}).$promise;
    }

    var importResource = $resource(api_server + '/ims/courses/import/:id', {id: '@id'},
      {
        'post': {method: 'POST'}
      });

    function importCourse(id, params) {
      return importResource.post({id: id}, params).$promise;
    }

    var syncResource = $resource(api_server + '/ims/courses/sync', {ims_id: '@ims_id'},
      {
        'post': {method: 'POST'}
      });

    function syncCourse(ims_id, id) {
      return syncResource.post({ims_id: ims_id}, {cid: id}).$promise;
    }

    var assignmentResource = $resource(api_server + '/ims/assignment/:experience_id', {
        experience_id: '@experience_id',
        ims_id: '@ims_id'
      },
      {
        'post': {method: 'POST'}
      });

    function postAssignment(ims_id, experience_id) {
      return assignmentResource.post({experience_id: experience_id, ims_id: ims_id}).$promise;
    }

    function getAvailableCourses(user) {
      var canvasClasses = {
        courses: [],
        isCanvasClassroomUser: false,
        lms_id: 0
      };

      // if this user is NOT a canvas user then immediately return an empty list
      if (!user.canvas_user) {
        return $q.when(canvasClasses);
      }

      return getIms(user.id).then(function (response) {
        // If the current user is not an IMS(canvas) user then return empty list
        if (!response || !response.length) {
          return canvasClasses;
        }

        var imsUser = response.find(function (res) {
          return res.int_id > 0;
        });
        if (!imsUser) {
          return canvasClasses;
        }

        canvasClasses.lms_id = imsUser.int_id;

        return getCourses(imsUser.int_id).then(function (canvasCourses) {
          if (!canvasCourses || canvasCourses.length === 0) {
            return canvasClasses;
          }

          // This user is a canvas classroom user since they returned a valid list of at least one classroom
          canvasClasses.isCanvasClassroomUser = true;

          // Now lookup this teachers existing classes so we can remove duplicates
          var params = {teacher_user_id: User.getId()};
          return CourseFactory.list(params).then(function (courses) {
            // Remove any canvas classes that have already been added
            canvasClasses.courses = canvasCourses.filter(function (canvasCourse) {
              var imported = false;
              courses.forEach(function (course) {
                // Get the current classroom ID from the data google returned to us
                var course_id = 0;
                var section_id = null;
                try {
                  var settings = angular.fromJson(course.settings) || {};
                  if (settings && settings.ims && settings.ims.classroom) {
                    course_id = settings.ims.classroom;
                    if (settings.ims.section_id) {
                      section_id = settings.ims.section_id;
                    }
                  }
                }
                catch (e) {
                  $log.error(e.message);
                }
                // if either the name or ID match then consider it a matching class
                if ((canvasCourse.course_id === course_id && canvasCourse.section_id === section_id) ||
                  canvasCourse.name === course.class_name) {
                  imported = true;
                }
              });
              return !imported;
            });
            return canvasClasses;
          }, function (error) {
            $log.error("canvas API error:", error);
            $q.reject(error);
          });
        });
      })
        .catch(function (error) {
          $log.error("get classroom API error:", error);
          $q.resolve(canvasClasses);
        });
    }

    var linkResource = $resource(api_server + '/ims/courses/link', {ims_id: '@ims_id'},
      {
        'post': {method: 'POST'}
      });

    function linkCourse(user, cid, className, courseId, sectionId, settings) {
      return getIms(user.id).then(function (response) {
        if (!response || !response.length) {
          return null;
        }
        var imsUser = response.find(function (res) {
          return res.int_id > 0;
        });
        if (!imsUser) {
          return null;
        }
        return linkResource.post({ims_id: imsUser.int_id}, {
          cid: cid,
          className: className,
          courseId: courseId,
          sectionId: sectionId,
          settings: settings
        }).$promise;
      });
    }

    return {
      getCourses: getCourses,
      getStudents: getStudents,
      getCourse: getCourse,
      importCourse: importCourse,
      syncCourse: syncCourse,
      postAssignment: postAssignment,
      getAvailableCourses: getAvailableCourses,
      linkCourse: linkCourse
    };
  }]);
