'use strict';

(function () {

  function abbreviateNumber(num) {
    num = Number(num) || 0;
    if (num < 1000) {
      return '' + num;
    }
    var power = ((num).toPrecision(2).split("e")[1] || [0, 0]).slice(1);
    var triplets = Math.floor(power / 3);
    var numericResult = (num / Math.pow(10, triplets * 3)).toFixed(1);
    if (numericResult.length > 4) {
      numericResult = (num / Math.pow(10, triplets * 3)).toFixed();
    }
    return numericResult + ['', 'K', 'M', 'B', 'T'][triplets];
  }

  function getFieldName(row, baseField) {
    return 'ex_' + row.experience_id + '_' + baseField;
  }

  function formatEndDate(endDate) {
    var d = new Date(endDate);
    var date = ("0" + (d.getMonth() + 1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2);
    return date;
  }

  function formatExportDate(endDate) {
    var d = new Date(endDate);
    var date = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
    return date;
  }

  function renderPercComplete(val) {
    return val + '%';
  }

  function renderAvgResp(val) {
    return val ? val : '-';
  }

  function renderQuizScore(val) {
    if (val || val === 0) {
      return val + '%';
    } else {
      return '-';
    }
  }

  function getCustomElementBackgroundColor(columnName, value) {
    if (!columnName) {
      return;
    }
    var numValue = parseFloat(value);
    var percentage = 0;
    if (columnName.indexOf('responses_per_student') > -1) {
      if (!numValue) {
        return '#ffffff';
      }
      return 'rgba(253, 180, 147,' + numValue + ')';

    }
    if (columnName.indexOf('quiz') > -1) {
      if (isNaN(numValue)) {
        return '#ffffff';
      } else if (numValue > 49.5 && numValue < 50.5) {
        return '#ffffff';
      } else if (numValue < 50) {
        return 'rgba(230, 129, 114, ' + (1 - numValue * 0.02) + ')';
      } else if (numValue > 50) {
        return 'rgba(160, 223, 253, ' + (numValue * 0.02 - 1) + ')';
      }
    }
    if (columnName.indexOf('percentage_completed') > -1) {
      if (!(numValue)) {
        return '#ffffff';
      } else {
        return 'rgba(169, 169, 169, ' + numValue * 0.01 + ')';
      }
    }
    if (columnName.indexOf('time_on_task') > -1) {
      if (!numValue) {
        return '#ffffff';
      } else {
        // max value here is 60 minutes
        percentage = (numValue / (60 / 100));
        if (percentage > 99) {
          percentage = 100;
        }
        return 'rgba(245, 211, 40, ' + percentage * 0.01 + ')';
      }
    }
    return '';
  }

  function getTimeOnTask(timeInSeconds) {
    var value = Math.round((timeInSeconds || 0) / 60);
    return value ? abbreviateNumber(value) : '-';
  }

  function formatRecordsByStudents(classRecords) {
    var byStdId = {};
    classRecords.forEach(function (row) {
      var id = row.student_id;
      if (!byStdId[id]) {
        byStdId[id] = {
          prettyName: row.first_name + ' ' + row.last_name,
          sortName: row.last_name + ' ' + row.first_name,
          student_id: row.student_id,
          class_id: row.class_id
        };
      }
      byStdId[id][getFieldName(row, 'responses_per_student')] = renderAvgResp(row.responses_per_student);
      byStdId[id][getFieldName(row, 'responses_per_student_sort')] = (parseFloat(row.responses_per_student) || 0);
      byStdId[id][getFieldName(row, 'responses_per_student') + '_bg'] = getCustomElementBackgroundColor('responses_per_student', row.responses_completed_percentage);

      byStdId[id][getFieldName(row, 'quiz')] = renderQuizScore(row.quiz);
      byStdId[id][getFieldName(row, 'quiz_sort')] = (parseFloat(row.quiz) || 0);
      byStdId[id][getFieldName(row, 'quiz') + '_bg'] = getCustomElementBackgroundColor('quiz', row.quiz);

      byStdId[id][getFieldName(row, 'percentage_completed')] = renderPercComplete(row.percentage_completed);
      byStdId[id][getFieldName(row, 'percentage_completed_sort')] = (parseFloat(row.percentage_completed) || 0);
      byStdId[id][getFieldName(row, 'percentage_completed') + '_bg'] = getCustomElementBackgroundColor('percentage_completed', row.percentage_completed);

      byStdId[id][getFieldName(row, 'time_on_task')] = getTimeOnTask(row.time_on_task);
      byStdId[id][getFieldName(row, 'time_on_task_sort')] = row.time_on_task || 0;
      byStdId[id][getFieldName(row, 'time_on_task') + '_bg'] = getCustomElementBackgroundColor('time_on_task', getTimeOnTask(row.time_on_task));
    });

    return Object.keys(byStdId).map(function (key) {
      return byStdId[key];
    });
  }

  function formatCSVRecordsByStudents(classRecords, filterColumn) {
    var byStdId = {};
    classRecords.forEach(function (row) {
      var id = row.student_id;
      if (!byStdId[id]) {
        byStdId[id] = {
          prettyName: row.first_name + ' ' + row.last_name
        };
      }

      if (filterColumn === 'responses_per_student') {
        byStdId[id]['ex_' + row.experience_id] = renderAvgResp(row.responses_per_student);
      }
      if (filterColumn === 'percentage_completed') {
        byStdId[id]['ex_' + row.experience_id] = renderPercComplete(row.percentage_completed);
      }
      if (filterColumn === 'quiz') {
        byStdId[id]['ex_' + row.experience_id] = renderQuizScore(row.quiz);
      }
      if (filterColumn === 'time_on_task') {
        byStdId[id]['ex_' + row.experience_id] = getTimeOnTask(row.time_on_task);
      }
    });

    return Object.keys(byStdId).map(function (key) {
      return byStdId[key];
    });
  }

  var module = angular.module('client.components');

  controller.$inject = ['$location', 'ActiveMode', 'ExperienceNavigator', 'NgTableParams', 'ReportNavigation', '$log', 'reportToCSV'];

  function controller($location, ActiveMode, ExperienceNavigator, NgTableParams, ReportNavigation, $log, reportToCSV) {

    var ctrl = this;
    ctrl.isPrinting = false;

    var staticColumns = ['prettyName', 'className'];
    ctrl.getStyleClass = function (columnName) {
      if (!staticColumns || !columnName) {
        return;
      }
      if (staticColumns.indexOf(columnName) > -1) {
        return 'xp-report-medium-cell xp-fixed-report-column';
      } else {
        return 'xp-report-small-box';
      }
    };

    ctrl.getTitlePopup = function getTitlePopup(columnName, data, columnTitle) {
      if (!staticColumns || !columnName) {
        return;
      }
      if (staticColumns.indexOf(columnName) > -1) {
        return columnTitle + ': ' + data;
      } else {
        return columnTitle;
      }
    };

    ctrl.columns = [];
    ctrl.experiencesList = [];
    ctrl.metrics = [
      {name: 'Quiz Score', id: 'quiz'},
      {name: 'Total Responses', id: 'responses_per_student'},
      {name: 'Percent Complete', id: 'percentage_completed'},
      {name: 'Time on Task (min.)', id: 'time_on_task'}
    ];

    function getFilters() {
      var filter = {};
      var query = $location.search();
      filter.class_id = parseInt(query.class_id, 10);
      filter.teacher_id = parseInt(query.teacher_id, 10);
      filter.metric = query.metric;
      return filter;
    }

    ctrl.drillDownFilters = getFilters();

    ctrl.filterMetricName = ctrl.metrics[0].name;
    ctrl.filterMetric = ctrl.metrics[0].id;
    if (ctrl.drillDownFilters.metric) {
      var metric = ctrl.metrics.find(function (mt) {
        return mt.id === ctrl.drillDownFilters.metric;
      });
      if (metric) {
        ctrl.filterMetricName = metric.name;
        ctrl.filterMetric = metric.id;
      }
    }

    ctrl.setMetric = function setMetric(selection) {
      ctrl.filterMetricName = selection.name;
      ctrl.filterMetric = selection.id;
      setColumns(ctrl.experiencesList, selection.id);
    };

    ctrl.reportData = [];
    ctrl.classes = [];
    ctrl.filterClassName = '';
    ctrl.filterClassId = -1;

    ctrl.reportKey = '';

    ctrl.setSelectedClass = function setSelectedClass(selection) {
      if (!selection || !selection.class_id) {
        ctrl.filterClassName = '';
        ctrl.filterClassId = -1;
        return false;
      }
      return updateReportView({class_id: selection.class_id, class_name: selection.class_name});
    };

    ctrl.data = [];
    ctrl.tableParams = new NgTableParams(
      {
        // items per page
        count: 12,
        sorting: {filterDate: "desc"}
      },
      {
        dataset: [],
        // options for page size
        counts: []
      }
    );

    var allLevelsColumns = [
      {
        field: 'prettyName',
        title: 'Student',
        titleName: 'Student',
        filter: {prettyName: 'text'},
        sortable: 'sortName',
        class: 'xp-report-medium-cell xp-fixed-report-column'
      }
    ];

    function setColumns(experiencesList, filterColumn) {
      var columns = [];
      experiencesList.forEach(function (row) {
        if (filterColumn === 'responses_per_student') {
          columns.push({
            field: getFieldName(row, 'responses_per_student'),
            titleName: row.experience_name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-student-progress-header',
            filter: {percentage_completed: 'date'},
            endDate: formatEndDate(row.ends_at),
            experienceId: row.experience_id,
            title: row.experience_name,
            headerTitle: row.experience_name,
            sortable: false
          });
        }
        if (filterColumn === 'percentage_completed') {
          columns.push({
            field: getFieldName(row, 'percentage_completed'),
            titleName: row.experience_name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-student-progress-header',
            filter: {percentage_completed: 'date'},
            endDate: formatEndDate(row.ends_at),
            experienceId: row.experience_id,
            title: row.experience_name,
            headerTitle: row.experience_name,
            sortable: false
          });
        }
        if (filterColumn === 'quiz') {
          columns.push({
            field: getFieldName(row, 'quiz'),
            titleName: row.experience_name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-student-progress-header',
            filter: {responses_per_student: 'date'},
            endDate: formatEndDate(row.ends_at),
            experienceId: row.experience_id,
            title: row.experience_name,
            headerTitle: row.experience_name,
            sortable: false
          });
        }
        if (filterColumn === 'time_on_task') {
          columns.push({
            field: getFieldName(row, 'time_on_task'),
            titleName: row.experience_name,
            class: 'text-center xp-report-tiny-cell',
            headerTemplateURL: 'xp-report-curriculum-student-progress-header',
            filter: {time_on_task: 'date'},
            endDate: formatEndDate(row.ends_at),
            experienceId: row.experience_id,
            title: row.experience_name,
            headerTitle: row.experience_name,
            sortable: getFieldName(row, 'time_on_task_sort')
          });
        }
      });

      ctrl.columns = allLevelsColumns.concat(columns);
    }

    ctrl.downloadCSV = reportToCSV(
      function () {
        var csvColumns = [
          {
            name: 'Student',
            field: 'prettyName',
            wrap: true
          }
        ];
        ctrl.experiencesList.forEach(function (experience) {
          csvColumns.push({
            name: experience.experience_name.replace(',', ''),
            field: 'ex_' + experience.experience_id,
            wrap: true
          });
        });

        return csvColumns;
      },
      function () {
        var csvRows = [{'prettyName': 'End Date'}];
        ctrl.experiencesList.forEach(function (experience) {
          csvRows[0]['ex_' + experience.experience_id] = formatExportDate(experience.ends_at);
        });

        var classRecords = ctrl.reportData.filter(function (rec) {
          return rec.class_id === ctrl.filterClassId;
        });

        csvRows = csvRows.concat(formatCSVRecordsByStudents(classRecords, ctrl.filterMetric));

        return csvRows;
      }, function () {
        return ctrl.filterClassName + ' Student Progress ' + ctrl.filterMetricName + '.csv';
      },
      ','
    );

    function renderReport(experiencesList, filterColumn, data) {
      setColumns(experiencesList, filterColumn);
      ctrl.tableParams.settings({
        dataset: data
      });
    }

    function updateReportView(filter) {
      var uniqClasses = {};
      ctrl.classes = ctrl.reportData
        .filter(function (rec) {
          if (uniqClasses[rec.class_id] || !rec.class_id) {
            return false;
          }
          uniqClasses[rec.class_id] = rec;
          return true;
        })
        .map(function (rec) {
          return {
            name: rec.class_name,
            class_name: rec.class_name,
            class_id: rec.class_id
          };
        });


      if (!ctrl.classes.length) {
        return;
      }

      if (!filter) {
        filter = {
          class_name: ctrl.classes[0].class_name,
          class_id: ctrl.classes[0].class_id
        };
      }

      ctrl.filterClassName = filter.class_name;
      ctrl.filterClassId = filter.class_id;

      var uniqueExpIds = {};
      ctrl.experiencesList = ctrl.reportData
        .filter(function (rec) {
          return rec.class_id === filter.class_id;
        })
        .filter(function (rec) {
          if (uniqueExpIds[rec.experience_id]) {
            return false;
          }
          uniqueExpIds[rec.experience_id] = true;
          return true;
        })
        .sort(function (a, b) {
          return new Date(a.ends_at) - new Date(b.ends_at);
        });

      var classRecords = ctrl.reportData.filter(function (rec) {
        return rec.class_id === filter.class_id;
      });

      renderReport(ctrl.experiencesList, ctrl.filterMetric, formatRecordsByStudents(classRecords));
    }

    function fetchAndUpdateReport() {
      var reportFilter = {};
      if (ctrl.drillDownFilters.teacher_id) {
        reportFilter.teacher_id = ctrl.drillDownFilters.teacher_id;
      }
      return ActiveMode.getReportData(reportFilter).then(function (report) {
        ctrl.reportKey = report.report_key;
        ctrl.reportData = report.data;
        updateReportView();
        return report;
      });
    }

    ctrl.inited = false;
    fetchAndUpdateReport()
      .then(function () {
        ctrl.inited = true;
      })
      .catch(function (error) {
        $log.error("error in updating report:", error);
        ctrl.inited = true;
      });

    ctrl.goToStudentReport = function goToStudentReport(classId, studentId) {
      ReportNavigation.navigateToReport(ctrl.reportKey, $location.path(),
        {
          class_id: classId,
          student_id: studentId,
          drilldown: 'student-summary',
          teacher_id: ctrl.drillDownFilters.teacher_id
        },
        $location.path(),
        {
          class_id: classId,
          teacher_id: ctrl.drillDownFilters.teacher_id,
          metric: ctrl.drillDownFilters.metric
        });
    };
    ctrl.goToPastExperience = function (expId) {
      var expUrl = '/experience/' + expId + '/dashboard/responses';
      ExperienceNavigator.navigateToExperience(expUrl, "", $location.path());
    };

  }

  module.component('studentProgress', {
    template: require('./studentProgressReport.jade'),
    controller: controller
  });

})();
