'use strict';
(function () {

  clientControllers.controller('ClassPackCtrl', ['$scope', '$rootScope', '$location', 'classIdService', 'CourseFactory',
    'userClassPermissions', 'PermissionConsts',
    function ($scope, $rootScope, $location, classIdService, CourseFactory,
              userClassPermissions, PermissionConsts) {
      // Get the class to see if/which license this class has
      $scope.packItems = [];
      $scope.permissions = PermissionConsts;

      var spirePackItems =
            [
              {
                name: 'Welcome to iSPIRE',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/Welcome_Pack.pdf'
              },
              {
                name: 'Getting Started with iSPIRE',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/Getting+Started+with+iSPIRE_Pack.pdf'
              },
              {
                name: 'iSPIRE Lesson Flow and Tips',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/iSPIRE_LessonFlowandTips_Pack.pdf'
              },
              {
                name: 'iSPIRE Tutorials',
                link: 'http://ispire.com/tutorials'
              },
              {
                name: 'iSPIRE Placement Test',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/iSPIRE_Placement_Test_Pack.pdf'
              },
              {
                name: 'iSPIRE Lesson Planner',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/iSPIRE_Lesson_Planner_Pack.pdf'
              },

              {
                name: 'Decodable Reader Overview',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/SPIRE_DecodableReader.pdf'
              },
              {
                name: 'Master Word Card List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/Master_Word _Card _List.pdf'
              },
              {
                name: 'Level 1 Reproducibles',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L1_Repros_pack.pdf'
              },
              {
                name: 'Level 1 Key Words and Pictures',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L1_KeyWords_Pack.pdf'
              },
              {
                name: 'Level 1 Concept Mastery Fluency Drills',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/1_CMFD_Pack.pdf'
              },
              {
                name: 'Level 2 Reproducibles',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L2_Repros_pack.pdf'
              },
              {
                name: 'Level 2 Key Words and Pictures',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L2_KeyWords_Pack.pdf'
              },
              {
                name: 'Level 2 Concept Mastery Fluency Drills',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/2_CMFD_Pack.pdf'
              },
              {
                name: 'Level 3 Reproducibles',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L3_Repros_pack.pdf'
              },
              {
                name: 'Level 3 Key Words and Pictures',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L3_KeyWords_Pack.pdf'
              },
              {
                name: 'Level 3 Concept Mastery Fluency Drills',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/3_CMFD_Pack.pdf'
              },
              {
                name: 'Level 4 Reproducibles',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L4_Repros_pack.pdf'
              },
              {
                name: 'Level 4 Key Words and Pictures',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L4_KeyWords_Pack.pdf'
              },
              {
                name: 'Level 4 Concept Mastery Fluency Drills',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/4_CMFD_Pack.pdf'
              },
              {
                name: 'Level 5 Reproducibles',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L5_Repros_pack.pdf'
              },
              {
                name: 'Level 5 Key Words and Pictures',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L5_KeyWords_Pack.pdf'
              },
              {
                name: 'Level 5 Concept Mastery Fluency Drills',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/5_CMFD_Pack.pdf'
              },
              {
                name: 'Level 6 Reproducibles',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L6_Repros_pack.pdf'
              },
              {
                name: 'Level 6 Key Words and Pictures',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/L6_KeyWords_Pack.pdf'
              },
              {
                name: 'Level 6 Concept Mastery Fluency Drills',
                link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/6_CMFD_Pack.pdf'
              },
              {
                name: 'Privacy Policy',
                link: 'https://www.exploros.com/privacy/'
              }
            ];

      var wwPackItems =
            [
              {
                name: 'Welcome to Wordly Wise i3000',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/Welcome+to+i3000.pdf'
              },
              {
                name: 'Getting Started with Wordly Wise i3000',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/Getting+Started+with+WWi3000.pdf'
              },
              {
                name: 'Wordly Wise i3000 Lesson Flow and Tips',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lesson+Flow+and+Tips.pdf'
              },
              {
                name: 'Wordly Wise i3000 Tutorials',
                link: 'http://www.wordlywise3000.com/tutorials'
              },
              {
                name: 'Overview of Wordly Wise i3000 Practice Activities',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/Practice+Activity+Overview.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 2 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev2_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 3 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev3_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 4 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev4_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 5 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev5_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 6 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev6_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 7 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev7_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 8 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev8_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 9 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev9_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 10 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev10_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 11 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev11_WordList.pdf'
              },
              {
                name: 'Wordly Wise i3000 Level 12 Word List',
                link: 'https://s3.amazonaws.com/exploros-shared-media/WordlyWise/Pack/i3000_Lev12_WordList.pdf'
              },
              {
                name: 'Privacy Policy',
                link: 'https://www.exploros.com/privacy/'
              }
            ];

            var spireHybridPackItems =
                  [
                    {
                      name: 'Welcome to S.P.I.R.E.',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/SPIRE_Welcome_Pack.pdf'
                    },
                    {
                      name: 'S.P.I.R.E. STAR Tutorials',
                      link: 'http://spire-star.com/tutorials/'
                    },
                    {
                      name: 'Getting Started with S.P.I.R.E. STAR',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/EPS_Getting_Started_with_SPIRE.pdf'
                    },
                    {
                      name: 'Using S.P.I.R.E. STAR',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/SPIRE_UsingSTAR_Pack.pdf'
                    },
                    {
                      name: 'S.P.I.R.E. Placement Test',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/SPIRE_Placement_Test_Pack.pdf'
                    },
                    {
                      name: 'S.P.I.R.E. Lesson Planner',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/SPIRE_Lesson_Planner_Pack.pdf'
                    },
                    {
                      name: 'Decodable Reader Overview',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/SPIRE_DecodableReader.pdf'
                    },
                    {
                      name: 'Master Word Card List',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/iSPIRE/Pack/Master_Word _Card _List.pdf'
                    },

                    {
                      name: 'Level 1 Miscellaneous Reproducibles',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/1_SPIRE_Reproducibles_Pack.pdf'
                    },
                    {
                      name: 'Level 1 Key Words and Pictures',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/1_SPIRE_KeyWords_Pack.pdf'
                    },

                    {
                      name: 'Level 1 Closed Syllable Lesson',
                      link: 'https://exploros-shared-media.s3.amazonaws.com/SPIRE/Pack/Level_1_Closed_Syllable_Lesson.pdf'
                    },

                    {
                      name: 'Level 2 Miscellaneous Reproducibles',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/2_SPIRE_Reproducibles_Pack.pdf'
                    },
                    {
                      name: 'Level 2 Key Words and Pictures',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/2_SPIRE_KeyWords_Pack.pdf'
                    },

                    {
                      name: 'Level 3 Miscellaneous Reproducibles',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/3_SPIRE_Reproducibles_Pack.pdf'
                    },
                    {
                      name: 'Level 3 Key Words and Pictures',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/3_SPIRE_KeyWords_Pack.pdf'
                    },

                    {
                      name: 'Level 4 Miscellaneous Reproducibles',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/4_SPIRE_Reproducibles_Pack.pdf'
                    },
                    {
                      name: 'Level 4 Key Words and Pictures',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/4_SPIRE_KeyWords_Pack.pdf'
                    },

                    {
                      name: 'Level 5 Miscellaneous Reproducibles',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/5_SPIRE_Reproducibles_Pack.pdf'
                    },
                    {
                      name: 'Level 5 Key Words and Pictures',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/5_SPIRE_KeyWords_Pack.pdf'
                    },

                    {
                      name: 'Level 6 Miscellaneous Reproducibles',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/6_SPIRE_Reproducibles_Pack.pdf'
                    },
                    {
                      name: 'Level 6 Key Words and Pictures',
                      link: 'https://s3.amazonaws.com/exploros-shared-media/SPIRE/Pack/6_SPIRE_KeyWords_Pack.pdf'
                    },
                    {
                      name: 'Privacy Policy',
                      link: 'https://www.exploros.com/privacy/'
                    }
                  ];

      CourseFactory.setCurrentCourse(classIdService());

      // Display the correct class pack items based on the permissions
      if (userClassPermissions.hasPermission($scope.permissions.ui_view_class_pack_ispire)) {
        $scope.packItems = spirePackItems;
      }
      else if (userClassPermissions.hasPermission($scope.permissions.ui_view_class_pack_ww)) {
        $scope.packItems = wwPackItems;
      }
      else if (userClassPermissions.hasPermission($scope.permissions.ui_view_class_pack_spire)) {
        $scope.packItems = spireHybridPackItems;
      }

    }]);
})();
