'use strict';

clientControllers.controller('CourseViewCtrl', [
  '$scope', 'currentCourse', 'ExperienceNavigator', '$controller', 'CourseFactory', 'User', 'xpAlert', 'UserTrials',
  function ($scope, currentCourse, ExperienceNavigator, $controller, CourseFactory, User, xpAlert, UserTrials) {
    $controller('ExperienceListCtrl', {$scope: $scope});

    if (!currentCourse.cid) {
      return $scope.goToAllExperiences();
    }

    if (User.getSystemRole() === User.ROLE.TEACHER) {
      if ((!currentCourse.licenses || !currentCourse.licenses.length) && !UserTrials.hasTrials()) {
        var msg = "This class does not have a curriculum plan license so you cannot assign experiences to students. <a href=http://help.exploros.com/en/articles/4912771-seat-licenses-for-teachers target=blank> Learn how to license a class.</a>";
        xpAlert.info(msg, true);
      }

      $scope.$on('$destroy', function () {
        xpAlert.clearAlerts();
      });

      if (User.getId() !== currentCourse.teacher_user_id) {
        var find = (currentCourse.teachers || []).find(t => t.user_id === User.getId());

        if (!find) {
          return $scope.goToAllExperiences();
        }
      }
    }

    CourseFactory.setCurrentCourse(currentCourse.cid);

    // If the user comes in through a route, open the Add Experience modal
    if (ExperienceNavigator.getPreview()) {
      $scope.addExperience();
      ExperienceNavigator.setPreview(false);
    }
  }
]);
