'use strict';

/* Controllers */
// 'ui.bootstrap'

clientControllers.controller('ExperienceListCtrl', ['$scope', '$log', '$rootScope', '$route', '$routeParams', '$q', '$timeout',
  'ExperienceList', 'ExperienceDeleteFactory', 'CollectionsFactory', '$location', 'User', 'ModalService',
  'ExperienceRealtimeService', 'ExperienceSaveAndInvite', 'MasterFactory', 'ExperienceNavigator',
  'userPermissions', 'PermissionConsts', '$sessionStorage', 'ModalNavigation', 'OrgCollectionService', 'CourseAPI',
  'ActiveMode', 'UserCourses', 'ElementRealtimeService', 'UserTrials', 'xpAlert', 'notificationService', 'tourService',
  function ($scope, $log, $rootScope, $route, $routeParams, $q, $timeout, ExperienceList, ExperienceDeleteFactory,
            CollectionsFactory, $location, User, ModalService, ExperienceRealtimeService,
            ExperienceSaveAndInvite, MasterFactory, ExperienceNavigator, userPermissions, PermissionConsts,
            $sessionStorage, ModalNavigation, OrgCollectionService, CourseAPI,
            ActiveMode, UserCourses, ElementRealtimeService, UserTrials, xpAlert, notificationService, tourService) {
    // Initialize error handler
    $scope.xpError = {};
    $scope.validationFailed = function () {
      // If error messages
      if ($scope.xpError.details) {
        return $scope.xpError.details.length > 0;
      }
      return false;
    };

    $scope.experiences = {};
    $scope.inpreview = false;
    $scope.masterParentalCollections = undefined;
    $scope.pastExpLimit = 0;
    $scope.activeExpLimit = 0;
    $scope.thereAreMorePastExperiencesLeft = true;
    $scope.thereAreMoreActiveExperiencesLeft = true;
    var limitIncrease = 20;
    $scope.endedExperienceNotifications = 0;

    UserTrials.checkTrials();

    $scope.defaultCollection = "DefaultCollection";

    $scope.loadPastExperiences = function () {
      if ($scope.experiences.past && $scope.experiences.past.length > 0) {
        return;
      }

      notificationService.removeEndedExperiences(User.getId()).then(() => {
        $scope.endedExperienceNotifications = 0;
      });

      $scope.getPastExperiences();
    };

    $scope.loadPastExperiencesMore = function loadPastExperiencesMore() {
      $scope.pastExpLimit = $scope.pastExpLimit + limitIncrease;
      $scope.getPastExperiences();
    };

    $scope.loadActiveExperiencesMore = function loadActiveExperiencesMore() {
      $scope.activeExpLimit = $scope.activeExpLimit + limitIncrease;
      $scope.getActiveAndPendingExperiences();
    };

    function queryActiveAndPendingExperiences() {
      if ($scope.filter) {
        return ExperienceList.getActiveAndPendingByFilter($scope.activeExpLimit, $scope.filter);
      } else {
        return ExperienceList.getActiveAndPending($scope.activeExpLimit);
      }
    }

    $scope.getActiveAndPendingExperiences = function () {
      return queryActiveAndPendingExperiences().then((experiences) => {
        $scope.experiences.active = extendExperience(experiences[0]);
        $scope.experiences.pending = extendExperience(experiences[1]);
        var loadedNumber = 0;
        var couldLoadMore = true;
        if ($scope.experiences.active && $scope.experiences.active.length) {
          loadedNumber = $scope.experiences.active.length;
        }
        if (!loadedNumber) {
          couldLoadMore = false;
        }
        if ($scope.activeExpLimit > loadedNumber) {
          couldLoadMore = false;
        }
        if (!$scope.activeExpLimit && (limitIncrease > loadedNumber)) {
          couldLoadMore = false;
        }
        $scope.thereAreMoreActiveExperiencesLeft = couldLoadMore;
        return $scope.experiences;
      })
        .catch((err) => {
          $log.warn('Error retrieving active and pending experiences', err);
        });
    };

    $scope.onFilterExperiences = function (schools, teachers, courses) {
      // Query for a new list of experiences based on filters
      $scope.filter = {};

      if (schools && schools.length && schools[0].id !== 'All') {
        $scope.filter.schools = schools.map(function (school) {
          return school.id;
        });
      }

      if (teachers && teachers.length && teachers[0].teacher_user_id !== 'All') {
        $scope.filter.teachers = teachers.map(function (teacher) {
          return teacher.teacher_user_id;
        });
      }

      if (courses && courses.length && courses[0].id !== 'All') {
        $scope.filter.courses = courses.map(function (course) {
          return course.id;
        });
      }

      // Load all experience lists
      $scope.getActiveAndPendingExperiences();
      $scope.getPastExperiences();
    };

    function queryPastExperiences() {
      if ($scope.filter) {
        return ExperienceList.getEndedByFilter($scope.pastExpLimit, $scope.filter);
      } else {
        return ExperienceList.getEnded($scope.pastExpLimit);
      }
    }

    $scope.getPastExperiences = function () {
      return queryPastExperiences().then(function (experiences) {
        $scope.experiences.past = extendExperience(experiences);
        var loadedNumber = 0;
        var couldLoadMore = true;
        if ($scope.experiences.past && $scope.experiences.past.length) {
          loadedNumber = $scope.experiences.past.length;
        }
        if (!loadedNumber) {
          couldLoadMore = false;
        }
        if ($scope.pastExpLimit > loadedNumber) {
          couldLoadMore = false;
        }
        if (!$scope.pastExpLimit && (limitIncrease > loadedNumber)) {
          couldLoadMore = false;
        }
        $scope.thereAreMorePastExperiencesLeft = couldLoadMore;
      })
        .catch(function () {
          $log.warn('Error retrieving past experiences');
        });
    };

    $scope.inCourseView = ActiveMode.isClassMode();
    $scope.inCurriculumMode = ActiveMode.isCurriculumMode();
    if ($scope.inCurriculumMode) {
      $scope.assignClassHelperText = "Assign an experience to your classes";
      UserCourses.getCourses().then(function (courses) {
        if (courses && courses.length) {
          $scope.defaultClass = courses[0].cid;
        }
      });
    } else {
      $scope.assignClassHelperText = "Assign an experience to your class";
    }

    $scope.showAddExperienceHelperText = function () {
      return $scope.isTeacher &&
        ($scope.inCourseView || $scope.inCurriculumMode) &&
        $scope.experiences.active &&
        $scope.experiences.active.length === 0;
    };

    $scope.addExperienceModalAllowed = true;

    function assignPermissionToCollectionTemplates(members, permissions) {
      // recurse all members adding permissions to templates and recursively calling descendants
      return members.map(function (member) {
        if (member.kind === 'collection' && member.members && member.members.length > 0) {
          member.members = assignPermissionToCollectionTemplates(member.members, permissions);
        } else if (member.kind === 'template') {
          member.permissions = permissions;
        }
        return member;
      });
    }

    function getCollections() {
      var results = {};
      if ($scope.inCurriculumMode) {
        // Get the list of all associated master templates and collections for this teacher
        return CollectionsFactory.userCollections({}, {'id': User.getId()})
          .$promise.then(function (response) {
            // recurse through all descendants adding permissions to each
            results.collections = response.collections.map(function (collection) {
              if (collection.permissions && collection.permissions.length > 0 && collection.members && collection.members.length > 0) {
                collection.members = assignPermissionToCollectionTemplates(collection.members, collection.permissions);
              }
              return collection;
            });
            results.templates = response.templates;
            return results;
          })
          .catch(function (err) {
            $log.error("failed to retrieve collections:", err);
            return results;
          });
      } else {
        // Get the list of all associated master templates and collections for this teacher
        return CollectionsFactory.classCollections({}, {'id': User.getId(), 'class_id': $route.current.params.classId})
          .$promise.then(function (response) {
            // recurse through all descendants adding permissions to each
            results.collections = response.collections.map(function (collection) {
              if (collection.permissions && collection.permissions.length > 0 && collection.members && collection.members.length > 0) {
                collection.members = assignPermissionToCollectionTemplates(collection.members, collection.permissions);
              }
              return collection;
            });
            results.templates = response.templates;
            return results;
          })
          .catch(function (err) {
            $log.error("failed to retrieve collections:", err);
            return results;
          });
      }
    }

    function getCourses() {
      return OrgCollectionService.getCollections().getAvailableList()
        .$promise.then(function (availableCourses) {
          return availableCourses;
        })
        .catch(function (err) {
          $log.error("failed to retrieve courses:", err);
          return [];
        });
    }

    function getStates() {
      return CourseAPI.getStates()
        .$promise.then(function (states) {
          return states.sort(function (state1, state2) {
            if (state1.state_label.toLowerCase() < state2.state_label.toLowerCase()) {
              return -1;
            }
            if (state1.state_label.toLowerCase() > state2.state_label.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        })
        .catch(function (err) {
          $log.error("failed to retrieve states:", err);
          return [];
        });
    }

    $scope.goToAllExperiences = function goToAllExperiences() {
      ActiveMode.navigateToDefaultView();
    };

    $scope.addExperience = function () {
      if (!$scope.addExperienceModalAllowed) {
        return false;
      }
      if ($scope.inCurriculumMode && !$scope.hasPermission(PermissionConsts.add_multi_class_experience)) {
        ModalService.show({
          feature: 'add_multi_class_experience',
          template: require('../../views/partials/modals/subscriptionFeatureModal.jade'),
          backdrop: 'static'
        });
        return false;
      }

      $scope.addExperienceModalAllowed = false;
      getCollections().then(function (response) {
        var collections = response.collections || [];
        var templates = response.templates || [];
        var list = collections.concat(templates);
        var allCourses = [];
        var config = {
          title: 'Assign Experience',
          template: require('../../views/partials/modals/addExperiencesModal.jade'),
          data: {
            collections: list,
            currentNode: list,
            mappedCourses: [],
            unmappedCourses: [],
            states: [],
            selectedState: {state_label: "Choose a state"},
            breadcrumb: [],
            currentTemplate: {},
            activeTab: 'Assign',
            classId: $route.current.params.classId,
            searchList: [],
            touring: tourService.isTouring()
          },
          setActiveList: function (node) {
            config.data.currentNode = node.members || node;
            $sessionStorage[$scope.defaultCollection] = node.id;
          },
          setActiveListAndBreadcrumb: function (node) {
            if (tourService.isActionStep()) {
              config.setActiveList(node);
              config.data.breadcrumb.push(node);
              if (!node.members || node.members.length === 0 || tourService.canNavigate(node.members[0].kind)) {
                tourService.gotoNextTourStep();
              }
            }
          },
          gotoRoot: function (node) {
            if (tourService.canNavigate('#course_library')) {
              config.data.currentNode = node.members || node;
              config.data.breadcrumb = [];
              delete $sessionStorage[$scope.defaultCollection];
            }
          },
          gotoNode: function (index) {
            if (config.data.breadcrumb.length === index + 1) {
              return;
            }

            config.data.breadcrumb = config.data.breadcrumb.filter(function (crumb, idx) {
              if (idx <= index) {
                return crumb;
              }
            });

            config.setActiveList(config.data.breadcrumb[index]);
          },
          showStandards: function (permissions) {
            return $scope.isTeacher && (permissions || []).indexOf(PermissionConsts.ui_curriculum_show_experience_card_standards) != -1;
          },
          masterOnClick: $scope.masterOnClick,
          canAssignExperience: $scope.canAssignExperience,
          canReviewExperience: $scope.canReviewExperience,
          canPreviewExperience: $scope.canPreviewExperience,
          canAIPreviewExperience: $scope.canAIPreviewExperience,
          calculateMaxStandards: $scope.calculateMaxStandards,
          previewOnClick: $scope.previewOnClick,
          reviewOnClick: $scope.reviewOnClick,
          addOERCourse: function (course) {
            course.updatingCourse = true;
            course.updateMessage = 'Adding Course...';
            // Call to the server to add all the experience templates that are part of this collection
            $scope.addOERCourse(course.collection_uuid).then(function (result) {
              $log.debug('Added OER Course: ' + course.collection_uuid);
              collectionHasLoaded(result);
              reloadCollections();
            })
              .catch(function (error) {
                $log.error('addOERCourse:', error);
                course.updatingCourse = false;
              });
          },
          removeOERCourse: function (course) {
            course.updatingCourse = true;
            course.updateMessage = 'Removing Course...';
            // Call to the server to remove all the experience templates that are part of this collection
            return $scope.removeOERCourse(course.collection_uuid)
              .then(function (message) {
                $log.debug('Removed OER Course: ' + course.collection_uuid);
                course.updateMessage = 'Course removed from library';
                course.showRemoved = true;
                collectionHasLoaded(message);
                reloadCollections();
              })
              .catch(function (error) {
                $log.error('removeOERCourse:', error);
                course.updatingCourse = false;
              });
          },
          getClassId: $route.current.params.classId,
          getIsFree: function (parents) {
            if (!parents || !parents.length) {
              return true;
            }
            // Find the matching collection
            var parentCollection = config.data.collections.find(function (collection) {
              return collection.parent_id === parents[0];
            });
            if (parentCollection) {
              return parentCollection.is_public;
            }
            return true;
          },
          onSearchChange: function () {
            if (config.data.searchText && config.data.searchText.length > 2) {
              var breadCrumb = [];
              var searchText = config.data.searchText.toLowerCase();
              config.data.searchList = $scope.findMatchingTemplates(config.data.collections, searchText, breadCrumb);
            } else {
              config.data.searchList = [];
            }
          },
          closeDialog: function() {
            tourService.setTour(0);
          },
          setSearchNode: function (selectedSearchItem) {
            config.data.searchId = 0;
            if (selectedSearchItem.breadcrumb && selectedSearchItem.breadcrumb.length) {
              config.data.breadcrumb = [...selectedSearchItem.breadcrumb];
              config.setActiveList(selectedSearchItem.breadcrumb[selectedSearchItem.breadcrumb.length - 1]);
            } else {
              config.gotoRoot(config.data.collections);
            }
            $timeout(function () {
              config.data.searchId = selectedSearchItem.node.id;
            }, 500);
          },
          getDefaultBreadcrumb: function (experienceList, defaultNode) {
            if (!config.data.touring) {
              // Loop through all nodes in this collection
              experienceList.forEach(function (node) {
                // if this node matches the default then we found where we want to be.
                if (node.id === defaultNode.defaultNodeId) {
                  // Add this node to the bread crumb and set status to found so we can fill in the remainder of the breadcrumb on the way out
                  defaultNode.breadCrumb.push(node);
                  defaultNode.found = true;
                } else if (node.members && !defaultNode.found) {
                  // Recursively look at child list for default node
                  config.getDefaultBreadcrumb(node.members, defaultNode);

                  // If it was found as a child of this node then splice this node at the beginning of the breadcrumb
                  if (defaultNode.found) {
                    defaultNode.breadCrumb.unshift(node);
                  }
                }
              });
            }
          },
          setDefault: function (nodeId) {
            var defaultNode = {
              defaultNodeId: nodeId,
              breadCrumb: [],
              found: false
            };

            // This call recursively rebuilds the breadcrumb for the specified node
            config.getDefaultBreadcrumb(config.data.collections, defaultNode);

            // if we found the node and we should have a full breadcrumb then navigate to it
            if (defaultNode.found) {
              // Assign the breadcrumb based on the calculated path
              config.data.breadcrumb = defaultNode.breadCrumb;

              // Navigate to this default
              config.setActiveList(config.data.breadcrumb[config.data.breadcrumb.length - 1]);
            }
          },
          onSelectState: function (state) {
            window.state = state;
            window.allCourses = allCourses;
            config.data.selectedState = state;
            // Filter the list of courses based on the selected state
            config.data.mappedCourses = (allCourses || []).filter(function (course) {
              return (course.states || [])
                .map(function (stateRec) {
                  return stateRec.state_id;
                })
                .includes(state.id);
            });
            config.data.unmappedCourses = (allCourses || []).filter(function (course) {
              return !(course.states || [])
                .map(function (stateRec) {
                  return stateRec.state_id;
                })
                .includes(state.id);
            });
          }
        };

        tourService.gotoNextTourStep();

        var modal = ModalService.show(config);
        modal.$promise.then(function () {
          $scope.addExperienceModalAllowed = true;

          // Load the courses and states in the background after the dialog has been shown
          getCourses().then(function (courses) {
            allCourses = courses;
            config.data.unmappedCourses = courses;

            getStates().then(function (states) {
              config.data.states = states;
              if (User.getStateId()) {
                config.data.userStateId = User.getStateId();
                var userState = config.data.states.find(function (state) {
                  return state.id === User.getStateId();
                });
                if (userState) {
                  config.onSelectState(userState);
                }
              }
            });
          });

          // See if there was a specific collection open the last time this dialog was used
          var defaultCollection = $sessionStorage[$scope.defaultCollection];
          if (defaultCollection) {
            config.setDefault(defaultCollection);
          }
        });

        function reloadCollections() {
          getCollections().then(function (response) {
            var collections = response.collections || [];
            var templates = response.templates || [];
            var list = collections.concat(templates);

            config.data.collections = list;
            config.data.currentNode = list;
            config.data.breadcrumb = [];
            config.data.currentTemplate = {};
          });
        }

        function collectionHasLoaded(message) {
          // Find the corresponding course that was just added/removed
          config.data.mappedCourses.forEach(function (course) {
            if (course.collection_uuid === message.success) {
              course.isAdded = message.action === "added";
              course.updatingCourse = false;
              course.updateMessage = "";
              course.showRemoved = false;
            }
          });
          config.data.unmappedCourses.forEach(function (course) {
            if (course.collection_uuid === message.success) {
              course.isAdded = message.action === "added";
              course.updatingCourse = false;
              course.updateMessage = "";
              course.showRemoved = false;
            }
          });
        }

      })
        .catch(function (err) {
          $log.error("failed to retrieve collections:", err);
          $scope.addExperienceModalAllowed = true;
        });
    };

    // Set scope variable to handle enabling/disabling UI elements based on the current user being a teacher or a student
    $scope.isTeacher = User.getSystemRole() == 'TEACHER';
    $scope.isStudent = User.getSystemRole() == 'STUDENT';

    function loadExperiences() {
      return $scope.getActiveAndPendingExperiences();
    }

    // Load experiences on activation
    loadExperiences();

    $scope.loadExperiences = loadExperiences;

    function endedExperienceNotifications() {
      notificationService.getEndedExperiences(User.getId()).then((ended) => {
        $scope.endedExperienceNotifications = ended.length;
      });
    }

    // Load the ended experience notifications
    endedExperienceNotifications();

    // Create a handler for new experiences
    ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPNewExperienceNotification,
      newExperienceNotificationHandler);
    $scope.$on('$destroy', function () {
      ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPNewExperienceNotification,
        newExperienceNotificationHandler);
      xpAlert.clearAlerts(xpAlert.alertKind.TRIAL);
    });

    // update list of experiences on students joining/progressing
    ExperienceRealtimeService.on(ElementRealtimeService.EVENTS.XPActivityStateChangedNotification,
      newExperienceNotificationHandler);
    $scope.$on('$destroy', function () {
      ExperienceRealtimeService.removeListener(ElementRealtimeService.EVENTS.XPActivityStateChangedNotification,
        newExperienceNotificationHandler);
    });

    function newExperienceNotificationHandler(e) {
      var message = e.detail;
      var experience = message && message.record;

      $log.debug("New experience added: " + JSON.stringify(experience));
      loadExperiences();
    }

    // Create a handler for updating experiences
    ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPUpdateExperienceNotification,
      updateExperienceListNotificationHandler);
    $scope.$on('$destroy', function () {
      ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPUpdateExperienceNotification,
        updateExperienceListNotificationHandler);
    });

    function updateExperienceListNotificationHandler(e) {
      var message = e.detail;
      var experience = message && message.record;

      $log.debug("Received experience update: " + JSON.stringify(experience));
      loadExperiences();
      if (e.type === 'experience_updated' && message.record && message.record.status) {
        endedExperienceNotifications();
        $scope.getPastExperiences();
      }
    }

    // Listen for student removed event
    ExperienceRealtimeService.on(ExperienceRealtimeService.EVENTS.XPExperienceStudentRemovedNotification,
      updateExperienceListNotificationHandler);
    $scope.$on('$destroy', function () {
      ExperienceRealtimeService.removeListener(ExperienceRealtimeService.EVENTS.XPExperienceStudentRemovedNotification,
        updateExperienceListNotificationHandler);
    });

    function extendExperience(experiences) {
      experiences.forEach(function (experience) {
        // Does this experience template support group sharing?
        if (experience.small_groups > 0) {
          experience.features = experience.features || [];
          experience.features.push({
            name: 'Small groups',
            description: 'Experiences published using this Master require the use of small student groups.'
          });
        }

        // Does this experience template support reading groups?
        if (experience.reading_group_id > 0 && $scope.isTeacher) {
          experience.features = experience.features || [];
          experience.features.push({
            name: 'Reading groups',
            description: 'Experiences published using this Master require the use of reading groups.'
          });
        }

        // Does this experience template support teacher-controlled navigation?
        if (experience.is_guided == 1) {
          experience.features = experience.features || [];
          experience.features.push({
            name: 'Teacher nav',
            description: 'Teachers will be able to control student navigation in Experiences published using this Master.'
          });
        }
      });

      return experiences;
    }

    $scope.masterOnClick = function (templateId, classId, parents, isFree, permissions, templateSource) {
      if (!$scope.canAssignExperience(permissions, templateSource)) {
        return false;
      }
      if (!tourService.canNavigate('#assign')) {
        retur;
      }
      // The master ID is a parameter to the URL
      var params = {
        templateId: templateId,
        parents: parents,
        is_public: isFree
      };

      tourService.gotoNextTourStep();

      if ($scope.inCurriculumMode) {
        ModalNavigation.navigateToModalWithParam('/experience/assign', params, $location.path());
      } else {
        ModalNavigation.navigateToModalWithParam('/class/' + classId + '/assign', params, $location.path());
      }
    };

    $scope.canAssignExperience = function canAssignExperience(permissions, templateSource) {
      return (permissions || []).includes('ui_assign_experience') || (templateSource === 'authoring');
    };

    $scope.canReviewExperience = function canReviewExperience(node) {
      return userPermissions.hasPermission(PermissionConsts.ui_analytics_review) &&
            (node.ai_preview_state == 'Edit' || node.ai_preview_state == 'Released');
    };

    $scope.canPreviewExperience = function canPreviewExperience(node) {
      return !node.ai_preview_state || node.ai_preview_state == 'Edit';
    };

    $scope.canAIPreviewExperience = function canAIPreviewExperience(node) {
      return node.ai_preview_state && node.ai_preview_state == 'Released';
    };

    $scope.calculateMaxStandards = function (experience) {
      var maxDisplayStandards = 13;
      if (experience.has_groups) {
        maxDisplayStandards = maxDisplayStandards - 2;
      }
      if (experience.reading_group_id && experience.reading_group_id > 0) {
        maxDisplayStandards = maxDisplayStandards - 2;
      }
      if (experience.is_guided) {
        maxDisplayStandards = maxDisplayStandards - 2;
      }
      if (experience.image_filename) {
        maxDisplayStandards = maxDisplayStandards - 2;
      }

      return maxDisplayStandards;
    };

    $scope.findMatchingTemplates = function (items, searchText, breadcrumb) {
      var foundItems = [];

      // Check each item to see if it matches the criteria or if it is a collection and any of its items match
      items.forEach(function (item) {
        if (item.kind === "collection" && item.members) {
          var currentBreadCrumb = [...breadcrumb];
          currentBreadCrumb.push(item);
          foundItems = foundItems.concat($scope.findMatchingTemplates(item.members, searchText, currentBreadCrumb));
        } else if (item.name.toLowerCase().search(searchText) !== -1) {
          var matchingItem = {
            node: item,
            breadcrumb: breadcrumb
          };
          foundItems.push(matchingItem);
        }
      });

      return foundItems;
    };

    function publishMaster(masterId, courseId, parentalCollections, isReview) {
      var oParams = {};
      MasterFactory.master({}, {'template_id': masterId}, function (response) {
        oParams.name = response.name + " (Preview)";
        oParams.section_name = "Preview of " + response.name;
        oParams.teacher_user_id = User.getId();
        oParams.catalog_item_id = masterId;
        oParams.template_id = masterId;
        // Calculate the start date based on Date and Time
        oParams.starts_at = new Date();
        oParams.starts_at = oParams.starts_at.toString();
        oParams.ends_at = null;
        oParams.status = "DRAFT";
        oParams.description = response.description;
        oParams.notification = response.notification;
        oParams.students = "";
        oParams.small_groups = 0;
        oParams.is_guided = response.is_guided;
        oParams.preview = true;
        oParams.review = isReview;
        oParams.return_url = null;
        oParams.project_id = response.project_id;
        if ($scope.inCurriculumMode) {
          oParams.class_id = $scope.defaultClass;
        } else {
          oParams.class_id = $route.current.params.classId;
        }
        oParams.parents = $scope.masterParentalCollections;

        ExperienceSaveAndInvite.publishExperience(oParams).then(
          function (oSave)        // Response Handler #1
          {
            tourService.gotoNextTourStep();
            var experience_id = oSave.dataResult.id;
            var loc = '/experience/' + experience_id + '/activity/scene/0';
            var back = '/experience';
            if (courseId) {
              back = '/class/' + courseId;
            }
            if (!tourService.isTouring()) {
              ExperienceNavigator.setPreview(true);
            }
            ExperienceNavigator.navigateToExperience(loc, "", back);
          });
      });
    }

    $scope.previewOnClick = function (masterId, courseId, parentalCollections) {
      if ($scope.inpreview) {
        return;
      }
      if (!tourService.canNavigate('#preview')) {
        return;
      }

      $scope.inpreview = true;
      $scope.masterParentalCollections = parentalCollections;

      publishMaster(masterId, courseId, parentalCollections, false);
    };

    $scope.reviewOnClick = function (masterId, courseId, parentalCollections) {
      if ($scope.inpreview) {
        return;
      }
      if (!tourService.canNavigate('#preview')) {
        return;
      }

      $scope.inpreview = true;
      $scope.masterParentalCollections = parentalCollections;

      publishMaster(masterId, courseId, parentalCollections, true);
    };

    $scope.addOERCourse = function (collection_uuid) {
      return OrgCollectionService.addCollection(collection_uuid).add()
        .$promise.then(function (result) {
          return $q.when(result);
        })
        .catch(function (error) {
          $log.warn('Error adding OER course: ' + error);
          return $q.reject(error);
        });
    };

    $scope.removeOERCourse = function (collection_uuid) {
      return OrgCollectionService.removeCollection(collection_uuid).remove()
        .$promise.then(function (result) {
          return $q.when(result);
        })
        .catch(function (error) {
          $log.warn('Error removing OER course: ' + error);
          return $q.reject(error);
        });
    };

  }]);
