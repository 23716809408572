'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', '$element', '$attrs', '$log'];

  function controller ($scope, $element, $attrs, $log) {
    var ctrl = this;

    ctrl.subscriber = null;
    ctrl.hasVideo = false
    ctrl.hasAudio = true;

    ctrl.getPublisherProps = function(stream) {
      var publisherProps = {
          style: {nameDisplayMode: 'on', buttonDisplayMode: 'off'}
      };
      var userInfo = JSON.parse(stream.connection.data);
      publisherProps.name = userInfo.first_name + " " + userInfo.last_name;
      return publisherProps;
    };

    ctrl.$onInit = function () {
      if (ctrl.session && ctrl.stream) {
        ctrl.subscriber  = ctrl.session.subscribe(ctrl.stream, $element[0], ctrl.getPublisherProps(ctrl.stream), function(err){
          if (err) {
            $log.error("Failed to initialize subscriber: " + err);
            return false;
          }
          ctrl.hasVideo = ctrl.stream.hasVideo;
          ctrl.hasAudio = ctrl.stream.hasAudio;
        });
      }
    };

    ctrl.$onChanges = function(changes) {
      if (changes.hasAudio) {
        ctrl.hasAudio = changes.hasAudio.currentValue;
      }
      if (changes.hasVideo) {
        ctrl.hasVideo = changes.hasVideo.currentValue;
      }
      if (ctrl.subscriber) {
        if (!ctrl.hasAudio && !ctrl.hasVideo) {
          ctrl.subscriber.setStyle("nameDisplayMode", "off");
        } else {
          ctrl.subscriber.setStyle("nameDisplayMode", "on");
        }
      }
    };

    ctrl.$onDestroy = function () {
      if (ctrl.session && ctrl.subscriber) {
        ctrl.session.unsubscribe(ctrl.subscriber);
      }
    };
  }

  module.component('subscriberStream', {
    template: require('./subscriberStream.jade'),
    controller: controller,
    bindings: {
      session: '<',
      stream: '<',
      hasAudio: '<'
    }
  });
})();
