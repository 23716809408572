'use strict';

angular.module('client.services').factory('curriculumReports', ['$q', '$resource', 'api_server',
  function ($q, $resource, api_server) {

    var reports = $resource(api_server + '/reports/curriculum/reports');
    var reportData = $resource(api_server + '/reports/curriculum/reports/:report_key', null,
      {
        'query': {method: 'GET', isArray: false}
      });

    function getReports() {
      return reports.query().$promise;
    }

    function getReportData(reportKey, filter) {
      if (!reportKey) {
        return $q.when([]);
      }
      return reportData.query({report_key: reportKey, filter: filter}).$promise;
    }

    return {
      getReports: getReports,
      getReportData: getReportData
    };
  }]);

