'use strict';

angular.module('client.services').factory('TeacherStreamingService', ['$log', '$q', 'ActiveExperience','VideoStreamingService',
                                                                      'PermissionConsts',
  function ($log, $q, ActiveExperience, VideoStreamingService,
            PermissionConsts) {

  var currentExperienceId = null;
  var teacherIsSharing = false;
  var teacherIsSharingVideo = false;
  var teacherIsSharingAudio = false;

  function initTeacherStreaming(experienceId) {
    if (currentExperienceId === experienceId) {
      return;
    }

    // If the experiences don't match then we need to make sure video for all students is turned off
    // since this means either the teacher refreshed the browser or change URL to different experience.
    VideoStreamingService.setTeacherStreamingStatus(experienceId, "off", 0);
  }

  function startVideoSharing(experienceId) {
    if (!ActiveExperience.hasPermission(experienceId, PermissionConsts.ui_streaming_video)) {
      $log.info("startVideoSharing: User doesn't have permissions to share video");
      return false;
    }

    currentExperienceId = experienceId;
    teacherIsSharing = true;
  }

  function isTeacherStreamingVideo() {
    return teacherIsSharing;
  }

  function stopVideoSharing(experienceId) {
    return VideoStreamingService.setTeacherStreamingStatus(experienceId, "off", 0).then(function (result) {
      teacherIsSharing = false;
      ActiveExperience.currentExperience().students.forEach(function(student){
        student.audioStreamEnabled = false;
        student.raisedHand = false;
      });
      return true;
    }).catch(function(error){
      $log.error("stopVideoSharing failed: " + error);
      return false;
    });
  }

  return {
    initTeacherStreaming: initTeacherStreaming,
    startVideoSharing: startVideoSharing,
    isTeacherStreamingVideo: isTeacherStreamingVideo,
    stopVideoSharing: stopVideoSharing
  };
}]);

