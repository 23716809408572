'use strict';

(function () {
  let module = angular.module('client.components');

  controller.$inject = ['$rootScope', '$scope', '$location', '$aside', '$log', '$window', 'PageService', 'User', 'ActiveMode', 'UserCodeFactory',
    'PermissionConsts', 'userPermissions', 'userClassPermissions', 'userSubscriptionPermissions',
    'userCurriculumPermissions', 'UserDefaultInfo', 'ModalService', 'SegmentFactory', 'loginService',
    'WebSocket', 'RealtimeEvents', 'UserCourses', 'loginNotificationService', 'ModalNavigation',
    'tourService'];

  function controller($rootScope, $scope, $location, $aside, $log, $window, PageService, User, ActiveMode, UserCodeFactory,
                      PermissionConsts, userPermissions, userClassPermissions, userSubscriptionPermissions,
                      userCurriculumPermissions, UserDefaultInfo, ModalService, SegmentFactory, loginService,
                      WebSocket, RealtimeEvents, UserCourses, loginNotificationService, ModalNavigation,
                      tourService) {
    let ctrl = this;
    ctrl.title = "";
    ctrl.teacherCode = "";
    ctrl.userId = User.getId();
    ctrl.updateNotifications = 0;
    ctrl.videoNotifications = 0;

    function getTitle() {
      ActiveMode.getTitle().then(function (title) {
        if (title === undefined) {
          return;
        }
        if (title.length > 50) {
          title = title.substring(0, 50) + '&hellip;';
        }
        ctrl.title = title;
      }, function (error) {
        ctrl.title = "";
      });
    }

    // get the current mode title
    getTitle();

    UserCodeFactory.get({}, {'id': User.getId()}).$promise
      .then(function (response) {
        ctrl.teacherCode = response ? response.code : "";
      })
      .catch(function (error) {
        $log.error("user code get error in navbar:", error);
      });

    loginNotificationService.getUpdateNotifications(User.getId())
      .then(function (updates) {
        ctrl.updateNotifications = updates.length;
      });

    loginNotificationService.getVideoNotifications(User.getId())
      .then(function (videos) {
        ctrl.videoNotifications = videos.length;
      });

    ctrl.isSubscriptionMode = function () {
      return ActiveMode.isSubscriptionMode();
    };

    ctrl.isClassMode = function () {
      return ActiveMode.isClassMode();
    };

    ctrl.isCurriculumMode = function () {
      return ActiveMode.isCurriculumMode();
    };

    ctrl.isActiveTour = function () {
      return tourService.isTouring();
    };

    ctrl.userIsTeacher = function () {
      return User.getSystemRole() == User.ROLE.TEACHER;
    };

    ctrl.userIsStudent = function () {
      return User.getSystemRole() == User.ROLE.STUDENT;
    };

    ctrl.toggleSidebar = function () {
      let aside = $aside({
        scope: $rootScope,
        container: 'body',
        placement: 'left',
        animation: 'am-slide-left',
        template: '<nav-sidebar/>'
      });

      aside.$promise.then(function () {
        $rootScope.$on('$locationChangeStart', function () {
          aside.hide();
        });

        $rootScope.$on('new-class', function () {
          aside.hide();
        });

        // let unregisterMH = $rootScope.$on('aside.hide', function(e, modal) {
        //   if (modal === aside) {
        //     unregisterLCS();
        //     unregisterMH();
        //   }
        // });

        aside.show();
      });
    };

    ctrl.showModeDropdown = function () {
      return ((ctrl.isSubscriptionMode() && (ctrl.hasPermission(ctrl.permissions.ispire_rosters_and_licenses) ||
          ctrl.hasPermission(ctrl.permissions.import_students_to_classes) ||
          ctrl.hasPermission(ctrl.permissions.view_district_schools_subscriptions))) ||
        ctrl.isClassMode()) && ctrl.userIsTeacher();
    };

    ctrl.getTitleDropdown = function () {
      if (ctrl.isSubscriptionMode()) {
        let menus = [];

        // Add the subscription and license menu if this user has permissions
        if (ctrl.hasPermission(ctrl.permissions.ispire_rosters_and_licenses)) {
          menus.push({
            "text": "Class Rosters and Licenses",
            "click": "manageSubscriptionLicenses()"
          });
        }

        // Add the subscription menu if this user has permissions
        if (ctrl.hasPermission(ctrl.permissions.view_district_schools_subscriptions)) {
          menus.push({
            "text": "School Licenses",
            "click": "viewSchoolLicenses()"
          });
        }

        // Add the csv import menu if this user has permissions
        let is_safari_on_ipad = /iPad.*AppleWebKit/i.test(navigator.userAgent);
        if (ctrl.hasPermission(ctrl.permissions.import_students_to_classes) && !is_safari_on_ipad) {
          menus.push({
            "text": "Import Users and Rosters (CSV)",
            "click": "importSubscriptionUsers()"
          });
        }

        // return any mneus
        return menus.length ? menus : null;
      } else if (ctrl.isClassMode()) {
        let menus = [];
        if (!ctrl.hasPermission(ctrl.permissions.ui_disable_rostering)) {
          menus.push({
            "text": "Roster",
            "click": "addStudentsToCourse()"
          });
        }
        menus.push({
          "text": "Settings",
          "click": "courseSettings()"
        });
        return menus;
      }
    };

    ctrl.permissions = PermissionConsts;
    ctrl.hasPermission = function (permission) {
      return userPermissions.hasPermission(permission) ||
        userClassPermissions.hasPermission(permission) ||
        userSubscriptionPermissions.hasPermission(permission) ||
        userCurriculumPermissions.hasPermission(permission);
    };

    ctrl.hasPermissions = function hasPermissions(permissions) {
      return permissions.filter(ctrl.hasPermission)[0];
    };

    ctrl.hasPermissionForClass = function hasPermissionForClass(permission) {
      return userClassPermissions.hasPermission(permission);
    };

    // Determine which view is currently active
    ctrl.showingExperiences = function () {
      return ActiveMode.showingExperiences();
    };

    ctrl.showingPack = function () {
      return ActiveMode.showingPack();
    };

    ctrl.showingLicensePack = function () {
      return ActiveMode.showingLicensePack();
    };

    ctrl.showingReports = function () {
      return ActiveMode.showingReports();
    };

    ctrl.showingAdminConsole = function () {
      return ActiveMode.showingAdminConsole();
    };

    ctrl.showAdminConsoleLink = function () {
      if (ctrl.userIsStudent()) {
        return false;
      }

      if (ActiveMode.isDistrictAdminMode()) {
        return ctrl.hasPermission(ctrl.permissions.ui_show_admin_interface);
      }

      return false;
    };

    // if the user has access to either the pack or reports then this image needs to show up so they can navigate back to experiences
    ctrl.showExperiencesLink = function () {
      return ctrl.showPackLink() || ctrl.showReportLink() || ctrl.showAdminConsoleLink() || ctrl.showLicensePackLink();
    };

    // if the user has access to the pack then this image needs to show up so they can navigate packs
    ctrl.showPackLink = function () {
      if (ActiveMode.isClassMode()) {
        return ctrl.hasPermissionForClass(ctrl.permissions.ui_view_class_pack_ispire) ||
          ctrl.hasPermissionForClass(ctrl.permissions.ui_view_class_pack_ww) ||
          ctrl.hasPermissionForClass(ctrl.permissions.ui_view_class_pack_spire) ||
          false;
      }

      return ctrl.hasPermission(ctrl.permissions.ui_view_class_pack);
    };

    ctrl.showLicensePackLink = function () {
      return ctrl.hasPermission(ctrl.permissions.ui_license_pack) && !ctrl.showPackLink();
    };

    ctrl.showReportLink = function () {
      if (ctrl.userIsStudent()) {
        return false;
      }

      if (ActiveMode.isCurriculumMode() || ActiveMode.isClassMode()) {
        return !ctrl.hasPermission(ctrl.permissions.ui_navbar_hide_curriculum_reports_button);
      }

      if (ActiveMode.isClassMode()) {
        return !!ctrl.hasPermissionForClass(ctrl.permissions.view_class_reports);
      }

      return ctrl.hasPermissions([
        ctrl.permissions.subscription_admin
      ]);
    };

    ctrl.showTourLink = function () {
      return ctrl.userIsTeacher();
    };

    ctrl.loadAccountSettings = function (state) {
      state = state || 'profile';

      let modalId = 'account-settings';

      return UserDefaultInfo.getUserInfo().then(function (userInfo) {
        let config = {
          id: modalId,
          template: require('../accountSettings/accountSettings.jade'),
          state: state,
          userIsStudent: ctrl.userIsStudent,
          changeState: function (state) {
            this.state = state;
          }
        };

        ModalService.show(config);

        if (!ctrl.userIsStudent) {
          SegmentFactory.track(SegmentFactory.events().ACCOUNT_SETTINGS_VIEWED);
        }
      })
        .catch(function (err) {
          $log.error('Error retrieving user data. Aborting display of Account Settings.');
        });
    };

    ctrl.coTeachClass = function () {
      $location.path('/joinAClass/teacher');
    };

    ctrl.joinAClass = function () {
      $location.path('/joinAClass/student');
    };

    ctrl.showReleases = function () {
      loginNotificationService.removeUpdateNotifications(User.getId());
      ctrl.updateNotifications = 0;
    };

    ctrl.showVideo = function () {
      loginNotificationService.removeVideoNotifications(User.getId());
      ctrl.videoNotifications = 0;
      $window.open('/helpVideos', '_blank');
    };

    ctrl.showTourEnable = function () {
      tourService.getTourTopics().then(function (topics) {
        let userData = User.getCurrentUser();
        let enabled = User.isTeacher() && userData && userData.active_tour_topic_id;

        let completed = true;
        topics.forEach(function (topic) {
          topic.tours.forEach(function (tour) {
            if (!tour.tour_status) {
              completed = false;
            }
          });
        });

        let msg = !enabled ? "Turn on" : "Turn off";

        let config = {
          title: 'Tours',
          backdrop: 'static',
          template: require('../onBoarding/tourEnable.jade'),
          buttonMsg: msg,
          tourIsEnabled: enabled,
          tourCompleted: completed,
          selectTopic: function (topic) {
            if (topic.checked) {
              topic.checked = false;
            } else {
              topics.forEach(function (topic) {
                topic.checked = false;
              });
              topic.checked = true;
            }
          },
          setActiveTour: function () {
            if (enabled) {
              tourService.saveUserCurrentTourTopic(0);
            } else {
              tourService.saveUserCurrentTourTopic(1);
            }
          }
        };

        ModalService.show(config);
      });
    };

    ctrl.showTours = function () {
      tourService.getTourTopics().then(function (topics) {
        let config = {
          title: 'Tours',
          backdrop: 'static',
          template: require('../onBoarding/tourTopics.jade'),
          topics: topics,
          selectTopic: function (topic) {
            if (topic.checked) {
              topic.checked = false;
            } else {
              topics.forEach(function (topic) {
                topic.checked = false;
              });
              topic.checked = true;
            }
          },
          getMessage: function () {
            let completedTours = 0;
            topics.forEach(function (topic) {
              let completed = false;
              topic.tours.forEach(function (tour) {
                completed = tour.tour_status ? true : false;
              });
              if (completed) {
                completedTours = completedTours + 1;
              }
            });
            let msg = "";
            if (completedTours > 0) {
              msg = "Congratulations on completing " + completedTours + " tours! ";
            }
            msg = msg + "Turn on any tour by clicking on the icon. You can only select one tour at a time.";
            return msg;
          },
          setActiveTour: function () {
            let selectedTourTopicId = 0;
            let selectedTourTopic = topics.find(function (topic) {
              return topic.checked;
            });
            if (selectedTourTopic) {
              selectedTourTopicId = selectedTourTopic.id;
              tourService.saveUserCurrentTourTopic(selectedTourTopicId);
            }
          }
        };

        ModalService.show(config);
      });
    };

    ctrl.onLogout = function () {
      return loginService.logout();
    };

    ctrl.navigateToExperiences = function () {
      ActiveMode.navigateToExperiences();
    };

    ctrl.navigateToReports = function () {
      ActiveMode.navigateToReports();
    };

    ctrl.navigateToClassPack = function () {
      ActiveMode.navigateToPack();
    };

    ctrl.navigateToLicenseClassPack = function () {
      ActiveMode.navigateToLicensePack();
    };

    ctrl.navigateToAdminConsole = function () {
      ActiveMode.navigateToAdminConsole();
    };

    ctrl.showProfileDropdown = function () {
      tourService.gotoNextTourStep();
    };

    // Create a handler for new experiences
    WebSocket.on(RealtimeEvents.EVENTS.Course.XPUpdateCourseNotification, updateCourseNotificationHandler);
    $scope.$on('$destroy', function () {
      WebSocket.removeListener(RealtimeEvents.EVENTS.Course.XPUpdateCourseNotification, updateCourseNotificationHandler);
    });

    function updateCourseNotificationHandler() {
      UserCourses.updateCachedCourses().then(function (courses) {
        getTitle();

        // Move the student to the All Classes view if they were removed from there current class
        let currentClass = parseInt(ActiveMode.currentClassId(), 10);

        // Find this in the list.  If it is not there then navigate to the default view
        let existingClass = courses.find(function (course) {
          return course.cid === currentClass;
        });
        if (!existingClass) {
          ActiveMode.navigateToDefaultView();
        }
      });
    }

  }

  module.component('xpNavbarPage', {
    template: require('./navbarPage.jade'),
    controller: controller,
    transclude: true
  });

})();

